import React, { useEffect, useRef, useState } from "react";
import ProfileImg from "../../assets/images/avatar.webp";
import { Menu } from "primereact/menu";
import { Link, useNavigate } from "react-router-dom";
import { EMPLOYEE_API } from "../../constants/api";
import { Skeleton } from "primereact/skeleton";
import { ROUTE_CONSTANTS } from "../../constants/routeConstants";
import { LOCAL_CONSTANTS } from "../../constants/localConstants";
import axios from "axios";

const MenuProfileView = () => {
  const menu = useRef(null);
  const [userProfile, setUserProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [roleData, setRoleData] = useState(null);
  const [roleChange, setRoleChange] = useState(localStorage.getItem("roleChange") || "");
  const [error, setError] = useState("");

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = () => {
    setLoading(true);
    setError("");
    const userDataString = localStorage.getItem("user");
    const userRoleChange = localStorage.getItem("roleChange");
    setRoleChange(userRoleChange)

    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setRoleData(userData.data.role);
      const employeeId = userData.data.id;
      axios
        .get(EMPLOYEE_API.USER_PROFILE(employeeId))
        .then(({ data }) => {
          setUserProfile(data.userData);
          setLoading(false);
        })
        .catch((error) => {
          setError(error, "Error fetching user data");
          setLoading(false);
        });
    } else {
      setError("User data not found in local storage");
      setLoading(false);
    }
  };

  const handleMenuToggle = (e) => {
    menu.current.toggle(e);
  };

  const fullName = `${userProfile?.firstname || ""} ${userProfile?.lastname || ""
    }`;

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem(LOCAL_CONSTANTS.ACCESS);
    localStorage.removeItem(LOCAL_CONSTANTS.USER);
    localStorage.removeItem("isChecked")
    navigate(ROUTE_CONSTANTS.LOGIN);
  };

  const handleChangeAccount = async (role, path, check) => {
    try {
      const userDataString = localStorage.getItem("user");
      localStorage.setItem("isChecked", check);
      if (userDataString) {
        localStorage.setItem("roleChange", role);
        window.location.href = path;
      } else {
        console.error("User data not found in local storage");
      }
    } catch (err) {
      console.error("Error updating user role:", err.response.data.message);
    }
  };


  let profileMenuItems = [
    {
      label: "Profile",
      to: "/myaccount",
      render: () => (
        <Link
          to="/myaccount"
          className="p-menuitem-link"
          style={{
            display: "flex",
            gap: "8px",
            padding: "8px",
            flexGrow: "1",
          }}
        >
          <i className={`fi fi-rr-circle-user`}></i>
          <span>Profile</span>
        </Link>
      ),
    },
    {
      label: "Logout",
      icon: "pi pi-sign-out",
      command: handleLogout,
    },
  ];

  if (roleData === "admin" || roleData === "superAdmin") {
    profileMenuItems.unshift({
      label: "Switch Account",
      icon: "fi fi-rr-admin-alt",
      items: [
        {
          label: "User",
          icon: "pi pi-user",
          className: roleChange === "user" ? "p-menuitem-selected" : "",
          command: () => {
            handleChangeAccount("user", ROUTE_CONSTANTS.USER_DASHBOARD, false);
          },
        },
        {
          label: "Admin",
          className: roleChange === "admin" ? "p-menuitem-selected" : "",

          icon: "pi pi-user-edit",
          command: () => {
            handleChangeAccount("admin", ROUTE_CONSTANTS.ADMIN_DASHBOARD, true);
          },
        },
      ],
    });
  }

  return (
    <div className="">
      <div
        onClick={handleMenuToggle}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          cursor: "pointer",
        }}
      >
        <span
          style={{
            fontWeight: "500",
            lineHeight: "1.2",
            textTransform: "capitalize",
          }}
        >
          {loading ? (
            <div>
              <Skeleton width="5rem" className="mb-2"></Skeleton>
            </div>
          ) : error ? (
            "Error"
          ) : (
            <> {fullName}
            </>
          )}
        </span>
        <img
          src={ProfileImg}
          alt="Profile"
          style={{ width: "35px", height: "35px", borderRadius: "50%" }}
        />
      </div>
      <Menu
        model={profileMenuItems.map((item) => ({
          label: item.render ? (
            item.render()
          ) : (
            <div
              onClick={item.command}
              className="p-menuitem-link"
              style={{
                display: "flex",
                gap: "8px",
                padding: "8px",
                flexGrow: "1",
                cursor: "pointer",
              }}
            >
              <i className={`pi ${item.icon}`}></i>
              <span>{item.label}</span>
            </div>
          ),
          items: item.items,
        }))}
        ref={menu}
        popup
        className="profile-menu"
      />
    </div>
  );
};

export default MenuProfileView;
