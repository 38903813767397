import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { PAY_HEAD_API } from "../../constants/api";
import axios from "axios";

const PayHead = () => {
  const [payHeads, setPayHeads] = useState([]);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [payHeadToDelete, setPayHeadToDelete] = useState(null);
  const [newPayHead, setNewPayHead] = useState({
    payhead_id: null,
    payhead_name: "",
    payhead_desc: "",
    payhead_type: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    payhead_name: "",
    payhead_desc: "",
    payhead_type: "",
  });
  const [selectedPayHead, setSelectedPayHead] = useState(null);
  const [editData, setEditData] = useState(null);
  const getStatusColor = (rowData) => {
    switch (rowData.payhead_type) {
      case "earnings":
        return "#16A34A";
      case "deductions":
        return "#DC2626";
      default:
        return "transparent";
    }
  };
  const deletePayHead = (rowData) => {
    setPayHeadToDelete(rowData);
    setDisplayConfirmation(true);
  };

  const hideConfirmationModal = () => {
    setPayHeadToDelete(null);
    setDisplayConfirmation(false);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(
        PAY_HEAD_API.DELETE_PAYHEAD(payHeadToDelete.payhead_id)
      );

      if (response.status === 200) {
        setPayHeads((prevPayHeads) =>
          prevPayHeads.filter(
            (payHead) => payHead.payhead_id !== payHeadToDelete.payhead_id
          )
        );
        getAllPayHeads();
      } else {
        console.error(
          `Failed to delete Pay Head ${payHeadToDelete.payhead_id}`
        );
      }
    } catch (error) {
      console.error("Error deleting pay head:", error);
    } finally {
      hideConfirmationModal();
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex items-center">
        <Button
          tooltip="Edit"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-pencil"
          className="p-button-rounded p-mr-2"
          onClick={() => editPayHead(rowData)}
          //   onClick={() => openEditModal(rowData)}
          style={{ marginRight: "10px", background: "#1677FF", border: "none" }}
        />
        <Button
          tooltip="Delete"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => deletePayHead(rowData)}
        />
      </div>
    );
  };

  const addPayHead = (rowData) => {
    setNewPayHead({
      payhead_id: rowData ? rowData.payhead_id : null,
      payhead_name: rowData ? rowData.payhead_name : "",
      payhead_desc: rowData ? rowData.payhead_desc : "",
      payhead_type: rowData ? rowData.payhead_type : "",
    });
    setDisplayDialog(true);
  };

  useEffect(() => {
    getAllPayHeads();
  }, []);

  const getAllPayHeads = async () => {
    try {
      const response = await axios.get(PAY_HEAD_API.GET_ALL_PAYHEAD);
      setPayHeads(response.data);
    } catch (error) {
      console.error("Error fetching pay heads:", error);
    }
  };

  const savePayHead = async () => {
    const errors = {};
    if (!newPayHead.payhead_name) {
      errors.payhead_name = "Head Name is required.";
    }
    if (!newPayHead.payhead_desc) {
      errors.payhead_desc = "Description is required.";
    }
    if (!newPayHead.payhead_type) {
      errors.payhead_type = "Head Type is required.";
    }

    setValidationErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      if (newPayHead.payhead_id) {
        await axios.put(
          PAY_HEAD_API.EDIT_PAYHEAD(newPayHead.payhead_id),
          newPayHead
        );
        getAllPayHeads();
      } else {
        const response = await axios.post(
          PAY_HEAD_API.GET_ALL_PAYHEAD,
          newPayHead
        );
        setPayHeads([...payHeads, response.data]);
        getAllPayHeads();
      }

      setDisplayDialog(false);
      setNewPayHead({
        payhead_id: null,
        payhead_name: "",
        payhead_desc: "",
        payhead_type: "",
      });
    } catch (error) {
      console.error("Error saving pay head:", error);
    }
  };
  const editPayHead = (rowData) => {
    setSelectedPayHead(rowData);
    addPayHead(rowData);
  };

  const cancelPayHead = () => {
    setDisplayDialog(false);
  };
  const headTypes = [
    { label: "Earnings", value: "earnings" },
    { label: "Deductions", value: "deductions" },
  ];
  const [serialNumbers, setSerialNumbers] = useState([]);

  useEffect(() => {
    setSerialNumbers(
      Array.from({ length: payHeads.length }, (_, index) => index + 1)
    );
  }, [payHeads]);

  return (
    <div>
      <div className="md:flex block justify-content-between mt-2">
        <div className="ml-2">
          <h2>Pay Heads</h2>
        </div>
        <div className="md:col-3 col-12">
          <div className="p-inputgroup">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="flex justify-content-end">
          <i
            class="fi fi-sr-square-plus"
            onClick={addPayHead}
            style={{
              color: "#6366F1",
              fontSize: "40px",
              marginRight: "9px",
            }}
          ></i>
        </div>
        <DataTable
          value={payHeads}
          globalFilter={globalFilter.trim() ? globalFilter : null}
          paginator
          rows={7}
          removableSort
        >
          <Column
            header="#"
            body={(rowData) => <span>{payHeads.indexOf(rowData) + 1}</span>}
          ></Column>
          {/* <Column field="payhead_id" header="ID"></Column> */}
          <Column field="payhead_name" header="Head Name"></Column>
          <Column field="payhead_desc" header="Description"></Column>
          <Column
            field="payhead_type"
            header="Head Type"
            body={(rowData) => (
              <span
                style={{
                  backgroundColor: getStatusColor(rowData),
                  padding: "6px 12px",
                  borderRadius: "4px",
                  color: "#fff",
                }}
              >
                {typeof rowData.payhead_type === "object"
                  ? rowData.payhead_type.label
                  : rowData.payhead_type}
              </span>
            )}
            sortable
          ></Column>
          <Column header="Actions" body={actionTemplate}></Column>
        </DataTable>

        <Dialog
          visible={displayDialog}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          header={selectedPayHead ? "Edit Pay Head" : "Add Pay Head"}
          modal
          onHide={cancelPayHead}
        >
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="payhead_name">Head Name</label>
            <InputText
              id="payhead_name"
              value={newPayHead.payhead_name}
              onChange={(e) =>
                setNewPayHead({
                  ...newPayHead,
                  payhead_name: e.target.value,
                })
              }
            />
            <small className="p-error">{validationErrors.payhead_name}</small>
          </div>
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="payhead_description">Description</label>
            <InputText
              id="payhead_description"
              value={newPayHead.payhead_desc}
              onChange={(e) =>
                setNewPayHead({
                  ...newPayHead,
                  payhead_desc: e.target.value,
                })
              }
            />
            <small className="p-error">{validationErrors.payhead_desc}</small>
          </div>
          <div className="p-field p-col-12 p-md-6 w-full">
            <label htmlFor="payhead_type">Head Type</label>
            <Dropdown
              id="payhead_type"
              options={headTypes}
              style={{ width: "100%" }}
              value={newPayHead.payhead_type}
              onChange={(e) =>
                setNewPayHead({
                  ...newPayHead,
                  payhead_type: e.value,
                })
              }
              placeholder="Select Head Type"
            />
            <small className="p-error">{validationErrors.payhead_type}</small>
          </div>

          <div className="p-dialog-footer mt-4 flex justify-content-end">
            <Button
              label="Cancel"
              className="p-button-danger"
              onClick={cancelPayHead}
            />
            <Button
              label="Save"
              className=""
              onClick={savePayHead}
            />
          </div>
        </Dialog>
        <Dialog
          visible={displayConfirmation}
          style={{ width: "50vw" }}
          breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          onHide={hideConfirmationModal}
          header="Confirmation"
          footer={
            <div>
              <Button
                style={{ color: "white", background: "red" }}
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideConfirmationModal}
              />
              <Button
                style={{ color: "white", background: "#22c55e" }}
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={confirmDelete}
              />
            </div>
          }
        >
          Are you sure you want to delete the payroll head data?
        </Dialog>
      </div>
    </div>
  );
};

export default PayHead;
