import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import {
  ADMIN_LEAVE_API,
  COMP_OFF_ADMIN_API,
  PROJECT_API,
} from "../../constants/api";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import moment from "moment";

export default function CompOff() {
  const toast = useRef(null);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [noofDays, setNoOfDays] = useState(0);
  const [statusOptions, setStatusOptions] = useState([
    { label: "Pending", value: "pending" },
    { label: "Approved", value: "approved" },
    { label: "Rejected", value: "rejected" },
  ]);
  const showToast = (severity, detail) => {
    toast.current.show({ severity, detail });
  };

  const fetchLeaveRequests = async () => {
    try {
      const response = await axios.get(COMP_OFF_ADMIN_API.COMPOFF_ALL_GET);
      if (response.status === 200) {
        const data = response.data;
        const sortedLeaveRequests = data.sort((a, b) => {
          if (a.status === "pending" && b.status !== "pending") return -1;
          if (a.status !== "pending" && b.status === "pending") return 1;
          return 0;
        });

        setLeaveRequests(sortedLeaveRequests);
      } else {
        console.error("Error fetching data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    if (selectedLeaveRequest?.fromdate && selectedLeaveRequest?.todate) {
      const fromDate = new Date(selectedLeaveRequest.fromdate);
      const toDate = new Date(selectedLeaveRequest.todate);
      const timeDifference = toDate.getTime() - fromDate.getTime();
      const calculatedNoOfDays = Math.ceil(
        timeDifference / (1000 * 3600 * 24) + 1
      );
      setNoOfDays(calculatedNoOfDays || 1);
    }
  }, [selectedLeaveRequest?.fromdate, selectedLeaveRequest?.todate]);

  const handleFromDateChange = (e) => {
    const newFromDate = e.target.value;
    setSelectedLeaveRequest((prev) => ({
      ...prev,
      fromdate: newFromDate,
      todate: newFromDate,
    }));
  };

  useEffect(() => {
    fetchLeaveRequests();
  }, []);

  const handleEdit = (rowData) => {
    setSelectedLeaveRequest(rowData);
    setShowEditModal(true);
  };

  const handleSaveLeaveType = async () => {
    try {
      const response = await axios.put(
        COMP_OFF_ADMIN_API.COMP_OFF_UPDATE(selectedLeaveRequest.id),
        {
          fromdate: selectedLeaveRequest.fromdate,
          todate: selectedLeaveRequest.todate,
          reason: selectedLeaveRequest.reason,
          status: selectedLeaveRequest.status,
        }
      );

      if (response.status === 200) {
        const updatedLeaveRequests = leaveRequests.map((request) =>
          request.empid === selectedLeaveRequest.empid
            ? { ...request, leavetype: selectedLeaveRequest.leavetype }
            : request
        );
        setLeaveRequests(updatedLeaveRequests);
        setShowEditModal(false);
        fetchLeaveRequests();
        showToast("success", "Leave request edited successfully");
      } else {
        console.error("Error updating leave type:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating leave type:", error.message);
    }
  };

  const getStatusColor = (rowData) => {
    switch (rowData.status) {
      case "pending":
        return {
          borderColor: "#91CAFF",
          backgroundColor: "#E6F4FF",
          color: "#4280E3",
        };
      case "approved":
        return {
          borderColor: "#B7EB8F",
          backgroundColor: "#F6FFED",
          color: "#389E0D",
        };
      case "rejected":
        return {
          borderColor: "#FF3D32",
          backgroundColor: "#FAFAFA",
          color: "#FF3D32",
        };
      default:
        return {
          borderColor: "#ffbf00",
          backgroundColor: "#E6F4FF",
          color: "#4280E3",
        };
    }
  };

  const handleApprove = async (rowData) => {
    try {
      const response = await axios.patch(
        COMP_OFF_ADMIN_API.COMP_OFF_APPROVAL(rowData.id),
        { status: "approved" }
      );

      if (response.status === 200) {
        rowData.status = "rejected";
        setLeaveRequests([...leaveRequests]);
        fetchLeaveRequests();
      } else {
        console.error("Error updating status:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };

  const handleReject = async (rowData) => {
    try {
      const response = await axios.patch(
        COMP_OFF_ADMIN_API.COMP_OFF_APPROVAL(rowData.id),
        { status: "rejected" }
      );

      if (response.status === 200) {
        rowData.status = "rejected";
        setLeaveRequests([...leaveRequests]);
        fetchLeaveRequests();
      } else {
        console.error("Error updating status:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };

  const actionTemplate = (rowData) => {
    const isApproved = rowData.status === "approved";
    const isRejected = rowData.status === "rejected";

    return (
      <div className="flex align-items-center">
        {!isApproved && !isRejected ? (
          <>
            <Button
              tooltip="Approve"
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
              }}
              className="icon-button"
              icon="pi pi-check"
              style={{
                border: "none",
                background: "#22c55e",
                borderRadius: "10px",
                padding: "10px",
                fontSize: "10px",
                color: "#fff",
              }}
              onClick={() => handleApprove(rowData)}
            />
            <Button
              tooltip="Reject"
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
              }}
              className="icon-button ml-2"
              icon="pi pi-times"
              style={{
                border: "none",
                background: "#ff3d32",
                borderRadius: "10px",
                padding: "10px",
                fontSize: "10px",
                color: "#fff",
              }}
              onClick={() => handleReject(rowData)}
            />
          </>
        ) : null}
        <Button
          tooltip="Edit"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          className="icon-button ml-2"
          icon="pi pi-pencil"
          style={{
            border: "none",
            background: "rgb(22,119,255)",
            borderRadius: "10px",
            padding: "10px",
            fontSize: "10px",
            color: "#fff",
          }}
          onClick={() => handleEdit(rowData)}
        />
      </div>
    );
  };

  return (
    <div className="CompOff">
      <Toast ref={toast} />
      <div className="md:flex block justify-content-between">
        <h3 className="my-3"></h3>
        <div className="md:col-4 lg:col-2 col-12">
          <div className="p-inputgroup flex-1">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <DataTable
        value={leaveRequests}
        scrollable
        className=""
        paginator
        rows={6}
        globalFilter={globalFilter.trim() ? globalFilter : null}
      >
        <Column
          field="Employeename"
          header="Employee Name"
          body={(rowData) => `${rowData.firstname} ${rowData.lastname}`}
        />
        <Column
          field="userid"
          header="Emp ID"
          style={{ minWidth: "90px" }}
        ></Column>
          <Column
          field="fromdate"
          header="Leave From"
          style={{ minWidth: "105px" }}
          body={(rowData) => moment(rowData.fromdate).format("DD-MM-YYYY")}
        ></Column>
        <Column
          field="todate"
          header="Leave To"
          style={{ minWidth: "105px" }}
          body={(rowData) => moment(rowData.todate).format("DD-MM-YYYY")}
        ></Column>
        <Column
          field="reason"
          header="Reason"
          body={(rowData) => (
            <div id={`reason_${rowData.id}`} className="tooltip-container">
              {rowData.reason.length > 20
                ? `${rowData.reason.slice(0, 20)}...`
                : rowData.reason}
              <Tooltip
                target={`#reason_${rowData.id}`}
                position="left"
                style={{ width: "300px" }}
              >
                {rowData.reason}
              </Tooltip>
            </div>
          )}
          style={{ minWidth: "90px" }}
        />
        <Column
          field="status"
          header="Status"
          style={{ minWidth: "80px" }}
          body={(rowData) => (
            <span
              style={{
                border: "2px solid",
                ...getStatusColor(rowData),
                padding: "6px 12px",
                borderRadius: "4px",
              }}
            >
              {rowData.status.charAt(0).toUpperCase() +
                rowData.status.slice(1).toLowerCase()}
            </span>
          )}
        />

        <Column
          field="action"
          header="Actions"
          body={actionTemplate}
          style={{ minWidth: "90px" }}
        ></Column>
      </DataTable>
      {showEditModal && (
        <Dialog
          visible={showEditModal}
          onHide={() => setShowEditModal(false)}
          header="Edit Comp-Off Request"
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div className="">
            <label
              htmlFor="editFromDate"
              style={{
                fontWeight: 600,
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              Start Date
            </label>
            <input
              id="editFromDate"
              type="date"
              value={selectedLeaveRequest?.fromdate || ""}
              onChange={handleFromDateChange}
              style={{ marginBottom: "10px", width: "100%" }}
            />
          </div>
          <div>
            <label
              htmlFor="editToDate"
              style={{
                fontWeight: 600,
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              To Date
            </label>
            <input
              id="editToDate"
              type="date"
              value={selectedLeaveRequest?.todate || ""}
              onChange={(e) => {
                setSelectedLeaveRequest({
                  ...selectedLeaveRequest,
                  todate: e.target.value,
                });
                const fromDate = new Date(selectedLeaveRequest?.fromdate);
                const toDate = new Date(e.target.value);
                const differenceInTime = toDate.getTime() - fromDate.getTime();
                const differenceInDays = differenceInTime / (1000 * 3600 * 24);
                setNoOfDays(differenceInDays);
              }}
              min={selectedLeaveRequest?.fromdate || ""}
            />
          </div>
          <div>
            <label
              htmlFor="editStatus"
              style={{
                fontWeight: 600,
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              Status
            </label>
            <Dropdown
              id="editStatus"
              options={statusOptions}
              value={selectedLeaveRequest?.status || ""}
              onChange={(e) =>
                setSelectedLeaveRequest({
                  ...selectedLeaveRequest,
                  status: e.value,
                })
              }
              placeholder="Select Status"
              style={{ marginBottom: "10px", fontWeight: 600, width: "100%" }}
            />
          </div>
          <div>
            <label
              htmlFor="editReason"
              style={{
                fontWeight: 600,
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              Reason
            </label>
            <InputTextarea
              id="editReason"
              value={selectedLeaveRequest?.reason || ""}
              onChange={(e) =>
                setSelectedLeaveRequest({
                  ...selectedLeaveRequest,
                  reason: e.target.value,
                })
              }
              style={{
                marginBottom: "10px",
                width: "100%",
                height: "100px",
                resize: "none",
              }}
            />
          </div>
          <div
            className="p-dialog-footer text-center flex justify-content-end"
            style={{ padding: 0, marginTop: "4px" }}
          >
            <Button
              style={{
                padding: "12px 12px",
                fontWeight: 500,
                fontSize: "13px",
              }}
              label="Cancel"
              className="p-button-danger"
              onClick={() => setShowEditModal(false)}
            />
            <Button
              style={{
                padding: "10px 10px",
                fontWeight: 500,
                fontSize: "13px",
              }}
              label="Submit"
              className="p-button-success"
              onClick={handleSaveLeaveType}
            />
          </div>
        </Dialog>
      )}
    </div>
  );
}