import React, { useState, useEffect, useRef } from "react";
import { Avatar } from "primereact/avatar";
import TodayAttendance from "./TodayAttendance";
import { Button } from "primereact/button";
import {
  EMPLOYEE_API,
  PROJECT_API,
  ADMIN_LEAVE_API,
  TASK_API,
  APPOINTMENTS_API,
  ATTENDANCE_API,
} from "../../constants/api";
import axios from "axios";
import moment from "moment";
import Appointments from "./Appointments";
import { Menu } from "primereact/menu";
import EditAppointmentDialog from "./EditAppointment";
import ConfirmationDialog from "./ConfirmationDialog ";
import RescheduleDialog from "./RescheduleDialog";
import { AvatarGroup } from "primereact/avatargroup";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { ScrollPanel } from "primereact/scrollpanel";
import BirthdayWish from "./BirthdayWish";

const Dashboard = () => {
  const [products, setProducts] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [projects, setProjects] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [inactiveEmployees, setInactiveEmployees] = useState([]);
  const [totalProjects, setTotalProjects] = useState([]);
  const [pendingLeave, setPendingLeave] = useState([]);
  const [taskDetails, setTaskDetails] = useState([]);
  const [totalTasks, setTotalTasks] = useState([]);
  const [showAddAppointment, setShowAddAppointment] = useState(false);
  const [appointmentData, setAppointmentData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [attendanceData, setAttendanceData] = useState([]);
  const [menuRefs, setMenuRefs] = useState([]);
  const [resignedCount, setResignedCount] = useState(0);
  const [terminatedCount, setTerminatedCount] = useState(0);
  const [abscondCount, setAbscondCount] = useState(0);
  const [totalInactiveEmployees, setTotalInactiveEmployees] = useState(0);
  const userData = localStorage.getItem("user");
  const user = JSON.parse(userData);
  const { firstname, lastname } = user.data;
  const [rescheduleDialogVisible, setRescheduleDialogVisible] = useState(false);

  const handleReschedule = (index) => {
    const selectedAppointment = appointmentData[index];
    setSelectedAppointment(selectedAppointment);
    setRescheduleDialogVisible(true);
  };

  const handleRescheduleSubmit = (index) => { };

  useEffect(() => {
    setMenuRefs(
      Array(appointmentData.length)
        .fill()
        .map((_, i) => React.createRef())
    );
  }, [appointmentData]);
  const toast = useRef(null);

  const items = appointmentData.map((appointment, index) => ({
    // label: "Options",
    items: [
      {
        label: "Edit",
        icon: "pi pi-pencil",
        command: () => handleEditAppointment(index),
      },
      {
        label: "Delete",
        icon: "pi pi-trash",
        command: () => handleDeleteAppointment(index),
      },
      {
        label: "Unable to Attend",
        icon: "fi fi-rr-time-delete",
        command: () => handleUnableToAttend(index),
      },
      {
        label: "Cancel Appointment",
        icon: "pi pi-times-circle",
        command: () => handleCancelAppointment(index),
      },
      {
        label: "Reschedule",
        icon: "fi fi-rr-time-watch-calendar",
        command: () => handleReschedule(index),
      },
    ],
  }));

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteAppointmentId, setDeleteAppointmentId] = useState(null);

  const showToast = (severity, detail) => {
    toast.current.clear();
    toast.current.show({ severity, detail });
  };

  const fetchAttendanceData = () => {
    axios
      .get(ATTENDANCE_API.ATTENDANCE_TODAY_PRESENT)
      .then((response) => {
        setAttendanceData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  };

  useEffect(() => {
    fetchAttendanceData();
  }, []);

  const handleCancelAppointment = async (index) => {
    const selectedAppointment = appointmentData[index];
    setSelectedAppointment(selectedAppointment);
    try {
      await axios.put(
        APPOINTMENTS_API.APPOINTMENTS_DEACTIVATE(
          selectedAppointment.appointment_id
        )
      );
      setAppointmentData((prevData) =>
        prevData.filter(
          (appointment) => appointment.id !== selectedAppointment.id
        )
      );
      reFetch();
      showToast("success", "Appointment Cancelled successfully");
    } catch (error) {
      console.error("Error canceling appointment:", error);
      showToast("error", "Failed to cancel appointment");
    }
  };

  const handleUnableToAttend = async (index) => {
    const selectedAppointment = appointmentData[index];
    try {
      const userString = localStorage.getItem("user");
      const user = JSON.parse(userString);
      if (!user || !user.data || !user.data.id) {
        console.error(
          "User object is null, undefined, or does not contain an id property."
        );
        return;
      }
      const user_id = user.data.id;
      await axios.post(
        APPOINTMENTS_API.APPOINTMENTS_ABSENCES(
          selectedAppointment.appointment_id
        ),
        { user_id }
      );
      reFetch();
      showToast("success", "Absence added successfully");
    } catch (error) {
      console.error("Error adding absence:", error.response.data.message);
      showToast("error", "Absence already exists");
    }
  };

  const handleDeleteAppointment = (index) => {
    const selectedAppointment = appointmentData[index];
    setSelectedAppointment(selectedAppointment);
    setDeleteAppointmentId(selectedAppointment.appointment_id);
    setShowConfirmation(true);
  };

  const confirmDeleteAppointment = async () => {
    try {
      await axios.delete(
        APPOINTMENTS_API.APPOINTMENTS_DELETE(deleteAppointmentId)
      );
      setAppointmentData((prevData) =>
        prevData.filter((appointment) => appointment.id !== deleteAppointmentId)
      );
      reFetch();
    } catch (error) {
      console.error("Error deleting appointment:", error);
    } finally {
      setDeleteAppointmentId(null);
      setShowConfirmation(false);
    }
  };

  const handleEditAppointment = (index) => {
    const selectedAppointment = appointmentData[index];
    setSelectedAppointment(selectedAppointment);
    setShowAddAppointment(true);
    setIsEditing(true);
  };

  const handleAddButtonClick = () => {
    setSelectedAppointment(null);
    setShowAddAppointment(true);
    setIsEditing(false);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.All_USERS);
      const usersData = response.data.usersData;
      setProducts(usersData);
      setEmployees(usersData);
      const totalUserCount = usersData.length;
      setTotalUsers(totalUserCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const reFetch = () => {
    fetchAppointmentData();
  };
  const totalLeaves = async () => {
    try {
      const response = await axios.get(ADMIN_LEAVE_API.GET_ALL_LEAVE);
      const pendingLeaves = response.data.leaveData.filter(
        (leave) => leave.status === "pending"
      );
      setPendingLeave(pendingLeaves.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    totalLeaves();
  }, []);

  const fetchInactiveEmployeesData = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.INACTIVE_USERS);
      const usersData = response.data.usersData;
      const resignedCount = usersData.filter(
        (user) => user.reason_of_leaving === "Resigned"
      ).length;
      const terminatedCount = usersData.filter(
        (user) => user.reason_of_leaving === "Terminated"
      ).length;
      const abscondCount = usersData.filter(
        (user) => user.reason_of_leaving === "Absconded"
      ).length;
      const totalInactiveEmployees = usersData.length;
      setInactiveEmployees(usersData);
      setResignedCount(resignedCount);
      setTerminatedCount(terminatedCount);
      setAbscondCount(abscondCount);
      setInactiveEmployees(usersData);
      setTotalInactiveEmployees(totalInactiveEmployees);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchInactiveEmployeesData();
  }, []);

  const fetchProjectData = async () => {
    try {
      const response = await axios.get(PROJECT_API.GET_ALL_PROJECTS);
      const projectsData = response.data;
      const totalProjectCount = projectsData.length;
      const activeProjects = projectsData.filter(
        (project) => project.project_status === "Active"
      );
      const completedProjects = projectsData.filter(
        (project) => project.project_status === "Completed"
      );
      const runningProjects = projectsData.filter(
        (project) => project.project_status === "Running"
      );
      const canceledProjects = projectsData.filter(
        (project) => project.project_status === "Cancelled"
      );
      const InitializedProjects = projectsData.filter(
        (project) => project.project_status === "Initialized"
      );

      setProjects(projectsData);
      setTotalProjects({
        active: activeProjects.length,
        completed: completedProjects.length,
        running: runningProjects.length,
        Cancelled: canceledProjects.length,
        totalProjectCount: totalProjectCount,
        Initialized: InitializedProjects.length,
      });
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchProjectData();
  }, []);

  const fetchTaskData = async () => {
    try {
      const response = await axios.get(TASK_API.GET_ALL_TASK);
      const tasksData = response.data;
      const totalTaskCount = tasksData.length;
      const currentDate = new Date();

      const pendingTasks = tasksData.filter(
        (task) =>
          ["In Progress"].includes(task.Status) &&
          new Date(task.DueDate) <= currentDate
      );
      const inProgressTasks = tasksData.filter(
        (task) => task.Status === "In Progress"
      );

      const completedTasks = tasksData.filter(
        (task) => task.Status === "Completed"
      );
      const newTask = tasksData.filter((task) => task.Status === "Initialized");
      const returnedTask = tasksData.filter(
        (task) => task.Status === "Returned"
      );
      const resolvedTask = tasksData.filter(
        (task) => task.Status === "Resolved"
      );

      setTaskDetails(tasksData);
      setTotalTasks({
        InProgress: inProgressTasks.length,
        pending: pendingTasks.length,
        completed: completedTasks.length,
        totalTaskCount: totalTaskCount,
        Initialized: newTask.length,
        Returned: returnedTask.length,
        Resolved: resolvedTask.length,
      });
    } catch (error) {
      console.error("Error fetching task data:", error);
    }
  };

  useEffect(() => {
    fetchTaskData();
  }, []);

  const fetchAppointmentData = async () => {
    try {
      const response = await axios.get(APPOINTMENTS_API.APPOINTMENTS_FETCH);
      const appointmentsData = response.data;
      const currentDate = moment();
      const startDate = currentDate.clone().subtract(2, 'days').startOf('day');
      const endDate = currentDate.clone().endOf('day');
      const currentAppointments = appointmentsData.filter((appointment) =>
        moment(appointment.appointment_date).isBetween(startDate, endDate, null, '[]')
      );
      setAppointmentData(currentAppointments);
    } catch (error) {
      console.error("Error fetching appointments data:", error);
    }
  };
  useEffect(() => {
    fetchAppointmentData();
  }, []);

  const currentDateMeetings = taskDetails.filter((task) => {
    const currentDate = moment().format("YYYY-MM-DD");
    return moment(task.MeetingSheduleDate).isSame(currentDate, "day");
  });

  const [selectedAppointmentDetails, setSelectedAppointmentDetails] =
    useState(null);
  const [appointmentDetailView, setAppointmentDetailView] = useState(false);

  const showAppointmentDetails = (appointment) => {
    setSelectedAppointmentDetails(appointment);
    setAppointmentDetailView(true);
  };

  const hideAppointmentDetails = () => {
    setSelectedAppointmentDetails(null);
    setAppointmentDetailView(false);
  };
  const [selectedMeetingDetails, setSelectedMeetingDetails] = useState(null);
  const [meetingDetailView, setMeetingDetailView] = useState(false);

  const showMeetingDetails = (meeting) => {
    setSelectedMeetingDetails(meeting);
    setMeetingDetailView(true);
  };

  const hideMeetingDetails = () => {
    setSelectedMeetingDetails(null);
    setMeetingDetailView(false);
  };
  return (
    <div>
      {" "}
      <div className="flex justify-content-between mb-3">
        <h2
          className="my-3"
          style={{ textTransform: "capitalize" }}
        >{`${firstname} ${lastname}`}</h2>
        <Toast ref={toast} />
      </div>
      <div className="dashboard">
        <BirthdayWish />
        <div className="grid">
          <div className="md:col-6 lg:col-3 col-12">
            <div className="card Dcard1">
              <Link to="/AllEmployee" style={{ color: "black" }}>
                <div className="dash-content">
                  <div className="title">
                    <h3>Employee</h3>
                  </div>
                  <div className="Card-grid">
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Total Employees</h5>
                        <p>{totalUsers}</p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="c-left flex">
                        <h5>To be Onboarded</h5>
                        <p>0</p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Onboarded this year</h5>
                        <p>{totalUsers}</p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Intern Resigned</h5>
                        <p>0</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Employee Resigned</h5>
                        <p>{resignedCount}</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Terminated</h5>
                        <p>{terminatedCount}</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Absconded</h5>
                        <p>{abscondCount}</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Total Resigned this year</h5>
                        <p>{totalInactiveEmployees}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="md:col-6 lg:col-3 col-12">
            <div className="card Dcard2">
              <Link to="/AttendanceSheet" style={{ color: "black" }}>
                <div className="dash-content">
                  <div className="title">
                    <h3>Attendance</h3>
                  </div>
                  <div className="Card-grid">
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Total Employee Present</h5>
                        <p>{attendanceData.totalPresentEmployees}</p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Total Employee Leave</h5>
                        <p>{attendanceData.totalLeaveEmployees}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="md:col-6 lg:col-3 col-12">
            <div className="card Dcard3">
              <Link to="/Project" style={{ color: "black" }}>
                <div className="dash-content">
                  <div className="title">
                    <h3>Project</h3>
                  </div>
                  <div className="Card-grid">
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Pending Projects</h5>
                        <p>{totalProjects.Initialized}</p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="c-left flex">
                        <h5>InProgress Projects</h5>
                        <p>{totalProjects.running}</p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="c-left flex">
                        <h5> Active Projects</h5>
                        <p>{totalProjects.active}</p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Completed Projects</h5>
                        <p>{totalProjects.completed}</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Total Projects</h5>
                        <p>{totalProjects.totalProjectCount}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="md:col-6 lg:col-3 col-12">
            <div className="card Dcard4">
              <Link to="/AllTasks" style={{ color: "black" }}>
                <div className="title">
                  <h3>Task</h3>
                </div>
                <div className="dash-content">
                  <div className="Card-grid">
                    <div className="flex">
                      <div className="c-left flex">
                        <h5 className="">New Task</h5>
                        <p>{totalTasks.Initialized}</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="c-left flex">
                        <h5 className="">Pending Task</h5>
                        <p>{totalTasks.pending}</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>In Progress Task</h5>
                        <p>{totalTasks.InProgress}</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Completed Task</h5>
                        <p>{totalTasks.completed}</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Returned Task</h5>
                        <p>{totalTasks.Returned}</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Resolved Task</h5>
                        <p>{totalTasks.Resolved}</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Total Task</h5>
                        <p>{totalTasks.totalTaskCount}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="md:col-6 col-12">
            <div className="card Dcard5">
              <Link to="/AllTasks" style={{ color: "black" }}>
                <div className="title">
                  <h3>Meetings</h3>
                </div>
              </Link>
              <ScrollPanel
                style={{ height: "160px" }}
                className="appointmentBar"
              >
                <div className="dash-content">
                  <div className="">
                    {currentDateMeetings.map((meeting, index) => (
                      <div key={index} className="col-12">
                        <div className="c-left">
                          <div className="grid">
                            <div
                              className="col-5 cursor-pointer capitalize"
                              onClick={() => showMeetingDetails(meeting)}
                            >
                              <div>{meeting.meeting_title}</div>
                            </div>
                            <div className="col-3">
                              <div className="flex">
                                <AvatarGroup style={{ marginLeft: "10px" }}>
                                  {meeting?.participants
                                    ?.split(",")
                                    .slice(0, 4)
                                    .map((memberId, index) => {
                                      const employee = employees.find(
                                        (emp) => emp.id === memberId.trim()
                                      );
                                      if (employee) {
                                        return (
                                          <Avatar
                                            key={index}
                                            id={`avatar-${index}`}
                                            label={`${employee.firstname.charAt(
                                              0
                                            )}${employee.lastname.charAt(0)}`}
                                            title={`${employee.firstname} ${employee.lastname}`}
                                            size="small"
                                            style={{
                                              border: "transparent",
                                              backgroundColor:
                                                "rgb(48 212 125)",
                                              color: "#fff",
                                              marginLeft: "-8px",
                                              borderRadius: "50%",
                                              cursor: "pointer",
                                            }}
                                          />
                                        );
                                      }
                                      return null;
                                    })}
                                  {meeting?.participants?.split(",").length >
                                    4 && (
                                      <Avatar
                                        label={`+${meeting.participants.split(",").length -
                                          4
                                          }`}
                                        shape="circle"
                                        size="small"
                                        style={{
                                          border: "transparent",
                                          background: "#eee",
                                        }}
                                      />
                                    )}
                                </AvatarGroup>
                                {Array.isArray(meeting.participants) &&
                                  meeting.participants.length > 4 && (
                                    <span style={{ marginLeft: "-4px" }}>
                                      4+
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="col-1">
                              <p style={{ fontSize: "12px", fontWeight: 500 }}>
                                {meeting.start_time}
                              </p>
                            </div>
                            <div className="col-1">
                              <p style={{ fontSize: "12px", fontWeight: 500 }}>
                                {meeting.end_time}
                              </p>
                            </div>
                            <div className="col-2">
                              <p style={{ fontSize: "12px", fontWeight: 500 }}>
                                {moment(meeting.MeetingSheduleDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </ScrollPanel>
            </div>
          </div>
          {selectedMeetingDetails && (
            <Dialog
              visible={meetingDetailView}
              onHide={hideMeetingDetails}
              position="center"
              draggable={false}
              header={
                <div className="capitalize">
                  <i className="fi fi-br-time-check"></i>
                  {" " + selectedMeetingDetails.meeting_title}
                </div>
              }
              style={{ width: "40vw" }}
              breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
              {selectedMeetingDetails && (
                <div>
                  <div className="flex gap-2 mt-2 mb-3 ml-1">
                    <div className="flex-1 flex  justify-content-start">
                      {" "}
                      {selectedMeetingDetails.agenda}
                    </div>
                  </div>
                  <div className="p-2">
                    <div className=" mb-1 mt-2 p-2">
                      <span className="col-6">
                        <i class="fi fi-rr-calendar-clock"></i> Date:
                      </span>
                      <span>
                        {moment(
                          selectedMeetingDetails.MeetingSheduleDate
                        ).format("DD-MM-YYYY")}
                      </span>
                    </div>

                    <div className="mb-1 mt-2 p-2">
                      <span className="col-6">
                        <i class="fi fi-rr-hourglass-start"></i> Start Time:
                      </span>
                      <span className="">
                        {selectedMeetingDetails.start_time}
                      </span>{" "}
                    </div>
                    <div className="mb-1 mt-2 p-2">
                      <span className="col-6">
                        <i class="fi fi-rr-hourglass-end"></i> End Time:
                      </span>
                      <span className="">
                        {selectedMeetingDetails.end_time}
                      </span>{" "}
                    </div>
                    <div className="ml-2 mt-2 p-2">
                      <AvatarGroup>
                        <span className="mr-3">
                          <i class="fi fi-rs-users-alt"></i> Team:{" "}
                        </span>
                        {selectedMeetingDetails?.participants
                          ?.split(",")
                          .map((memberId, index) => {
                            const employee = employees.find(
                              (emp) => emp.id === memberId.trim()
                            );
                            if (employee) {
                              return (
                                <Avatar
                                  key={index}
                                  id={`avatar-${index}`}
                                  label={`${employee.firstname.charAt(
                                    0
                                  )}${employee.lastname.charAt(0)}`}
                                  title={`${employee.firstname} ${employee.lastname}`}
                                  size="small"
                                  style={{
                                    border: "transparent",
                                    backgroundColor: "#2196F3",
                                    color: "#ffffff",
                                    marginLeft: "-8px",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                  }}
                                />
                              );
                            }
                            return null;
                          })}
                      </AvatarGroup>
                    </div>
                  </div>
                </div>
              )}
            </Dialog>
          )}
          <div className="md:col-6 col-12">
            <div className="card Dcard6">
              <div className="title flex justify-content-between">
                <h3>Appointments</h3>
                <span
                  className="icon-only-button"
                  onClick={handleAddButtonClick}
                >
                  <i
                    className="pi pi-plus"
                    style={{ width: "50px", textAlign: "center" }}
                  ></i>
                </span>

                {showAddAppointment && !isEditing && (
                  <Appointments
                    visible={showAddAppointment}
                    onHide={() => {
                      setShowAddAppointment(false);
                      setSelectedAppointment(null);
                    }}
                    onSave={(data) => {
                      handleAddButtonClick();
                    }}
                    reFetch={reFetch}
                  />
                )}
                {showAddAppointment && isEditing && (
                  <EditAppointmentDialog
                    visible={showAddAppointment}
                    onHide={() => {
                      setShowAddAppointment(false);
                      setSelectedAppointment(null);
                    }}
                    onSave={(data) => { }}
                    appointmentData={selectedAppointment}
                    reFetch={reFetch}
                  />
                )}
                <ConfirmationDialog
                  visible={showConfirmation}
                  onHide={() => setShowConfirmation(false)}
                  onConfirm={confirmDeleteAppointment}
                />
              </div>
              <ScrollPanel
                style={{ height: "160px" }}
                className="appointmentBar"
              >
                <div className="dash-content">
                  <table className="table">
                    <tbody>
                      {appointmentData.map((appointment, index) => (
                        <tr key={index}>
                          <td
                            style={{ width: "38%" }}
                            onClick={() => showAppointmentDetails(appointment)}
                            className="cursor-pointer capitalize"
                          >
                            {appointment.appointment_title}
                          </td>
                          <td style={{ width: "20%" }}>
                            <div className="flex">
                              <AvatarGroup style={{ marginLeft: "10px" }}>
                                {appointment?.employee_ids
                                  ?.split(",")
                                  .slice(0, 4)
                                  .map((memberId, index) => {
                                    const employee = employees.find(
                                      (emp) => emp.id === memberId.trim()
                                    );
                                    if (employee) {
                                      return (
                                        <Avatar
                                          key={index}
                                          id={`avatar-${index}`}
                                          label={`${employee.firstname.charAt(
                                            0
                                          )}${employee.lastname.charAt(0)}`}
                                          title={`${employee.firstname} ${employee.lastname}`}
                                          size="small"
                                          style={{
                                            border: "transparent",
                                            backgroundColor: "rgb(236 67 171)",
                                            color: "#fff",
                                            marginLeft: "-8px",
                                            borderRadius: "50%",
                                            cursor: "pointer",
                                          }}
                                        />
                                      );
                                    }
                                    return null;
                                  })}
                                {appointment?.employee_ids?.split(",").length >
                                  4 && (
                                    <Avatar
                                      label={`+${appointment.employee_ids.split(",")
                                        .length - 4
                                        }`}
                                      shape="circle"
                                      size="small"
                                      style={{
                                        border: "transparent",
                                        background: "#eee",
                                      }}
                                    />
                                  )}
                              </AvatarGroup>
                              {Array.isArray(appointment.employee_ids) &&
                                appointment.employee_ids.length > 4 && (
                                  <span style={{ marginLeft: "-4px" }}>4+</span>
                                )}
                            </div>
                          </td>
                          <td style={{ width: "20%" }}>
                            {appointment.absences.map((absence, index) => (
                              <div key={index}>
                                {absence.status}-{absence.user_id}
                              </div>
                            ))}
                          </td>{" "}
                          <td style={{ width: "10%" }}>
                            {appointment.appointment_time}
                          </td>
                          <td style={{ width: "20%" }}>
                            {moment(appointment.appointment_date).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td style={{ width: "10%" }}>
                            {appointment.activeStatus}
                          </td>
                          <td style={{ width: "10%" }}>
                            <Menu
                              model={items[index].items}
                              popup
                              ref={menuRefs[index]}
                              id={`popup_menu_left_${index}`}
                            />
                            <Button
                              icon="fi fi-bs-menu-dots-vertical"
                              className="mr-2"
                              onClick={(event) =>
                                menuRefs[index].current.toggle(event)
                              }
                              aria-controls={`popup_menu_left_${index}`}
                              aria-haspopup
                              style={{
                                background: "none",
                                border: "none",
                                color: "black",
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {selectedAppointmentDetails && (
                    <Dialog
                      visible={appointmentDetailView}
                      onHide={hideAppointmentDetails}
                      position="center"
                      draggable={false}
                      header={
                        <div className="capitalize">
                          <i className="fi fi-br-time-check"></i>
                          {" " + selectedAppointmentDetails.appointment_title}
                        </div>
                      }
                      style={{ width: "50vw" }}
                      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                    >
                      {selectedAppointmentDetails && (
                        <div>
                          <div className="flex gap-2 mt-2 mb-3 ml-1">
                            <div className="flex-1 flex  justify-content-start">
                              {" "}
                              {
                                selectedAppointmentDetails.appointment_description
                              }
                            </div>

                            <div className="flex justify-content-end align-items-baseline ">
                              <span
                                className={`border-round-lg bg-blue-400 text-white border-blue-500 px-3 py-1`}
                              >
                                {selectedAppointmentDetails.activeStatus}
                              </span>
                            </div>
                          </div>

                          <div className=" mb-1 mt-2">
                            <span className="col-6">
                              <i class="fi fi-rr-calendar-clock"></i> Date:
                            </span>
                            <span>
                              {moment(
                                selectedAppointmentDetails.appointment_date
                              ).format("DD-MM-YYYY")}
                            </span>
                          </div>

                          <div className=" mb-1 mt-2">
                            <span className="col-6">
                              <i class="fi fi-rr-member-list"></i>  Creator:
                            </span>
                            <span >
                              {
                                `${employees.find(item => item.id ===
                                  selectedAppointmentDetails.appointment_creator
                                )?.firstname}
                                ${employees.find(item => item.id ===
                                  selectedAppointmentDetails.appointment_creator
                                )?.lastname
                                }`

                              }
                            </span>
                          </div>

                          <div className="mb-1 mt-2">
                            <span className="col-6">
                              <i class="fi fi-rs-clock-five"></i> Time:
                            </span>
                            <span className="">
                              {selectedAppointmentDetails.appointment_time}
                            </span>{" "}
                          </div>
                          {selectedAppointmentDetails.absences != "" && (
                            <div className="mt-2">
                              {selectedAppointmentDetails.absences.map(
                                (absence, index) => (
                                  <span key={index} className="">
                                    <span className="col-6">
                                      {" "}
                                      <i class="fi fi-rs-user-xmark"></i>{" "}
                                      {absence.status}:
                                    </span>
                                    <span className="">
                                      {absence.user_id
                                        ?.split(",")
                                        .map((memberId, index) => {
                                          const employee = employees.find(
                                            (emp) => emp.id === memberId.trim()
                                          );
                                          if (employee) {
                                            return (
                                              <span key={index}>
                                                {`${employee.firstname}${employee.lastname}`}
                                              </span>
                                            );
                                          }
                                          return null;
                                        })}
                                    </span>
                                  </span>
                                )
                              )}
                            </div>
                          )}
                          <div className="ml-2 mt-2">
                            <AvatarGroup>
                              <span className="mr-3">
                                <i class="fi fi-rs-users-alt"></i> Team:{" "}
                              </span>
                              {selectedAppointmentDetails?.employee_ids
                                ?.split(",")
                                .map((memberId, index) => {
                                  const employee = employees.find(
                                    (emp) => emp.id === memberId.trim()
                                  );
                                  if (employee) {
                                    return (
                                      <Avatar
                                        key={index}
                                        id={`avatar-${index}`}
                                        label={`${employee.firstname.charAt(
                                          0
                                        )}${employee.lastname.charAt(0)}`}
                                        title={`${employee.firstname} ${employee.lastname}`}
                                        size="small"
                                        style={{
                                          border: "transparent",
                                          backgroundColor: "#2196F3",
                                          color: "#ffffff",
                                          marginLeft: "-8px",
                                          borderRadius: "50%",
                                          cursor: "pointer",
                                        }}
                                      />
                                    );
                                  }
                                  return null;
                                })}
                            </AvatarGroup>
                          </div>
                        </div>
                      )}
                    </Dialog>
                  )}
                </div>
              </ScrollPanel>
              <RescheduleDialog
                visible={rescheduleDialogVisible}
                onHide={() => {
                  setRescheduleDialogVisible(false);
                  setSelectedAppointment(null);
                }}
                onSubmit={handleRescheduleSubmit}
                appointmentData={selectedAppointment}
                reFetch={reFetch}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="">
              <h2 className="my-2">Calendar</h2>
              <TodayAttendance />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
