import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

export default function EmployeeAddPayroll({
  displayAddPayHeadsDialog,
  editMode,
  closeAddEditPayHeadsDialog,
  employeeOptions,
  selectedEmployee,
  handleEmployeeChange,
  selectedEmployeeDetails,
  fromDate,
  setFromDate,
  setToDate,
  toDate,
  earnings,
  handleDeleteEarning,
  earningOptions,
  newEarning,
  setNewEarning,
  totalEarnings,
  deductions,
  handleDeleteDeduction,
  deductionOptions,
  newDeduction,
  setNewDeduction,
  totalDeductions,
  netPay,
  closeAddPayHeadsDialog,
  validateForm,
  addPayHeadsToEmployee,
  handleAddEarning,
  handleAddDeduction,
  totalMissingDays,
  setEarnings,
  setDeductions,
  setEarningIds,
  setDeductionIds,
}) {
  useEffect(() => {
    if (
      selectedEmployeeDetails &&
      selectedEmployeeDetails.salary &&
      earningOptions.length > 0 &&
      !editMode
    ) {
      const getPayHeadNameById = (id, options) => {
        const option = options.find((opt) => opt.value === id);
        return option ? option.label : "";
      };

      const firstPayHeadId = earningOptions[0]?.value || "";
      const deductionPayHeadId = deductionOptions[0]?.value || "";

      const defaultEarning = {
        payhead_name: getPayHeadNameById(firstPayHeadId, earningOptions),
        payhead_amount: selectedEmployeeDetails.salary,
        payhead_id: firstPayHeadId,
      };

      setEarnings([defaultEarning]);
      setEarningIds({ [defaultEarning.payhead_name]: firstPayHeadId });

      const defaultDeduction = {
        payhead_name: getPayHeadNameById(deductionPayHeadId, deductionOptions),
        payhead_amount: 0,
        payhead_id: deductionPayHeadId,
      };

      setDeductions([defaultDeduction]);
      setDeductionIds({ [defaultDeduction.payhead_name]: deductionPayHeadId });
    }
  }, [selectedEmployeeDetails, earningOptions, deductionOptions, editMode]);

  const formatMonthYear = (dateString) => {
    if (!dateString) return "";
    const options = { year: "numeric", month: "long" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  return (
    <div>
      <Dialog
        visible={displayAddPayHeadsDialog}
        style={{ width: "60vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        header={
          editMode ? "Edit Payheads for Employee" : "Add Payheads to Employee"
        }
        modal
        onHide={closeAddEditPayHeadsDialog}
      >
        <div className="md:flex block justify-content-between">
          <div className="p-field w-full mt-1">
            <label htmlFor="employee">Select Employee</label>
            <Dropdown
              id="employee"
              options={employeeOptions}
              value={selectedEmployee}
              onChange={handleEmployeeChange}
              filter
              filterBy="label,value"
              placeholder="Select Employee"
              className="w-full"
              style={{ height: "40px" }}
            />
            {!selectedEmployee && (
              <div style={{ color: "red", marginTop: "5px" }}>
                Please select an employee.
              </div>
            )}
          </div>
          <div className="md:col-3 col-12">
            <div className="p-field w-full">
              <label htmlFor="empid">Emp Id</label>
              <InputText
                id="empid"
                value={selectedEmployeeDetails.empid}
                disabled
              />
            </div>
          </div>
          <div className="md:col-3 col-12">
            <div className="p-field w-full">
              <label htmlFor="department">Department ID</label>
              <InputText
                id="department"
                value={selectedEmployeeDetails.department}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="md:flex block justify-content-between mt-2 gap-2">
          <div className="w-full">
            <label htmlFor="salary">Actual Salary</label>
            <InputText
              id="salary"
              value={selectedEmployeeDetails.salary}
              disabled
            />
          </div>
          <div className="p-field w-full">
            <label>Month and Year</label>
            <div
              style={{
                border: "1px solid #F0F0F0",
                padding: "10px",
                height: "39px",
                borderRadius: "5px",
              }}
            >
              {formatMonthYear(fromDate)}
            </div>
          </div>
          <div className="p-field w-full">
            <label htmlFor="fromDate">From Date</label>
            <InputText
              id="fromDate"
              className="w-full"
              style={{ height: "40px" }}
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              placeholder="Enter From Date"
              type="date"
            />
          </div>
          <div className="p-field w-full">
            <label htmlFor="toDate">To Date</label>
            <InputText
              id="toDate"
              className="w-full"
              style={{ height: "40px" }}
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              placeholder="Enter To Date"
              type="date"
            />
          </div>
          <div className="p-field w-full">
            <label htmlFor="days">Absent Days</label>
            <InputText
              id="days"
              className="w-full"
              value={totalMissingDays}
              disabled
            />
          </div>
        </div>
        <div>
          <h3>Earnings</h3>
          {earnings.length === 0 && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              Please add at least one earning.
            </div>
          )}
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "1px solid #ddd",
              margin: "10px 0px",
            }}
          >
            <thead>
              <tr style={{ background: "#f2f2f2" }}>
                <th
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  Earning Name
                </th>
                <th
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  Amount
                </th>
                <th
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  delete
                </th>
              </tr>
            </thead>
            <tbody>
              {earnings.map((earning, index) => (
                <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    {earning.payhead_name || "no data"}
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    {earning.payhead_amount}
                  </td>
                  <td>
                    <i
                      className="fi fi-sr-trash"
                      onClick={() => handleDeleteEarning(index)}
                      style={{
                        color: "red",
                        cursor: "pointer",
                        fontSize: "20px",
                      }}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="md:flex block justify-content-between">
            <div
              className="md:flex block justify-content-end"
              style={{ marginTop: "30px" }}
            >
              <Dropdown
                id="earningPayHead"
                options={earningOptions}
                className="w-full md:mt-0 "
                value={newEarning.payhead_id}
                onChange={(e) =>
                  setNewEarning({
                    ...newEarning,
                    payhead_id: e.value,
                  })
                }
                placeholder="Select Earning Head"
                style={{ marginRight: "10px" }}
              />
              <InputText
                keyfilter="num"
                className="w-full md:mt-0 mt-3"
                value={newEarning.payhead_amount}
                onChange={(e) =>
                  setNewEarning({
                    ...newEarning,
                    payhead_amount: e.target.value,
                  })
                }
                placeholder="Earning amount"
                style={{ marginRight: "10px" }}
              />
              <div className="flex justify-content-end">
                <i
                  class="fi fi-sr-square-plus"
                  onClick={handleAddEarning}
                  style={{
                    color: "rgb(22,119,255)",
                    fontSize: "40px",
                    marginRight: "9px",
                  }}
                ></i>
              </div>
            </div>
            <div className="mt-5">
              <h4 className="mt-1">
                Total Earnings:
                <span className="font-size-200">INR.{totalEarnings}</span>
              </h4>
            </div>
          </div>
        </div>
        <div>
          <h3 className="mt-2">Deductions</h3>
          {deductions.length === 0 && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              Please add at least one deduction.
            </div>
          )}
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "1px solid #ddd",
              margin: "10px 0px",
            }}
          >
            <thead>
              <tr style={{ background: "#f2f2f2" }}>
                <th
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  Deduction Name
                </th>
                <th
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  Amount
                </th>
                <th
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  delete
                </th>
              </tr>
            </thead>
            <tbody>
              {deductions.map((deduction, index) => (
                <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    {deduction.payhead_name}
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    {deduction.payhead_amount}
                  </td>
                  <td>
                    <i
                      className="fi fi-sr-trash"
                      onClick={() => handleDeleteDeduction(index)}
                      style={{
                        color: "red",
                        cursor: "pointer",
                        fontSize: "20px",
                      }}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="md:flex block justify-content-between">
            <div
              className="md:flex block justify-content-end"
              style={{ marginTop: "30px" }}
            >
              <Dropdown
                id="deductionPayHead"
                options={deductionOptions}
                className="w-full md:mt-0"
                value={newDeduction.payhead_id}
                onChange={(e) =>
                  setNewDeduction({
                    ...newDeduction,
                    payhead_id: e.value,
                  })
                }
                placeholder="Select Deduction Head"
                style={{ marginRight: "10px" }}
              />
              <InputText
                className="w-full md:mt-0 mt-3"
                keyfilter="num"
                value={newDeduction.payhead_amount}
                onChange={(e) =>
                  setNewDeduction({
                    ...newDeduction,
                    payhead_amount: e.target.value,
                  })
                }
                placeholder="Deduction amount"
                style={{ marginRight: "10px" }}
              />
              <div className="flex justify-content-end">
                <i
                  class="fi fi-sr-square-plus"
                  onClick={handleAddDeduction}
                  style={{
                    color: "rgb(22,119,255)",
                    fontSize: "40px",
                    marginRight: "9px",
                  }}
                ></i>
              </div>
            </div>
            <div className="mt-5">
              <h4 className="mt-1">
                Total Deductions:
                <span className="font-size-200">INR.{totalDeductions}</span>
              </h4>
            </div>
          </div>
          <div className="mt-5">
            <h4 className="mt-1">
              Net Pay: <span className="font-size-200">INR.{netPay}</span>
            </h4>
          </div>
        </div>
        <div className="flex justify-content-end">
          <Button
            label="Cancel"
            className="p-button-danger  mr-2"
            onClick={closeAddPayHeadsDialog}
          />
          <Button
            label={editMode ? "Submit" : "Submit"}
            className="mx-2"
            onClick={() => {
              if (validateForm()) {
                addPayHeadsToEmployee();
              } else {
              }
            }}
          />
        </div>
      </Dialog>
    </div>
  );
}