import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import axios from "axios";
import { LEAVE_API, ADMIN_LEAVE_API, EMPLOYEE_API } from "../../constants/api";
import TextArea from "antd/es/input/TextArea";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";

export default function AdminLeave() {
  const toast = useRef(null);
  const fileInputRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [employees, setEmployees] = useState([]);
  const [editedData, setEditedData] = useState({
    empid: "",
    fromdate: "",
    todate: "",
    noOfdays: 0,
    leavetype: "",
    reason: "",
    status: "",
  });
  const [newLeaveData, setNewLeaveData] = useState({});
  const [leaveTypes, setLeaveTypes] = useState([
    { label: "Sick Leave", value: "Sick Leave" },
    { label: "Personal Leave", value: "Personal Leave" },
    { label: "Vacation Leave", value: "Vacation Leave" },
    { label: "Casual Leave", value: "Casual Leave" },
    { label: "Quarantine leave", value: "Quarantine leave" },
    { label: "Marriage leave", value: "Marriage leave" },
    { label: "Maternity Leave", value: "Maternity Leave" },
    { label: "Paternity Leave", value: "Paternity Leave" },
    { label: "Privilege Leave", value: "Privilege Leave" },
    { label: "Jury Duty Leave", value: "Jury Duty Leave" },
    { label: "Comp - Off", value: "Comp - Off" },
    { label: "Others", value: "Others" },
  ]);
  const [timeOff, setTimeOff] = useState([
    { label: "Paid", value: "Paid" },
    { label: "Unpaid", value: "Unpaid" },
  ]);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState(null);
  const [sortField, setSortField] = useState("fromdate");
  const [sortOrder, setSortOrder] = useState(-1);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");

  const onSort = (e) => {
    const { field, order } = e.sort;
    setSortField(field);
    setSortOrder(order);
  };
  const showConfirmationModal = (rowData) => {
    setSelectedRowToDelete(rowData);
    setDisplayConfirmation(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmation(false);
    setSelectedRowToDelete(null);
  };

  const confirmDelete = () => {
    if (selectedRowToDelete) {
      deleteRow(selectedRowToDelete);
      hideConfirmationModal();
    }
  };
  const calculateNoOfDays = (fromDate, toDate) => {
    const from = new Date(fromDate);
    const to = new Date(toDate);

    const fromUTC = Date.UTC(
      from.getFullYear(),
      from.getMonth(),
      from.getDate()
    );
    const toUTC = Date.UTC(to.getFullYear(), to.getMonth(), to.getDate());

    const timeDiff = Math.abs(toUTC - fromUTC);
    const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;

    setEditedData((prevData) => ({ ...prevData, noOfdays: diffDays }));
  };

  const calculateNoOfDaysForNewLeave = (fromDate, toDate) => {
    const from = new Date(fromDate);
    const to = new Date(toDate);

    const fromUTC = Date.UTC(
      from.getFullYear(),
      from.getMonth(),
      from.getDate()
    );
    const toUTC = Date.UTC(to.getFullYear(), to.getMonth(), to.getDate());

    const timeDiff = Math.abs(toUTC - fromUTC);
    const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;

    setNewLeaveData((prevData) => ({ ...prevData, NoOfDays: diffDays }));
  };

  useEffect(() => {
    calculateNoOfDaysForNewLeave(newLeaveData.LeaveFrom, newLeaveData.LeaveTo);
  }, [newLeaveData.LeaveFrom, newLeaveData.LeaveTo]);

  const showToast = (severity, detail) => {
    toast.current.clear();
    toast.current.show({ severity, detail, life: 3000 });
  };

  useEffect(() => {
    fetchLeaveData();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.All_USERS);
      const { usersData } = response.data;
      setEmployees(usersData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const employeeOptions = employees.map((employee) => ({
    label: `${employee.firstname} ${employee.lastname}`,
    value: employee.id,
  }));

  const openEditModal = (rowData, rowIndex) => {
    const {
      leaveRequestId,
      fromdate,
      todate,
      noOfdays,
      leave_request_type,
      leavetype,
      reason,
      status,
      filename,
    } = rowData;
    setEditedData({
      leaveRequestId,
      fromdate,
      todate,
      noOfdays,
      leave_request_type,
      leavetype,
      reason,
      status,
      filename,
    });

    setEditIndex(rowIndex);
    setDisplayEditModal(true);
  };

  const openAddModal = () => {
    setDisplayAddModal(true);
  };
  const fetchLeaveData = () => {
    axios
      .get(ADMIN_LEAVE_API.GET_ALL_LEAVE)
      .then((response) => {
        setProducts(response.data.leaveData);
      })
      .catch((error) => {
        console.error("Error fetching leave data:", error.message);
      });
  };

  const onUpload = (event) => {
    const files = event.target.files;
    if (files.length > 5) {
      event.preventDefault();
      alert("You can only select up to 5 files.");
      return;
    }
    const fileNames = [];
    for (let i = 0; i < files.length; i++) {
      fileNames.push(files[i].name);
    }
    setUploadedFiles(fileNames);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !newLeaveData.LeaveFrom ||
      !newLeaveData.LeaveTo ||
      !newLeaveData.NoOfDays ||
      !newLeaveData.LeaveRequsetType ||
      !newLeaveData.LeaveType ||
      !newLeaveData.Reason ||
      !selectedEmployee
    ) {
      setDisplayAddModal(true);
      showToast("error", "Please enter all the fields.");
      return;
    }

    const requestData = {
      empid: selectedEmployee,
      fromdate: newLeaveData.LeaveFrom,
      todate: newLeaveData.LeaveTo,
      leavetype: newLeaveData.LeaveType,
      noOfdays: newLeaveData.NoOfDays,
      leave_request_type: newLeaveData.LeaveRequsetType,
      reason: newLeaveData.Reason,
      status: "pending",
      files: null,
    };

    const formData = new FormData();
    for (const key in requestData) {
      formData.append(key, requestData[key]);
    }

    const files = fileInputRef.current.files;
    if (files.length > 0) {
      Array.from(files).forEach((file) => {
        formData.append("files", file);
      });
    }

    axios
      .post(LEAVE_API.LEAVE_ADD, formData)
      .then((response) => {
        fetchLeaveData();
        showToast("success", "Leave request added successfully");
        setDisplayAddModal(false);
        setNewLeaveData({
          LeaveFrom: "",
          LeaveTo: "",
          NoOfDays: 0,
          LeaveType: "",
          Reason: "",
        });
        setSelectedEmployee("");
        setUploadedFiles([]);
      })
      .catch((error) => {
        console.error("Error submitting leave application:", error.message);
        const errorMessage =
          error.response?.data?.message || "Error adding leave request";
        showToast("error", errorMessage);
      });
  };

  const saveChanges = async () => {
    if (editIndex !== null) {
      const requiredFields = [
        "fromdate",
        "todate",
        "leave_request_type",
        "leavetype",
        "reason",
      ];
      if (requiredFields.some((field) => !editedData[field])) {
        setDisplayEditModal(true);
        showToast("error", "Please enter all the fields.");
        return;
      }

      const formData = new FormData();
      for (const key in editedData) {
        if (key !== "filename" || !fileInputRef.current.files.length) {
          formData.append(key, editedData[key]);
        }
      }

      if (fileInputRef.current.files.length) {
        const filenames = [];
        Array.from(fileInputRef.current.files).forEach((file, index) => {
          formData.append(`files`, file);
          filenames.push(file.name);
        });
        formData.append("filename", filenames.join(","));
      }

      try {
        const { leaveRequestId } = editedData;
        await axios.put(LEAVE_API.LEAVE_UPDATE(leaveRequestId), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        setProducts((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts[editIndex] = editedData;
          return updatedProducts;
        });

        setDisplayEditModal(false);
        fetchLeaveData();
        showToast("success", "Leave request updated successfully");
      } catch (error) {
        console.error("Error updating leave application:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          showToast("error", error.response.data.message);
        } else {
          showToast("error", "Error updating leave request");
        }
      }
    }
  };

  const deleteRow = async (rowData) => {
    const { leaveRequestId } = rowData;

    try {
      await axios.delete(LEAVE_API.LEAVE_DELETE(leaveRequestId));
      const updatedProducts = products.filter(
        (product) => product.leaveRequestId !== leaveRequestId
      );
      setProducts(updatedProducts);
      showToast("success", "Leave request deleted successfully");
      fetchLeaveData();
    } catch (error) {
      console.error("Error deleting leave application:", error);
      showToast("error", "Error deleting leave request");
    }
  };

  const today = new Date().toISOString().split("T")[0];
  const actionTemplate = (rowData) => {
    if (rowData.status === "approved" || rowData.status === "rejected") {
      return null;
    }

    return (
      <div className="flex items-center">
        <Button
          tooltip="Edit"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-pencil"
          className="p-button-rounded p-mr-2"
          onClick={() => openEditModal(rowData)}
          style={{ marginRight: "10px", background: "rgb(22,119,255)" }}
        />
        <Button
          tooltip="Delete"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => showConfirmationModal(rowData)}
        />
      </div>
    );
  };

  const statusTemplate = (rowData) => {
    let color, label;
    switch (rowData.status) {
      case "pending":
        color = "#FEFAD3";
        label = "Pending";
        break;
      case "approved":
        color = "#C6EFCD";
        label = "Approved";
        break;
      case "rejected":
        color = "#FFC8CE";
        label = "Rejected";
        break;
      default:
        color = "gray";
        label = "Unknown";
    }

    return (
      <span
        style={{
          backgroundColor: color,
          color: "black",
          padding: "5px",
          borderRadius: "5px",
          display: "inline-block",
        }}
      >
        {label}
        {rowData.status === "rejected" && (
          <Tooltip target={`#status_${rowData.leaveRequestId}`} position="top">
            <p style={{ color: "red", fontWeight: "bold" }}>
              Rejection Reason:{" "}
            </p>{" "}
            <span style={{ textTransform: "capitalize" }}>
              {rowData.rejection_reason}
            </span>
          </Tooltip>
        )}
      </span>
    );
  };

  return (
    <div>
      <div className="md:flex block justify-content-between">
        <div className="">
          <h2 className="my-3">Employee Leave Apply</h2>
        </div>
        <div className="">
          <div className="p-inputgroup">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <Toast ref={toast} />
        <div className="flex justify-content-end">
          <i
            class="fi fi-sr-square-plus"
            onClick={openAddModal}
            style={{
              color: "#6366F1",
              fontSize: "40px",
              marginRight: "9px",
            }}
          ></i>
        </div>

        <DataTable
          value={products}
          paginator
          rows={5}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={onSort}
          globalFilter={globalFilter.trim() ? globalFilter : null}
        >
          <Column
            field="Employeename"
            header="Employee Name"
            style={{ minWidth: "100px" }}
            frozen
            body={(rowData) => (
              <span>{`${rowData.firstname} ${rowData.lastname}`}</span>
            )}
          ></Column>
          <Column
            field="fromdate"
            header="From Date"
            style={{ minWidth: "120px" }}
            body={(rowData) => moment(rowData.fromdate).format("DD-MM-YYYY")}
          />
          <Column
            field="todate"
            header="To Date"
            style={{ minWidth: "120px" }}
            body={(rowData) => moment(rowData.todate).format("DD-MM-YYYY")}
          />
          <Column
            field="noOfdays"
            header="No of Days"
            style={{ minWidth: "120px" }}
          />
          <Column
            field="leave_request_type"
            header="Time Off"
            style={{ minWidth: "100px" }}
          />
          <Column
            field="leavetype"
            header="Leave Type"
            style={{ minWidth: "100px" }}
          />
          <Column
            field="filename"
            header="Medical Certificate"
            body={(rowData) => (rowData.filename ? rowData.filename : "-")}
            style={{ minWidth: "100px" }}
          />
          <Column
            field="reason"
            header="Reason"
            body={(rowData) => (
              <div
                id={`reason_${rowData.leaveRequestId}`}
                className="tooltip-container"
              >
                {rowData.reason.length > 20
                  ? `${rowData.reason.slice(0, 20)}...`
                  : rowData.reason}
                <Tooltip
                  target={`#reason_${rowData.leaveRequestId}`}
                  position="right"
                >
                  {rowData.reason}
                </Tooltip>
              </div>
            )}
          />
          <Column
            field="status"
            header="Status"
            body={(rowData) => (
              <div id={`status_${rowData.leaveRequestId}`}>
                {statusTemplate(rowData)}
              </div>
            )}
            style={{ minWidth: "100px", textAlign: "center" }}
          />
          <Column
            field="Action"
            header="Actions"
            body={actionTemplate}
            style={{ minWidth: "100px" }}
          />
        </DataTable>

        {/* Edit Modal */}
        <Dialog
          visible={displayEditModal}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={() => setDisplayEditModal(false)}
          header="Edit Leave"
        >
          <label htmlFor="leaveFrom">From Date</label>
          <input
            type="date"
            id="leaveFrom"
            value={editedData.fromdate || ""}
            onChange={(e) => {
              setEditedData({ ...editedData, fromdate: e.target.value });
              calculateNoOfDays(e.target.value, editedData.todate);
            }}
            min={today}
          />

          <label htmlFor="leaveTo">To Date</label>
          <input
            type="date"
            id="leaveTo"
            value={editedData.todate || ""}
            onChange={(e) => {
              setEditedData({ ...editedData, todate: e.target.value });
              calculateNoOfDays(editedData.fromdate, e.target.value);
            }}
            min={editedData.fromdate || ""}
          />

          <div>
            <label htmlFor="noOfDays">No Of Days</label>
            <input
              type="number"
              id="noOfDays"
              value={editedData.noOfdays || ""}
              onChange={(e) =>
                setEditedData({ ...editedData, noOfdays: e.target.value })
              }
              disabled
            />
          </div>
          <div>
            <label htmlFor="leaveType">Time Off</label>
            <Dropdown
              id="leave_request_type"
              value={editedData.leave_request_type || ""}
              options={timeOff}
              onChange={(e) =>
                setEditedData({ ...editedData, leave_request_type: e.value })
              }
              placeholder="Select Time Off"
              className="w-full"
            />
          </div>

          <div>
            <label htmlFor="leaveType">Leave Type</label>
            <Dropdown
              id="leaveType"
              value={editedData.leavetype || ""}
              options={leaveTypes}
              onChange={(e) =>
                setEditedData({ ...editedData, leavetype: e.value })
              }
              placeholder="Select Leave Type"
              className="w-full"
            />
          </div>
          <div>
            <label htmlFor="reason">Reason</label>
            <TextArea
              type="text"
              id="reason"
              className="w-full"
              value={editedData.reason || ""}
              onChange={(e) =>
                setEditedData({ ...editedData, reason: e.target.value })
              }
            />
          </div>
          <div className="col-12">
            <label htmlFor="file">Medical Certificate</label>
            <input
              id="file"
              type="file"
              multiple
              onChange={(e) => {
                const files = e.target.files;
                const fileNames = [];
                for (let i = 0; i < files.length; i++) {
                  fileNames.push(files[i].name);
                }
                setUploadedFiles(fileNames.join(", "));
                fileInputRef.current.files = files;
              }}
              ref={fileInputRef}
            />

            <div className="flex">
              <div className="mt-1 ml-2">
                <i className="fi fi-rs-file-upload"></i>
              </div>
              <p className="mb-2 ml-2">
                {editedData.filename ? (
                  <span>{editedData.filename}</span>
                ) : (
                  uploadedFiles
                )}
              </p>
            </div>
          </div>
          <div className="flex justify-content-end mt-4">
            <Button
              label="Cancel"
              className="p-button-danger"
              onClick={() => {
                setDisplayEditModal(false);
                setNewLeaveData({
                  LeaveFrom: "",
                  LeaveTo: "",
                  NoOfDays: 0,
                  LeaveType: "",
                  Reason: "",
                });
                setUploadedFiles([]);
              }}
            />
            <Button label="Submit" className="mx-2" onClick={saveChanges} />
          </div>
        </Dialog>

        {/* Add Modal */}
        <Dialog
          position="center"
          draggable={false}
          visible={displayAddModal}
          style={{ width: "40vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={() => setDisplayAddModal(false)}
          header="Add Leave"
        >
          <div className="grid">
            <div className="col-12">
              <label htmlFor="employee">Employee</label>
              <Dropdown
                id="employee"
                value={selectedEmployee}
                options={employeeOptions}
                onChange={(e) => setSelectedEmployee(e.value)}
                placeholder="Select an Employee"
                className="w-full"
                filter
                showClear
              />
            </div>
            <div className="col-6">
              <label htmlFor="leaveFrom">From Date</label>
              <input
                type="date"
                id="leaveFrom"
                value={newLeaveData?.LeaveFrom || ""}
                onChange={(e) => {
                  setNewLeaveData({
                    ...newLeaveData,
                    LeaveFrom: e.target.value,
                  });
                }}
                min={today}
              />
            </div>
            <div className="col-6">
              <label htmlFor="leaveTo">To Date</label>
              <input
                type="date"
                id="leaveTo"
                value={newLeaveData?.LeaveTo || ""}
                onChange={(e) => {
                  setNewLeaveData({ ...newLeaveData, LeaveTo: e.target.value });
                }}
                min={newLeaveData?.LeaveFrom || ""}
              />
            </div>
            <div className="col-12">
              <label htmlFor="noOfDays">No Of Days</label>
              <input
                type="number"
                id="noOfDays"
                value={newLeaveData?.NoOfDays || ""}
                onChange={(e) =>
                  setNewLeaveData({ ...newLeaveData, NoOfDays: e.target.value })
                }
                disabled
              />
            </div>
            <div className="col-6">
              <label htmlFor="leave_request_type">Time Off</label>
              <Dropdown
                id="leave_request_type"
                value={newLeaveData?.LeaveRequsetType || ""}
                options={timeOff}
                onChange={(e) =>
                  setNewLeaveData({
                    ...newLeaveData,
                    LeaveRequsetType: e.value,
                  })
                }
                placeholder="Select Time Off"
                className="w-full"
              />
            </div>
            <div className="col-6">
              <label htmlFor="leaveType">Leave Type</label>
              <Dropdown
                id="leaveType"
                value={newLeaveData?.LeaveType || ""}
                options={leaveTypes}
                onChange={(e) =>
                  setNewLeaveData({ ...newLeaveData, LeaveType: e.value })
                }
                placeholder="Select Leave Type"
                className="w-full"
              />
            </div>
            <div className="col-12">
              <label htmlFor="reason">Reason</label>
              <TextArea
                style={{ resize: "none" }}
                type="text"
                id="reason"
                className="w-full"
                value={newLeaveData?.Reason || ""}
                onChange={(e) =>
                  setNewLeaveData({ ...newLeaveData, Reason: e.target.value })
                }
              />
            </div>
            <div className="col-12">
              <label htmlFor="file">Medical Certificate</label>
              <input
                id="file"
                type="file"
                multiple
                onChange={onUpload}
                ref={fileInputRef}
              />
              <div className="flex">
                <div className="mt-1 ml-2">
                  <i className="fi fi-rs-file-upload"></i>
                </div>
                <p className="mb-2 ml-2">{uploadedFiles}</p>
              </div>
            </div>
          </div>
          <div className="flex justify-content-end mt-4">
            <Button
              label="Cancel"
              className="p-button-danger"
              onClick={() => {
                setDisplayAddModal(false);
                setNewLeaveData({
                  LeaveFrom: "",
                  LeaveTo: "",
                  NoOfDays: 0,
                  LeaveType: "",
                  Reason: "",
                });
                setUploadedFiles([]);
              }}
            />
            <Button
              label="Submit"
              className="mx-2"
              onClick={(e) => {
                setDisplayAddModal(false);
                handleSubmit(e);
              }}
            />
          </div>
        </Dialog>

        {/* delete model */}
        <Dialog
          visible={displayConfirmation}
          style={{ width: "50vw" }}
          breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          onHide={hideConfirmationModal}
          header="Confirmation"
          footer={
            <div>
              <Button
                style={{ color: "white", background: "red" }}
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideConfirmationModal}
              />
              <Button
                style={{ color: "white", background: "#22c55e" }}
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={confirmDelete}
              />
            </div>
          }
        >
          Are you sure you want to delete the data?
        </Dialog>
      </div>
    </div>
  );
}