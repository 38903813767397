import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import {
  ATTENDANCE_API,
  EMPLOYEE_API,
  DEPARTMENT_API,
} from "../../constants/api";
import axios from "axios";

export default function TodayAttendance() {
  const [attendanceData, setAttendanceData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);

  const fetchAttendanceData = () => {
    axios
      .get(ATTENDANCE_API.ATTENDANCE_TODAY)
      .then((response) => {
        setAttendanceData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  };

  useEffect(() => {
    fetchAttendanceData();
  }, []);

  const fetchEmployeeData = () => {
    axios
      .get(EMPLOYEE_API.GET_ALL)
      .then((response) => {
        setEmployeeData(response.data.usersData);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  };
  useEffect(() => {
    fetchEmployeeData();
  }, []);
  const getAllDepartment = async () => {
    try {
      const response = await axios.get(DEPARTMENT_API.DEPARTMENT_FETCH);
      setDepartmentData(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  useEffect(() => {
    getAllDepartment();
  }, []);
  const onRowEditComplete = (e) => {};

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };
  const currentDateString = new Date().toISOString().split("T")[0];
  const currentAttendanceData = attendanceData.filter(
    (record) => record.date === currentDateString
  );
  return (
    <div>
      <div className="card mt-2 h-full">
        <div className="md:flex block justify-content-between w-full">
          <h2 className="my-3">Today Attendance</h2>
          <div className="md:col-3 col-12 mt-0">
            <div className="p-inputgroup">
              <InputText
                id="globalFilter"
                type="text"
                className="search-input"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Search"
              />
              <Button icon="fi fi-bs-search" className="search-icon" />
            </div>
          </div>
        </div>
        <DataTable
          className="Emp_att"
          value={currentAttendanceData}
          editMode="row"
          dataKey="id"
          onRowEditComplete={onRowEditComplete}
          scrollable
          scrollHeight="550px"
          paginator
          rows={10}
          globalFilter={globalFilter ? globalFilter.trim() : ""}
          removableSort
        >
          <Column
            field="date"
            header="Date"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "100px" }}
          ></Column>
          <Column
            header="Name"
            frozen
            className="font-bold"
            body={(rowData) => {
              const user =
                Array.isArray(employeeData) &&
                employeeData.find((emp) => emp.empid === rowData.userid);
              return user ? `${user.firstname} ${user.lastname}` : "";
            }}
            style={{ minWidth: "100px", textAlign: "left" }}
          ></Column>
          <Column
            field="department"
            header="Department"
            body={(rowData) => {
              const user =
                Array.isArray(employeeData) &&
                employeeData.find((emp) => emp.empid === rowData.userid);
              const departmentId = user ? parseInt(user.department) : null;
              const department = departmentId
                ? departmentData.find(
                    (dept) => dept.department_id === departmentId
                  )
                : null;
              return department ? department.department_name : "-";
            }}
            editor={(options) => textEditor(options)}
            style={{ minWidth: "100px", textAlign: "left" }}
            sortable
          ></Column>
          <Column
            field="check_in"
            header="Check In"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "100px" }}
            sortable
          ></Column>
          <Column
            field="check_out"
            header="Check Out"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "100px" }}
            sortable
          ></Column>
          <Column
            field="workinghours"
            header="Working Hours"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "100px" }}
            sortable
          ></Column>
          {/* <Column
            field="break"
            header="Break In"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "100px" }}
          ></Column>
          <Column
            field="break"
            header="Break Out"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "100px" }}
          ></Column> */}
          <Column
            field="status"
            header="Status"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "100px" }}
            sortable
          ></Column>
          {/* <Column
            field="shift"
            header="Shift"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "100px" }}
          ></Column> */}
        </DataTable>
      </div>
    </div>
  );
}
