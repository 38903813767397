import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  TASK_API,
  TASK_QUERY_API,
  TASK_File_UPLOAD,
} from "../../constants/api";
import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

const tableHeaderStyle = {
  border: "1px solid black",
  backgroundColor: "#f2f2f2",
  padding: "8px",
  textAlign: "center",
};
function DocumnetDetails() {
  const [filenames, setFilenames] = useState([]);
  const toast = useRef(null);
  const [creater, setCreater] = useState([]);
  const [startDate, setStartDate] = useState([]);
  const [createrEmployee, setCreaterEmployee] = useState([]);
  const [uploadedDate, setuploadedDate] = useState([]);
  const [employeeFileName, setemployeeFileName] = useState([]);
  const [fileDetails, setFileDetails] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [adminCreater, setAdminCreater] = useState([]);
  const [adminStartDate, setAdminStartDate] = useState([]);
  const [adminFileName, setAdminFileName] = useState([]);
  const fileInputRef = useRef(null);
  const { taskId } = useParams();

  const showToast = (severity, detail) => {
    toast.current.show({
      severity,
      summary: severity === "error" ? "Error" : "Success",
      detail,
    });
  };

  const getFolderFromFilename = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
      return "Images";
    } else if (["pdf", "doc", "docx", "txt"].includes(extension)) {
      return "OnlyPDF";
    } else if (["mp3", "wav", "ogg", "aac"].includes(extension)) {
      return "OnlyAudio";
    } else if (["mp4", "avi", "mkv", "mov"].includes(extension)) {
      return "OnlyVideo";
    } else {
      return "common";
    }
  };

  //task filename fetch api
  const fetchFilenames = async () => {
    try {
      const response = await axios.get(TASK_API.GET_TASK_DOCUMENT(taskId));
      const data = response.data;
      setFilenames(data.filenames);
      setCreater(data.creater);
      setStartDate(data.StartDate);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFilenames();
  }, []);

  //task doubt filename fetch api
  const fetchFileDetails = async () => {
    try {
      const response = await axios.get(
        TASK_QUERY_API.GET_TASK_DOCUMENT_USER(taskId)
      );
      const data = response.data;
      setemployeeFileName(data.filenames);
      setCreaterEmployee(data.creater);
      setuploadedDate(data.UploadDate);
    } catch (error) {
      console.error("Error fetching task details:", error);
    }
  };

  useEffect(() => {
    fetchFileDetails();
  }, [taskId]);

  //task admin filename fetch api
  const fetchAdminFileDetails = async () => {
    try {
      const response = await axios.get(
        TASK_File_UPLOAD.GET_TASK_DOCUMENT_USER(taskId)
      );
      const data = response.data;
      setAdminFileName(data.filenames);
      setAdminCreater(data.creater);
      setAdminStartDate(data.UploadDate);
      setFileDetails(response.data);
    } catch (error) {
      console.error("Error fetching task details:", error);
    }
  };

  useEffect(() => {
    fetchAdminFileDetails();
  }, [taskId]);

  const fetchFileUrl = async (filename) => {
    try {
      const response = await axios.get(
        TASK_API.DOWNLOAD_FILE(getFolderFromFilename(filename), filename),
        {
          responseType: "blob",
        }
      );
      const contentType = response.headers["content-type"];

      if (contentType && contentType.includes("application/json")) {
        const data = response.data;
        return data.fileUrl;
      } else {
        const blob = new Blob([response.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        return url;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleDownload = async (filename) => {
    try {
      const fileUrl = await fetchFileUrl(filename);
      if (fileUrl) {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEmployeeFileUrl = async (filename) => {
    try {
      const response = await axios.get(
        TASK_QUERY_API.DOWNLOAD_FILE_USER(
          getFolderFromFilename(filename),
          filename
        ),
        {
          responseType: "blob",
        }
      );
      const contentType = response.headers["content-type"];

      if (contentType && contentType.includes("application/json")) {
        const data = response.data;
        return data.fileUrl;
      } else {
        const blob = new Blob([response.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        return url;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleEmployeeFileDownload = async (filename) => {
    try {
      const fileUrl = await fetchEmployeeFileUrl(filename);
      if (fileUrl) {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAdminFileUrl = async (filename) => {
    try {
      const response = await axios.get(
        TASK_File_UPLOAD.DOWNLOAD_FILE_USER(
          getFolderFromFilename(filename),
          filename
        ),
        {
          responseType: "blob",
        }
      );
      const contentType = response.headers["content-type"];

      if (contentType && contentType.includes("application/json")) {
        const data = response.data;
        return data.fileUrl;
      } else {
        const blob = new Blob([response.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        return url;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleAdminFileDownload = async (filename) => {
    try {
      const fileUrl = await fetchAdminFileUrl(filename);
      if (fileUrl) {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleShowUploadDialog = () => {
    setShowUploadDialog(true);
    setIsEditMode(false);
  };
  const onUpload = (event) => {
    const files = event.target.files;
    if (files.length > 5) {
      event.preventDefault();
      alert("You can only select up to 5 files.");
      return;
    }
    const fileNames = [];
    for (let i = 0; i < files.length; i++) {
      fileNames.push(files[i].name);
    }
    setUploadedFiles(fileNames);
  };

  const handleShowEditDialog = async () => {
    try {
      const response = await axios.get(
        TASK_File_UPLOAD.GET_TASK_DOCUMENT_USER(taskId)
      );
      setFileDetails(response.data);
      setShowUploadDialog(true);
      setIsEditMode(true);
      setUploadedFiles(response.data.filenames.join(", "));
    } catch (error) {
      console.error("Error fetching task details:", error);
    }
  };

  const handleEditUpload = async (fileDetails) => {
    try {
      const files = fileInputRef.current.files;
      if (files.length === 0) {
        handleCloseUploadDialog();
        return;
      }
      const formData = new FormData();
      if (files.length > 5) {
        showToast("error", "You can only select up to 5 files.");
        return;
      }
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i], files[i].name);
      }
      formData.append("task_id", fileDetails.task_id);
      formData.append("creater", fileDetails.creater);

      await axios.put(TASK_File_UPLOAD.EDIT_FILE_DETAILS(taskId), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      showToast("success", "Files edited successfully.");
      handleCloseUploadDialog();
      fetchAdminFileDetails();
    } catch (error) {
      console.error("Error editing files:", error);
      showToast("error", error.response.data.message);
    }
  };

  const handleSubmitUploadDialog = async () => {
    if (isEditMode) {
      handleEditUpload(fileDetails);
    } else {
      try {
        const user_id = localStorage.getItem("user");
        const user = JSON.parse(user_id);
        if (!user || !user.data || !user.data.id) {
          console.error(
            "User object is null, undefined, or does not contain an id property."
          );
          return;
        }

        const files = fileInputRef.current.files;
        if (files.length === 0) {
          alert("Please select at least one file to upload.");
          return;
        }

        if (files.length > 5) {
          alert("You can only select up to 5 files.");
          return;
        }

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i], files[i].name);
        }

        formData.append("task_id", taskId);
        formData.append("creater", user.data.id);

        await axios.post(TASK_File_UPLOAD.TASK_FILE_ADD, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        showToast("success", "Files uploaded successfully.");
        setShowUploadDialog(false);
        fetchAdminFileDetails();
      } catch (error) {
        console.error("Error uploading files:", error);
        showToast("error", error.response?.data?.message || "");
      }
    }
  };

  const handleCloseUploadDialog = () => {
    setShowUploadDialog(false);
    setIsEditMode(false);
    setUploadedFiles([]);
  };

  const renderUploadButton = () => {
    if (
      Array.isArray(fileDetails.filenames) &&
      fileDetails.filenames.length > 0
    ) {
      return (
        <Button
          label="Edit Upload File"
          icon="pi pi-upload"
          className="mx-2 mt-4"
          onClick={handleShowEditDialog}
          style={{ height: "40px" }}
        />
      );
    } else {
      return (
        <Button
          label="Upload File"
          icon="pi pi-upload"
          className="mx-2 mt-4"
          onClick={handleShowUploadDialog}
          style={{ height: "40px" }}
        />
      );
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex justify-content-between">
        <div className="">
          <h3 className="Text-center mt-4 mb-2">Task Document</h3>
        </div>
        <div className="mb-2">
          {renderUploadButton()}
          {/* <Button style ={{height:"40px",marginTop:"10px"}}>Upload</Button> */}
        </div>
      </div>
      <div>
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Start Date</th>
              <th style={tableHeaderStyle}>Creater</th>
              <th style={tableHeaderStyle}>File Name</th>
              <th style={tableHeaderStyle}>Action</th>
            </tr>
          </thead>
          <tbody>
            {filenames?.map((filename, index) => (
              <tr key={index}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "100px",
                  }}
                >
                  {startDate
                    ? `${startDate.split("-")[2]}-${startDate.split("-")[1]}-${
                        startDate.split("-")[0]
                      }`
                    : ""}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "200px",
                  }}
                >
                  {creater}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "600px",
                  }}
                >
                  {filename}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  <button
                    style={{
                      background: "green",
                      color: "white",
                      border: "1px solid green",
                      paddingTop: "7px",
                      paddingBottom: "6px",
                      borderRadius: "5px",
                    }}
                    onClick={() => handleDownload(filename)}
                  >
                    <i
                      className="fi fi-rs-down-to-line"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Download
                  </button>
                </td>
              </tr>
            ))}

            {employeeFileName?.map((filename, index) => (
              <tr key={index}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "100px",
                  }}
                >
                  {uploadedDate}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "200px",
                  }}
                >
                  {createrEmployee}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "600px",
                  }}
                >
                  {filename}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  <button
                    style={{
                      background: "green",
                      color: "white",
                      border: "1px solid green",
                      paddingTop: "7px",
                      paddingBottom: "6px",
                      borderRadius: "5px",
                    }}
                    onClick={() => handleEmployeeFileDownload(filename)}
                  >
                    <i
                      className="fi fi-rs-down-to-line"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Download
                  </button>
                </td>
              </tr>
            ))}
            {adminFileName?.map((filename, index) => (
              <tr key={index}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "100px",
                  }}
                >
                  {adminStartDate}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "200px",
                  }}
                >
                  {adminCreater}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "600px",
                  }}
                >
                  {adminFileName}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  <button
                    style={{
                      background: "green",
                      color: "white",
                      border: "1px solid green",
                      paddingTop: "7px",
                      paddingBottom: "6px",
                      borderRadius: "5px",
                    }}
                    onClick={() => handleAdminFileDownload(filename)}
                  >
                    <i
                      className="fi fi-rs-down-to-line"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Download
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Dialog
          visible={showUploadDialog}
          style={{ width: "25vw" }}
          onHide={handleCloseUploadDialog}
          showHeader={false}
          className="taskdetail"
        >
          <div className="grid">
            <div className="col-12">
              <label htmlFor="file">Task Attachment</label>
              <input
                id="file"
                type="file"
                multiple
                onChange={onUpload}
                ref={fileInputRef}
              />
              <div className="flex">
                <div className="mt-1 ml-2">
                  <i className="fi fi-rs-file-upload"></i>
                </div>
                <p className="mb-2 ml-2">{uploadedFiles}</p>
              </div>
            </div>
          </div>
          <div className="text-center mt-2">
            <Button
              label="Cancel"
              icon="pi pi-times"
              onClick={handleCloseUploadDialog}
              className="p-button-danger"
            />
            <Button
              label={isEditMode ? "Update" : "Upload"}
              icon={isEditMode ? "pi pi-check" : "pi pi-upload"}
              className="mx-2"
              onClick={isEditMode ? handleEditUpload : handleSubmitUploadDialog}
            />
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default DocumnetDetails;