import React, { useState } from "react";
import { Button } from 'primereact/button';
import AssetsRequest from './AssetsRequest';
import Assets from "./Assets";

export default function AssetsTab({ userId }) {
    const [value, setValue] = useState("Assets");

    const justifyOptions = [
        { name: 'Assets', value: 'Assets', severity: "success" },
        { name: 'Assets Request', value: 'AssetsRequest', severity: "danger" },
    ];

    return (
        <div>
            <div className="flex gap-3 flex-wrap mb-3 lg-mb-2">
                {justifyOptions.map((option) => (
                    <Button
                        key={option.value}
                        label={option.name}
                        className={`p-button ${value === option.value ? 'p-button-primary' : 'p-button-outlined'} flex-grow-1 sm:flex-grow-0`}
                        severity={option.severity}
                        onClick={() => setValue(option.value)}
                        text raised
                    />
                ))}
            </div>
            <div>
                {value === 'Assets' && <Assets userId={userId} />}
                {value === 'AssetsRequest' && <AssetsRequest userId={userId} />}
            </div>
        </div>
    );
}
