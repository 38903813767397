import { configureStore } from "@reduxjs/toolkit";
import middleware from "./middleware";
import rootReducer from "./rootReducer";
import paginatorReducer from "../service/paginator";

export const store = configureStore({
  reducer: { rootReducer, paginator: paginatorReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware),
});

export const { dispatch, getState } = store;
