import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import {
  ATTENDANCE_API,
  EMPLOYEE_API,
  DEPARTMENT_API,
  ASSETS_IMAGES_API,
} from "../../constants/api";
import AttendanceAddModel from "./AttendanceAddModel";
import { Toast } from "primereact/toast";
import moment from "moment/moment";
import { Image } from "primereact/image";
import deviceInfo from "../../utills/deviceInfo";
import { Tooltip } from 'primereact/tooltip';
import { Link } from "react-router-dom";

export default function AllAttendance() {
  const [attendanceData, setAttendanceData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [editData, setEditData] = useState({
    currentDate: "",
    check_in: "",
    check_out: "",
    status: "",
    workinghours: "",
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentTime, setCurrentTime] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [checkInError, setCheckInError] = useState("");
  const [checkOutError, setCheckOutError] = useState("");
  const toastRef = useRef(null);
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [attendanceStatus, setAttendanceStatus] = useState("Present");
  const [errors, setErrors] = useState({});
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const today = new Date().toISOString().split("T")[0];
  const [dateAdd, setDateAdd] = useState(today);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);
  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] = useState(false);

  const fetchAttendanceData = () => {
    axios
      .get(ATTENDANCE_API.ATTENDANCE_TODAY)
      .then((response) => {
        setAttendanceData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  };

  useEffect(() => {
    fetchAttendanceData();
  }, []);

  const fetchEmployeeData = () => {
    axios
      .get(EMPLOYEE_API.GET_ALL)
      .then((response) => {
        setEmployeeData(response.data.usersData);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  };

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  const getAllDepartment = async () => {
    try {
      const response = await axios.get(DEPARTMENT_API.DEPARTMENT_FETCH);
      setDepartmentData(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  useEffect(() => {
    getAllDepartment();
  }, []);

  const getCurrentDateTime = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    setCurrentTime(
      `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${period}`
    );
    setCurrentDate(now.toISOString().split("T")[0]);
  };

  useEffect(() => {
    getCurrentDateTime();
    const intervalId = setInterval(getCurrentDateTime, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const showToast = (message) => {
    toastRef?.current?.clear();
    if (toastRef && toastRef.current) {
      toastRef.current.show({
        severity: "error",
        summary: "Error Message",
        detail: message,
      });
    }
  };
  const showSuccessToast = (message) => {
    toastRef.current.clear();
    if (toastRef && toastRef.current) {
      toastRef.current.show({
        severity: "success",
        summary: "Success Message",
        detail: message,
      });
    }
  };
  const isValidTime = (time) => {
    const timeRegex = /^(1[0-2]|0?[1-9]):([0-5][0-9]):([0-5][0-9])\s?(AM|PM)$/i;
    return timeRegex.test(time);
  };

  const calculateWorkingHours = (checkIn, checkOut) => {
    if (!checkIn || !checkOut) return "";

    const parseTimeString = (timeString) => {
      const [time, period] = timeString.split(" ");
      const [hours, minutes, seconds] = time.split(":").map(Number);
      let totalHours = hours % 12;

      if (period?.toUpperCase() === "PM") {
        totalHours += 12;
      }

      return { totalHours, minutes, seconds };
    };

    const checkInTime = parseTimeString(checkIn);
    const checkOutTime = parseTimeString(checkOut);

    const checkInDate = new Date();
    checkInDate.setHours(
      checkInTime.totalHours,
      checkInTime.minutes,
      checkInTime.seconds
    );

    const checkOutDate = new Date();
    checkOutDate.setHours(
      checkOutTime.totalHours,
      checkOutTime.minutes,
      checkOutTime.seconds
    );

    let diff = checkOutDate.getTime() - checkInDate.getTime();
    if (diff < 0) {
      diff += 24 * 60 * 60 * 1000;
    }
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours} hours ${minutes} minutes`;
  };

  const handleTimeChange = (e, field) => {
    let inputValue = e.target.value || "";

    let checkInTime = editData.check_in;
    let checkOutTime = editData.check_out;

    if (field === "check_in") {
      if (!isValidTime(inputValue)) {
        setCheckInError("Time format should be in HH:MM:SS AM/PM");
      } else {
        setCheckInError("");
        checkInTime = inputValue;
      }
    } else if (field === "check_out") {
      if (!isValidTime(inputValue)) {
        setCheckOutError("Time format should be in HH:MM:SS AM/PM");
      } else {
        setCheckOutError("");
        checkOutTime = inputValue;
      }
    }
    const workinghours = calculateWorkingHours(checkInTime, checkOutTime);
    setEditData({
      ...editData,
      [field]: inputValue,
      workinghours: workinghours !== "" ? workinghours : "",
    });
  };

  const openConfirmModal = (rowData) => {
    setSelectedRow(rowData);
    getCurrentDateTime();

    if (rowData.date !== currentDate) {
      showToast("Date expired, check-out action cannot be performed.");
      return;
    }

    if (rowData.check_out) {
      return;
    }

    setDisplayConfirmModal(true);
  };

  const onRowEditComplete = (e) => {
    let updatedData = [...attendanceData];
    let { newData, index } = e;
    updatedData[index] = newData;
    setAttendanceData(updatedData);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };



  const openEditModal = (rowData) => {
    setSelectedRow(rowData);
    setEditData({
      currentDate: rowData.date,
      check_in: rowData.check_in,
      check_out: rowData.check_out,
      workinghours: rowData.workinghours,
      status: rowData.status,
    });
    setDisplayEditModal(true);
  };

  const handleEditSubmit = () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    const { check_in, check_out, workinghours, currentDate, status } = editData;
    const { id } = selectedRow;

    axios
      .put(ATTENDANCE_API.ATTENDANCE_UPDATE_ADMIN(id), {
        date: currentDate,
        check_in,
        check_out,
        workinghours,
        status,
      })
      .then((response) => {
        setDisplayEditModal(false);
        fetchAttendanceData();
        showSuccessToast("Attendance updated successfully");
      })
      .catch((error) => {
        console.error("Failed to update attendance record:", error.message);
        showToast("Failed to update attendance record");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  const deviceType = deviceInfo()
  const handleConfirm = () => {
    if (selectedRow) {
      const { id } = selectedRow;
      axios
        .put(ATTENDANCE_API.ATTENDANCE_CHECKOUT(id), {
          check_out: currentTime,
          check_out_device: deviceType
        })
        .then((response) => {
          setDisplayConfirmModal(false);
          showSuccessToast(`${response.data.message}`);
          fetchAttendanceData();
        })
        .catch((error) => {
          console.error("Failed to check out:", error.message);
        });
    } else {
      console.error("No row selected");
    }
  };

  const handleCancel = () => {
    setDisplayConfirmModal(false);
  };

  const openAddModal = () => {
    getCurrentDateTime();
    setDisplayAddModal(true);
  };

  const handleSubmit = () => {
    if (isSubmitting) {
      return;
    }

    const newErrors = {};
    if (!attendanceStatus) {
      newErrors.attendanceStatus = "Status is required.";
    }
    if (!selectedEmployee) {
      newErrors.selectedEmployee = "Employee selection is required.";
    }
    if (!dateAdd) {
      errors.dateAdd = "Date is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({});
    }

    setIsSubmitting(true);

    axios
      .post(ATTENDANCE_API.ATTENDANCE_ADD, {
        date: dateAdd,
        check_in: currentTime,
        status: attendanceStatus,
        userid: selectedEmployee,
        check_in_device: deviceType
      })
      .then((response) => {
        setDisplayAddModal(false);
        showSuccessToast("Check-in successful");
        setSelectedEmployee("");
        setAttendanceStatus("");
        setDateAdd("");
        fetchAttendanceData();
      })
      .catch((error) => {
        console.error("Failed to add attendance record:", error.message);
        const errorMessage =
          error.response && error.response.data
            ? error.response.data.message || "An error occurred."
            : "An error occurred.";
        showToast(errorMessage);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const openDeleteConfirmation = (rowData) => {
    setDeleteConfirmationData(rowData);
    setDisplayDeleteConfirmation(true);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        ATTENDANCE_API.ATTENDANCE_DELETE(deleteConfirmationData.id)
      );
      fetchAttendanceData();
      setDisplayDeleteConfirmation(false);
    } catch (error) {
      console.error("Failed to delete payroll data:", error);
    }
  };

  const attendanceOptions = [
    { label: "Present", value: "Present" },
    { label: "On The Way", value: "On The Way" },
    { label: "Absent ", value: "Absent" },
    { label: "Sick Leave", value: "Sick Leave" },
    { label: "Day Off", value: "Day Off" },
    { label: "Permissions", value: "Permissions" },
    { label: "Comp Off", value: "Comp Off" },
    { label: "Optional Leave", value: "Optional Leave" },
  ];

  const handleStatusChange = (e) => {
    const value = e.target.value;
    setAttendanceStatus(value);
    setEditData({ ...editData, status: e.target.value })
    setErrors((prevErrors) => ({
      ...prevErrors,
      attendanceStatus: !value ? "Status is required" : "",
    }));
  };


  const getIconClass = (deviceType) => {
    switch (deviceType) {
      case 'Mobile':
        return 'fi fi-br-mobile-notch';
      case 'Tablet':
        return 'fi fi-br-tablet';
      case 'Desktop':
        return 'fi fi-br-computer';
      default:
        return '';
    }
  };

  const renderImageWithIcon = (imageUrl, device, assetsPath) => {
    const iconClass = getIconClass(device);

    return (
      <div div className="flex flex-column gap-1 justify-content-center align-items-center">
        {imageUrl && (
          <Image
            src={`${assetsPath}${imageUrl}`}
            alt="EmployeeImage"
            width="50"
            height="50"
            preview
          />
        )}
        {device && (
          <>
            <i className={`pi ${iconClass}  text-blue-500`} id={device}></i>
            <Tooltip target={`#${device}`} content={device} />
          </>
        )}
        {!imageUrl && !device && "-"}
      </div>
    );
  };

  return (
    <div>
      <div className="col-12 md:flex block justify-content-between w-full">
        <Toast ref={toastRef} />
        <h2 className="my-1">All Attendance</h2>
        <div className="md:col-4 lg:col-3 col-12 mt-0">
          <div className="p-inputgroup">
            <InputText
              type="date"
              id="globalFilter"
              className="search-input"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search"
              max={today}
            />
            <Button icon="fi fi-bs-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div className="card mt-2 h-full">
        <div className="flex justify-content-end mb-2">
          <Button
            icon="fi fi-bs-plus"
            className="add-button"
            onClick={openAddModal}
          >
            Add
          </Button>
          <AttendanceAddModel
            setDisplayAddModal={setDisplayAddModal}
            displayAddModal={displayAddModal}
            currentDate={currentDate}
            currentTime={currentTime}
            attendanceStatus={attendanceStatus}
            setErrors={setErrors}
            setAttendanceStatus={setAttendanceStatus}
            errors={errors}
            handleSubmit={handleSubmit}
            employeeData={employeeData}
            setSelectedEmployee={setSelectedEmployee}
            selectedEmployee={selectedEmployee}
            setDateAdd={setDateAdd}
            dateAdd={dateAdd}
          />
        </div>
        <DataTable
          className="Emp_att"
          value={attendanceData}
          editMode="row"
          dataKey="id"
          onRowEditComplete={onRowEditComplete}
          scrollable
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          globalFilter={globalFilter ? globalFilter.trim() : ""}
          removableSort
        >
          <Column
            field="date"
            header="Date"
            editor={(options) => textEditor(options)}
            style={{ width: "80px", whiteSpace: "nowrap" }}
            body={(rowData) => moment(rowData.date).format("DD-MM-YYYY")}
            sortable
          ></Column>
          <Column
            header="Name"
            style={{ textTransform: "capitalize" }}
            className="font-bold"
            body={(rowData) => {
              const user =
                Array.isArray(employeeData) &&
                employeeData.find((emp) => emp.empid === rowData.userid);

              return (
                <>
                  <Tooltip
                    target=".link-without-color"
                    mouseTrack
                    mouseTrackLeft={5}
                    mouseTrackTop={5}
                  >
                    Click to view details
                  </Tooltip>
                  <Link
                    className="link-without-color"
                    to={`/profile/${rowData.userid}`}
                    state={{ activeTab: 1 }}
                  >
                    {user ? `${user.firstname} ${user.lastname}` : ""}
                  </Link>
                </>
              );
            }}
          />

          {/* <Column
            header="Name"
            // frozen
            className="font-bold"
            body={(rowData) => {
              const user =
                Array.isArray(employeeData) &&
                employeeData.find((emp) => emp.empid === rowData.userid);
              return user ? `${user.firstname} ${user.lastname}` : "";
            }}
            style={{ minWidth: "130px" }}
          ></Column> */}
          <Column
            field="department"
            header="Department"
            body={(rowData) => {
              const user =
                Array.isArray(employeeData) &&
                employeeData.find((emp) => emp.empid === rowData.userid);
              const departmentId = user ? parseInt(user.department) : null;
              const department = departmentId
                ? departmentData.find(
                  (dept) => dept.department_id === departmentId
                )
                : null;
              return department ? department.department_name : "-";
            }}
            editor={(options) => textEditor(options)}
            style={{ minWidth: "130px" }}
          ></Column>
          <Column
            field="check_in"
            header="Check In"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "140px" }}
            sortable
          ></Column>
          <Column
            field="check_out"
            header="Check Out"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "140px" }}
            sortable
          ></Column>
          <Column
            field="workinghours"
            header="Working Hours"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "170px" }}
            sortable
          ></Column>
          <Column
            header="CI-img"
            body={(rowData) => renderImageWithIcon(rowData.check_in_img, null, ASSETS_IMAGES_API.ASSETS_IMAGES)}
            style={{ textAlign: "center" }}
          />
          <Column
            header="CO-img"
            body={(rowData) => renderImageWithIcon(rowData.check_out_img, null, ASSETS_IMAGES_API.ASSETS_IMAGES)}
            style={{ textAlign: "center" }}
          />
          <Column
            header="CI-Device"
            body={(rowData) => renderImageWithIcon(null, rowData.check_in_device, ASSETS_IMAGES_API.ASSETS_IMAGES)}
            style={{ textAlign: "center" }}
          />
          <Column
            header="CO-Device"
            body={(rowData) => renderImageWithIcon(null, rowData.check_out_device, ASSETS_IMAGES_API.ASSETS_IMAGES)}
            style={{ textAlign: "center" }}
          />

          <Column
            field="status"
            header="Status"
            editor={(options) => textEditor(options)}
            style={{ width: "100px" }}
          ></Column>
          <Column
            header="Actions"
            body={(rowData) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  icon="fi fi-bs-pencil"
                  className="p-button-rounded p-button-text"
                  onClick={() => openEditModal(rowData)}
                />
                {rowData.check_out ? (
                  <Button
                    tooltip="Checked Out"
                    tooltipOptions={{
                      position: "bottom",
                      mouseTrack: true,
                      mouseTrackTop: 15,
                    }}
                    icon="fi fi-rs-log-out"
                    className="p-button-rounded p-button-text"
                    style={{ color: "red" }}
                  />
                ) : (
                  <Button
                    icon="fi fi-rs-log-out"
                    className="p-button-rounded p-button-text"
                    onClick={() => openConfirmModal(rowData)}
                    style={{ color: "green" }}
                  />
                )}
                <Button
                  icon="pi pi-trash"
                  className="p-button-rounded p-button-text"
                  onClick={() => openDeleteConfirmation(rowData)}
                  style={{ color: "red" }}
                />
                <Dialog
                  visible={displayConfirmModal}
                  onHide={() => setDisplayConfirmModal(false)}
                  header="Confirm Action"
                >
                  <div>Today logout time will be at {currentTime}</div>
                  <div className="p-dialog-footer flex mt-4">
                    <Button
                      label="No"
                      icon="pi pi-times"
                      className="p-button-text"
                      onClick={handleCancel}
                    />
                    <Button
                      label="Yes"
                      icon="pi pi-check"
                      className="p-button-text"
                      onClick={handleConfirm}
                    />
                  </div>
                </Dialog>
              </div>
            )}
            style={{ minWidth: "150px" }}
          />
        </DataTable>
      </div>
      {/* edit model */}
      <Dialog
        header="Edit Attendance"
        style={{ width: "55vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        visible={displayEditModal}
        onHide={() => setDisplayEditModal(false)}
      >
        <div className="grid p-fluid">
          <div className="field col-12">
            <label htmlFor="date">Date</label>
            <InputText
              id="date"
              type="date"
              value={editData.currentDate}
              onChange={(e) =>
                setEditData({ ...editData, currentDate: e.target.value })
              }
              max={today}
              disabled
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="checkIn">Check In</label>
            <InputText
              id="checkIn"
              value={editData.check_in}
              onChange={(e) => handleTimeChange(e, "check_in")}
            />
            {checkInError && (
              <span className="text-red-500">{checkInError}</span>
            )}
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="checkOut">Check Out</label>
            <InputText
              id="checkOut"
              value={editData.check_out}
              onChange={(e) => handleTimeChange(e, "check_out")}
            />
            {checkOutError && (
              <span className="text-red-500">{checkOutError}</span>
            )}
          </div>
          <div className="field col-12">
            <label htmlFor="workingHours">Working Hours</label>
            <InputText
              id="workingHours"
              value={editData.workinghours}
              onChange={(e) =>
                setEditData({ ...editData, workinghours: e.target.value })
              }
              disabled
            />
          </div>
          <div className="p-field col-12">
            <label htmlFor="attendanceStatus">Status</label>

            <div className="grid">
              {attendanceOptions.map((option) => (
                <div
                  key={option.value}
                  className="flex col-12 sm:col-4 md:col-3 align-items-center"
                >
                  <input
                    type="radio"
                    id={`attendanceStatus_${option.value}`}
                    name="attendanceStatus"
                    value={option.value}
                    checked={editData.status === option.value}
                    onChange={handleStatusChange}
                    className={`${errors.attendanceStatus} ? 'p-error' : '' w-auto `}
                  />
                  <label
                    className="font-normal "
                    style={{ whiteSpace: "nowrap" }}
                    htmlFor={`attendanceStatus_${option.value}`}
                  >
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
            {errors.attendanceStatus && (
              <small className="p-error">{errors.attendanceStatus}</small>
            )}
          </div>
        </div>
        <div className="flex justify-content-end mt-4">
          <Button
            label="Cancel"
            icon="pi pi-times"
            className="p-button-text"
            onClick={() => setDisplayEditModal(false)}
          />
          <Button
            label="Submit"
            icon="fi fi-bs-check"
            onClick={handleEditSubmit}
            disabled={isSubmitting}
          />
        </div>
      </Dialog>
      <Dialog
        visible={displayDeleteConfirmation}
        onHide={() => setDisplayDeleteConfirmation(false)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        header="Confirmation"
        footer={
          <div>
            <Button
              style={{ color: "white", background: "red" }}
              label="No"
              icon="pi pi-times"
              className="p-button-text"
              onClick={() => setDisplayDeleteConfirmation(false)}
            />
            <Button
              style={{ color: "white", background: "#22c55e" }}
              label="Yes"
              icon="pi pi-check"
              className="p-button-text"
              onClick={handleDelete}
            />
          </div>
        }
      >
        Are you sure you want to delete the Attendance data?
      </Dialog>
    </div>
  );
}
