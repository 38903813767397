import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { HOLIDAYS_API } from "../../constants/api";
import axios from "axios";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";

function UserHolidays() {
  const [holidays, setHolidays] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  useEffect(() => {
    getAllHolidays();
  }, []);

  const getAllHolidays = async () => {
    try {
      const response = await axios.get(HOLIDAYS_API.HOLIDAYS_GET);
      setHolidays(response.data);
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  return (
    <div>
      <div className="md:flex block justify-content-between mt-4">
        <div className="ml-2">
          <h2>Holidays</h2>
        </div>
        <div className="md:col-3 col-12">
          <div className="p-inputgroup">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div className="card">
        <DataTable
          value={holidays}
          globalFilter={globalFilter.trim() ? globalFilter : null}
          paginator
          rows={10}
        >
          <Column
            field="holiday_name"
            header="Name"
            bodyStyle={{ width: "100px" }}
          />
          <Column
            field="holiday_date"
            header="Date"
            bodyStyle={{ width: "90px" }}
            body={(rowData) =>
              moment(rowData.holiday_date).format("DD-MM-YYYY")
            }
          />
          <Column
            field="holiday_type"
            header="Type"
            bodyStyle={{ width: "90px" }}
          />
          <Column
            field="holiday_details"
            header="Details"
            bodyStyle={{ width: "240px" }}
            body={(rowData) => (
              <div
                id={`reason_${rowData.holiday_id}`}
                className="tooltip-container"
              >
                {rowData.holiday_details.length > 20
                  ? `${rowData.holiday_details.slice(0, 60)}...`
                  : rowData.holiday_details}
                <Tooltip
                  target={`#reason_${rowData.holiday_id}`}
                  position="left"
                  style={{ width: "300px" }}
                >
                  {rowData.holiday_details}
                </Tooltip>
              </div>
            )}
          />
        </DataTable>
      </div>
    </div>
  );
}

export default UserHolidays;
