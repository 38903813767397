import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { assetsData as assets } from '../Assets/data/assetsData';
import { ASSETS_API, EMPLOYEE_API } from '../../constants/api';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import moment from 'moment';
import { Tooltip } from 'primereact/tooltip';
import AdminAssetsModal from './AdminAssetsModal';

const AllAssetsRequest = () => {
    const [visible, setVisible] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [date, setDate] = useState('');
    const [description, setDescription] = useState('');
    const [requests, setRequests] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [errors, setErrors] = useState({ asset: '', date: '', description: '' });
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    const toast = useRef(null);
    const menuRef = useRef(null);

    const fetchEmployeeData = () => {
        axios.get(EMPLOYEE_API.GET_ALL)
            .then(response => {
                setEmployeeOptions(response.data.usersData.map(employee => ({
                    label: `${employee.firstname} ${employee.lastname}`,
                    value: employee.empid
                })));
            })
            .catch(error => {
                console.error('Error fetching employee data:', error);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch employees', life: 3000 });
            });
    };

    const fetchData = async () => {
        axios.get(ASSETS_API.GET_REQUEST_ALL)
            .then(response => {
                setRequests(response.data);
            })
            .catch(error => {
                console.error('Error fetching requests:', error);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch requests', life: 3000 });
            });
    };

    useEffect(() => {
        fetchData();
        fetchEmployeeData();
    }, []);

    const openModal = () => setVisible(true);
    const closeModal = () => {
        setVisible(false);
        resetForm();
        setErrors({ asset: '', date: '', description: '' });
    };

    const resetForm = () => {
        setSelectedAsset(null);
        setSelectedModel(null);
        setSelectedBrand(null);
        setDate('');
        setDescription('');
        setEditIndex(null);
        setSelectedEmployee(null);
    };

    const validateForm = () => {
        const newErrors = { asset: '', date: '', description: '', employee: '' };
        let valid = true;

        if (!selectedAsset) {
            newErrors.asset = 'Asset is required';
            valid = false;
        }
        if (!date) {
            newErrors.date = 'Date is required';
            valid = false;
        }
        if (!description.trim()) {
            newErrors.description = 'Description cannot be empty';
            valid = false;
        }
        if (!selectedEmployee) {
            newErrors.employee = 'Employee is required';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            const newRequest = {
                assets: selectedAsset,
                model: selectedModel,
                brand: selectedBrand,
                before_date: date,
                description,
                empid: selectedEmployee
            };
            if (editIndex !== null) {
                axios.put(ASSETS_API.ASSETS_REQUEST_UPDATE(requests[editIndex].id), newRequest)
                    .then(response => {
                        fetchData();
                        toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Request updated successfully', life: 3000 });
                        closeModal();
                    })
                    .catch(error => {
                        console.error('Error updating request:', error);
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update request', life: 3000 });
                    });
            } else {
                axios.post(ASSETS_API.ASSETS_REQUEST_ADD, newRequest)
                    .then(response => {
                        fetchData();
                        toast.current.show({ severity: 'success', summary: 'Added', detail: 'Request added successfully', life: 3000 });
                        closeModal();
                    })
                    .catch(error => {
                        console.error('Error adding request:', error);
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to add request', life: 3000 });
                    });
            }
        }
    };

    const handleEdit = (rowData, index) => {
        setSelectedAsset(rowData.assets);
        setSelectedModel(rowData.model);
        setSelectedBrand(rowData.brand);
        setDate(rowData.before_date);
        setDescription(rowData.description);
        setEditIndex(index);
        setSelectedEmployee(rowData.empid);
        openModal();
    };

    const handleDelete = (index) => {
        setDeleteIndex(index);
        setConfirmDeleteVisible(true);
    };

    const confirmDelete = () => {
        axios.delete(ASSETS_API.ASSETS_REQUEST_DELETE(requests[deleteIndex].id))
            .then(() => {
                fetchData();
                setConfirmDeleteVisible(false);
                toast.current.show({ severity: 'success', summary: 'Deleted', detail: 'Request deleted successfully', life: 3000 });
            })
            .catch(error => {
                console.error('Error deleting request:', error);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete request', life: 3000 });
            });
    };

    const cancelDelete = () => setConfirmDeleteVisible(false);

    const clearError = (field) => {
        setErrors(prevErrors => ({ ...prevErrors, [field]: '' }));
    };

    const assetOptions = Array.from(new Set(assets.map(asset => asset.asset_name))).map(name => ({ label: name, value: name }));

    const brandOptions = selectedAsset ? Array.from(new Set(assets.filter(asset => asset.asset_name === selectedAsset).map(asset => asset.brand))).map(brand => ({ label: brand, value: brand })) : [];

    const modelOptions = selectedAsset && selectedBrand ? Array.from(new Set(assets.filter(asset => asset.asset_name === selectedAsset && asset.brand === selectedBrand).map(asset => asset.model))).map(model => ({ label: model, value: model })) : [];

    const handleContextMenu = (event, rowData) => {
        event.preventDefault();
        setSelectedRequest(rowData);
        menuRef.current.toggle(event);
    };

    const menuItems = [
        {
            label: "Edit",
            icon: "pi pi-pencil",
            command: () => {

                handleEdit(selectedRequest, selectedRequest.index);
            },
        },
        {
            label: "Approve",
            icon: "pi pi-check",
            command: () => updateStatus(selectedRequest.id, "approved"),
        },
        {
            label: "Reject",
            icon: "pi pi-times",
            command: () => updateStatus(selectedRequest.id, "rejected"),
        },
        {
            label: "Pending",
            icon: "pi pi-clock",
            command: () => updateStatus(selectedRequest.id, "pending"),
        },
        {
            label: "Delete",
            icon: "pi pi-trash",
            command: () => {
                setDeleteIndex(requests.findIndex(row => row.id === selectedRequest.id));
                setConfirmDeleteVisible(true);
            },
        },
    ];

    const updateStatus = (id, status) => {
        axios.put(ASSETS_API.ASSETS_REQUEST_UPDATE_STATUS(id), { status: status })
            .then(response => {
                fetchData();
                toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Status updated successfully', life: 3000 });
            })
            .catch(error => {
                console.error('Error updating status:', error);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update status', life: 3000 });
            });
    };

    const actionBodyTemplate = (rowData, { rowIndex }) => (
        <div className="flex" style={{ alignItems: "center" }}>
            <Button
                icon="pi pi-ellipsis-v"
                className="p-button-rounded p-button-text"
                aria-label="More"
                onClick={(e) => handleContextMenu(e, { ...rowData, index: rowIndex })}
            />
            <Menu className='w-8rem' model={menuItems} popup ref={menuRef} />
        </div>
    );

    const getStatusColor = (rowData) => {
        switch (rowData.status) {
            case "pending":
                return {
                    borderColor: "#91CAFF",
                    backgroundColor: "#E6F4FF",
                    color: "#4280E3",
                };
            case "approved":
                return {
                    borderColor: "#B7EB8F",
                    backgroundColor: "#F6FFED",
                    color: "#389E0D",
                };
            case "rejected":
                return {
                    borderColor: "#FF3D32",
                    backgroundColor: "#FAFAFA",
                    color: "#FF3D32",
                };
            case "request":
                return {
                    borderColor: "#fd7e14",
                    backgroundColor: "#FAFAFA",
                    color: "#fd7e14",
                };
            default:
                return {
                    borderColor: "#ffbf00",
                    backgroundColor: "#E6F4FF",
                    color: "#4280E3",
                };
        }
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="col-12 md:flex block justify-content-between w-full align-items-center">
                <h2 className="my-1">All Assets Request</h2>
                <div className="md:col-5 lg:col-2 col-12 mt-0">
                    <div className="p-inputgroup">
                        <InputText type="text" id="globalFilter" className="search-input" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
                        <Button icon="fi fi-bs-search" className="search-icon" />
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="flex w-full justify-content-end mb-2">
                    <Button icon="pi pi-plus" tooltip="Add Request" onClick={openModal} className="" tooltipOptions={{ position: 'left' }} />
                </div>
                <AdminAssetsModal
                    visible={visible}
                    onHide={closeModal}
                    onSubmit={handleSubmit}
                    selectedAsset={selectedAsset}
                    setSelectedAsset={setSelectedAsset}
                    selectedModel={selectedModel}
                    setSelectedModel={setSelectedModel}
                    selectedBrand={selectedBrand}
                    setSelectedBrand={setSelectedBrand}
                    date={date}
                    setDate={setDate}
                    description={description}
                    setDescription={setDescription}
                    employeeOptions={employeeOptions}
                    selectedEmployee={selectedEmployee}
                    setSelectedEmployee={setSelectedEmployee}
                    assetOptions={assetOptions}
                    modelOptions={modelOptions}
                    brandOptions={brandOptions}
                    errors={errors}
                    clearError={clearError}
                    editIndex={editIndex}
                />
                <DataTable value={requests} paginator rows={10} rowsPerPageOptions={[10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                    globalFilter={globalFilter} emptyMessage="No data found">
                    <Column header="S.No" body={(rowData) => <span>{requests.indexOf(rowData) + 1}</span>} />
                    <Column header="Name" style={{ minWidth: '120px' }} body={(rowData) => {
                        return rowData.firstname && rowData.lastname
                            ? `${rowData.firstname} ${rowData.lastname}`
                            : '-'
                    }} />
                    <Column field="assets" style={{ minWidth: '120px' }} header="Asset" sortable />
                    <Column field="model" style={{ minWidth: '120px' }} header="Model" sortable body={(rowData) => rowData.model || '-'} />
                    <Column field="brand" style={{ minWidth: '120px' }} header="Brand" sortable body={(rowData) => rowData.brand || '-'} />
                    <Column field="before_date" style={{ minWidth: '120px' }} header="Before" body={rowData => moment(rowData.before_date).format("DD-MM-YYYY")} />
                    <Column field="description" style={{ minWidth: '120px' }} header="Description" sortable body={(rowData) => (
                        <div>
                            <Tooltip target={`.description-tooltip-${rowData.id}`} content={rowData.description || 'No description'} position='left' />
                            <span className={`description-tooltip-${rowData.id}`} style={{ cursor: 'pointer' }}>
                                {rowData.description ? rowData.description.substring(0, 20) + (rowData.description.length > 20 ? '...' : '') : '-'}
                            </span>
                        </div>
                    )} />
                    <Column field="status" header="Status" body={(rowData) => (
                        <span
                            style={{
                                border: "2px solid",
                                ...getStatusColor(rowData),
                                padding: "6px 12px",
                                borderRadius: "4px",
                                position: "relative",
                                cursor: "pointer"
                            }}
                        >
                            {rowData?.status?.charAt(0)?.toUpperCase() +
                                rowData?.status?.slice(1)?.toLowerCase()}
                        </span>
                    )} />
                    <Column header="Action" body={actionBodyTemplate} className='action-button' />
                </DataTable>
            </div>
            <Dialog visible={confirmDeleteVisible} onHide={cancelDelete} header="Delete Confirmation" footer={<div>
                <Button label="No" icon="pi pi-times" onClick={cancelDelete} className="p-button-text" />
                <Button label="Yes" icon="pi pi-check" onClick={confirmDelete} className="p-button-danger" />
            </div>}>
                <p>Are you sure you want to delete this request?</p>
            </Dialog>
        </div>
    );
};

export default AllAssetsRequest;
