import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { DEPARTMENT_API } from "../../constants/api";
import axios from "axios";

const EditProfileModal = ({
  showEditModal,
  setShowEditModal,
  editedData,
  setEditedData,
  errors,
  setErrors,
  saveChanges,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState(
    editedData?.contact_number ?? ""
  );
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);

  const steps = [{ label: "Personal Information" }];

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    if (value.replace(/\D/g, "").length < 10) {
      setPhoneNumberValid(false);
    } else {
      setPhoneNumberValid(true);
    }
    setEditedData({ ...editedData, contact_number: value });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSaveChanges = () => {
    let isValid = true;
    const newErrors = {};

    if (!editedData.firstname) {
      newErrors.firstname = "First name is required";
      isValid = false;
    }
    if (!editedData.lastname) {
      newErrors.lastname = "Last name is required";
      isValid = false;
    }
    if (!editedData.contact_number) {
      newErrors.contact_number = "Contact number is required";
      isValid = false;
    }
    if (!editedData.DOB) {
      newErrors.DOB = "Date of Birth is required";
      isValid = false;
    }
    if (!editedData.gender) {
      newErrors.gender = "Gender is required";
      isValid = false;
    }
    if (!editedData.marital_status) {
      newErrors.marital_status = "Marital status is required";
      isValid = false;
    }
    if (!editedData.address) {
      newErrors.address = "Address is required";
      isValid = false;
    }
    if (!editedData.house_no) {
      newErrors.house_no = "House No is required";
      isValid = false;
    }
    if (!editedData.state) {
      newErrors.state = "state is required";
      isValid = false;
    }
    if (!editedData.district) {
      newErrors.district = "district is required";
      isValid = false;
    }
    if (!editedData.pin_code) {
      newErrors.pin_code = "pin code is required";
      isValid = false;
    }
    setErrors(newErrors);

    if (isValid) {
      if (currentStep === 0) {
        saveChanges();
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  return (
    <Dialog
      visible={showEditModal}
      onHide={() => setShowEditModal(false)}
      style={{ width: "60vw" }}
      header="Edit Profile"
      footer={
        <div>
          {currentStep !== 0 && (
            <Button
              label="Back"
              icon="pi pi-arrow-left"
              className="p-button-text"
              onClick={() => setCurrentStep(currentStep - 1)}
            />
          )}
          <Button
            label={currentStep === 0 ? "Save" : "Next"}
            icon={currentStep === 0 ? "pi pi-check" : "pi pi-arrow-right"}
            className="p-button-success"
            onClick={handleSaveChanges}
          />
          <Button
            label="Cancel"
            className="p-button-danger"
            onClick={() => setShowEditModal(false)}
          />
        </div>
      }
    >
      <div className="">
        <Steps model={steps} activeIndex={currentStep} />
        {currentStep === 0 && (
          <div className="mt-4">
            <h5></h5>
            <div className="grid">
              <div className="col-6">
                <label htmlFor="firstname">First Name</label>
                <InputText
                  id="firstname"
                  keyfilter={/^[a-zA-Z\s]*$/}
                  value={capitalizeFirstLetter(editedData.firstname)}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      firstname: e.target.value,
                    })
                  }
                  className={errors.firstname ? "p-invalid" : ""}
                />
                {errors.firstname && (
                  <small className="p-error">{errors.firstname}</small>
                )}
              </div>
              <div className="col-6">
                <label htmlFor="lastname">Last Name</label>
                <InputText
                  id="lastname"
                  keyfilter={/^[a-zA-Z\s]*$/}
                  value={capitalizeFirstLetter(editedData.lastname)}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      lastname: e.target.value,
                    })
                  }
                  className={errors.lastname ? "p-invalid" : ""}
                />
                {errors.lastname && (
                  <small className="p-error">{errors.lastname}</small>
                )}
              </div>
              <div className="col-4">
                <label htmlFor="dateOfBirth">Profile</label>
                <input type="file" id="dateOfBirth" />
              </div>
              <div className="col-2"></div>
              <div className="col-6">
                <label>Mobile</label>
                <PhoneInput
                  country={"in"}
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  inputProps={{
                    required: true,
                  }}
                  inputStyle={{
                    border: phoneNumberValid ? "" : "1px solid red",
                  }}
                />
                {!phoneNumberValid && (
                  <small className="p-error">
                    Please enter a valid phone number.
                  </small>
                )}
              </div>
              <div className="col-4">
                <label htmlFor="dateOfBirth">Date of Birth</label>
                <InputText
                  type="date"
                  id="dateOfBirth"
                  value={editedData.DOB}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      DOB: e.target.value,
                    })
                  }
                  className={errors.DOB ? "p-invalid" : ""}
                />
                {errors.DOB && <small className="p-error">{errors.DOB}</small>}
              </div>
              <div className="col-4">
                <label htmlFor="gender">Gender</label>
                <Dropdown
                  className="w-full"
                  id="gender"
                  value={editedData.gender}
                  options={[
                    { label: "Male", value: "Male" },
                    { label: "Female", value: "Female" },
                    { label: "Transgender", value: "Transgender" },
                  ]}
                  onChange={(e) =>
                    setEditedData({ ...editedData, gender: e.value })
                  }
                />
                {errors.gender && (
                  <small className="p-error">{errors.gender}</small>
                )}
              </div>
              <div className="col-4">
                <label htmlFor="maritalStatus">Marital Status</label>
                <Dropdown
                  className="w-full"
                  id="maritalStatus"
                  value={editedData.marital_status}
                  options={[
                    { label: "Single", value: "Single" },
                    { label: "Married", value: "Married" },
                  ]}
                  onChange={(e) =>
                    setEditedData({ ...editedData, marital_status: e.value })
                  }
                />
                {errors.marital_status && (
                  <small className="p-error">{errors.marital_status}</small>
                )}
              </div>
              <div className="col-2">
                <label htmlFor="house_no">House No</label>
                <InputText
                  id="house_no"
                  value={editedData.house_no}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      house_no: e.target.value,
                    })
                  }
                  className={errors.house_no ? "p-invalid" : ""}
                />
                {errors.house_no && (
                  <small className="p-error">{errors.house_no}</small>
                )}
              </div>
              <div className="col-10">
                <label htmlFor="address">Address</label>
                <InputTextarea
                  id="address"
                  value={editedData.address}
                  onChange={(e) =>
                    setEditedData({ ...editedData, address: e.target.value })
                  }
                  rows={2}
                  cols={30}
                  className={errors.address ? "p-invalid" : ""}
                  style={{ resize: "none" }}
                />
                {errors.address && (
                  <small className="p-error">{errors.address}</small>
                )}
              </div>
              <div className="col-4">
                <label htmlFor="state">State</label>
                <InputText
                  id="state"
                  keyfilter={/^[a-zA-Z\s]*$/}
                  value={editedData.state}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      state: e.target.value,
                    })
                  }
                  className={errors.state ? "p-invalid" : ""}
                />
                {errors.state && (
                  <small className="p-error">{errors.state}</small>
                )}
              </div>
              <div className="col-4">
                <label htmlFor="district">District</label>
                <InputText
                  id="district"
                  keyfilter={/^[a-zA-Z\s]*$/}
                  value={editedData.district}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      district: e.target.value,
                    })
                  }
                  className={errors.district ? "p-invalid" : ""}
                />
                {errors.district && (
                  <small className="p-error">{errors.district}</small>
                )}
              </div>
              <div className="col-4">
                <label htmlFor="pincode">Postal Code</label>
                <InputText
                  id="pincode"
                  value={editedData.pin_code}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      pin_code: e.target.value.replace(/\D/g, "").slice(0, 6),
                    })
                  }
                  className={errors.pin_code ? "p-invalid" : ""}
                />

                {errors.pin_code && (
                  <small className="p-error">{errors.pin_code}</small>
                )}
              </div>
              <div className="col-12">
                <label htmlFor="about">About</label>
                <InputTextarea
                  id="about"
                  value={editedData.about}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      about: e.target.value,
                    })
                  }
                  rows={3}
                  cols={30}
                  style={{ resize: "none" }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default EditProfileModal;