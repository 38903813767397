import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import Select from "react-select";
import axios from "axios";
import { Tooltip } from "primereact/tooltip";
import { PROJECT_API, EMPLOYEE_API } from "../../constants/api";
import moment from "moment/moment";

const Project = () => {
  const [products, setProducts] = useState([]);
  const [edit, setEdit] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);
  const [deadline, setDeadline] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.All_USERS);
      const { usersData } = response.data;
      setEmployees(usersData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const handleEmployeeSelectChange = (selectedOptions) => {
    setSelectedEmployeeIds(selectedOptions.map((option) => option.value));
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(PROJECT_API.GET_ALL_PROJECTS);
      const filteredProjects = response.data.filter(
        (project) => project.project_status.trim() !== "Completed"
      );
      setProducts(filteredProjects);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const hideConfirmationModal = () => {
    setDisplayConfirmation(false);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(PROJECT_API.DELETE_PROJECT(selectedProjectId));
      fetchData();
      setDisplayConfirmation(false);
    } catch (error) {
      console.error(error);
    }
  };

  const actionTemplate = (rowData) => {
    const handleDelete = (rowDataToDelete) => {
      setSelectedProjectId(rowDataToDelete.project_id);
      setDisplayConfirmation(true);
    };

    return (
      <div>
        <Button
          tooltip="Edit"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-pencil"
          className="p-button-rounded bg-blue-500 "
          onClick={() => handlepass(rowData)}
        />
        <Button
          tooltip="Delete"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-trash"
          onClick={() => handleDelete(rowData)}
          className="p-button-rounded p-button-danger mx-2"
        />
      </div>
    );
  };

  const [visible, setVisible] = useState(false);
  const [project, setProject] = useState(null);
  const [department, setDepartment] = useState(null);
  const Department = [
    { name: "Web Development" },
    { name: "Information Technology" },
    { name: "Software Development" },
    { name: "Quality Assurance" },
    { name: "Development" },
    { name: "Technical Support" },
    { name: "Data Science" },
  ];

  const [priority, setPriority] = useState(null);
  const Priority = [{ name: "High" }, { name: "Medium" }, { name: "Low" }];

  const [status, setStatus] = useState(null);
  const Status = [
    { name: "Initialized" },
    { name: "Active" },
    { name: "Completed" },
    { name: "Running" },
    { name: "Cancelled" },
  ];

  const [value, setValue] = useState("");

  const resetForm = () => {
    setProject(null);
    setDepartment(null);
    setPriority(null);
    setCreatedAt(null);
    setDeadline(null);
    setStatus(null);
    setValue("");
    setSelectedEmployeeIds([]);
  };

  const handlepass = (data) => {
    setEdit(true);
    setSelectedProjectId(data.project_id);
    setProject(data.project_name);
    setDepartment({ name: data.project_department });
    setPriority({ name: data.project_priority });
    setStatus({ name: data.project_status });
    setValue(data.project_description);
    setCreatedAt(data.created_at);
    setDeadline(data.deadline);

    if (employees && employees.length > 0) {
      const teamMemberIds = data.teammembers.split(",");
      setSelectedEmployeeIds(
        teamMemberIds
          .map((id) => id.trim())
          .filter((id) => employees.find((emp) => emp.id === id))
      );
    }
  };

  const [isFormValid, setIsFormValid] = useState(false);

  const checkFormValidity = () => {
    return (
      project !== null &&
      department !== null &&
      priority !== null &&
      status !== null &&
      value.trim() !== "" &&
      selectedEmployeeIds.length > 0
    );
  };

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [project, department, priority, status, value, selectedEmployeeIds]);

  const handleSubmit = async () => {
    try {
      const payload = {
        project_name: project,
        project_department: department.name,
        project_priority: priority.name,
        created_at: new Date().toISOString(),
        deadline: new Date().toISOString(),
        project_description: value,
        teammembers: selectedEmployeeIds.join(","),
        project_status: status.name,
      };

      await axios.post(PROJECT_API.ADD_PROJECT, payload);
      setVisible(false);
      fetchData();
      resetForm();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = async () => {
    try {
      const payload = {
        project_name: project,
        project_department: department.name,
        project_priority: priority.name,
        created_at: new Date().toISOString(),
        deadline: new Date().toISOString(),
        project_description: value,
        teammembers: selectedEmployeeIds.join(","),
        project_status: status.name,
      };
      await axios.put(PROJECT_API.EDIT_PROJECT(selectedProjectId), payload);
      setEdit(false);
      fetchData();
      resetForm();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    resetForm();
  };
  const getPriorityClassName = (priority) => {
    switch (priority) {
      case "High":
        return "high-priority";
      case "Medium":
        return "medium-priority";
      case "Low":
        return "low-priority";
      default:
        return "";
    }
  };
  function getTomorrowDate() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, "0");
    const day = String(tomorrow.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const tomorrow = getTomorrowDate();
  return (
    <div className="project">
      <div className="md:flex  justify-content-between">
        <div className="ml-2">{/* <h2>Projects</h2> */}</div>
      </div>
      <div>
        <div className="md:flex  justify-content-between mt-3">
          <div className="md:col-3 col-12">
            <div className="p-inputgroup">
              <div className="input-wrapper">
                <i className="fi fi-rs-search search-icon"></i>
                <InputText
                  type="text"
                  className="search-input p-inputtext p-component"
                  placeholder="Search"
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                />
              </div>
              {/* <Button icon="pi pi-search" className="search-icon" /> */}
            </div>
          </div>
          <div className="flex justify-content-end mb-3 align-items-center">
            <Button
              label=""
              icon="fi fi-br-plus"
              onClick={() => setVisible(true)}
            ></Button>
          </div>
        </div>
        <DataTable
          value={products}
          paginator
          rows={5}
          tableStyle={{ minWidth: "50rem" }}
          globalFilter={globalFilter.trim() ? globalFilter : null}
          removableSort
        >
          <Column
            field="project_name"
            header="Project Name"
            style={{
              width: "160px",
              textTransform: "capitalize",
            }}
            sortable
          ></Column>
          <Column
            field="project_department"
            header="Department"
            style={{
              width: "140px",
              textTransform: "capitalize",
            }}
            sortable
          ></Column>
          <Column
            field="project_priority"
            header="Priority"
            style={{
              width: "100px",
              textTransform: "capitalize",
            }}
            body={(rowData) => {
              const priorityClassName = getPriorityClassName(
                rowData.project_priority
              );
              return (
                <div className={priorityClassName}>
                  {rowData.project_priority}
                </div>
              );
            }}
          ></Column>
          <Column
            field="created_at"
            header="Start Date"
            style={{
              minWidth: "110px",
            }}
            body={(rowData) => moment(rowData.created_at).format("DD-MM-YYYY")}
            sortable
          ></Column>
          <Column
            header="End Date"
            field="deadline"
            style={{
              minWidth: "110px",
            }}
            body={(rowData) => moment(rowData.deadline).format("DD-MM-YYYY")}
            sortable
          ></Column>
          <Column
            field="project_description"
            header="Description"
            style={{
              minWidth: "140px",
              textTransform: "capitalize",
            }}
            body={(rowData) => (
              <div
                id={`reason_${rowData.project_id}`}
                className="tooltip-container"
              >
                {rowData.project_description.length > 20
                  ? `${rowData.project_description.slice(0, 20)}...`
                  : rowData.project_description}
                <Tooltip
                  target={`#reason_${rowData.project_id}`}
                  position="left"
                  style={{ width: "300px" }}
                >
                  {rowData.project_description}
                </Tooltip>
              </div>
            )}
          ></Column>
          <Column
            field="teammembers"
            header="Team Members"
            body={(rowData) => {
              const teamMemberIds = rowData.teammembers.split(",");
              const teamMembers = teamMemberIds
                .map((id) => employees.find((emp) => emp.id === id.trim()))
                .filter((emp) => emp !== undefined)
                .map((emp) => `${emp.firstname} ${emp.lastname}`)
                .join(", ");
              return teamMembers;
            }}
            style={{
              width: "180px",
              textTransform: "capitalize",
            }}
          />
          <Column
            field="project_status"
            header="Status"
            style={{
              width: "120px",
              textTransform: "capitalize",
            }}
            sortable
          ></Column>
          <Column
            header="Actions"
            body={actionTemplate}
            bodyStyle={{ minWidth: "160px" }}
          ></Column>
        </DataTable>
      </div>
      {/* Add Task*/}
      <Dialog
        visible={visible}
        modal
        showHeader={false}
        style={{ width: "45rem" }}
        onHide={() => setVisible(false)}
        className="taskdetail"
      >
        <h3>Add Project</h3>
        <div className="grid mt-2">
          <div className="md:col-8 col-12 ml-0">
            <label htmlFor="username">Project Title</label>
            <InputText
              value={project}
              onChange={(e) => setProject(e.target.value)}
              placeholder="Project name"
              className="w-full"
            />
          </div>
          <div className="md:col-6 col-12">
            <label htmlFor="username">Department</label>
            <Dropdown
              value={department}
              onChange={(e) => setDepartment(e.value)}
              options={Department}
              optionLabel="name"
              placeholder="Select a department"
              className="w-full "
            />
          </div>
          <div className="md:col-6 col-12">
            <label htmlFor="username">Priority</label>
            <Dropdown
              value={priority}
              onChange={(e) => setPriority(e.value)}
              options={Priority}
              optionLabel="name"
              placeholder="Select a priority"
              className="w-full "
            />
          </div>
          <div className="md:col-6 col-12">
            <label htmlFor="username">Created On</label>
            <InputText
              type="date"
              name="CreatedAt"
              id="CreatedAt"
              value={new Date().toISOString().slice(0, 10)}
              disabled
            />
          </div>
          <div className="md:col-6 col-12">
            <label htmlFor="username">Due Date</label>
            <InputText
              type="date"
              name="Deadline"
              id="Deadline"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
              min={tomorrow}
            />
          </div>
          <div className="md:col-6 col-12">
            <label htmlFor="username">Team Members</label>
            <Select
              value={selectedEmployeeIds.map((empId) => ({
                label: `${
                  employees.find((emp) => emp.id === empId).firstname
                } ${employees.find((emp) => emp.id === empId).lastname}`,
                value: empId,
              }))}
              onChange={handleEmployeeSelectChange}
              options={employees.map((emp) => ({
                label: `${emp.firstname} ${emp.lastname}`,
                value: emp.id,
              }))}
              isMulti
              isClearable
              placeholder="Select team members"
              getOptionLabel={(option) => (
                <div className="flex align-items-center">{option.label}</div>
              )}
            />
          </div>
          <div className="md:col-6 col-12">
            <label htmlFor="username">Status</label>
            <Dropdown
              value={status}
              onChange={(e) => setStatus(e.value)}
              options={Status}
              optionLabel="name"
              placeholder="Select a status"
              className="w-full "
            />
          </div>
          <div className="col-12">
            <label htmlFor="username">Description</label>
            <InputTextarea
              autoResize
              value={value}
              onChange={(e) => setValue(e.target.value)}
              rows={2}
              cols={30}
              placeholder="Enter a project description..."
            />
          </div>
        </div>
        <div className="flex justify-content-end mt-4">
          <Button
            label="Cancel"
            className="p-button-danger"
            onClick={() => {
              handleCancel();
            }}
          />
          <Button
            label="Submit"
            className="mx-2"
            disabled={!isFormValid}
            onClick={handleSubmit}
          />
        </div>
      </Dialog>
      {/* edit model */}
      <Dialog
        visible={edit}
        modal
        showHeader={false}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setEdit(false)}
        className="taskdetail"
      >
        <h3>Edit Project</h3>
        <div className="grid mt-2">
          <div className="md:col-8 col-12">
            <label htmlFor="username">Project Title</label>
            <InputText
              value={project}
              onChange={(e) => setProject(e.target.value)}
              placeholder="Enter Project Title"
              className="w-full "
            />
          </div>
          <div className="md:col-6 col-12">
            <label htmlFor="username">Department</label>
            <Dropdown
              value={department}
              onChange={(e) => setDepartment(e.value)}
              options={Department}
              optionLabel="name"
              placeholder="Select a Department"
              className="w-full "
            />
          </div>
          <div className="md:col-6 col-12">
            <label htmlFor="username">Priority</label>
            <Dropdown
              value={priority}
              onChange={(e) => setPriority(e.value)}
              options={Priority}
              optionLabel="name"
              placeholder="Select a Priority"
              className="w-full "
            />
          </div>
          <div className="md:col-6 col-12">
            <label htmlFor="username">Created At</label>
            <InputText
              type="date"
              name="CreatedAt"
              id="CreatedAt"
              value={createdAt}
              onChange={(e) => setCreatedAt(e.value)}
              disabled
            />
          </div>
          <div className="md:col-6 col-12">
            <label htmlFor="username">Due Date</label>
            <InputText
              type="date"
              name="Deadline"
              id="Deadline"
              value={deadline}
              min={createdAt}
              onChange={(e) => setDeadline(e.value)}
            />
          </div>
          <div className="md:col-6 col-12">
            <label htmlFor="username">Team Members</label>
            <Select
              value={selectedEmployeeIds.map((empId) => ({
                label: `${
                  employees.find((emp) => emp.id === empId).firstname
                } ${employees.find((emp) => emp.id === empId).lastname}`,
                value: empId,
              }))}
              onChange={handleEmployeeSelectChange}
              options={employees.map((emp) => ({
                label: `${emp.firstname} ${emp.lastname}`,
                value: emp.id,
              }))}
              isMulti
              isClearable
              placeholder="Select team members"
              getOptionLabel={(option) => (
                <div className="flex align-items-center">{option.label}</div>
              )}
            />
          </div>
          <div className="md:col-6 col-12">
            <label htmlFor="username">Status</label>
            <Dropdown
              value={status}
              onChange={(e) => setStatus(e.value)}
              options={Status}
              optionLabel="name"
              placeholder="Select a Status"
              className="w-full "
            />
          </div>
          <div className="col-12">
            <label htmlFor="username">Description</label>
            <InputTextarea
              id="Description"
              name="Description"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-content-end mt-4">
          <Button
            label="Cancel"
            className="p-button-danger"
            onClick={() => {
              setEdit(false);
              handleCancel();
            }}
          />
          <Button label="Submit" className="mx-2" onClick={handleEdit} />
        </div>
      </Dialog>
      {/* delete model */}
      <Dialog
        visible={displayConfirmation}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={hideConfirmationModal}
        header="Confirmation"
        footer={
          <div>
            <Button
              style={{ color: "white", background: "red" }}
              label="No"
              icon="pi pi-times"
              className="p-button-text"
              onClick={hideConfirmationModal}
            />
            <Button
              style={{ color: "white", background: "#22c55e" }}
              label="Yes"
              icon="pi pi-check"
              className="p-button-text"
              onClick={confirmDelete}
            />
          </div>
        }
      >
        Are you sure you want to delete this project?
      </Dialog>
    </div>
  );
};

export default Project;
