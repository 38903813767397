import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { HOLIDAYS_API } from "../../constants/api";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { InputTextarea } from "primereact/inputtextarea";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";

function Holidays() {
  const [holidays, setHolidays] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [newHoliday, setNewHoliday] = useState({
    holiday_name: "",
    holiday_date: "",
    holiday_type: "",
    holiday_details: "",
  });
  const [errors, setErrors] = useState({
    holiday_name: "",
    holiday_date: "",
    holiday_type: "",
  });
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    getAllHolidays();
  }, []);

  const getAllHolidays = async () => {
    try {
      const response = await axios.get(HOLIDAYS_API.HOLIDAYS_GET);
      setHolidays(response.data);
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  const addHoliday = () => {
    setNewHoliday({
      holiday_name: "",
      holiday_date: "",
      holiday_type: "",
      holiday_details: "",
    });
    setErrors({
      holiday_name: "",
      holiday_date: "",
      holiday_type: "",
    });
    setEditMode(false);
    setModalTitle("Add Holiday");
    setDisplayDialog(true);
  };

  const editHoliday = (rowData) => {
    setSelectedHoliday(rowData);
    setNewHoliday({ ...rowData });
    setEditMode(true);
    setModalTitle("Edit Holiday");
    setDisplayDialog(true);
  };

  const saveHoliday = async () => {
    let formIsValid = true;
    const newErrors = { ...errors };

    if (!newHoliday.holiday_name) {
      newErrors.holiday_name = "Holiday Name is required.";
      formIsValid = false;
    } else {
      newErrors.holiday_name = "";
    }
    if (!newHoliday.holiday_date) {
      newErrors.holiday_date = "Holiday Date is required.";
      formIsValid = false;
    } else {
      newErrors.holiday_date = "";
    }
    if (!newHoliday.holiday_type) {
      newErrors.holiday_type = "Holiday Type is required.";
      formIsValid = false;
    } else {
      newErrors.holiday_type = "";
    }
    if (!newHoliday.holiday_details) {
      newErrors.holiday_details = "Holiday Details is required.";
      formIsValid = false;
    } else {
      newErrors.holiday_details = "";
    }

    if (!formIsValid) {
      setErrors(newErrors);
      return;
    }

    try {
      let response;
      if (editMode) {
        response = await axios.put(
          HOLIDAYS_API.HOLIDAYS_UPDATE(selectedHoliday.holiday_id),
          newHoliday
        );
        setHolidays(
          holidays.map((holiday) =>
            holiday.holiday_id === selectedHoliday.holiday_id
              ? response.data
              : holiday
          )
        );
      } else {
        response = await axios.post(HOLIDAYS_API.HOLIDAYS_ADD, newHoliday);
        setHolidays([...holidays, response.data]);
      }

      setDisplayDialog(false);
      getAllHolidays();
    } catch (error) {
      console.error("Error saving holiday:", error);
    }
  };

  const deleteHoliday = (rowData) => {
    setDisplayConfirmation(true);
    setSelectedHoliday(rowData.holiday_id);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(HOLIDAYS_API.HOLIDAYS_DELETE(selectedHoliday));
      setHolidays(
        holidays.filter((holiday) => holiday.holiday_id !== selectedHoliday)
      );
      setDisplayConfirmation(false);
    } catch (error) {
      console.error("Error deleting holiday:", error);
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex items-center">
        <Button
          tooltip="Edit"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-pencil"
          className="p-button-rounded p-mr-2"
          onClick={() => editHoliday(rowData)}
          style={{ marginRight: "10px", background: "#1677FF", border: "none" }}
        />
        <Button
          tooltip="Delete"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => deleteHoliday(rowData)}
        />
      </div>
    );
  };
  const capitalizeName = (key, value) => {
    if (key === "holiday_name") {
      const formattedValue = value.replace(/\b\w/g, (char) =>
        char.toUpperCase()
      );
      setNewHoliday({ ...newHoliday, [key]: formattedValue });
    } else {
      setNewHoliday({ ...newHoliday, [key]: value });
    }
  };

  return (
    <div>
      <div className="md:flex block justify-content-between mt-2">
        <div className="ml-2">
          <h2>Holidays</h2>
        </div>
        <div className="md:col-3 col-12">
          <div className="p-inputgroup">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="flex justify-content-end">
          <div className="text-right">
            <i
              className="fi fi-sr-square-plus"
              onClick={addHoliday}
              style={{
                color: "#6366F1",
                fontSize: "40px",
                marginRight: "9px",
              }}
            ></i>
          </div>
        </div>
        <DataTable
          value={holidays}
          globalFilter={globalFilter.trim() ? globalFilter : null}
          paginator
          rows={10}
        >
          <Column
            field="holiday_name"
            header="Name"
            bodyStyle={{ minWidth: "100px" }}
          />
          <Column
            field="holiday_date"
            header="Date"
            bodyStyle={{ minWidth: "110px" }}
            body={(rowData) =>
              moment(rowData.holiday_date).format("DD-MM-YYYY")
            }
          />
          <Column
            field="holiday_type"
            header="Type"
            bodyStyle={{ minWidth: "110px" }}
          />
          <Column
            field="holiday_details"
            header="Details"
            bodyStyle={{ minWidth: "140px" }}
            body={(rowData) => (
              <div
                id={`reason_${rowData.holiday_id}`}
                className="tooltip-container"
              >
                {rowData.holiday_details?.length > 20
                  ? `${rowData.holiday_details.slice(0, 25)}...`
                  : rowData.holiday_details}
                <Tooltip
                  target={`#reason_${rowData.holiday_id}`}
                  position="left"
                  style={{ width: "300px" }}
                >
                  {rowData.holiday_details}
                </Tooltip>
              </div>
            )}
          />
          <Column
            header="Actions"
            bodyStyle={{ minWidth: "90px" }}
            body={actionTemplate}
          />
        </DataTable>
      </div>

      <Dialog
        visible={displayDialog}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        header={modalTitle}
        modal
        onHide={() => setDisplayDialog(false)}
      >
        <div className="p-field">
          <label htmlFor="holiday_name">Name</label>
          <InputText
            id="holiday_name"
            value={newHoliday.holiday_name}
            onChange={(e) => capitalizeName("holiday_name", e.target.value)}
          />
          {errors.holiday_name && (
            <small className="p-error">{errors.holiday_name}</small>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="holiday_date">Date</label>
          <InputText
            type="date"
            id="holiday_date"
            value={newHoliday.holiday_date}
            onChange={(e) =>
              setNewHoliday({ ...newHoliday, holiday_date: e.target.value })
            }
          />
          {errors.holiday_date && (
            <small className="p-error">{errors.holiday_date}</small>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="holiday_type">Type</label>
          <InputText
            id="holiday_type"
            value={newHoliday.holiday_type}
            onChange={(e) =>
              setNewHoliday({ ...newHoliday, holiday_type: e.target.value })
            }
          />
          {errors.holiday_type && (
            <small className="p-error">{errors.holiday_type}</small>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="holiday_details">Details</label>
          <InputTextarea
            id="holiday_details"
            value={newHoliday.holiday_details}
            onChange={(e) =>
              setNewHoliday({ ...newHoliday, holiday_details: e.target.value })
            }
          />
          {errors.holiday_details && (
            <small className="p-error">{errors.holiday_details}</small>
          )}
        </div>
        <div className="p-dialog-footer mt-4 flex">
          <Button
            label="Cancel"
            className="p-button-danger mt-2"
            onClick={() => setDisplayDialog(false)}
          />
          <Button label="Submit" className="" onClick={saveHoliday} />
        </div>
      </Dialog>
      <Dialog
        visible={displayConfirmation}
        style={{ width: "50vw" }}
        breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
        header="Confirmation"
        modal
        onHide={() => setDisplayConfirmation(false)}
        footer={
          <div>
            <Button
              style={{ color: "white", background: "red" }}
              label="No"
              icon="pi pi-times"
              className="p-button-text"
              onClick={() => setDisplayConfirmation(false)}
            />
            <Button
              style={{ color: "white", background: "#22c55e" }}
              label="Yes"
              icon="pi pi-check"
              className="p-button-text"
              onClick={confirmDelete}
            />
          </div>
        }
      >
        Are you sure you want to delete this holiday?
      </Dialog>
    </div>
  );
}

export default Holidays;
