import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { EMPLOYEE_API } from '../../constants/api';
import Celebration from "../../assets/images/Celebration.png";
import { Toast } from 'primereact/toast';
import moment from 'moment';

const BirthdayWish = () => {
    const [employees, setEmployees] = useState([]);
    const [birthdaySeen, setBirthdaySeen] = useState(false);
    const toast = useRef(null);

    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail });
    };

    const fetchData = async () => {
        try {
            const { data } = await axios.get(EMPLOYEE_API.GET_ALL);
            setEmployees(data.usersData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchBirthdayData = async () => {
        try {
            const { data } = await axios.get(EMPLOYEE_API.GET_BIRTHDAY);
            const userData = JSON.parse(localStorage.getItem("user"));
            const empId = userData?.data?.id;
            const unseenBirthday = data.filter(item =>
                item.empid === empId &&
                moment(item.date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
            );
            setBirthdaySeen(unseenBirthday.length <= 0 ? false : true);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchBirthdayData();
    }, []);

    const getEmployeesWithBirthdayToday = () => {
        const today = new Date();
        return employees.filter(employee => {
            const employeeBirthday = new Date(employee.DOB);
            return (
                employeeBirthday.getDate() === today.getDate() &&
                employeeBirthday.getMonth() === today.getMonth()
            );
        });
    };

    const birthdayEmployees = getEmployeesWithBirthdayToday() || [];

    const markBirthdayWishAsSeen = async () => {
        const userData = JSON.parse(localStorage.getItem("user"));
        const empId = userData?.data?.id;
        try {
            const { data } = await axios.post(`${EMPLOYEE_API.SEEN_BIRTHDAY}`, { empId });
            fetchData();
            fetchBirthdayData();
            showToast('success', data.message);
        } catch (error) {
            console.error("Error marking birthday wish as seen:", error);
        }
    };

    return (
        <>
            <Toast ref={toast} />
            {birthdayEmployees.length > 0 && !birthdaySeen &&
                <div className="flex justify-content-center">
                    <div className="col-12 lg:col-8">
                        <div
                            className='p-4'
                            style={{ backgroundImage: 'linear-gradient(to right, #ff4d4d, #edbe68, #eaea4f, #57f557, #9090ef, #a35dd7, #e9a0e9)' }}
                        >
                            <div className="card border-round-3xl shadow-lg">
                                <h2>Hey, there's a birthday!</h2>
                                <div className="flex justify-content-center mt-3">
                                    <div className="" style={{ width: '130px', height: '130px' }}>
                                        <img src={Celebration} alt="Celebration" style={{ width: '100%' }} />
                                    </div>
                                </div>
                                <div className="text-center mt-3">
                                    {birthdayEmployees.length > 0 &&
                                        <p className='text-lg'>
                                            {birthdayEmployees.map((employee, index) => (
                                                <span className='cursor-pointer ' key={employee.id}>
                                                    <span className=''><span className='text-blue-500'>@</span>{`${employee.firstname} ${employee.lastname}`}</span>
                                                    {index < birthdayEmployees.length - 1 && <span>, </span>}
                                                </span>
                                            ))} <span>'s Birthday today!
                                            </span>
                                        </p>
                                    }
                                </div>
                                <div className="flex justify-content-center mt-4">
                                    <button className='border-indigo-500 p-2 outline-none bg-white cursor-pointer font-bold w-10rem' onClick={markBirthdayWishAsSeen}>Say Happy Birthday!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default BirthdayWish;
