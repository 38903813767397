import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { ASSETS_IMAGES_API, ATTENDANCE_API, HOLIDAYS_API } from "../../../../constants/api";
import { Badge } from "primereact/badge";
import moment from "moment/moment";
import { Image } from "primereact/image";

export default function Attendance({ userId }) {
  const [products, setProducts] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [attendanceData, setAttendanceData] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [daysCount, setDaysCount] = useState({
    absentCount: 0,
    presentCount: 0,
    sundayCount: 0,
    governmentHolidayCount: 0,
    workingCount: 0,
    optionalLeaveCount: 0,
  });
  const getCurrentDate = new Date();
  const currentMonthSelected = getCurrentDate.toISOString().slice(0, 7);
  const [searchDate, setSearchDate] = useState(currentMonthSelected);

  useEffect(() => {
    fetchAttendanceData();
  }, []);

  const fetchAttendanceData = () => {
    axios
      .get(ATTENDANCE_API.ATTENDANCE_GET(userId))
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });

  };

  const onRowEditComplete = (e) => {
    let _products = [...products];
    let { newData, index } = e;

    _products[index] = newData;

    setProducts(_products);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  useEffect(() => {
    getHolidaysData();
    getAttendanceData();
  }, []);

  const getAttendanceData = async () => {
    axios
      .get(ATTENDANCE_API.ATTENDANCE_GET(userId))
      .then(({ data }) => {
        setAttendanceData(data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  };

  const getHolidaysData = async () => {
    axios
      .get(HOLIDAYS_API.HOLIDAYS_ALL)
      .then(({ data }) => {
        setHolidays(data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  };

  useEffect(() => {
    if (searchDate === "") {
      setDaysCount({
        presentCount: 0,
        sundayCount: 0,
        governmentHolidayCount: 0,
        workingCount: 0,
        absentCount: 0,
        optionalLeaveCount: 0,
      });
      return;
    }

    const selectedDate = new Date(searchDate);
    const selectedMonth = selectedDate.getMonth() + 1;
    const selectedYear = selectedDate.getFullYear();

    const filteredAttendance = attendanceData.filter((data) => {
      const date = new Date(data.date);
      return (
        date.getMonth() + 1 === selectedMonth &&
        date.getFullYear() === selectedYear
      );
    });

    const filteredHoliday = holidays.filter((holiday) => {
      const holidayDate = new Date(holiday.holiday_date);
      return (
        holidayDate.getMonth() + 1 === selectedMonth &&
        holidayDate.getFullYear() === selectedYear
      );
    });

    const firstDayOfMonth = new Date(selectedYear, selectedMonth - 1, 1);
    const lastDayOfMonth = new Date(selectedYear, selectedMonth, 0);
    let sundayCount = 0;
    let governmentHolidayCount = filteredHoliday.filter(
      (holiday) => holiday.holiday_type === "Public Holiday"
    ).length;

    for (
      let d = new Date(firstDayOfMonth);
      d <= lastDayOfMonth;
      d.setDate(d.getDate() + 1)
    ) {
      if (d.getDay() === 0) {
        // Check if it's Sunday
        sundayCount++;
        // Check if it's also a holiday
        const isHoliday = filteredHoliday.some((holiday) => {
          const holidayDate = new Date(holiday.holiday_date);
          return holidayDate.toDateString() === d.toDateString();
        });
        if (isHoliday) {
          governmentHolidayCount--; // Adjust holiday count
        }
      }
    }

    const totalDaysInMonth = lastDayOfMonth.getDate();
    const workingDays = totalDaysInMonth - sundayCount - governmentHolidayCount;

    const presentCount = filteredAttendance.filter((data) => {
      const date = new Date(data.date);
      return date.getDay() !== 0 && data.status === "Present";
    }).length;

    const optionalLeaveCount = filteredAttendance.filter((data) => {
      const date = new Date(data.date);
      return date.getDay() !== 0 && data.status === "Optional Leave";
    }).length;

    setDaysCount({
      presentCount,
      sundayCount,
      governmentHolidayCount,
      workingCount: workingDays,
      absentCount: 0,
      optionalLeaveCount: optionalLeaveCount,
    });

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const isCurrentOrPreviousMonth =
      selectedYear < currentYear ||
      (selectedYear === currentYear && selectedMonth <= currentMonth);
    if (isCurrentOrPreviousMonth) {
      if (selectedMonth === currentMonth && selectedYear === currentYear) {
        const startOfMonth = new Date(selectedYear, selectedMonth - 1, 1);
        const today = new Date();
        let absentCount = 0;

        for (
          let calDate = new Date(startOfMonth);
          calDate <= today;
          calDate.setDate(calDate.getDate() + 1)
        ) {
          if (calDate.getDay() !== 0) {
            const isPresent = filteredAttendance.some((data) => {
              const attendanceDate = new Date(data.date);
              return (
                attendanceDate.toDateString() === calDate.toDateString() &&
                (data.status === "Present" || data.status === "Optional Leave")
              );
            });
            if (!isPresent) {
              absentCount++;
            }
          }
        }

        const currentMonthGovernment = today.getMonth() + 1;

        const holidaysThisMonth = holidays.filter((holiday) => {
          const holidayDate = new Date(holiday.holiday_date);
          const holidayMonth = holidayDate.getMonth() + 1;

          return (
            holidayMonth === currentMonthGovernment && holidayDate <= today
          );
        });

        // Only subtract holidays that do not have a corresponding present attendance
        holidaysThisMonth.forEach((holiday) => {
          const holidayDate = new Date(holiday.holiday_date);
          const isPresentOnHoliday = filteredAttendance.some((data) => {
            const attendanceDate = new Date(data.date);
            return (
              attendanceDate.toDateString() === holidayDate.toDateString() &&
              data.status === "Present"
            );
          });
          if (!isPresentOnHoliday) {
            absentCount--;
          }
        });

        setDaysCount((prevDaysCount) => ({
          ...prevDaysCount,
          absentCount,
        }));
      } else {
        const absentCountFromWorkingDays =
          workingDays - presentCount - optionalLeaveCount;
        setDaysCount((prevDaysCount) => ({
          ...prevDaysCount,
          absentCount: absentCountFromWorkingDays,
        }));
      }
    }
  }, [searchDate, attendanceData, holidays]);

  const filteredProducts = products.filter((product) => {
    const formattedDate = moment(product.date).format("YYYY-MM");
    return formattedDate === searchDate;
  });

  useEffect(() => {
    const filterData = products.filter((item) => {
      const filterDate = moment(item.date).format("DD-MM-YYYY");
      const currentDate = moment().format("DD-MM-YYYY");
      return currentDate === filterDate;
    });

    if (filterData.length == 1) {
    }
  }, [products]);

  return (
    <div>
      <div className="">
        <div className="flex justify-content-end mb-2">
          <div className="w-10rem">
            <div>
              <label htmlFor="monthPicker">
                <input
                  id="monthPicker"
                  type="month"
                  value={searchDate}
                  onChange={(e) => setSearchDate(e.target.value)}
                  style={{
                    boxShadow: "1px 1px 4px gray",
                    outline: "none",
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                    appearance: "none",
                  }}
                  showButtonBar={false}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="my-3">
          <div
            className="permision-card md:flex  block"
            style={{ flexWrap: "wrap", gap: "10px" }}
          >
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={daysCount.presentCount}
                severity="success"
                size="large"
              />
              <div className="permi-text">
                <h3>Present</h3>
              </div>
            </div>
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={daysCount.absentCount}
                severity="danger"
                size="large"
              />
              <div className="permi-text">
                <h3>Absent</h3>
              </div>
            </div>

            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={daysCount.sundayCount}
                severity="warning"
                size="large"
              />
              <div className="permi-text">
                <h3>Sundays</h3>
              </div>
            </div>
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={daysCount.optionalLeaveCount}
                severity="secondary"
                size="large"
              />
              <div className="permi-text">
                <h3>Optional Leave</h3>
              </div>
            </div>
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={daysCount.governmentHolidayCount}
                severity="info"
                size="large"
              />
              <div className="permi-text">
                <h3>Holidays</h3>
              </div>
            </div>
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge value={daysCount.workingCount} size="large" />
              <div className="permi-text">
                <h3>Working days</h3>
              </div>
            </div>
          </div>
        </div>

        <DataTable
          stripedRows
          value={filteredProducts}
          editMode="row"
          dataKey="id"
          selectionMode="single"
          selection={selectedRow}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          onRowEditComplete={onRowEditComplete}
          scrollable
          scrollHeight="600px"
          className=""
          paginator
          rows={9}
          globalFilter={searchDate}
          emptyMessage="No records found"
        >
          <Column
            field="date"
            header="Date"
            style={{ minWidth: "105px" }}
            className="font-bold"
            headerStyle={{
              borderTopLeftRadius: "14px",
              borderBottomLeftRadius: "14px",
            }}
            body={(rowData) => moment(rowData.date).format("DD-MM-yyyy")}
          ></Column>
          <Column
            field="check_in"
            header="Check-In"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "120px" }}
          ></Column>
          <Column
            field="check_out"
            header="Check-Out"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "120px" }}
            body={(rowData) => (rowData.check_out ? rowData.check_out : "-")}
          ></Column>
          <Column
            field="workinghours"
            header="Working Hours"
            style={{ maxWidth: "200px" }}
            body={(rowData) =>
              rowData.workinghours ? rowData.workinghours : "-"
            }
          ></Column>
          <Column
            field="status"
            header="Status"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "100px" }}
          ></Column>
          <Column
            header="CII"
            body={(rowData) => {
              return rowData?.check_in_img !== null ?
                <>
                  <Image src={`${ASSETS_IMAGES_API.ASSETS_IMAGES}${rowData.check_in_img}`} alt="EmployeeImage" width="50" preview />
                </>
                : "-"
            }}
            style={{ width: "70px", textAlign: "center" }}
          />
          <Column
            header="COI"
            body={(rowData) => {
              return rowData.check_out_img !== null ?
                <Image src={`${ASSETS_IMAGES_API.ASSETS_IMAGES}${rowData.check_out_img}`} alt="EmployeeImage" width="50" preview />
                : "-"
            }}
            style={{ width: "50px", textAlign: "center" }}
          />

        </DataTable>
      </div>
    </div>
  );
}
