import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Image } from 'primereact/image';
import { DownloadOutlined, FilePdfOutlined, FileWordOutlined } from '@ant-design/icons';
import moment from 'moment';
import { PERSONAL_DOCUMENT_API, ASSETS_IMAGES_API, ASSETS_DOC_API } from '../../../constants/api';
import { Country } from './data/CountryList';
import { DocumentTitle } from './data/DocumentTitle';

const EmpPersonalDocument = () => {
    const [visible, setVisible] = useState(false);
    const [mode, setMode] = useState('add');
    const [file, setFile] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [showFileInput, setShowFileInput] = useState(false);
    const [documentData, setDocumentData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [formState, setFormState] = useState({
        title: '',
        issueDate: null,
        validityDate: null,
        country: null,
        issuingAuthority: '',
        idNumber: '',
    });

    const [errors, setErrors] = useState({
        title: '',
        file: '',
    });

    const toast = useRef(null);
    const menu = useRef(null);
    const fileInputRef = useRef(null);

    const documentOptions = DocumentTitle;
    const countryOptions = Country;

    const fetchDocumentData = useCallback(async () => {
        try {
            const userData = JSON.parse(localStorage.getItem("user"))?.data;
            const id = userData.id;
            const { data } = await axios.get(PERSONAL_DOCUMENT_API.DOCUMENT_FETCH(id));
            setDocumentData(data || []);
        } catch (error) {
            console.error('Error fetching document data:', error);
        }
    }, []);

    useEffect(() => {
        fetchDocumentData();
    }, [fetchDocumentData]);

    const openAddDialog = () => {
        setMode('add');
        resetForm();
        setVisible(true);
    };

    const openEditDialog = (document) => {
        setMode('edit');
        setFormState({
            title: document.title,
            issueDate: document?.issueDate,
            validityDate: document?.validityDate,
            country: document.country,
            issuingAuthority: document.issuingAuthority,
            idNumber: document.idNumber,
        });
        setFile(document.document_file);
        setSelectedDocument(document);
        setShowFileInput(false);
        setVisible(true);
    };

    const handleDelete = async (documentId) => {
        try {
            await axios.delete(PERSONAL_DOCUMENT_API.DOCUMENT_DELETE(documentId));
            await fetchDocumentData();
            showToast('success', 'Document deleted successfully!');
        } catch (error) {
            console.error('Error:', error);
            const errorMessage = error.response?.data?.message || 'An error occurred while deleting the document.';
            showToast('error', errorMessage);
        }
    };

    const onFileChange = (e) => {
        setFile(e.target.files[0]);
        setErrors(prevErrors => ({ ...prevErrors, file: '' }));
    };

    const showToast = (severity, detail) => {
        if (toast.current) {
            toast.current.show({ severity, detail, life: 3000 });
        }
    };

    console.log('Form Data:', {
        title: formState.title,
        issueDate: formState.issueDate,
        validityDate: formState.validityDate,
        country: formState.country,
        issuingAuthority: formState.issuingAuthority,
        idNumber: formState.idNumber,
        file: file
    });


    const handleSave = async () => {
        let hasError = false;
        const newErrors = {
            title: '',
            file: '',
        };

        if (!formState.title) {
            newErrors.title = 'Title is required';
            hasError = true;
        }
        if (mode === 'add' && !file) {
            newErrors.file = 'File is required';
            hasError = true;
        }

        setErrors(newErrors);

        if (hasError) return;

        const userData = JSON.parse(localStorage.getItem("user"))?.data;
        const id = userData.id;

        const formDataToSend = new FormData();
        formDataToSend.append('title', formState.title);
        formDataToSend.append('issueDate', formState?.issueDate || "");
        formDataToSend.append('validityDate', formState?.validityDate || "");
        formDataToSend.append('country', formState.country === null ? "" : formState.country)
        formDataToSend.append('issuingAuthority', formState.issuingAuthority);
        formDataToSend.append('idNumber', formState.idNumber);
        formDataToSend.append('empid', id);
        if (file) {
            formDataToSend.append('file', file);
        }

        try {
            if (mode === 'add') {
                await axios.post(PERSONAL_DOCUMENT_API.DOCUMENT_ADD, formDataToSend, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            } else {
                await axios.put(PERSONAL_DOCUMENT_API.DOCUMENT_UPDATE(selectedDocument.id), formDataToSend, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            }
            await fetchDocumentData();
            showToast('success', 'Document saved successfully!');
            handleCancel();
        } catch (error) {
            console.error('Error:', error);
            const errorMessage = error.response?.data?.message || 'An error occurred while saving the document.';
            showToast('error', errorMessage);
        }
    };

    const handleCancel = () => {
        resetForm();
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        setVisible(false);
    };

    const resetForm = () => {
        setFormState({
            title: '',
            issueDate: "",
            validityDate: "",
            country: "",
            issuingAuthority: '',
            idNumber: '',
        });
        setSelectedDocument(null);
        setFile(null);
        setShowFileInput(false);
        setErrors({
            title: '',
            file: '',
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    };

    const handleDateChange = (name, value) => {
        setFormState(prevState => ({
            ...prevState,
            [name]: value || ""
        }));
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    };

    const getFilePreview = (fileUrl) => {
        if (!fileUrl) return <span>No file available</span>;

        const extension = fileUrl.split('.').pop().toLowerCase();
        const fileUrlWithBase = `${ASSETS_DOC_API.ASSETS_DOC}${fileUrl}`;

        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
                return (
                    <div>
                        <Image src={`${ASSETS_IMAGES_API.ASSETS_IMAGES}${fileUrl}`} alt="File preview" preview width='45px' height='45px' />
                    </div>
                );
            case 'pdf':
                return (
                    <Button
                        icon={<FilePdfOutlined />}
                        className="p-button-text text-red-500 border-red-500 hover:bg-red-500 hover:text-white"
                        onClick={() => window.open(fileUrlWithBase, '_blank')}
                    />
                );
            case 'doc':
            case 'docx':
                return (
                    <Button
                        icon={<FileWordOutlined />}
                        className="p-button-text text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white"
                        onClick={() => window.open(fileUrlWithBase, '_blank')}
                    />
                );
            default:
                return <span>Unsupported file type</span>;
        }
    };

    const handleDownload = async (documentId) => {
        try {
            const url = PERSONAL_DOCUMENT_API.DOCUMENT_DOWNLOAD(documentId);

            const response = await axios.get(url, {
                responseType: 'blob',
            });

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const urlObject = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = urlObject;
            a.download = `file_${documentId}`;
            a.click();

            URL.revokeObjectURL(urlObject);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    const handleContextMenu = (event, rowData) => {
        event.preventDefault();
        setSelectedDocument(rowData);
        menu.current.show(event);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <Button
                    icon="pi pi-ellipsis-v"
                    className="p-button-rounded p-button-text"
                    aria-label="More"
                    onClick={(e) => handleContextMenu(e, rowData)}
                />
                <Menu
                    popup
                    model={[
                        { label: 'Download', icon: <DownloadOutlined />, command: () => handleDownload(selectedDocument.id) }
                    ]}
                    ref={menu}
                    className="w-8rem"
                />
            </div>
        );
    };

    return (
        <div>
            <div className="md:flex justify-content-between">
                <div className="ml-2">
                    <h2>My Documents</h2>
                </div>
                <div className="md:col-4 lg:col-3 col-12">
                    <div className="p-inputgroup">
                        <InputText
                            type="text"
                            className="search-input p-inputtext p-component"
                            placeholder="Search"
                            value={globalFilter}
                            onChange={(e) => setGlobalFilter(e.target.value)}
                        />
                        <Button icon="pi pi-search" className="search-icon" />
                    </div>
                </div>
            </div>
            <Toast ref={toast} />
            <div className="card">
                <div className="flex my-2 justify-content-end">
                    <Button label="Add" icon="pi pi-plus" onClick={openAddDialog} />
                </div>
                <DataTable value={documentData} paginator rows={10} rowsPerPageOptions={[10, 25, 50]}>
                    <Column
                        header="S.No"
                        body={(rowData) => <span>{documentData.indexOf(rowData) + 1}</span>}
                    />
                    <Column
                        header="Date"
                        body={(rowData) => rowData?.created_at ? moment(rowData.created_at).format("DD-MM-YYYY") : "-"}
                        style={{ minWidth: '120px' }}
                        field='created_at'
                    />
                    <Column
                        header="Document Id"
                        body={(rowData) => rowData?.idNumber || "-"}
                        field="idNumber"
                    />
                    <Column
                        header="Document"
                        body={(rowData) => rowData?.title || "-"}
                        field="title"
                    />
                    <Column
                        header="Issue Date"
                        body={(rowData) => rowData?.issueDate ? moment(rowData?.issueDate).format("DD-MM-YYYY") : "-"}
                        style={{ minWidth: '120px' }}
                    />
                    <Column
                        header="Validity Date"
                        body={(rowData) => rowData?.issueDate ? moment(rowData?.validityDate)?.format("DD-MM-YYYY") : "-"}
                        style={{ minWidth: '120px' }}
                    />
                    <Column
                        header="Country"
                        body={(rowData) => rowData?.country != "null" ? rowData?.country : "-"}
                        field="country"
                    />
                    <Column
                        header="Issuing Authority"
                        body={(rowData) => rowData?.issuingAuthority || "-"}
                        field="issuingAuthority"
                    />
                    <Column
                        header="File"
                        body={(rowData) => rowData?.document_file ? getFilePreview(rowData.document_file) : "-"}
                    />
                    <Column
                        header="Actions"
                        body={actionBodyTemplate}
                    />
                </DataTable>

                <Dialog
                    header={mode === 'add' ? 'Add Document' : 'Edit Document'}
                    visible={visible}
                    onHide={handleCancel}
                    style={{ width: "90vw", maxWidth: "600px" }}
                    draggable={false}
                    position="center"
                    footer={
                        <div>
                            <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={handleCancel} />
                            <Button label="Save" icon="pi pi-check" onClick={handleSave} />
                        </div>
                    }
                >
                    <div className="p-fluid">
                        <div className="p-field">
                            <label htmlFor="title">Document</label>
                            <Dropdown
                                id="title"
                                name="title"
                                value={formState.title}
                                options={documentOptions}
                                onChange={(e) => setFormState(prevState => ({
                                    ...prevState,
                                    title: e.value
                                }))}
                                placeholder="Select a title"
                                filter
                                showClear
                            />
                            {errors.title && <div className="p-error">{errors.title}</div>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="idNumber">ID Number</label>
                            <InputText
                                id="idNumber"
                                name="idNumber"
                                value={formState.idNumber}
                                onChange={handleChange}
                                placeholder="Enter ID number"
                            />
                        </div>
                        <div className="p-field">
                            <label htmlFor="issueDate">Issue Date</label>
                            <InputText
                                type='date'
                                id="issueDate"
                                name="issueDate"
                                value={formState?.issueDate || null}
                                onChange={(e) => handleDateChange('issueDate', e.target.value)}
                                showIcon
                            />
                        </div>
                        <div className="p-field">
                            <label htmlFor="validityDate">Validity Date</label>
                            <InputText
                                type='date'
                                id="validityDate"
                                name="validityDate"
                                value={formState?.validityDate || null}
                                onChange={(e) => handleDateChange('validityDate', e.target.value)}
                                showIcon
                            />
                        </div>
                        <div className="p-field">
                            <label htmlFor="country">Country</label>
                            <Dropdown
                                id="country"
                                name="country"
                                value={formState.country || ""}
                                options={countryOptions}
                                onChange={(e) => setFormState(prevState => ({
                                    ...prevState,
                                    country: e.value
                                }))}
                                placeholder="Select a country"
                                filter
                                showClear
                            />
                        </div>

                        <div className="p-field">
                            <label htmlFor="issuingAuthority">Issuing Authority</label>
                            <InputText
                                id="issuingAuthority"
                                name="issuingAuthority"
                                value={formState.issuingAuthority}
                                onChange={handleChange}
                                placeholder="Enter issuing authority"
                            />
                        </div>
                        <div className="p-field">
                            <label htmlFor="file">File</label>
                            {selectedDocument?.document_file && !showFileInput && (
                                <div className='flex align-items-center'>
                                    {getFilePreview(selectedDocument.document_file)}
                                    <Button
                                        icon="pi pi-pencil"
                                        className="p-button-text"
                                        onClick={() => setShowFileInput(true)}
                                    />
                                </div>
                            )}
                            {mode === 'add' && (
                                <input
                                    type="file"
                                    name="file"
                                    onChange={onFileChange}
                                />
                            )}
                            {showFileInput && (
                                <div className=''>
                                    <input
                                        type="file"
                                        name="file"
                                        className=''
                                        ref={fileInputRef}
                                        onChange={onFileChange}
                                    />
                                    <Button
                                        label="Cancel"
                                        icon="pi pi-times"
                                        className="p-button-text mt-2 w-10rem"
                                        onClick={() => setShowFileInput(false)}
                                    />
                                </div>
                            )}
                            {errors.file && <div className="p-error">{errors.file}</div>}
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default EmpPersonalDocument;
