// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
:where(.css-dev-only-do-not-override-1rqnfsa).ant-layout .ant-layout-sider-trigger{
    background: rgb(81, 75, 167);

}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-menu-light .ant-menu-item-selected {
    background-color: #514ba7;
}
:where(.css-dev-only-do-not-override-1rqnfsa).ant-menu-light .ant-menu-item-selected{
    color:white;
}
::where(.css-dev-only-do-not-override-1rqnfsa).ant-btn-primary {
    background: #514ba7;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/main.css"],"names":[],"mappings":";AACA;IACI,4BAA4B;;AAEhC;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,WAAW;AACf;AACA;IACI,mBAAmB;AACvB","sourcesContent":["\n:where(.css-dev-only-do-not-override-1rqnfsa).ant-layout .ant-layout-sider-trigger{\n    background: rgb(81, 75, 167);\n\n}\n\n:where(.css-dev-only-do-not-override-1rqnfsa).ant-menu-light .ant-menu-item-selected {\n    background-color: #514ba7;\n}\n:where(.css-dev-only-do-not-override-1rqnfsa).ant-menu-light .ant-menu-item-selected{\n    color:white;\n}\n::where(.css-dev-only-do-not-override-1rqnfsa).ant-btn-primary {\n    background: #514ba7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
