import React, { useState, useEffect } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Login from "../pages/login/index";
import Register from "../pages/register/index";
import Dashboard from "../pages/Dashboard/Dashboard";
import AdminDashboard from "../pages/Dashboard/AdminDashboard";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";
import PrivateRoute from "../PrivateRoute";
import AttendanceSheet from "../pages/Attendence/AttendanceSheet";
import EmpAttendance from "../pages/Attendence/EmpAttendance";
import TodayAttendance from "../pages/Attendence/TodayAttendance";
import AllLeaveRequest from "../pages/LeaveManagement/index.jsx";
import ApplyLeave from "../pages/LeaveManagement/ApplyLeave";
import ApplyHistory from "../pages/LeaveManagement/ApplyHistory";
import ApplyPending from "../pages/LeaveManagement/ApplyPending";
import LeaveBalance from "../pages/LeaveManagement/LeaveBalance";
import LeaveApply from "../pages/LeaveManagement/LeaveApply";
import AddEmployee from "../pages/Employee/AddEmployee";
import PaySlip from "../pages/Payroll/PaySlip";
import AllEmployee from "../pages/Employee/AllEmployee";
import SalaryCertificate from "../pages/Payroll/SalaryCertificate";
import Onboarding from "../pages/OnBoarding/Onboarding";
import Leave from "../pages/MyLeave/Leave";
import EmployeePayroll from "../pages/Payroll/EmployeePayroll";
import PayHeads from "../pages/Payroll/PayHead";
import UserPaySlip from "../pages/Payroll/UserPaySlip";
import TaskAdmin from "../pages/Tasks/UserTask/TaskAdmin.jsx";
import TaskUser from "../pages/Tasks/UserTask/TaskEmp.jsx";
import TaskAdminHistory from "../pages/Tasks/UserTask/TaskUserDetailsView.jsx";
import TaskDetailsView from "../pages/Tasks/AdminTask/TaskAdminDetailsView.jsx";
import TaskDetails from "../pages/Tasks/UserTask/TaskDetails.jsx";
import Project from "../pages/Projects/index.js";
import Department from "../pages/Department/Department";
import DepartHistory from "../pages/Department/DepartHistory";
import MyProjects from "../pages/Projects/myproject";
import AllTasks from "../pages/Tasks/AdminTask/AllTaskAdmin.jsx";
import EmployeeAdd from "../pages/Employee/EmployeeAddForm";
import Holidays from "../pages/Holidays/Holidays";
import UserHolidays from "../pages/Holidays/userHolidays";
import InactiveEmployees from "../pages/Employee/InactiveEmployees";
import MyPermissions from "../pages/Permissions/myPermissions.jsx";
import PermissionApprovals from "../pages/Permissions/permissionsApproval.jsx";
import AllLeaves from "../pages/MyLeave/AdminLeave";
import AllAttendance from "../pages/Attendence/AllAttendance.jsx";
import CalendarView from "../pages/Holidays/CalendarView.jsx";
import { LOCAL_CONSTANTS } from "../constants/localConstants.js";
import axios from "axios";
import Hierarchy from "../pages/Hierarchy/Hierarchy";
import LeaveViewPage from "../pages/LeaveManagement/LeaveViewPage.jsx";
import TimeSheet from "../pages/TimeSheet/index.jsx";
import TimeSheetApprovals from "../pages/TimeSheet/timeSheetApprovals.jsx";
import MyAccount from "../pages/Profile/MyAccount.jsx";
import ResetPassword from "../pages/login/ResetPassword.jsx";
import AdminTaskApprovals from "../pages/Tasks/AdminTask/AdminTaskApprovals.jsx";
import TaskApprovals from "../pages/Tasks/UserTask/TaskApprovals.jsx";
import Role from "../pages/Employee/Role.jsx";
import AllAssetsRequest from "../pages/Assets/AllAssetsRequest.jsx";
import EmpAssetsRequest from "../pages/Assets/EmpAssetsRequest.jsx";
import EmpAssetsDetail from "../pages/Assets/EmpAssetsDetail.jsx";
import AllAssetsDetail from "../pages/Assets/AllAssetsDetail.jsx";
import EmployeeDetails from "../pages/Employee/EmployeeDetail/EmployeeDetails.jsx";
import EmpLetter from "../pages/report/letter/EmpLetter.jsx";
import AdminLetter from "../pages/report/letter/AdminLetter.jsx";
import AllPersonalDocument from "../pages/report/personalDocument/AllPersonalDocument.jsx";
import EmpPersonalDocument from "../pages/report/personalDocument/EmpPersonalDocument.jsx";
import AllMemo from "../pages/Memo/AllMemo.jsx";
import EmpMemo from "../pages/Memo/EmpMemo.jsx";
import StaffCategories from "../pages/Category/StaffCategories.jsx";

const DefaultRoute = () => <Navigate to={ROUTE_CONSTANTS.LOGIN} />;

function Routes() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isadmin, setIsadmin] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [roleChange, setRoleChange] = useState(false);

  const getLastSegment = () => {
    const url = window.location.href;
    const segments = url.split("/");
    return segments[segments.length - 1];
  };

  const lastSegment = getLastSegment();

  const url = window.location.href;
  const segments = url.split("/");

  const getuser = async () => {
    if (segments[5]?.length !== undefined) {
      const response = await axios.get(
        `https://khrms.kodukku.com/api/auth/getuser?kcplid=${lastSegment}`
      );
      const data = response.data.data;
      localStorage.setItem(LOCAL_CONSTANTS.USER, JSON.stringify(data));
      localStorage.setItem(
        LOCAL_CONSTANTS.ROLE_CHANGE,
        JSON.stringify(data.role)
      );
    } else {
      console.log("Error fetching user data");
    }
  };

  const getUserDetails = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem("user"));
      const roleChange = localStorage.getItem(LOCAL_CONSTANTS.ROLE_CHANGE);
      setRoleChange(roleChange);
      if (userData && userData.success) {
        return {
          success: true,
          data: userData.data,
          role: userData.data.role,
        };
      } else {
        return { success: false };
      }
    } catch (error) {
      console.log("Error retrieving user details from local storage:", error);
      return { success: false };
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      await getuser();
      const result = await getUserDetails();
      if (result.success) {
        setIsAdmin(result?.role === "admin" || result?.role === "superAdmin");
        setIsadmin(result?.role === "admin" || result?.role === "superAdmin");
        setSuperAdmin(result?.role === "superAdmin");
      }
    };

    fetchUserDetails();
  }, []);

  const adminRoutes = [
    {
      path:
        segments[5]?.length !== undefined
          ? ROUTE_CONSTANTS.ADMIN_DASHBOARD1
          : ROUTE_CONSTANTS.ADMIN_DASHBOARD,
      element: <AdminDashboard />,
    },
    {
      path: ROUTE_CONSTANTS.PROJECT,
      element: <Project />,
    },
    {
      path: ROUTE_CONSTANTS.ATTENDANCESHEET,
      element: <AttendanceSheet />,
    },
    {
      path: ROUTE_CONSTANTS.TODAYATTENDANCE,
      element: <TodayAttendance />,
    },
    {
      path: ROUTE_CONSTANTS.ALLLEAVEREQUEST,
      element: <AllLeaveRequest />,
    },
    {
      path: ROUTE_CONSTANTS.APPLYPENDING,
      element: <ApplyPending />,
    },
    {
      path: ROUTE_CONSTANTS.TIME_SHEET_APPROVAL,
      element: <TimeSheetApprovals />,
    },
    {
      path: ROUTE_CONSTANTS.LEAVEBALANCE,
      element: <LeaveBalance />,
    },
    {
      path: ROUTE_CONSTANTS.ADDEMPLOYEE,
      element: <AddEmployee />,
    },
    {
      path: ROUTE_CONSTANTS.PAYSLIP,
      element: <PaySlip />,
    },
    {
      path: ROUTE_CONSTANTS.EMPLOYEEPAYROLL,
      element: <EmployeePayroll />,
    },
    {
      path: ROUTE_CONSTANTS.PAYHEADS,
      element: <PayHeads />,
    },
    {
      path: ROUTE_CONSTANTS.ALLEMP,
      element: <AllEmployee />,
    },
    {
      path: ROUTE_CONSTANTS.SALARYCERTI,
      element: <SalaryCertificate />,
    },
    {
      path: ROUTE_CONSTANTS.ONBOARD,
      element: <Onboarding />,
    },
    {
      path: ROUTE_CONSTANTS.PROFILE,
      element: <EmployeeDetails />,
    },
    {
      path: ROUTE_CONSTANTS.DEPARTMENT,
      element: <Department />,
    },
    {
      path: ROUTE_CONSTANTS.DEPARTMENT_HISTORY,
      element: <DepartHistory />,
    },
    {
      path: ROUTE_CONSTANTS.ALLTASKS,
      element: <AllTasks />,
    },
    {
      path: ROUTE_CONSTANTS.ADMIN_TASKHISTORY,
      element: <TaskDetailsView />,
    },
    {
      path: ROUTE_CONSTANTS.ADDEMPLOYEES,
      element: <EmployeeAdd />,
    },
    {
      path: ROUTE_CONSTANTS.HOLIDAYS,
      element: <Holidays />,
    },
    {
      path: ROUTE_CONSTANTS.HOLIDAYS_CALENDAR,
      element: <CalendarView />,
    },
    {
      path: ROUTE_CONSTANTS.INACTIVEEMPLOYEES,
      element: <InactiveEmployees />,
    },
    {
      path: ROUTE_CONSTANTS.All_PERMISSIONS,
      element: <PermissionApprovals />,
    },
    {
      path: ROUTE_CONSTANTS.ADMIN_LEAVES,
      element: <AllLeaves />,
    },
    {
      path: ROUTE_CONSTANTS.ALL_ATTENDANCE,
      element: <AllAttendance />,
    },
    {
      path: ROUTE_CONSTANTS.MYPROJECT,
      element: <MyProjects />,
    },
    {
      path: ROUTE_CONSTANTS.HIERARCHY,
      element: <Hierarchy />,
    },
    {
      path: ROUTE_CONSTANTS.LEAVE_VIEW,
      element: <LeaveViewPage />,
    },
    {
      path: ROUTE_CONSTANTS.MY_ACCOUNT,
      element: <MyAccount />,
    },
    {
      path: ROUTE_CONSTANTS.TASK_APPROVAL,
      element: <AdminTaskApprovals />,
    },
    {
      path: ROUTE_CONSTANTS.ALL_ASSETS_REQUEST,
      element: <AllAssetsRequest />,
    },
    {
      path: ROUTE_CONSTANTS.ALL_ASSETS_DETAIL,
      element: <AllAssetsDetail />,
    },
    {
      path: ROUTE_CONSTANTS.ALL_LETTER,
      element: <AdminLetter />,
    },
    {
      path: ROUTE_CONSTANTS.ALL_PERSONAL_DOCUMENT,
      element: <AllPersonalDocument />,
    },
    {
      path: ROUTE_CONSTANTS.ALL_MEMO,
      element: <AllMemo />,
    },
    {
      path: ROUTE_CONSTANTS.STAFF_CATEGORIES,
      element: <StaffCategories />,
    },
  ];

  const userRoutes = [
    {
      path:
        segments[5]?.length !== undefined
          ? ROUTE_CONSTANTS.USER_DASHBOARD1
          : ROUTE_CONSTANTS.USER_DASHBOARD,
      element: <Dashboard />,
    },
    {
      path: ROUTE_CONSTANTS.EMPATTENDANCE,
      element: <EmpAttendance />,
    },
    {
      path: ROUTE_CONSTANTS.LEAVE,
      element: <Leave />,
    },
    {
      path: ROUTE_CONSTANTS.MYPROJECT,
      element: <MyProjects />,
    },
    {
      path: ROUTE_CONSTANTS.ADMINTASK,
      element: <TaskAdmin />,
    },
    {
      path: ROUTE_CONSTANTS.TASKHISTORY,
      element: <TaskAdminHistory />,
    },
    {
      path: ROUTE_CONSTANTS.USER_PAYSLIP,
      element: <UserPaySlip />,
    },
    {
      path: ROUTE_CONSTANTS.EMPLOYEETASK,
      element: <TaskUser />,
    },
    {
      path: ROUTE_CONSTANTS.TASKDETAIL,
      element: <TaskDetails />,
    },
    {
      path: ROUTE_CONSTANTS.USERHOLIDAYS,
      element: <UserHolidays />,
    },
    {
      path: ROUTE_CONSTANTS.HOLIDAYS_CALENDAR,
      element: <CalendarView />,
    },
    {
      path: ROUTE_CONSTANTS.MY_PERMISSIONS,
      element: <MyPermissions />,
    },
    {
      path: ROUTE_CONSTANTS.TIME_SHEET,
      element: <TimeSheet />,
    },
    {
      path: ROUTE_CONSTANTS.MY_ACCOUNT,
      element: <MyAccount />,
    },
    {
      path: ROUTE_CONSTANTS.TASK_APPROVER,
      element: <TaskApprovals />,
    },
    {
      path: ROUTE_CONSTANTS.EMPLOYEE_ASSETS_REQUEST,
      element: <EmpAssetsRequest />,
    },
    {
      path: ROUTE_CONSTANTS.MY_ASSETS_DETAIL,
      element: <EmpAssetsDetail />,
    },
    {
      path: ROUTE_CONSTANTS.EMPLOYEE_LETTER,
      element: <EmpLetter />,
    },
    {
      path: ROUTE_CONSTANTS.EMPLOYEE_PERSONAL_DOCUMENT,
      element: <EmpPersonalDocument />,
    },
    {
      path: ROUTE_CONSTANTS.MY_MEMO,
      element: <EmpMemo />
    },
  ];

  const superAdminRoutes = [
    {
      path: ROUTE_CONSTANTS.ROLE,
      element: <Role />,
    },
  ];

  const routes = isAdmin ? adminRoutes : userRoutes;

  let mergedRoutes = [];

  if (superAdmin) {
    if (roleChange === "admin") {
      mergedRoutes = [...adminRoutes, ...superAdminRoutes];
    } else if (roleChange === "user") {
      mergedRoutes = [...userRoutes];
    } else {
      mergedRoutes = [...superAdminRoutes];
    }
  } else if (isadmin) {
    if (roleChange === "admin") {
      mergedRoutes = [...adminRoutes];
    } else if (roleChange === "user") {
      mergedRoutes = [...userRoutes];
    } else {
      mergedRoutes = [...routes];
    }
  } else {
    mergedRoutes = [...routes];
  }

  const routeConfig = [
    {
      path: "/",
      element: <DefaultRoute />,
    },
    {
      path: ROUTE_CONSTANTS.LOGIN,
      element: <Login />,
    },
    {
      path: ROUTE_CONSTANTS.REGISTER,
      element: <Register />,
    },
    {
      path: ROUTE_CONSTANTS.RESET,
      element: <ResetPassword />,
    },
    {
      path: "/",
      element: <PrivateRoute />,
      children: mergedRoutes,
    },
  ];

  const element = useRoutes(routeConfig);

  return element;
}

export default Routes;
