import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import axios from "axios";
import { COMP_OFF_API, LEAVE_API, PROJECT_API } from "../../constants/api";
import TextArea from "antd/es/input/TextArea";
import { Tooltip } from "primereact/tooltip";
import { FaTrash } from "react-icons/fa";
import { FaPen } from "react-icons/fa6";
import { InputTextarea } from "primereact/inputtextarea";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment/moment";

export default function Leave() {
  const toast = useRef(null);
  const fileInputRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [remainingAnnualLeave, setRemainingAnnualLeave] = useState([]);
  const [editedData, setEditedData] = useState({
    fromdate: "",
    todate: "",
    noOfdays: 0,
    leavetype: "",
    reason: "",
    status: "",
  });
  const [newLeaveData, setNewLeaveData] = useState({
    LeaveFrom: moment().format("YYYY-MM-DD"),
    LeaveTo: moment().format("YYYY-MM-DD")
  });
  const [leaveTypes, setLeaveTypes] = useState([
    { label: "Sick Leave", value: "Sick Leave" },
    { label: "Personal Leave", value: "Personal Leave" },
    { label: "Vacation Leave", value: "Vacation Leave" },
    { label: "Casual Leave", value: "Casual Leave" },
    { label: "Quarantine leave", value: "Quarantine leave" },
    { label: "Marriage leave", value: "Marriage leave" },
    { label: "Maternity Leave", value: "Maternity Leave" },
    { label: "Paternity Leave", value: "Paternity Leave" },
    { label: "Privilege Leave", value: "Privilege Leave" },
    { label: "Jury Duty Leave", value: "Jury Duty Leave" },
    { label: "Comp - Off", value: "Comp - Off" },
    { label: "Others", value: "Others" },
  ]);
  const [timeOff, setTimeOff] = useState([
    { label: "Paid", value: "Paid" },
    { label: "Unpaid", value: "Unpaid" },
  ]);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState(null);
  const [sortField, setSortField] = useState("fromdate");
  const [sortOrder, setSortOrder] = useState(-1);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [visible, setVisible] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [reason, setReason] = useState("");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [btnChange, setBtnChange] = useState("Apply");
  const [upDateListId, setUpDateListId] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [rowDataToDelete, setRowDataToDelete] = useState(null);
  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] =
    useState(false);
  const [projectListData, setProjectListData] = useState([]);
  const [currentYearCount, setCurrentYearCount] = useState(0);
  const [fromDateError, setFromDateError] = useState("");
  const [toDateError, setToDateError] = useState("");
  const [projectError, setProjectError] = useState("");
  const [reasonError, setReasonError] = useState("");
  const user = localStorage.getItem("user");
  const userData = JSON.parse(user);
  const userId = userData.data.id;
  const [requestLeaveModal, setRequestLeaveModal] = useState(false)

  useEffect(() => {
    getCompOffData();
  }, []);
  const getCompOffData = async () => {
    const userDataString = localStorage.getItem("user");

    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const userId = userData.data.id;

      axios
        .get(COMP_OFF_API.COMP_OFF_GET(userId))
        .then(({ data }) => {
          const compOffData = data;
          const currentYear = moment().format("YYYY");
          const currentYearCompOffs = compOffData.filter((compOff) => {
            const fromDate = moment(compOff.fromdate);
            return parseInt(fromDate.format("YYYY")) === parseInt(currentYear);
          });
          setCurrentYearCount(currentYearCompOffs.length);
          setDataList(data);
        })
        .catch((error) => {
          console.error("Error fetching comp off data:", error.message);
        });
    } else {
      console.error("User data not found in local storage");
    }
  };
  useEffect(() => {
    getProjectList();
  }, []);

  const getProjectList = async () => {
    axios
      .get(PROJECT_API.GET_ALL_PROJECTS)
      .then(({ data }) => {
        const projectsFromDB = data;
        const projectsFormatted = projectsFromDB.map((project) => ({
          label: project?.project_name,
          value: project?.project_id,
        }));
        setProjectListData(projectsFormatted);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  };

  const resetValues = () => {
    setFromDate("");
    setToDate("");
    setSelectedProject(null);
    setReason("");
  };

  const compOffData = {
    fromdate: fromDate,
    todate: toDate,
    project: selectedProject,
    reason,
    userid: userId,
  };

  const handleApply = async () => {
    if (validateValue()) {
      axios
        .post(`${COMP_OFF_API.COMP_OFF_ADD}/`, compOffData)
        .then((response) => {
          getCompOffData();
          resetValues();
          setSelectedTabIndex(1);
          showToast("success", "Request applied successfully.");
        })
        .catch((error) => {
          console.error("Error Comp off Request applied", error.message);
          const errorMessage =
            error.response?.data?.message || "Error Comp off Request applied";
          showToast("error", errorMessage);
        });
    }
  };

  const handleEdit = (rowData) => {
    setBtnChange("Update");
    setSelectedTabIndex(0);
    setFromDate(moment(rowData.fromdate).format("YYYY-MM-DD"));
    setToDate(moment(rowData.todate).format("YYYY-MM-DD") || "");

    const selectedProjectObj = projectListData.find(
      (project) => project.value === parseInt(rowData.project)
    );
    setSelectedProject(selectedProjectObj.value);
    setReason(rowData.reason);
    setUpDateListId(rowData.id);
  };

  const handleUpdate = async () => {
    if (validateValue()) {
      axios
        .put(COMP_OFF_API.COMP_OFF_UPDATE(upDateListId), compOffData)
        .then(() => {
          getCompOffData();
          resetValues();
          setBtnChange("Apply");
          setSelectedTabIndex(1);
          showToast("success", "Comp Off updated successfully");
        })
        .catch((error) => {
          console.log(error);
          const errorMessage =
            error.response.data.message || "Error updating Comp Off";
          showToast("error", errorMessage);
          console.error("Error updating leave application", error.message);
        });
    }
  };
  const handleDeleteList = (rowData) => {
    setRowDataToDelete(rowData);
    setDisplayDeleteConfirmation(true);
  };

  const confirmDeleteList = async () => {
    try {
      await axios.delete(COMP_OFF_API.COMP_OFF_DELETE(rowDataToDelete.id));
      await getCompOffData();
      showToast("success", "List deleted successfully");
      setRowDataToDelete(null);
      setDisplayDeleteConfirmation(false);
    } catch (error) {
      console.error("Error deleting list application:", error);
      showToast("error", "Error deleting list");
    }
  };

  const cancelDelete = () => {
    setRowDataToDelete(null);
    setDisplayDeleteConfirmation(false);
  };

  const actionTemplateForCompOff = (rowData) => {
    const isApproved =
      rowData.status === "approved" || rowData.status === "rejected";

    return (
      <div style={{ gap: "7px", display: "flex" }}>
        <i
          style={{
            display: "inline-block",
            marginRight: "4px",
            color: isApproved ? "#d3d3d3" : "#415ff5",
            pointerEvents: isApproved ? "none" : "auto",
          }}
          onClick={() => !isApproved && handleEdit(rowData)}
        >
          {<FaPen />}
        </i>
        <i
          style={{
            display: "inline-block",
            color: isApproved ? "#d3d3d3" : "#f64040",
            pointerEvents: isApproved ? "none" : "auto",
          }}
          onClick={() => !isApproved && handleDeleteList(rowData)}
        >
          {<FaTrash />}
        </i>
        <Dialog
          visible={displayDeleteConfirmation && rowDataToDelete === rowData}
          onHide={cancelDelete}
          modal
          position="center"
          draggable={false}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          header="Confirm Deletion"
          footer={
            <div>
              <Button
                style={{ color: "white", background: "red", border: "none" }}
                label="Cancel"
                icon="pi pi-times"
                onClick={cancelDelete}
              />
              <Button
                label="Delete"
                icon="pi pi-check"
                style={{
                  color: "white",
                  background: "#22c55e",
                  border: "none",
                }}
                onClick={confirmDeleteList}
                autoFocus
              />
            </div>
          }
        >
          <div>
            <p>Are you sure you want to delete data?</p>
          </div>
        </Dialog>
      </div>
    );
  };

  const validateValue = () => {
    let isValid = true;
    if (!fromDate && !toDate && !reason?.trim() && !selectedProject) {
      showToast("error", "Please fill in all required fields.");
      return false;
    }

    if (!fromDate) {
      setFromDateError("Please enter a start date.");
      isValid = false;
    } else {
      setFromDateError("");
    }

    if (!toDate) {
      setToDateError("Please enter an end date.");
      isValid = false;
    } else {
      setToDateError("");
    }

    if (!selectedProject) {
      setProjectError("Please select a project.");
      isValid = false;
    } else {
      setProjectError("");
    }

    if (!reason.trim()) {
      setReasonError("Please enter a reason.");
      isValid = false;
    } else {
      setReasonError("");
    }

    return isValid;
  };

  const resetErrors = () => {
    setFromDateError("");
    setToDateError("");
    setProjectError("");
    setReasonError("");
  };

  const onSort = (e) => {
    const { field, order } = e.sort;
    setSortField(field);
    setSortOrder(order);
  };
  const showConfirmationModal = (rowData) => {
    setSelectedRowToDelete(rowData);
    setDisplayConfirmation(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmation(false);
    setSelectedRowToDelete(null);
  };

  const confirmDelete = () => {
    if (selectedRowToDelete) {
      deleteRow(selectedRowToDelete);
      hideConfirmationModal();
    }
  };
  const calculateNoOfDays = (fromDate, toDate) => {
    const from = new Date(fromDate);
    const to = new Date(toDate);

    const fromUTC = Date.UTC(
      from.getFullYear(),
      from.getMonth(),
      from.getDate()
    );
    const toUTC = Date.UTC(to.getFullYear(), to.getMonth(), to.getDate());

    const timeDiff = Math.abs(toUTC - fromUTC);
    const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;

    setEditedData((prevData) => ({ ...prevData, noOfdays: diffDays }));
  };

  const calculateNoOfDaysForNewLeave = (fromDate, toDate) => {
    const from = new Date(fromDate);
    const to = new Date(toDate);

    const fromUTC = Date.UTC(
      from.getFullYear(),
      from.getMonth(),
      from.getDate()
    );
    const toUTC = Date.UTC(to.getFullYear(), to.getMonth(), to.getDate());

    const timeDiff = Math.abs(toUTC - fromUTC);
    const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;

    setNewLeaveData((prevData) => ({ ...prevData, NoOfDays: diffDays }));
  };

  useEffect(() => {
    calculateNoOfDaysForNewLeave(newLeaveData.LeaveFrom, newLeaveData.LeaveTo);
  }, [newLeaveData.LeaveFrom, newLeaveData.LeaveTo]);

  const showToast = (severity, detail) => {
    toast.current.clear();
    toast.current.show({ severity, detail, life: 3000 });
  };

  useEffect(() => {
    fetchLeaveData();
  }, []);

  const openEditModal = (rowData, rowIndex) => {
    const {
      leaveRequestId,
      fromdate,
      todate,
      noOfdays,
      leave_request_type,
      leavetype,
      reason,
      status,
      filename,
    } = rowData;
    setEditedData({
      leaveRequestId,
      fromdate,
      todate,
      noOfdays,
      leave_request_type,
      leavetype,
      reason,
      status,
      filename,
    });

    setEditIndex(rowIndex);
    setDisplayEditModal(true);
  };

  const openAddModal = () => {
    setDisplayAddModal(true);
  };

  const fetchLeaveData = () => {
    const userDataString = localStorage.getItem("user");

    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const userId = userData.data.id;

      axios
        .get(LEAVE_API.LEAVE_FETCH(userId))
        .then((response) => {
          setProducts(response.data.leaveData);
          setRemainingAnnualLeave(response.data);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });
    } else {
      console.error("User data not found in local storage");
    }
  };

  const onUpload = (event) => {
    const files = event.target.files;
    if (files.length > 5) {
      event.preventDefault();
      alert("You can only select up to 5 files.");
      return;
    }
    const fileNames = [];
    for (let i = 0; i < files.length; i++) {
      fileNames.push(files[i].name);
    }
    setUploadedFiles(fileNames);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const userDataString = localStorage.getItem("user");

    if (
      !newLeaveData.LeaveFrom ||
      !newLeaveData.LeaveTo ||
      !newLeaveData.NoOfDays ||
      !newLeaveData.LeaveRequsetType ||
      !newLeaveData.LeaveType ||
      !newLeaveData.Reason
    ) {
      setDisplayAddModal(true);
      showToast("error", "Please enter all the fields.");
      return;
    }

    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const userId = userData.data.id;
      const requestData = {
        empid: userId,
        fromdate: newLeaveData.LeaveFrom,
        todate: newLeaveData.LeaveTo,
        leavetype: newLeaveData.LeaveType,
        noOfdays: newLeaveData.NoOfDays,
        leave_request_type: newLeaveData.LeaveRequsetType,
        reason: newLeaveData.Reason,
        status: "pending",
      };

      const formData = new FormData();
      for (const key in requestData) {
        formData.append(key, requestData[key]);
      }

      const files = fileInputRef.current.files;
      if (files.length > 0) {
        Array.from(files).forEach((file) => {
          formData.append("files", file);
        });
      }

      axios
        .post(LEAVE_API.LEAVE_ADD, formData)
        .then((response) => {
          fetchLeaveData();
          showToast("success", "Leave request added successfully");
          setDisplayAddModal(false);
          setNewLeaveData({
            LeaveFrom: "",
            LeaveTo: "",
            NoOfDays: 0,
            LeaveType: "",
            Reason: "",
          });
          setUploadedFiles([]);
        })
        .catch((error) => {
          console.error("Error submitting leave application:", error.message);
          const errorMessage =
            error.response?.data?.message || "Error adding leave request";
          showToast("error", errorMessage);
        });
    } else {
      console.error("User data not found in local storage");
    }
  };

  const saveChanges = async () => {
    if (editIndex !== null) {
      const requiredFields = [
        "fromdate",
        "todate",
        "leave_request_type",
        "leavetype",
        "reason",
      ];
      if (requiredFields.some((field) => !editedData[field])) {
        setDisplayEditModal(true);
        showToast("error", "Please enter all the fields.");
        return;
      }

      const formData = new FormData();
      for (const key in editedData) {
        if (key !== "filename" || !fileInputRef.current.files.length) {
          formData.append(key, editedData[key]);
        }
      }

      if (fileInputRef.current.files.length) {
        const filenames = [];
        Array.from(fileInputRef.current.files).forEach((file, index) => {
          formData.append(`files`, file);
          filenames.push(file.name);
        });
        formData.append("filename", filenames.join(","));
      }

      try {
        const { leaveRequestId } = editedData;
        await axios.put(LEAVE_API.LEAVE_UPDATE(leaveRequestId), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        setProducts((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts[editIndex] = editedData;
          return updatedProducts;
        });

        setDisplayEditModal(false);
        fetchLeaveData();
        showToast("success", "Leave request updated successfully");
      } catch (error) {
        console.error("Error updating leave application:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          showToast("error", error.response.data.message);
        } else {
          showToast("error", "Error updating leave request");
        }
      }
    }
  };

  const deleteRow = async (rowData) => {
    const { leaveRequestId } = rowData;

    try {
      await axios.delete(LEAVE_API.LEAVE_DELETE(leaveRequestId));
      const updatedProducts = products.filter(
        (product) => product.leaveRequestId !== leaveRequestId
      );
      setProducts(updatedProducts);
      showToast("success", "Leave request deleted successfully");
      fetchLeaveData();
    } catch (error) {
      console.error("Error deleting leave application:", error);
      showToast("error", "Error deleting leave request");
    }
  };

  const today = new Date().toISOString().split("T")[0];


  const statusTemplate = (rowData) => {
    let color, label;
    switch (rowData.status) {
      case "pending":
        color = "#FEFAD3";
        label = "Pending";
        break;
      case "approved":
        color = "#C6EFCD";
        label = "Approved";
        break;
      case "rejected":
        color = "#FFC8CE";
        label = "Rejected";
        break;
      case "request":
        label = "Request"
        color = "#fd7e14"
        break
      default:
        color = "gray";
        label = "Unknown";
    }

    return (
      <span
        style={{
          backgroundColor: color,
          color: "black",
          padding: "5px",
          borderRadius: "5px",
          display: "inline-block",
        }}
      >
        {label}
        {rowData.status === "rejected" && (
          <Tooltip target={`#status_${rowData.leaveRequestId}`} position="top">
            <p style={{ color: "red", fontWeight: "bold" }}>
              Rejection Reason:{" "}
            </p>{" "}
            <span style={{ textTransform: "capitalize" }}>
              {rowData.rejection_reason}
            </span>
          </Tooltip>
        )}
      </span>
    );
  };

  const date = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonth = monthNames[date.getMonth()];


  const [userReply, setUserReply] = useState("")
  const [reasonModal, setReasonModal] = useState(false)
  const [selectedData, setSelectedData] = useState(null)
  const [dataReasonView, setDataReasonView] = useState(null)


  const actionTemplate = (rowData) => {
    if (rowData.status === "approved" || rowData.status === "rejected" && rowData.user_replay !== null
    ) {
      return null;
    }
    if (rowData.status === "rejected" && rowData.user_replay === null) {
      return (
        <Button
          tooltip="Request"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-send"
          className="p-button-rounded p-mr-2 bg-orange-400 border-orange-500"
          onClick={() => {
            setSelectedData(rowData)
            setRequestLeaveModal(true)
          }}
          style={{ marginRight: "10px", background: "rgb(22,119,255)" }}
        />
      );
    } else if (rowData.status === "request" && rowData.user_replay !== null) {
      return (
        <div className="flex items-center">
          <Button
            tooltip="View Request"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
            icon="pi pi-file
"
            className="p-button-rounded p-mr-2 bg-purple-400 border-purple-500"
            onClick={() => {
              setReasonModal(true)
              setDataReasonView(rowData)
            }}
            style={{ marginRight: "10px", background: "rgb(22,119,255)" }}
          />
          <Button
            tooltip="Request Edit"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
            icon="pi pi-pencil"
            className="p-button-rounded p-mr-2 bg-orange-400 border-orange-500"
            onClick={() => {
              setRequestLeaveModal(true)
              setUserReply(rowData.user_replay)
            }}
            style={{ marginRight: "10px", background: "rgb(22,119,255)" }}
          />
        </div>
      );
    }

    return (
      <div className="flex items-center">
        <Button
          tooltip="Edit"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-pencil"
          className="p-button-rounded p-mr-2"
          onClick={() => openEditModal(rowData)}
          style={{ marginRight: "10px", background: "rgb(22,119,255)" }}
        />
        <Button
          tooltip="Delete"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => showConfirmationModal(rowData)}
        />
      </div>
    );
  };

  const handleRequest = async () => {
    try {
      const response = await axios.put(
        LEAVE_API.LEAVE_REASON(selectedData.leaveRequestId),
        { user_replay: userReply, status: "request" }
      );
      console.log(response)
      showToast("success", "Leave request deleted successfully");
      fetchLeaveData()
      setReasonModal(false)
      setRequestLeaveModal(false)
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  }

  const getStatusColor = (rowData) => {
    switch (rowData.status) {
      case "pending":
        return {
          borderColor: "#91CAFF",
          backgroundColor: "#E6F4FF",
          color: "#4280E3",
        };
      case "approved":
        return {
          borderColor: "#B7EB8F",
          backgroundColor: "#F6FFED",
          color: "#389E0D",
        };
      case "rejected":
        return {
          borderColor: "#FF3D32",
          backgroundColor: "#FAFAFA",
          color: "#FF3D32",
        };
      case "request":
        return {
          borderColor: "#fd7e14",
          backgroundColor: "#FAFAFA",
          color: "#fd7e14",
        };
      default:
        return {
          borderColor: "#ffbf00",
          backgroundColor: "#E6F4FF",
          color: "#4280E3",
        };
    }
  };

  return (
    <div>
      <div className="flex flex-column sm:flex-row gap-2 my-2 sm:my-3 justify-content-between ">
        <div className="flex-order-1 sm:flex-order-0">
          <p
            className="mr-3"
            style={{
              borderRadius: "5px",
              color: "#030303",
              fontWeight: "500",
              fontSize: "1.2rem",
            }}
          >
            Leave Balance :
            <span
              style={{
                color: "#000",
                fontWeight: "700",
                fontSize: "1.3rem",
                marginLeft: "5px",
              }}
            >
              {remainingAnnualLeave.balanceLeave || 12}
            </span>
          </p>
        </div>
        <div className="">
          <div className="p-inputgroup">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div
        className="card p-4"
        style={{
          minWidth: "100px",
          maxWidth: "350px",
          background: "#eff5fc",
          // border: "1px solid #4fb737",
          border: "1px solid rgb(0 101 247)",
        }}
      >
        <div className="flex justify-content-between">
          <div className="">
            <p>
              <span
                className=""
                style={{ fontSize: "35px", fontWeight: "600" }}
              >
                {currentYearCount}/
              </span>
              <span style={{ fontSize: "25px", fontWeight: "300" }}>0</span>
            </p>
            <h4>{currentMonth}</h4>
          </div>
          {/* <div
            className=""
            style={{
              backgroundColor: "#18499D",
              borderRadius: "4px",
              width: "101px",
              height: "82px",
              textAlign: "center",
            }}
          >
            <i
              class="fi fi-bs-chart-user"
              style={{ fontSize: "4rem", color: "#DEE1F5", marginTop: "4px" }}
            ></i>
          </div> */}
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
            >
              <rect width="64" height="64" rx="5" fill="#18499D" />
              <path
                d="M33.1535 36.7074V40.9195C31.3294 40.2746 29.3773 40.0768 27.4609 40.3427C25.5446 40.6086 23.72 41.3304 22.1405 42.4475C20.5609 43.5647 19.2725 45.0445 18.3833 46.7628C17.4942 48.4811 17.0303 50.3876 17.0307 52.3223H13C12.9991 49.8612 13.5616 47.4325 14.6445 45.2224C15.7273 43.0123 17.3018 41.0794 19.2472 39.5719C21.1926 38.0644 23.4574 37.0223 25.8678 36.5254C28.2783 36.0285 30.7705 36.0921 33.1535 36.7074ZM29.1228 34.1842C22.4419 34.1842 17.0307 28.773 17.0307 22.0921C17.0307 15.4112 22.4419 10 29.1228 10C35.8037 10 41.2149 15.4112 41.2149 22.0921C41.2149 28.773 35.8037 34.1842 29.1228 34.1842ZM29.1228 30.1535C33.5767 30.1535 37.1842 26.546 37.1842 22.0921C37.1842 17.6382 33.5767 14.0307 29.1228 14.0307C24.6689 14.0307 21.0614 17.6382 21.0614 22.0921C21.0614 26.546 24.6689 30.1535 29.1228 30.1535ZM41.2149 42.2456V35.1919L51.2916 44.2609L41.2149 53.33V46.2763H35.1688V42.2456H41.2149Z"
                fill="#DEE1F5"
              />
            </svg>
          </div>
        </div>
        <div className="flex justify-content-between">
          <div className="">
            <p className="" style={{ fontSize: "25px", fontWeight: "bold" }}>
              Comp Off{" "}
            </p>
          </div>

          <div className="flex justify-content-between">
            <a
              href="#"
              className="p-link mt-4 hover:text-blue-300 text-blue-500"
              onClick={(e) => {
                e.preventDefault();
                setVisible(true);
              }}
            >
              Apply
            </a>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <Toast ref={toast} />
        <div className="flex justify-content-end">
          <i
            class="fi fi-sr-square-plus"
            onClick={openAddModal}
            style={{
              color: "#6366F1",
              fontSize: "40px",
              marginRight: "9px",
            }}
          ></i>
        </div>

        <DataTable
          value={products}
          paginator
          rows={5}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={onSort}
          globalFilter={globalFilter.trim() ? globalFilter : null}
          emptyMessage="No data available"
        >
          <Column
            field="fromdate"
            header="From Date"
            style={{ minWidth: "120px" }}
            body={(rowData) => moment(rowData.fromdate).format("DD-MM-YYYY")}
          />
          <Column
            field="todate"
            header="To Date"
            style={{ minWidth: "110px" }}
            body={(rowData) => moment(rowData.todate).format("DD-MM-YYYY")}
          />
          <Column
            field="noOfdays"
            header="No of Days"
            style={{ minWidth: "120px" }}
          />
          <Column
            field="leave_request_type"
            header="Time Off"
            style={{ minWidth: "100px" }}
          />
          <Column
            field="leavetype"
            header="Leave Type"
            style={{ minWidth: "100px" }}
          />
          <Column
            field="filename"
            header="Medical Certificate"
            body={(rowData) => rowData.filename ?? "-"}
            style={{ minWidth: "100px" }}
          />
          <Column
            field="reason"
            header="Reason"
            body={(rowData) => (
              <div
                id={`reason_${rowData.leaveRequestId}`}
                className="tooltip-container"
              >
                {rowData.reason.length > 20
                  ? `${rowData.reason.slice(0, 20)}...`
                  : rowData.reason}
                <Tooltip
                  target={`#reason_${rowData.leaveRequestId}`}
                  position="right"
                >
                  {rowData.reason}
                </Tooltip>
              </div>
            )}
          />
          <Column
            field="status"
            header="Status"
            body={(rowData) => (
              <span
                id={`status_${rowData.leaveRequestId}`}
                style={{
                  border: "2px solid",
                  ...getStatusColor(rowData),
                  padding: "6px 12px",
                  borderRadius: "4px",
                  position: "relative",
                  cursor: "pointer"
                }}
              >
                {rowData.status.charAt(0).toUpperCase() +
                  rowData.status.slice(1).toLowerCase()}

                {rowData.status === "rejected" && (
                  <Tooltip target={`#status_${rowData.leaveRequestId}`} position="top">
                    <p style={{ color: "red", fontWeight: "bold" }}>
                      Rejected Reason:
                    </p>{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {rowData.rejection_reason}
                    </span>
                  </Tooltip>
                )}
              </span>
            )}
            style={{ minWidth: "100px", textAlign: "center" }}
          />
          <Column
            field="Action"
            header="Actions"
            body={actionTemplate}
            style={{ minWidth: "100px" }}
          />
        </DataTable>

        {/* Edit Modal */}
        <Dialog
          visible={displayEditModal}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={() => setDisplayEditModal(false)}
          header="Edit Leave"
        >
          <label htmlFor="leaveFrom">From Date</label>
          <input
            type="date"
            id="leaveFrom"
            value={editedData.fromdate || ""}
            onChange={(e) => {
              setEditedData({ ...editedData, fromdate: e.target.value });
              calculateNoOfDays(e.target.value, editedData.todate);
            }}
            min={today}
          />

          <label htmlFor="leaveTo">To Date</label>
          <input
            type="date"
            id="leaveTo"
            value={editedData.todate || ""}
            onChange={(e) => {
              setEditedData({ ...editedData, todate: e.target.value });
              calculateNoOfDays(editedData.fromdate, e.target.value);
            }}
            min={editedData.fromdate || ""}
          />

          <div>
            <label htmlFor="noOfDays">No Of Days</label>
            <input
              type="number"
              id="noOfDays"
              value={editedData.noOfdays || ""}
              onChange={(e) =>
                setEditedData({ ...editedData, noOfdays: e.target.value })
              }
              disabled
            />
          </div>
          <div>
            <label htmlFor="leaveType">Time Off</label>
            <Dropdown
              id="leave_request_type"
              value={editedData.leave_request_type || ""}
              options={timeOff}
              onChange={(e) =>
                setEditedData({ ...editedData, leave_request_type: e.value })
              }
              placeholder="Select Time Off"
              className="w-full"
            />
          </div>

          <div>
            <label htmlFor="leaveType">Leave Type</label>
            <Dropdown
              id="leaveType"
              value={editedData.leavetype || ""}
              options={leaveTypes}
              onChange={(e) =>
                setEditedData({ ...editedData, leavetype: e.value })
              }
              placeholder="Select Leave Type"
              className="w-full"
            />
          </div>
          <div>
            <label htmlFor="reason">Reason</label>
            <TextArea
              type="text"
              id="reason"
              className="w-full"
              value={editedData.reason || ""}
              onChange={(e) =>
                setEditedData({ ...editedData, reason: e.target.value })
              }
            />
          </div>
          <div className="col-12">
            <label htmlFor="file">Medical Certificate</label>
            <input
              id="file"
              type="file"
              multiple
              onChange={(e) => {
                const files = e.target.files;
                const fileNames = [];
                for (let i = 0; i < files.length; i++) {
                  fileNames.push(files[i].name);
                }
                setUploadedFiles(fileNames.join(", "));
                fileInputRef.current.files = files;
              }}
              ref={fileInputRef}
            />

            <div className="flex">
              <div className="mt-1 ml-2">
                <i className="fi fi-rs-file-upload"></i>
              </div>
              <p className="mb-2 ml-2">
                {editedData.filename ? (
                  <span>{editedData.filename}</span>
                ) : (
                  uploadedFiles
                )}
              </p>
            </div>
          </div>
          <div className="flex justify-content-end mt-4">
            <Button
              label="Cancel"
              className="p-button-danger"
              onClick={() => {
                setDisplayEditModal(false);
                setNewLeaveData({
                  LeaveFrom: "",
                  LeaveTo: "",
                  NoOfDays: 0,
                  LeaveType: "",
                  Reason: "",
                });
                setUploadedFiles([]);
              }}
            />
            <Button label="Submit" className="mx-2" onClick={saveChanges} />
          </div>
        </Dialog>

        {/* Add Modal */}
        <Dialog
          visible={displayAddModal}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={() => setDisplayAddModal(false)}
          header="Add Leave"
        >
          <div>
            <label htmlFor="leaveFrom">From Date</label>
            <input
              type="date"
              id="leaveFrom"
              value={newLeaveData?.LeaveFrom || ""}
              onChange={(e) => {
                setNewLeaveData({ ...newLeaveData, LeaveFrom: e.target.value });
              }}
              min={today}
            />
          </div>
          <div>
            <label htmlFor="leaveTo">To Date</label>
            <input
              type="date"
              id="leaveTo"
              value={newLeaveData?.LeaveTo || ""}
              onChange={(e) => {
                setNewLeaveData({ ...newLeaveData, LeaveTo: e.target.value });
              }}
              min={newLeaveData?.LeaveFrom || ""}
            />
          </div>

          <div>
            <label htmlFor="noOfDays">No Of Days</label>
            <input
              type="number"
              id="noOfDays"
              value={newLeaveData?.NoOfDays || ""}
              onChange={(e) =>
                setNewLeaveData({ ...newLeaveData, NoOfDays: e.target.value })
              }
              disabled
            />
          </div>
          <div>
            <label htmlFor="leave_request_type">Time Off</label>
            <Dropdown
              id="leave_request_type"
              value={newLeaveData?.LeaveRequsetType || ""}
              options={timeOff}
              onChange={(e) =>
                setNewLeaveData({ ...newLeaveData, LeaveRequsetType: e.value })
              }
              placeholder="Select Time Off"
              className="w-full"
            />
          </div>
          <div>
            <label htmlFor="leaveType">Leave Type</label>
            <Dropdown
              id="leaveType"
              value={newLeaveData?.LeaveType || ""}
              options={leaveTypes}
              onChange={(e) =>
                setNewLeaveData({ ...newLeaveData, LeaveType: e.value })
              }
              placeholder="Select Leave Type"
              className="w-full"
            />
          </div>
          <div>
            <label htmlFor="reason">Reason</label>
            <TextArea
              type="text"
              id="reason"
              style={{ resize: 'none' }}
              className="w-full"
              value={newLeaveData?.Reason || ""}
              onChange={(e) =>
                setNewLeaveData({ ...newLeaveData, Reason: e.target.value })
              }
            />
          </div>
          <div className="col-12">
            <label htmlFor="file">Medical Certificate</label>
            <input
              id="file"
              type="file"
              multiple
              onChange={onUpload}
              ref={fileInputRef}
            />
            <div className="flex">
              <div className="mt-1 ml-2">
                <i className="fi fi-rs-file-upload"></i>
              </div>
              <p className="mb-2 ml-2">{uploadedFiles}</p>
            </div>
          </div>
          <div className="flex justify-content-end mt-4">
            <Button
              label="Cancel"
              className="p-button-danger"
              onClick={() => {
                setDisplayAddModal(false);
                setNewLeaveData({
                  LeaveFrom: moment().format("YYYY-MM-DD"),
                  LeaveTo: moment().format("YYYY-MM-DD"),
                  NoOfDays: 0,
                  LeaveType: "",
                  Reason: "",
                });
                setUploadedFiles([]);
              }}
            />
            <Button
              label="Submit"
              className="mx-2"
              onClick={(e) => {
                setDisplayAddModal(false);
                handleSubmit(e);
              }}
            />
          </div>
        </Dialog>

        {/* delete model */}
        <Dialog
          visible={displayConfirmation}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={hideConfirmationModal}
          header="Confirmation"
          footer={
            <div>
              <Button
                style={{ color: "white", background: "red" }}
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideConfirmationModal}
              />
              <Button
                style={{ color: "white", background: "#22c55e" }}
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={confirmDelete}
              />
            </div>
          }
        >
          Are you sure you want to delete the data?
        </Dialog>

        <Dialog
          header="Comp Off"
          visible={visible}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          position="center"
          draggable={false}
          onHide={() => {
            resetErrors();
            resetValues();
            setSelectedTabIndex(0);
            setVisible(false);
            setBtnChange("Apply");
          }}
        >
          <TabView
            activeIndex={selectedTabIndex}
            onTabChange={(e) => {
              setBtnChange("Apply");
              resetValues();
              setSelectedTabIndex(e.index);
            }}
          >
            <TabPanel header="Request Form">
              <div className="p-fluid">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  <div
                    className="p-field"
                    style={{
                      flexGrow: "1",
                      marginBottom: "8px",
                    }}
                  >
                    <label htmlFor="fromDate">Start Date</label>
                    <InputText
                      style={{
                        borderColor: fromDateError ? "red" : "",
                        marginTop: "8px",
                      }}
                      type="date"
                      id="fromDate"
                      value={fromDate}
                      onChange={(e) => {
                        setFromDate(e.target.value);
                        if (fromDateError && e.target.value) {
                          setFromDateError("");
                        }
                        if (fromDate < toDate) {
                          setToDate("");
                        }
                      }}
                      disabled={btnChange === "Update"}
                    />
                    <small style={{ color: "red" }}>{fromDateError}</small>
                  </div>

                  <div
                    className="p-field"
                    style={{
                      flexGrow: "1",
                      marginBottom: "8px",
                      borderColor: toDateError ? "red" : "",
                    }}
                  >
                    <label htmlFor="toDate">End Date</label>
                    <InputText
                      style={{
                        marginTop: "8px",
                        borderColor: toDateError ? "red" : "",
                      }}
                      type="date"
                      id="toDate"
                      value={toDate || ""}
                      onChange={(e) => {
                        if (toDateError && e.target.value) {
                          setToDateError("");
                        }
                        setToDate(e.target.value);
                      }}
                      min={fromDate}
                      disabled={btnChange === "Update"}
                    />
                    <small style={{ color: "red" }}>{toDateError}</small>
                  </div>
                </div>
                <div
                  className="p-field"
                  style={{
                    marginBottom: "8px",
                    borderColor: projectError ? "red" : "",
                  }}
                >
                  <label htmlFor="project">Project</label>
                  <Dropdown
                    style={{
                      borderColor: projectError ? "red" : "",
                      marginTop: "8px",
                    }}
                    id="project"
                    value={selectedProject}
                    options={projectListData}
                    onChange={(e) => {
                      setSelectedProject(e.value);
                      if (projectError && e.target.value) {
                        setProjectError("");
                      }
                    }}
                    optionLabel="label"
                    placeholder="Select a Project"
                  />
                  <small style={{ color: "red" }}>{projectError}</small>
                </div>
                <div
                  className="p-field"
                  style={{
                    marginBottom: "8px",
                    borderColor: reasonError ? "red" : "",
                  }}
                >
                  <label htmlFor="reason">Reason</label>
                  <InputTextarea
                    id="reason"
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                      if (reasonError && e.target.value) {
                        setReasonError("");
                      }
                    }}
                    rows={4}
                    placeholder="Enter a reason"
                    style={{
                      borderColor: reasonError ? "red" : "",
                      resize: "none",
                      marginTop: "8px",
                    }}
                  />
                  <small style={{ color: "red" }}>{reasonError}</small>
                </div>
                <div
                  className=""
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    label={btnChange === "Apply" ? "Apply" : "Update"}
                    onClick={btnChange === "Apply" ? handleApply : handleUpdate}
                    style={{ width: "auto" }}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel header="List">
              <div className="">
                <DataTable
                  value={dataList}
                  paginator={dataList.length <= 5 ? false : true}
                  rows={5}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <Column
                    field="date"
                    header="From Date"
                    body={(rowData) =>
                      moment(rowData.fromdate).format("DD-MM-YYYY")
                    }
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    field="date"
                    header="To Date"
                    body={(rowData) =>
                      moment(rowData.todate).format("DD-MM-YYYY")
                    }
                    style={{ whiteSpace: "nowrap" }}
                  ></Column>
                  <Column
                    style={{ whiteSpace: "nowrap" }}
                    field="duration"
                    header="Duration"
                    body={(rowData) => {
                      const daysAgo = moment(rowData.todate).diff(
                        moment(rowData.fromdate),
                        "d"
                      );
                      if (daysAgo === 0) {
                        return "1 day";
                      } else {
                        return `${daysAgo + 1} days`;
                      }
                    }}
                  ></Column>
                  <Column
                    field="status"
                    header="Status"
                    style={{ whiteSpace: "nowrap" }}
                    body={statusTemplate}
                  ></Column>
                  <Column
                    field="reason"
                    header="Reason"
                    body={(rowData) => (
                      <div
                        id={`reason_show_${rowData.id}`}
                        className="tooltip-container"
                      >
                        {rowData.reason.length > 20
                          ? `${rowData.reason.slice(0, 20)}...`
                          : rowData.reason}
                        <Tooltip
                          target={`#reason_show_${rowData.id}`}
                          position="right"
                        >
                          {rowData.reason}
                        </Tooltip>
                      </div>
                    )}
                  ></Column>
                  <Column
                    style={{ whiteSpace: "nowrap" }}
                    header="Actions"
                    body={actionTemplateForCompOff}
                  ></Column>
                </DataTable>
              </div>
            </TabPanel>
          </TabView>
        </Dialog>

        {/* RequestModal */}

        <Dialog
          header={"Request Leave"}
          visible={requestLeaveModal}
          onHide={() => {
            setRequestLeaveModal(false)
          }}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          position="center"
          draggable={false}
          footer={
            <div>
              <Button
                style={{ color: "white", background: "red" }}
                label="Cancel"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                  setUserReply("")
                  setRequestLeaveModal(false)
                }}
              />
              <Button
                style={{ color: "white", background: "#22c55e" }}
                label="Request"
                icon="pi pi-check"
                className="p-button-text"
                onClick={handleRequest}
              />
            </div>
          }>
          <div className="my-1">
            <label htmlFor="user_reply">Reason</label>
            <TextArea
              type="text"
              id="user_reply"
              style={{ resize: 'none' }}
              className="w-full"
              value={userReply}
              onChange={(e) => setUserReply(e.target.value)} />
          </div>
        </Dialog>

        {/* Request Modal  */}
        <Dialog
          header={'Request Reason'}
          visible={reasonModal}
          onHide={() => setReasonModal(false)}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          position="center"
          draggable={false}
        >
          <p><span className="font-bold">Reason:
          </span> {dataReasonView?.user_replay}
          </p>
        </Dialog>

      </div>
    </div>
  );
}
