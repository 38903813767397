import React, { useEffect, useState } from "react";
import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup";
import { PROJECT_API, EMPLOYEE_API } from "../../constants/api";
import axios from "axios";
import moment from "moment/moment";

const Myproject = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [employees, setEmployees] = useState([]);

  const fetchTeamMembers = async () => {
    try {
      const userString = localStorage.getItem("user");
      const user = JSON.parse(userString);
      if (!user || !user.data || !user.data.id) {
        console.error(
          "User object is null, undefined, or does not contain an id property."
        );
        return;
      }
      const response = await axios.get(
        PROJECT_API.GET_EMPLOYEE_PROJECT(user.data.id)
      );
      if (response.status === 200) {
        setTeamMembers(response.data);
      } else {
        throw new Error("Failed to fetch team members");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.GET_ALL);
      const { usersData } = response.data;
      setEmployees(usersData);
    } catch (error) {
      console.error("Error fetching approvers:", error);
    }
  };

  useEffect(() => {
    fetchTeamMembers();
    fetchEmployees();
  }, []);

  const colors = ["#FEE4CB", "#e5fff0", "#ffdfea", "#efefff", "#def4fe"];

  return (
    <div className="my-project">
      <h2 className="my-2">Projects</h2>
      <div className="card w-full">
        {teamMembers.length === 0 ? (
          <p>No projects found</p>
        ) : (
          <div className="grid">
            {teamMembers.map((project, index) => (
              <div key={index} className="md:col-3 col-12">
                <div
                  className="card"
                  style={{
                    borderRadius: "20px",
                    border: "none",
                    background: colors[index % colors.length],
                  }}
                >
                  <h4
                    className="mb-3 text-center"
                    style={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      textTransform: "capitalize",
                    }}
                  >
                    {project.project_name}
                  </h4>
                  <div>
                    <span className="mt-3">{project.project_description}</span>
                  </div>
                  <div className="">
                    <p className="mt-3">
                      <span
                        className="mr-2"
                        style={{ color: "#4f4f4f", fontWeight: "500" }}
                      >
                        Start Date:
                      </span>
                      <span className="ml-2 mr-2">
                        <i class="fi fi-rr-calendar"></i>
                      </span>
                      {moment(project.created_at).format("DD-MM-YYYY")}
                    </p>
                    <p className="">
                      <span
                        className="mr-2"
                        style={{ color: "#4f4f4f", fontWeight: "500" }}
                      >
                        End Date:
                      </span>
                      <span className="ml-3 mr-2">
                        <i class="fi fi-rr-calendar"></i>
                      </span>

                      {moment(project.deadline).format("DD-MM-YYYY")}
                    </p>
                  </div>

                  <div className="flex justify-content-between my-3">
                    <p
                      className=""
                      style={{
                        background:
                          project.project_priority === "High"
                            ? "#FE6E72"
                            : project.project_priority === "Medium"
                            ? "#E900E9"
                            : project.project_priority === "Low"
                            ? "#00DA52"
                            : "",
                        borderRadius: "15px",
                        textAlign: "center",
                        fontWeight: "500",
                        padding: "4px 10px",
                        color: "white",
                      }}
                    >
                      {project.project_priority}
                    </p>
                    <p className="" style={{ fontWeight: "600" }}>
                      {project.project_status}
                    </p>
                  </div>

                  <div className="flex">
                    <AvatarGroup>
                      {project.teammembers.split(",").map((memberId, index) => {
                        const employee = employees.find(
                          (emp) => emp.id === memberId.trim()
                        );
                        if (employee) {
                          return (
                            <Avatar
                              key={index}
                              label={`${employee.firstname.charAt(
                                0
                              )}${employee.lastname.charAt(0)}`}
                              title={`${employee.firstname} ${employee.lastname}`}
                              size="small"
                              style={{
                                backgroundColor: "#2196F3",
                                color: "#ffffff",
                                marginLeft: "-8px",
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                            />
                          );
                        }
                        return null;
                      })}
                    </AvatarGroup>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const styles = `
  .my-project {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .card {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  .grid {
    // display: flex;
    // flex-wrap: wrap;
    // gap: 20px;
  }

  .col-3 {
    flex: 0 0 calc(33.333% - 20px);
    max-width: calc(33.333% - 20px);
  }
`;

// Apply the styles
const styleElement = document.createElement("style");
styleElement.innerHTML = styles;
document.head.appendChild(styleElement);

export default Myproject;
