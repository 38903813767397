import React, { useState, useEffect } from "react";
import profile from "../../../assets/images/profile.avif";
import axios from "axios";
import { CATEGORY_API, DEPARTMENT_API, EMPLOYEE_API } from "../../../constants/api";
import { Skeleton } from 'primereact/skeleton';

const Detail = ({ userId }) => {
    const [profileData, setProfileData] = useState(null);
    const [departmentData, setDepartmentData] = useState([]);
    const [staffCategoryData, setStaffCategoryData] = useState([]);
    const [loadingProfile, setLoadingProfile] = useState(true);
    const [loadingDepartments, setLoadingDepartments] = useState(true);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                if (userId) {
                    const response = await axios.get(EMPLOYEE_API.USER_PROFILE(userId));
                    setProfileData(response.data.userData);
                }
            } catch (error) {
                console.error("Error fetching user profile:", error);
            } finally {
                setLoadingProfile(false);
            }
        };
        fetchProfileData();
    }, [userId]);

    const getAllDepartment = async () => {
        try {
            const response = await axios.get(DEPARTMENT_API.DEPARTMENT_FETCH);
            setDepartmentData(response.data);
        } catch (error) {
            console.error("Error fetching departments:", error);
        } finally {
            setLoadingDepartments(false);
        }
    };

    const getAllStaffCategory = async () => {
        try {
            const { data } = await axios.get(CATEGORY_API.GET_ALL_CATEGORY);
            setStaffCategoryData(data);
        } catch (error) {
            console.error("Error fetching departments:", error);
        } finally {
            setLoadingDepartments(false);
        }
    };

    useEffect(() => {
        getAllStaffCategory()
        getAllDepartment();
    }, []);

    const categoryName = (id) => {
        return staffCategoryData?.filter(category => category.category_id == id)[0]?.category_name
    }

    if (loadingProfile || loadingDepartments) {
        return (
            <div className="emp-profile">
                <div className="">
                    <div className="grid">
                        <div className="md:col-12 lg:col-4 col-12">
                            <div className="card flex flex-column align-items-center gap-2">
                                <Skeleton shape="circle" width="8rem" height="8rem" />
                                <Skeleton className="mt-3" width="10rem" height="2rem" />
                                <Skeleton width="8rem" height="1.5rem" />
                                <div className="flex">
                                    <Skeleton width="10rem" height="1.5rem" />
                                </div>
                            </div>
                        </div>
                        <div className="md:col-12 lg:col-8 col-12">
                            <div className="card" style={{ overflowX: "scroll" }}>
                                <table
                                    className="table"
                                    style={{ width: "100%", borderCollapse: "collapse" }}
                                >
                                    <tbody>
                                        {[...Array(20)].map((_, index) => (
                                            <tr key={index} className=" flex justify-content-center gap-3 w-full">
                                                <td >
                                                    <Skeleton width="8rem" height="1.5rem" />
                                                </td>
                                                <td className="p-0">
                                                    <Skeleton width="12rem" height="1.5rem" />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!profileData) {
        return null;
    }

    const departmentName = departmentData.find(
        (department) =>
            department.department_id === parseInt(profileData.department)
    );

    return (
        <div className="emp-profile">
            <div className="">
                <div className="grid">
                    <div className="md:col-12 lg:col-4 col-12">
                        <div className="card flex flex-column align-items-center">
                            <img
                                src={profile}
                                alt=""
                                style={{ width: "8rem", borderRadius: "50%", color: "#3a3a3a" }}
                            />
                            <h2 className="mt-3">
                                {profileData.firstname} {profileData.lastname}
                            </h2>
                            <p className="font-semibold" style={{ color: "#9c9c9c" }}>
                                {profileData.position}
                            </p>
                            <div className="flex">
                                <p className="font-semibold" style={{ color: "#9c9c9c" }}>
                                    {profileData.email}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="md:col-12 lg:col-8 col-12">
                        <div className="card" style={{ overflowX: "scroll" }}>
                            <table
                                className="table"
                                style={{ width: "100%", borderCollapse: "collapse" }}
                            >
                                <tbody>
                                    <TableRow
                                        label="HRMS ID"
                                        value={profileData.id}
                                        icon="fi fi-sr-id-badge"
                                    />
                                    <TableRow
                                        label="KID ID"
                                        value={profileData.kid_id || ""}
                                        icon="fi fi-sr-membership"
                                    />
                                    {profileData?.category?.category_id &&
                                        <TableRow
                                            label="Staff Category"
                                            value={categoryName(profileData?.category?.category_id) || ""}
                                            icon="fi fi-sr-skill"
                                        />
                                    }
                                    <TableRow
                                        className="table-even"
                                        label="Mobile Number"
                                        value={profileData.contact_number}
                                        icon="fi fi-sr-phone-call"
                                    />
                                    <TableRow
                                        label="Date Of Birth"
                                        value={profileData.DOB}
                                        icon="fi fi-sr-calendar"
                                    />
                                    <TableRow
                                        className="table-even"
                                        label="Status"
                                        value={profileData.marital_status}
                                        icon="fi-sr-user"
                                    />
                                    <TableRow
                                        label="Gender"
                                        value={profileData.gender}
                                        icon="fi-sr-venus-mars"
                                    />
                                    <TableRow
                                        className="table-even"
                                        label="Department"
                                        value={departmentName}
                                        icon="fi-sr-pencil-paintbrush"
                                    />
                                    <TableRow
                                        label="Designation"
                                        value={profileData.position}
                                        icon="fi-sr-briefcase"
                                    />
                                    <TableRow
                                        className="table-even"
                                        label="Joining Date"
                                        value={profileData.joining_date}
                                        icon="fi-sr-calendar"
                                    />
                                    <TableRow
                                        label="Salary"
                                        value={profileData.salary}
                                        icon="fi-sr-user"
                                    />
                                    <TableRow
                                        label="Employment"
                                        value={profileData.employee_type}
                                        icon="fi-sr-employee-man-alt"
                                    />
                                    <TableRow
                                        className="table-even"
                                        label="Employment Status"
                                        value={profileData.employment_status}
                                        icon="fi-sr-tools"
                                    />
                                    <TableRow
                                        label="Bank Name"
                                        value={profileData.bank_name}
                                        icon="fi-sr-bank"
                                    />
                                    <TableRow
                                        className="table-even"
                                        label="Account Number"
                                        value={profileData.account_number}
                                        icon="fi-sr-credit-card"
                                    />
                                    <TableRow
                                        label="IFSC Code"
                                        value={profileData.IFSC_Code}
                                        icon="fi-sr-code-branch"
                                    />
                                    {profileData?.feedback &&
                                        <TableRow
                                            label="Feedback"
                                            value={profileData?.feedback}
                                            icon="fi fi-rs-feedback-review"
                                        />
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const TableRow = ({ label, value, icon }) => {
    let displayValue = value;
    if (label === "Department" && typeof value === "object") {
        displayValue = value.department_name;
    }

    return (
        <tr>
            <td>
                <h5
                    style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        padding: "7px",
                        color: "#7c7c7c",
                        borderBottom: "1px solid #d9d9d9",
                    }}
                >
                    <span
                        className={`mr-2 fi ${icon}`}
                        style={{ marginRight: "16px" }}
                    ></span>
                    {label}:
                </h5>
            </td>
            <td className="p-0">
                <p
                    className=""
                    style={{
                        color: "rgb(99 102 241)",
                        fontWeight: "500",
                        padding: "7px",
                        fontSize: "16px",
                        borderBottom: "1px solid #d9d9d9",
                    }}
                >
                    {displayValue}
                </p>
            </td>
        </tr>
    );
};

export default Detail;
