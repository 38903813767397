import React, { useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ErrorRoute from "../component/common/ErrorRoute";
import Loader from "../component/common/Loader";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";
import MainLayout from "../layout/MainLayout";
import { getLocalStorage } from "../utills/localHelpers";
import { LOCAL_CONSTANTS } from "../constants/localConstants";
import { Toast } from "primereact/toast";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

function PrivateRoute() {
  const toastRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const token = getLocalStorage(LOCAL_CONSTANTS.ACCESS);
  const isAuth = Boolean(token);
  const isPermit = true;

  const showToast = (message) => {
    toastRef?.current?.show({
      severity: "info",
      summary: "Info Message",
      detail: message,
      life: 9000,
    });
  };

  const getLastSegment = () => {
    const url = window.location.href;
    const segments = url.split('/');
    return segments[segments.length - 1];
  };

  const lastSegment = getLastSegment();

  const getuser = async () => {
    const url = window.location.href;
    const segments = url.split('/');
    if (segments[5]?.length !== undefined) {
      const response = await axios.get(`https://khrms.kodukku.com/api/auth/getuser?kcplid=${lastSegment}`);
      const data = response.data.data;
    }
  }

  useEffect(() => {
    getuser();
  }, []);

  const decodeToken = (token) => {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };

  const isTokenExpired = () => {
    if (location.pathname === ROUTE_CONSTANTS.LOGIN) {
      return false;
    }

    if (!token) {
      return true;
    }

    const decodedToken = decodeToken(token);
    if (!decodedToken || !decodedToken.exp) {
      return true;
    }

    const currentTime = Math.floor(Date.now() / 1000);
    if (decodedToken.exp < currentTime) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (isTokenExpired()) {
      localStorage.removeItem(LOCAL_CONSTANTS.ACCESS);
      localStorage.removeItem(LOCAL_CONSTANTS.USER);
      navigate(ROUTE_CONSTANTS.LOGIN);
      showToast("Session expired. Please login to continue.");
    }
  }, [location.pathname, token]);

  return isAuth ? (
    isPermit ? (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ) : (
      <ErrorRoute />
    )
  ) : (
    <Loader />
  );
}

export default PrivateRoute;
