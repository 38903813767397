import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Menu } from 'primereact/menu';
import { Dialog } from 'primereact/dialog';
import { ASSETS_API, EMPLOYEE_API } from '../../constants/api';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';
import AllAssetsModal from './AllAssetsModal';

const AllAssetsDetail = () => {
  const [requests, setRequests] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const toast = useRef(null);
  const menuRef = useRef(null);

  const showToast = (severity, detail) => {
    toast.current.show({ severity, detail, life: 3000 });
  };

  const fetchEmployeeData = () => {
    axios.get(EMPLOYEE_API.GET_ALL)
      .then(response => {
        setEmployeeOptions(response.data.usersData.map(employee => ({
          label: `${employee.firstname} ${employee.lastname}`,
          value: employee.empid
        })));
      })
      .catch(error => {
        console.error('Error fetching employee data:', error);
        showToast('error', 'Failed to fetch employees');
      });
  };

  const fetchData = async () => {
    axios.get(ASSETS_API.GET_ALL)
      .then(response => {
        setRequests(response?.data);
      })
      .catch(error => {
        console.error('Error fetching requests:', error);
        showToast('error', 'Failed to fetch requests');
      });
  };

  useEffect(() => {
    fetchData();
    fetchEmployeeData();
  }, []);

  const openEditModal = (asset) => {
    setSelectedAsset(asset);
    setIsNew(false);
    setModalVisible(true);
  };

  const openAddModal = () => {
    setSelectedAsset(null);
    setIsNew(true);
    setModalVisible(true);
  };

  const handleSave = () => {
    fetchData();
    setModalVisible(false);
  };

  const handleContextMenu = (event, rowData) => {
    event.preventDefault();
    setSelectedRequest(rowData);
    menuRef.current.show(event);
  };

  const handleEdit = () => {
    openEditModal(selectedRequest);
  };

  const handleDelete = () => {
    setConfirmDeleteVisible(true);
  };

  const confirmDelete = () => {
    axios.delete(ASSETS_API.ASSETS_DELETE(selectedRequest.id))
      .then(() => {
        showToast('success', 'Assets deleted successfully');
        fetchData();
        setConfirmDeleteVisible(false);
      })
      .catch(error => {
        console.error('Error deleting asset:', error);
        showToast('error', 'Failed to delete asset');
      });
  };

  const cancelDelete = () => {
    setConfirmDeleteVisible(false);
  };

  const menuItems = [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: handleEdit,
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: handleDelete,
    }
  ];

  const actionBodyTemplate = (rowData) => (
    <div className="flex" style={{ alignItems: "center" }}>
      <Button
        icon="pi pi-ellipsis-v"
        className="p-button-rounded p-button-text"
        onClick={(e) => handleContextMenu(e, rowData)}
      />
      <Menu model={menuItems} popup ref={menuRef} className="w-8rem" />
    </div>
  );

  return (
    <div>
      <Toast ref={toast} />
      <div className="col-12 md:flex block justify-content-between w-full align-items-center">
        <h2 className="my-1">All Assets</h2>
        <div className="md:col-5 lg:col-2 col-12 mt-0">
          <div className="p-inputgroup">
            <InputText type="text" id="globalFilter" className="search-input" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
            <Button icon="fi fi-bs-search" className="search-icon" />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="flex w-full justify-content-end mb-2">
          <Button icon="pi pi-plus" tooltip="Add Assets" onClick={openAddModal} className="" tooltipOptions={{ position: 'left' }} />
        </div>
        <DataTable
          value={requests}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          tableStyle={{ minWidth: '50rem' }}
          globalFilter={globalFilter}
          emptyMessage="No data found"
        >
          <Column header="S.No" body={(rowData) => <span>{requests.indexOf(rowData) + 1}</span>} />
          <Column header="Name" style={{ minWidth: '120px' }} body={(rowData) => {
            return rowData.firstname && rowData.lastname
              ? `${rowData.firstname} ${rowData.lastname}`
              : '-'
          }} />
          <Column
            field="assetname"
            style={{ minWidth: '120px' }}
            header="Asset"
            sortable
            body={(rowData) => rowData.assetname || '-'}
          />
          <Column
            field="assetcategory"
            style={{ minWidth: '120px' }}
            header="Category"
            sortable
            body={(rowData) => rowData.assetcategory || '-'}
          />
          <Column
            field="serialnumber"
            style={{ minWidth: '120px' }}
            header="Serial"
            sortable
            body={(rowData) => rowData.serialnumber || '-'}
          />
          <Column
            field="issuedate"
            style={{ minWidth: '120px' }}
            header="Issue"
            body={(rowData) => rowData.issuedate ? moment(rowData.issuedate).format("DD-MM-YYYY") : '-'}
          />
          <Column
            field="returndate"
            style={{ minWidth: '120px' }}
            header="Return"
            body={(rowData) => rowData.returndate ? moment(rowData.returndate).format("DD-MM-YYYY") : '-'}
          />
          <Column
            field="assetstatus"
            style={{ minWidth: '120px' }}
            header="Status"
            sortable
            body={(rowData) => rowData.assetstatus || '-'}
          />
          <Column
            body={actionBodyTemplate}
            style={{ minWidth: '100px' }}
            header="Actions"
          />
        </DataTable>
      </div>

      {modalVisible && (
        <AllAssetsModal
          visible={modalVisible}
          onHide={() => setModalVisible(false)}
          assetData={selectedAsset}
          onSave={handleSave}
          isNew={isNew}
          employeeOptions={employeeOptions}
        />
      )}
      <Dialog visible={confirmDeleteVisible} onHide={() => setConfirmDeleteVisible(false)} header="Delete Confirmation" footer={<div>
        <Button label="No" icon="pi pi-times" onClick={cancelDelete} className="p-button-text" />
        <Button label="Yes" icon="pi pi-check" onClick={confirmDelete} className="p-button-danger" />
      </div>}>
        <p>Are you sure you want to delete this asset?</p>
      </Dialog>
    </div>
  );
};

export default AllAssetsDetail;
