import React from "react";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import Select from "react-select";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";

const TaskEdit = ({ visible, setVisible, addform, projectOptions, setAddform, formErrors, handleAddTask, editData, user, approverOptions, employees, priority, handleEmployeeSelectChange, onUpload, status, fileInputRef, uploadedFiles, participants, handleParticipantSelectChange, startTime, handleStartTimeChange, endTime, handleEndTimeChange, duration, handleCancel, handleAddTaskSubmit, isFormValid }) => {

    return (
        <Dialog
            header="Edit Task"
            visible={visible}
            onHide={() => {
                setVisible(false);
            }}
            style={{ width: "55vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
            <div className="grid">
                <div className="md:col-6 col-12">
                    <label htmlFor="Project">Project</label>
                    <Dropdown
                        value={addform.projectname}
                        name="projectname"
                        id="projectname"
                        options={projectOptions.map((project) => ({
                            label: project.project_name,
                            value: project.project_name,
                        }))}
                        placeholder="Select a project"
                        className="w-full"
                        onChange={(e) => {
                            setAddform({ ...addform, projectname: e.value });
                        }}
                    />
                    {formErrors.projectname && (
                        <small className="text-red-500">{formErrors.projectname}</small>
                    )}
                </div>
                <div className="md:col-6 col-12">
                    <label htmlFor="TaskName">Task Name</label>
                    <InputText
                        id="task_name"
                        name="task_name"
                        value={addform.task_name}
                        onChange={handleAddTask}
                        className="w-full"
                    />
                </div>
                {!editData && (
                    <div className="md:col-3 col-12">
                        <label htmlFor="Maker">Maker</label>
                        <InputText
                            value={`${user.data.firstname} ${user.data.lastname}`}
                            readOnly
                            className="w-full"
                        />
                        <input type="hidden" name="makerId" value={user.data.id} />
                    </div>
                )}
                <div className="md:col-3 col-12">
                    <label htmlFor="Task">Approver</label>
                    <Dropdown
                        value={addform.approver}
                        className="w-full"
                        options={approverOptions}
                        optionLabel="label"
                        placeholder="Select an approver"
                        onChange={(e) => setAddform({ ...addform, approver: e.value })}
                        filter
                        showClear
                    />
                    {formErrors.approver && (
                        <small className="text-red-500">{formErrors.approver}</small>
                    )}
                </div>
                <div className="md:col-6 col-12">
                    <label htmlFor="Task">Processor</label>
                    <Select
                        value={addform.processorselect.map((option) => ({
                            label: option.label,
                            value: option.value,
                        }))}
                        onChange={handleEmployeeSelectChange}
                        options={employees}
                        isMulti
                        isClearable
                        placeholder="Select team members"
                        getOptionLabel={(option) => option.label}
                    />
                    {formErrors.processorselect && (
                        <small className="text-red-500">
                            {formErrors.processorselect}
                        </small>
                    )}
                </div>
                <div className="md:col-6 col-12">
                    <label htmlFor="Priority">Priority</label>
                    <Dropdown
                        value={addform.priority}
                        name="priority"
                        options={priority}
                        optionLabel="name"
                        placeholder="Select priority"
                        className="w-full "
                        onChange={handleAddTask}
                    />
                    {formErrors.priority && (
                        <small className="text-red-500">{formErrors.priority}</small>
                    )}
                </div>
                <div className="md:col-6 col-12">
                    <label htmlFor="Status">Status</label>
                    <Dropdown
                        name="status"
                        value={addform.status}
                        options={status}
                        optionLabel="name"
                        placeholder="Select status"
                        className="w-full "
                        onChange={handleAddTask}
                    />
                    {formErrors.status && (
                        <small className="text-red-500">{formErrors.status}</small>
                    )}
                </div>
                <div className="md:col-6 col-12">
                    <label htmlFor="StartDate">Start Date</label>
                    <InputText
                        type="date"
                        id="StartDate"
                        name="StartDate"
                        value={addform.StartDate}
                        onChange={handleAddTask}
                        showIcon
                        style={{ width: "100%" }}
                        className={formErrors.StartDate ? "p-invalid" : ""}
                        min={new Date().toISOString().split("T")[0]}
                        disabled={!!editData}
                    />
                    {formErrors.StartDate && (
                        <small className="text-red-500">{formErrors.StartDate}</small>
                    )}
                </div>
                <div className="md:col-6 col-12">
                    <label htmlFor="DueDate">Due Date</label>
                    <InputText
                        id="DueDate"
                        name="DueDate"
                        type="date"
                        value={addform.DueDate}
                        showIcon
                        onChange={handleAddTask}
                        style={{ width: "100%" }}
                        className={formErrors.StartDate ? "p-invalid" : ""}
                        min={addform.StartDate || ""}
                    />
                    {formErrors.DueDate && (
                        <small className="text-red-500">{formErrors.DueDate}</small>
                    )}
                </div>
                <div className="col-12">
                    <label htmlFor="Details">Details</label>
                    <InputTextarea
                        id="Details"
                        name="Details"
                        value={addform.Details}
                        onChange={handleAddTask}
                        placeholder="Enter a task description..."
                        style={{ resize: "none" }}
                    />
                    {formErrors.Details && (
                        <small className="text-red-500">{formErrors.Details}</small>
                    )}
                </div>
                <div className="col-12">
                    <label htmlFor="file">Task Attachment</label>
                    <input
                        id="file"
                        type="file"
                        multiple
                        onChange={onUpload}
                        ref={fileInputRef}
                    />
                    <div className="flex">
                        <div className="mt-1 ml-2">
                            <i className="fi fi-rs-file-upload"></i>
                        </div>
                        <p className="mb-2 ml-2">{uploadedFiles}</p>
                    </div>
                </div>
            </div>
            <div>
                <h4 style={{ textTransform: "uppercase" }}>Schedule Meeting</h4>
                <div className="grid">
                    <div className="md:col-6 col-12">
                        <label htmlFor="TaskName">Meeting Title</label>
                        <InputText
                            id="meeting_title"
                            name="meeting_title"
                            value={addform.meeting_title}
                            onChange={handleAddTask}
                            className="w-full"
                            placeholder="Meeting Title"
                        />
                    </div>
                </div>
                <div className="col-12" style={{ marginLeft: "-5px" }}>
                    <label htmlFor="Details">Agenda</label>
                    <InputTextarea
                        id="agenda"
                        name="agenda"
                        value={addform.agenda}
                        onChange={handleAddTask}
                        placeholder="Agenda"
                        style={{ resize: "none" }}
                    />
                    {formErrors.agenda && (
                        <small className="text-red-500">{formErrors.agenda}</small>
                    )}
                </div>
                <div className="col-12" style={{ marginLeft: "-5px" }}>
                    <label htmlFor="Task">Participants</label>
                    <Select
                        value={participants}
                        onChange={handleParticipantSelectChange}
                        options={employees}
                        isMulti
                        isClearable
                        placeholder="Select Participants"
                        getOptionLabel={(option) => (
                            <div className="flex align-items-center">{`${option.label}`}</div>
                        )}
                    />

                    {formErrors.participants && (
                        <small className="text-red-500">
                            {formErrors.participants}
                        </small>
                    )}
                </div>
                <div className="grid">
                    <div className="md:col-4 col-12">
                        <label htmlFor="MeetingSheduleDate">Schedule it on</label>
                        <InputText
                            type="date"
                            id="MeetingSheduleDate"
                            name="MeetingSheduleDate"
                            value={addform.MeetingSheduleDate || ""}
                            onChange={handleAddTask}
                            showIcon
                            style={{ width: "100%" }}
                            min={new Date().toISOString().split("T")[0]}
                        />
                    </div>

                    <div className="md:col-2 col-12">
                        <label htmlFor="startTime">Start Time</label>
                        <input
                            type="time"
                            id="startTime"
                            name="startTime"
                            value={startTime}
                            onChange={handleStartTimeChange}
                        />
                    </div>
                    <div className="md:col-2 col-12">
                        <label htmlFor="endTime">End Time</label>
                        <input
                            type="time"
                            id="endTime"
                            name="endTime"
                            value={endTime}
                            onChange={handleEndTimeChange}
                        />
                    </div>
                    <div className="md:col-3 col-12">
                        <label htmlFor="duration">Duration</label>
                        <input
                            type="text"
                            id="duration"
                            name="duration"
                            value={duration}
                            readOnly
                        />
                    </div>
                </div>
            </div>
            <div className="flex justify-content-end mt-4">
                <Button
                    label="Cancel"
                    onClick={handleCancel}
                    className="bg-red-400"
                    style={{ background: "red", border: "none" }}
                />
                <Button
                    onClick={handleAddTaskSubmit}
                    label={"Update"}
                    disabled={!isFormValid}
                    className="mx-2"
                />
            </div>
        </Dialog>
    )
}

export default TaskEdit