import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

export default function EmployeePayrollDelete({
  displayConfirmation,
  hideConfirmationModal,
  confirmDelete,
}) {
  return (
    <Dialog
      visible={displayConfirmation}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={hideConfirmationModal}
      header="Confirmation"
      footer={
        <div>
          <Button
            style={{ color: "white", background: "red" }}
            label="No"
            icon="pi pi-times"
            className="p-button-text"
            onClick={hideConfirmationModal}
          />
          <Button
            style={{ color: "white", background: "#22c55e" }}
            label="Yes"
            icon="pi pi-check"
            className="p-button-text"
            onClick={confirmDelete}
          />
        </div>
      }
    >
      Are you sure you want to delete the payroll data?
    </Dialog>
  );
}
