import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const Job = ({ jobInfo }) => {
  const renderValue = (rowData, field) => {
    return rowData[field] && rowData[field] !== "" ? rowData[field] : "-";
  };

  return (
    <div className="p-datatable-customers">
      <DataTable value={jobInfo} className="p-datatable-customers"        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50]}
        emptyMessage="No data available">
        <Column header="S.No" body={(rowData) => <span>{jobInfo.indexOf(rowData) + 1}</span>} />
        <Column field="jobDetails_company_name" header="Company Name" body={(rowData) => renderValue(rowData, 'jobDetails_company_name')} />
        <Column field="JobResponsibilities" header="Job Responsibilities" body={(rowData) => renderValue(rowData, 'JobResponsibilities')} />
        <Column field="postfixDesignation" header="Postfix Designation" body={(rowData) => renderValue(rowData, 'postfixDesignation')} />
        <Column field="prefixDesignation" header="Prefix Designation" body={(rowData) => renderValue(rowData, 'prefixDesignation')} />
        <Column field="industry" header="Industry" body={(rowData) => renderValue(rowData, 'industry')} />
        <Column field="salary" header="Salary" body={(rowData) => renderValue(rowData, 'salary')} />
        <Column field="totalSalary" header="Total Salary" body={(rowData) => renderValue(rowData, 'totalSalary')} />
        <Column field="workExperience" header="Work Experience" body={(rowData) => renderValue(rowData, 'workExperience')} />
        <Column field="jobDetails_issue_date" style={{ minWidth: '120px' }} header="Issue Date" body={(rowData) => renderValue(rowData, 'jobDetails_issue_date')} />
        <Column field="jobDetails_validity_date" header="Validity Date" style={{ minWidth: '120px' }} body={(rowData) => renderValue(rowData, 'jobDetails_validity_date')} />
        <Column field="verificationemail" header="Verification Email" body={(rowData) => renderValue(rowData, 'verificationemail')} />
        <Column field="otherRole" header="Other Role" body={(rowData) => renderValue(rowData, 'otherRole')} />
        <Column field="otherPrefixDesignation" header="Other Prefix Designation" body={(rowData) => renderValue(rowData, 'otherPrefixDesignation')} />
        <Column field="otherPostfixDesignation" header="Other Postfix Designation" body={(rowData) => renderValue(rowData, 'otherPostfixDesignation')} />
        <Column field="otherIndustry" header="Other Industry" body={(rowData) => renderValue(rowData, 'otherIndustry')} />
      </DataTable>
    </div>
  );
}

export default Job;
