import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const ConfirmationDialog = ({ visible, onHide, onConfirm }) => {
  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Confirmation"
      modal
      footer={
        <div>
          {/* <Button label="No" icon="pi pi-times" onClick={onHide} className="p-button-text" />
          <Button label="Yes" icon="pi pi-check" onClick={onConfirm} autoFocus /> */}
          <Button
              style={{ color: "white", background: "red" }}
              label="No"
              icon="pi pi-times"
              className="p-button-text"
              onClick={onHide}
            />
            <Button
              style={{ color: "white", background: "#22c55e" }}
              label="Yes"
              icon="pi pi-check"
              className="p-button-text"
              onClick={onConfirm}
              autoFocus
            />
        </div>
      }
    >
      Are you sure you want to delete this appointment?
    </Dialog>
  );
};

export default ConfirmationDialog;
