import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import MainLayoutSidebar from "./MainLayoutSidebar";
import PropTypes from "prop-types";
import { MdOutlineMenu } from "react-icons/md";
import MobileSidebar from "./MobileSidebar";
import { RiFullscreenExitFill, RiFullscreenFill } from "react-icons/ri";
import MenuProfileView from "../pages/Profile/MenuProfileView";
import { MdNotifications } from "react-icons/md";
import { Tag } from "primereact/tag";
import { InputSwitch } from "primereact/inputswitch";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";
const { Content } = Layout;

const MainLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [roleDataView, setRoleDataView] = useState("");

  useEffect(() => {
    const getUserDetails = () => {
      try {
        const userData = JSON.parse(localStorage.getItem("user"));
        if (userData && userData.success) {
          return {
            success: true,
            data: userData.data,
            role: userData.data.role,
          };
        } else {
          return { success: false };
        }
      } catch (error) {
        console.error(
          "Error retrieving user details from local storage:",
          error
        );
        return { success: false };
      }
    };

    const userRole = localStorage.getItem("roleChange");
    setRoleDataView(userRole);

    const user = getUserDetails();
    setIsAdmin(
      user.success && (user.role === "admin" || user.role === "superAdmin")
    );
  }, []);

  const [sideOpen, setSideOpen] = useState(false);

  const handlesidebar = () => {
    setSideOpen(!sideOpen);
  };

  const handleFullscreen = () => {
    if (!isFullscreen) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  const [checked, setChecked] = useState(false)

  useEffect(() => {
    const value = localStorage.getItem("isChecked");
    const isChecked = value === "true";
    setChecked(isChecked);
  }, []);


  const handleChangeAccount = async (role, path, check) => {
    try {
      const userDataString = localStorage.getItem("user");
      localStorage.setItem("isChecked", check);
      if (userDataString) {
        localStorage.setItem("roleChange", role);
        window.location.href = path;
      } else {
        console.error("User data not found in local storage");
      }
    } catch (err) {
      console.error("Error updating user role:", err.response.data.message);
    }
  };

  const handleChange = (e) => {
    setChecked(e.value);
    const role = e.value ? "admin" : "user";
    const path = e.value ? ROUTE_CONSTANTS.ADMIN_DASHBOARD : ROUTE_CONSTANTS.USER_DASHBOARD;
    handleChangeAccount(role, path, Boolean(e.value));
  };

  return (
    <Layout className="light-theme" style={{ minHeight: "100vh" }}>
      <div className="md:block hidden">
        <MainLayoutSidebar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          fixed
        />
      </div>
      <div className="block md:hidden hello">
        <MobileSidebar sideOpen={sideOpen} setSideOpen={setSideOpen} />
      </div>

      <Layout
        className="site-layout"
        style={{ marginLeft: collapsed ? 80 : 200 }}
      >
        <div className="block md:hidden">
          <div
            className="navbar "
            style={{
              background: "rgb(81, 75, 167)",
              color: "white",
              fontWeight: 700,
              fontSize: "2rem",
              padding: "14px 22px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className="mobile-p">KODUKKU</p>
            <button
              onClick={handlesidebar}
              style={{
                fontSize: "21px",
                padding: "5px 0px",
                width: "37px",
                height: "35px",
                color: "white",
                backgroundColor: "#514ba7",
                border: "none",
              }}
            >
              <MdOutlineMenu />
            </button>
          </div>
        </div>
        <Content
          style={{
            padding: "0px 24px 24px",
            minHeight: 360,
            marginLeft: collapsed ? 0 : 0,
          }}
        >
          <div
            style={{
              gap: "8px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "8px 0px",
            }}
          >
            <div className="hidden sm:flex" style={{ display: "flex", alignItems: "center" }}>
              <button
                onClick={handleFullscreen}
                style={{
                  marginTop: "4px",
                  fontSize: "20px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  color: "inherit",
                }}
              >
                {isFullscreen ? <RiFullscreenExitFill /> : <RiFullscreenFill />}
              </button>
            </div>
            <div style={{ fontSize: "24px", cursor: "pointer" }}>
              <MdNotifications />
            </div>
            {isAdmin && (
              <span className="text-center cursor-pointer hidden sm:block">
                <Tag
                  className="capitalize px-2"
                  icon="pi pi-user"
                  value={roleDataView}
                ></Tag>
              </span>
            )}

            {isAdmin &&
              <InputSwitch
                id="switchControl"
                checked={checked}
                onChange={handleChange}
              />
            }

            <div
              style={{
                padding: "4px 16px",
                borderStartEndRadius: "30px",
                borderStartStartRadius: "30px",
                borderEndEndRadius: "30px",
                borderEndStartRadius: "30px",
                cursor: "pointer",
              }}
              className="avatar-user"
            >
              <MenuProfileView />
            </div>
          </div>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

MainLayout.propTypes = {
  children: PropTypes.element,
};

export default MainLayout;
