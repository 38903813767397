import React, { useRef } from "react";
import moment from "moment";
import { OverlayPanel } from "primereact/overlaypanel";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../../styles/HolidaysCalendar.scss";

const CustomEventOverlay = ({ event }) => {
  const op = useRef(null);

  const handleMouseEnter = (e) => {
    op.current.show(e);
  };

  const handleMouseLeave = () => {
    op.current.hide();
  };

  return (
    <div>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="custom-event"
      >
        {event.title}
      </div>
      <OverlayPanel ref={op} style={{ width: "200px", height: "auto" }}>
        <div className="custom-tooltip-content">
          <div className="mt-1"><strong>Title: </strong> {event.title}</div>
          <div className="mt-1"><strong>Date: </strong> {moment(event.start).format("MMMM Do YYYY")}</div>
          <div className="mt-1"><strong>Type: </strong> {event.holiday_type}</div>
          <hr></hr>
          <div><strong>Description:</strong></div>
          <div>&nbsp;&nbsp;{event.holiday_details}</div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default CustomEventOverlay;
