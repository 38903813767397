import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import axios from "axios";
import { ASSETS_IMAGES_API, ATTENDANCE_API, HOLIDAYS_API } from "../../constants/api";
import { Badge } from "primereact/badge";
import moment from "moment/moment";
import Webcam from 'react-webcam'
import { Image } from "primereact/image";
import deviceInfo from "../../utills/deviceInfo";

export default function EmpAttendance() {
  const toastRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const [editData, setEditData] = useState({
    currentDate: "",
    currentTime: "",
    status: "",
  });
  const [statusError, setStatusError] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const [attendanceData, setAttendanceData] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [daysCount, setDaysCount] = useState({
    absentCount: 0,
    presentCount: 0,
    sundayCount: 0,
    governmentHolidayCount: 0,
    workingCount: 0,
    optionalLeaveCount: 0,
  });
  const [clickCamera, setClickCamera] = useState(false)

  const getCurrentDate = new Date();
  const currentMonthSelected = getCurrentDate.toISOString().slice(0, 7);
  const [searchDate, setSearchDate] = useState(currentMonthSelected);

  const [attendanceStatus, setAttendanceStatus] = useState("Present");
  const [webCamView, setWebCamView] = useState(false);
  const webcamRef = useRef(null);

  const attendanceOptions = [
    { label: "Present", value: "Present" },
    { label: "On The Way", value: "On The Way" },
    { label: "Day Off", value: "Day Off" },
    { label: "Absent", value: "Absent" },
    { label: "Permissions", value: "Permissions" },
    { label: "Comp Off", value: "Comp Off" },
    { label: "Sick Leave", value: "Sick Leave" },
    { label: "Optional Leave", value: "Optional Leave" },
  ];


  const [userAttendance, setUserAttendance] = useState([])
  const [count, setCount] = useState({
    presentCount: 0,
    absentCount: 0,
    weekOffCount: 0,
    optionalLeave: 0,
    holidayCount: 0,
    workingDaysCount: 0,
    overTimeCount: 0
  })

  const fetchDataMonth = async () => {
    const userDataString = localStorage.getItem("user");

    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const userid = userData.data.id;
      const [year, month] = searchDate.split("-")
      axios
        .get(ATTENDANCE_API.ATTENDANCE_GET_MONTH(userid, month, year))
        .then(({ data }) => {
          setUserAttendance(data.attendance)
          setCount({
            presentCount: data.counts.present,
            absentCount: data.counts.absent,
            weekOffCount: data.counts.weekoff,
            optionalLeave: data.counts.optionalLeave,
            holidayCount: data.counts.holiday,
            workingDaysCount: data.counts.workingDays,
            overTimeCount: data.counts.overtime,

          })
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });
    } else {
      console.error("User data not found in local storage");
    }
  }
  useEffect(() => { fetchDataMonth() }, [searchDate])

  const showToast = (message) => {
    toastRef.current.clear();
    if (toastRef && toastRef.current) {
      toastRef.current.show({
        severity: "error",
        summary: "Error Message",
        detail: message,
      });
    }
  };
  const showSuccessToast = (message) => {
    toastRef.current.clear();
    if (toastRef && toastRef.current) {
      toastRef.current.show({
        severity: "success",
        summary: "Success Message",
        detail: message,
      });
    }
  };

  const deviceType = deviceInfo()

  const getCurrentDateTime = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    setCurrentTime(
      `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${period}`
    );
    setCurrentDate(now.toISOString().split("T")[0]);
  };

  useEffect(() => {
    getCurrentDateTime();
    const intervalId = setInterval(getCurrentDateTime, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const openEditModal = (rowData) => {
    getCurrentDateTime();
    setEditData({
      currentDate: rowData.date,
      currentTime,
      status: rowData.status,
    });
    setSelectedRow(rowData);
    setDisplayEditModal(true);
  };
  useEffect(() => {
    getCurrentDateTime();
  }, []);

  const handleConfirm = (rowData) => {
    setClickCamera(true);
    if (rowData.date !== currentDate) {
      showToast("Date expired, check-out action cannot be performed.");
      return;
    }

    if (rowData.check_out) {
      return;
    }
    if (!rowData) {
      console.error("No row selected");
      showToast("No row selected.");
      setClickCamera(false);
      return;
    }

    const { id } = rowData;

    const capturePhoto = () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          const imageSrc = webcamRef.current.getScreenshot({
            quality: 1, width: 1800, height: 1880
          });
          resolve(imageSrc);
        }, 3000);
      });
    };

    capturePhoto().then((imageSrc) => {
      const formData = new FormData();
      formData.append('check_out', currentTime);
      formData.append('check_out_device', deviceType);
      if (imageSrc) {
        // Convert base64 to Blob
        const blob = dataURItoBlob(imageSrc);
        formData.append('image', blob, 'image.jpeg');
      }

      axios.put(ATTENDANCE_API.ATTENDANCE_CHECKOUT(id), formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      })
        .then((response) => {
          fetchAttendanceData();
          fetchDataMonth()
          showSuccessToast(`${response.data.message}`);
        })
        .catch((error) => {
          console.error("Failed to check out:", error.message);
          showToast("Failed to check out.");
        })
        .finally(() => {
          setClickCamera(false);
        });

    }).catch((error) => {
      console.error("Failed to capture photo:", error.message);
      showToast("Failed to capture photo.");
      setClickCamera(false);
    });
  };

  useEffect(() => {
    fetchAttendanceData();
  }, []);

  const fetchAttendanceData = () => {
    const userDataString = localStorage.getItem("user");

    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const userid = userData.data.id;

      axios
        .get(ATTENDANCE_API.ATTENDANCE_GET(userid))
        .then((response) => {
          setProducts(response.data);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });
    } else {
      console.error("User data not found in local storage");
    }
  };

  const onRowEditComplete = (e) => {
    let _products = [...products];
    let { newData, index } = e;

    _products[index] = newData;

    setProducts(_products);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const handleSubmit = async () => {
    setClickCamera(true);

    if (isSubmitting) {
      return;
    }

    if (!attendanceStatus) {
      setStatusError(true);
      setClickCamera(false);
      return;
    } else {
      setStatusError(false);
    }

    setIsSubmitting(true);

    const capturePhoto = () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          const imageSrc = webcamRef.current.getScreenshot({
            quality: 1, width: 1800, height: 1880
          });
          resolve(imageSrc);
        }, 3000);
      });
    };

    try {
      let imageSrc;
      if (attendanceStatus === "Present") {
        imageSrc = await capturePhoto();
      }

      const userDataString = localStorage.getItem("user");
      if (!userDataString) {
        throw new Error("User data not found.");
      }

      const userData = JSON.parse(userDataString);
      const userid = userData.data.id;

      const formData = new FormData();
      formData.append('date', currentDate);
      formData.append('check_in', currentTime);
      formData.append('status', attendanceStatus);
      formData.append('userid', userid);
      formData.append('check_in_device', deviceType);

      if (imageSrc) {
        const blob = dataURItoBlob(imageSrc);
        formData.append('image', blob, 'image.jpeg');
      }

      await axios.post(ATTENDANCE_API.ATTENDANCE_ADD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });

      fetchAttendanceData();
      fetchDataMonth()
      showSuccessToast("Check-in successful");

    } catch (error) {
      console.error("Error:", error.message);
      const errorMessage = error.response?.data?.message || "An error occurred.";
      showToast(errorMessage);
    } finally {
      setIsSubmitting(false);
      setClickCamera(false);
    }
  };

  // Function to convert base64 to Blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleEditSubmit = () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    const { status, currentDate, currentTime } = editData;
    const { id } = selectedRow;

    axios
      .put(ATTENDANCE_API.ATTENDANCE_UPDATE(id), {
        date: currentDate,
        check_in: currentTime,
        status,
        check_in_device: deviceType
      })
      .then((response) => {
        setDisplayEditModal(false);
        showSuccessToast("Attendance updated successfully");
        fetchAttendanceData();
      })
      .catch((error) => {
        console.error("Failed to update attendance record:", error.message);
        const errorMessage =
          error.response && error.response.data
            ? error.response.data.message || "An error occurred."
            : "An error occurred.";
        showToast(errorMessage);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    getHolidaysData();
    getAttendanceData();
  }, []);

  const getAttendanceData = async () => {
    const userDataString = localStorage.getItem("user");

    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const userid = userData.data.id;

      axios
        .get(ATTENDANCE_API.ATTENDANCE_GET(userid))
        .then(({ data }) => {
          setAttendanceData(data);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });
    } else {
      console.error("User data not found in local storage");
    }
  };

  const getHolidaysData = async () => {
    axios
      .get(HOLIDAYS_API.HOLIDAYS_ALL)
      .then(({ data }) => {
        setHolidays(data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  };

  useEffect(() => {
    if (searchDate === "") {
      setDaysCount({
        presentCount: 0,
        sundayCount: 0,
        governmentHolidayCount: 0,
        workingCount: 0,
        absentCount: 0,
        optionalLeaveCount: 0,
      });
      return;
    }

    const selectedDate = new Date(searchDate);
    const selectedMonth = selectedDate.getMonth() + 1;
    const selectedYear = selectedDate.getFullYear();

    const filteredAttendance = attendanceData.filter((data) => {
      const date = new Date(data.date);
      return (
        date.getMonth() + 1 === selectedMonth &&
        date.getFullYear() === selectedYear
      );
    });

    const filteredHoliday = holidays.filter((holiday) => {
      const holidayDate = new Date(holiday.holiday_date);
      return (
        holidayDate.getMonth() + 1 === selectedMonth &&
        holidayDate.getFullYear() === selectedYear
      );
    });

    const firstDayOfMonth = new Date(selectedYear, selectedMonth - 1, 1);
    const lastDayOfMonth = new Date(selectedYear, selectedMonth, 0);
    let sundayCount = 0;
    let governmentHolidayCount = filteredHoliday.filter(
      (holiday) => holiday.holiday_type === "Public Holiday"
    ).length;

    for (
      let d = new Date(firstDayOfMonth);
      d <= lastDayOfMonth;
      d.setDate(d.getDate() + 1)
    ) {
      if (d.getDay() === 0) {
        // Check if it's Sunday
        sundayCount++;
        // Check if it's also a holiday
        const isHoliday = filteredHoliday.some((holiday) => {
          const holidayDate = new Date(holiday.holiday_date);
          return holidayDate.toDateString() === d.toDateString();
        });
        if (isHoliday) {
          governmentHolidayCount--; // Adjust holiday count
        }
      }
    }

    const totalDaysInMonth = lastDayOfMonth.getDate();
    const workingDays = totalDaysInMonth - sundayCount - governmentHolidayCount;

    const presentCount = filteredAttendance.filter((data) => {
      const date = new Date(data.date);
      return date.getDay() !== 0 && data.status === "Present";
    }).length;

    const optionalLeaveCount = filteredAttendance.filter((data) => {
      const date = new Date(data.date);
      return date.getDay() !== 0 && data.status === "Optional Leave";
    }).length;

    setDaysCount({
      presentCount,
      sundayCount,
      governmentHolidayCount,
      workingCount: workingDays,
      absentCount: 0,
      optionalLeaveCount: optionalLeaveCount,
    });

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const isCurrentOrPreviousMonth =
      selectedYear < currentYear ||
      (selectedYear === currentYear && selectedMonth <= currentMonth);
    if (isCurrentOrPreviousMonth) {
      if (selectedMonth === currentMonth && selectedYear === currentYear) {
        const startOfMonth = new Date(selectedYear, selectedMonth - 1, 1);
        const today = new Date();
        let absentCount = 0;

        for (
          let calDate = new Date(startOfMonth);
          calDate <= today;
          calDate.setDate(calDate.getDate() + 1)
        ) {
          if (calDate.getDay() !== 0) {
            const isPresent = filteredAttendance.some((data) => {
              const attendanceDate = new Date(data.date);
              return (
                attendanceDate.toDateString() === calDate.toDateString() &&
                (data.status === "Present" || data.status === "Optional Leave")
              );
            });
            if (!isPresent) {
              absentCount++;
            }
          }
        }

        const currentMonthGovernment = today.getMonth() + 1;

        const holidaysThisMonth = holidays.filter((holiday) => {
          const holidayDate = new Date(holiday.holiday_date);
          const holidayMonth = holidayDate.getMonth() + 1;

          return (
            holidayMonth === currentMonthGovernment && holidayDate <= today
          );
        });

        // Only subtract holidays that do not have a corresponding present attendance
        holidaysThisMonth.forEach((holiday) => {
          const holidayDate = new Date(holiday.holiday_date);
          const isPresentOnHoliday = filteredAttendance.some((data) => {
            const attendanceDate = new Date(data.date);
            return (
              attendanceDate.toDateString() === holidayDate.toDateString() &&
              data.status === "Present"
            );
          });
          if (!isPresentOnHoliday) {
            absentCount--;
          }
        });

        setDaysCount((prevDaysCount) => ({
          ...prevDaysCount,
          absentCount,
        }));
      } else {
        const absentCountFromWorkingDays =
          workingDays - presentCount - optionalLeaveCount;
        setDaysCount((prevDaysCount) => ({
          ...prevDaysCount,
          absentCount: absentCountFromWorkingDays,
        }));
      }
    }
  }, [searchDate, attendanceData, holidays]);

  const handleStatusChangeUpdate = (e) => {
    const value = e.target.value;
    setEditData({ ...editData, status: value });
    setAttendanceStatus(value);
    setStatusError(!value);
  };

  const filteredProducts = products.filter((product) => {
    const formattedDate = moment(product.date).format("YYYY-MM");
    return formattedDate === searchDate;
  });

  const isDataExist = () => {
    return !products.some((data) => {
      return data.date === currentDate;
    });
  };

  useEffect(() => {
    const filterData = products.filter((item) => {
      const filterDate = moment(item.date).format("DD-MM-YYYY");
      const currentDate = moment().format("DD-MM-YYYY");
      return currentDate === filterDate;
    });

    if (filterData.length == 1) {
      setWebCamView(true);
      setAttendanceStatus("");
    }
  }, [products]);

  return (
    <div>
      <div className="card">
        <div className="md:flex block justify-content-between">
          <h2 className="mt-3">Attendance</h2>
          <Toast ref={toastRef} />
          <div className="" style={{ margin: "8px 0" }}>
            <div>
              <label htmlFor="monthPicker">
                <input
                  id="monthPicker"
                  type="month"
                  value={searchDate}
                  onChange={(e) => setSearchDate(e.target.value)}
                  style={{
                    boxShadow: "1px 1px 4px gray",
                    outline: "none",
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                    appearance: "none",
                  }}
                  showButtonBar={false}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="" style={{ margin: "10px 0px" }}>
          <div
            className="permision-card md:flex  block"
            style={{ flexWrap: "wrap", gap: "10px" }}
          >
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={count.presentCount}
                severity="success"
                size="large"
              />
              <div className="permi-text">
                <h3>Present</h3>
              </div>
            </div>
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={count.absentCount}
                severity="danger"
                size="large"
              />
              <div className="permi-text">
                <h3>Absent</h3>
              </div>
            </div>
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={count.overTimeCount}
                className="bg-purple-500"
                size="large"
              />
              <div className="permi-text">
                <h3>Overtime</h3>
              </div>
            </div>
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={count.optionalLeave}
                severity="secondary"
                size="large"
              />
              <div className="permi-text">
                <h3>Optional Leave</h3>
              </div>
            </div>
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={count.weekOffCount}
                severity="warning"
                size="large"
              />
              <div className="permi-text">
                <h3>Weekoff</h3>
              </div>
            </div>
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={count.holidayCount}
                severity="info"
                size="large"
              />
              <div className="permi-text">
                <h3>Holidays</h3>
              </div>
            </div>

            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge value={count.workingDaysCount} size="large" />
              <div className="permi-text">
                <h3>Working days</h3>
              </div>
            </div>
          </div>
        </div>

        {isDataExist() && (
          <div className="flex align-items-center flex-wrap gap-3 my-3">
            <div className="flex-1 flex-shrink-0">
              <label htmlFor="currentDate">Date</label>
              <input
                id="currentDate"
                className="outline-none cursor-pointer"
                value={currentDate}
                readOnly
              />
            </div>

            <div className="flex-1 flex-shrink-0">
              <label htmlFor="currentTime">Time</label>
              <input
                type="text"
                id="currentTime"
                className="outline-none cursor-pointer "
                value={currentTime}
                readOnly
              />
            </div>

            <div className="">
              <label htmlFor="attendanceStatus">Status</label>
              <div className="flex flex-wrap gap-2 lg:gap-3">
                {attendanceOptions.map((option) => (
                  <div key={option.value} className="flex align-items-center">
                    <input
                      type="radio"
                      id={option.value}
                      name="attendanceStatus"
                      value={option.value}
                      checked={attendanceStatus === option.value}
                      onChange={(e) => {
                        setAttendanceStatus(e.target.value);
                        setStatusError(false);
                      }}
                      className={`${statusError ? "p-invalid" : ""} p-0 w-auto`}
                    />
                    <label
                      htmlFor={option.value}
                      style={{ whiteSpace: "nowrap" }}
                      className=" font-normal mt-1"
                    >
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
              {statusError && (
                <small className="p-error ml-2">Status is required.</small>
              )}
            </div>
            {attendanceStatus === "Present" && !webCamView && (
              <div className="">
                <label htmlFor="camera">Camera</label>
                <div
                  style={{
                    width: !clickCamera && "50px",
                    height: !clickCamera && "50px",
                  }}
                >
                  {clickCamera &&
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      imageSmoothing={true}
                      screenshotQuality={1}
                      videoConstraints={{ facingMode: "user" }}
                      disablePictureInPicture={false}
                      style={{ width: "50px", height: "50px" }}
                    />
                  }
                </div>
              </div>
            )}
            <div className="align-self-end">
              <Button
                label="Check In"
                icon="fi fi-rr-tap"
                className="p-button p-2"
                onClick={handleSubmit}
              />
            </div>
          </div>
        )}

        <DataTable
          stripedRows
          value={userAttendance}
          editMode="row"
          dataKey="id"
          selectionMode="single"
          selection={selectedRow}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          onRowEditComplete={onRowEditComplete}
          scrollable
          scrollHeight="600px"
          className="mt-3"
          paginator rows={10} rowsPerPageOptions={[10, 25, 50]}
          globalFilter={searchDate}
          emptyMessage="No records found"
        >
          <Column
            field="date"
            header="Date"
            style={{ minWidth: "105px" }}
            className="font-bold"
            headerStyle={{
              borderTopLeftRadius: "14px",
              borderBottomLeftRadius: "14px",
            }}
            body={(rowData) => moment(rowData.date).format("DD-MM-yyyy")}
          ></Column>
          <Column
            field="check_in"
            header="Check-In"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "120px" }}
          ></Column>
          <Column
            field="check_out"
            header="Check-Out"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "120px" }}
            body={(rowData) => (rowData.check_out ? rowData.check_out : "-")}
          ></Column>
          {/* <Column
            field="checkin"
            header="Break-In"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "100px" }}
          ></Column>
          <Column
            field="checkout"
            header="Break-Out"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "100px" }}
          ></Column> */}
          {/* <Column
            field="checkin"
            header="Overtime-In"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "200px" }}
          ></Column>
          <Column
            field="checkout"
            header="Overtime-Out"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "200px" }}
          ></Column> */}
          <Column
            field="workinghours"
            header="Working Hours"
            style={{ minWidth: "200px" }}
            body={(rowData) =>
              rowData.workinghours ? rowData.workinghours : "-"
            }
          ></Column>
          {/* <Column
            field="workinghours"
            header="Break Time"
            style={{ minWidth: "150px" }}
          ></Column> */}
          {/* <Column
            field="shift"
            header="Shift"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "200px" }}
          ></Column> */}
          <Column
            field="status"
            header="Status"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "100px" }}
          ></Column>
          <Column
            header="CII"
            body={(rowData) => {
              return rowData.check_in_img !== null ?
                <>
                  <Image src={`${ASSETS_IMAGES_API.ASSETS_IMAGES}${rowData.check_in_img}`} alt="EmployeeImage" width="50" height="50" preview />
                </>
                : "-"
            }}
            style={{ width: "50px", textAlign: "center" }}
          />
          <Column
            header="COI"
            body={(rowData) => {
              return rowData.check_out_img == null && rowData.check_out == null && rowData.date === currentDate && rowData.status === "Present" ?
                <div>
                  {clickCamera &&
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      imageSmoothing={true}
                      screenshotQuality={1}
                      videoConstraints={{ facingMode: "user" }}
                      disablePictureInPicture={false}
                      style={{ width: '50px', height: '50px' }}
                    />
                  }
                </div> : rowData.check_out_img !== null ?
                  <Image src={`${ASSETS_IMAGES_API.ASSETS_IMAGES}${rowData.check_out_img}`} alt="EmployeeImage" width="50" height="50" preview />

                  : "-"
            }}
            style={{ width: "70px", textAlign: "center" }}
          />
          <Column
            header="Actions"
            body={(rowData) => (
              <div>
                {rowData.date === currentDate && !rowData.check_out ? (
                  <Button
                    icon="fi fi-bs-pencil"
                    className="p-button-rounded p-button-text"
                    onClick={() => openEditModal(rowData)}
                  />
                ) : (
                  <Button
                    icon="fi fi-bs-pencil"
                    tooltip="Editing Restricted"
                    tooltipOptions={{
                      position: "bottom",
                      mouseTrack: true,
                      mouseTrackTop: 15,
                    }}
                    className="p-button-rounded p-button-text"
                  />
                )}

                {rowData.check_out ? (
                  <Button
                    tooltip="Checked Out"
                    tooltipOptions={{
                      position: "bottom",
                      mouseTrack: true,
                      mouseTrackTop: 15,
                    }}
                    icon="fi fi-rs-log-out"
                    className="p-button-rounded p-button-text"
                    style={{ color: "red" }}
                  />
                ) : (
                  <Button
                    icon="fi fi-rs-log-out"
                    className="p-button-rounded p-button-text"
                    onClick={() => handleConfirm(rowData)}
                    style={{ color: "green" }}
                  />
                )}
              </div>
            )}
            style={{ minWidth: "150px" }}
          />
        </DataTable>

        <Dialog
          header="Edit Attendance"
          visible={displayEditModal}
          onHide={() => setDisplayEditModal(false)}
          style={{ width: "55vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div className="field">
            <label htmlFor="currentDate">Date</label>
            <InputText
              id="currentDate"
              value={editData.currentDate}
              onChange={(e) =>
                setEditData({ ...editData, currentDate: e.target.value })
              }
              disabled
            />
          </div>
          <div className="field">
            <label htmlFor="currentTime">Time</label>
            <InputText
              id="currentTime"
              value={editData.currentTime}
              onChange={(e) =>
                setEditData({ ...editData, currentTime: e.target.value })
              }
              disabled
            />
          </div>
          <div className="p-field ">
            <label htmlFor="attendanceStatus">Status</label>

            <div className="grid">
              {attendanceOptions.map((option) => (
                <div
                  key={option.value}
                  className="flex col-12 sm:col-4 md:col-3 align-items-center"
                >
                  <input
                    type="radio"
                    id={`attendanceStatus_${option.value}`}
                    name="attendanceStatus"
                    value={option.value}
                    checked={editData.status === option.value}
                    onChange={handleStatusChangeUpdate}
                    className={statusError ? "p-invalid w-auto" : "w-auto"}
                  />
                  <label
                    className="font-normal "
                    style={{ whiteSpace: "nowrap" }}
                    htmlFor={`attendanceStatus_${option.value}`}
                  >
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
            {statusError && (
              <small className="p-error ml-2">Status is required.</small>
            )}
          </div>
          <div className="flex justify-content-end mt-4">
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => setDisplayEditModal(false)}
            />
            <Button
              label="Submit"
              icon="fi fi-bs-check"
              className="ml-3"
              onClick={handleEditSubmit}
              disabled={isSubmitting}
            />
          </div>
        </Dialog>
      </div>
    </div>
  );
}
