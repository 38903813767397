import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import axios from "axios";
import { HOLIDAYS_API } from "../../constants/api";
import CustomEventOverlay from "./CustomEventTooltip";
import "../../styles/HolidaysCalendar.scss";

const localizer = momentLocalizer(moment);

const CalendarView = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    try {
      const { data } = await axios.get(HOLIDAYS_API.HOLIDAYS_ALL);
      setEvents(data);
    } catch (error) {
      console.log(error);
    }
  };

  const formattedEvents = events?.map((item) => ({
    ...item,
    title: item.holiday_name,
    start: new Date(item.holiday_date),
    end: new Date(item.holiday_date),
    holiday_type: item.holiday_type,
    holiday_details: item.holiday_details,
  }));

  const eventStyleGetter = (event) => {
    return {
      style: {
        backgroundColor: "#3174ad",
        borderRadius: "5px",
        opacity: 0.8,
        color: "white",
        border: "0px",
        display: "block",
      },
    };
  };

  return (
    <div className="mt-2">
      <div className="holiday-cal">
        <Calendar
          localizer={localizer}
          events={formattedEvents}
          style={{ minHeight: 600 }}
          toolbar={{}}
          defaultView="month"
          views={["month"]}
          eventPropGetter={eventStyleGetter}
          components={{
            event: CustomEventOverlay,
          }}
          titleAccessor={() => ""}
        />
      </div>
    </div>
  );
};

export default CalendarView;
