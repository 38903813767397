import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { EMPLOYEE_API, TASK_API } from "../../../../constants/api";
import axios from "axios";
import { Tooltip } from "primereact/tooltip";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { setFirst, setPageRows, setStatusSelector } from "../../../../service/paginator";
import { Dropdown } from "primereact/dropdown";

export default function Task({ userId }) {
  const [products, setProducts] = useState([]);
  const [employeesData, setEmployeesData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const dispatch = useDispatch();
  const first = useSelector((state) => state.paginator.pages.taskEmp.first);
  const rowsPerPage = useSelector((state) => state.paginator.pages.taskEmp.rowsPerPage);
  const [localFirst, setLocalFirst] = useState(first || 0);
  const [localRowsPerPage, setLocalRowsPerPage] = useState(rowsPerPage || 10);

  const onPageChange = (e) => {
    setLocalFirst(e.first);
    dispatch(setFirst({ page: 'taskEmp', value: e.first }));
  };

  const onPageRowsChange = (event) => {
    setLocalRowsPerPage(event.rows);
    dispatch(setPageRows({ page: 'taskEmp', value: event.rows }));
  };

  const rowsPerPageOptions = [10, 25, 50];

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setLocalRowsPerPage(newRowsPerPage);
    dispatch(setPageRows({ page: 'taskEmp', value: newRowsPerPage }))
  };

  const statusSelector = useSelector(state => state.paginator.pages.taskEmp.statusSelector)

  useEffect(() => {
    const fetchData = async () => {

      const response = await axios.get(TASK_API.GET_USER_TASK(userId));

      const filterData = response.data.filter(item => item.approval_status === "Approved")
      setProducts(filterData);
    };
    fetchData();
  }, []);

  const fetchApprovers = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.All_USERS);
      const { usersData } = response.data;
      setEmployeesData(usersData)
    } catch (error) {
      console.error("Error fetching approvers:", error);
    }
  };

  useEffect(() => {
    fetchApprovers();
  }, []);

  const filteredProducts =
    statusSelector === "All"
      ? products
      : products.filter((product) => product.Status === statusSelector);

  const getPriorityClassName = (priority) => {
    switch (priority) {
      case "High":
        return "high-priority";
      case "Medium":
        return "medium-priority";
      case "Low":
        return "low-priority";
      default:
        return "";
    }
  };

  return (
    <div>
      <div className="flex justify-content-end mb-2">
        <div className="w-15rem">
          <div className="p-inputgroup">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="md:flex block justify-content-between mb-2">
          <div className="md:flex block filter-buttons md:mt-0 mb-2 flex-wrap gap-2">
            <Button
              className={`filter-button1 md:mt-0 mt-3 ${selectedStatus === "All" ? "active" : ""
                }`}
              onClick={() => {
                setSelectedStatus("All")
                dispatch(setStatusSelector({ page: 'taskEmp', value: "All" }));
              }}
            >
              All
            </Button>
            <Button
              className={`filter-button2 md:mt-0 mt-3 ${selectedStatus === "Initialized" ? "active" : ""
                }`}
              onClick={() => {
                setSelectedStatus("Initialized")
                dispatch(setStatusSelector({ page: 'taskEmp', value: "Initialized" }));
              }}
            >
              Initialized
            </Button>
            <Button
              className={`filter-button3 md:mt-0 mt-3 ${selectedStatus === "In Progress" ? "active" : ""
                }`}
              onClick={() => {
                setSelectedStatus("In Progress")
                dispatch(setStatusSelector({ page: 'taskEmp', value: "In Progress" }));
              }}
            >
              In Progress
            </Button>
            <Button
              className={`filter-button5 md:mt-0 mt-3 ${selectedStatus === "Returned" ? "active" : ""
                }`}
              onClick={() => {
                setSelectedStatus("Returned")
                dispatch(setStatusSelector({ page: 'taskEmp', value: "Returned" }));
              }}
            >
              Returned
            </Button>
            <Button
              className={`filter-button4 md:mt-0 mt-3 ${selectedStatus === "Resolved" ? "active" : ""
                }`}
              onClick={() => {
                setSelectedStatus("Resolved")
                dispatch(setStatusSelector({ page: 'taskEmp', value: "Resolved" }));
              }}
            >
              Resolved
            </Button>
            <Button
              className={`filter-button6 md:mt-0 mt-3 ${selectedStatus === "Completed" ? "active" : ""
                }`}
              onClick={() => {
                setSelectedStatus("Completed")
                dispatch(setStatusSelector({ page: 'taskEmp', value: "Completed" }));
              }}
            >
              Completed
            </Button>
          </div>
        </div>
        <DataTable
          value={filteredProducts}
          paginator
          tableStyle={{ minWidth: "50rem" }}
          globalFilter={globalFilter.trim() ? globalFilter : null}
          className="dataTableHiddenFooterBg"
          rows={localRowsPerPage}
          onPage={onPageChange}
          onPageRows={onPageRowsChange}
          first={localFirst}
          footer={
            <Dropdown
              value={localRowsPerPage}
              options={rowsPerPageOptions.map(option => ({ label: option, value: option }))}
              onChange={handleRowsPerPageChange}
              placeholder="Select Rows Per Page"
            />
          }
        >
          <Column
            field="Task_id"
            header="Id"
            bodyStyle={{ width: "70px" }}
          ></Column>
          <Column
            field="task_name"
            header="Name"
            style={{ width: "120px" }}
            body={(rowData) => (
              <>
                {rowData.task_name?.length > 20
                  ? `${rowData?.task_name?.slice(0, 20)}...`
                  : rowData?.task_name}
              </>
            )}
            frozen
          ></Column>
          <Column
            field="Project"
            header="Project"
            bodyStyle={{ width: "95px" }}
          ></Column>
          <Column
            field="Creater"
            header="Maker"
            body={(rowData) => {
              const createrId = rowData.Creater;
              const creater = employeesData.find((emp) => emp.id === createrId);
              const createrName = creater ? `${creater.firstname} ${creater.lastname}` : '';
              return (
                <div
                  id={`Creater_${rowData.Task_id}`}
                  className="tooltip-container"
                >
                  {createrName.length > 20
                    ? `${createrName.slice(0, 20)}...`
                    : createrName}
                  <Tooltip
                    target={`#Creater_${rowData.Task_id}`}
                    position="right"
                  >
                    {createrName}
                  </Tooltip>
                </div>
              );
            }}
          />
          <Column
            field="Approver"
            header="Approver"
            body={(rowData) => {
              const approverId = rowData.Approver;
              const approver = employeesData.find((emp) => emp.id === approverId);
              const approverName = approver ? `${approver.firstname} ${approver.lastname}` : '';

              return (
                <div
                  id={`Approver_${rowData.Task_id}`}
                  className="tooltip-container"
                >
                  {approverName.length > 20
                    ? `${approverName.slice(0, 20)}...`
                    : approverName}
                  <Tooltip
                    target={`#Approver_${rowData.Task_id}`}
                    position="right"
                  >
                    {approverName}
                  </Tooltip>
                </div>
              );
            }}
          />
          <Column
            field="Processer"
            header="Processer"
            body={(rowData) => {
              const teamMemberIds = rowData.Processer.split(",");
              const processer = teamMemberIds
                .map((id) => employeesData.find((emp) => emp.id === id.trim()))
                .filter((emp) => emp !== undefined)
                .map((emp) => `${emp.firstname} ${emp.lastname}`)
                .join(", ");
              return (
                <div
                  id={`Processer_${rowData.Task_id}`}
                  className="tooltip-container"
                >
                  {processer?.length > 10
                    ? `${processer.slice(0, 10)}...`
                    : processer}
                  <Tooltip
                    target={`#Processer_${rowData.Task_id}`}
                    position="right"
                  >
                    {processer}
                  </Tooltip>
                </div>
              );
            }}
            style={{
              width: "180px",
              textTransform: "capitalize",
            }}
          ></Column>
          <Column
            field="Priority"
            header="Priority"
            bodyStyle={{ width: "100px" }}
            body={(rowData) => {
              const priorityClassName = getPriorityClassName(rowData.Priority);
              return (
                <div className={priorityClassName}>{rowData.Priority}</div>
              );
            }}
          ></Column>
          <Column
            field="Details"
            header="Details"
            style={{
              width: "150px",
              textTransform: "capitalize",
            }}
            body={(rowData) => (
              <div
                id={`Details_${rowData.Task_id}`}
                className="tooltip-container"
              >
                {rowData.Details.length > 20
                  ? `${rowData.Details.slice(0, 20)}...`
                  : rowData.Details}
                <Tooltip
                  target={`#Details_${rowData.Task_id}`}
                  position="right"
                >
                  {rowData.Details}
                </Tooltip>
              </div>
            )}
          ></Column>
          <Column
            field="StartDate"
            header="Started"
            style={{ minWidth: "105px" }}
            body={(rowData) => moment(rowData.StartDate).format("DD-MM-YYYY")}
          ></Column>
          <Column
            field="DueDate"
            header="Due"
            style={{ minWidth: "105px" }}
            body={(rowData) => moment(rowData.DueDate).format("DD-MM-YYYY")}
          ></Column>
          <Column
            field="Status" className="cursor-pointer"
            header="Status"
            style={{ width: "110px" }}
            body={(rowData) => (
              <span
                className={rowData.Status === "Completed" ? "green-text" : ""}
              >
                {rowData.Status}
              </span>
            )}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
}