import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import axios from "axios";
import { APPOINTMENTS_API, EMPLOYEE_API } from "../../constants/api";
import Select from "react-select";

const RescheduleDialog = ({ visible, onHide, appointmentData, reFetch }) => {
  const [formCompleted, setFormCompleted] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState({
    appointment_title: "",
    appointment_time: "",
    appointment_description: "",
    appointment_date: "",
    employee_ids: [],
  });
  const [employees, setEmployees] = useState([]);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.All_USERS);
      const { usersData } = response.data;
      setEmployees(usersData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  useEffect(() => {
    if (appointmentData) {
      setAppointmentDetails({
        appointment_title: appointmentData.appointment_title || "",
        appointment_time: appointmentData.appointment_time || "",
        appointment_description: appointmentData.appointment_description || "",
        appointment_date: appointmentData.appointment_date || "",
        employee_ids: appointmentData.employee_ids ? appointmentData.employee_ids.split(",") : [],
      });
    }
  }, [appointmentData]);

  const updateAppointment = async () => {
    try {
      const updatedDetails = {
        appointment_date: appointmentDetails.appointment_date,
        appointment_time: appointmentDetails.appointment_time,
        status: "Rescheduled",
      };
      await axios.put(APPOINTMENTS_API.APPOINTMENTS_RESHEDULE(appointmentData.appointment_id), updatedDetails);
      reFetch();
    } catch (error) {
      console.error("Error updating appointment:", error);
    }
  };

  useEffect(() => {
    const isFormCompleted =
      appointmentDetails.appointment_date &&
      appointmentDetails.appointment_time;
    setFormCompleted(isFormCompleted);
  }, [appointmentDetails]);

  const today = new Date().toISOString().split("T")[0];
  return (
    <Dialog
      header="Reschedule Appointment"
      visible={visible}
      style={{ width: "50vw" }}
      breakpoints={{ '960px': '75vw', '641px': '100vw' }}
      onHide={onHide}
      footer={
        <div>
          <Button label="Cancel" icon="pi pi-times" onClick={onHide} 
            style={{ color: "white", background: "red", border: "red" }}
            />
          <Button
            label="Reschedule"
            icon="fi fi-ss-calendar-clock"
            onClick={() => {
              updateAppointment();
              onHide();
            }}
            disabled={!formCompleted}
          />
        </div>
      }
    >
      <div className="p-grid p-fluid">
        <div className="p-col-4">
          <label htmlFor="appointment_title">Appointment Title</label>
          <InputText
            id="appointment_title"
            value={appointmentDetails.appointment_title}
            disabled
          />
        </div>
        <div className="p-col-12">
          <label htmlFor="description">Description</label>
          <InputTextarea
            id="description"
            value={appointmentDetails.appointment_description}
            disabled
          />
        </div>
        {employees.length > 0 && (
          <div className="p-col-12">
            <label htmlFor="employees">Participants</label>
            <Select
              value={(appointmentDetails.employee_ids || []).map((empId) => ({
                label: `${
                  employees.find((emp) => emp.id === empId)?.firstname ?? ""
                } ${employees.find((emp) => emp.id === empId)?.lastname ?? ""}`,
                value: empId,
              }))}
              isDisabled
              options={employees.map((emp) => ({
                label: `${emp.firstname} ${emp.lastname}`,
                value: emp.id,
              }))}
              isMulti
              isClearable
              placeholder="Select team members"
              getOptionLabel={(option) => (
                <div className="flex align-items-center">{option.label}</div>
              )}
            />
          </div>
        )}
        <div className="grid">
          <div className="col-5">
            <label htmlFor="appointment_date">Appointment Date</label>
            <InputText
              id="appointment_date"
              value={appointmentDetails.appointment_date}
              onChange={(e) =>
                setAppointmentDetails({
                  ...appointmentDetails,
                  appointment_date: e.target.value,
                })
              }
              type="date"
              min={today}
            />
          </div>
          <div className="col-3">
            <label htmlFor="time">Time</label>
            <InputText
              id="time"
              value={appointmentDetails.appointment_time}
              onChange={(e) =>
                setAppointmentDetails({
                  ...appointmentDetails,
                  appointment_time: e.target.value,
                })
              }
              type="time"
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default RescheduleDialog;
