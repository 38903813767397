import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { DEPARTMENT_API, EMPLOYEE_API } from "../../constants/api";
import { Tooltip } from "primereact/tooltip";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";

function Department() {
  const [departmentData, setDepartmentData] = useState({
    departmentName: "",
    subDepartmentName: "",
    branch: "",
    departmentHead: "",
    contactNumber: "",
    address: "",
    description: "",
  });
  const [editDepartmentData, setEditDepartmentData] = useState({
    departmentName: "",
    subDepartmentName: "",
    branch: "",
    departmentHead: "",
    contactNumber: "",
    address: "",
    description: "",
  });
  const [departments, setDepartments] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [selectedDepartmentForDelete, setSelectedDepartmentForDelete] =
    useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [visible, setVisible] = useState(false);
  const [displayAddModal, setDisplayAddModal] = useState(false);

  const toast = useRef(null);

  const showToast = (severity, detail) => {
    if (toast.current) {
      toast.current.clear();
      toast.current.show({ severity, detail, life: 3000 });
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);
  const fetchEmployees = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.GET_ALL);
      if (response.status === 200) {
        const employeesData = response.data.usersData;
        setAllEmployees(employeesData);
      }
    } catch (error) {
      console.error("Error fetching employees:", error.message);
    }
  };

  const employeeOptions = allEmployees.map((employee) => ({
    label: `${employee.firstname} ${employee.lastname}`,
    value: employee.empid,
  }));

  const getAllDepartment = async () => {
    try {
      const response = await axios.get(DEPARTMENT_API.DEPARTMENT_FETCH);
      setDepartments(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  useEffect(() => {
    getAllDepartment();
  }, []);
  const capitalizeFirstLetter = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "departmentName" ||
      name === "subDepartmentName" ||
      name === "branch" ||
      name === "departmentHead" ||
      name === "address" ||
      name === "description"
    ) {
      setDepartmentData((prevData) => ({
        ...prevData,
        [name]: capitalizeFirstLetter(value),
      }));
    } else {
      setDepartmentData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleAddDepartment = async () => {
    try {
      if (
        departmentData.departmentName &&
        departmentData.subDepartmentName &&
        departmentData.branch &&
        departmentData.departmentHead &&
        departmentData.contactNumber &&
        departmentData.address &&
        departmentData.description
      ) {
        const newDepartment = {
          department_name: departmentData.departmentName,
          sub_department_name: departmentData.subDepartmentName,
          department_branch: departmentData.branch,
          department_head: departmentData.departmentHead,
          department_contact_no: departmentData.contactNumber,
          department_address: departmentData.address,
          department_description: departmentData.description,
        };

        await axios.post(DEPARTMENT_API.DEPARTMENT_ADD, newDepartment);

        setDepartments([...departments, newDepartment]);
        getAllDepartment();
        setDisplayAddModal(false);
        setDepartmentData({
          departmentName: "",
          subDepartmentName: "",
          branch: "",
          departmentHead: "",
          contactNumber: "",
          address: "",
          description: "",
        });
      } else {
        alert("Please fill in all fields before adding.");
      }
    } catch (error) {
      console.error("Error adding department:", error);
    }
  };

  const handleEditDepartment = (rowData) => {
    setSelectedDepartment(rowData);
    setEditDepartmentData({
      id: rowData.department_id,
      departmentName: rowData.department_name,
      subDepartmentName: rowData.sub_department_name,
      branch: rowData.department_branch,
      departmentHead: rowData.department_head,
      contactNumber: rowData.department_contact_no,
      address: rowData.department_address,
      description: rowData.department_description,
    });
    setVisible(true);
  };

  const handleUpdateDepartment = async () => {
    try {
      if (
        editDepartmentData.departmentName &&
        editDepartmentData.subDepartmentName &&
        editDepartmentData.branch &&
        editDepartmentData.departmentHead &&
        editDepartmentData.contactNumber &&
        editDepartmentData.address &&
        editDepartmentData.description
      ) {
        const updatedDepartment = {
          department_name: editDepartmentData.departmentName,
          sub_department_name: editDepartmentData.subDepartmentName,
          department_branch: editDepartmentData.branch,
          department_head: editDepartmentData.departmentHead,
          department_contact_no: editDepartmentData.contactNumber,
          department_address: editDepartmentData.address,
          department_description: editDepartmentData.description,
        };

        await axios.put(
          DEPARTMENT_API.DEPARTMENT_UPDATE(editDepartmentData.id),
          updatedDepartment
        );
        const updatedDepartments = departments.map((dept) =>
          dept.department_id === editDepartmentData.id
            ? { ...dept, ...updatedDepartment }
            : dept
        );
        setDepartments(updatedDepartments);
        getAllDepartment();
        setEditDepartmentData({
          departmentName: "",
          subDepartmentName: "",
          branch: "",
          departmentHead: "",
          contactNumber: "",
          address: "",
          description: "",
        });

        setVisible(false);
      } else {
        alert("Please fill in all fields before updating.");
      }
    } catch (error) {
      console.error("Error updating department:", error);
    }
  };

  const handleDeleteDepartment = (rowData) => {
    setSelectedDepartmentForDelete(rowData);
    setDisplayConfirmation(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmation(false);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(
        DEPARTMENT_API.DEPARTMENT_DELETE(
          selectedDepartmentForDelete.department_id
        )
      );
      const updatedDepartments = departments.filter(
        (dept) =>
          dept.department_id !== selectedDepartmentForDelete.department_id
      );
      setDepartments(updatedDepartments);
      showToast("success", "Department deleted successfully");
      setDisplayConfirmation(false);
    } catch (error) {
      console.error("Error deleting department:", error);
      showToast("error", "Error deleteing Department");
    }
  };

  const BodyTemplate = (rowData) => {
    return (
      <div className=" flex">
        <Button
          tooltip="Edit"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-pencil"
          className="p-button-rounded bg-blue-500 "
          onClick={() => handleEditDepartment(rowData)}
        ></Button>
        <Button
          tooltip="Delete"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-trash"
          className="mx-2 p-button-rounded p-button-danger"
          onClick={() => handleDeleteDepartment(rowData)}
        ></Button>
      </div>
    );
  };

  const openAddModal = () => {
    setDisplayAddModal(true);
  };

  return (
    <div>
      <div className="md:flex block justify-content-between">
        <div className="ml-2">
          <h2>Departments</h2>
        </div>
        <div className="md:col-3 col-12">
          <div className="p-inputgroup">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div className="department card">
        <div className=" justify-content-between mb-2 sm:flex block">
          <div>
            <h3 className="mb-2 mt-4">
              Total Department: {departments.length}
            </h3>
          </div>
          <div className="flex sm:justify-content-end mx-2 justify-content-start">
            <Button
              icon="fi fi-bs-plus"
              className="add-button"
              onClick={openAddModal}
            >
              Add
            </Button>
          </div>
        </div>
        <DataTable
          value={departments}
          tableStyle={{ minWidth: "50rem" }}
          selectionMode="single"
          selection={selectedDepartment}
          onSelectionChange={(e) => setSelectedDepartment(e.value)}
          globalFilter={globalFilter.trim() ? globalFilter : null}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          removableSort
        >
          <Column
            field="department_name"
            header="Department Name"
            style={{
              width: "170px",
              textTransform: "capitalize",
              
            }}
            sortable
          ></Column>
          <Column
            field="sub_department_name"
            header="Team Name"
            style={{
              width: "120px",
              textTransform: "capitalize",
              
            }}
            sortable
          ></Column>
          <Column
            field="department_branch"
            header="Branch"
            style={{
              width: "140px",
              textTransform: "capitalize",
              
            }}
            sortable
          ></Column>
          <Column
            field="department_head"
            header="Department Head"
            body={(rowData) => {
              const headEmployee = allEmployees.find(
                (employee) => employee.empid === rowData.department_head
              );
              if (headEmployee) {
                return `${headEmployee.firstname} ${headEmployee.lastname}`;
              } else {
                return "N/A";
              }
            }}
            style={{
              width: "160px",
              textTransform: "capitalize",
           
            }}
            sortable
          ></Column>

          <Column
            field="department_contact_no"
            header="Contact Number"
            style={{
              width: "150px",
              textTransform: "capitalize",
              
            }}
          ></Column>
          <Column
            field="department_address"
            header="Address"
            style={{
              width: "150px",
              textTransform: "capitalize",
            
            }}
          ></Column>
          <Column
            field="department_description"
            header="Description"
            body={(rowData) => (
              <div
                id={`reason_${rowData.department_id}`}
                className="tooltip-container"
              >
                {rowData.department_description.length > 20
                  ? `${rowData.department_description.slice(0, 20)}...`
                  : rowData.department_description}
                <Tooltip
                  target={`#reason_${rowData.department_id}`}
                  position="left"
                  style={{ width: "300px" }}
                >
                  {rowData.department_description}
                </Tooltip>
              </div>
            )}
          ></Column>
          <Column header="Actions" body={BodyTemplate}></Column>
        </DataTable>
        {/* edit model */}
        <Dialog
          showHeader={false}
          visible={visible}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={() => setVisible(false)}
          className="taskdetail"
        >
          <h3 className="mb-4">Edit Department</h3>
          <div className="grid">
            <div className="col-12">
              <label htmlFor="username">Department Name</label>
              <InputText
                id="EditDepartment"
                keyfilter={/^[a-zA-Z\s]*$/}
                value={editDepartmentData.departmentName}
                onChange={(e) =>
                  setEditDepartmentData({
                    ...editDepartmentData,
                    departmentName: e.target.value,
                  })
                }
              />
            </div>
            <div className="md:col-6 col-12">
              <label htmlFor="username">Team Name</label>
              <InputText
                id="EditDepartment"
                keyfilter={/^[a-zA-Z\s]*$/}
                value={editDepartmentData.subDepartmentName}
                onChange={(e) =>
                  setEditDepartmentData({
                    ...editDepartmentData,
                    subDepartmentName: e.target.value,
                  })
                }
              />
            </div>
            <div className="md:col-6 col-12">
              <label htmlFor="username">Branch</label>
              <InputText
                id="EditBranch"
                keyfilter={/^[a-zA-Z\s]*$/}
                value={editDepartmentData.branch}
                onChange={(e) =>
                  setEditDepartmentData({
                    ...editDepartmentData,
                    branch: e.target.value,
                  })
                }
              />
            </div>
            <div className="md:col-6 col-12">
              <label htmlFor="username">Department Head</label>
              <Dropdown
                className="w-full"
                id="EditHead"
                optionLabel="firstname"
                optionValue="empid"
                value={editDepartmentData.departmentHead}
                options={allEmployees}
                onChange={(e) =>
                  setEditDepartmentData({
                    ...editDepartmentData,
                    departmentHead: e.value,
                  })
                }
                filter
                showClear
                placeholder="Select Department Head"
              />
            </div>
            <div className="md:col-6 col-12">
              <label htmlFor="username">Contact Number</label>
              <InputText
                id="EditNumber"
                keyfilter="pnum"
                maxLength={10}
                value={editDepartmentData.contactNumber}
                onChange={(e) =>
                  setEditDepartmentData({
                    ...editDepartmentData,
                    contactNumber: e.target.value,
                  })
                }
              />
            </div>
            <div className="md:col-6 col-12">
              <label htmlFor="username">Address</label>
              <InputTextarea
                id="EditAddress"
                value={editDepartmentData.address}
                onChange={(e) =>
                  setEditDepartmentData({
                    ...editDepartmentData,
                    address: e.target.value,
                  })
                }
              />
            </div>
            <div className="md:col-6 col-12">
              <label htmlFor="username">Description</label>
              <InputTextarea
                id="EditDescription"
                value={editDepartmentData.description}
                onChange={(e) =>
                  setEditDepartmentData({
                    ...editDepartmentData,
                    description: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="flex justify-content-end">
            <Button
              className="p-button-danger"
              onClick={() => setVisible(false)}
              label="Cancel"
            ></Button>
            <Button
              className="mx-2"
              label="Update"
              onClick={handleUpdateDepartment}
            ></Button>
          </div>
        </Dialog>
        {/* add model */}
        <Dialog
          visible={displayAddModal}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={() => setDisplayAddModal(false)}
          header="Add Department"
          position="center"
          draggable={false}
        >
          <div className="grid">
            <div className="md:col-6 col-12">
              <h3 className="my-2">Department Name</h3>
              <InputText
                className="w-full"
                placeholder="Department Name"
                name="departmentName"
                value={departmentData.departmentName}
                onChange={handleInputChange}
                keyfilter={/^[a-zA-Z ]*$/}
              />
            </div>
            <div className="md:col-6 col-12">
              <h3 className="my-2">Sub Department</h3>
              <InputText
                className="w-full"
                placeholder="Sub Department"
                name="subDepartmentName"
                value={departmentData.subDepartmentName}
                onChange={handleInputChange}
                keyfilter={/^[a-zA-Z ]*$/}
              />
            </div>
            <div className="md:col-6 col-12">
              <h3 className="my-2">Branch</h3>
              <InputText
                className="w-full"
                placeholder="Branch"
                name="branch"
                value={departmentData.branch}
                onChange={handleInputChange}
                keyfilter={/^[a-zA-Z ]*$/}
              />
            </div>
            <div className="md:col-6 col-12">
              <h3 className="my-2">Department Head</h3>
              <Dropdown
                className="w-full"
                value={departmentData.departmentHead}
                options={employeeOptions}
                onChange={(e) =>
                  setDepartmentData((prevData) => ({
                    ...prevData,
                    departmentHead: e.value,
                  }))
                }
                placeholder="Select Department Head"
                filter
                showClear
                filterBy="label"
              />
            </div>
            <div className="md:col-6 col-12">
              <h3 className="my-2">Contact Number</h3>
              <InputText
                className="w-full"
                placeholder="Contact Number"
                name="contactNumber"
                value={departmentData.contactNumber}
                onChange={handleInputChange}
                keyfilter="int"
                maxLength="10"
              />
            </div>
            <div className="md:col-6 col-12">
              <h3 className="my-2">Address</h3>
              <InputTextarea
                className="w-full"
                placeholder="Address"
                name="address"
                value={departmentData.address}
                onChange={handleInputChange}
                autoResize
              />
            </div>
            <div className="col-12">
              <h3 className="my-2">Description</h3>
              <InputTextarea
                className="w-full"
                placeholder="Description"
                name="description"
                value={departmentData.description}
                onChange={handleInputChange}
                autoResize
              />
            </div>
          </div>
          <div className="flex justify-content-end mt-3">
            <Button
              label="Cancel"
              className="p-button-danger"
              onClick={() => setDisplayAddModal(false)}
            />
            <Button
              label="Submit"
              className="mx-2"
              onClick={handleAddDepartment}
            />
          </div>
        </Dialog>

        {/* delete modal  */}
        <Dialog
          visible={displayConfirmation}
          style={{ width: "50vw" }}
          breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          onHide={hideConfirmationModal}
          header="Confirmation"
          footer={
            <div>
              <Button
                style={{ color: "white", background: "red" }}
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideConfirmationModal}
              />
              <Button
                style={{ color: "white", background: "#22c55e" }}
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={confirmDelete}
              />
            </div>
          }
        >
          Are you sure you want to delete the department?
        </Dialog>
      </div>
    </div>
  );
}

export default Department;
