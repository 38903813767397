import React from "react";

function ErrorRoute() {
  return (
    <div className="h-screen w-full grid place-content-center">
      <div className="text-5xl">404</div>
    </div>
  );
}

export default ErrorRoute;
