export const EmployeeTypeOptions = [
  { label: "Full-Time Employee", value: "Full-Time Employee" },
  { label: "Part-Time Employee", value: "Part-Time Employee" },
  { label: "Contract Employee", value: "Contract Employee" },
  { label: "Temporary Employee", value: "Temporary Employee" },
  { label: "Intern/Trainee", value: "Intern/Trainee" },
  { label: "Seasonal Employee", value: "Seasonal Employee" },
  {
    label: "Freelancer/Independent Contractor",
    value: "Freelancer/Independent Contractor",
  },
  { label: "Consultant", value: "Consultant" },
  { label: "Probationary Employee", value: "Probationary Employee" },
  { label: "Per Diem Employee", value: "Per Diem Employee" },
  { label: "Remote Employee", value: "Remote Employee" },
  { label: "Permanent Employee", value: "Permanent Employee" },
  { label: "Junior Employee", value: "Junior Employee" },
  { label: "Senior Employee", value: "Senior Employee" },
  { label: "Entry-Level Employee", value: "Entry-Level Employee" },
  { label: "Mid-Level Employee", value: "Mid-Level Employee" },
  { label: "Executive", value: "Executive" },
  { label: "Director", value: "Director" },
  { label: "Manager", value: "Manager" },
  { label: "Supervisor", value: "Supervisor" },
  { label: "Coordinator", value: "Coordinator" },
  { label: "Administrator", value: "Administrator" },
  { label: "Analyst", value: "Analyst" },
  { label: "Specialist", value: "Specialist" },
  { label: "Technician", value: "Technician" },
  { label: "Officer", value: "Officer" },
  { label: "Apprentice", value: "Apprentice" },
  { label: "Graduate Trainee", value: "Graduate Trainee" },
  { label: "Volunteer", value: "Volunteer" },
  { label: "Casual Employee", value: "Casual Employee" },
  { label: "Freelance Employee", value: "Freelance Employee" },
  { label: "Independent Contractor", value: "Independent Contractor" },
  { label: "Project-Based Employee", value: "Project-Based Employee" },
  { label: "Seasonal Contract Employee", value: "Seasonal Contract Employee" },
  { label: "Fixed-Term Employee", value: "Fixed-Term Employee" },
  { label: "Flexible Employee", value: "Flexible Employee" },
  { label: "Hourly Employee", value: "Hourly Employee" },
  { label: "Salaried Employee", value: "Salaried Employee" },
  { label: "Shift Worker", value: "Shift Worker" },
  { label: "Trainee", value: "Trainee" },
];
