import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Logo from "../assets/images/HRMSborder.png";
import TestLogo from "../assets/images/KodukkuLogoBorderNone.png";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";
import { RiLogoutBoxRLine } from "react-icons/ri";
import layoutNavs from "./LayoutNavs";

const MobileSidebar = ({ sideOpen, setSideOpen }) => {
  const [expanded, setExpanded] = useState(null);
  const sidebarRef = useRef(null);

  const toggleAccordion = (key) => {
    setExpanded(expanded === key ? null : key);
  };

  // Filter out repeated values based on the header
  const uniqueLayoutNavs = layoutNavs.reduce((acc, current) => {
    if (!acc.find((item) => item.header === current.header)) {
      acc.push(current);
    }
    return acc;
  }, []);

  const handleClose = () => {
    setSideOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = ROUTE_CONSTANTS.LOGIN;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        handleClose(); 
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={sidebarRef}
      className="MobileSidebar"
      style={{ left: sideOpen ? "0rem" : "-16rem" }}
    >
      {/* <center className="">
        <img
          src={Logo}
          alt="Logo"
          style={{ marginTop: "1rem" }}
          className="w-4rem mr-3"
        />
        <img
          src={Logo}
          alt="Logo"
          style={{ marginTop: "1rem" }}
          className="w-4rem"
        />
      </center> */}

      <div className="p-3 flex justify-content-center gap-3">
        <a
          href="https://kodukku.com"
          className="inline-block"
          style={{
            height: "68px",
            width: "70px",

          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={TestLogo}
            alt="Logo"
            style={{
              width: "100%",
              background: "#fff",
              cursor: "pointer",
              height: "100%",
            }}
          />
        </a>
        <img
          src={Logo}
          alt="Logo"
          style={{
            width: "70px",
            background: "#fff",
            cursor: "pointer",
            marginRight: "10px",
          }}
        />
      </div>
      <div className="middle-sidebar">
        {uniqueLayoutNavs.map((item) => (
          <div key={item.key} className="sidebar-item">
            <div
              className="sidebar-header"
              onClick={() => toggleAccordion(item.key)}
            >
              <div className="header-content">
                <span className="header-icon">{item.headerIcon}</span>
                <span className="header-text">{item.header}</span>
              </div>
              <div className="header-arrow">
                {expanded === item.key ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
            </div>
            {expanded === item.key && (
              <ul className="submenu">
                {layoutNavs
                  .filter((subitem) => subitem.header === item.header)
                  .map((subitem) => (
                    <li key={subitem.key} onClick={handleClose}>
                      <NavLink to={subitem.path} activeClassName="active-link">
                        {subitem.label}
                      </NavLink>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        ))}
      </div>
      <div className="footer-sidebar" onClick={handleLogout}>
        <p>
          <RiLogoutBoxRLine />

          <span>Logout</span>
        </p>
      </div>
    </div>
  );
};

export default MobileSidebar;
