import React, { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import Detail from './Detail';
import LetterTab from './Letter/LetterTab';
import AssetsTab from './Assets/AssetsTab';
import Contact from './Contact';
import Education from './Education';
import Salary from './Salary';
import Job from './Job';
import { useNavigate, useParams, useLocation } from 'react-router';
import { FaUser, FaCalendarCheck, FaTasks, FaEnvelope, FaCubes, FaPhoneAlt, FaGraduationCap, FaDollarSign, FaBriefcase } from 'react-icons/fa';
import axios from 'axios';
import { EMPLOYEE_API } from '../../../constants/api';
import { Button } from 'primereact/button';
import TaskTab from './Task/TaskTab';
import AttendanceTab from './Attendance/AttendanceTab';

const tabHeaders = [
    { label: 'Profile', icon: <FaUser size={15} />, component: Detail },
    { label: 'Attendance', icon: <FaCalendarCheck size={15} />, component: AttendanceTab },
    { label: 'Task', icon: <FaTasks size={15} />, component: TaskTab },
    { label: 'Letter', icon: <FaEnvelope size={15} />, component: LetterTab },
    { label: 'Assets', icon: <FaCubes size={15} />, component: AssetsTab },
    { label: 'Contact', icon: <FaPhoneAlt size={15} />, component: Contact },
    { label: 'Education', icon: <FaGraduationCap size={15} />, component: Education },
    { label: 'Salary', icon: <FaDollarSign size={15} />, component: Salary },
    { label: 'Job', icon: <FaBriefcase size={15} />, component: Job }
];

const EmployeeDetails = () => {
    const navigate = useNavigate();
    const { id: userId } = useParams();
    const location = useLocation();

    const [profileData, setProfileData] = useState(null);
    const [contactInfo, setContactInfo] = useState([]);
    const [educationInfo, setEducationInfo] = useState([]);
    const [jobInfo, setJobInfo] = useState([]);
    const [relationshipInfo, setRelationshipInfo] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                if (userId) {
                    const response = await axios.get(EMPLOYEE_API.USER_PROFILE(userId));
                    setProfileData(response.data.userData);
                    const kidId = response.data.kid_id;
                    if (kidId) {
                        const { data } = await axios.get(EMPLOYEE_API.GET_USER_DETAILS_KID, { params: { 'user_id': kidId } });
                        setContactInfo(data.contactInfo);
                        setEducationInfo(data.educationInfo);
                        setJobInfo(data.jobInfo);
                        setRelationshipInfo(data.relationshipInfo);
                    }
                }
            } catch (error) {
                console.error("Error fetching user profile:", error);
            }
        };
        fetchProfileData();
    }, [userId]);


    useEffect(() => {
        const tabState = location.state?.activeTab;
        if (tabState !== undefined) {
            setActiveIndex(tabState);
        }
    }, [location.state]);

    const handlePrev = () => {
        setActiveIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    };

    const handleNext = () => {
        setActiveIndex(prevIndex => (prevIndex < tabHeaders.length - 1 ? prevIndex + 1 : prevIndex));
    };

    return (
        <div className="employee-details">
            <div>
                <div className="flex align-items-center lg:gap-3 md:gap-2 gap-1">
                    <i
                        className="pi pi-arrow-left"
                        style={{ fontSize: "2rem", cursor: "pointer" }}
                        onClick={() => navigate(-1)}
                    ></i>
                    <span className='font-bold text-2xl' style={{ letterSpacing: '1px', lineHeight: '7px' }}>{tabHeaders[activeIndex].label}</span>
                </div>
                <div className="flex justify-content-end gap-2 mb-2">
                    <div>
                        <Button onClick={handlePrev} className="border-transparent p-1" icon="pi pi-arrow-circle-left" disabled={activeIndex === 0} />
                    </div>
                    <div>
                        <Button onClick={handleNext} className="border-transparent p-1" icon="pi pi-arrow-circle-right" disabled={activeIndex === tabHeaders.length - 1} />
                    </div>
                </div>
                <TabView className='card p-1 md:p-3 employee-details' activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    {tabHeaders.map((tab, index) => {
                        const Component = tab.component;
                        return (
                            <TabPanel key={index} header={<div className={"flex align-items-center gap-1"}>{tab.icon} {tab.label}</div>}>
                                <Component
                                    userId={userId}
                                    contactInfo={contactInfo}
                                    educationInfo={educationInfo}
                                    jobInfo={jobInfo}
                                    relationshipInfo={relationshipInfo}
                                />
                            </TabPanel>
                        );
                    })}
                </TabView>
            </div>
        </div>
    );
};

export default EmployeeDetails;
