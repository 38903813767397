import React, { useState } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import LeaveRequest from './AllLeaveRequest';
import CompOff from './CompOff';

const TabMenuComponent = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const menuItems = [
    { label: 'Leave Requests', icon: 'fi fi-br-assept-document', command: () => setActiveIndex(0) },
    { label: 'CompOff Requests', icon: 'fi fi-br-time-check', command: () => setActiveIndex(1) },
  ];

  const renderContent = () => {
    switch (activeIndex) {
      case 0:
        return <LeaveRequest />;
      case 1:
        return <CompOff />;
      default:
        return null;
    }
  };

  return (
    <div className='card'>
      <TabMenu model={menuItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
        {/* <div className='card'> */}
      {renderContent()}
    </div>
  );
};

export default TabMenuComponent;
