import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import moment from "moment";

export default function addTimeSheet({
  visible,
  handleSubmitClear,
  handleSubmit,
  handleChange,
  inputData,
  setSelectedTitle,
  selectedTitle,
  errors,
  projectListData,
}) {
  return (
    <div>
      <Dialog
        header="Add Time Entry"
        visible={visible}
        style={{ width: "40vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => {
          handleSubmitClear();
        }}
        position="center"
        draggable={false}
      >
        <div className="grid">
          <div className="col-6">
            <label htmlFor="date">Date</label>
            <InputText
              type="date"
              id="date"
              name="date"
              value={inputData.date}
              onChange={handleChange}
              className={errors.date ? "p-invalid" : ""}
              min={moment().subtract(3, "days").format("YYYY-MM-DD")}
              max={moment().format("YYYY-MM-DD")}
            />
            {errors.date && (
              <small className="p-error">Date is required.</small>
            )}
          </div>
          <div className="col-6"></div>
          <div className="col-6" style={{ flexGrow: "1" }}>
            <label htmlFor="startTime">Start time</label>
            <InputText
              type="time"
              id="startTime"
              name="startTime"
              value={inputData.startTime}
              onChange={handleChange}
              className={errors.startTime ? "p-invalid" : ""}
              inputMode="numeric"
            />
            {errors.startTime && (
              <small className="p-error">Start time is required.</small>
            )}
          </div>
          <div className="col-6" style={{ flexGrow: "1" }}>
            <label htmlFor="endTime">End time</label>
            <InputText
              type="time"
              id="endTime"
              name="endTime"
              value={inputData.endTime}
              onChange={handleChange}
              className={errors.endTime ? "p-invalid" : ""}
              inputMode="numeric"
            />
            {errors.endTime && (
              <small className="p-error">End time is required.</small>
            )}
          </div>

          <div className="col-12">
            <label htmlFor="title">Title</label>
            <Dropdown
              className="w-full"
              id="title"
              name="title"
              value={selectedTitle}
              options={projectListData}
              onChange={(e) => setSelectedTitle(e.value)}
              optionLabel="label"
              placeholder="Select a Project"
            />
            {errors.title && (
              <small className="p-error">Title is required.</small>
            )}
          </div>
          <div className="col-12">
            <label htmlFor="description">Description</label>
            <InputTextarea
              id="description"
              name="description"
              rows={3}
              placeholder="..."
              value={inputData.description}
              onChange={handleChange}
              style={{
                resize: "none",
              }}
              className={errors.description ? "p-invalid" : ""}
            />
            {errors.description && (
              <small className="p-error">Description is required.</small>
            )}
          </div>
        </div>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "8px",
            gap: "10px",
          }}
        >
          <Button
            label="Cancel"
            className="p-button-danger"
            style={{ width: "auto" }}
            onClick={handleSubmitClear}
          />
          <Button
            label="Submit"
            style={{ width: "auto" }}
            onClick={handleSubmit}
          />
        </div>
      </Dialog>
    </div>
  );
}
