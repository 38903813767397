import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import axios from "axios";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { TASK_API, APPOINTMENTS_API, EMPLOYEE_API } from "../../constants/api";
import { useNavigate } from "react-router-dom";

const localizer = momentLocalizer(moment);

const TodayAttendance = () => {
  const [taskDetails, setTaskDetails] = useState([]);
  const [appointmentsData, setAppointmentData] = useState([]);
  const [meetingData, setMeetingData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const navigate = useNavigate();

  const fetchAppointmentData = () => {
    try {
      axios
        .get(APPOINTMENTS_API.APPOINTMENTS_FETCH)
        .then((response) => {
          setAppointmentData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });
    } catch (error) {
      console.error("Error fetching appointment data:", error.message);
    }
  };

  useEffect(() => {
    fetchAppointmentData();
  }, []);

  const todayMeetings = async () => {
    try {
      const response = await axios.get(TASK_API.GET_ALL_TASK);

      setMeetingData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    todayMeetings();
  }, []);

  const fetchTaskData = async () => {
    try {
      const response = await axios.get(TASK_API.GET_ALL_TASK);
      const projectsData = response.data;
      setTaskDetails(projectsData);
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };


  const fetchData = async () => {
    try {
      const { data } = await axios.get(EMPLOYEE_API.GET_ALL);
      setEmployees(data.usersData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData()
    fetchTaskData();
  }, []);

  const generateBirthdayEvents = (employees) => {
    const today = moment();
    return employees
      .map((employee) => {
        const birthday = moment(employee.DOB, "YYYY-MM-DD");
        const birthdayThisYear = birthday.year(today.year());
        const birthdayInRange = birthdayThisYear.isSame(today, 'day') ||
          (birthdayThisYear.isAfter(today, 'day') && birthdayThisYear.diff(today, 'days') <= 7);

        if (birthdayInRange) {
          return {
            title: `${today.isSame(birthdayThisYear, 'day') ? "Birthday Today" : "Birthday Upcoming"} ${employee.firstname}`,
            start: birthdayThisYear.toDate(),
            end: birthdayThisYear.add(1, 'day').toDate(),
            type: "birthday",
          };
        }
        return null;
      })
      .filter(event => event !== null);
  };


  const events = [
    ...taskDetails.map((task) => ({
      title: task.task_name,
      start: moment(task.StartDate + " " + task.start_time).toDate(),
      end: moment(task.DueDate + " " + task.end_time).toDate(),
      type: "task",
    })),
    ...meetingData.map((meeting) => ({
      title: meeting.meeting_title,
      start: moment(meeting.MeetingSheduleDate + " " + meeting.start_time).toDate(),
      end: moment(meeting.MeetingSheduleDate + " " + meeting.end_time).toDate(),
      type: "meeting",
    })),
    ...appointmentsData.map((appointment) => ({
      title: appointment.appointment_title,
      start: moment(appointment.appointment_date + " " + appointment.appointment_time).toDate(),
      end: moment(appointment.appointment_date + " " + appointment.appointment_time).add(1, 'hour').toDate(),
      type: "appointment",
    })),
    ...generateBirthdayEvents(employees),
  ];

  const handleEventClick = (event) => {
    if (event.type === "task") {
      navigate("/AllTasks");
    }
  };

  return (
    <div className="calendar-container">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, border: "1px solid #ccc" }}
        eventPropGetter={(event, start, end, isSelected) => {
          let newStyle = {
            borderRadius: "0px",
            border: "none",
            color: "black",
            fontWeight: "500"
          };

          if (event.type === "meeting") {
            newStyle.backgroundColor = "#72f3f3";
          } else if (event.type === "appointment") {
            newStyle.backgroundColor = "#c7e0fb";
          } else if (event.type === "birthday") {
            newStyle.backgroundColor = "#ff6e38";
            newStyle.color = "#ffffff";
          }

          return {
            className: "",
            style: newStyle,
          };
        }}
        onSelectEvent={handleEventClick}
      />
    </div>
  );
};

export default TodayAttendance;
