import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import { ASSETS_API } from '../../constants/api';
import { Toast } from 'primereact/toast';

const AllAssetsModal = ({ visible, onHide, assetData, onSave, isNew, employeeOptions }) => {
    const [formData, setFormData] = useState({
        assetname: '',
        serialnumber: '',
        assetcategory: '',
        assetstatus: '',
        issuedate: null,
        returndate: null,
        empid: null,
    });

    const [errors, setErrors] = useState({});
    const toast = useRef(null);

    const showToast = (severity, detail) => {
        console.log('showToast called', severity, detail); // Debug log
        if (toast.current) {
            toast.current.show({ severity, detail, life: 3000 });
        }
    };

    useEffect(() => {
        if (assetData) {
            setFormData(prevData => ({
                ...prevData,
                ...assetData,
                issuedate: assetData.issuedate ? assetData.issuedate.substring(0, 10) : '',
                returndate: assetData.returndate ? assetData.returndate.substring(0, 10) : '',
            }));
        }
    }, [assetData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleSave = () => {

        console.log('Handling save with isNew:', isNew);
        const newErrors = {};

        if (!formData.empid) {
            newErrors.empid = 'Employee is required.';
        }

        if (!formData.assetname) {
            newErrors.assetname = 'Asset Name is required.';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const request = isNew
            ? axios.post(ASSETS_API.ASSETS_ADD, formData)
            : axios.put(ASSETS_API.ASSETS_UPDATE(formData.id), {
                assetname: formData.assetname,
                serialnumber: formData.serialnumber,
                assetcategory: formData.assetcategory,
                assetstatus: formData.assetstatus,
                issuedate: formData.issuedate,
                returndate: formData.returndate,
                empid: formData.empid,
            });

        request
            .then(response => {
                showToast('success', isNew ? 'Asset added successfully' : 'Asset updated successfully');
                onSave();
                onHide();
            })
            .catch(error => {
                console.error(isNew ? 'Error adding asset:' : 'Error updating asset:', error);
                showToast('error', isNew ? 'Failed to add asset' : 'Failed to update asset');
            });

    };

    const handleClose = () => {
        setErrors({});
        onHide();
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                header={isNew ? "Add Asset" : "Edit Asset"}
                visible={visible}
                onHide={handleClose}
                modal
                position="center"
                draggable={false}
                style={{ width: "90vw", maxWidth: "500px" }}
            >
                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="empid">Employee</label>
                        <Dropdown
                            id="empid"
                            name="empid"
                            value={formData.empid}
                            options={employeeOptions}
                            onChange={(e) => handleChange({ target: { name: 'empid', value: e.value } })}
                            optionLabel="label"
                            placeholder="Select Employee"
                        />
                        {errors.empid && (
                            <div className="text-red-500">{errors.empid}</div>
                        )}
                    </div>
                    <div className="p-field">
                        <label htmlFor="assetname">Asset Name</label>
                        <InputText id="assetname" name="assetname" value={formData.assetname} onChange={handleChange} />
                        {errors.assetname && (
                            <div className="text-red-500">{errors.assetname}</div>
                        )}
                    </div>
                    <div className="p-field">
                        <label htmlFor="serialnumber">Serial Number</label>
                        <InputText id="serialnumber" name="serialnumber" value={formData.serialnumber} onChange={handleChange} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="assetcategory">Category</label>
                        <InputText id="assetcategory" name="assetcategory" value={formData.assetcategory} onChange={handleChange} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="assetstatus">Status</label>
                        <InputText id="assetstatus" name="assetstatus" value={formData.assetstatus} onChange={handleChange} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="issuedate">Issue Date</label>
                        <input
                            type="date"
                            id="issuedate"
                            name="issuedate"
                            value={formData.issuedate || ""}
                            onChange={handleChange}
                            className="p-inputtext p-component"
                        />
                        {errors.issuedate && (
                            <div className="text-red-500">{errors.issuedate}</div>
                        )}
                    </div>
                    <div className="p-field">
                        <label htmlFor="returndate">Return Date</label>
                        <input
                            type="date"
                            id="returndate"
                            name="returndate"
                            value={formData.returndate || ""}
                            onChange={handleChange}
                            className="p-inputtext p-component"
                        />
                    </div>
                    <div className="flex justify-content-end my-3">
                        <div className="flex gap-3">
                            <Button icon="pi pi-times" label="Cancel" className="p-button-danger" onClick={handleClose} />
                            <Button label={isNew ? "Submit" : "Update"} icon="pi pi-check" onClick={handleSave} />
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default AllAssetsModal;
