import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { ASSETS_DOC_API, ASSETS_IMAGES_API, EMPLOYEE_API, MEMO_API } from '../../constants/api';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import { DownloadOutlined, FilePdfOutlined, FileWordOutlined } from '@ant-design/icons';
import { Image } from 'primereact/image';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';

const AllMemo = () => {
  const [employeesData, setEmployeesData] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [formInput, setFormInput] = useState({
    empid: [],
    subject: '',
    date: '',
    message: '',
    file: null,
  });
  const [data, setData] = useState([]);
  const [error, setError] = useState({});
  const [editing, setEditing] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [showFileInput, setShowFileInput] = useState(false);
  const [selectedMeme, setSelectedMeme] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const toast = useRef(null);

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(EMPLOYEE_API.All_USERS);
        const { usersData } = response.data;
        setEmployeesData(usersData);

        const formattedEmployees = usersData.map((employee) => ({
          label: `${employee.firstname} ${employee.lastname}`,
          value: `${employee.id}`,
        }));

        setEmployeeOptions(formattedEmployees);
      } catch (error) {
        console.error("Error fetching employees:", error);
        setError({ ...error, fetch: "Failed to fetch employees" });
      }
    };

    const fetchMemoData = async () => {
      try {
        const response = await axios.get(MEMO_API.GET_ALL);
        setData(response.data || []);
      } catch (error) {
        console.error("Error fetching memo data:", error);
      }
    };

    fetchEmployees();
    fetchMemoData();
  }, []);

  const validateForm = () => {
    const { empid, subject, date, message } = formInput;
    const newErrors = {};
    if (empid.length === 0) newErrors.empid = "Employees are required.";
    if (!subject) newErrors.subject = "Subject is required.";
    if (!date) newErrors.date = "Date is required.";
    if (!message) newErrors.message = "Message is required.";
    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormInput((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
    setError((prevError) => ({
      ...prevError,
      [name]: '',
    }));
  };

  const handleDateChange = (e) => {
    setFormInput((prevForm) => ({
      ...prevForm,
      date: e.target.value,
    }));
    setError((prevError) => ({
      ...prevError,
      date: '',
    }));
  };

  const handleFileUpload = (e) => {
    setFormInput((prevForm) => ({
      ...prevForm,
      file: e.target.files[0],
    }));
  };

  const handleEmployeeSelectChange = (selectedOptions) => {
    setFormInput((prevForm) => ({
      ...prevForm,
      empid: selectedOptions || [],
    }));

    setError((prevError) => ({
      ...prevError,
      empid: '',
    }));
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    const empid = Array.isArray(formInput.empid)
      ? formInput.empid.map((option) => option).join(",")
      : formInput.empid;
    const formData = new FormData();
    formData.append('empid', empid);
    formData.append('subject', formInput.subject);
    formData.append('date', formInput.date);
    formData.append('message', formInput.message);
    if (formInput.file) {
      formData.append('file', formInput.file);
    }

    try {
      if (editing !== null) {
        await axios.put(MEMO_API.MEMO_UPDATE(editing), formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        showToast('success', 'Success', 'Memo updated successfully!');
      } else {
        await axios.post(MEMO_API.MEMO_ADD, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        showToast('success', 'Success', 'Memo added successfully!');
      }
      setFormInput({
        empid: [],
        subject: '',
        date: '',
        message: '',
        file: null,
      });
      handleDialogClose();
      const response = await axios.get(MEMO_API.GET_ALL);
      setData(response.data || []);
    } catch (error) {
      console.error('Error saving memo:', error);
      showToast('error', 'Error', 'Failed to save memo!');
    }
  };

  const handleEdit = (memo) => {
    const formattedEmpIds = memo.empid.split(',').map(empId => {
      return empId;
    });

    setFormInput({
      empid: formattedEmpIds,
      subject: memo.subject,
      date: memo.date,
      message: memo.message,
      file: null,
    });
    setShowFileInput(false)
    setSelectedMeme(memo)
    setEditing(memo.id);
    setDisplayModal(true);
  };

  const handleDeleteMemo = async (memoId) => {
    try {
      await axios.delete(MEMO_API.MEMO_DELETE(memoId));
      showToast('success', 'Success', 'Memo deleted successfully!');
      const response = await axios.get(MEMO_API.GET_ALL);
      setData(response.data || []);
    } catch (error) {
      console.error('Error deleting memo:', error);
      showToast('error', 'Error', 'Failed to delete memo!');
    }
  };

  const handleCancel = () => {
    setFormInput({
      empid: [],
      subject: '',
      date: '',
      message: '',
      file: null,
    });
    setShowFileInput(false)
    setEditing(null);
    setDisplayModal(false);
    setError({});
  };

  const handleDialogClose = () => {
    setDisplayModal(false);
    handleCancel();
  };

  const renderFooter = () => (
    <div>
      <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={handleCancel} />
      <Button label={editing !== null ? 'Update' : 'Add'} icon="pi pi-check" onClick={handleSubmit} />
    </div>
  );

  const getFilePreview = (fileUrl) => {
    if (!fileUrl) return <span>-</span>;

    const extension = fileUrl.split('.').pop().toLowerCase();
    const fileUrlWithBase = `${ASSETS_DOC_API.ASSETS_DOC}${fileUrl}`;

    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
        return (
          <div>
            <Image src={`${ASSETS_IMAGES_API.ASSETS_IMAGES}${fileUrl}`} alt="File preview" preview width='45px' height='45px' />
          </div>
        );
      case 'pdf':
        return (
          <Button
            icon={<FilePdfOutlined />}
            className="p-button-text text-red-500 border-red-500 hover:bg-red-500 hover:text-white "
            onClick={() => window.open(fileUrlWithBase, '_blank')}
          />
        );
      case 'doc':
      case 'docx':
        return (
          <Button
            icon={<FileWordOutlined />}
            className="p-button-text text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white"
            onClick={() => window.open(fileUrlWithBase, '_blank')}
          />
        );
      default:
        return <span>Unsupported file type</span>;
    }
  };
  const handleDownload = async (memeId) => {
    try {
      const url = MEMO_API.MEMO_DOWNLOAD(memeId);

      const response = await axios.get(url, {
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const urlObject = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = urlObject;
      a.download = `file_${memeId}`;
      a.click();

      URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };
  const menu = useRef(null)

  const handleContextMenu = (event, rowData) => {
    event.preventDefault();
    setSelectedMeme(rowData)
    menu.current.show(event);
  };

  const actionBodyTemplate = (rowData) => {
    const menuItems = [
      { label: 'Edit', icon: 'pi pi-pencil', command: () => handleEdit(selectedMeme) },
      { label: 'Delete', icon: 'pi pi-trash', command: () => handleDeleteMemo(selectedMeme.id) }
    ];
    if (selectedMeme?.file) {
      menuItems.push({
        label: 'Download',
        icon: <DownloadOutlined />,
        command: () => handleDownload(selectedMeme.id)
      });
    }

    return (
      <div>
        <Button
          icon="pi pi-ellipsis-v"
          className="p-button-rounded p-button-text"
          aria-label="More"
          onClick={(e) => handleContextMenu(e, rowData)}
        />
        <Menu
          popup
          model={menuItems}
          ref={menu}
          className="w-8rem"
        />
      </div>
    );
  };

  return (
    <div>
      <div className="md:flex justify-content-between">
        <Toast ref={toast} />
        <div className="ml-2">
          <h2> Memo / Notice / Circular</h2>
        </div>
        <div className="md:col-4 lg:col-3 col-12 ">
          <div className="p-inputgroup">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="flex my-2 justify-content-end">
          <Button label="Add" icon="pi pi-plus" onClick={() => setDisplayModal(true)} />
        </div>
        <div className="">
          <DataTable value={data} paginator rows={10} globalFilter={globalFilter}>
            <Column header="S.No" body={(rowData) => <span>{data.indexOf(rowData) + 1}</span>} />
            <Column
              field="date"
              header="Date"
              body={(rowData) => rowData.date ? moment(rowData.date).format("DD-MM-YYYY") : '-'}
              style={{ minWidth: '120px', maxWidth: '120px' }}
            />
            <Column
              field="empid"
              header="Employees"
              style={{ minWidth: '120px' }}
              body={(rowData) => {
                const empIds = Array.isArray(rowData.empid) ? rowData.empid : rowData.empid ? rowData.empid.split(',') : [];
                const employeeLabels = empIds.map(id => {
                  const employee = employeesData.find(emp => emp.id === id);
                  return employee ? `${employee.firstname} ${employee.lastname}` : id;
                });

                const displayNames = employeeLabels.slice(0, 2).join(', ');
                const truncatedDisplayNames = displayNames.length > 20 ? `${displayNames.slice(0, 20)}...` : displayNames;
                const tooltipContent = employeeLabels.join(', ');

                return (
                  <div>
                    <span
                      data-pr-tooltip={tooltipContent}
                      data-pr-position="right"
                      className="employee-tooltip">
                      {truncatedDisplayNames || '-'}
                    </span>
                    <Tooltip target=".employee-tooltip" position='right' />
                  </div>
                );
              }}
            />
            <Column
              field="subject"
              header="Subject"
              className='cursor-pointer'
              body={(rowData) => (
                <div>
                  <Tooltip target={`.subject-tooltip-${rowData.id}`} content={rowData.subject} />
                  <span
                    className={`subject-tooltip-${rowData.id}`}
                    style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {rowData.subject.length > 20 ? rowData.subject.slice(0, 20) + '...' : rowData.subject}
                  </span>
                </div>
              )}
            />
            <Column
              field="message"
              header="Message"
              className='cursor-pointer'
              body={(rowData) => (
                <div>
                  <Tooltip target={`.message-tooltip-${rowData.id}`} content={rowData.message} />
                  <span
                    className={`message-tooltip-${rowData.id}`}
                    style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {rowData.message.length > 20 ? rowData.message.slice(0, 20) + '...' : rowData.message}
                  </span>
                </div>
              )}
            />
            <Column body={(rowData) => getFilePreview(rowData.file)} header="File" />
            <Column body={actionBodyTemplate} header="Actions" />
          </DataTable>
        </div>
      </div>

      <Dialog
        header={editing !== null ? 'Edit Memo' : 'Add Memo'}
        visible={displayModal}
        onHide={handleDialogClose}
        footer={renderFooter()}
        style={{ width: "90vw", maxWidth: "500px" }}
        position='center'
        draggable={false}
      >
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="employees">Employees</label>
            <MultiSelect
              id="empid"
              value={formInput.empid}
              options={employeeOptions}
              onChange={(e) => handleEmployeeSelectChange(e.value)}
              placeholder="Select Employees"
              display="chip"
              filter
              showClear
            />
            {error.empid && <small className="p-error">{error.empid}</small>}
          </div>
          <div className="p-field">
            <label htmlFor="date">Date</label>
            <InputText type="date" id="date" name="date" value={formInput.date} onChange={handleDateChange} />
            {error.date && <small className="p-error">{error.date}</small>}
          </div>
          <div className="p-field">
            <label htmlFor="subject">Subject</label>
            <InputText id="subject" name="subject" value={formInput.subject} onChange={handleInputChange} />
            {error.subject && <small className="p-error">{error.subject}</small>}
          </div>
          <div className="p-field">
            <label htmlFor="message">Message</label>
            <InputTextarea id="message" s style={{ resize: "none" }} name="message" value={formInput.message} onChange={handleInputChange} rows={5} />
            {error.message && <small className="p-error">{error.message}</small>}
          </div>
          <div className="p-field">
            <label htmlFor="file">File</label>
            {selectedMeme?.file && !showFileInput && (
              <div className='flex align-items-center'>
                {getFilePreview(selectedMeme.file)}
                <Button
                  icon="pi pi-pencil"
                  className="p-button-text"
                  onClick={() => setShowFileInput(true)}
                />
              </div>
            )}
            {editing !== null && selectedMeme?.file == null && (
              <input
                type="file"
                name="file"
                onChange={handleFileUpload}
              />
            )}
            {editing == null && (
              <input
                type="file"
                name="file"
                onChange={handleFileUpload}
              />
            )}
            {showFileInput && (
              <div className=''>
                <input
                  type="file"
                  name="file"
                  className=''
                  onChange={handleFileUpload}
                />
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  className="p-button-text mt-2 w-10rem"
                  onClick={() => setShowFileInput(false)}
                />
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AllMemo;