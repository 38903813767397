import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import {
  EMPLOYEE_API,
  PAY_HEAD_API,
  PAYROLL_API,
  ATTENDANCE_API,
} from "../../constants/api";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
export default function EmployeeAddAllPayroll({
  isModalOpen,
  closeModal,
  fetchPayrollData,
}) {
  const toast = useRef(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [earningOptions, setEarningOptions] = useState([]);
  const [deductionOptions, setDeductionOptions] = useState([]);
  const [selectedEarning, setSelectedEarning] = useState("");
  const [selectedDeduction, setSelectedDeduction] = useState("");
  const [errors, setErrors] = useState({});
  const [numSundays, setNumSundays] = useState(0);
  const [totalDaysMissing, setTotalDaysMissing] = useState({});
  const [totalDaysPresent, setTotalDaysPresent] = useState({});
  const [totalDays, setTotalDays] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const fetchData = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.GET_ALL);
      const usersData = response.data.usersData;
      setEmployeeData(usersData);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error fetching employee data",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchPayHeads = async () => {
    try {
      const response = await axios.get(PAY_HEAD_API.GET_ALL_PAYHEAD);
      const payHeads = response.data;
      const earningOptions = payHeads
        .filter((payHead) => payHead.payhead_type === "earnings")
        .map((payHead) => ({
          label: payHead.payhead_name,
          value: payHead.payhead_id,
          payhead_name: payHead.payhead_name,
        }));
      const deductionOptions = payHeads
        .filter((payHead) => payHead.payhead_type === "deductions")
        .map((payHead) => ({
          label: payHead.payhead_name,
          value: payHead.payhead_id,
          payhead_name: payHead.payhead_name,
        }));
      setEarningOptions(earningOptions);
      setDeductionOptions(deductionOptions);
      if (earningOptions.length > 0) {
        setSelectedEarning(earningOptions[0].payhead_name);
      }
      if (deductionOptions.length > 0) {
        setSelectedDeduction(deductionOptions[0].payhead_name);
      }
    } catch (error) {
      console.error("Error fetching pay head data:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error fetching pay head data",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    fetchPayHeads();
  }, []);

  const fetchAttendanceData = async (empid, fromDate, toDate) => {
    try {
      const response = await axios.get(
        ATTENDANCE_API.ATTENDANCE_PAYROLL_GET(empid),
        {
          params: {
            fromDate,
            toDate,
          },
        }
      );
      const attendanceData = response.data;

      const totalSundays = attendanceData.sundayCount || 0;
      setNumSundays(totalSundays);
      const totalDays = attendanceData.totalDays || 0;
      setTotalDays(totalDays);
      const totalDaysPresent = attendanceData.totalDaysPresent || 0;
      setTotalDaysPresent(totalDaysPresent);
      const totalDaysMissingForEmp = attendanceData.totalDaysMissing || 0;
      setTotalDaysMissing((prevState) => ({
        ...prevState,
        [empid]: totalDaysMissingForEmp,
      }));

      return {
        totalDays,
        totalDaysMissing: totalDaysMissingForEmp,
        totalDaysPresent,
      };
    } catch (error) {
      console.error("Error fetching attendance data:", error.message);
      return {
        totalDays: 0,
        totalDaysMissing: 0,
        totalDaysPresent: 0,
      };
    }
  };

  const formatMonthYear = (dateString) => {
    if (!dateString) return "";
    const options = { year: "numeric", month: "long" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const extractMonthYear = (dateString) => {
    if (!dateString) return { month: "", year: "" };
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const [month, year] = formattedDate.split(" ");
    return { month, year };
  };

  const handleAddPayroll = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;

    let valid = true;
    let newErrors = {};

    if (!fromDate) {
      newErrors.fromDate = "Please select a from date.";
      valid = false;
    }

    if (!toDate) {
      newErrors.toDate = "Please select a to date.";
      valid = false;
    }

    setErrors(newErrors);

    if (!valid) return;

    setIsSubmitting(true);

    if (!employeeData || !Array.isArray(employeeData)) {
      console.error("Employee data is null, undefined, or not an array");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Employee data is invalid",
        life: 3000,
      });
      setIsSubmitting(false);
      return;
    }

    const payrollData = employeeData.map(async (employee) => {
      const empid = employee.empid;
      const attendanceData = await fetchAttendanceData(empid, fromDate, toDate);
      const totalDaysMissingForEmp = attendanceData.totalDaysMissing || 0;
      const totalDays = attendanceData.totalDays || 0;
      const dailyRate = employee.ot_hour || 0;
      const payhead_amount = totalDays * dailyRate;
      const total_deductions = totalDaysMissingForEmp * dailyRate;
      const total_earnings = payhead_amount;
      const netpay = total_earnings - total_deductions;
      const { month, year } = extractMonthYear(fromDate);
      const payroll = {
        empid: empid,
        from_date: fromDate,
        to_date: toDate,
        salary: employee.salary,
        total_earnings: total_earnings,
        total_deductions: total_deductions,
        netpay: netpay,
        month,
        year,
        payroll_earnings: [
          {
            payhead_id:
              earningOptions.length > 0 ? earningOptions[0].value : null,
            payhead_amount: payhead_amount,
          },
        ],
        payroll_deductions: [
          {
            payhead_id:
              deductionOptions.length > 0 ? deductionOptions[0].value : null,
            payhead_amount: total_deductions,
          },
        ],
      };
      if (
        !payroll.empid ||
        !payroll.from_date ||
        !payroll.to_date ||
        payroll.total_earnings === null ||
        payroll.total_earnings === undefined
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `Invalid data for employee: ${employee.empid}`,
          life: 3000,
        });
        valid = false;
      }

      return payroll;
    });

    if (!valid) {
      setIsSubmitting(false);
      return;
    }

    try {
      const resolvedPayrollData = await Promise.all(payrollData);
      const response = await axios.post(PAYROLL_API.ADD_MULTIPLE_PAYROLL, {
        payrolls: resolvedPayrollData,
      });
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Payroll data added successfully",
        life: 3000,
      });
      handleCloseModal();
      fetchPayrollData();
    } catch (error) {
      console.error("Error adding payroll data:", error);
      if (error.response && error.response.data && error.response.data.errors) {
        const firstError = error.response.data.errors[0];
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: firstError,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error adding payroll data",
          life: 3000,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleCloseModal = () => {
    setFromDate(null);
    setToDate(null);
    setErrors({});
    closeModal();
  };

  return (
    <div className="emp-profile">
      <Toast ref={toast} />
      <Dialog
        visible={isModalOpen}
        onHide={handleCloseModal}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        header="ADD MONTHLY PAYROLL"
      >
        <div className="emp-details grid">
          <div className="col-12">
            <label>Month and Year</label>
            <div>{formatMonthYear(fromDate)}</div>
          </div>
          <div className="col-6">
            <label htmlFor="fromDate">From Date</label>
            <InputText
              id="fromDate"
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
            />
            {errors.fromDate && (
              <small className="p-error">{errors.fromDate}</small>
            )}
          </div>
          <div className="col-6">
            <label htmlFor="toDate">To Date</label>
            <InputText
              id="toDate"
              type="date"
              value={toDate}
              onChange={handleToDateChange}
            />
            {errors.toDate && (
              <small className="p-error">{errors.toDate}</small>
            )}
          </div>
        </div>
        <div className="mt-3 flex justify-content-end">
          <Button
            label="Cancel"
            className="p-button-danger  mr-2"
            onClick={handleCloseModal}
          />
          <Button
            label="Add Payroll"
            className="p-button-raised ml-2"
            onClick={handleAddPayroll}
            disabled={isSubmitting}
          />
        </div>
      </Dialog>
    </div>
  );
}