import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { CATEGORY_API } from "../../constants/api";
import axios from "axios";

const StaffCategoryForm = ({ visible, onHide, fetchCategory, showToast, setVisible }) => {
  const [formData, setFormData] = useState({
    category_name: "",
    category_description: "",
    annual_leave: "",
    annual_permission: "",
    lateGrace: "",
    earlyGrace: "",
    weeklyOffDay: [],
    weeklyOffs: [],
    ShiftType: "",
    markPrefixAbsent: false,
    markSuffixAbsent: false,
    halfDayThreshold: "",
    absentThreshold: "",
    partialHalfDayThreshold: "",
    partialAbsentThreshold: "",
  });

  const weekdays = [
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
  ];

  const shiftTypes = [
    { label: "Morning-Shift", value: "Morning-Shift" },
    { label: "Afternoon-Shift", value: "Afternoon-Shift" },
    { label: "Evening-Shift", value: "Evening-Shift" },
    { label: "Night-Shift", value: "Night-Shift" },
    { label: "Split-Shift", value: "Split-Shift" },
    { label: "Rotational-Shift", value: "Rotational-Shift" },
    { label: "Flexible-Shift", value: "Flexible-Shift" },
    { label: "Fixed-Shift", value: "Fixed-Shift" },
  ];

  const weeklyOffOptions = [
    { label: "1st", value: "1st" },
    { label: "2nd", value: "2nd" },
    { label: "3rd", value: "3rd" },
    { label: "4th", value: "4th" },
    { label: "5th", value: "5th" },
  ];

  const handleSave = async () => {
    const dataToSend = {
      ...formData,
      weeklyOffs: formData.weeklyOffs.join(","),
      weeklyOffDay: formData.weeklyOffDay.join(","),
    };

    try {
      const response = await axios.post(CATEGORY_API.ADD_CATEGORY, dataToSend);
      fetchCategory();
      resetForm();
      onHide();
      showToast("success", "Success Message", "Category added successfully");
    } catch (error) {
      console.error("Error saving category:", error.message);
      showToast("error", "Error Message", "Failed to add category");
    }
  };

  const resetForm = () => {
    setFormData({
      category_name: "",
      category_description: "",
      annual_leave: "",
      annual_permission: "",
      lateGrace: "",
      earlyGrace: "",
      weeklyOffDay: [],
      weeklyOffs: [],
      ShiftType: "",
      markPrefixAbsent: false,
      markSuffixAbsent: false,
      halfDayThreshold: "",
      absentThreshold: "",
      partialHalfDayThreshold: "",
      partialAbsentThreshold: "",
    });
    setVisible(false)
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target || e;
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave();
  };

  const handleCancel = () => {
    onHide();
    resetForm()
  };

  return (
    <Dialog
      header="Add Category"
      visible={visible}
      style={{ minWidth: "19rem", maxWidth: '35rem' }}
      onHide={resetForm}
      draggable={false}
      position="center"
      footer={
        <div>
          <Button
            label="Cancel"
            className="p-button-danger"
            onClick={handleCancel}
          />
          <Button
            label="Submit"
            className="p-button-primary"
            onClick={handleSubmit}
          />
        </div>
      }
    >
      <form onSubmit={handleSubmit}>
        <div className="grid">
          <div className="col-12 sm:col-6">
            <label>Category Name</label>
            <InputText
              name="category_name"
              value={formData.category_name}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 sm:col-6">
            <label>Short Name</label>
            <InputText
              name="category_description"
              value={formData.category_description}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="grid">
          <div className="col-12 sm:col-6">
            <label>Grace Time for Late Coming</label>
            <div className="p-inputgroup">
              <InputNumber
                name="lateGrace"
                value={formData.lateGrace}
                onChange={handleChange}
              />
              <span className="p-inputgroup-addon">min</span>
            </div>

          </div>
          <div className="col-12 sm:col-6">
            <label>Grace Time for Early Going</label>
            <div className="p-inputgroup">
              <InputNumber
                name="earlyGrace"
                value={formData.earlyGrace}
                onChange={handleChange}
              />
              <span className="p-inputgroup-addon">min</span>
            </div>

          </div>
        </div>
        <div className="grid">
          <div className="col-12 sm:col-6">
            <label>Weekly Off</label>
            <MultiSelect
              name="weeklyOffDay"
              value={formData.weeklyOffDay}
              options={weekdays}
              onChange={(e) =>
                handleChange({ name: "weeklyOffDay", value: e.value })
              }
              placeholder="Select weekly off days"
              className="w-full"
            />
          </div>
          <div className="col-12 sm:col-6">
            <label>Weekly Off Options</label>
            <MultiSelect
              name="weeklyOffs"
              value={formData.weeklyOffs}
              options={weeklyOffOptions}
              className="w-full"
              onChange={(e) =>
                handleChange({ name: "weeklyOffs", value: e.value })
              }
              placeholder="Select weekly off options"
              disabled={formData?.weeklyOffDay?.length === 0}
            />
          </div>
        </div>
        <div className="grid">
          <div className="col-12 sm:col-6">
            <label>Calculate Half day if Work Duration is less than</label>
            <div className="p-inputgroup">
              <InputNumber
                name="halfDayThreshold"
                value={formData.halfDayThreshold}
                onChange={(e) =>
                  handleChange({
                    target: { name: "halfDayThreshold", value: e.value },
                  })
                }
              />
              <span className="p-inputgroup-addon">min</span>
            </div>
          </div>
          <div className="col-12 sm:col-6">
            <label>Calculate Absent if Work Duration is less than</label>
            <div className="p-inputgroup">
              <InputNumber
                name="absentThreshold"
                value={formData.absentThreshold}
                onChange={(e) =>
                  handleChange({
                    target: { name: "absentThreshold", value: e.value },
                  })
                }
              />
              <span className="p-inputgroup-addon">min</span>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col-12 sm:col-6">
            <label>
              On Partial Day Calculate Half day if Work Duration is less than
            </label>
            <div className="p-inputgroup">
              <InputNumber
                name="partialHalfDayThreshold"
                value={formData.partialHalfDayThreshold}
                onChange={(e) =>
                  handleChange({
                    target: {
                      name: "partialHalfDayThreshold",
                      value: e.value,
                    },
                  })
                }
              />
              <span className="p-inputgroup-addon">min</span>
            </div>
          </div>
          <div className="col-12 sm:col-6">
            <label>
              On Partial Day Calculate Absent day if Work Duration is less than
            </label>
            <div className="p-inputgroup">
              <InputNumber
                name="partialAbsentThreshold"
                value={formData.partialAbsentThreshold}
                onChange={(e) =>
                  handleChange({
                    target: {
                      name: "partialAbsentThreshold",
                      value: e.value,
                    },
                  })
                }
              />
              <span className="p-inputgroup-addon">min</span>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap sm:flex-nowrap gap-3 sm:gap-0 mt-2">
          <div className="flex">
            <Checkbox
              id="markPrefixAbsent"
              name="markPrefixAbsent"
              checked={formData.markPrefixAbsent}
              onChange={handleChange}
            />
            <label htmlFor="markPrefixAbsent" style={{ marginTop: "2px", marginLeft: "4px" }}>
              Mark Weekly Off and Holiday as Absent if prefix day is Absent
            </label>
          </div>
          <div className="flex">
            <Checkbox
              id="markSuffixAbsent"
              name="markSuffixAbsent"
              checked={formData.markSuffixAbsent}
              onChange={handleChange}
            />
            <label htmlFor="markSuffixAbsent" style={{ marginTop: "2px", marginLeft: "4px" }}>
              Mark Weekly Off and Holiday as Absent if Suffix day is Absent
            </label>
          </div>
        </div>
        <div className="grid mt-2 align-items-center">
          <div className="col-12 sm:col-6">
            <label>Flexible Shift Type</label>
            <Dropdown
              name="ShiftType"
              value={formData.ShiftType}
              options={shiftTypes}
              onChange={handleChange}
              className="w-full"
              placeholder="Select Shift Type options"
            />
          </div>
          <div className="sm:col-3 col-6">
            <label>Annual Leave</label>
            <InputNumber
              name="annual_leave"
              value={formData.annual_leave}
              onChange={handleChange}
            />
          </div>
          <div className="sm:col-3 col-6">
            <label>Annual Permission</label>
            <InputNumber
              name="annual_permission"
              value={formData.annual_permission}
              onChange={handleChange}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default StaffCategoryForm;
