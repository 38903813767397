import React, { useEffect, useState, useRef } from "react";
import ProfileImg from "../../assets/images/avatar.webp";
import { FaPencilAlt, FaArrowRight } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { TabView, TabPanel } from "primereact/tabview";
import { Tree } from "primereact/tree";
import { useNavigate } from "react-router";
import { MdNoSim } from "react-icons/md";
import { RiBankFill } from "react-icons/ri";
import { LuBanknote } from "react-icons/lu";
import axios from "axios";
import { EMPLOYEE_API } from "../../constants/api";
import moment from "moment";
import { Skeleton } from "primereact/skeleton";
import EditProfileModal from "./EditProfileModal";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import ChangePassword from "./ChangePassword";

const MyAccount = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate("");
  const [userProfile, setUserProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [errors, setErrors] = useState({});
  const toast = useRef(null);

  const showToast = (severity, detail) => {
    if (toast.current) {
      toast.current.show({ severity, detail });
    }
  };
  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = () => {
    const userDataString = localStorage.getItem("user");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const employeeId = userData.data.id;

      axios
        .get(EMPLOYEE_API.USER_PROFILE(employeeId))
        .then(({ data }) => {
          setUserProfile(data.userData);
          setLoading(false);
        })
        .catch((error) => {
          setError("Error fetching user data");
          setLoading(false);
        });
    } else {
      setError("User data not found in local storage");
      setLoading(false);
    }
  };

  const openEditModal = () => {
    setEditedData({
      id: userProfile.id,
      firstname: userProfile.firstname,
      lastname: userProfile.lastname,
      kid_id: userProfile.kid_id,
      department: userProfile.department,
      position: userProfile.position,
      contact_number: userProfile.contact_number,
      email: userProfile.email,
      joining_date: userProfile.joining_date,
      empid: userProfile.empid,
      reporting_manager_id: userProfile.reporting_manager_id,
      gender: userProfile.gender,
      marital_status: userProfile.marital_status,
      address: userProfile.address,
      employee_type: userProfile.employee_type,
      employment_status: userProfile.employment_status,
      salary: userProfile.salary,
      annual_leave: userProfile.annual_leave,
      annual_permissions: userProfile.annual_permissions,
      bank_name: userProfile.bank_name,
      account_number: userProfile.account_number,
      IFSC_Code: userProfile.IFSC_Code,
      balance_leave: userProfile.balance_leave,
      leave_taken: userProfile.leave_taken,
      contact_relationship: userProfile.contact_relationship,
      emergency_contact_name: userProfile.emergency_contact_name,
      emergency_contact_no: userProfile.emergency_contact_no,
      DOB: userProfile.DOB,
      state: userProfile.state,
      district: userProfile.district,
      pin_code: userProfile.pin_code,
      ot_hour: userProfile.ot_hour,
      house_no: userProfile.house_no,
      responsibilities: userProfile.responsibilities,
      about: userProfile.about,
    });
    setShowEditModal(true);
  };

  const saveChanges = async () => {
    const newErrors = {};

    if (!editedData.firstname) {
      newErrors.firstname = "First name is required";
    }

    if (!editedData.lastname) {
      newErrors.lastname = "Last name is required";
    }
    if (!editedData.DOB) {
      newErrors.DOB = "DOB is required";
    }
    if (!editedData.address) {
      newErrors.address = "Address is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const { id, empid, ...employeeDetails } = editedData;
      const payload = {
        user: {
          firstname: editedData.firstname,
          lastname: editedData.lastname,
          email: editedData.email,
          kid_id: editedData.kid_id,
        },
        employeeDetails: {
          ...employeeDetails,
        },
      };
      await axios.put(EMPLOYEE_API.EDIT_USER(empid), payload);
      await fetchProfileData();
      setShowEditModal(false);
      showToast("success", "Data updated successfully");
    } catch (error) {
      console.error("Error editing row:", error);
      showToast("error", "Error updating data");
    }
  };
  const [passwordView, setPasswordView] = useState(false)


  const renderProfile = () => {
    if (loading) {
      return (
        <div className="p-grid p-nogutter">
          <div className="p-col-12 p-md-6 p-lg-4">
            <Skeleton shape="circle" size="6rem" />
            <Skeleton width="100%" height="50px" />
            <Skeleton width="100%" height="30px" />
          </div>
          <div className="p-col-12 p-md-6 mt-4 p-lg-8">
            <Skeleton width="100%" height="20px" />
            <Skeleton width="100%" height="40px" />
            <Skeleton width="100%" height="30px" />
            <Skeleton width="100%" height="40px" />
          </div>
        </div>
      );
    } else if (error) {
      return <p>{error}</p>;
    } else if (!userProfile) {
      return <p>No data available</p>;
    } else {
      return (
        <div>
          <div className=" block md:flex md:gap-1 lg:gap-4" >
            <div className="" style={{ display: "flex", flexFlow: "column" }}>
              {/* profile-card */}
              <div
                className=" mb-2"
                style={{
                  padding: "0px",
                  overflow: "visible",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  border: "1px solid #eee",
                }}
              >
                <div
                  style={{
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    backgroundColor: "#ccc",
                    paddingTop: "20px",
                    paddingBottom: "50px",
                    position: "relative",
                    marginBottom: "50px",
                  }}
                >
                  <div style={{ marginBottom: "4px" }}>
                    <div style={{ textAlign: "center", fontWeight: "700" }}>
                      {" "}
                      {`${userProfile.firstname} ${userProfile.lastname}`}
                    </div>
                    {/* <div
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        marginTop: "4px",
                      }}
                    >
                      {userProfile.position}
                    </div> */}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      position: "absolute",
                    }}
                  >
                    <img
                      src={ProfileImg}
                      style={{
                        width: "6rem",
                        height: "6rem",
                        borderRadius: "50%",
                        boxShadow: "1px 1px 5px #00000050",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    paddingBottom: "16px",
                    padding: "10px",
                    gap: "4px",
                  }}
                >
                  <p style={{ whiteSpace: "pre-wrap", textAlign: "center" }}>
                    <i
                      class="fi fi-rs-map-marker"
                      style={{ marginRight: "8px" }}
                    ></i>
                    {userProfile.house_no}
                    {userProfile.address}
                  </p>
                  <div>
                    <i
                      class="fi fi-rs-phone-call"
                      style={{ marginRight: "8px" }}
                    ></i>
                    {userProfile?.contact_number}
                  </div>
                </div>
              </div>
              {/* about and skill */}
              <div
                className=""
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  border: "1px solid #eee",
                  padding: "8px",
                  minHeight: "200px",
                  marginBottom: "10px",
                }}
              >
                <TabView
                  activeIndex={activeIndex}
                  onTabChange={(e) => setActiveIndex(e.index)}
                  className="profile-tab-menu"
                >
                  <TabPanel
                    header={
                      <div>
                        <i
                          class="fi fi-sr-info"
                          style={{ marginRight: "4px" }}
                        ></i>
                        About
                      </div>
                    }
                  >
                    <div>
                      <p style={{ lineHeight: "1.8" }}>
                        {userProfile?.about == null ? (
                          <p style={{ textAlign: "center" }}>
                            {" "}
                            <MdNoSim /> No data available{" "}
                          </p>
                        ) : (
                          userProfile.about
                        )}
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel
                    header={
                      <div>
                        <IoSettingsSharp /> Skills
                      </div>
                    }
                    style={{ padding: "0px" }}
                  >
                    {userProfile.generalSkills == null &&
                      userProfile.technicalSkills == null ? (
                      <p style={{ textAlign: "center" }}>
                        <MdNoSim /> No data available
                      </p>
                    ) : (
                      <>
                        <Tree
                          style={{
                            padding: "0px",
                            border: "none",
                            outline: "none",
                          }}
                          className="no-outline"
                          value={[
                            {
                              label: "General Skills",
                              children: userProfile.generalSkills.map(
                                (skill) => ({
                                  label: skill,
                                })
                              ),
                            },
                          ]}
                        />
                        <Tree
                          className="no-outline"
                          style={{
                            padding: "0px",
                            border: "none",
                            whiteSpace: "wrap",
                            outline: "none",
                          }}
                          value={[
                            {
                              label: "Technical Skills",
                              children: userProfile.technicalSkills.map(
                                (skill) => ({ label: skill })
                              ),
                            },
                          ]}
                        />
                      </>
                    )}
                  </TabPanel>
                </TabView>
              </div>
            </div>
            {/* details */}
            <div
              className=" "
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                border: "1px solid #eee",
                padding: "1.25rem",
                flexGrow: "1",
              }}
            >
              {/* details heading */}
              <div
                className="flex-wrap gap-2 sm:gap-2 sm:flex-nowrap"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <h2>About</h2>
                <div className="flex gap-1 align-items-center">
                  <button
                    style={{
                      padding: "3px 12px",
                      border: '2px solid #1677ff',
                      outline: 'none',
                      background: 'white',
                      borderRadius: "5px",
                      cursor: "pointer",
                      flexWrap: 'nowrap',
                      whiteSpace: 'nowrap'
                    }}
                    className="hover:bg-blue-500 text-blue-400 hover:text-white flex gap-1 align-items-center"
                    onClick={() => setPasswordView(true)}
                  >
                    <i class="fi fi-rr-padlock-check text-lg"></i>Change Password
                  </button>
                  <button
                    style={{
                      padding: "8px 20px",
                      backgroundColor: "#3b82f6",
                      borderRadius: "5px",
                      color: "#fff",
                      outline: "none",
                      border: "none",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      cursor: "pointer",
                    }}
                    onClick={openEditModal}
                  >
                    <FaPencilAlt />
                    <span>Edit</span>
                  </button>
                </div>
              </div>
              <ChangePassword
                setPasswordView={setPasswordView}
                passwordView={passwordView} />

              {showEditModal && (
                <EditProfileModal
                  setEditedData={setEditedData}
                  editedData={editedData}
                  showEditModal={showEditModal}
                  setShowEditModal={setShowEditModal}
                  userProfile={userProfile}
                  setErrors={setErrors}
                  errors={errors}
                  saveChanges={saveChanges}
                />
              )}
              {/* details data view */}
              <div
                className="grid mt-1 sm:mt-2 md:mt-3"
                style={{ lineHeight: "1.1" }}
              >
                <div
                  className="col-12 md:col-4"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  {" "}
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    <i style={{ marginRight: "8px" }} class="fi fi-bs-user"></i>
                    Full Name
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {" "}
                    {`${userProfile.firstname} ${userProfile.lastname}` || ""}
                  </span>
                </div>
                <div
                  className="col-12 md:col-4"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  {" "}
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    <i
                      class="fi fi-rs-calendar-star"
                      style={{ marginRight: "4px" }}
                    ></i>{" "}
                    Date Birth
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {" "}
                    {moment(userProfile.DOB).format("DD-MM-YYYY") || ""}
                  </span>
                </div>
                <div
                  className="col-12 md:col-4"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  {" "}
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    {" "}
                    <i
                      class="fi fi-rs-venus-mars"
                      style={{ marginRight: "4px" }}
                    ></i>
                    Gender
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {" "}
                    {userProfile?.gender || ""}
                  </span>
                </div>
                <div
                  className="col-12 md:col-4"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  {" "}
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    <i
                      class="fi fi-rs-phone-call"
                      style={{ marginRight: "4px" }}
                    ></i>
                    Phone
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {userProfile?.contact_number || ""}
                  </span>
                </div>
                <div
                  className="col-12 md:col-4 xl:col-4"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  {" "}
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    <i
                      class="fi fi-rs-envelope"
                      style={{ marginRight: "4px" }}
                    ></i>{" "}
                    Email
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {userProfile?.email || ""}
                  </span>
                </div>
                <div
                  className="col-12 md:col-4"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  {" "}
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    <i
                      class="fi-sr-employee-man-alt"
                      style={{ marginRight: "4px" }}
                    ></i>
                    Employee Type
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {userProfile?.employee_type || ""}
                  </span>
                </div>
                {userProfile?.category?.category_id &&
                  <div
                    className="col-12 md:col-4 xl:col-4"
                    style={{
                      display: "flex",
                      flexFlow: "column",
                      gap: "2px",
                      marginBottom: "4px",
                    }}
                  >
                    {" "}
                    <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                      <i
                        class="fi fi fi-sr-skill"
                        style={{ marginRight: "4px" }}
                      ></i>{" "}
                      Category
                    </h4>
                    <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                      {userProfile?.category?.category_id || ""}
                    </span>
                  </div>
                }
                <div
                  className="col-12 md:col-4"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  {" "}
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    {" "}
                    <i
                      style={{ marginRight: "4px" }}
                      class="fi fi-br-clock"
                    ></i>
                    Joining date
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {userProfile?.joining_date || ""}
                  </span>
                </div>
                <div
                  className="col-12 md:col-4"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  {" "}
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    {" "}
                    <i
                      style={{ marginRight: "4px" }}
                      class="fi fi-bs-users-alt"
                    ></i>
                    Department
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {userProfile?.position || ""}
                  </span>
                </div>
                <div
                  className="col-12 md:col-4"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  {" "}
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    {" "}
                    <i
                      style={{ marginRight: "4px" }}
                      class="fi fi-sr-briefcase"
                    ></i>
                    Designation
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {userProfile?.position || ""}
                  </span>
                </div>

                {/* <div
                  className="col-12  md:col-12 xl:col-6"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  {" "}
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    {" "}
                    <i
                      class="fi fi-rs-marker"
                      style={{ marginRight: "4px" }}
                    ></i>
                    Address
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {userProfile?.address || ""}
                  </span>
                </div> */}
                <div
                  className="col-12 md:col-4"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    <i
                      class="fi fi-rs-track"
                      style={{ marginRight: "4px" }}
                    ></i>
                    State
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {userProfile?.state || ""}
                  </span>
                </div>
                <div
                  className="col-12 md:col-4"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    <i
                      class="fi fi-rs-location-arrow"
                      style={{ marginRight: "4px" }}
                    ></i>
                    District
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {userProfile?.district || ""}
                  </span>
                </div>
                <div
                  className="col-12 md:col-4"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    <i
                      class="fi fi-rs-map-pin"
                      style={{ marginRight: "4px" }}
                    ></i>
                    Pincode{" "}
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {userProfile?.pin_code || ""}
                  </span>
                </div>
                <div
                  className="col-12 md:col-4"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  {" "}
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    <RiBankFill style={{ marginRight: "4px" }} /> Account Name
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {userProfile?.bank_name || ""}
                  </span>
                </div>
                <div
                  className="col-12  md:col-4"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    <i
                      class="fi fi-sr-credit-card"
                      style={{ marginRight: "4px" }}
                    ></i>
                    Account No
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {userProfile?.account_number || ""}
                  </span>
                </div>
                <div
                  className="col-12  md:col-4"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  {" "}
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    {" "}
                    <LuBanknote style={{ marginRight: "4px" }} /> IFSC Code
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {userProfile?.IFSC_Code || ""}
                  </span>
                </div>
                <div
                  className="col-12"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  {" "}
                  {/* <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    {" "}
                    <i
                      class="fi fi-rr-graduation-cap"
                      style={{ marginRight: "4px" }}
                    ></i>{" "}
                    Education
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {userProfile?.education?.map((exp, index) => (
                      <p key={index}>
                        <FaArrowRight
                          style={{ marginLeft: "8px", marginRight: "8px" }}
                        />{" "}
                        {exp}
                      </p>
                    )) || (
                      <p style={{ textAlign: "center" }}>
                        <MdNoSim /> No data available
                      </p>
                    )}
                  </span> */}
                </div>
                {/* <div
                  className="col-12"
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: "2px",
                    marginBottom: "4px",
                  }}
                >
                  <h4 style={{ fontWeight: "500", color: "4a4a4a" }}>
                    {" "}
                    <i
                      class="fi fi-rr-briefcase"
                      style={{ marginRight: "4px" }}
                    ></i>
                    Experience
                  </h4>
                  <span style={{ marginLeft: "16px", marginTop: "4px" }}>
                    {userProfile?.experience?.map((exp, index) => (
                      <p key={index}>
                        <FaArrowRight
                          style={{ marginLeft: "8px", marginRight: "8px" }}
                        />
                        {exp}
                      </p>
                    )) || (
                      <p style={{ textAlign: "center" }}>
                        <MdNoSim /> No data available
                      </p>
                    )}
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <>
      <div className="p-3">
        <div className="flex" style={{ alignItems: "center", gap: "4px" }}>
          <i
            className="fi fi-ss-angle-left"
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          ></i>
          <p
            className="mb-2 text-center ml-1 sm:ml-3 md:ml-2"
            style={{ fontSize: "1rem", fontWeight: "600", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            Back
          </p>
        </div>
      </div>
      <div className="">{renderProfile()}</div>
    </>
  );
};

export default MyAccount;