import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import axios from "axios";
import {
  EMPLOYEE_API,
  ATTENDANCE_API,
  DEPARTMENT_API,
} from "../../constants/api";
import { Link } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import EditEmployeeModal from "./EditEmployee";
import "react-phone-input-2/lib/style.css";
import { Menu } from "primereact/menu";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { setFirst, setPageRows } from "../../service/paginator";
import { Tooltip } from "primereact/tooltip";
import { InputTextarea } from "primereact/inputtextarea";

export default function AllEmployee() {
  const [products, setProducts] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [editIndex, setEditIndex] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [selectedEmployeeToDelete, setSelectedEmployeeToDelete] =
    useState(null);
  const [reportingManagers, setReportingManagers] = useState([]);
  const [dateOfLeaving, setDateOfLeaving] = useState(null);
  const [feedBack, setFeedBack] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);
  const [selectedVacant, setSelectedVacant] = useState(null);
  const toast = useRef(null);
  const [attendanceData, setAttendanceData] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);
  const [errors, setErrors] = useState({});
  const [userDataPdf, setUserDataPdf] = useState([]);
  const menuRef = useRef(null);
  const departments = Array.from(
    new Set(products.map((product) => product.department))
  );

  const dispatch = useDispatch();
  const first = useSelector((state) => state.paginator.pages.allEmployee.first);
  const rowsPerPage = useSelector(
    (state) => state.paginator.pages.allEmployee.rowsPerPage
  );

  const [localFirst, setLocalFirst] = useState(first || 0);
  const [localRowsPerPage, setLocalRowsPerPage] = useState(rowsPerPage || 10);

  const onPageChange = (e) => {
    setLocalFirst(e.first);
    dispatch(setFirst({ page: "allEmployee", value: e.first }));
  };

  const onPageRowsChange = (event) => {
    setLocalRowsPerPage(event.rows);
    dispatch(setPageRows({ page: "allEmployee", value: event.rows }));
  };

  const rowsPerPageOptions = [10, 25, 50];

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setLocalRowsPerPage(newRowsPerPage);
    dispatch(setPageRows({ page: "allEmployee", value: newRowsPerPage }));
  };

  useEffect(() => {
    if (!filterApplied) {
      setFilteredProducts(products);
    } else {
      const filteredData = products.filter((product) =>
        selectedDepartments.includes(product.department)
      );
      setFilteredProducts(filteredData);
    }
  }, [filterApplied, products, selectedDepartments]);
  const handleFilterApply = () => {
    setShowFilter(false);
    setFilterApplied(true);
  };

  const handleFilterCancel = () => {
    setShowFilter(false);
    setFilterApplied(false);
    setSelectedDepartments([]);
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const reasonOptions = [
    { label: "Resigned", value: "Resigned" },
    { label: "Terminated", value: "Terminated" },
    { label: "Absconded", value: "Absconded" },
    { label: "Redundancy", value: "Redundancy" },
  ];
  const vacantOptions = [
    { label: "Vacant", value: "Vacant" },
    { label: "No Vacant", value: "No Vacant" },
  ];

  const fetchAttendanceData = () => {
    axios
      .get(ATTENDANCE_API.ATTENDANCE_TODAY)
      .then((response) => {
        setAttendanceData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  };

  useEffect(() => {
    fetchAttendanceData();
  }, []);

  const getCurrentDate = () => {
    const date = new Date();
    return date.toISOString().split("T")[0];
  };

  const currentDate = getCurrentDate();
  const currentDateDetails = attendanceData.filter(
    (data) => data.date === currentDate
  );

  const deleteRow = async (rowData) => {
    showConfirmationModal(rowData);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmation(false);
    setSelectedEmployeeToDelete(null);
    setDateOfLeaving(null);
    setFeedBack(null);
    setSelectedReason(null);
    setSelectedVacant(null);
  };

  const showConfirmationModal = (rowData) => {
    setSelectedEmployeeToDelete(rowData);
    setDisplayConfirmation(true);
  };

  const confirmDelete = async () => {
    try {
      if (!dateOfLeaving) {
        showToast("error", "Please select a relieving date");
        return;
      }
      const userId = selectedEmployeeToDelete.empid;
      await axios.delete(EMPLOYEE_API.DELETE_USER(userId), {
        data: {
          date_of_leaving: dateOfLeaving,
          reason_of_leaving: selectedReason,
          vacant: selectedVacant,
          feedback: feedBack,
        },
      });
      const updatedProducts = products.filter(
        (product) => product.id !== selectedEmployeeToDelete.id
      );
      setProducts(updatedProducts);
      showToast("success", "Data deleted successfully");
      hideConfirmationModal();
    } catch (error) {
      console.error("Error deleting row:", error);
      showToast("error", "Error deleting data");
      hideConfirmationModal();
    }
  };

  useEffect(() => {
    fetchData();
    fetchUserRole();
  }, []);

  const fetchUserRole = () => {
    const userDataString = localStorage.getItem("user");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setUserRole(userData.data.role);
    }
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(EMPLOYEE_API.All_USERS);
        if (response.status === 200) {
          const employeesData = response.data.usersData;
          setAllEmployees(employeesData);
          setReportingManagers(employeesData);
        }
      } catch (error) {
        console.error("Error fetching employees:", error.message);
      }
    };

    fetchEmployees();
  }, []);

  const getAllDepartment = async () => {
    try {
      const response = await axios.get(DEPARTMENT_API.DEPARTMENT_FETCH);
      setDepartmentData(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  useEffect(() => {
    getAllDepartment();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.GET_ALL);
      const usersData = response.data.usersData;
      const updatedUsersData = usersData.map((user) => ({
        ...user,
      }));

      setProducts(updatedUsersData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const openEditModal = (rowData, rowIndex) => {
    setEditedData({
      id: rowData.id,
      firstname: rowData.firstname,
      lastname: rowData.lastname,
      familyname: rowData.familyname,
      kid_id: rowData.kid_id,
      department: rowData.department,
      position: rowData.position,
      contact_number: rowData.contact_number,
      email: rowData.email,
      joining_date: rowData.joining_date,
      empid: rowData.empid,
      reporting_manager_id: rowData.reporting_manager_id,
      gender: rowData.gender,
      marital_status: rowData.marital_status,
      address: rowData.address,
      employee_type: rowData.employee_type,
      employment_status: rowData.employment_status,
      salary: rowData.salary,
      notice_period: rowData.notice_period,
      annual_leave: rowData.annual_leave,
      annual_permissions: rowData.annual_permissions,
      bank_name: rowData.bank_name,
      account_number: rowData.account_number,
      IFSC_Code: rowData.IFSC_Code,
      balance_leave: rowData.balance_leave,
      leave_taken: rowData.leave_taken,
      contact_relationship: rowData.contact_relationship,
      emergency_contact_name: rowData.emergency_contact_name,
      emergency_contact_no: rowData.emergency_contact_no,
      DOB: rowData.DOB,
      state: rowData.state,
      district: rowData.district,
      pin_code: rowData.pin_code,
      ot_hour: rowData.ot_hour,
      house_no: rowData.house_no,
      responsibilities: rowData.responsibilities,
      category_id: rowData?.category?.category_id,
    });
    setEditIndex(rowIndex);
    setDisplayModal(true);
  };

  const saveChanges = async () => {
    const newErrors = {};

    if (!editedData.firstname) {
      newErrors.firstname = "First name is required";
    }

    if (!editedData.lastname) {
      newErrors.lastname = "Last name is required";
    }
    if (!editedData.DOB) {
      newErrors.DOB = "DOB is required";
    }
    if (!editedData.address) {
      newErrors.address = "Address is required";
    }
    if (!editedData.department) {
      newErrors.department = "Department is required";
    }
    if (!editedData.position) {
      newErrors.position = "Position is required";
    }
    if (!editedData.account_number) {
      newErrors.account_number = "Account Number is required";
    }
    if (!editedData.IFSC_Code) {
      newErrors.IFSC_Code = "IFSC Code is required";
    }
    if (!editedData.bank_name) {
      newErrors.bank_name = "Bank Name is required";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if (editIndex !== null) {
      try {
        const { id, empid, ...employeeDetails } = editedData;
        const payload = {
          user: {
            firstname: editedData.firstname,
            lastname: editedData.lastname,
            email: editedData.email,
            kid_id: editedData.kid_id,
          },
          employeeDetails: {
            ...employeeDetails,
          },
        };
        await axios.put(EMPLOYEE_API.EDIT_USER(empid), payload);
        await fetchData();

        setProducts((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts[editIndex] = editedData;
          return updatedProducts;
        });

        setDisplayModal(false);
        showToast("success", "Data updated successfully");
      } catch (error) {
        console.error("Error editing row:", error);
        showToast("error", "Error updating data");
      }
    }
  };

  const showToast = (severity, detail) => {
    toast.current.show({ severity, detail });
  };

  const fetchUserDataPdf = (id) => {
    axios
      .get(EMPLOYEE_API.GET_PROFILE_PDF(id))
      .then((response) => {
        setUserDataPdf(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  };

  useEffect(() => {
    if (selectedProducts && selectedProducts.kid_id) {
      fetchUserDataPdf(selectedProducts.kid_id);
    }
  }, [selectedProducts]);

  const generateTable = (doc, title, data, startY) => {
    if (data.length > 0) {
      doc.text(title, 14, startY);
      data.forEach((item, index) => {
        if (typeof item === "object") {
          doc.autoTable({
            startY: index === 0 ? startY + 5 : startY,
            head: [["Field", "Value"]],
            body: Object.entries(item).map(([key, value]) => {
              if (Array.isArray(value)) {
                return [
                  key,
                  value.map((obj) => Object.values(obj).join(",")).join("\n"),
                ];
              }
              return [key, value];
            }),
          });
        } else {
          doc.autoTable({
            startY: index === 0 ? startY + 5 : startY,
            head: [["Value"]],
            body: [[item]],
          });
        }
      });
      return doc.lastAutoTable.finalY + 10;
    }
    return startY;
  };

  const downloadEmployeePdf = () => {
    if (!userDataPdf) return;

    const doc = new jsPDF("landscape");
    let startY = 20;

    startY = generateTable(
      doc,
      "User Details",
      userDataPdf.userDetailsData,
      startY
    );
    startY = generateTable(
      doc,
      "Personal Details",
      userDataPdf.personalDetailsData,
      startY
    );
    startY = generateTable(
      doc,
      "Contact Information",
      userDataPdf.contactInfoData,
      startY
    );
    startY = generateTable(
      doc,
      "Relationship Details",
      userDataPdf.relationshipDetailsData,
      startY
    );
    startY = generateTable(
      doc,
      "Education Details",
      userDataPdf.educationDetailsData,
      startY
    );
    startY = generateTable(
      doc,
      "Job Profile Details",
      userDataPdf.jobProfileDetailsData,
      startY
    );

    doc.save(`Employee_${selectedProducts.kid_id}_Details.pdf`);
  };

  const statusBodyTemplate = (product, index) => {
    const handleContextMenu = (event) => {
      event.preventDefault();
      menuRef.current.show(event);
    };

    const menuItems = [
      {
        label: "Edit",
        icon: "pi pi-pencil",
        command: () => openEditModal(selectedProducts, index),
      },
      {
        label: "View",
        icon: "pi pi-eye",
        command: () => { },
        template: () => {
          if (selectedProducts && selectedProducts.id) {
            return (
              <Link
                to={`/profile/${selectedProducts.id}`}
                className="p-menuitem-link"
                style={{ padding: "12px 20px" }}
              >
                <span
                  className="pi pi-eye"
                  style={{
                    color: "#4b5563",
                  }}
                ></span>
                <span style={{ color: "#4b5563", marginLeft: "8px" }}>
                  View
                </span>
              </Link>
            );
          }
          return null;
        },
      },
      {
        label: "Deactivate",
        icon: "pi pi-trash",
        command: () => deleteRow(selectedProducts),
      },
      {
        label: "Download",
        icon: "fi fi-rr-file-download",
        command: () => downloadEmployeePdf(selectedProducts),
      },
    ];

    return (
      <div className="flex" style={{ alignItems: "center" }}>
        <Button
          icon="pi pi-ellipsis-v"
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            setSelectedProducts(product);
            handleContextMenu(e);
          }}
        />
        <Menu model={menuItems} popup ref={menuRef} />
      </div>
    );
  };

  return (
    <div>
      <div className="md:flex block justify-content-between">
        <div className="">
          <h2 className="my-3">Employees</h2>
        </div>
        <div className="">
          <div className="p-inputgroup flex-1">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div className="card h-full">
        <div className="md:flex block  mb-2 justify-content-end">
          <div className="md:flex block gap-2 md:my-0 my-2 mr-4">
            <div className="">
              <Link to={`/addEmployeeDetails`}>
                <Button icon="fi fi-bs-plus" className="add-button">
                  Add
                </Button>
              </Link>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <Button className="" icon="fi fi-br-filter" onClick={handleFilter}>
              Select Department{" "}
            </Button>
            {showFilter && (
              <div
                className="filter-box"
                style={{
                  borderRadius: "10px",
                  padding: "10px",
                  width: "100%",
                  position: "absolute",
                  background: "#fff",
                  zIndex: "1",
                }}
              >
                <h2>Department</h2>
                <div
                  className="filter-list"
                  style={{
                    maxHeight: "270px",
                    overflowY: "scroll",
                    padding: "8px 0px",
                  }}
                >
                  {departments.map((departmentId) => {
                    const department = departmentData.find(
                      (dept) =>
                        parseInt(dept.department_id) === parseInt(departmentId)
                    );

                    return (
                      <div
                        key={departmentId}
                        className="filter-form flex align-items-center"
                        style={{ padding: "4px 0px" }}
                      >
                        <div className="">
                          <input
                            type="checkbox"
                            name="filter-name"
                            id={`filter-${departmentId}`}
                            style={{ marginTop: "6px", height: "15px" }}
                            checked={selectedDepartments.includes(departmentId)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedDepartments([
                                  ...selectedDepartments,
                                  departmentId,
                                ]);
                              } else {
                                setSelectedDepartments(
                                  selectedDepartments.filter(
                                    (item) => item !== departmentId
                                  )
                                );
                              }
                            }}
                          />
                        </div>
                        <label
                          htmlFor={`filter-${departmentId}`}
                          style={{
                            marginLeft: "8px",
                            fontWeight: "400",
                            fontSize: "16px",
                          }}
                        >
                          {department
                            ? department.department_name
                            : "Loading..."}
                        </label>
                      </div>
                    );
                  })}

                  <div className="button-filter flex justify-content-end">
                    <Button
                      severity="danger"
                      className="cancel-button"
                      onClick={handleFilterCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      severity="info"
                      className="apply-button"
                      onClick={handleFilterApply}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Toast ref={toast} />
        <DataTable
          key={JSON.stringify(products)}
          value={filteredProducts}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="id"
          tableStyle={{ minWidth: "50rem" }}
          globalFilter={globalFilter ? globalFilter.trim() : ""}
          removableSort
          paginator
          className="dataTableHiddenFooterBg"
          rows={localRowsPerPage}
          onPage={onPageChange}
          onPageRows={onPageRowsChange}
          first={localFirst}
          footer={
            <Dropdown
              value={localRowsPerPage}
              options={rowsPerPageOptions.map((option) => ({
                label: option,
                value: option,
              }))}
              onChange={handleRowsPerPageChange}
              placeholder="Select Rows Per Page"
            />
          }
        >
          <Column
            field="empid"
            header="Id"
            style={{ width: "33px", overflow: "visible" }}
            sortable
          ></Column>
          <Column
            header="Name"
            // body={(rowData) => `${rowData.firstname} ${rowData.lastname}`}
            style={{ textTransform: "capitalize" }}
            bodyStyle={{ width: "97px" }}
            body={(rowData) => (
              <>
                <Tooltip
                  target=".link-without-color"
                  mouseTrack
                  mouseTrackLeft={5}
                  mouseTrackTop={5}
                >
                  Click to view details
                </Tooltip>
                <Link
                  className="link-without-color"
                  to={`/profile/${rowData.id}`}
                >
                  {`${rowData.firstname} ${rowData.lastname}`}
                </Link>
              </>
            )}
          ></Column>
          <Column
            field="department"
            header="Department"
            style={{ textTransform: "capitalize" }}
            bodyStyle={{ width: "115px" }}
            body={(rowData) => {
              const departmentId = parseInt(rowData.department);
              const department = departmentData.find(
                (dept) => dept.department_id === departmentId
              );
              return department ? department.department_name : "-";
            }}
            sortable
          />
          <Column
            field="position"
            header="Designation"
            style={{ textTransform: "capitalize" }}
            bodyStyle={{ width: "115px" }}
            sortable
          ></Column>
          <Column
            field="Attendance"
            header="Attendance"
            bodyStyle={{ width: "89px" }}
            body={(rowData) => {
              const attendance = currentDateDetails.find(
                (data) => data.userid === rowData.empid
              );
              if (attendance) {
                return attendance.status === "Present" ? "Present" : "Absent";
              }
              return "-";
            }}
          ></Column>
          <Column
            field="employment_status"
            header="Employment Status"
            style={{ textTransform: "capitalize" }}
            bodyStyle={{ width: "95px" }}
          ></Column>
          <Column
            field="reporting_manager_id"
            header="Reporting Manager"
            body={(rowData) => {
              const reportingManager = allEmployees.find(
                (employee) => employee.id === rowData.reporting_manager_id
              );
              return reportingManager
                ? `${reportingManager.firstname} ${reportingManager.lastname}`
                : "-";
            }}
            style={{ textTransform: "capitalize" }}
            bodyStyle={{ width: "100px" }}
          />
          <Column
            field="joining_date"
            header="Joining Date"
            style={{ width: "87px", overflow: "visible" }}
            sortable
            body={(rowData) =>
              moment(rowData.joining_date).format("DD-MM-YYYY")
            }
          ></Column>
          <Column
            header="Actions"
            body={(rowData, rowIndex) => statusBodyTemplate(rowData, rowIndex)}
            style={{ width: "130px", overflow: "visible" }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={displayModal}
        onHide={() => setDisplayModal(false)}
        header="Edit Employee"
        footer={
          <div>
            <Button
              label="Save"
              icon="pi pi-check"
              className="p-button-success"
              onClick={saveChanges}
            />
            <Button
              label="Cancel"
              className="p-button-danger"
              onClick={() => setDisplayModal(false)}
            />
          </div>
        }
      >
        <EditEmployeeModal
          visible={displayModal}
          onHide={() => setDisplayModal(false)}
          saveChanges={saveChanges}
          editedData={editedData}
          setEditedData={setEditedData}
          reportingManagers={reportingManagers}
          errors={errors}
          setErrors={setErrors}
        />
      </Dialog>
      {/* delete model */}
      <Dialog
        visible={displayConfirmation}
        className="w-24rem md:30rem"
        onHide={hideConfirmationModal}
        header="Confirmation"
        footer={
          <div>
            <Button
              style={{ color: "white", background: "red" }}
              label="No"
              icon="pi pi-times"
              className="p-button-text"
              onClick={hideConfirmationModal}
            />
            <Button
              style={{ color: "white", background: "" }}
              label="Yes"
              icon="pi pi-check"
              onClick={confirmDelete}
            />
          </div>
        }
      >
        <div className="mb-4">
          Are you sure you want to Inactive the employee?
        </div>
        <div className="flex">
          <div className="mb-2 col-6">
            <Dropdown
              className="w-full"
              value={selectedReason}
              options={reasonOptions}
              onChange={(e) => setSelectedReason(e.value)}
              placeholder="Select reason"
            />
          </div>
          <div className="mb-2 col-6">
            <Dropdown
              className="w-full"
              value={selectedVacant}
              options={vacantOptions}
              onChange={(e) => setSelectedVacant(e.value)}
              placeholder="Select vacent"
            />
          </div>
        </div>
        <div className="col-6">
          <InputText
            type="date"
            value={dateOfLeaving}
            onChange={(e) => setDateOfLeaving(e.target.value)}
            showIcon
            placeholder="Select relieving date"
          />
          <div className="text-blue-500 text-xs relative mt-2">
            <FaInfoCircle className="absolute top-[3px]" />
            <span className="ml-4">Relieving Date</span>
          </div>
        </div>
        <div className="col-12">
          <InputTextarea
            type="text"
            value={feedBack}
            onChange={(e) => setFeedBack(e.target.value)}
            showIcon
            placeholder="Feedback...."
            style={{ resize: "none" }}
          />
        </div>
      </Dialog>
    </div>
  );
}