import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Toast } from "primereact/toast";
import axios from "axios";
import { PROJECT_API, TIME_SHEET_API } from "../../constants/api";
import { Tooltip } from "primereact/tooltip";
import { Menu } from "primereact/menu";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const TimeSheetApprovals = () => {
  const toast = useRef(null);
  const [projectListData, setProjectListData] = useState([]);
  const [timeSheetData, setTimeSheetData] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const menuRef = useRef(null);
  const menuAllRef = useRef(null);
  const [filteredTimeSheetData, setFilteredTimeSheetData] = useState([]);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);

  useEffect(() => {
    if (selectedDateFilter) {
      const filteredData = timeSheetData.filter(
        (entry) =>
          moment(entry.date).format("YYYY-MM-DD") === selectedDateFilter
      );
      setFilteredTimeSheetData(filteredData);
    } else {
      setFilteredTimeSheetData(timeSheetData);
    }
  }, [selectedDateFilter, timeSheetData]);

  const handleClick = (event, entry) => {
    setSelectedEntry(entry);
    menuRef.current.show(event);
  };

  useEffect(() => {
    getTimeSheetData();
    getProjectData();
  }, []);

  const showToast = (severity, detail) => {
    toast.current.clear();
    toast.current.show({ severity, detail, life: 3000 });
  };

  const getTimeSheetData = () => {
    axios
      .get(TIME_SHEET_API.TIME_SHEET_DATA)
      .then(({ data }) => {
        setTimeSheetData(data);
      })
      .catch((error) => {
        console.error("Error fetching timesheet data:", error.message);
      });
  };

  const getProjectData = () => {
    axios
      .get(PROJECT_API.GET_ALL_PROJECTS)
      .then(({ data }) => {
        const projectsFromDB = data;
        const projectsFormatted = projectsFromDB.map((project) => ({
          label: project?.project_name,
          value: project?.project_id,
        }));
        setProjectListData(projectsFormatted);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  };

  const handleDeny = async () => {
    if (selectedEntry) {
      try {
        await axios.patch(
          TIME_SHEET_API.TIME_SHEET_STATUS_UPDATE(selectedEntry.id),
          {
            status: "Rejected",
          }
        );
        getTimeSheetData();
        showToast("success", "Rejecetd sucessfully");
      } catch (error) {
        console.error("Error denying entry:", error);
        showToast("error", "Failed to deny entry.");
      }
    }
  };

  const handleApprove = async () => {
    if (selectedEntry) {
      try {
        await axios.patch(
          TIME_SHEET_API.TIME_SHEET_STATUS_UPDATE(selectedEntry.id),
          {
            status: "Approved",
          }
        );
        getTimeSheetData();
        showToast("success", "Approved successfully");
      } catch (error) {
        console.error("Error denying entry:", error);
        showToast("error", "Failed to approve entry.");
      }
    }
  };

  const getStatusText = (status) => {
    switch (status.toLowerCase()) {
      case "pending":
        return {
          text: "Not yet Approved",
          bgColor: "#EBE9F5",
          color: "#6C28D6",
        };
      case "approved":
        return { text: "Approved", bgColor: "#615EFC", color: "#fff" };
      case "rejected":
        return { text: "Not Approved", bgColor: "#FF204E", color: "#fff" };
      default:
        return { text: status, bgColor: "#EBE9F5", color: "" };
    }
  };

  const projectTitleView = (id) => {
    const project = projectListData.find(
      (project) => project.value === parseInt(id)
    );

    return project ? project.label : "";
  };

  function calculateTotalHours(startTime, endTime) {
    const start = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");
    const duration = moment.duration(end.diff(start));
    const hours = duration.hours().toString().padStart(2, "0");
    const minutes = duration.minutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  const renderActions = (entry) => {
    const isApprovedOrRejected =
      entry.status === "Approved" || entry.status === "Rejected";

    if (isApprovedOrRejected) {
      return (
        <div>
          <Menu
            model={[
              {
                label: "View Details",
                icon: "pi pi-search",
              },
            ]}
            popup
            ref={menuRef}
            onHide={() => setSelectedEntry(null)}
            style={{ width: "130px" }}
          />
        </div>
      );
    }

    return (
      <div>
        <i
          className="pi pi-ellipsis-v"
          style={{ cursor: "pointer", color: "black" }}
          onClick={(event) => handleClick(event, entry)}
        />
        <Menu
          model={[
            {
              label: "Approve",
              icon: "fi fi-br-social-network",
              command: handleApprove,
              disabled: isApprovedOrRejected,
            },
            {
              label: "Deny",
              icon: "fi fi-br-hand",
              command: handleDeny,
              disabled: isApprovedOrRejected,
            },
          ]}
          popup
          ref={menuRef}
          onHide={() => setSelectedEntry(null)}
          style={{ width: "130px" }}
        />
      </div>
    );
  };

  const handleClickAll = (event, date, userId) => {
    event.preventDefault();
    setSelectedDate(date);
    setSelectedUserId(userId);
    menuAllRef.current.show(event);
  };
  
  const renderActionAll = (key, entries) => {
    const date = key.slice(0, 10);
    const userId = key.slice(11);
  
    const filteredEntries = entries.filter(
      (entry) =>
        entry.userid === userId &&
        moment(entry.date).format("YYYY-MM-DD") === date
    );
  
    const hasPendingEntries = filteredEntries.some(
      (entry) => entry.status === "Pending"
    );
  
    return (
      <div>
        <i
          className="pi pi-ellipsis-v"
          style={{ cursor: "pointer", color: "black" }}
          onClick={(event) => handleClickAll(event, date, userId)}
        />
        <Menu
          model={[
            {
              label: "Approve all",
              icon: "fi fi-br-social-network",
              command: () => handleApproveAll(),
              disabled: !hasPendingEntries,
            },
            {
              label: "Deny all",
              icon: "fi fi-br-hand",
              command: () => handleDenyAll(),
              disabled: !hasPendingEntries,
            },
          ]}
          popup
          ref={menuAllRef}
          onHide={() => {
            setSelectedDate(null);
            setSelectedUserId(null);
          }}
          style={{ width: "160px" }}
        />
      </div>
    );
  };
  

  const handleApproveAll = async () => {
    try {
      if (selectedDate && selectedUserId) {
        const entriesToUpdate = timeSheetData.filter(
          (entry) =>
            moment(entry.date).format("YYYY-MM-DD") === selectedDate &&
            entry.userid === selectedUserId
        );

        const updatePromises = entriesToUpdate.map((entry) =>
          axios.put(
            `${TIME_SHEET_API.TIME_SHEET_ALLSTATUS_UPDATE(
              selectedDate,
              selectedUserId
            )}`,
            { status: "Approved" }
          )
        );

        await Promise.all(updatePromises);
        getTimeSheetData();
        showToast("success", "All entries approved successfully");
      } else {
        showToast("error", "Please select a date and user");
      }
    } catch (error) {
      console.error("Error approving all entries:", error);
      showToast("error", "Failed to approve all entries.");
    }
  };

  const handleDenyAll = async () => {
    try {
      if (selectedDate && selectedUserId) {
        const entriesToUpdate = timeSheetData.filter(
          (entry) =>
            moment(entry.date).format("YYYY-MM-DD") === selectedDate &&
            entry.userid === selectedUserId
        );

        const updatePromises = entriesToUpdate.map((entry) =>
          axios.put(
            `${TIME_SHEET_API.TIME_SHEET_ALLSTATUS_UPDATE(
              selectedDate,
              selectedUserId
            )}`,
            { status: "Rejected" }
          )
        );

        await Promise.all(updatePromises);
        getTimeSheetData();
        showToast("success", "All entries denied successfully");
      } else {
        showToast("error", "Please select a date and user");
      }
    } catch (error) {
      console.error("Error denying all entries:", error);
      showToast("error", "Failed to deny all entries.");
    }
  };

  return (
    <div>
      <div className="col-12 md:flex block justify-content-between w-full">
        <Toast ref={toast} />
        <h2 className="my-1" style={{ marginBottom: "16px" }}>
          Daily Update
        </h2>
        <div className="md:col-2 col-12 mt-0">
          <div className="p-inputgroup">
            <InputText
              type="date"
              id="dateFilter"
              value={selectedDateFilter}
              onChange={(e) => setSelectedDateFilter(e.target.value)}
            />
            <Button icon="fi fi-bs-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div className="card">
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "5px",
            flexWrap: "wrap",
            marginBottom: "16px",
          }}
        >
          <h2 className="" style={{ color: "black" }}>
            Activities
          </h2>
        </div>

        <div>
          <div className="time-sheet-data">
            {Object.entries(
              filteredTimeSheetData.reduce((acc, entry) => {
                const date = moment(entry.date).format("YYYY-MM-DD");
                const userId = entry.userid;
                const key = `${date}-${userId}`;
                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push({ ...entry, userId });
                return acc;
              }, {})
            ).map(([key, entries]) => {
              const totalMinutes = entries.reduce((total, entry) => {
                const start = moment(entry.start_time, "HH:mm");
                const end = moment(entry.end_time, "HH:mm");
                return total + end.diff(start, "minutes");
              }, 0);

              const totalHours = Math.floor(totalMinutes / 60);
              const totalMinutesRemainder = totalMinutes % 60;

              return (
                <div
                  key={key}
                  style={{
                    background: "#eee",
                    padding: "6px",
                    marginBottom: "16px",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    className=""
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "8px",
                      marginBottom: "8px",
                      padding: "8px",
                    }}
                  >
                    <div className="">
                      <h4 style={{ minWidth: "300px" }}>
                        {moment(key, "YYYY-MM-DD").format("dddd, MMMM D, YYYY")}
                      </h4>
                      <p style={{ minWidth: "300px" }}>
                        <span style={{ color: "gray", fontWeight: "500" }}>
                          {entries[0].userId}
                        </span>
                        <span style={{ color: "blue" }}> @</span>
                        <span style={{ fontWeight: "600", fontSize: "12px" }}>
                          {entries[0].firstname} {entries[0].lastname}
                        </span>
                      </p>
                    </div>
                    <div className="total working flex aligin-content-center">
                      <div className="" style={{ marginTop: "1px" }}>
                        Total:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {totalHours}:
                          {totalMinutesRemainder < 10
                            ? `0${totalMinutesRemainder}`
                            : totalMinutesRemainder}
                        </span>
                      </div>
                      <div className="" style={{ marginLeft: "10px" }}>
                        {renderActionAll(key, entries)}
                      </div>
                    </div>
                  </div>
                  <div>
                    {entries.map((entry, index) => (
                      <div
                        key={index}
                        style={{
                          marginBottom: "10px",
                          background: "#fff",
                          borderRadius: "5px",
                          padding: "16px",
                        }}
                      >
                        <div
                          className=""
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            gap: "8px",
                          }}
                        >
                          <div
                            className=""
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                              minWidth: "200px",
                            }}
                          >
                            <span
                              className={`description-tooltip-${entry.id}`}
                              style={{ fontWeight: "500" }}
                            >
                              {entry.description.length > 20 ? (
                                <div>
                                  {entry.description.slice(0, 51)}...
                                  <Tooltip
                                    target={`.description-tooltip-${entry.id}`}
                                    position="top"
                                  >
                                    {entry.description}
                                  </Tooltip>
                                </div>
                              ) : (
                                entry.description
                              )}
                            </span>
                            <span
                              className={`title-tooltip-${entry.id}`}
                              style={{
                                background: "#EBE9F5",
                                color: "#6C28D6",
                                borderRadius: "9px",
                                padding: "5px 5px",
                              }}
                            >
                              {projectTitleView(entry.title) && (
                                <>
                                  {projectTitleView(entry.title)?.length >
                                  10 ? (
                                    <div>
                                      {projectTitleView(entry.title)?.slice(
                                        0,
                                        10
                                      )}
                                      ...
                                      <Tooltip
                                        target={`.title-tooltip-${entry.id}`}
                                        position="right"
                                      >
                                        {projectTitleView(entry.title)}
                                      </Tooltip>
                                    </div>
                                  ) : (
                                    projectTitleView(entry.title)
                                  )}
                                </>
                              )}
                            </span>
                          </div>
                          <div
                            className=""
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                              gap: "8px",
                            }}
                          >
                            <span
                              style={{
                                background: `${
                                  getStatusText(entry.status).bgColor
                                }`,
                                color: `${getStatusText(entry.status).color}`,
                                borderRadius: "5px",
                                padding: " 1px 5px",
                                marginRight: "4px",
                              }}
                            >
                              {getStatusText(entry.status).text}
                            </span>
                            <span
                              style={{
                                letterSpacing: "1.5px",
                                fontWeight: "500",
                              }}
                            >
                              {entry.start_time}-{entry.end_time}
                              <span
                                style={{
                                  color: "#eee",
                                  fontWeight: "500",
                                  fontSize: "20px",
                                  margin: " 0px 2px ",
                                }}
                              >
                                |
                              </span>
                              <span className="total hrs">
                                {calculateTotalHours(
                                  entry.start_time,
                                  entry.end_time
                                )}
                              </span>{" "}
                            </span>
                            <div className="">{renderActions(entry)}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeSheetApprovals;