import React, { useState, useRef, useEffect } from "react";
import laptop from "../../assets/images/signupimg.jpg";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import "./login.css";
import { setLocalStorage, getLocalStorage } from "../../utills";
import { LOCAL_CONSTANTS } from "../../constants/localConstants";
import kodukkuLogo from "../../assets/images/KodukkuLogoBorderNone.png";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const toast = useRef(null);

  useEffect(() => {
    const storedUsername = getLocalStorage("useremail");
    if (storedUsername) {
      const userEmail = JSON.parse(storedUsername);
      setUsername(userEmail);
      setRememberMe(true);
    }
  }, []);
  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!username || !emailRegex.test(username)) {
      showToast("error", "Please enter a valid email address");
      return false;
    }

    if (!password) {
      showToast("error", "Password is required");
      return false;
    }

    return true;
  };
  const showToast = (severity, detail) => {
    toast.current.clear();
    toast.current.show({ severity, detail, life: 3000 });
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    if (!validateForm()) {
      setLoading(false);
      return;
    }
  
    try {
      const response = await fetch("https://khrms.kodukku.com/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: username, password }),
      });

      if (response.ok) {
        const { token } = await response.json();
        setLocalStorage(LOCAL_CONSTANTS.ACCESS, token);
        const userResponse = await fetch("https://khrms.kodukku.com/api/auth/me", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (userResponse.ok) {
          const userData = await userResponse.json();
          setLocalStorage(LOCAL_CONSTANTS.USER, JSON.stringify(userData));
          const userRole = userData.data.role;
          if (userRole === "user" || userRole === "admin" || userRole === "superAdmin") {
            if (userRole === "admin" || userRole === "superAdmin") {
              setLocalStorage(LOCAL_CONSTANTS.ROLE_CHANGE, "user");
              localStorage.setItem("isChecked", false)
            }
            window.location.href = `/user/dashboard`;
          } else if (userRole === "admin") {
            window.location.href = "/admin/dashboard";
          }
        }
        showToast("info", "Please wait while we process your login");
      } else {
        console.error("Please check the credentials");
        showToast("error", "Invalid credentials");
      }
    } catch (error) {
      console.error("Error:", error);
      showToast("error", "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-container1">
      <div className="image-container1">
        <img src={laptop} alt="lap" />
      </div>
      <div className="form-content1 mt-5 md:mt-0">
        <div
        >
          <img
            src={kodukkuLogo}
            style={{
              width: "3.6rem",
              height: "3.5rem",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </div>
        <h2 style={{ marginTop: "0.7rem" }}>Login</h2>
        <small
          style={{
            fontWeight: "600",
            marginTop: "8px",
            color: "#2196f3",
          }}
        >
          {" "}
          Welcome to Kodukku HRMS
        </small>
        <form onSubmit={handleLogin}>
          <div className="input-container1">
            <label htmlFor="username" className="input-label">
              Username
            </label>
            <InputText
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username*"
            />
          </div>

          <div className="input-container">
            <label htmlFor="password" className="input-label">
              Password
            </label>
            <Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              feedback={false}
              placeholder="password*"
              toggleMask
            />
          </div>
          <div 
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "1px" }}>
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                autoFocus
                style={{
                  color: "rgb(18 112 251)",
                  outline: "none",
                }}
              />
              <label
                htmlFor="rememberMe"
                style={{
                  whiteSpace: "nowrap",
                  marginTop: "3px",
                  fontSize: "16px",
                }}
              >
                Remember me
              </label>
            </div>

            <Link to={"/resetPassword"}>
              <span className="forgotPassword hover:text-blue-300 text-blue-400 cursor-pointer">
                {" "}
                Forgot Password?
              </span>
            </Link>
          </div>
          <Button
            className="login-btn1"
            label="Submit"
            icon="pi pi-check"
            loading={loading}
            type="submit"
          />
        </form>
        <Toast ref={toast} />
      </div>
    </div>
  );
};

export default LoginForm;
