import React from 'react';
import { FaClock, FaRegClock, FaRegHourglass, FaStopwatch } from 'react-icons/fa';

const AttendanceTime = ({ time }) => {
    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
        gap: '16px',
    };

    return (
        <div className="my-2">
            <div
                style={{
                    ...gridStyle,
                }}
            >
                {/* Time Needed Card */}
                <div className="card bg-blue-100 p-2 rounded-lg shadow-lg flex items-center space-x-1">
                    <div className="flex flex-wrap gap-1 align-items-center justify-content-center"> <FaRegHourglass className="text-blue-500 text-2xl" />
                        <h3 className="font-bold text-lg">Time Needed</h3>
                    </div>
                    <div>
                        <p className="text-center">{time.totalNeedToWorkTime}</p>
                    </div>
                </div>

                {/* Worked Time Card */}
                <div className="card bg-green-100 p-2 rounded-lg shadow-lg flex items-center space-x-1">
                    <div className="flex flex-wrap gap-1 align-items-center justify-content-center">
                        <FaClock className="text-green-500 text-2xl" />
                        <h3 className="font-bold text-lg">Worked Time</h3>
                    </div>
                    <div>
                        <p className="text-center">{time.totalWorkingTime}</p>
                    </div>
                </div>

                {/* Overtime Card */}
                <div className="card bg-yellow-100 p-2 rounded-lg shadow-lg flex items-center space-x-1">
                    <div className="flex flex-wrap gap-1 align-items-center justify-content-center">
                        <FaRegClock className="text-yellow-500 text-2xl" />
                        <h3 className="font-bold text-lg">Overtime</h3>
                    </div>
                    <div>
                        <p className="text-center">{time.totalOverTime}</p>
                    </div>
                </div>

                {/* Remaining Time Card */}
                <div className="card bg-red-100 p-2 rounded-lg shadow-lg flex items-center space-x-1">
                    <div className="flex flex-wrap gap-1 align-items-center justify-content-center">
                        <FaStopwatch className="text-red-500 text-2xl" />
                        <h3 className="font-bold text-lg">Remaining Time</h3>
                    </div>
                    <div>
                        <p className="text-center">{time.totalRemainingTime}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AttendanceTime;
