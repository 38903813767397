import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { TASK_API, TASK_QUERY_API } from "../../../constants/api";
import { TabMenu } from "primereact/tabmenu";
import TaskFileEmployee from "../MyTaskDocumentView";
import { FaInfoCircle } from "react-icons/fa";
import { Toast } from "primereact/toast";
import moment from "moment/moment";

const tableStyles = {
  width: "100%",
  borderCollapse: "collapse",
};

const thStyles = {
  padding: "8px",
  textAlign: "left",
  border: "1px solid #ddd",
  backgroundColor: "#f2f2f2",
  width: "15%",
};

const tdStyles = {
  padding: "8px",
  textAlign: "left",
  border: "1px solid #ddd",
  width: "34%",
  fontWeight: 600,
  color: "#646464",
  wordBreak: "break-all",
};

const tableHeaderStyle = {
  border: "1px solid black",
  backgroundColor: "#f2f2f2",
  padding: "8px",
  textAlign: "left",
};

const TaskDetails = () => {
  const navigate = useNavigate();
  const { taskId } = useParams();
  const [taskDetails, setTaskDetails] = useState([]);
  const [visible, setVisible] = useState(false);
  const [action, setAction] = useState(false);
  const [taskQuery, setTaskQuery] = useState([]);
  const [progress, setProgress] = useState("");
  const [progressStatus, setProgressStatus] = useState("");
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileDetails, setFileDetails] = useState([]);
  const fileInputRef = useRef(null);
  const toast = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allTasks, setAllTasks] = useState([]);

  const showToast = (severity, detail) => {
    toast.current.show({
      severity,
      summary: severity === "error" ? "Error" : "Success",
      detail,
    });
  };
  const items = [
    { label: "Task", icon: "pi pi-fw pi-calendar" },
    { label: "Document", icon: "fi fi-rr-document" },
  ];
  const [activeItem, setActiveItem] = useState(items[0]);

  const statusOptions = [
    { name: "Returned", value: "Returned" },
    { name: "ReSolved", value: "ReSolved" },
  ];
  const statusProgressOptions = [
    { name: "In Progress", value: "In Progress" },
    { name: "Completed", value: "Completed" },
  ];

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }

    try {
      setIsSubmitting(true);

      const user_id = localStorage.getItem("user");
      const user = JSON.parse(user_id);
      if (!user || !user.data || !user.data.id) {
        console.error(
          "User object is null, undefined, or does not contain an id property."
        );
        return;
      }

      await axios.post(TASK_QUERY_API.TASK_QUERY_ADD, {
        task_id: taskId,
        user_id: user.data.id,
        query_comment: comment,
        query_status: status,
      });
      onHide();
      onCancel();
      fetchTaskQueryDetails();
    } catch (error) {
      console.error("Error adding query:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleTabChange = (e) => {
    setActiveItem(e.value);
  };

  const fetchFileDetails = async () => {
    try {
      const response = await axios.get(
        TASK_QUERY_API.GET_TASK_DOCUMENT_USER(taskId)
      );
      setFileDetails(response.data);
    } catch (error) {
      console.error("Error fetching task details:", error);
    }
  };
  useEffect(() => {
    fetchFileDetails();
  }, [taskId]);

  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleShowUploadDialog = () => {
    setShowUploadDialog(true);
    setIsEditMode(false);
  };

  const handleShowEditDialog = async () => {
    try {
      const response = await axios.get(
        TASK_QUERY_API.GET_TASK_DOCUMENT_USER(taskId)
      );
      setFileDetails(response.data);
      setShowUploadDialog(true);
      setIsEditMode(true);
      setUploadedFiles(response.data.filenames.join(", "));
    } catch (error) {
      console.error("Error fetching task details:", error);
    }
  };

  const handleCloseUploadDialog = () => {
    setShowUploadDialog(false);
    setIsEditMode(false);
    setUploadedFiles([]);
  };

  const renderUploadButton = () => {
    if (
      Array.isArray(fileDetails.filenames) &&
      fileDetails.filenames.length > 0
    ) {
      return (
        <Button
          label="Edit Upload File"
          icon="pi pi-upload"
          className="mx-2"
          onClick={handleShowEditDialog}
        />
      );
    } else {
      return (
        <Button
          label="Upload File"
          icon="pi pi-upload"
          className="mx-2"
          onClick={handleShowUploadDialog}
        />
      );
    }
  };
  const handleEditUpload = async (fileDetails) => {
    try {
      const files = fileInputRef.current.files;
      if (files.length === 0) {
        handleCloseUploadDialog();
        return;
      }
      const formData = new FormData();
      if (files.length > 5) {
        showToast("error", "You can only select up to 5 files.");
        return;
      }
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i], files[i].name);
      }
      formData.append("task_id", fileDetails.task_id);
      formData.append("creater", fileDetails.creater);

      await axios.put(TASK_QUERY_API.EDIT_FILE_DETAILS(taskId), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      showToast("success", "Files edited successfully.");
      handleCloseUploadDialog();
      fetchFileDetails();
    } catch (error) {
      console.error("Error editing files:", error);
      showToast("error", error.response.data.message);
    }
  };

  const handleSubmitUploadDialog = async () => {
    if (isEditMode) {
      handleEditUpload(fileDetails);
    } else {
      try {
        const user_id = localStorage.getItem("user");
        const user = JSON.parse(user_id);
        if (!user || !user.data || !user.data.id) {
          console.error(
            "User object is null, undefined, or does not contain an id property."
          );
          return;
        }

        const files = fileInputRef.current.files;
        if (files.length === 0) {
          alert("Please select at least one file to upload.");
          return;
        }

        if (files.length > 5) {
          alert("You can only select up to 5 files.");
          return;
        }

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i], files[i].name);
        }

        formData.append("task_id", taskId);
        formData.append("creater", user.data.id);

        await axios.post(TASK_QUERY_API.TASK_FILE_ADD, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        showToast("success", "Files uploaded successfully.");
        handleCloseUploadDialog();
        fetchFileDetails();
      } catch (error) {
        console.error("Error uploading files:", error);
        showToast("error", error.response.data.message);
      }
    }
  };

  const handleProgressSubmit = async () => {
    if (isSubmitting) {
      return;
    }

    try {
      setIsSubmitting(true);

      const user_id = localStorage.getItem("user");
      const user = JSON.parse(user_id);
      if (!user || !user.data || !user.data.id) {
        console.error(
          "User object is null, undefined, or does not contain an id property."
        );
        return;
      }

      await axios.post(TASK_QUERY_API.TASK_PROGRESS_ADD, {
        task_id: taskId,
        user_id: user.data.id,
        query_comment: progress,
        query_status: progressStatus,
      });

      handleCancel();
      fetchTaskQueryDetails();
      fetchTaskDetails();
    } catch (error) {
      console.error("Error adding query:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onUpload = (event) => {
    const files = event.target.files;
    if (files.length > 5) {
      event.preventDefault();
      alert("You can only select up to 5 files.");
      return;
    }
    const fileNames = [];
    for (let i = 0; i < files.length; i++) {
      fileNames.push(files[i].name);
    }
    setUploadedFiles(fileNames);
  };

  const onHide = () => {
    setVisible(false);
  };
  const fetchTaskDetails = async () => {
    try {
      const response = await axios.get(TASK_API.GET_PARTICULAR_TASK(taskId));
      setTaskDetails(response.data);
    } catch (error) {
      console.error("Error fetching task details:", error);
    }
  };
  useEffect(() => {
    fetchTaskDetails();
  }, [taskId]);

  const fetchTaskQueryDetails = async () => {
    try {
      const response = await axios.get(TASK_QUERY_API.GET_TASKQUERY(taskId));
      setTaskQuery(response.data);
    } catch (error) {
      console.error("Error fetching task details:", error);
    }
  };

  useEffect(() => {
    fetchTaskQueryDetails();
  }, [taskId]);
  const onCancel = () => {
    setComment("");
    setStatus("");
    setVisible(false);
  };
  const handleCancel = () => {
    setProgress("");
    setProgressStatus("");
    setAction(false);
  };


  const fetchData = async () => {
    const userString = localStorage.getItem("user");
    const user = JSON.parse(userString);
    if (!user || !user.data || !user.data.id) {
      console.error(
        "User object is null, undefined, or does not contain an id property."
      );
      return;
    }
    const response = await axios.get(TASK_API.GET_USER_TASK(user.data.id));
    setAllTasks(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePrev = () => {
    const currentIndex = allTasks.findIndex(
      (task) => String(task.Task_id) === String(taskId)
    );
    if (currentIndex > 0) {
      const prevTaskId = allTasks[currentIndex - 1].Task_id;
      navigate(`/Taskdetail/${prevTaskId}`);
    }
  };

  const handleNext = () => {
    const currentIndex = allTasks.findIndex(
      (task) => String(task.Task_id) === String(taskId)
    );
    if (currentIndex < allTasks.length - 1) {
      const nextTaskId = allTasks[currentIndex + 1].Task_id;
      navigate(`/Taskdetail/${nextTaskId}`);
    }
  };

  return (
    <div className="taskdetail">
      <div className="flex justify-content-between mt-3">
      <div className="mb-4">
        <i
          className="pi pi-arrow-left"
          style={{ fontSize: "2rem", cursor: "pointer" }}
          onClick={() => navigate("/EmployeeTask")}
        ></i>
      </div>
      <div className="mb-4">
          <Button
            style={{
              fontSize: "14px",
              cursor: "pointer",
              borderRadius: "16px",
              marginRight: "8px",
              padding: "8px",
              textAlign: "center",
            }}
            onClick={handlePrev}
          >
            <i
              class="fi fi-br-angle-double-small-left"
              style={{
                marginRight: "3px",
                marginTop: "2px",
                textAlign: "center",
              }}
            >
              {" "}
            </i>
            Prev
          </Button>
          <Button
            style={{
              fontSize: "14px",
              cursor: "pointer",
              borderRadius: "16px",
              padding: "8px",
              textAlign: "center",
            }}
            onClick={handleNext}
          >
            Next{" "}
            <i
              class="fi fi-br-angle-double-small-right"
              style={{ marginTop: "4px", textAlign: "center" }}
            ></i>
          </Button>
        </div>
      </div>
      <div className="card">
        <Toast ref={toast} />
        <TabMenu
          model={items}
          activeItem={activeItem}
          onTabChange={handleTabChange}
          className="custom-tabmenu"
        />
        {activeItem.label === "Task" && (
          <div>
            <h2 className="text-center">TASK HISTORY</h2>
            <table style={tableStyles} className="mt-3">
              <tbody>
                <tr>
                  <th style={thStyles}>Task Name</th>
                  <td style={tdStyles}>{taskDetails.task_name}</td>
                  <th style={thStyles}>Project</th>
                  <td style={tdStyles}>{taskDetails.Project}</td>
                </tr>
                <tr>
                  <th style={thStyles}>Status</th>
                  <td style={tdStyles}>{taskDetails.Status}</td>
                  <th style={thStyles}>Priority</th>
                  <td style={tdStyles}>{taskDetails.Priority}</td>
                </tr>
                <tr>
                  <th style={thStyles}>Start Date</th>
                  <td style={tdStyles}>
                    {moment(taskDetails.StartDate).format("DD-MM-YYYY")}
                  </td>
                  <th style={thStyles}>Details</th>
                  <td style={tdStyles}>{taskDetails.Details}</td>
                </tr>
                <tr>
                  <th style={thStyles}>Due Date</th>
                  <td style={tdStyles}>
                    {moment(taskDetails.DueDate).format("DD-MM-YYYY")}
                  </td>
                  <th style={thStyles}>Task To</th>
                  <td style={tdStyles}>{taskDetails.Processer}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div className="flex justify-content-center mt-3">
              <Button
                label="Query"
                icon="fi fi-rr-lightbulb-question"
                onClick={() => setVisible(true)}
              ></Button>
              <Button
                label="Take Action"
                icon="fi fi-br-hourglass-start"
                className="mx-2"
                onClick={() => setAction(true)}
              />
              {/* <Button
                label="Upload File"
                icon="fi fi-br-hourglass-start"
                className="mx-2"
                onClick={handleShowUploadDialog}
              /> */}
              {renderUploadButton()}

              <Dialog
                visible={visible}
                style={{ width: "25vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                onHide={() => setVisible(false)}
                showHeader={false}
                className="taskdetail"
              >
                <h3 className="flex justify-content-center">Query</h3>
                <div className="grid">
                  <div className="col-12">
                    <label htmlFor="comment">Comment</label>
                    <InputTextarea
                      id="comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      className=""
                      placeholder="Comment"
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="status" className="mt-0">
                      Status
                    </label>
                    <Dropdown
                      value={status}
                      onChange={(e) => setStatus(e.value)}
                      options={statusOptions}
                      optionLabel="name"
                      placeholder="Status"
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="text-center mt-2">
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    onClick={onCancel}
                    className="p-button-danger"
                  />
                  <Button
                    label="Send"
                    icon="pi pi-send"
                    className="mx-2"
                    onClick={handleSubmit}
                    disabled={!comment || !status}
                  />
                </div>
              </Dialog>
            </div>
            <Dialog
              visible={action}
              style={{ width: "25vw" }}
              breakpoints={{ "960px": "75vw", "641px": "100vw" }}
              onHide={() => setAction(false)}
              showHeader={false}
              className="taskdetail"
            >
              <h3 className="flex justify-content-center">Take Action</h3>
              <div className="grid">
                <div className="col-12">
                  <label htmlFor="progress">Task Progress</label>
                  <InputTextarea
                    id="progress"
                    value={progress}
                    onChange={(e) => setProgress(e.target.value)}
                    className=""
                    placeholder="Task Progress"
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="status" className="mt-0">
                    Progress Status
                  </label>
                  <Dropdown
                    value={progressStatus}
                    onChange={(e) => setProgressStatus(e.value)}
                    options={statusProgressOptions}
                    optionLabel="name"
                    placeholder="Progress Status"
                    className="w-full"
                  />
                </div>
              </div>
              <div className="text-center mt-2">
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  onClick={handleCancel}
                  className="p-button-danger"
                />
                <Button
                  label="Submit"
                  icon="pi pi-check"
                  className="mx-2"
                  onClick={handleProgressSubmit}
                  value={progress}
                  disabled={!progress || !progressStatus}
                />
              </div>
            </Dialog>
            <Dialog
              visible={showUploadDialog}
              style={{ width: "25vw" }}
              onHide={handleCloseUploadDialog}
              showHeader={false}
              className="taskdetail"
            >
              <div className="grid">
                <div className="col-12">
                  <label htmlFor="file">Task Attachment</label>
                  <input
                    id="file"
                    type="file"
                    multiple
                    onChange={onUpload}
                    ref={fileInputRef}
                  />
                  <div className="flex">
                    <div className="mt-1 ml-2">
                      <i className="fi fi-rs-file-upload"></i>
                    </div>
                    <p className="mb-2 ml-2">{uploadedFiles}</p>
                  </div>
                </div>
              </div>
              <div className="text-center mt-2">
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  onClick={handleCloseUploadDialog}
                  className="p-button-danger"
                />
                <Button
                  label={isEditMode ? "Update" : "Upload"}
                  icon={isEditMode ? "pi pi-check" : "pi pi-upload"}
                  className="mx-2"
                  onClick={
                    isEditMode ? handleEditUpload : handleSubmitUploadDialog
                  }
                />
              </div>
            </Dialog>

            <div className="mt-4">
              <div className="taskhistory">
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <thead>
                    <tr>
                      <th style={tableHeaderStyle}>#</th>
                      <th style={tableHeaderStyle}>Remark</th>
                      <th style={tableHeaderStyle}>Task From</th>
                      <th style={tableHeaderStyle}>Task To</th>
                      <th style={tableHeaderStyle}>Task Status</th>
                      <th style={tableHeaderStyle}>Task Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          width: "25px",
                        }}
                      >
                        {taskDetails.Task_id}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          width: "400px",
                        }}
                      >
                        {taskDetails.Details}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          width: "70px",
                        }}
                      >
                        {taskDetails.Creater}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          width: "100px",
                          wordBreak: "break-all",
                        }}
                      >
                        {taskDetails.Processer}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          width: "50px",
                        }}
                      >
                        {taskDetails.Status}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          width: "80px",
                        }}
                      >
                        {taskDetails.StartDate
                          ? `${taskDetails.StartDate.split("-")[2]}-${
                              taskDetails.StartDate.split("-")[1]
                            }-${taskDetails.StartDate.split("-")[0]}`
                          : ""}
                      </td>
                    </tr>
                    {taskQuery.map((query) => (
                      <tr key={query.query_id}>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "8px",
                            width: "25px",
                          }}
                        >
                          {query.task_id}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "8px",
                            width: "400px",
                          }}
                        >
                          {query.query_comment}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "8px",
                            width: "70px",
                          }}
                        >
                          {query.user_id}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "8px",
                            width: "100px",
                          }}
                        >
                          {query.Creater}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "8px",
                            width: "50px",
                          }}
                        >
                          {query.query_status}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            padding: "8px",
                            width: "80px",
                          }}
                        >
                          {query.query_date
                            ? `${query.query_date.split("-")[2]}-${
                                query.query_date.split("-")[1]
                              }-${query.query_date.split("-")[0]}`
                            : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {activeItem.label === "Document" && <TaskFileEmployee />}
      </div>
    </div>
  );
};

export default TaskDetails;
