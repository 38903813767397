import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { LETTER_API, ASSETS_IMAGES_API, ASSETS_DOC_API } from '../../../constants/api';
import { Menu } from 'primereact/menu';
import { Image } from 'primereact/image';
import { DownloadOutlined, FilePdfOutlined, FileWordOutlined } from '@ant-design/icons';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';

const EmpLetter = () => {
    const [visible, setVisible] = useState(false);
    const [mode, setMode] = useState('add');
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState(null);
    const [errors, setErrors] = useState({ title: '', file: '', empid: '' });
    const [letterData, setLetterData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [formData, setFormData] = useState({ empid: '' });
    const [selectedLetter, setSelectedLetter] = useState(null);
    const [showFileInput, setShowFileInput] = useState(false);
    const toast = useRef(null);
    const menu = useRef(null);
    const fileInputRef = useRef(null);

    const letterOptions = [
        { label: 'Welcome Letter', value: 'Welcome Letter' },
        { label: 'Promotion Letter', value: 'Promotion Letter' },
        { label: 'Termination Letter', value: 'Termination Letter' },
        { label: 'Offer Letter', value: 'Offer Letter' },
        { label: 'Resignation Letter', value: 'Resignation Letter' },
        { label: 'Salary Increment Letter', value: 'Salary Increment Letter' },
        { label: 'Transfer Letter', value: 'Transfer Letter' },
        { label: 'Leave Approval Letter', value: 'Leave Approval Letter' },
        { label: 'Relieving Letter', value: 'Relieving Letter' },
        { label: 'Internship Letter', value: 'Internship Letter' },
        { label: 'Redundancy Letter', value: 'Redundancy Letter' },
    ];

    const fetchLetterData = useCallback(async () => {
        try {
            const userData = JSON.parse(localStorage.getItem("user"))?.data
            const id = userData.id
            const { data } = await axios.get(LETTER_API.LETTER_FETCH(id));
            setLetterData(data || []);
        } catch (error) {
            console.error('Error fetching letter data:', error);
        }
    }, []);

    useEffect(() => {
        fetchLetterData();
    }, [fetchLetterData]);

    const openAddDialog = () => {
        setMode('add');
        resetForm();
        setVisible(true);
    };

    const openEditDialog = (letter) => {
        setMode('edit');
        setTitle(letter.title);
        setFile(letter.letter);
        setFormData({ empid: letter.empid });
        setSelectedLetter(letter);
        setErrors({ title: '', file: '', empid: '' });
        setShowFileInput(false);
        setVisible(true);
    };

    const handleDelete = async (letterId) => {
        try {
            await axios.delete(LETTER_API.LETTER_DELETE(letterId));
            await fetchLetterData();
            showToast('success', 'Letter deleted successfully!');
        } catch (error) {
            console.error('Error:', error);
            const errorMessage = error.response?.data?.message || 'An error occurred while deleting the letter.';
            showToast('error', errorMessage);
        }
    };

    const onFileChange = (e) => {
        const { name, files } = e.target;
        setFile(files[0]);
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    };

    const showToast = (severity, detail) => {
        if (toast.current) {
            toast.current.show({ severity, detail, life: 3000 });
        }
    };

    const handleSave = async () => {
        const newErrors = { title: '', file: '', empid: '' };
        let hasError = false;

        if (!title) {
            newErrors.title = 'Title is required';
            hasError = true;
        }
        if (mode === 'add' && !file) {
            newErrors.file = 'File is required';
            hasError = true;
        }
        setErrors(newErrors);

        if (hasError) return;
        const userData = JSON.parse(localStorage.getItem("user"))?.data
        const id = userData?.id

        const formDataToSend = new FormData();
        formDataToSend.append('title', title);
        if (file) {
            formDataToSend.append('file', file);
        }
        formDataToSend.append('empid', id);
        formDataToSend.append('creator', id);

        try {
            if (mode === 'add') {
                await axios.post(LETTER_API.LETTER_ADD, formDataToSend, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            } else {
                await axios.put(LETTER_API.LETTER_UPDATE(selectedLetter.id), formDataToSend, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            }
            await fetchLetterData();
            showToast('success', 'Letter saved successfully!');
            handleCancel();
        } catch (error) {
            console.error('Error:', error);
            const errorMessage = error.response?.data?.message || 'An error occurred while saving the letter.';
            showToast('error', errorMessage);
        }
    };

    const handleCancel = () => {
        resetForm();
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        setVisible(false);
    };

    const resetForm = () => {
        setTitle(null);
        setFile(null);
        setFormData({ empid: '' });
        setErrors({ title: '', file: '', empid: '' });
        setSelectedLetter(null);
        setShowFileInput(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'title') {
            setTitle(value);
            setErrors(prevErrors => ({ ...prevErrors, title: '' }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
            setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
        }
    };

    const getFilePreview = (fileUrl) => {
        if (!fileUrl) return <span>No file available</span>;

        const extension = fileUrl.split('.').pop().toLowerCase();
        const fileUrlWithBase = `${ASSETS_DOC_API.ASSETS_DOC}${fileUrl}`;

        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
                return (
                    <div>
                        <Image src={`${ASSETS_IMAGES_API.ASSETS_IMAGES}${fileUrl}`} alt="File preview" preview width='45px' height='45px' />
                    </div>
                );
            case 'pdf':
                return (
                    <Button
                        icon={<FilePdfOutlined />}
                        className="p-button-text text-red-500 border-red-500 hover:bg-red-500 hover:text-white "
                        onClick={() => window.open(fileUrlWithBase, '_blank')}
                    />
                );
            case 'doc':
            case 'docx':
                return (
                    <Button
                        icon={<FileWordOutlined />}
                        className="p-button-text text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white"
                        onClick={() => window.open(fileUrlWithBase, '_blank')}
                    />
                );
            default:
                return <span>Unsupported file type</span>;
        }
    };

    const handleDownload = async (letterId) => {
        try {
            const url = LETTER_API.LETTER_DOWNLOAD(letterId);

            const response = await axios.get(url, {
                responseType: 'blob',
            });

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const urlObject = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = urlObject;
            a.download = `file_${letterId}`;
            a.click();

            URL.revokeObjectURL(urlObject);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    const handleContextMenu = (event, rowData) => {
        event.preventDefault();
        setSelectedLetter(rowData)
        menu.current.show(event);
    };

    const actionBodyTemplate = (rowData) => {
        const userData = JSON.parse(localStorage.getItem("user"))?.data
        const id = userData?.id
        const isCreator = selectedLetter?.creator === id
        return (
            <div>
                <Button
                    icon="pi pi-ellipsis-v"
                    className="p-button-rounded p-button-text"
                    aria-label="More"
                    onClick={(e) => handleContextMenu(e, rowData)}
                />
                <Menu
                    popup
                    model={[
                        ...(isCreator ? [
                            { label: 'Edit', icon: 'pi pi-pencil', command: () => openEditDialog(selectedLetter) },
                            { label: 'Delete', icon: 'pi pi-trash', command: () => handleDelete(selectedLetter.id) }
                        ] : []),
                        { label: 'Download', icon: <DownloadOutlined />, command: () => handleDownload(selectedLetter.id) }
                    ]}
                    ref={menu}
                    className="w-8rem"
                />
            </div>
        );
    };

    return (
        <div className=''>
            <div className="md:flex justify-content-between">
                <div className="ml-2">
                    <h2>My Letters</h2>
                </div>
                <div className="md:col-4 lg:col-3 col-12 ">
                    <div className="p-inputgroup">
                        <InputText
                            type="text"
                            className="search-input p-inputtext p-component"
                            placeholder="Search"
                            value={globalFilter}
                            onChange={(e) => setGlobalFilter(e.target.value)}
                        />
                        <Button icon="pi pi-search" className="search-icon" />
                    </div>
                </div>
            </div>
            <Toast ref={toast} />
            <div className="card">
                <div className="flex my-2 justify-content-end">
                    <Button label="Add" icon="pi pi-plus" onClick={openAddDialog} />
                </div>
                <DataTable value={letterData} paginator rows={10} rowsPerPageOptions={[10, 25, 50]} >
                    <Column header="S.No"
                        body={(rowData) => <span>{letterData.indexOf(rowData) + 1}</span>}
                    />
                    <Column body={(rowData) => moment(rowData?.created_at).format("DD-MM-YYYY")} style={{ minWidth: '120px' }} header="Date" field='created_at' />
                    <Column header="Creator"
                        body={(rowData) => `${rowData.creator_firstname} ${rowData.creator_lastname}`}
                    />
                    <Column header="Creator Designation"
                        body={(rowData) => `${rowData.position}`}
                    />
                    <Column field="title" header="Title" />
                    <Column body={(rowData) => getFilePreview(rowData.letter)} header="Letter" />
                    <Column body={actionBodyTemplate} header="Actions" />
                </DataTable>

                <Dialog
                    header={mode === 'add' ? 'Add Letter' : 'Edit Letter'}
                    visible={visible}
                    onHide={handleCancel}
                    style={{ width: "90vw", maxWidth: "400px" }}
                    draggable={false}
                    position="center"
                    footer={
                        <div>
                            <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={handleCancel} />
                            <Button label="Save" icon="pi pi-check" onClick={handleSave} />
                        </div>
                    }
                >
                    <div className="p-fluid">
                        <div className="p-field">
                            <label htmlFor="title">Title</label>
                            <Dropdown
                                id="title"
                                name="title"
                                value={title}
                                options={letterOptions}
                                onChange={handleChange}
                                placeholder="Select a title"
                                filter
                                showClear
                            />
                            {errors.title && <div className="p-error">{errors.title}</div>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="file">Letter</label>
                            {selectedLetter?.letter && !showFileInput && (
                                <div className='flex align-items-center'>
                                    {getFilePreview(selectedLetter.letter)}
                                    <Button
                                        icon="pi pi-pencil"
                                        className="p-button-text"
                                        onClick={() => setShowFileInput(true)}
                                    />
                                </div>
                            )}
                            {mode === 'add' && (
                                <input
                                    type="file"
                                    name="file"
                                    onChange={onFileChange}
                                />
                            )}
                            {showFileInput && (
                                <div className=''>
                                    <input
                                        type="file"
                                        name="file"
                                        className=''
                                        ref={fileInputRef}
                                        onChange={onFileChange}
                                    />
                                    <Button
                                        label="Cancel"
                                        icon="pi pi-times"
                                        className="p-button-text mt-2 w-10rem"
                                        onClick={() => setShowFileInput(false)}
                                    />
                                </div>
                            )}
                            {errors.file && <div className="p-error">{errors.file}</div>}
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default EmpLetter;
