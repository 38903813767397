import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { ADMIN_LEAVE_API, LEAVE_API, TASK_API } from "../../constants/api";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { setFirst, setPageRows } from "../../service/paginator";

export default function AllLeaveRequest() {
  const toast = useRef(null);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [taskCount, setTaskCount] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [leaveTypes, setLeaveTypes] = useState([
    { label: "Sick Leave", value: "Sick Leave" },
    { label: "Personal Leave", value: "Personal Leave" },
    { label: "Vacation", value: "Vacation" },
    { label: "Casual Leave", value: "Casual Leave" },
    { label: "Quarantine leave", value: "Quarantine leave" },
    { label: "Marriage leave", value: "Marriage leave" },
    { label: "Maternity Leave", value: "Maternity Leave" },
    { label: "Privilege Leave", value: "Privilege Leave" },
    { label: "Jury Duty Leave", value: "Jury Duty Leave" },
    { label: "Comp - Off", value: "Comp - Off" },
    { label: "Others", value: "Others" },
  ]);
  const [timeOff, setTimeOff] = useState([
    { label: "Paid", value: "Paid" },
    { label: "Unpaid", value: "Unpaid" },
  ]);
  const [noOfDays, setNoOfDays] = useState(0);
  const [statusOptions, setStatusOptions] = useState([
    { label: "Pending", value: "pending" },
    { label: "Approved", value: "approved" },
    { label: "Rejected", value: "rejected" },
  ]);
  const dispatch = useDispatch();
  const first = useSelector((state) => state.paginator.pages.leaveApprovals.first);
  const rowsPerPage = useSelector((state) => state.paginator.pages.leaveApprovals.rowsPerPage);

  const [localFirst, setLocalFirst] = useState(first || 0);
  const [localRowsPerPage, setLocalRowsPerPage] = useState(rowsPerPage || 10);

  const onPageChange = (e) => {
    setLocalFirst(e.first);
    dispatch(setFirst({ page: 'leaveApprovals', value: e.first }));
  };

  const onPageRowsChange = (event) => {
    setLocalRowsPerPage(event.rows);
    dispatch(setPageRows({ page: 'leaveApprovals', value: event.rows }));
  };

  const rowsPerPageOptions = [10, 25, 50];

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setLocalRowsPerPage(newRowsPerPage);
    dispatch(setPageRows({ page: 'leaveApprovals', value: newRowsPerPage }))
  };

  const showToast = (severity, detail) => {
    toast.current.show({ severity, detail });
  };

  const fetchAllTasks = async () => {
    try {
      const response = await axios.get(TASK_API.GET_ALL_TASK);
      setTasks(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  useEffect(() => {
    fetchAllTasks();
  }, []);

  const fetchLeaveRequests = async () => {
    try {
      const response = await axios.get(ADMIN_LEAVE_API.GET_ALL_LEAVE);
      if (response.status === 200) {
        const data = response.data;
        const sortedLeaveRequests = data.leaveData.sort((a, b) => {
          if (a.status === "pending" && b.status !== "pending") return -1;
          if (a.status !== "pending" && b.status === "pending") return 1;
          return 0;
        });
        const updatedLeaveRequests = sortedLeaveRequests.map((leaveRequest) => {
          const matchingTasks = tasks.filter(
            (task) =>
              task.Processer.split(",").includes(leaveRequest.empid) &&
              ((new Date(task.StartDate) >= new Date(leaveRequest.fromdate) &&
                new Date(task.StartDate) <= new Date(leaveRequest.todate)) ||
                (new Date(task.DueDate) >= new Date(leaveRequest.fromdate) &&
                  new Date(task.DueDate) <= new Date(leaveRequest.todate)))
          );
          return {
            ...leaveRequest,
            matchingTasks: matchingTasks.length ? matchingTasks : [],
          };
        });

        const taskCounts = updatedLeaveRequests.reduce((acc, leaveRequest) => {
          acc[leaveRequest.empid] = leaveRequest.matchingTasks.length;
          return acc;
        }, {});

        setTaskCount(taskCounts);
        setLeaveRequests(sortedLeaveRequests);
      } else {
        console.error("Error fetching data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    if (selectedLeaveRequest?.fromdate && selectedLeaveRequest?.todate) {
      const fromDate = new Date(selectedLeaveRequest.fromdate);
      const toDate = new Date(selectedLeaveRequest.todate);
      const timeDifference = toDate.getTime() - fromDate.getTime();
      const calculatedNoOfDays = Math.ceil(
        timeDifference / (1000 * 3600 * 24) + 1
      );
      setNoOfDays(calculatedNoOfDays || 1);
    }
  }, [selectedLeaveRequest?.fromdate, selectedLeaveRequest?.todate]);

  const handleFromDateChange = (e) => {
    const newFromDate = e.target.value;
    setSelectedLeaveRequest((prev) => ({
      ...prev,
      fromdate: newFromDate,
      todate: newFromDate,
    }));
  };

  useEffect(() => {
    fetchLeaveRequests();
  }, []);

  const handleEdit = (rowData) => {
    setSelectedLeaveRequest(rowData);
    setShowEditModal(true);
  };

  const handleSaveLeaveType = async () => {
    try {
      const response = await axios.put(
        ADMIN_LEAVE_API.EDIT_LEAVETYPE(selectedLeaveRequest.leaveRequestId),
        {
          fromdate: selectedLeaveRequest.fromdate,
          todate: selectedLeaveRequest.todate,
          noOfdays: noOfDays,
          leavetype: selectedLeaveRequest.leavetype,
          leave_request_type: selectedLeaveRequest.leave_request_type,
          status: selectedLeaveRequest.status,
        }
      );

      if (response.status === 200) {
        const updatedLeaveRequests = leaveRequests.map((request) =>
          request.empid === selectedLeaveRequest.empid
            ? { ...request, leavetype: selectedLeaveRequest.leavetype }
            : request
        );

        if (selectedLeaveRequest.status === "approved" && selectedLeaveRequest.leave_request_type === "Paid") {
          const paidLeaveData = { status: selectedLeaveRequest.status, leave_request_type: selectedLeaveRequest.leave_request_type }
          const { data } = await axios.put(LEAVE_API.LEAVE_PAID_APPROVED(selectedLeaveRequest.leaveRequestId), { ...paidLeaveData })
        }

        else if (selectedLeaveRequest.status === "rejected" && selectedLeaveRequest.leave_request_type === "Paid") {
          const paidLeaveData = { status: selectedLeaveRequest.status, leave_request_type: selectedLeaveRequest.leave_request_type }

          const { data } = await axios.put(LEAVE_API.LEAVE_PAID_REJECTED(selectedLeaveRequest.leaveRequestId), { ...paidLeaveData })
        }



        setLeaveRequests(updatedLeaveRequests);
        setShowEditModal(false);
        fetchLeaveRequests();
        showToast("success", "Leave request edited successfully");
      } else {
        console.error("Error updating leave type:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating leave type:", error.message);
    }
  };

  const getStatusColor = (rowData) => {
    switch (rowData.status) {
      case "pending":
        return {
          borderColor: "#91CAFF",
          backgroundColor: "#E6F4FF",
          color: "#4280E3",
        };
      case "approved":
        return {
          borderColor: "#B7EB8F",
          backgroundColor: "#F6FFED",
          color: "#389E0D",
        };
      case "rejected":
        return {
          borderColor: "#FF3D32",
          backgroundColor: "#FAFAFA",
          color: "#FF3D32",
        };
      case "request":
        return {
          borderColor: "#fd7e14",
          backgroundColor: "#FAFAFA",
          color: "#fd7e14",
        };
      default:
        return {
          borderColor: "#ffbf00",
          backgroundColor: "#E6F4FF",
          color: "#4280E3",
        };
    }
  };

  const handleApprove = async (rowData) => {
    try {
      const response = await axios.put(
        ADMIN_LEAVE_API.LEAVE_APPROVAL(rowData.leaveRequestId),
        { status: "approved" }
      );

      if (response.status === 200) {
        const { empid } = response.data.leaveData;
        rowData.status = "approved";
        setLeaveRequests([...leaveRequests]);
        const leaveBalanceResponse = await axios.put(
          ADMIN_LEAVE_API.LEAVE_BALANCE(empid)
        );

        fetchLeaveRequests();

        if (rowData.status === "approved" && rowData.leave_request_type === "Paid") {

          const paidLeaveData = { status: rowData.status, leave_request_type: rowData.leave_request_type }

          const { data } = await axios.put(LEAVE_API.LEAVE_PAID_APPROVED(rowData.leaveRequestId), { ...paidLeaveData })

        }


      } else {
        console.error("Error updating status:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };
  const navigate = useNavigate()
  const handleReject = async (rowData) => {
    navigate(`/leaveData/${rowData.leaveRequestId}`)

    // try {
    //   const response = await axios.put(
    //     ADMIN_LEAVE_API.LEAVE_APPROVAL(rowData.leaveRequestId),
    //     { status: "rejected" }
    //   );

    //   if (response.status === 200) {
    //     rowData.status = "rejected";
    //     setLeaveRequests([...leaveRequests]);
    //     fetchLeaveRequests();
    //   } else {
    //     console.error("Error updating status:", response.statusText);
    //   }
    // } catch (error) {
    //   console.error("Error updating status:", error.message);
    // }
  };

  const actionTemplate = (rowData) => {
    const isApproved = rowData.status === "approved";
    const isRejected = rowData.status === "rejected";

    return (
      <div className="flex align-items-center">
        {!isApproved && !isRejected ? (
          <>
            <Button
              tooltip="Approve"
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
              }}
              className="icon-button"
              icon="pi pi-check"
              style={{
                border: "none",
                background: "#22c55e",
                borderRadius: "10px",
                padding: "10px",
                fontSize: "10px",
                color: "#fff",
              }}
              onClick={() => handleApprove(rowData)}
            />
            <Button
              tooltip="Reject"
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
              }}
              className="icon-button ml-2"
              icon="pi pi-times"
              style={{
                border: "none",
                background: "#ff3d32",
                borderRadius: "10px",
                padding: "10px",
                fontSize: "10px",
                color: "#fff",
              }}
              onClick={() => handleReject(rowData)}
            />
          </>
        ) : null}
        <Button
          tooltip="Edit"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          className="icon-button ml-2"
          icon="pi pi-pencil"
          style={{
            border: "none",
            background: "rgb(22,119,255)",
            borderRadius: "10px",
            padding: "10px",
            fontSize: "10px",
            color: "#fff",
          }}
          onClick={() => handleEdit(rowData)}
        />
      </div>
    );
  };

  const taskBodyTemplate = (rowData) => {
    return taskCount[rowData.empid] || 0;
  };
  return (
    <div className="AllleaveReq ">
      <Toast ref={toast} />
      <div className="md:flex block justify-content-between">
        <h2 className="my-3"></h2>
        <div className="md:col-4 lg:col-2 col-12">
          <div className="p-inputgroup flex-1">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <DataTable
        value={leaveRequests}
        scrollable
        paginator
        globalFilter={globalFilter.trim() ? globalFilter : null}
        className="dataTableHiddenFooterBg"
        rows={localRowsPerPage}
        onPage={onPageChange}
        onPageRows={onPageRowsChange}
        first={localFirst}
        footer={
          <Dropdown
            value={localRowsPerPage}
            options={rowsPerPageOptions.map(option => ({ label: option, value: option }))}
            onChange={handleRowsPerPageChange}
            placeholder="Select Rows Per Page"
          />
        }
      >
        <Column
          field="task"
          header="Task"
          body={taskBodyTemplate}
          style={{ width: "90px" }}
        ></Column>
        <Column
          field="Employeename"
          header="Name"
          body={(rowData) => (
            <>
              <Tooltip
                target=".link-without-color"
                mouseTrack
                mouseTrackLeft={5}
                mouseTrackTop={5}
              >
                Click to view details
              </Tooltip>
              <Link
                className="link-without-color"
                to={`/leaveData/${rowData.leaveRequestId}`}
              >
                {`${rowData.firstname} ${rowData.lastname}`}
              </Link>
            </>
          )}
        ></Column>
        <Column field="empid" header="ID" style={{ width: "90px" }}></Column>
        <Column
          field="fromdate"
          header="Leave From"
          style={{ minWidth: "105px" }}
          body={(rowData) => moment(rowData.fromdate).format("DD-MM-YYYY")}
        ></Column>
        <Column
          field="todate"
          header="Leave To"
          style={{ minWidth: "105px" }}
          body={(rowData) => moment(rowData.todate).format("DD-MM-YYYY")}
        ></Column>
        <Column
          field="noOfdays"
          header="No Of Days"
          style={{ width: "70px" }}
        ></Column>
        <Column
          field="leave_taken"
          header="Taken Leave"
          style={{ minWidth: "90px" }}
        ></Column>
        <Column
          field="balance_leave"
          header="Balance Leave"
          style={{ minWidth: "90px" }}
        ></Column>
        <Column
          field="leave_request_type"
          header="Time Off"
          style={{ minWidth: "90px" }}
        ></Column>
        <Column
          field="leavetype"
          header="Leave Type"
          style={{ minWidth: "90px" }}
        ></Column>
        {/* <Column
          field="reason"
          header="Reason"
          body={(rowData) => (
            <div
              id={`reason_${rowData.leaveRequestId}`}
              className="tooltip-container"
            >
              {rowData.reason.length > 20
                ? `${rowData.reason.slice(0, 20)}...`
                : rowData.reason}
              <Tooltip
                target={`#reason_${rowData.leaveRequestId}`}
                position="left"
                style={{ width: "300px" }}
              >
                {rowData.reason}
              </Tooltip>
            </div>
          )}
        /> */}
        <Column
          field="status"
          header="Status"
          style={{ minWidth: "80px" }}
          body={(rowData) => (
            <span
              id={`status_${rowData.leaveRequestId}`}
              style={{
                border: "2px solid",
                ...getStatusColor(rowData),
                padding: "6px 12px",
                borderRadius: "4px",
                position: "relative",
                cursor: "pointer"
              }}
            >
              {rowData.status.charAt(0).toUpperCase() +
                rowData.status.slice(1).toLowerCase()}
              {rowData.status == "request" && (
                <Tooltip target={`#status_${rowData.leaveRequestId}`} position="top">
                  <p style={{ color: "red", fontWeight: "bold" }}>
                    Request:
                  </p>{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    {rowData.user_replay}
                  </span>
                </Tooltip>
              )}
            </span>
          )}
        />

        <Column
          field="action"
          header="Actions"
          body={actionTemplate}
          style={{ minWidth: "90px" }}
        ></Column>
      </DataTable>
      {showEditModal && (
        <Dialog
          visible={showEditModal}
          onHide={() => setShowEditModal(false)}
          header="Edit Leave Request"
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div className="">
            <label
              htmlFor="editFromDate"
              style={{
                fontWeight: 600,
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              From Date:
            </label>
            <input
              id="editFromDate"
              type="date"
              value={selectedLeaveRequest?.fromdate || ""}
              onChange={handleFromDateChange}
              style={{ marginBottom: "10px", width: "100%" }}
            />
          </div>
          <div>
            <label
              htmlFor="editToDate"
              style={{
                fontWeight: 600,
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              To Date:
            </label>
            <input
              id="editToDate"
              type="date"
              value={selectedLeaveRequest?.todate || ""}
              onChange={(e) => {
                setSelectedLeaveRequest({
                  ...selectedLeaveRequest,
                  todate: e.target.value,
                });
                // Calculate number of days between fromdate and todate
                const fromDate = new Date(selectedLeaveRequest?.fromdate);
                const toDate = new Date(e.target.value);
                const differenceInTime = toDate.getTime() - fromDate.getTime();
                const differenceInDays = differenceInTime / (1000 * 3600 * 24);
                setNoOfDays(differenceInDays);
              }}
              min={selectedLeaveRequest?.fromdate || ""}
            />
          </div>
          <div>
            <label
              htmlFor="editNoOfDays"
              style={{
                fontWeight: 600,
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              No Of Days:
            </label>
            <input
              id="editNoOfDays"
              type="text"
              value={noOfDays || ""}
              onChange={(e) => setNoOfDays(e.target.value)}
              style={{ marginBottom: "10px", width: "100%" }}
              disabled
            />
          </div>
          <div>
            <label
              htmlFor="editLeaveType"
              style={{
                fontWeight: 600,
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              Time Off:
            </label>
            <Dropdown
              id="leave_request_type"
              options={timeOff}
              value={selectedLeaveRequest?.leave_request_type || ""}
              onChange={(e) =>
                setSelectedLeaveRequest({
                  ...selectedLeaveRequest,
                  leave_request_type: e.value,
                })
              }
              placeholder="Select Time off"
              style={{ marginBottom: "10px", fontWeight: 600, width: "100%" }}
            />
          </div>
          <div>
            <label
              htmlFor="editLeaveType"
              style={{
                fontWeight: 600,
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              Leave Type:
            </label>
            <Dropdown
              id="editLeaveType"
              options={leaveTypes}
              value={selectedLeaveRequest?.leavetype || ""}
              onChange={(e) =>
                setSelectedLeaveRequest({
                  ...selectedLeaveRequest,
                  leavetype: e.value,
                })
              }
              placeholder="Select Leave Type"
              style={{ marginBottom: "10px", fontWeight: 600, width: "100%" }}
            />
          </div>
          <div>
            <label
              htmlFor="editStatus"
              style={{
                fontWeight: 600,
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              Status:
            </label>
            <Dropdown
              id="editStatus"
              options={statusOptions}
              value={selectedLeaveRequest?.status || ""}
              onChange={(e) =>
                setSelectedLeaveRequest({
                  ...selectedLeaveRequest,
                  status: e.value,
                })
              }
              placeholder="Select Status"
              style={{ marginBottom: "10px", fontWeight: 600, width: "100%" }}
            />
          </div>
          <div
            className="p-dialog-footer text-center flex justify-content-end"
            style={{ padding: 0, marginTop: "4px" }}
          >
            <Button
              style={{
                padding: "12px 12px",
                fontWeight: 500,
                fontSize: "13px",
              }}
              label="Cancel"
              className="p-button-danger"
              onClick={() => setShowEditModal(false)}
            />
            <Button
              style={{
                padding: "10px 10px",
                fontWeight: 500,
                fontSize: "13px",
              }}
              label="Submit"
              className="p-button-success"
              onClick={handleSaveLeaveType}
            />
          </div>
        </Dialog>
      )}
    </div>
  );
}