import { useEffect, useState } from "react";
import axios from "axios";
import {
  PAYROLL_API,
  PAY_HEAD_API,
  DEPARTMENT_API,
  ATTENDANCE_API,
} from "../../constants/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import logo from "../../assets/images/KodukkuLogoBorderNone.png";
import html2pdf from "html2pdf.js";
import numToWords from "num-to-words";
import moment from "moment";

const UserPaySlip = () => {
  const [payrollDetails, setPayrollDetails] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [payheadDetails, setPayheadDetails] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [date, setDate] = useState(null);
  const [totalDaysMissing, setTotalDaysMissing] = useState(0);
  const [totalDays, setTotalDays] = useState([]);

  const monthStrings = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleDateChange = (e) => {
    const selectedDate = e.value;
    if (selectedDate) {
      setSelectedMonth(monthStrings[selectedDate.getMonth()]);
      setSelectedYear(selectedDate.getFullYear());
    }
  };
  const getPayheadName = (payheadId) => {
    const payhead = payheadDetails.find(
      (payhead) => payhead.payhead_id === payheadId
    );
    return payhead ? payhead.payhead_name : "Unknown Payhead";
  };
  const fetchPayrollDetails = async () => {
    try {
      const storedData = JSON.parse(localStorage.getItem("user"));
      const userId = storedData?.data.id;
      if (!userId) {
        console.error("User ID not found in localStorage");
        return;
      }
      if (!selectedMonth || !selectedYear) {
        setPayrollDetails(null);
        return;
      }
      setUserDetails(storedData?.data);
      if (selectedMonth && selectedYear) {
        const response = await axios.get(
          PAYROLL_API.GET_PAYROLL_BY_YEAR_AND_MONTH(
            userId,
            selectedYear,
            selectedMonth
          )
        );
        setPayrollDetails(response.data.payrollDetails);
      }
    } catch (error) {
      console.error("Error fetching payroll details:", error);
      setPayrollDetails(null);
    }
  };
  const fetchAllPayheads = async () => {
    try {
      const response = await axios.get(PAY_HEAD_API.GET_ALL_PAYHEAD);
      setPayheadDetails(response.data);
    } catch (error) {
      console.error("Error fetching payheads:", error);
    }
  };
  useEffect(() => {
    fetchAllPayheads();
  }, []);

  useEffect(() => {
    fetchPayrollDetails();
  }, [selectedMonth, selectedYear]);

  const getAllDepartment = async () => {
    try {
      const response = await axios.get(DEPARTMENT_API.DEPARTMENT_FETCH);
      setDepartmentData(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  useEffect(() => {
    getAllDepartment();
  }, []);

  const fetchAttendanceData = async () => {
    const { empid, from_date, to_date } = payrollDetails;
    try {
      const response = await axios.get(ATTENDANCE_API.ATTENDANCE_PAYROLL_GET(empid), {
        params: {
          fromDate: from_date,
          toDate: to_date,
        },
      });
      const TotalDays = response.data.totalDays || 0;
      setTotalDays(TotalDays);
      const totalDaysMissing = response.data.totalDaysMissing || 0;
      if (
        (selectedMonth === "December" && selectedYear === 2023) ||
        (selectedMonth === "January" && selectedYear === 2024) ||
        (selectedMonth === "February" && selectedYear === 2024) ||
        (selectedMonth === "March" && selectedYear === 2024) ||
        (selectedMonth === "April" && selectedYear === 2024) ||
        (selectedMonth === "May" && selectedYear === 2024) ||
        (selectedMonth === "June" && selectedYear === 2024)
      ) {
        totalDaysMissing = 0;
      }
      setTotalDaysMissing(totalDaysMissing);
    } catch (error) {
      console.error("Error fetching attendance data:", error.message);
    }
  };

  useEffect(() => {
    if (payrollDetails) {
      fetchAttendanceData();
    }
  }, [payrollDetails]);

  const downloadPdf = () => {
    const element = document.getElementsByClassName("payslipmodal")[0];
    const fileName = "Employee_PaySlip.pdf";

    element.style.padding = "1in";

    html2pdf(element, {
      filename: fileName,
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    });

    element.style.padding = "0";
  };

  const netpayInWords = numToWords(Number(payrollDetails?.netpay)).replace(
    /^\w/,
    (c) => c.toUpperCase()
  );

  return (
    <div>
      <div className="payslip">
        <div className="payslip-header">
          <div
            className="icons m-3 flex justify-content-end"
            style={{
              borderBottom: "2px solid #f0f0f0",
              paddingBottom: "20px",
            }}
          >
            <Calendar
              value={date}
              onChange={(e) => {
                setDate(e.value);
                handleDateChange(e);
              }}
              view="month"
              dateFormat="mm/yy"
              style={{ width: "10%" }}
              className="mr-3"
              placeholder="Month"
            />
            <Button
              onClick={downloadPdf}
              icon="fi fi-rr-download"
              className="px-3 btn-button"
              style={{
                height: "40px",
                background: "#3694ED",
                border: "#3694ED",
              }}
            ></Button>
          </div>
        </div>
      </div>
      <div className="card" style={{ padding: "15px 20px " }}>
        <div className="payslipmodal px-3 mt-6">
          <div
            className="modal-headers flex justify-content-center"
            id="modal-headers"
          >
            <div className="flex payslip">
              <img src={logo} alt="" style={{ width: "5rem" }} />
              <div className="head ml-4">
                <h1 className="m-head" style={{ color: "#3694ED" }}>
                  Kodukku Classifieds Private Limited
                </h1>
                <p className="m-addres " style={{ fontSize: "14px" }}>
                  253 Thanthai Periyar Nagar, Pallikaranai, Chennai, Tamil Nadu
                  600100
                </p>
              </div>
            </div>
          </div>
          {payrollDetails && (
            <div className="m-body">
              <h4 className="text-center my-4 fs-5">
                Payslip {payrollDetails?.month} {payrollDetails?.year}
              </h4>
              <h4 className="ml-2">EMPLOYEE DETAILS</h4>
              <div className="md:flex mt-3 block">
                <div className="md:col-6 col-12">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td
                          className="border"
                          style={{
                            textTransform: "capitalize",
                            borderSpacing: "0",
                          }}
                        >
                          {userDetails?.firstname} {userDetails?.lastname}
                        </td>
                      </tr>
                      <tr>
                        <td className="top">Employee Id</td>
                        <td className="border">{payrollDetails?.empid}</td>
                      </tr>
                      <tr>
                        <td>Department</td>
                        <td style={{ textTransform: "capitalize" }}>
                          {departmentData.length > 0 &&
                            payrollDetails?.employeeDetails?.department &&
                            departmentData.find(
                              (dept) =>
                                dept.department_id ===
                                parseInt(
                                  payrollDetails.employeeDetails.department
                                )
                            )?.department_name}
                        </td>
                      </tr>
                      <tr>
                        <td>Designation</td>
                        <td style={{ textTransform: "capitalize" }}>
                          {payrollDetails?.employeeDetails?.position}
                        </td>
                      </tr>
                      <tr>
                        <td className="top">Joining Date</td>
                        <td className="border">
                          {payrollDetails?.employeeDetails?.joining_date
                            ? moment(
                              payrollDetails.employeeDetails.joining_date
                            ).format("DD-MM-YYYY")
                            : "N/A"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="md:col-6 col-12">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="top">Bank Name</td>
                        <td
                          className="border"
                          style={{ textTransform: "capitalize" }}
                        >
                          {payrollDetails?.employeeDetails?.bank_name}
                        </td>
                      </tr>
                      <tr>
                        <td className="top">Bank Account No</td>
                        <td className="border">
                          {payrollDetails?.employeeDetails?.account_number}
                        </td>
                      </tr>
                      <tr>
                        <td>Total Days</td>
                        <td>{totalDays} DAYS</td>
                      </tr>

                      <tr>
                        <td>Absent Days</td>
                        <td>{totalDaysMissing} DAYS</td>
                      </tr>
                      <tr>
                        <td>Pay Date</td>
                        <td>
                          {moment(payrollDetails?.from_date).format("DD-MM-YYYY")}
                          {" to "}
                          {moment(payrollDetails?.to_date).format("DD-MM-YYYY")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="md:flex mt-3 block">
                <div className="md:col-6 col-12">
                  <table className="tables">
                    <thead>
                      <tr>
                        <th className="t-l">EARNINGS</th>
                        <th className="t-r">AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payrollDetails &&
                        payrollDetails.payroll_earnings.map((earning) => (
                          <tr key={earning.payroll_earnings_id}>
                            <td style={{ textTransform: "capitalize" }}>
                              {getPayheadName(earning.payhead_id)}
                            </td>
                            <td>
                              {Number(earning.payhead_amount)?.toLocaleString(
                                "en-IN"
                              )}
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td className="b-l">Total</td>
                        <td className="b-r">
                          {Number(
                            payrollDetails?.total_earnings
                          )?.toLocaleString("en-IN")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="md:col-6 col-12">
                  <table className="tables">
                    <thead>
                      <tr>
                        <th className="t-l">DEDUCTIONS</th>
                        <th className="t-r">AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payrollDetails &&
                        payrollDetails.payroll_deductions.map((deduction) => (
                          <tr key={deduction.payroll_deductions_id}>
                            <td style={{ textTransform: "capitalize" }}>
                              {getPayheadName(deduction.payhead_id)}
                            </td>
                            <td>
                              {Number(
                                deduction?.payhead_amount
                              )?.toLocaleString("en-IN")}
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td className="b-l">Total</td>
                        <td className="b-r">
                          {Number(
                            payrollDetails?.total_deductions
                          )?.toLocaleString("en-IN")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className=""
                style={{
                  border: " 2px solid #f3f3f3",
                  padding: "6px 15px",
                  marginTop: "1rem",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "8px",
                  marginRight: "28px",
                  marginLeft: "8px",
                }}
              >
                <div>
                  <h4 className="">TOTAL NET PAYABLE</h4>
                  <p>Gross Earnings - Total Deductions</p>
                </div>
                <p
                  className=""
                  style={{ fontSize: "1.3rem", fontWeight: "600" }}
                >
                  ₹{Number(payrollDetails?.netpay)?.toLocaleString("en-IN")}
                </p>
              </div>
              <div className="flex justify-content-end mt-4 mr-6">
                <p className="payslip-total">
                  Amount In Words:{" "}
                  <span className="font-semibold">{netpayInWords}</span> Only
                </p>
              </div>
              <hr
                className=""
                style={{
                  marginRight: "31px",
                  marginLeft: "10px",
                  backgroundColor: "#F3F3F3",
                  height: "3px",
                  border: "none",
                }}
              />
            </div>
          )}
        </div>
      </div>
      {!payrollDetails && (
        <div className="text-center mt-3">
          <p>No data available. Please select a date.</p>
        </div>
      )}
    </div>
  );
};

export default UserPaySlip;
