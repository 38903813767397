 
 import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  TASK_API,
  TASK_QUERY_API,
  TASK_File_UPLOAD,
} from "../../constants/api";
import axios from "axios";
import moment from "moment";

const tableHeaderStyle = {
  border: "1px solid black",
  backgroundColor: "#f2f2f2",
  padding: "8px",
  textAlign: "center",
};
function DocumnetDetails() {
  const [filenames, setFilenames] = useState([]);
  const toast = useRef(null);
  const [creater, setCreater] = useState([]);
  const [startDate, setStartDate] = useState([]);
  const [createrEmployee, setCreaterEmployee] = useState([]);
  const [uploadedDate, setuploadedDate] = useState([]);
  const [employeeFileName, setemployeeFileName] = useState([]);
  const [fileDetails, setFileDetails] = useState([]);
  const [adminCreater, setAdminCreater] = useState([]);
  const [adminStartDate, setAdminStartDate] = useState([]);
  const [adminFileName, setAdminFileName] = useState([]);
  const { taskId } = useParams();
  const fetchFilenames = async () => {
    try {
      const response = await axios.get(TASK_API.GET_TASK_DOCUMENT(taskId));
      const data = response.data;
      setFilenames(data.filenames);
      setCreater(data.creater);
      setStartDate(data.StartDate);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFilenames();
  }, []);
  const fetchFileDetails = async () => {
    try {
      const response = await axios.get(
        TASK_QUERY_API.GET_TASK_DOCUMENT_USER(taskId)
      );
      const data = response.data;
      setemployeeFileName(data.filenames);
      setCreaterEmployee(data.creater);
      setuploadedDate(data.UploadDate);
    } catch (error) {
      console.error("Error fetching task details:", error);
    }
  };
  useEffect(() => {
    fetchFileDetails();
  }, [taskId]);

  const fetchAdminFileDetails = async () => {
    try {
      const response = await axios.get(
        TASK_File_UPLOAD.GET_TASK_DOCUMENT_USER(taskId)
      );
      const data = response.data;
      setAdminFileName(data.filenames);
      setAdminCreater(data.creater);
      setAdminStartDate(data.UploadDate);
      setFileDetails(response.data);
    } catch (error) {
      console.error("Error fetching task details:", error);
    }
  };

  useEffect(() => {
    fetchAdminFileDetails();
  }, [taskId]);

  const getFolderFromFilename = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
      return "Images";
    } else if (["pdf", "doc", "docx", "txt"].includes(extension)) {
      return "OnlyPDF";
    } else if (["mp3", "wav", "ogg", "aac"].includes(extension)) {
      return "OnlyAudio";
    } else if (["mp4", "avi", "mkv", "mov"].includes(extension)) {
      return "OnlyVideo";
    } else {
      return "common";
    }
  };
  const fetchFileUrl = async (filename) => {
    try {
      const response = await axios.get(
        TASK_API.DOWNLOAD_FILE(getFolderFromFilename(filename), filename),
        {
          responseType: "blob",
        }
      );
      const contentType = response.headers["content-type"];

      if (contentType && contentType.includes("application/json")) {
        const data = response.data;
        return data.fileUrl;
      } else {
        const blob = new Blob([response.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        return url;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleDownload = async (filename) => {
    try {
      const fileUrl = await fetchFileUrl(filename);
      if (fileUrl) {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEmployeeFileUrl = async (filename) => {
    try {
      const response = await axios.get(
        TASK_QUERY_API.DOWNLOAD_FILE_USER(
          getFolderFromFilename(filename),
          filename
        ),
        {
          responseType: "blob",
        }
      );
      const contentType = response.headers["content-type"];

      if (contentType && contentType.includes("application/json")) {
        const data = response.data;
        return data.fileUrl;
      } else {
        const blob = new Blob([response.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        return url;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleEmployeeFileDownload = async (filename) => {
    try {
      const fileUrl = await fetchEmployeeFileUrl(filename);
      if (fileUrl) {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAdminFileUrl = async (filename) => {
    try {
      const response = await axios.get(
        TASK_File_UPLOAD.DOWNLOAD_FILE_USER(
          getFolderFromFilename(filename),
          filename
        ),
        {
          responseType: "blob",
        }
      );
      const contentType = response.headers["content-type"];

      if (contentType && contentType.includes("application/json")) {
        const data = response.data;
        return data.fileUrl;
      } else {
        const blob = new Blob([response.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        return url;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleAdminFileDownload = async (filename) => {
    try {
      const fileUrl = await fetchAdminFileUrl(filename);
      if (fileUrl) {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h3 className="Text-center mt-4 mb-2">Task Document</h3>
      <div>
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Start Date</th>
              <th style={tableHeaderStyle}>Creater</th>
              <th style={tableHeaderStyle}>File Name</th>
              <th style={tableHeaderStyle}>Action</th>
            </tr>
          </thead>
          <tbody>
            {filenames?.map((filename, index) => (
              <tr key={index}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "100px",
                  }}
                >
                  {moment(startDate).format("DD-MM-YYYY")}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "200px",
                  }}
                >
                  {creater}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "600px",
                  }}
                >
                  {filename}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  <button
                    style={{
                      background: "green",
                      color: "white",
                      border: "1px solid green",
                      paddingTop: "7px",
                      paddingBottom: "6px",
                      borderRadius: "5px",
                    }}
                    onClick={() => handleDownload(filename)}
                  >
                    <i
                      className="fi fi-rs-down-to-line"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Download
                  </button>
                </td>
              </tr>
            ))}

            {employeeFileName?.map((filename, index) => (
              <tr key={index}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "100px",
                  }}
                >
                  {uploadedDate}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "200px",
                  }}
                >
                  {createrEmployee}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "600px",
                  }}
                >
                  {filename}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  <button
                    style={{
                      background: "green",
                      color: "white",
                      border: "1px solid green",
                      paddingTop: "7px",
                      paddingBottom: "6px",
                      borderRadius: "5px",
                    }}
                    onClick={() => handleEmployeeFileDownload(filename)}
                  >
                    <i
                      className="fi fi-rs-down-to-line"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Download
                  </button>
                </td>
              </tr>
            ))}
            {adminFileName?.map((filename, index) => (
              <tr key={index}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "100px",
                  }}
                >
                  {adminStartDate}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "200px",
                  }}
                >
                  {adminCreater}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    width: "600px",
                  }}
                >
                  {adminFileName}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  <button
                    style={{
                      background: "green",
                      color: "white",
                      border: "1px solid green",
                      paddingTop: "7px",
                      paddingBottom: "6px",
                      borderRadius: "5px",
                    }}
                    onClick={() => handleAdminFileDownload(filename)}
                  >
                    <i
                      className="fi fi-rs-down-to-line"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Download
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DocumnetDetails;
