import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import axios from "axios";
import Select from "react-select";
import { APPOINTMENTS_API, EMPLOYEE_API } from "../../constants/api";
import moment from "moment";

const EditAppointmentDialog = ({
  visible,
  onHide,
  appointmentData,
  reFetch,
}) => {
  const [formCompleted, setFormCompleted] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState({
    appointment_date: new Date(appointmentData.appointment_date),
    appointment_title: appointmentData.appointment_title,
    appointment_time: appointmentData.appointment_time,
    appointment_description: appointmentData.appointment_description,
    employee_ids: appointmentData.employee_ids.split(","),
  });
  const [employees, setEmployees] = useState([]);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.All_USERS);
      const { usersData } = response.data;
      setEmployees(usersData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const updateAppointment = async () => {
    try {
      const employeeIdsString = appointmentDetails.employee_ids.join(",");
      const updatedDetails = {
        ...appointmentDetails,
        employee_ids: employeeIdsString,
      };
      const response = await axios.put(
        APPOINTMENTS_API.APPOINTMENTS_UPDATE(appointmentData.appointment_id),
        updatedDetails
      );
      reFetch();
    } catch (error) {
      console.error("Error updating appointment:", error);
    }
  };

  useEffect(() => {
    const isFormCompleted =
      appointmentDetails.appointment_date &&
      appointmentDetails.appointment_title &&
      appointmentDetails.appointment_time &&
      appointmentDetails.appointment_description &&
      appointmentDetails.employee_ids.length > 0;
    setFormCompleted(isFormCompleted);
  }, [appointmentDetails]);

  const handleEmployeeSelectChange = (selectedOptions) => {
    setAppointmentDetails({
      ...appointmentDetails,
      employee_ids: selectedOptions.map((option) => option.value),
    });
  };

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Edit Appointment"
      modal
      closable={false}
      style={{ width: "40rem" }}
      footer={
        <div>
          <Button
            style={{ color: "white", background: "red" }}
            label="Cancel"
            icon="pi pi-times"
            className="p-button-text"
            onClick={onHide}
          />
          <Button
            style={{
              color: "white",
              background: "#22c55e",
            }}
            label="Save"
            icon="pi pi-check"
            className="p-button-text"
            onClick={() => {
              updateAppointment();
              onHide();
            }}
            disabled={!formCompleted}
          />
        </div>
      }
    >
      <div className="p-grid p-fluid">
        <div className="p-col-4">
          <label htmlFor="appointment_title">Appointment Title</label>
          <InputText
            id="appointment_title"
            value={appointmentDetails.appointment_title}
            onChange={(e) =>
              setAppointmentDetails({
                ...appointmentDetails,
                appointment_title: e.target.value,
              })
            }
          />
        </div>
        <div className="p-col-12">
          <label htmlFor="description">Description</label>
          <InputTextarea
            id="description"
            value={appointmentDetails.appointment_description}
            onChange={(e) =>
              setAppointmentDetails({
                ...appointmentDetails,
                appointment_description: e.target.value,
              })
            }
          />
        </div>
        {employees.length > 0 && (
          <div className="p-col-12">
            <label htmlFor="employees">Select participants</label>
            <Select
              value={(appointmentDetails.employee_ids || []).map((empId) => ({
                label: `${employees.find((emp) => emp.id === empId)?.firstname ?? ""
                  } ${employees.find((emp) => emp.id === empId)?.lastname ?? ""}`,
                value: empId,
              }))}
              onChange={handleEmployeeSelectChange}
              options={employees.map((emp) => ({
                label: `${emp.firstname} ${emp.lastname}`,
                value: emp.id,
              }))}
              isMulti
              isClearable
              placeholder="Select team members"
              getOptionLabel={(option) => (
                <div className="flex align-items-center">{option.label}</div>
              )}
            />
          </div>
        )}
        <div className="p-col-4">
          <label htmlFor="appointment_date">Appointment Date</label>
          <InputText
            id="appointment_date"
            value={appointmentDetails.appointment_date.toISOString().split("T")[0]}
            onChange={(e) =>
              setAppointmentDetails({
                ...appointmentDetails,
                appointment_date: new Date(e.target.value),
              })
            }
            type="date"
            min={moment().format('YYYY-MM-DD')}
          />
        </div>
        <div className="p-col-4">
          <label htmlFor="time">Time</label>
          <InputText
            id="time"
            value={appointmentDetails.appointment_time}
            onChange={(e) =>
              setAppointmentDetails({
                ...appointmentDetails,
                appointment_time: e.target.value,
              })
            }
            type="time"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default EditAppointmentDialog;
