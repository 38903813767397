import axios from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import React, { useRef, useState } from 'react';
import { AUTH_API } from '../../constants/api';
import { Toast } from 'primereact/toast';

const ChangePassword = ({ passwordView, setPasswordView }) => {
    const [password, setPassword] = useState({
        old_password: '',
        new_password: '',
        confirm_password: ''
    });

    const [errors, setErrors] = useState({
        old_password: false,
        new_password: false,
        confirm_password: false
    });

    const toast = useRef(null);

    const showToast = (severity, detail) => {
        toast.current.clear();
        toast.current.show({ severity, detail });
    };

    const handleSave = async () => {
        try {
            const userDataString = localStorage.getItem("user");
            if (!userDataString) {
                throw new Error("User data not found");
            }
            const userData = JSON.parse(userDataString);
            const userId = userData.data.id;

            const { data } = await axios.put(AUTH_API.PASSWORD_CHANGE(userId), {
                old_password: password.old_password,
                new_password: password.new_password
            });

            showToast("success", "Password Changed Successfully!");
            setPasswordView(false);
            resetForm();

        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                showToast("error", error.response.data.error);
            } else {
                console.error("Error changing password:", error);
                showToast("error", "Failed to change password");
            }
        }
    };

    const resetForm = () => {
        setPassword({
            old_password: '',
            new_password: '',
            confirm_password: ''
        });

        setErrors({
            old_password: false,
            new_password: false,
            confirm_password: false
        });
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        if (!password.old_password) {
            newErrors.old_password = true;
            valid = false;
        }

        if (!password.new_password) {
            newErrors.new_password = true;
            valid = false;
        }

        if (!password.confirm_password || password.new_password !== password.confirm_password) {
            newErrors.confirm_password = true;
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleChangeOldPassword = (e) => {
        setPassword(prevPassword => ({
            ...prevPassword,
            old_password: e.target.value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            old_password: false
        }));
    };

    const handleChangeNewPassword = (e) => {
        setPassword(prevPassword => ({
            ...prevPassword,
            new_password: e.target.value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            new_password: false
        }));
    };

    const handleChangeConfirmPassword = (e) => {
        const value = e.target.value;
        validateForm();
        setPassword(prevPassword => ({
            ...prevPassword,
            confirm_password: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            confirm_password: false
        }));
    };

    return (
        <div>
            <Toast ref={toast} />
            <Dialog visible={passwordView}
                style={{ width: '34rem' }} onHide={() => { setPasswordView(false); resetForm(); }} draggable={false} position="center" header={<span>
                    <i class="fi fi-bs-key"></i>{" "}Reset Password
                </span>}>
                <div className="my-3">
                    <label htmlFor="old_password">Old Password </label>
                    <div className="p-inputgroup">
                        <Password
                            id={"old_password"}
                            className="w-full"
                            placeholder="Old Password"
                            toggleMask
                            feedback={false}
                            value={password.old_password}
                            onChange={handleChangeOldPassword}
                        />
                    </div>
                    {errors.old_password && <small className="text-red-500">Old Password is required</small>}
                </div>
                <div className="my-3">
                    <label htmlFor="new_password">New Password </label>
                    <div className="p-inputgroup">
                        <Password
                            id={"new_password"}
                            className="w-full"
                            placeholder="New Password"
                            toggleMask
                            feedback={false}
                            value={password.new_password}
                            onChange={handleChangeNewPassword}
                        />
                    </div>
                    {errors.new_password && <small className="text-red-500">New Password is required</small>}
                </div>
                <div className="my-3">
                    <label htmlFor="confirm_password">Confirm Password </label>
                    <div className="p-inputgroup">
                        <Password
                            id={"confirm_password"}
                            className="w-full"
                            placeholder="Confirm Password"
                            toggleMask
                            feedback={false}
                            value={password.confirm_password}
                            onChange={handleChangeConfirmPassword}
                        />
                    </div>
                    {errors.confirm_password && <small className="text-red-500">Passwords do not match</small>}
                </div>
                <div className="mt-5 flex justify-content-end gap-3">
                    <Button
                        label="Cancel"
                        className="p-button-danger"
                        icon="pi pi-times"
                        onClick={() => { setPasswordView(false); resetForm(); }}
                    />
                    <Button
                        label="Save"
                        icon="pi pi-check"
                        className=""
                        onClick={() => validateForm() && handleSave()}
                        disabled={!password.old_password || !password.new_password || !password.confirm_password}
                    />
                </div>
            </Dialog>
        </div>
    );
};

export default ChangePassword;
