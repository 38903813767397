import React, { useState } from "react";
import { TabMenu } from "primereact/tabmenu";
import Projects from "./Project";
import CompletedProjects from "./CompletedProject";

const TabMenuComponent = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const menuItems = [
    {
      label: "Running Projects",
      icon: "fi fi-rr-book-user",
      command: () => setActiveIndex(0),
    },
    {
      label: "Completed Projects",
      icon: "fi fi-bs-check-circle",
      command: () => setActiveIndex(1),
    },
  ];

  const renderContent = () => {
    switch (activeIndex) {
      case 0:
        return <Projects />;
      case 1:
        return <CompletedProjects />;
      default:
        return null;
    }
  };

  return (
    <div className="card">
      <TabMenu
        model={menuItems}
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      />
      {/* <div className='card'> */}
      {renderContent()}
    </div>
  );
};

export default TabMenuComponent;
