import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const Education = ({ educationInfo }) => {
  const renderValue = (rowData, field) => {
    return rowData[field] ? rowData[field] : "-";
  };

  return (
    <div>
      <DataTable
        value={educationInfo}
        className=""
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50]}
        emptyMessage="No data available">
        <Column header="S.No" body={(rowData) => <span>{educationInfo.indexOf(rowData) + 1}</span>} />
        <Column field="collegename" header="College Name" body={(rowData) => renderValue(rowData, 'collegename')} />
        <Column field="rollno" style={{ minWidth: '120px' }} header="Roll No" body={(rowData) => renderValue(rowData, 'rollno')} />
        <Column field="university" header="University" body={(rowData) => renderValue(rowData, 'university')} />
        <Column field="collegedegree" header="Degree" body={(rowData) => renderValue(rowData, 'collegedegree')} />
        <Column field="collegespecialization" header="Specialization" body={(rowData) => renderValue(rowData, 'collegespecialization')} />
        <Column field="collegestartYear" style={{ minWidth: '120px' }} header="Start Year" body={(rowData) => renderValue(rowData, 'collegestartYear')} />
        <Column field="collegeendYear" style={{ minWidth: '120px' }} header="End Year" body={(rowData) => renderValue(rowData, 'collegeendYear')} />
        <Column field="collegepercentage" header="Percentage" body={(rowData) => renderValue(rowData, 'collegepercentage')} />
        <Column field="collegesection" header="Section" body={(rowData) => renderValue(rowData, 'collegesection')} />
        <Column field="education" header="Other Education" body={(rowData) => renderValue(rowData, 'education')} />
        <Column field="otherDegree" header="Other Degree" body={(rowData) => renderValue(rowData, 'otherDegree')} />
        <Column field="otherSpecialization" header="Other Specialization" body={(rowData) => renderValue(rowData, 'otherSpecialization')} />
        <Column field="otherUniversity" header="Other University" body={(rowData) => renderValue(rowData, 'otherUniversity')} />
      </DataTable>
    </div>
  );
}

export default Education;
