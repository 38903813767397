import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { ASSETS_API } from '../../../../constants/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

const Assets = ({ userId }) => {
    const [globalFilter, setGlobalFilter] = useState('');
    const [requests, setRequests] = useState([]);
    const toast = useRef(null)

    const fetchData = async () => {
        axios.get(ASSETS_API.ASSETS_FETCH(userId))
            .then(response => {
                setRequests(response.data);
            })
            .catch(error => {
                console.error('Error fetching requests:', error);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch requests', life: 3000 });
            });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <Toast ref={toast} />
            <div className="flex block justify-content-end align-items-center mb-2">
                <div className="w-15rem">
                    <div className="p-inputgroup">
                        <InputText type="text" id="globalFilter" className="search-input" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
                        <Button icon="fi fi-bs-search" className="search-icon" />
                    </div>
                </div>
            </div>
            <div className="">
                <DataTable value={requests} paginator rows={10} rowsPerPageOptions={[10, 25, 50]} className="p-datatable-customers" globalFilter={globalFilter.trim()} emptyMessage="No requests found">
                    <Column header="S.No" body={(rowData) => <span>{requests.indexOf(rowData) + 1}</span>} />
                    <Column field="assetname" style={{ minWidth: '120px' }} sortable header="Asset" />
                    <Column field="assetcategory" style={{ minWidth: '120px' }} header="Category" body={(rowData) => rowData.assetcategory || '-'} />
                    <Column field="serialnumber" style={{ minWidth: '120px' }} header="Serial" body={(rowData) => rowData.serialnumber || '-'} />
                    <Column field="issuedate" style={{ minWidth: '120px' }} header="Issued" body={rowData => rowData.issuedate ? moment(rowData?.issuedate)?.format("DD-MM-YYYY") : '-'} />
                    <Column field="returndate" style={{ minWidth: '120px' }} header="Return" body={rowData => rowData.returndate ? moment(rowData.returndate).format("DD-MM-YYYY") : '-'} />
                </DataTable>
            </div>
        </div>
    );
};
export default Assets
