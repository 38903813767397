import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { LOCAL_CONSTANTS } from "../constants/localConstants";
import { getLocalStorage, setLocalStorage } from "../utills";

const mutex = new Mutex();

export const resetAuth = () => {
  localStorage.removeItem(LOCAL_CONSTANTS.REFRESH);
  localStorage.removeItem(LOCAL_CONSTANTS.ACCESS);
  window.location.href = "/";
};

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers) => {
    const token = getLocalStorage(LOCAL_CONSTANTS.ACCESS);
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshToken = getLocalStorage(LOCAL_CONSTANTS.REFRESH);

        if (refreshToken) {
          // try to get a new token
          const refreshResult = await baseQuery(
            {
              url: "/auth/refreshToken",
              method: "POST",
              body: { refreshToken },
            },
            api,
            extraOptions
          );
          if (refreshResult.data) {
            const userWithTokens = refreshResult.data.data;
            setLocalStorage(LOCAL_CONSTANTS.ACCESS, userWithTokens.accessToken);
            setLocalStorage(
              LOCAL_CONSTANTS.REFRESH,
              userWithTokens.refreshToken
            );
          } else {
            resetAuth();
          }
        }
      } catch (error) {
        console.log(error); // eslint-disable-line
        resetAuth();
      } finally {
        release();
      }
    }
    console.log(args, api, extraOptions, "base"); // eslint-disable-line

    // retry the initial query
    result = await baseQuery(args, api, extraOptions);
  }
  return result;
};

export default baseQueryWithReauth;
