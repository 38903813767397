import React, { useState } from "react";
import resetPassword from "../../assets/images/resetpassword.jpg";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import kodukkuLogo from "../../assets/images/Logo.svg";
import { InputOtp } from "primereact/inputotp";
import { Button } from "primereact/button";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [editEmailView, setEditEmailView] = useState(true);
  const [token, setTokens] = useState("");

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    validateEmail(value);
  };

  const validateEmail = (value) => {
    if (!value.trim()) {
      setEmailError("Please enter your email");
      setValidEmail(false);
    } else {
      setEmailError("");
      setValidEmail(true);
    }
  };
  const [otpView, setOtpView] = useState(false);

  const handleResetPassword = () => {
    validateEmail(email);
    if (validEmail) {
      setOtpView(true);
      setTokens("");
    }
  };

  const handleVerify = () => {
    setEditEmailView(false);
  };

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        height: "100vh",
        placeContent: "center",
      }}
      className="p-4 sm:p-3 block sm:flex"
    >
      {/* left */}
      <div className="hidden sm:block" style={{ flex: "1 1 20rem" }}>
        <img
          src={resetPassword}
          alt="reset password"
          style={{ width: "100%" }}
        />
      </div>
      {/* right */}
      <div
        className="md:ml-5 "
        style={{
          flex: "1 1 20rem",
        }}
      >
        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <div>
            <div
              className="cursor-pointer"
              style={{
                border: "2px solid rgb(104, 188, 255)",
                borderRadius: "10px",
                padding: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <img
                src={kodukkuLogo}
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <h5 style={{ color: "rgb(104, 188, 255)" }}>Kodukku</h5>
            </div>
          </div>

          <div className="">
            <h2>Reset Password</h2>
            <h3 style={{ color: "#aaa", fontWeight: "400" }}>
              Let Us Help You
            </h3>
          </div>
        </div>

        <div style={{ marginTop: "4rem" }}>
          <p style={{ color: "gray", textAlign: "center" }}>
            Enter your registered email address
          </p>
          <div
            style={{ margin: "8px auto", minWidth: "150px", maxWidth: "400px" }}
          >
            <div className="p-inputgroup flex-1">
              <InputText
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email"
                style={{ borderColor: emailError ? "red" : "" }}
                disabled={otpView ? true : false}
              />
              <Button
                label="Get OTP"
                className="bg-blue-500"
                onClick={handleResetPassword}
                style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}
                disabled={otpView}
              />
            </div>
            {emailError && <small style={{ color: "red" }}>{emailError}</small>}
          </div>
        </div>

        {otpView && editEmailView && (
          <div className="grid mt-2">
            <div className=" col-5 xl:col-offset-3">
              <small
                className="cursor-pointer"
                onClick={() => setOtpView(false)}
              >
                <i class="fi fi-br-pencil"></i> Edit email
              </small>
            </div>
          </div>
        )}

        {otpView && (
          <div className="justify-content-center  flex">
            <div
              className="my-4 flex flex-column justify-content-center gap-3 "
              style={{ minWidth: "150px", maxWidth: "400px" }}
            >
              <p style={{ color: "gray", textAlign: "center" }}>
                Enter your OTP
              </p>
              <InputOtp
                value={token}
                onChange={(e) => setTokens(e.value)}
                length={6}
              />

              <button
                className="bg-blue-500 hover:bg-blue-400 text-white border-none cursor-pointer  border-round"
                style={{ whiteSpace: "nowrap", padding: "10px" }}
                onClick={handleVerify}
              >
                Verify
              </button>
            </div>
          </div>
        )}

        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Link className="resetLogin mt-2" to={"/login"}>
            <span className="text-blue-400 hover:text-blue-300">Login?</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
