export const DesignationOptions = [
  { value: "Frontend Developer", label: "Frontend Developer" },
  { value: "React Developer", label: "React Developer" },
  { value: "Full Stack Developer", label: "Full Stack Developer" },
  { value: "UI/UX Designer", label: "UI/UX Designer" },
  { value: "Web Developer", label: "Web Developer" },
  { value: "Mobile Developer", label: "Mobile Developer" },
  { value: "JavaScript Developer", label: "JavaScript Developer" },
  { value: "Angular Developer", label: "Angular Developer" },
  { value: "Vue.js Developer", label: "Vue.js Developer" },
  { value: "Node.js Developer", label: "Node.js Developer" },
  { value: "Backend Developer", label: "Backend Developer" },
  { value: "Software Engineer", label: "Software Engineer" },
  { value: "Technical Lead", label: "Technical Lead" },
  { value: "Project Manager", label: "Project Manager" },
  { value: "Scrum Master", label: "Scrum Master" },
  { value: "DevOps Engineer", label: "DevOps Engineer" },
  { value: "Cloud Architect", label: "Cloud Architect" },
  { value: "System Administrator", label: "System Administrator" },
  {
    value: "Database Administrator (DBA)",
    label: "Database Administrator (DBA)",
  },
  {
    value: "Quality Assurance (QA) Engineer",
    label: "Quality Assurance (QA) Engineer",
  },
  { value: "Security Engineer", label: "Security Engineer" },
  { value: "UI Developer", label: "UI Developer" },
  { value: "UX Developer", label: "UX Developer" },
  { value: "E-commerce Developer", label: "E-commerce Developer" },
  { value: "Game Developer", label: "Game Developer" },
  { value: "API Developer", label: "API Developer" },
  { value: "Integration Developer", label: "Integration Developer" },
  { value: "Machine Learning Engineer", label: "Machine Learning Engineer" },
  { value: "Data Scientist", label: "Data Scientist" },
  { value: "IT Support Specialist", label: "IT Support Specialist" },
  { value: "Network Engineer", label: "Network Engineer" },
  { value: "UI Architect", label: "UI Architect" },
  { value: "UX Architect", label: "UX Architect" },
  { value: "Frontend Architect", label: "Frontend Architect" },
  { value: "Solution Architect", label: "Solution Architect" },
  { value: "Principal Engineer", label: "Principal Engineer" },
  { value: "Technical Evangelist", label: "Technical Evangelist" },
  { value: "Content Developer", label: "Content Developer" },
  {
    value: "Digital Marketing Technologist",
    label: "Digital Marketing Technologist",
  },
  {
    value: "Web Accessibility Specialist",
    label: "Web Accessibility Specialist",
  },
  { value: "IT Consultant", label: "IT Consultant" },
  { value: "Product Manager", label: "Product Manager" },
  { value: "Release Manager", label: "Release Manager" },
  { value: "Customer Success Manager", label: "Customer Success Manager" },
  { value: "UI Tester", label: "UI Tester" },
  { value: "UX Tester", label: "UX Tester" },
  { value: "Mobile QA Tester", label: "Mobile QA Tester" },
  { value: "Technical Writer", label: "Technical Writer" },
  { value: "UI/UX Researcher", label: "UI/UX Researcher" },
  { value: "Innovation Manager", label: "Innovation Manager" },
  { value: "Human Resource", label: "Human Resource" },
  { value: "Business Analyst", label: "Business Analyst" },
  { value: "Data Analyst", label: "Data Analyst" },
  {
    value: "Machine Learning Specialist",
    label: "Machine Learning Specialist",
  },
  { value: "Cloud Engineer", label: "Cloud Engineer" },
  {
    value: "Artificial Intelligence (AI) Engineer",
    label: "Artificial Intelligence (AI) Engineer",
  },
  { value: "Blockchain Developer", label: "Blockchain Developer" },
  { value: "Embedded Systems Engineer", label: "Embedded Systems Engineer" },
  { value: "Firmware Engineer", label: "Firmware Engineer" },
  { value: "Robotics Engineer", label: "Robotics Engineer" },
  {
    value: "Virtual Reality (VR) Developer",
    label: "Virtual Reality (VR) Developer",
  },
  {
    value: "Augmented Reality (AR) Developer",
    label: "Augmented Reality (AR) Developer",
  },
  { value: "Cybersecurity Analyst", label: "Cybersecurity Analyst" },
  { value: "Ethical Hacker", label: "Ethical Hacker" },
  { value: "Network Security Engineer", label: "Network Security Engineer" },
  { value: "IT Auditor", label: "IT Auditor" },
  { value: "Technology Risk Manager", label: "Technology Risk Manager" },
  { value: "Systems Analyst", label: "Systems Analyst" },
  { value: "UX/UI Designer", label: "UX/UI Designer" },
  {
    value: "Information Systems Manager",
    label: "Information Systems Manager",
  },
  {
    value: "Technical Support Specialist",
    label: "Technical Support Specialist",
  },
  { value: "AI/ML Product Manager", label: "AI/ML Product Manager" },
  {
    value: "Health Informatics Specialist",
    label: "Health Informatics Specialist",
  },
  { value: "Bioinformatics Analyst", label: "Bioinformatics Analyst" },
  {
    value: "Geographic Information Systems (GIS) Specialist",
    label: "Geographic Information Systems (GIS) Specialist",
  },
  {
    value: "Quantum Computing Scientist",
    label: "Quantum Computing Scientist",
  },
  { value: "UI/UX Development Manager", label: "UI/UX Development Manager" },
  {
    value: "Chief Technology Officer (CTO)",
    label: "Chief Technology Officer (CTO)",
  },
  {
    value: "Chief Information Officer (CIO)",
    label: "Chief Information Officer (CIO)",
  },
  { value: "Chief Data Officer (CDO)", label: "Chief Data Officer (CDO)" },
  {
    value: "Chief Security Officer (CSO)",
    label: "Chief Security Officer (CSO)",
  },
  {
    value: "Chief Innovation Officer (CINO)",
    label: "Chief Innovation Officer (CINO)",
  },
  { value: "Technical Recruiter", label: "Technical Recruiter" },
  {
    value: "HR Information Systems Specialist",
    label: "HR Information Systems Specialist",
  },
  {
    value: "Compensation and Benefits Manager",
    label: "Compensation and Benefits Manager",
  },
  {
    value: "Talent Acquisition Specialist",
    label: "Talent Acquisition Specialist",
  },
  { value: "Employee Relations Manager", label: "Employee Relations Manager" },
  {
    value: "Learning and Development Specialist",
    label: "Learning and Development Specialist",
  },
  {
    value: "Organizational Development Consultant",
    label: "Organizational Development Consultant",
  },
  {
    value: "Change Management Specialist",
    label: "Change Management Specialist",
  },
  { value: "HR Business Partner", label: "HR Business Partner" },
  {
    value: "Diversity and Inclusion Specialist",
    label: "Diversity and Inclusion Specialist",
  },
  { value: "Workforce Planning Analyst", label: "Workforce Planning Analyst" },
  { value: "Corporate Trainer", label: "Corporate Trainer" },
  { value: "HR Analytics Specialist", label: "HR Analytics Specialist" },
  { value: "Employment Law Consultant", label: "Employment Law Consultant" },
  { value: "Benefits Analyst", label: "Benefits Analyst" },
  { value: "HR Generalist", label: "HR Generalist" },
  { value: "Labor Relations Specialist", label: "Labor Relations Specialist" },
  { value: "Executive Coach", label: "Executive Coach" },
  { value: "People Operations Manager", label: "People Operations Manager" },
  { value: "Remote Work Coordinator", label: "Remote Work Coordinator" },
  {
    value: "Employee Engagement Specialist",
    label: "Employee Engagement Specialist",
  },
  { value: "HR Coordinator", label: "HR Coordinator" },
  { value: "Onboarding Specialist", label: "Onboarding Specialist" },
  { value: "Retention Specialist", label: "Retention Specialist" },
  { value: "HR Consultant", label: "HR Consultant" },
  { value: "Payroll Specialist", label: "Payroll Specialist" },
  { value: "HR Director", label: "HR Director" },
  { value: "Employee Benefits Manager", label: "Employee Benefits Manager" },
  { value: "Recruitment Manager", label: "Recruitment Manager" },
  {
    value: "Training and Development Manager",
    label: "Training and Development Manager",
  },
  { value: "HR Administrator", label: "HR Administrator" },
  { value: "Career Counselor", label: "Career Counselor" },
  { value: "Compensation Analyst", label: "Compensation Analyst" },
  {
    value: "Succession Planning Specialist",
    label: "Succession Planning Specialist",
  },
  { value: "HR Specialist", label: "HR Specialist" },
  {
    value: "Workforce Analytics Specialist",
    label: "Workforce Analytics Specialist",
  },
];
