import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import axios from "axios";
import Select from "react-select";

import { APPOINTMENTS_API, EMPLOYEE_API } from "../../constants/api";

const Appointments = ({ visible, onHide, reFetch }) => {
  const [appointmentData, setAppointmentData] = useState([]);
  const [formData, setFormData] = useState({
    appointment_date: "",
    appointment_title: "",
    appointment_time: "",
    appointment_description: "",
    employee_ids: [],
  });

  const [formCompleted, setFormCompleted] = useState(false);
  const [employees, setEmployees] = useState([]);

  const fetchAppointmentData = async () => {
    try {
      const response = await axios.get(APPOINTMENTS_API.APPOINTMENTS_FETCH);
      const appointmentsData = response.data;
      setAppointmentData(appointmentsData);
      reFetch();
    } catch (error) {
      console.error("Error fetching appointments data:", error);
    }
  };

  useEffect(() => {
    fetchAppointmentData();
  }, []);

  useEffect(() => {
    if (appointmentData) {
      setFormData({
        appointment_date: appointmentData.appointment_date,
        appointment_title: appointmentData.appointment_title,
        appointment_time: appointmentData.appointment_time,
        appointment_description: appointmentData.appointment_description,
        employee_ids: appointmentData.employee_ids,
      });
    }
  }, [appointmentData]);
  
  const handleSaveAppointment = async () => {
    try {
      const userDataString = localStorage.getItem("user");

      if (userDataString) {
        const userData = JSON.parse(userDataString);
        const userid = userData.data.id;

        const employeeIdsString = formData.employee_ids.join(",");
        const data = {
          ...formData,
          employee_ids: employeeIdsString,
          appointment_creator:userid
        };
        await axios.post(APPOINTMENTS_API.APPOINTMENTS_ADD, data);
        setFormData({
          appointment_date: "",
          appointment_title: "",
          appointment_time: "",
          appointment_description: "",
          employee_ids: [],
        });
        onHide();
        fetchAppointmentData();
      }
    } catch (error) {
      console.error("Error saving appointment:", error);
    }
  };

  useEffect(() => {
    const isFormCompleted =
      formData.appointment_date &&
      formData.appointment_title &&
      formData.appointment_time &&
      formData.appointment_description &&
      formData.employee_ids.length > 0;
    setFormCompleted(isFormCompleted);
  }, [formData]);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.All_USERS);
      const { usersData } = response.data;
      setEmployees(usersData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const handleEmployeeSelectChange = (selectedOptions) => {
    setFormData({
      ...formData,
      employee_ids: selectedOptions.map((option) => option.value),
    });
  };

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Add Appointment"
      modal
      closable={false}
      style={{ width: "40rem" }}
      footer={
        <div>
          <Button
            style={{ color: "white", background: "red" }}
            label="Cancel"
            icon="pi pi-times"
            className="p-button-text"
            onClick={onHide}
          />
          <Button
            disabled={!formCompleted}
            style={{
              color: "white",
              cursor: formCompleted ? "pointer" : "not-allowed",
            }}
            label="Create"
            icon="pi pi-check"
            className=""
            onClick={handleSaveAppointment}
          />
        </div>
      }
    >
      <div className="p-grid" style={{ height: " 376px" }}>
        <div className="p-col-4">
          <label htmlFor="appointment_title">Appointment Title</label>
          <InputText
            id="appointment_title"
            value={formData.appointment_title}
            onChange={(e) =>
              setFormData({ ...formData, appointment_title: e.target.value })
            }
          />
        </div>

        <div className="p-col-12">
          <label htmlFor="description">Description</label>
          <InputTextarea
            id="description"
            value={formData.appointment_description}
            onChange={(e) =>
              setFormData({
                ...formData,
                appointment_description: e.target.value,
              })
            }
          />
        </div>
        {employees.length > 0 && (
          <div>
            <label htmlFor="employees">Select participants</label>
            <Select
              value={(formData.employee_ids || []).map((empId) => ({
                label: `${employees.find((emp) => emp.id === empId).firstname
                  } ${employees.find((emp) => emp.id === empId).lastname}`,
                value: empId,
              }))}
              onChange={handleEmployeeSelectChange}
              options={employees.map((emp) => ({
                label: `${emp.firstname} ${emp.lastname}`,
                value: emp.id,
              }))}
              isMulti
              isClearable
              placeholder="Select team members"
              getOptionLabel={(option) => (
                <div className="flex align-items-center">{option.label}</div>
              )}
            />
          </div>
        )}
        <div className="grid">
          <div className="col-6">
            <label htmlFor="appointment_date">Appointment Date</label>
            <InputText
              id="appointment_date"
              value={formData.appointment_date}
              onChange={(e) =>
                setFormData({ ...formData, appointment_date: e.target.value })
              }
              type="date"
              min={new Date().toISOString().split('T')[0]}
            />
          </div>

          <div className="col-5">
            <label htmlFor="time">Appointment Time</label>
            <InputText
              id="time"
              value={formData.appointment_time}
              onChange={(e) =>
                setFormData({ ...formData, appointment_time: e.target.value })
              }
              type="time"
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default Appointments;
