import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { EMPLOYEE_API, DEPARTMENT_API, CATEGORY_API } from "../../constants/api";
import { FaInfoCircle } from "react-icons/fa";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { EmployeeTypeOptions } from "./data/EmployeeType";
import { DesignationOptions } from "./data/Designation";
import moment from "moment";

export default function AddEmployee() {
  const [employeeData, setEmployeeData] = useState({
    firstname: "",
    lastname: "",
    familyname: "",
    kid_id: "",
    dateOfBirth: null,
    dateofjoining: null,
    gender: null,
    reportingManager: null,
    maritalStatus: "",
    empType: null,
    empStatus: null,
    contact_number: "",
    email: "",
    address: "",
    salary: "",
    empid: "",
    department: "",
    designation: "",
    annual_leave: "",
    annual_permissions: "",
    bank_name: "",
    account_number: "",
    IFSC_Code: "",
    state: "",
    district: "",
    pin_code: "",
    house_no: "",
    category_id: null,
  });
  const [errors, setErrors] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [kid, setKid] = useState("");
  const navigate = useNavigate();
  const toast = useRef(null);
  const [ifscError, setIFSCError] = useState("");
  const [valid, setValid] = useState(true);
  const [departmentData, setDepartmentData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const MaritalStatus = [
    { label: "Single", value: "Single" },
    { label: "Married", value: "Married" },
  ];

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(EMPLOYEE_API.All_USERS);
        if (response.status === 200) {
          const employeesData = response.data.usersData;
          setEmployees(employeesData);
        }
      } catch (error) {
        console.error("Error fetching employees:", error.message);
      }
    };

    const fetchEmployeesCategory = async () => {
      try {
        const { data } = await axios.get(CATEGORY_API.GET_ALL_CATEGORY);
        setCategoryOptions(data.map(item => ({
          label: item.category_name,
          value: item.category_id
        })));

      } catch (error) {
        console.error("Error fetching employees:", error.message);
      }
    };

    fetchEmployeesCategory()
    fetchEmployees();
  }, []);

  const fetchUserDetails = async (phoneNumber) => {
    try {
      const response = await axios.post(EMPLOYEE_API.GET_PROFILE, {
        mobile: phoneNumber,
      });
      const profileData = response.data.data.user;
      const personalData = response.data.data.personalDetails;
      const contactData = response.data.data.contactInfo;
      setEmployeeData({
        ...employeeData,
        firstname: profileData.name,
        lastname: profileData.fathername,
        dateOfBirth: moment(profileData.dateofbirth).format("YYYY-MM-DD"),
        gender: personalData.gender,
        maritalStatus: personalData.material_status,
        empType: null,
        empStatus: null,
        contact_number: profileData.mobile_no,
        email: profileData.email,
        address: contactData.address,
        salary: "",
        empid: "",
        department: "",
        designation: personalData.designation,
        annual_leave: "",
        annual_permissions: "",
        bank_name: "",
        account_number: "",
        IFSC_Code: "",
        state: contactData.state,
        district: contactData.district,
        pin_code: contactData.pincode,
        house_no: contactData.doorno,
        kid_id: profileData.user_id,
      });
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const fetchUserDetailsById = async (kid) => {
    try {
      const response = await axios.post(EMPLOYEE_API.GET_PROFILE_BYID, {
        user_id: kid,
      });
      const profileData = response.data.data.user;
      const personalData = response.data.data.personalDetails;
      const contactData = response.data.data.contactInfo;
      setEmployeeData({
        ...employeeData,
        firstname: profileData.name,
        lastname: profileData.fathername,
        dateOfBirth: moment(profileData.dateofbirth).format("YYYY-MM-DD"),
        gender: personalData.gender,
        maritalStatus: personalData.material_status,
        empType: null,
        empStatus: null,
        contact_number: profileData.mobile_no,
        email: profileData.email,
        address: contactData.address,
        salary: "",
        empid: "",
        department: "",
        designation: personalData.designation,
        annual_leave: "",
        annual_permissions: "",
        bank_name: "",
        account_number: "",
        IFSC_Code: "",
        state: contactData.state,
        district: contactData.district,
        pin_code: contactData.pincode,
        house_no: contactData.doorno,
        kid_id: profileData.user_id,
      });
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };
  const updateEmployeeDataField = (field, value) => {
    if (field === "empid") {
      setEmployeeData({
        ...employeeData,
        empid: value,
      });
    } else if (field === "reportingManager") {
      setEmployeeData({
        ...employeeData,
        reportingManager: value,
      });
    } else if (field === "IFSC_Code") {
      setEmployeeData({
        ...employeeData,
        IFSC_Code: value,
      });
    } else if (field === "email") {
      setEmployeeData({
        ...employeeData,
        email: value,
      });
    } else if (field === "contact_number") {
      setEmployeeData({
        ...employeeData,
        contact_number: value,
      });
    } else {
      setEmployeeData({
        ...employeeData,
        [field]: value,
      });
    }
    setErrors(pre => ({ ...pre, [field]: "", }))
  };

  const getAllDepartment = async () => {
    try {
      const response = await axios.get(DEPARTMENT_API.DEPARTMENT_FETCH);
      setDepartmentData(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  useEffect(() => {
    getAllDepartment();
  }, []);

  const departmentOptions = departmentData.map((department) => ({
    label: department.department_name,
    value: department.department_id,
  }));

  const handleSave = async () => {
    if (isSubmitting) return;
    let isValid = true;
    const newErrors = {};
    if (employeeData.empid.trim() === "") {
      newErrors.empid = "Please enter an Employee ID";
      isValid = false;
    }
    if (employeeData.empid.trim() === "") {
      newErrors.empid = "Please enter an Employee ID";
      isValid = false;
    } else if (!/^KCPL0\d+$/.test(employeeData.empid.trim())) {
      newErrors.empid =
        "Employee ID should start with KCPL0 followed by numbers";
      isValid = false;
    }

    if (employeeData.firstname.trim() === "") {
      newErrors.firstname = "Please enter a first name";
      isValid = false;
    }
    if (employeeData.lastname.trim() === "") {
      newErrors.lastname = "Please enter a last name";
      isValid = false;
    }
    if (employeeData.email.trim() === "" || !isEmailValid(employeeData.email)) {
      newErrors.email = "Please enter a valid email";
      isValid = false;
    }
    if (employeeData.dateOfBirth === null) {
      newErrors.dateOfBirth = "Please enter a valid date of birth";
      isValid = false;
    }
    if (employeeData.dateofjoining === null) {
      newErrors.dateofjoining = "Please enter a date of joining";
      isValid = false;
    }
    if (employeeData.salary.trim() === "") {
      newErrors.salary = "Please enter a salary";
      isValid = false;
    }
    if (employeeData.address.trim() === "") {
      newErrors.address = "Please enter an address";
      isValid = false;
    }
    if (
      employeeData?.department &&
      typeof employeeData.department === "string" &&
      employeeData.department.trim() === ""
    ) {
      newErrors.department = "Please enter a department";
      isValid = false;
    }
    if (
      (employeeData.designation !== null &&
        employeeData.designation.trim() === "") ||
      employeeData.designation === null
    ) {
      newErrors.designation = "Please enter a designation";
      isValid = false;
    }

    if (employeeData.annual_leave.trim() === "") {
      newErrors.annual_leave = "Please enter annual leave";
      isValid = false;
    }
    if (employeeData.annual_permissions.trim() === "") {
      newErrors.annual_permissions = "Please enter annual permission";
      isValid = false;
    }
    if (employeeData.bank_name.trim() === "") {
      newErrors.bank_name = "Please enter bank name";
      isValid = false;
    }
    if (employeeData.category_id === null) {
      newErrors.category_id = "Please select category";
      isValid = false;
    }
    if (employeeData.account_number.trim() === "") {
      newErrors.account_number = "Please enter account number";
      isValid = false;
    }
    if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(employeeData.IFSC_Code.trim())) {
      newErrors.IFSC_Code = "Please enter a valid IFSC code";
      isValid = false;
    }
    if (employeeData.empType === null) {
      newErrors.empType = "Please select an employee type";
      isValid = false;
    }

    if (employeeData.empStatus === null) {
      newErrors.empStatus = "Please select an employee status";
      isValid = false;
    }
    if (employeeData.gender === null) {
      newErrors.gender = "Please select a gender";
      isValid = false;
    }

    if (employeeData.maritalStatus === "") {
      newErrors.maritalStatus = "Please select a marital status";
      isValid = false;
    }
    if (employeeData.contact_number.trim() === "") {
      newErrors.contact_number = "Please enter a phone number";
      isValid = false;
    } else if (!/^\+?\d{12,15}$/.test(employeeData.contact_number.trim())) {
      newErrors.contact_number = "Please enter a valid phone number";
      isValid = false;
    } else if (employeeData.contact_number.replace(/\D/g, "").length > 12) {
      newErrors.contact_number = "Phone number must be at least 12 digits";
      isValid = false;
    }
    setErrors(newErrors);
    if (isValid) {
      setIsSubmitting(true);
      try {
        const formData = {
          user: {
            firstname: employeeData.firstname,
            lastname: employeeData.lastname,
            email: employeeData.email,
            id: employeeData.empid,
            kid_id: employeeData.kid_id,
          },
          employeeDetails: {
            familyname: employeeData.familyname,
            DOB: employeeData.dateOfBirth,
            gender: employeeData.gender,
            marital_status: employeeData.maritalStatus,
            reporting_manager_id: employeeData.reportingManager,
            contact_number: employeeData.contact_number,
            address: employeeData.address,
            department: employeeData.department,
            position: employeeData.designation,
            joining_date: employeeData.dateofjoining,
            employment_status: employeeData.empStatus,
            salary: employeeData.salary,
            employee_type: employeeData.empType,
            bank_name: employeeData.bank_name,
            account_number: employeeData.account_number,
            IFSC_Code: employeeData.IFSC_Code,
            annual_leave: employeeData.annual_leave,
            annual_permissions: employeeData.annual_permissions,
            empid: employeeData.empid,
            state: employeeData.state,
            district: employeeData.district,
            pin_code: employeeData.pin_code,
            house_no: employeeData.house_no,
            category_id: employeeData.category_id,
          },
        };

        const response = await axios.post(EMPLOYEE_API.ADD_USER, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          showToast("success", "Data successfully added");
          navigate("/AllEmployee");
        } else {
          showToast("error", response.data.error);
        }
      } catch (error) {
        if (error.response) {
          showToast("error", error.response.data.error);
        } else {
          showToast("error", "An error occurred. Please try again later.");
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const showToast = (severity, summary, detail = "") => {
    toast.current.show({
      severity,
      summary,
      detail,
      life: 3000,
    });
  };

  const handleReset = () => {
    setEmployeeData({
      firstname: "",
      lastname: "",
      familyname: "",
      kid_id: "",
      dateOfBirth: null,
      dateofjoining: null,
      gender: null,
      reportingManager: null,
      maritalStatus: "",
      empType: null,
      empStatus: null,
      contact_number: "",
      email: "",
      address: "",
      salary: "",
      empid: "",
      department: "",
      designation: "",
      annual_leave: "",
      annual_permissions: "",
      bank_name: "",
      account_number: "",
      IFSC_Code: "",
      state: "",
      district: "",
      pin_code: "",
      house_no: "",
      category_id: "",
    });
    setErrors({});
    setEmail("");
    setEmailValid(true);
    setIFSCError("");
    setValid(true);
  };
  const today = new Date().toISOString().split("T")[0];
  return (
    <>
      <div className="card p-mb-4">
        <div className="p-3">
          <div className="flex align-items-center">
            <i
              className="fi fi-ss-angle-left"
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            ></i>
            <h2 className="mb-2 text-center w-full">Add Employee</h2>
            <Link to={`/AddEmployee`}>
              <i
                className="fi-br-plus text-blue-900  "
                style={{ fontSize: "1.5rem", cursor: "pointer" }}
              ></i>
            </Link>
          </div>
          <hr className="mb-3" />
        </div>

        <Toast ref={toast} />

        <div className="flex mb-1 input-flex-col">
          <div className="p-field">
            <label htmlFor="phoneNumber">Contact Number for Search</label>
            <div className="flex">
              <PhoneInput
                country={"in"}
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber(phone)}
              />
              <button
                className="pi pi-search"
                style={{
                  background: "#6366f1",
                  border: "none",
                  padding: "0px 14px",
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                  color: "#fff",
                }}
                onClick={() => fetchUserDetails(phoneNumber)}
              ></button>
            </div>
          </div>

          <div className="p-field">
            <label htmlFor="phoneNumber">KID for Search</label>
            <div className="flex">
              <InputText
                id="phoneNumber"
                value={kid}
                onChange={(e) => setKid(e.target.value)}
              />
              <button
                className="pi pi-search"
                style={{
                  background: "#6366f1",
                  border: "none",
                  padding: "0px 14px",
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                  color: "#fff",
                }}
                onClick={() => fetchUserDetailsById(kid)}
              ></button>
            </div>
          </div>
        </div>
        <div className="input-grid-col">
          <div className="p-field">
            <label htmlFor="empid">Employee Id</label>
            <InputText
              value={employeeData.empid}
              onChange={(e) => updateEmployeeDataField("empid", e.target.value)}
              className={valid ? "" : "border-red-500"}
            />
            {errors.empid && <small className="p-error">{errors.empid}</small>}
            {!valid && (
              <div className="text-red-500 text-xs relative">
                <FaInfoCircle className="absolute top-[3px]" />
                <span className="ml-4">
                  Employee id format should start with KCPL0
                </span>
              </div>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="empid">Employee KID</label>
            <InputText
              value={employeeData.kid_id}
              onChange={(e) =>
                updateEmployeeDataField("kid_id", e.target.value)
              }
              className={valid ? "" : "border-red-500"}
            />
            {errors.kid_id && (
              <small className="p-error">{errors.kid_id}</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="firstname">First Name</label>
            <InputText
              keyfilter={/^[a-zA-Z\s]*$/}
              id="firstname"
              value={employeeData.firstname}
              onChange={(e) => updateEmployeeDataField("firstname", e.target.value)}
            />
            {errors.firstname && <small className="p-error">{errors.firstname}</small>}
          </div>

          <div className="p-field">
            <label htmlFor="lastname">Last Name</label>
            <InputText
              keyfilter={/^[a-zA-Z\s]*$/}
              id="lastname"
              value={employeeData.lastname}
              onChange={(e) => updateEmployeeDataField("lastname", e.target.value)}
            />
            {errors.lastname && <small className="p-error">{errors.lastname}</small>}
          </div>

          <div className="p-field">
            <label htmlFor="familyname">Family Name</label>
            <InputText
              keyfilter={/^[a-zA-Z\s]*$/}
              id="familyname"
              value={employeeData.familyname}
              onChange={(e) => updateEmployeeDataField("familyname", e.target.value)}
            />
            {errors.familyname && <small className="p-error">{errors.familyname}</small>}
          </div>

          <div className="p-field">
            <label htmlFor="email">Email</label>
            <InputText
              id="email"
              value={employeeData.email}
              onChange={(e) => updateEmployeeDataField("email", e.target.value)}
              className={emailValid ? "" : "p-invalid"}
            />
            {errors.email && <small className="p-error">{errors.email}</small>}
          </div>

          <div className="p-field">
            <label htmlFor="phoneNumber">Phone Number</label>
            <PhoneInput
              country={"in"}
              value={employeeData.contact_number}
              onChange={(value) => updateEmployeeDataField("contact_number", value)}
            />
            {errors.contact_number && <small className="p-error">{errors.contact_number}</small>}
          </div>

          <div className="p-field">
            <label htmlFor="dateOfBirth">Date of Birth</label>
            <InputText
              type="date"
              id="dateOfBirth"
              value={employeeData.dateOfBirth}
              onChange={(e) => updateEmployeeDataField("dateOfBirth", e.target.value)}
              dateFormat="yy-mm-dd"
              max={today}
              inputClassName="p-inputtext-sm"
            />
            {errors.dateOfBirth && <small className="p-error">{errors.dateOfBirth}</small>}
          </div>

          <div className="p-field">
            <label htmlFor="gender">Gender</label>
            <Dropdown
              id="gender"
              value={employeeData.gender}
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]}
              onChange={(e) => updateEmployeeDataField("gender", e.value)}
              placeholder="Select Gender"
              className={errors.gender ? "p-invalid w-full" : "w-full"}
            />
            {errors.gender && <small className="p-error">{errors.gender}</small>}
          </div>

          <div className="p-field">
            <label htmlFor="maritalStatus">Marital Status</label>
            <Dropdown
              id="maritalStatus"
              value={employeeData.maritalStatus}
              options={MaritalStatus}
              onChange={(e) => updateEmployeeDataField("maritalStatus", e.value)}
              placeholder="Select Marital Status"
              className={errors.maritalStatus ? "p-invalid w-full" : "w-full"}
            />
            {errors.maritalStatus && <small className="p-error">{errors.maritalStatus}</small>}
          </div>

          <div className="p-field">
            <label htmlFor="empType">Employee Type</label>
            <Dropdown
              id="empType"
              value={employeeData.empType}
              options={EmployeeTypeOptions}
              onChange={(e) => updateEmployeeDataField("empType", e.value)}
              placeholder="Select Employee Type"
              className={errors.empType ? "p-invalid w-full" : "w-full"}
              filter
              showClear
            />
            {errors.empType && <small className="p-error">{errors.empType}</small>}
          </div>

          <div className="p-field">
            <label htmlFor="empType">Employee Category</label>
            <Dropdown
              id="category_id"
              value={employeeData.category_id}
              options={categoryOptions}
              onChange={(e) => updateEmployeeDataField("category_id", e.value)}
              placeholder="Select Employee Category"
              className={errors.category_id ? "p-invalid w-full" : "w-full"}
              filter
              showClear
            />
            {errors.category_id && <small className="p-error">{errors.category_id}</small>}
          </div>

          <div className="p-field">
            <label htmlFor="empStatus">Employee Status</label>
            <Dropdown
              id="empStatus"
              value={employeeData.empStatus}
              options={[
                { label: "Active", value: "Active" },
                { label: "Inactive", value: "Inactive" },
              ]}
              onChange={(e) => updateEmployeeDataField("empStatus", e.value)}
              placeholder="Select Employee Status"
              className={errors.empStatus ? "p-invalid w-full" : "w-full"}
            />
            {errors.empStatus && <small className="p-error">{errors.empStatus}</small>}
          </div>

          <div className="p-field">
            <label htmlFor="dateofjoining">Date of Joining</label>
            <InputText
              id="dateofjoining"
              type="date"
              value={employeeData.dateofjoining}
              onChange={(e) => updateEmployeeDataField("dateofjoining", e.target.value)}
              inputClassName="p-inputtext-sm"
            />
            {errors.dateofjoining && <small className="p-error">{errors.dateofjoining}</small>}
          </div>

          <div className="p-field">
            <label htmlFor="salary">Salary</label>
            <InputText
              keyfilter="pnum"
              id="salary"
              value={employeeData.salary}
              onChange={(e) => updateEmployeeDataField("salary", e.target.value)}
              className={errors.salary ? "p-invalid" : ""}
            />
            {errors.salary && <small className="p-error">{errors.salary}</small>}
          </div>

          <div className="p-field" style={{ display: "flex", gap: "10px" }}>
            <div style={{ width: "75px" }}>
              <label htmlFor="house_no">House No</label>
              <InputText
                keyfilter="pnum"
                id="house_no"
                value={employeeData.house_no}
                onChange={(e) => updateEmployeeDataField("house_no", e.target.value)}
              />
            </div>
            <div style={{ flexGrow: "1" }}>
              <label htmlFor="address">Address</label>
              <InputText
                id="address"
                value={employeeData.address}
                onChange={(e) => updateEmployeeDataField("address", e.target.value)}
              />
              {errors.address && <small className="p-error">{errors.address}</small>}
            </div>
          </div>

          <div className="p-field">
            <label htmlFor="state">State</label>
            <InputText
              keyfilter={/^[a-zA-Z\s]*$/}
              id="state"
              value={employeeData.state}
              onChange={(e) => updateEmployeeDataField("state", e.target.value)}
            />
          </div>

          <div className="p-field">
            <label htmlFor="district">District</label>
            <InputText
              keyfilter={/^[a-zA-Z\s]*$/}
              id="district"
              value={employeeData.district}
              onChange={(e) => updateEmployeeDataField("district", e.target.value)}
            />
          </div>

          <div className="p-field">
            <label htmlFor="pin_code">Postal Code</label>
            <InputText
              keyfilter="pnum"
              id="pin_code"
              value={employeeData.pin_code}
              onChange={(e) => updateEmployeeDataField("pin_code", e.target.value)}
            />
          </div>

          <div className="p-field">
            <label htmlFor="department">Department</label>
            <Dropdown
              placeholder="Select Department"
              value={employeeData.department}
              onChange={(e) => updateEmployeeDataField("department", e.value)}
              options={departmentOptions}
              className={errors.department ? "w-full p-invalid" : "w-full"}
            />
            {errors.department && <small className="p-error">{errors.department}</small>}
          </div>

          <div className="p-field">
            <label htmlFor="designation">Designation</label>
            <Dropdown
              id="designation"
              value={employeeData.designation}
              options={DesignationOptions}
              onChange={(e) => updateEmployeeDataField("designation", e.value)}
              placeholder="Select a designation"
              filter
              showClear
              className={`${errors.designation ? "p-invalid" : ""} w-full`}
            />
            {errors.designation && <small className="p-error">{errors.designation}</small>}
          </div>

          <div className="p-field">
            <label htmlFor="reportingManager">Reporting Manager</label>
            <Dropdown
              id="reportingManager"
              className="reporting-manager"
              value={employeeData.reportingManager}
              optionLabel="fullname"
              options={employees.map((employee) => ({
                label: `${employee.firstname} ${employee.lastname}`,
                value: employee.id,
                fullname: `${employee.firstname} ${employee.lastname}`,
              }))}
              onChange={(e) => updateEmployeeDataField("reportingManager", e.value)}
              placeholder="Select Reporting Manager"
              filter
              showClear
            />
          </div>

          <div className="p-field" style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <div className="p-field">
              <label htmlFor="annual_leave">Annual Leave</label>
              <InputText
                keyfilter="pnum"
                id="annual_leave"
                value={employeeData.annual_leave}
                onChange={(e) => updateEmployeeDataField("annual_leave", e.target.value)}
                className={errors.annual_leave ? "p-invalid" : ""}
              />
              {errors.annual_leave && <small className="p-error">{errors.annual_leave}</small>}
            </div>
            <div className="p-field" style={{ flexGrow: "1", whiteSpace: "nowrap" }}>
              <label htmlFor="annual_permissions">Annual Permission</label>
              <InputText
                keyfilter="pnum"
                id="annual_permissions"
                value={employeeData.annual_permissions}
                onChange={(e) => updateEmployeeDataField("annual_permissions", e.target.value)}
                className={errors.annual_permissions ? "p-invalid" : ""}
              />
              {errors.annual_permissions && <small className="p-error">{errors.annual_permissions}</small>}
            </div>
          </div>

          <div className="p-field">
            <label htmlFor="bank_name">Bank Name</label>
            <InputText
              id="bank_name"
              keyfilter={/^[a-zA-Z\s]*$/}
              value={employeeData.bank_name}
              onChange={(e) => updateEmployeeDataField("bank_name", e.target.value)}
              className={errors.bank_name ? "p-invalid" : ""}
            />
            {errors.bank_name && <small className="p-error">{errors.bank_name}</small>}
          </div>

          <div className="p-field">
            <label htmlFor="account_number">Account Number</label>
            <InputText
              keyfilter="pnum"
              id="account_number"
              value={employeeData.account_number}
              onChange={(e) => updateEmployeeDataField("account_number", e.target.value)}
              className={errors.account_number ? "p-invalid" : ""}
            />
            {errors.account_number && <small className="p-error">{errors.account_number}</small>}
          </div>

          <div className="p-field">
            <label htmlFor="IFSC_Code">IFSC Code</label>
            <InputText
              id="IFSC_Code"
              value={employeeData.IFSC_Code}
              onChange={(e) => updateEmployeeDataField("IFSC_Code", e.target.value)}
              className={errors.IFSC_Code ? "p-invalid" : ""}
            />
            {errors.IFSC_Code && <small className="p-error">{errors.IFSC_Code}</small>}
          </div>
        </div>

        <div className="flex mt-4 w-full justify-content-end">
          <div>
            <Button
              style={{ background: "red", border: "none" }}
              onClick={handleReset}
              label="Cancel"
              className="p-button-secondary"
            />
            <Button
              onClick={handleSave}
              label="Submit"
              className="mx-3"
              disabled={isSubmitting}
            />
          </div>
        </div>
      </div>
    </>

  );
}