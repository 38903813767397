import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import axios from "axios";
import { EMPLOYEE_API, DEPARTMENT_API } from "../../constants/api";
import { Link } from "react-router-dom";
import EditEmployeeModal from "./EditEmployee";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { setFirst, setPageRows } from "../../service/paginator";
import { Tooltip } from "primereact/tooltip";

export default function AllEmployee() {
  const [products, setProducts] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [editIndex, setEditIndex] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [selectedEmployeeToDelete, setSelectedEmployeeToDelete] =
    useState(null);
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);
  const [reportingManagers, setReportingManagers] = useState([]);
  const toast = useRef(null);
  const [departmentData, setDepartmentData] = useState([]);
  const dispatch = useDispatch();
  const first = useSelector((state) => state.paginator.pages.inactiveEmployee.first);
  const rowsPerPage = useSelector((state) => state.paginator.pages.inactiveEmployee.rowsPerPage);

  const [localFirst, setLocalFirst] = useState(first || 0);
  const [localRowsPerPage, setLocalRowsPerPage] = useState(rowsPerPage || 10);

  const onPageChange = (e) => {
    setLocalFirst(e.first);
    dispatch(setFirst({ page: 'inactiveEmployee', value: e.first }));
  };

  const onPageRowsChange = (event) => {
    setLocalRowsPerPage(event.rows);
    dispatch(setPageRows({ page: 'inactiveEmployee', value: event.rows }));
  };

  const rowsPerPageOptions = [10, 25, 50];

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setLocalRowsPerPage(newRowsPerPage);
    dispatch(setPageRows({ page: 'inactiveEmployee', value: newRowsPerPage }))
  };


  const showConfirmationModal = (rowData) => {
    setSelectedEmployeeToDelete(rowData);
    setDisplayConfirmation(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmation(false);
    setSelectedEmployeeToDelete(null);
  };

  const deleteRow = async (rowData) => {
    showConfirmationModal(rowData);
  };

  const confirmDelete = async () => {
    try {
      const userId = selectedEmployeeToDelete.empid;
      await axios.put(EMPLOYEE_API.TO_ACTIVE_USER(userId));
      const updatedProducts = products.filter(
        (product) => product.id !== selectedEmployeeToDelete.id
      );
      setProducts(updatedProducts);
      showToast("success", "Data Activated successfully");
      hideConfirmationModal();
    } catch (error) {
      console.error("Error activating user:", error);
      showToast("error", "Error activating user");
      hideConfirmationModal();
    }
  };

  useEffect(() => {
    fetchData();
    fetchUserRole();
  }, []);

  const fetchUserRole = () => {
    const userDataString = localStorage.getItem("user");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setUserRole(userData.data.role);
    }
  };

  const getAllDepartment = async () => {
    try {
      const response = await axios.get(DEPARTMENT_API.DEPARTMENT_FETCH);
      setDepartmentData(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  useEffect(() => {
    getAllDepartment();
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(EMPLOYEE_API.All_USERS);
        if (response.status === 200) {
          const employeesData = response.data.usersData;
          setAllEmployees(employeesData);
          setReportingManagers(employeesData);
        }
      } catch (error) {
        console.error("Error fetching employees:", error.message);
      }
    };

    fetchEmployees();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.INACTIVE_USERS);
      const usersData = response.data.usersData;

      const allEmployees = usersData.map((user) => ({
        empid: user.empid,
        firstname: user.firstname,
        lastname: user.lastname,
      }));

      const updatedUsersData = usersData.map((user) => ({
        ...user,
      }));

      setProducts(updatedUsersData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openEditModal = (rowData, rowIndex) => {
    setEditedData({
      id: rowData.id,
      firstname: rowData.firstname,
      lastname: rowData.lastname,
      department: rowData.department,
      position: rowData.position,
      contact_number: rowData.contact_number,
      email: rowData.email,
      joining_date: rowData.joining_date,
      empid: rowData.empid,
      reporting_manager_id: rowData.reporting_manager_id,
      gender: rowData.gender,
      marital_status: rowData.marital_status,
      address: rowData.address,
      employee_type: rowData.employee_type,
      employment_status: rowData.employment_status,
      salary: rowData.salary,
      annual_leave: rowData.annual_leave,
      bank_name: rowData.bank_name,
      account_number: rowData.account_number,
      IFSC_Code: rowData.IFSC_Code,
      balance_leave: rowData.balance_leave,
      leave_taken: rowData.leave_taken,
      contact_relationship: rowData.contact_relationship,
      emergency_contact_name: rowData.emergency_contact_name,
      emergency_contact_no: rowData.emergency_contact_no,
      DOB: rowData.DOB,
    });
    setEditIndex(rowIndex);
    setDisplayModal(true);
  };
  const [errors, setErrors] = useState({});

  const saveChanges = async () => {
    const newErrors = {};

    if (!editedData.firstname) {
      newErrors.firstname = "First name is required";
    }

    if (!editedData.lastname) {
      newErrors.lastname = "Last name is required";
    }
    if (!editedData.DOB) {
      newErrors.DOB = "DOB is required";
    }
    if (!editedData.address) {
      newErrors.address = "Address is required";
    }
    if (!editedData.department) {
      newErrors.department = "Department is required";
    }
    if (!editedData.position) {
      newErrors.position = "Position is required";
    }
    if (!editedData.account_number) {
      newErrors.account_number = "Account Number is required";
    }
    if (!editedData.IFSC_Code) {
      newErrors.IFSC_Code = "IFSC Code is required";
    }
    if (!editedData.bank_name) {
      newErrors.bank_name = "Bank Name is required";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if (editIndex !== null) {
      try {
        const { id, empid, ...employeeDetails } = editedData;
        const payload = {
          user: {
            firstname: editedData.firstname,
            lastname: editedData.lastname,
            email: editedData.email,
          },
          employeeDetails: {
            ...employeeDetails,
          },
        };
        await axios.put(EMPLOYEE_API.EDIT_USER(empid), payload);
        await fetchData();

        setProducts((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts[editIndex] = editedData;
          return updatedProducts;
        });

        setDisplayModal(false);
        showToast("success", "Data updated successfully");
      } catch (error) {
        console.error("Error editing row:", error);
        showToast("error", "Error updating data");
      }
    }
  };

  const showToast = (severity, detail) => {
    toast.current.show({ severity, detail });
  };

  const statusBodyTemplate = (product, index) => {
    const userDataString = localStorage.getItem("user");
    let userRole = "";

    if (userDataString) {
      const userData = JSON.parse(userDataString);
      userRole = userData.data.role;
    }

      return (
        <div className="flex">
          <Link to={`/profile/${product.id}`}>
            <Button
              tooltip="View"
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
              }}
              icon="pi pi-eye"
              className="p-button-rounded p-button mr-2"
              style={{
                background: "#FBA91E",
                color: "white",
                border: "#FBA91E 2px solid",
              }}
            />
          </Link>
          <Button
            tooltip="Activate access"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
            icon="fi fi-rr-user-check"
            className="p-button-rounded p-button-success "
            onClick={() => deleteRow(product)}
          />
        </div>
      );

  };

  return (
    <div>
      <div className="md:flex block justify-content-between">
        <div className="">
          <h2 className="my-3">Inactive Employees</h2>
        </div>
        <div className="md:my-0 my-3">
          <div className="p-inputgroup flex-1">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div className="card h-full">
        <div className="flex  justify-content-end gap-2 mr-4"></div>
        <Toast ref={toast} />
        <DataTable
          key={JSON.stringify(products)}
          value={products}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="id"
          tableStyle={{ minWidth: "50rem" }}
          paginator
          globalFilter={globalFilter.trim() ? globalFilter : null}
          removableSort
          className="dataTableHiddenFooterBg"
          rows={localRowsPerPage}
          onPage={onPageChange}
          onPageRows={onPageRowsChange}
          first={localFirst}
          footer={
            <Dropdown
              value={localRowsPerPage}
              options={rowsPerPageOptions.map(option => ({ label: option, value: option }))}
              onChange={handleRowsPerPageChange}
              placeholder="Select Rows Per Page"
            />
          }
        >
          <Column
            field="empid"
            header="Id"
            style={{ minWidth: "90px" }}
            sortable
          ></Column>
          <Column
            header="Name"
            // body={(rowData) => `${rowData.firstname} ${rowData.lastname}`}
            style={{ minWidth: "90xp", textTransform: "capitalize" }}
            body={(rowData) => (
              <>
                <Tooltip
                  target=".link-without-color"
                  mouseTrack
                  mouseTrackLeft={5}
                  mouseTrackTop={5}
                >
                  Click to view details
                </Tooltip>
                <Link
                  className="link-without-color"
                  to={`/profile/${rowData.id}`}
                >
                  {`${rowData.firstname} ${rowData.lastname}`}
                </Link>
              </>
            )}
          ></Column>
          <Column
            field="department"
            header="Department"
            style={{ minWidth: "115px", textTransform: "capitalize" }}
            body={(rowData) => {
              const departmentId = parseInt(rowData.department);
              const department = departmentData.find(
                (dept) => dept.department_id === departmentId
              );
              return department ? department.department_name : "-";
            }}
            sortable
          ></Column>
          <Column
            field="position"
            header="Designation"
            style={{ minWidth: "90px", textTransform: "capitalize" }}
            sortable
          ></Column>
          <Column
            field="Attendance"
            header="Attendance"
            style={{ minWidth: "90px", textTransform: "capitalize" }}
            body={(rowData) => rowData.Attendance || "-"}
          ></Column>
          <Column
            field="reporting_manager_id"
            header="Reporting Manager"
            body={(rowData) => {
              const reportingManager = allEmployees.find(
                (employee) => employee.id === rowData.reporting_manager_id
              );
              return reportingManager
                ? `${reportingManager.firstname} ${reportingManager.lastname}`
                : "-";
            }}
            style={{
              minWidth: "120px",
              textTransform: "capitalize",
            }}
          />
          <Column
            field="joining_date"
            header="Joining Date"
            style={{ minWidth: "87px", overflow: "visible" }}
            sortable
          ></Column>
          <Column
            field="date_of_leaving"
            header="Relieving Date"
            style={{ minWidth: "87px", overflow: "visible" }}
            body={(rowData) =>
              moment(rowData.date_of_leaving).format("DD-MM-YYYY") || "-"
            }
            sortable
          ></Column>
          <Column
            field="reason_of_leaving"
            header="Reason"
            style={{
              wordBreak: "break-word",
              minWidth: "120px",
              textTransform: "capitalize",
            }}
          ></Column>
            <Column
              header="Actions"
              body={(rowData, rowIndex) =>
                statusBodyTemplate(rowData, rowIndex)
              }
              style={{ minWidth: "130px", overflow: "visible" }}
            ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={displayModal}
        onHide={() => setDisplayModal(false)}
        header="Edit Employee"
        footer={
          <div>
            <Button
              label="Save"
              icon="pi pi-check"
              className="p-button-success"
              onClick={saveChanges}
            />
            <Button
              label="Cancel"
              className="p-button-danger"
              onClick={() => setDisplayModal(false)}
            />
          </div>
        }
      >
        <EditEmployeeModal
          visible={displayModal}
          onHide={() => setDisplayModal(false)}
          saveChanges={saveChanges}
          editedData={editedData}
          setEditedData={setEditedData}
          phoneNumberValid={phoneNumberValid}
          reportingManagers={reportingManagers}
          errors={errors}
          setErrors={setErrors}
        />
      </Dialog>
      {/* delete model */}
      <Dialog
        visible={displayConfirmation}
        style={{ minWidth: "50vw" }}
        breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
        onHide={hideConfirmationModal}
        header="Confirmation"
        footer={
          <div>
            <Button
              style={{ color: "white", background: "red" }}
              label="No"
              icon="pi pi-times"
              className="p-button-text"
              onClick={hideConfirmationModal}
            />
            <Button
              style={{ color: "white", background: "#22c55e" }}
              label="Yes"
              icon="pi pi-check"
              className="p-button-text"
              onClick={confirmDelete}
            />
          </div>
        }
      >
        Are you sure you want to Active the employee?
      </Dialog>
    </div>
  );
}
