export const assetsData=[
    { "asset_name": "Laptop", "brand": "Dell", "model": "XPS 15" },
    { "asset_name": "Monitor", "brand": "LG", "model": "UltraWide" },
    { "asset_name": "Keyboard", "brand": "Logitech", "model": "K380" },
    { "asset_name": "Mouse", "brand": "Logitech", "model": "MX Master 3" },
    { "asset_name": "Office Chair", "brand": "Herman Miller", "model": "Aeron" },
    { "asset_name": "Desk", "brand": "IKEA", "model": "Bekant" },
    { "asset_name": "Printer", "brand": "HP", "model": "LaserJet Pro" },
    { "asset_name": "Webcam", "brand": "Logitech", "model": "C920" },
    { "asset_name": "Headphones", "brand": "Sony", "model": "WH-1000XM4" },
    { "asset_name": "Router", "brand": "Netgear", "model": "Nighthawk" },
    { "asset_name": "External Hard Drive", "brand": "Western Digital", "model": "My Passport 1TB" },
    { "asset_name": "Docking Station", "brand": "Dell", "model": "WD19" },
    { "asset_name": "Smartphone", "brand": "Apple", "model": "iPhone 13" },
    { "asset_name": "Tablet", "brand": "Apple", "model": "iPad Air" },
    { "asset_name": "Projector", "brand": "Epson", "model": "Home Cinema" },
    { "asset_name": "Smart Watch", "brand": "Apple", "model": "Watch Series 7" },
    { "asset_name": "USB Drive", "brand": "SanDisk", "model": "Cruzer" },
    { "asset_name": "Microphone", "brand": "Blue", "model": "Yeti" },
    { "asset_name": "Router", "brand": "Asus", "model": "RT-AX86U" },
    { "asset_name": "Speaker", "brand": "Bose", "model": "SoundLink" },
    { "asset_name": "Laptop", "brand": "Apple", "model": "MacBook Pro 16" },
    { "asset_name": "Monitor", "brand": "Dell", "model": "Ultrasharp U2720Q" },
    { "asset_name": "Keyboard", "brand": "Logitech", "model": "MX Keys" },
    { "asset_name": "Mouse", "brand": "Logitech", "model": "G502 Hero" },
    { "asset_name": "Office Chair", "brand": "Steelcase", "model": "Leap" },
    { "asset_name": "Desk", "brand": "Herman Miller", "model": "Renew" },
    { "asset_name": "Printer", "brand": "Brother", "model": "HL-L2350DW" },
    { "asset_name": "Webcam", "brand": "Logitech", "model": "Brio" },
    { "asset_name": "Headphones", "brand": "Bose", "model": "QuietComfort 35" },
    { "asset_name": "Router", "brand": "TP-Link", "model": "Archer AX50" },
    { "asset_name": "External Hard Drive", "brand": "Seagate", "model": "Backup Plus" },
    { "asset_name": "Docking Station", "brand": "HP", "model": "Thunderbolt Dock 120W G2" },
    { "asset_name": "Smartphone", "brand": "Samsung", "model": "Galaxy S21" },
    { "asset_name": "Tablet", "brand": "Samsung", "model": "Galaxy Tab S7" },
    { "asset_name": "Projector", "brand": "BenQ", "model": "HT2050A" },
    { "asset_name": "Smart Watch", "brand": "Samsung", "model": "Galaxy Watch 4" },
    { "asset_name": "USB Drive", "brand": "Kingston", "model": "DataTraveler" },
    { "asset_name": "Microphone", "brand": "Rode", "model": "NT1-A" },
    { "asset_name": "Router", "brand": "Google", "model": "Nest Wifi" },
    { "asset_name": "Speaker", "brand": "JBL", "model": "Flip 5" }
]
