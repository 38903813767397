import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CATEGORY_API } from "../../constants/api";
import { Button } from "primereact/button";
import AddCategoryDialog from "./AddCategoryDialog";
import EditCategoryDialog from "./EditCategoryDialog";
import { Toast } from "primereact/toast";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

const StaffCategories = () => {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);
  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] =
    useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toastRef = useRef(null);

  const fetchCategory = async () => {
    try {
      const response = await axios.get(CATEGORY_API.GET_ALL_CATEGORY);
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error.message);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const openDeleteConfirmation = (rowData) => {
    setDeleteConfirmationData(rowData);
    setDisplayDeleteConfirmation(true);
  };

  const openEditDialog = (rowData) => {
    setSelectedCategory(rowData);
    setEditVisible(true);
    setFormData({
      category_name: rowData.category_name || "",
      category_description: rowData.category_description || "",
      annual_leave: rowData.annual_leave || "",
      annual_permission: rowData.annual_permission || "",
      lateGrace: rowData.lateGrace || "",
      earlyGrace: rowData.earlyGrace || "",
      weeklyOffDay: rowData.weeklyOffDay ? rowData.weeklyOffDay.split(",") : [],
      weeklyOffs: rowData.weeklyOffs ? rowData.weeklyOffs.split(",") : [],
      ShiftType: rowData.ShiftType || "",
      markPrefixAbsent: rowData.markPrefixAbsent === "1" ? true : false,
      markSuffixAbsent: rowData.markSuffixAbsent === "1" ? true : false,
      halfDayThreshold: rowData.halfDayThreshold || "",
      absentThreshold: rowData.absentThreshold || "",
      partialHalfDayThreshold: rowData.partialHalfDayThreshold || "",
      partialAbsentThreshold: rowData.partialAbsentThreshold || "",
    });
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${CATEGORY_API.DELETE_CATEGORY(deleteConfirmationData.category_id)}`
      );
      fetchCategory();
      showToast("success", "Success Message", "Category deleted successfully");
      setDisplayDeleteConfirmation(false);
    } catch (error) {
      console.error("Failed to delete category data:", error);
      showToast("error", "Error Message", "Failed to delete category");
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <div>
        <Button
          tooltip="Edit"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-pencil"
          className="p-button-rounded bg-blue-500 border-none"
          onClick={() => openEditDialog(rowData)}
        />
        <Button
          tooltip="Delete"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger mx-2"
          onClick={() => openDeleteConfirmation(rowData)}
        />
      </div>
    );
  };

  const showDialog = () => {
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const hideEditDialog = () => {
    setEditVisible(false);
    setFormData({
      category_name: "",
      category_description: "",
      annual_leave: "",
      annual_permission: "",
      lateGrace: "",
      earlyGrace: "",
      weeklyOffDay: [],
      weeklyOffs: [],
      ShiftType: "",
      markPrefixAbsent: false,
      markSuffixAbsent: false,
      halfDayThreshold: "",
      absentThreshold: "",
      partialHalfDayThreshold: "",
      partialAbsentThreshold: "",
    })
  };

  const showToast = (severity, summary, detail) => {
    toastRef.current.show({ severity, summary, detail });
  };

  return (
    <div>
      <Toast ref={toastRef} />
      <div className="col-12 md:flex block justify-content-between w-full align-items-center">
        <h2 className="my-1">Staff Categories</h2>
        <div className="md:col-5 lg:col-2 col-12 mt-0">
          <div className="p-inputgroup">
            <InputText type="text" id="globalFilter" className="search-input" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
            <Button icon="fi fi-bs-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div className="flex justify-content-end mb-3 align-items-center mr-4">
        <Button label="" icon="fi fi-br-plus" onClick={showDialog}></Button>
      </div>
      <div className="card">
        <DataTable
          value={data}
          tableStyle={{ minWidth: "50rem" }}
          globalFilter={globalFilter ? globalFilter.trim() : ""}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          removableSort
        >
          <Column header="S.No" body={(rowData) => <span>{data.indexOf(rowData) + 1}</span>} />
          <Column field="category_name" header="Name" sortable />
          <Column field="category_description" header="Short Name" sortable />
          <Column field="weeklyOffDay" header="weeklyOff Day" sortable />
          <Column field="weeklyOffs" header="weeklyOffs" sortable />
          <Column
            header="Actions"
            body={actionTemplate}
            bodyStyle={{ minWidth: "160px" }}
          ></Column>
        </DataTable>
      </div>
      <AddCategoryDialog
        visible={visible}
        onHide={hideDialog}
        fetchCategory={fetchCategory}
        showToast={showToast}
        setVisible={setVisible}
      />
      <EditCategoryDialog
        editVisible={editVisible}
        formData={formData}
        setFormData={setFormData}
        onHide={hideEditDialog}
        selectedCategory={selectedCategory}
        fetchCategory={fetchCategory}
        showToast={showToast}
      />
      <Dialog
        visible={displayDeleteConfirmation}
        onHide={() => setDisplayDeleteConfirmation(false)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        header="Confirmation"
        footer={
          <div>
            <Button
              style={{ color: "white", background: "red" }}
              label="No"
              icon="pi pi-times"
              className="p-button-text"
              onClick={() => setDisplayDeleteConfirmation(false)}
            />
            <Button
              style={{ color: "white", background: "#22c55e" }}
              label="Yes"
              icon="pi pi-check"
              className="p-button-text"
              onClick={handleDelete}
            />
          </div>
        }
      >
        Are you sure you want to delete the category data?
      </Dialog>
    </div>
  );
};

export default StaffCategories;
