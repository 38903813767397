import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { EMPLOYEE_API } from "../../constants/api";

export default function LeaveBalance() {
  const [globalFilter, setGlobalFilter] = useState("");
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get(EMPLOYEE_API.GET_ALL);
        setEmployees(response.data.usersData);
      } catch (error) {
        console.error("Error fetching employee data:", error.message);
      }
    };

    fetchEmployeeData();
  }, []);
  return (
    <div className="card">
      <div className="grid">
        <div className="md:col-9 col-12">
          <h2 className="my-3">Leave Balance Sheet</h2>
        </div>
        <div className="md:col-2 col-12">
          <div className="p-inputgroup flex-1">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      {employees.length > 0 && (
        <DataTable
          value={employees}
          paginator
          rows={10}
          tableStyle={{ minWidth: "50rem" }}
          globalFilter={globalFilter.trim() ? globalFilter : null}
          emptyMessage="No records found"
          removableSort
        >
          <Column
            field="empid"
            header="Employee ID"
            className="font-bold"
            sortable
            style={{ width: "70px" }}
          />
          <Column
            field="fullname"
            header="Employee Name"
            style={{ width: "90px" }}
            className="font-bold"
            body={(rowData) => (
              <span>{`${rowData.firstname} ${rowData.lastname}`}</span>
            )}
          />
          <Column
            field="annual_leave"
            header="Annual Leave"
            sortable
            style={{ width: "50px" }}
          />
          <Column
            field="leave_taken"
            header="Leave Taken"
            sortable
            style={{ width: "50px" }}
          />
          <Column
            field="balance_leave"
            header="Leave Balance"
            sortable
            style={{ width: "50px" }}
          />
        </DataTable>
      )}
    </div>
  );
}
