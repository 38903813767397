import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pages: {
    allEmployee: {
      first: 0,
      rowsPerPage: 10,
    },
    inactiveEmployee: {
      first: 0,
      rowsPerPage: 10,
    },
    leaveApprovals: {
      first: 0,
      rowsPerPage: 10,
    },
    employeePayroll: {
      first: 0,
      selectedMonthEmp: "",
      selectedYearEmp: "",
      rowsPerPage: 10,
    },
    allTaskAdmin: {
      firstTask: 0,
      rowsPerPageTask: 10,
      statusSelector: "All",
    },
    taskEmp: {
      firstTask: 0,
      rowsPerPageTask: 10,
      statusSelector: "All",
    },
  },
};

const paginatorSlice = createSlice({
  name: "paginator",
  initialState,
  reducers: {
      selectMonthEmp: (state, action) => {
      state.selectedMonthEmp = action.payload;
    },
    
    selectYearEmp: (state, action) => {
      state.selectedYearEmp = action.payload;
    },

    setFirst: (state, action) => {
      const { page, value } = action.payload;
      state.pages[page].first = value;
    },

    setPageRows: (state, action) => {
      const { page, value } = action.payload;
      state.pages[page].rowsPerPage = value;
    },

    setStatusSelector: (state, action) => {
      const { page, value } = action.payload;
      state.pages[page].statusSelector = value;
    },
    clearStore: () => initialState,
  },
});

export const {
  setFirst,
  selectMonthEmp,
  selectYearEmp,
  setPageRows,
  setStatusSelector,
  clearStore,
} = paginatorSlice.actions;

export default paginatorSlice.reducer;
