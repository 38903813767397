import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import { Toast } from "primereact/toast";
import axios from "axios";
import { PROJECT_API, TIME_SHEET_API } from "../../constants/api";
import { Tooltip } from "primereact/tooltip";
import { FaPencil } from "react-icons/fa6";
import { FaTrash } from "react-icons/fa";
import AddTimeSheet from "./addTimeSheet";
import EditTimeSheet from "./editTimeSheet";
import { InputText } from "primereact/inputtext";

const TimeSheet = () => {
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [projectListData, setProjectListData] = useState([]);
  const [deleteData, setDeleteData] = useState(null);
  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] =
    useState(null);
  const [timeSheetData, setTimeSheetData] = useState([]);
  const [inputData, setInputData] = useState({
    date: "",
    startTime: "",
    endTime: "",
    title: null,
    description: "",
  });
  const [editVisible, setEditVisible] = useState(false);
  const [editData, setEditData] = useState({
    date: moment().format("YYYY-MM-DD"),
    startTime: "",
    endTime: "",
    title: null,
    description: "",
  });
  const [updateId, setUpdateId] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [editErrors, setEditErrors] = useState({
    date: false,
    startTime: false,
    endTime: false,
    title: false,
    description: false,
  });

  const [errors, setErrors] = useState({
    date: false,
    startTime: false,
    endTime: false,
    title: false,
    description: false,
  });

  const [filteredTimeSheetData, setFilteredTimeSheetData] = useState([]);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);

  useEffect(() => {
    if (selectedDateFilter) {
      const filteredData = timeSheetData.filter(
        (entry) =>
          moment(entry.date).format("YYYY-MM-DD") === selectedDateFilter
      );
      setFilteredTimeSheetData(filteredData);
    } else {
      setFilteredTimeSheetData(timeSheetData);
    }
  }, [selectedDateFilter, timeSheetData]);

  useEffect(() => {
    getTimeSheetData();
    getProjectData();
  }, []);

  const showToast = (severity, detail) => {
    toast.current.clear();
    toast.current.show({ severity, detail, life: 3000 });
  };

  const getTimeSheetData = () => {
    const userDataString = localStorage.getItem("user");

    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const userid = userData.data.id;
      axios
        .get(TIME_SHEET_API.TIME_SHEET_GET(userid))
        .then(({ data }) => {
          setTimeSheetData(data);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });
    } else {
      console.error("User data not found in local storage");
    }
  };

  const getProjectData = async () => {
    axios
      .get(PROJECT_API.GET_ALL_PROJECTS)
      .then(({ data }) => {
        const projectsFromDB = data;
        const projectsFormatted = projectsFromDB.map((project) => ({
          label: project?.project_name,
          value: project?.project_id,
        }));
        setProjectListData(projectsFormatted);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({
      ...inputData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: false });
  };

  const handleDropdownChange = (e) => {
    const selectedProject = e.value;
    setSelectedTitle(selectedProject);
    setInputData({
      ...inputData,
      title: selectedProject?.value || null,
    });
    setErrors({ ...errors, title: false });
  };

  const validateTimeRange = () => {
    if (inputData.startTime >= inputData.endTime) {
      setInputData((prevData) => ({
        ...prevData,
        startTime: "",
        endTime: "",
      }));

      setErrors((prevErrors) => ({
        ...prevErrors,
        startTime: "Start time must be earlier than end time",
      }));

      return false;
    }
    return true;
  };

  const handleSubmit = () => {

    if (!validateTimeRange()) {
      return;
    }

    const validationErrors = {};

    if (!inputData.date) validationErrors.date = "Date is required.";
    if (!inputData.startTime) validationErrors.startTime = "Start time is required.";
    if (!inputData.endTime) validationErrors.endTime = "End time is required.";
    if (!selectedTitle) validationErrors.title = "Title is required.";
    if (!inputData.description) validationErrors.description = "Description is required.";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      showToast("error", "Please fill in all required fields.");
      return;
    }

    const userDataString = localStorage.getItem("user");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const userid = userData.data.id;

      const postTimeSheetData = {
        date: inputData.date,
        start_time: inputData.startTime,
        end_time: inputData.endTime,
        userid: userid,
        title: selectedTitle,
        description: inputData.description,
      };

      axios
        .post(`${TIME_SHEET_API.TIME_SHEET_ADD}`, postTimeSheetData)
        .then(({ data }) => {
          getTimeSheetData();
          handleSubmitClear();
          showToast("success", "Timesheet applied successfully.");
        })
        .catch((error) => {
          console.error("Error Timesheet Request applied", error.message);
          const errorMessage =
            error.response?.data?.message || "Error Timesheet Request applied";
          showToast("error", errorMessage);
        });
    } else {
      console.error("User data not found in local storage");
    }
  };

  const handleDelete = (data) => {
    setDeleteData(data);
    setDisplayDeleteConfirmation(true);
  };

  const confirmDeleteList = async () => {
    try {
      await axios.delete(TIME_SHEET_API.TIME_SHEET_DELETE(deleteData.id));
      getTimeSheetData();
      showToast("success", "List deleted successfully");
      setDeleteData(null);
      setDisplayDeleteConfirmation(false);
    } catch (error) {
      console.error("Error deleting list application:", error);
      showToast("error", "Error deleting list");
    }
  };

  const cancelDelete = () => {
    setDeleteData(null);
    setDisplayDeleteConfirmation(false);
  };

  const handleSubmitClear = () => {
    setVisible(false);
    setInputData({
      date: "",
      startTime: "",
      endTime: "",
      title: null,
      description: "",
    });
    setSelectedTitle(null);
    setErrors({
      date: false,
      startTime: false,
      endTime: false,
      title: false,
      description: false,
    });
  };
  const handleEdit = (entry) => {
    setUpdateId(entry.id);
    setEditData({
      date: entry.date,
      startTime: entry.start_time,
      endTime: entry.end_time,
      title: entry.title,
      description: entry.description,
    });
    const selectedProjectObj = projectListData.find(
      (project) => project.value === parseInt(entry.title)
    );
    setSelectedTitle(selectedProjectObj.value);
    setEditVisible(true);
  };
  const handleUpdate = () => {
    const validationErrors = {};

    if (!editData.description) {
      validationErrors.description = true;
    }

    if (!selectedTitle) {
      validationErrors.title = true;
    }

    if (Object.keys(validationErrors).length > 0) {
      setEditErrors(validationErrors);
      return;
    }

    const userDataString = localStorage.getItem("user");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const userid = userData.data.id;

      const updateTimeSheetData = {
        date: editData.date,
        start_time: editData.startTime,
        end_time: editData.endTime,
        userid: userid,
        title: selectedTitle,
        description: editData.description,
      };

      axios
        .put(
          `${TIME_SHEET_API.TIME_SHEET_UPDATE(updateId)}`,
          updateTimeSheetData
        )
        .then(({ data }) => {
          getTimeSheetData();
          handleClose();
          showToast("success", "Timesheet updated successfully.");
        })
        .catch((error) => {
          console.error("Error updating timesheet:", error.message);
          const errorMessage =
            error.response?.data?.message || "Error updating timesheet";
          showToast("error", errorMessage);
        });
    } else {
      console.error("User data not found in local storage");
    }
  };

  const getStatusText = (status) => {
    switch (status.toLowerCase()) {
      case "pending":
        return {
          text: "Not yet Approved",
          bgColor: "#EBE9F5",
          color: "#6C28D6",
        };
      case "approved":
        return { text: "Approved", bgColor: "#615EFC", color: "#fff" };
      case "rejected":
        return { text: "Not Approved", bgColor: "#FF204E", color: "#fff" };
      default:
        return { text: status, bgColor: "#EBE9F5", color: "" };
    }
  };

  const projectTitleView = (id) => {
    const project = projectListData.find(
      (project) => project.value === parseInt(id)
    );

    return project ? project.label : "";
  };

  function calculateTotalHours(startTime, endTime) {
    const start = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");
    const duration = moment.duration(end.diff(start));
    const hours = duration.hours().toString().padStart(2, "0");
    const minutes = duration.minutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }
  const handleClose = () => {
    setEditVisible(false);
    setEditData({
      date: "",
      startTime: "",
      endTime: "",
      title: null,
      description: "",
    });
    setSelectedTitle(null);
    setEditErrors({
      date: false,
      startTime: false,
      endTime: false,
      title: false,
      description: false,
    });
  };



  return (
    <div>
      <div className="col-12 md:flex align-items-center block justify-content-between w-full">
        <Toast ref={toast} />
        <h2 style={{ marginBottom: "16px" }}>Time Sheet</h2>
        <div className="lg:col-2 md:col-5 col-12 mt-0">
          <div className="p-inputgroup">
            <InputText
              type="date"
              id="dateFilter"
              value={selectedDateFilter}
              onChange={(e) => setSelectedDateFilter(e.target.value)}
            />
            <Button icon="fi fi-bs-search" className="search-icon" />
          </div>
        </div>
      </div>

      <div className="card">
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "5px",
            flexWrap: "wrap",
            marginBottom: "16px",
          }}
        >
          <h2 className="" style={{ color: "black" }}>
            Activities
          </h2>
          <Button
            label="Add New Entry"
            style={{ fontSize: "16px" }}
            onClick={(e) => {
              e.preventDefault();
              setVisible(true);
            }}
          />
        </div>

        {/* read data */}

        <div>
          <div className="time-sheet-data">
            {Object.entries(
              filteredTimeSheetData.reduce((acc, entry) => {
                const date = moment(entry.date).format("YYYY-MM-DD");
                if (!acc[date]) {
                  acc[date] = [];
                }
                acc[date].push(entry);
                return acc;
              }, {})
            ).sort(([date1], [date2]) => moment(date2, 'YYYY-MM-DD').valueOf() - moment(date1, 'YYYY-MM-DD').valueOf())
              .map(([date, entries]) => {
                const totalMinutes = entries.reduce((total, entry) => {
                  const start = moment(entry.start_time, "HH:mm");
                  const end = moment(entry.end_time, "HH:mm");
                  return total + end.diff(start, "minutes");
                }, 0);
                const totalHours = Math.floor(totalMinutes / 60);
                const totalMinutesRemainder = totalMinutes % 60;
                return (
                  <div
                    key={date}
                    style={{
                      background: "#eee",
                      padding: "6px",
                      marginBottom: "16px",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "8px",
                        marginBottom: "8px",
                        padding: "8px",
                      }}
                    >
                      <div className="">
                        <h4
                          style={{
                            minWidth: "300px",
                          }}
                        >
                          {moment(date, "YYYY-MM-DD").format(
                            "dddd, MMMM D, YYYY"
                          )}
                        </h4>
                      </div>
                      <div className="total working">
                        Total:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {totalHours}:
                          {totalMinutesRemainder < 10
                            ? `0${totalMinutesRemainder}`
                            : totalMinutesRemainder}
                        </span>
                      </div>
                    </div>
                    <div>
                      {entries.map((entry, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: "10px",
                            background: "#fff",
                            borderRadius: "5px",
                            padding: "16px",
                          }}
                        >
                          <div
                            className=""
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                              gap: "8px",
                            }}
                          >
                            <div
                              className=""
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                                minWidth: "200px",
                              }}
                            >
                              <span
                                className={`description-tooltip-${entry.id}`}
                                style={{ fontWeight: "500" }}
                              >
                                {entry.description.length > 20 ? (
                                  <>
                                    {entry.description.slice(0, 51)}...
                                    <Tooltip
                                      target={`.description-tooltip-${entry.id}`}
                                      position="top"
                                    >
                                      {entry.description}
                                    </Tooltip>
                                  </>
                                ) : (
                                  entry.description
                                )}
                              </span>
                              <span
                                className={`title-tooltip-${entry.id}`}
                                style={{
                                  background: "#EBE9F5",
                                  color: "#6C28D6",
                                  borderRadius: "9px",
                                  padding: "5px 5px",
                                }}
                              >
                                {projectTitleView(entry.title) && (
                                  <>
                                    {projectTitleView(entry.title)?.length >
                                      10 ? (
                                      <>
                                        {projectTitleView(entry.title)?.slice(
                                          0,
                                          10
                                        )}
                                        ...
                                        <Tooltip
                                          target={`.title-tooltip-${entry.id}`}
                                          position="right"
                                        >
                                          {projectTitleView(entry.title)}
                                        </Tooltip>
                                      </>
                                    ) : (
                                      projectTitleView(entry.title)
                                    )}
                                  </>
                                )}
                              </span>
                            </div>
                            <div
                              className=""
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                gap: "8px",
                              }}
                            >
                              <span
                                style={{
                                  background: `${getStatusText(entry.status).bgColor
                                    }`,
                                  color: `${getStatusText(entry.status).color}`,
                                  borderRadius: "5px",
                                  padding: " 1px 5px",
                                  marginRight: "4px",
                                }}
                              >
                                {getStatusText(entry.status).text}
                              </span>
                              <span
                                style={{
                                  letterSpacing: "1.5px",
                                  fontWeight: "500",
                                }}
                              >
                                {entry.start_time}-{entry.end_time}
                                <span
                                  style={{
                                    color: "#eee",
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    margin: " 0px 2px ",
                                  }}
                                >
                                  |
                                </span>
                                <span className="total hrs">
                                  {calculateTotalHours(
                                    entry.start_time,
                                    entry.end_time
                                  )}
                                </span>{" "}
                              </span>
                              {entry.status !== "Approved" && entry.status !== "Rejected" && (
                                <span
                                  style={{
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                  }}
                                >
                                  <FaPencil
                                    onClick={() => handleEdit(entry)}
                                    style={{ color: "#615EFC" }}
                                  />

                                  <FaTrash
                                    onClick={() => handleDelete(entry)}
                                    style={{ color: "red" }}
                                  />

                                  <Dialog
                                    visible={
                                      displayDeleteConfirmation &&
                                      deleteData === entry
                                    }
                                    onHide={cancelDelete}
                                    modal
                                    position="center"
                                    draggable={false}
                                    breakpoints={{
                                      "960px": "75vw",
                                      "641px": "100vw",
                                    }}
                                    header="Confirm Deletion"
                                    footer={
                                      <div>
                                        <Button
                                          style={{
                                            color: "white",
                                            background: "red",
                                            border: "none",
                                          }}
                                          label="Cancel"
                                          icon="pi pi-times"
                                          onClick={cancelDelete}
                                        />
                                        <Button
                                          label="Delete"
                                          icon="pi pi-check"
                                          style={{
                                            color: "white",
                                            background: "#22c55e",
                                            border: "none",
                                          }}
                                          onClick={confirmDeleteList}
                                          autoFocus
                                        />
                                      </div>
                                    }
                                  >
                                    <div>
                                      <p>Are you sure you want to delete data?</p>
                                    </div>
                                  </Dialog>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <AddTimeSheet
        visible={visible}
        handleSubmitClear={handleSubmitClear}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleDropdownChange={handleDropdownChange}
        inputData={inputData}
        errors={errors}
        projectListData={projectListData}
        selectedTitle={selectedTitle}
        setSelectedTitle={setSelectedTitle}
      />
      <EditTimeSheet
        editVisible={editVisible}
        handleClose={handleClose}
        handleUpdate={handleUpdate}
        handleChange={handleChange}
        handleDropdownChange={handleDropdownChange}
        editData={editData}
        setEditData={setEditData}
        errors={editErrors}
        projectListData={projectListData}
        selectedTitle={selectedTitle}
        setSelectedTitle={setSelectedTitle}
      />
    </div>
  );
};

export default TimeSheet;