import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { Tooltip } from "primereact/tooltip";
import { FaBook } from "react-icons/fa";
import { FaBookBookmark } from "react-icons/fa6";
import { GiPalmTree } from "react-icons/gi";
import moment from "moment/moment";
import { PERMISSION_API } from "../../../../constants/api";

export default function Permissions({ userId }) {
    const [products, setProducts] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [remainingAnnualLeave, setRemainingAnnualLeave] = useState([]);

    useEffect(() => {
        fetchPermissionData();
    }, []);

    const fetchPermissionData = () => {
        axios
            .get(PERMISSION_API.PERMISSION_GET_BYID(userId))
            .then((response) => {
                setProducts(response.data.permissions);
                setRemainingAnnualLeave(response.data);
            })
            .catch((error) => {
                console.error("Error fetching user data:", error.message);
            });

    };


    const statusTemplate = (rowData) => {
        let color, label;

        switch (rowData.status) {
            case "Pending":
                color = "#FEFAD3";
                label = "Pending";
                break;
            case "Approved":
                color = "#C6EFCD";
                label = "Approved";
                break;
            case "Rejected":
                color = "#FFC8CE";
                label = "Rejected";
                break;
            default:
                color = "gray";
                label = "Unknown";
        }

        return (
            <span
                style={{
                    backgroundColor: color,
                    color: "black",
                    padding: "5px",
                    borderRadius: "5px",
                }}
            >
                {label}
            </span>
        );
    };

    return (
        <div className="mt-2">
            <div className="flex justify-content-end mb-2">
                <div className="w-15rem">
                    <div className="p-inputgroup">
                        <InputText
                            type="text"
                            className="search-input p-inputtext p-component"
                            placeholder="Search"
                            value={globalFilter}
                            onChange={(e) => setGlobalFilter(e.target.value)}
                        />
                        <Button icon="pi pi-search" className="search-icon" />
                    </div>
                </div>
            </div>
            <div className="permision-card flex flex-wrap gap-5  justify-content-evenly align-items-center mt-5">
                <div className="card md:mt-0 mt-3">
                    <div
                        className="img-permi"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            fontSize: "2rem",
                            background: "#F7F1FD",
                            padding: "7px 7px",
                            borderRadius: "10px",
                            color: "#9B51E0",
                        }}
                    >
                        <FaBook />
                    </div>
                    <div className="permi-text">
                        <p>{remainingAnnualLeave.permission_taken || 0}</p>
                        <h3>Permissions Taken</h3>
                    </div>
                </div>
                <div className="card md:mt-0 mt-3">
                    <div
                        className="img-permi"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            fontSize: "2rem",
                            background: "#EFF5FE",
                            padding: "7px 7px",
                            borderRadius: "10px",
                            color: "#2F80ED",
                        }}
                    >
                        <GiPalmTree />
                    </div>
                    <div className="permi-text">
                        <p>{remainingAnnualLeave.balance_permissions || 0}</p>
                        <h3>Balance Permission</h3>
                    </div>
                </div>
                <div className="card md:mt-0 mt-3">
                    <div
                        className="img-permi"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            fontSize: "2rem",
                            background: "#EEF7F2",
                            padding: "7px 7px",
                            color: "#219653",
                            borderRadius: "10px",
                        }}
                    >
                        <FaBookBookmark />
                    </div>
                    <div className="permi-text">
                        <p>{remainingAnnualLeave.annual_permissions || 0}</p>
                        <h3>Annual Permissions</h3>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <DataTable
                    value={Array.isArray(products) ? products : []}
                    paginator
                    rows={5}
                    globalFilter={globalFilter.trim() ? globalFilter : null}
                >
                    <Column
                        field="date"
                        header="Date"
                        body={(rowData) => moment(rowData.date).format("DD-MM-YYYY")}
                        style={{ minWidth: "105px" }}
                    />
                    <Column
                        field="no_of_hours"
                        header="No Of Hours"
                        style={{ minWidth: "120px" }}
                    />
                    <Column
                        field="reason"
                        header="Reason"
                        body={(rowData) => (
                            <div id={`reason_${rowData.id}`} className="tooltip-container">
                                {rowData.reason.length > 20
                                    ? `${rowData.reason.slice(0, 20)}...`
                                    : rowData.reason}
                                <Tooltip target={`#reason_${rowData.id}`} position="right">
                                    {rowData.reason}
                                </Tooltip>
                            </div>
                        )}
                    />
                    <Column
                        field="status"
                        header="Status"
                        body={statusTemplate}
                        style={{ minWidth: "100px" }}
                    />
                </DataTable>
            </div>
        </div>
    );
}
