import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import axios from "axios";
import { PAYROLL_API } from "../../constants/api";

export default function DeletePayroll({
  deleteModalOpen,
  closeDeleteModal,
  fetchPayrollData,
}) {
  const toast = useRef(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [errors, setErrors] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeletePayroll = async (e) => {
    e.preventDefault();

    if (isDeleting) return;

    let valid = true;
    let newErrors = {};

    if (!fromDate) {
      newErrors.fromDate = "Please select a from date.";
      valid = false;
    }

    if (!toDate) {
      newErrors.toDate = "Please select a to date.";
      valid = false;
    }

    setErrors(newErrors);

    if (!valid) return;

    setIsDeleting(true);

    try {
      const response = await axios.delete(
        PAYROLL_API.DELETE_PAYROLL_BY_FROM_DATE_AND_TO_DATE(fromDate, toDate)
      );
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Payroll details deleted successfully",
        life: 3000,
      });
      handleCloseDeleteModal();
      fetchPayrollData();
    } catch (error) {
      console.error("Error deleting payroll details:", error);
      if (error.response && error.response.data && error.response.data.message) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error deleting payroll details",
          life: 3000,
        });
      }
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCloseDeleteModal = () => {
    setFromDate("");
    setToDate("");
    setErrors({});
    closeDeleteModal();
  };

  return (
    <div className="emp-profile">
      <Toast ref={toast} />
      <Dialog
        visible={deleteModalOpen}
        onHide={handleCloseDeleteModal}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        header="DELETE MONTHLY PAYROLL"
      >
        <div className="grid">
          <div className="col-12">
            <label htmlFor="fromDate">From Date</label>
            <InputText
              id="fromDate"
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              className={errors.fromDate ? "p-invalid w-full" : "w-full"}
            />
            {errors.fromDate && (
              <small className="p-error">{errors.fromDate}</small>
            )}
          </div>

          <div className="col-12">
            <label htmlFor="toDate">To Date</label>
            <InputText
              id="toDate"
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              className={errors.toDate ? "p-invalid w-full" : "w-full"}
            />
            {errors.toDate && (
              <small className="p-error">{errors.toDate}</small>
            )}
          </div>
        </div>

        <div className="mt-3 flex justify-content-end">
          <Button
            label="Cancel"
            className="p-button-danger mr-2"
            onClick={handleCloseDeleteModal}
          />
          <Button
            label="Delete Payroll"
            className="p-button-raised ml-2"
            onClick={handleDeletePayroll}
            disabled={isDeleting}
          />
        </div>
      </Dialog>
    </div>
  );
}
