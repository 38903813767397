import React, { useState, useRef, useEffect } from "react";
import propic from "../../assets/images/Logo.svg";
import logo from "../../assets/images/Logo.svg";
import avatar from "../../assets/images/avatar.webp";
import { Tooltip } from "react-tooltip";
import { DEPARTMENT_API, EMPLOYEE_API, TASK_API } from "../../constants/api";
import axios from "axios";

const Hierarchy = () => {
  const [float2, setFloat2] = useState(false);
  const [float3, setFloat3] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [reportingManager, setReportingManagers] = useState([]);
  const [employeesByManagerId, setEmployeesByManagerId] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const toggleFloat2 = async (departmentId, managerId) => {
    setFloat2(float2 === departmentId ? "" : departmentId);
    setFloat3("");
  };

  const toggleFloat3 = async (departmentId, managerId) => {
    const employeesByManagerId = {};
    const employees = await fetchEmployeesByReportingManagerId(managerId);
    const departmentMatchedEmployees = employees.filter(
      (employee) => employee.department === departmentId.toString()
    );
    employeesByManagerId[managerId] = departmentMatchedEmployees;
    const totalCount = departmentMatchedEmployees.length;
    setTotalCount(totalCount);
    setEmployeesByManagerId(employeesByManagerId);
    setFloat3(departmentId);
  };

  const fetchEmployeesByReportingManagerId = async (reportingManagerId) => {
    try {
      const response = await axios.get(
        EMPLOYEE_API.REPORTING_MANAGER_BYID(reportingManagerId)
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching employees by reporting manager ID:", error);
      return [];
    }
  };

  const fetchReportingManager = async (departmentId) => {
    if (departmentId) {
      setReportingManagers([]);
      try {
        const response = await axios.get(
          `${EMPLOYEE_API.REPORTING_MANAGER_BYDEPARTMNET}?department=${departmentId}`
        );
        if (response.status === 200) {
          const reportingManagers =
            response.data.length > 0 ? response.data : [];

          const reportingManagersWithCounts = await Promise.all(
            reportingManagers.map(async (manager) => {
              const employees = await fetchEmployeesByReportingManagerIdCount(
                manager.id
              );
              const departmentMatchedEmployees = employees.filter(
                (employee) => employee.department === departmentId.toString()
              );
              return {
                ...manager,
                employeeCount: departmentMatchedEmployees.length,
              };
            })
          );

          setReportingManagers(reportingManagersWithCounts);
        }
      } catch (error) {
        console.error("Error fetching fetchReportingManager:", error.message);
        setReportingManagers([]);
      }
    } else {
      setReportingManagers([]);
    }
  };

  useEffect(() => {
    fetchReportingManager(float2);
  }, [float2]);

  useEffect(() => {
    fetchReportingManager(float3);
  }, [float3]);

  const getAllDepartment = async () => {
    try {
      const response = await axios.get(DEPARTMENT_API.DEPARTMENT_FETCH);
      setDepartments(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  useEffect(() => {
    getAllDepartment();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.GET_ALL);
      if (response.status === 200) {
        const employeesData = response.data.usersData;
        setAllEmployees(employeesData);
      }
    } catch (error) {
      console.error("Error fetching employees:", error.message);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchAllTasks = async () => {
    try {
      const response = await axios.get(TASK_API.GET_ALL_TASK);
      setTasks(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  useEffect(() => {
    fetchAllTasks();
  }, []);

  const getTasksForEmployee = (empid) => {
    const currentDate = new Date().toISOString().split("T")[0];
    return tasks.filter(
      (task) => task.Processer === empid && task.StartDate === currentDate
    );
  };

  const fetchEmployeesByReportingManagerIdCount = async (
    reportingManagerId
  ) => {
    try {
      const response = await axios.get(
        EMPLOYEE_API.REPORTING_MANAGER_BYID(reportingManagerId)
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching employees by reporting manager ID:", error);
      return [];
    }
  };

  const ceos = allEmployees.filter((employee) => employee.position === "CEO");
  return (
    <div className="hierarchy">
      <div
        className="card overflow-x-auto"
        style={{ height: "94vh", overflowY: "scroll" }}
      >
        {/* Company */}
        <div>
          <div className="empcard">
            <img
              src={logo}
              alt="Company Logo"
              style={{ width: "2.5rem", borderRadius: "5px" }}
            />
          </div>
          <div className="arrow"></div>

          {/* Kodukku */}
          <div className="empcard" style={{ marginTop: "18px" }}>
            <p>Kodukku</p>
          </div>
          <div className="arrow"></div>

          {/* Branches */}
          <div className="empcard" style={{ marginTop: "18px" }}>
            <p>Branches</p>
          </div>
          <div className="arrow"></div>

          {/* CEO */}
          <div className="empcard" style={{ marginTop: "18px" }}>
            {ceos.map((ceo) => (
              <div className="flex">
                <img
                  src={propic}
                  alt="Profile"
                  style={{ width: "2.5rem", borderRadius: "5px" }}
                />
                <div className="ml-2">
                  <h5>{ceo.firstname}</h5>
                  <h5>{ceo.lastname}</h5>
                  <p>{ceo.position}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="arrow"></div>

          {/* Co-Founder */}
          <div className="empcard" style={{ marginTop: "18px" }}>
            <div className="flex">
              <img
                src={propic}
                alt="Profile"
                style={{ width: "2.5rem", borderRadius: "5px" }}
              />
              <div className="ml-2">
                <h5>Name</h5>
                <p>Co-Founder</p>
              </div>
            </div>
          </div>
          <div className="arrow"></div>
          {/* Departments */}
          <div className="empcard" style={{ marginTop: "18px" }}>
            <h5>Departments({departments.length})</h5>
          </div>
          <div className="arrow"></div>
        </div>

        {departments.map((department, index) => (
          <div key={index} className="step2" style={{ position: "relative" }}>
            <div
              className="empcard"
              style={{ marginTop: "18px" }}
              onClick={() => toggleFloat2(department.department_id)}
            >
              <h4>{department.department_name}</h4>
            </div>
            {index !== departments.length - 1 && <div className="arrow"></div>}
            {float2 === department.department_id && (
              <div className="">
                {/* Float content */}
                <div
                  style={{
                    position: "absolute",
                    top: "4rem",
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                  }}
                  onClick={() => {
                    toggleFloat3(department.department_id);
                  }}
                >
                  <div
                    className="arrowside1"
                    style={{ position: "relative" }}
                  ></div>
                  {reportingManager.map((employee, index) => (
                    <div
                      key={employee.id}
                      style={{
                        position: "relative",
                        marginLeft: "32.2rem",
                      }}
                      onClick={() =>
                        toggleFloat3(department.department_id, employee.id)
                      }
                    >
                      <div
                        className="empcardside2"
                        style={{
                          position: "relative",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <p className="text-right">
                          <span
                            style={{
                              color: "rgb(7, 194, 113)",
                              marginRight: "2px",
                              fontSize: "6px",
                            }}
                          >
                            <i className="fi fi-ss-circle"></i>
                          </span>
                          {employee.employment_status}
                        </p>
                        <div className="flex">
                          <img src={avatar} alt="" />
                          <div className="text ml-2">
                            <h5>{employee.firstname}</h5>
                            <p>{employee.position}</p>
                            <p style={{ fontWeight: "600" }}>
                              Team Member [{employee.employeeCount}]
                            </p>
                            <p>{employee.joining_date}</p>
                          </div>
                        </div>
                        <div className="flex justify-content-between">
                          <p>{employee.id}</p>
                          <p>
                            <i
                              className="fi fi-sr-briefcase"
                              data-tooltip-id={`my-tooltip4-${index}`}
                            ></i>
                            <Tooltip
                              id={`my-tooltip4-${index}`}
                              style={{
                                background: "transparent",
                                userSelect: "text",
                              }}
                            >
                              <div
                                className="card"
                                style={{
                                  opacity: "1",
                                  border: "2px solid #2196F3",
                                  background: "#e9f5ff",
                                  width: "300px",
                                }}
                              >
                                <p style={{ color: "#000" }}>
                                  Responsibilities
                                </p>
                                <ol>
                                  <span style={{ color: "#000" }}>
                                    {employee.responsibilities}
                                  </span>
                                </ol>
                              </div>
                            </Tooltip>
                            <i
                              className="fi fi-sr-note mx-2"
                              data-tooltip-id={`tolltiptask-${employee.empid}`}
                            ></i>
                            <Tooltip id={`tolltiptask-${employee.empid}`}>
                              Task:{" "}
                              <span>
                                {getTasksForEmployee(employee.empid).length > 0
                                  ? getTasksForEmployee(employee.empid)[0]
                                      .task_name
                                  : "No tasks for today"}
                              </span>
                            </Tooltip>
                          </p>
                          <p
                            data-tooltip-id={`view-details-tooltip-${index}`}
                            style={{
                              color: "#2196F3",
                              fontSize: "10px",
                              cursor: "pointer",
                            }}
                          >
                            View Details
                          </p>
                          <Tooltip
                            id={`view-details-tooltip-${index}`}
                            style={{
                              background: "transparent",
                              border: "2px solid grey",
                            }}
                          >
                            <div
                              className="card"
                              style={{
                                opacity: "1",
                                background: "#e9f5ff",
                                width: "20",
                                boxShadow:
                                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                                border: "2px solid #2196F3",
                              }}
                            >
                              <div className="flex">
                                <img
                                  src={avatar}
                                  alt=""
                                  style={{ height: "60px" }}
                                />
                                <div className="text ml-4">
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Name:{" "}
                                    <span>
                                      {employee.firstname} {employee.lastname}
                                    </span>
                                  </p>
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Staff ID: <span>{employee.empid}</span>
                                  </p>
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Designation:{" "}
                                    <span>{employee.position}</span>
                                  </p>
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Department:{" "}
                                    <span>{employee.department}</span>
                                  </p>
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: "16px",
                                    }}
                                  >
                                    D.O.B: <span>{employee.DOB}</span>
                                  </p>
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Mail ID:{" "}
                                    <span style={{ color: "#2196F3" }}>
                                      {employee.email}
                                    </span>
                                  </p>
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Contact No:{" "}
                                    <span>{employee.contact_number}</span>
                                  </p>
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Status:{" "}
                                    <span style={{ color: "red" }}>
                                      {employee.employment_status}
                                    </span>
                                  </p>
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Notice Period: <span>0</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                      {reportingManager.length > 1 &&
                        index < reportingManager.length - 1 && (
                          <div className="arrowside2"></div>
                        )}

                      {float3 === department.department_id && (
                        <div
                          style={{
                            position: "absolute",
                            left: "32.2rem",
                            top: "4rem",
                          }}
                          onClick={() => {
                            toggleFloat3(department.department_id);
                          }}
                        >
                          {employeesByManagerId &&
                            employeesByManagerId[employee.empid] && (
                              <div>
                                {Array.isArray(
                                  employeesByManagerId[employee.empid]
                                ) &&
                                  employeesByManagerId[employee.empid].map(
                                    (subEmployee, subIndex) => (
                                      <div
                                        key={subEmployee.id}
                                        className="empcardside3"
                                        style={{
                                          position: "relative",
                                          marginBottom: "1.3rem",
                                        }}
                                      >
                                        <p className="text-right">
                                          <span
                                            style={{
                                              color: "rgb(7, 194, 113)",
                                              marginRight: "2px",
                                              fontSize: "6px",
                                            }}
                                          >
                                            <i className="fi fi-ss-circle"></i>
                                          </span>
                                          {subEmployee.employment_status}
                                        </p>
                                        <div className="flex">
                                          <img src={avatar} alt="" />
                                          <div className="text ml-2">
                                            <h5>{subEmployee.firstname}</h5>
                                            <p>{subEmployee.position}</p>
                                            <p>{subEmployee.joining_date}</p>
                                          </div>
                                        </div>
                                        <div className="flex justify-content-between">
                                          <p>{subEmployee.id}</p>
                                          <p>
                                            <i
                                              className="fi fi-sr-briefcase"
                                              data-tooltip-id={`my-tooltip4-${index}-${subIndex}`}
                                            ></i>
                                            <Tooltip
                                              id={`my-tooltip4-${index}-${subIndex}`}
                                              style={{
                                                background: "transparent",
                                                userSelect: "text",
                                              }}
                                            >
                                              <div
                                                className="card"
                                                style={{
                                                  opacity: "1",
                                                  border: "2px solid #2196F3",
                                                  background: "#e9f5ff",
                                                  width: "300px",
                                                }}
                                              >
                                                <p style={{ color: "#000" }}>
                                                  Responsibilities
                                                </p>
                                                <ol>
                                                  <span
                                                    style={{ color: "#000" }}
                                                  >
                                                    {
                                                      subEmployee.responsibilities
                                                    }
                                                  </span>
                                                </ol>
                                              </div>
                                            </Tooltip>
                                            <i
                                              className="fi fi-sr-note mx-2"
                                              data-tooltip-id={`tolltiptask-${subEmployee.empid}`}
                                            ></i>
                                            <Tooltip
                                              id={`tolltiptask-${subEmployee.empid}`}
                                            >
                                              Task:{" "}
                                              <span>
                                                {getTasksForEmployee(
                                                  subEmployee.empid
                                                ).length > 0
                                                  ? getTasksForEmployee(
                                                      subEmployee.empid
                                                    )[0].task_name
                                                  : "No tasks for today"}
                                              </span>
                                            </Tooltip>
                                          </p>
                                          <p
                                            data-tooltip-id={`view-details-tooltip-${index}-${subIndex}`}
                                            style={{
                                              color: "#2196F3",
                                              fontSize: "10px",
                                            }}
                                          >
                                            View Details
                                          </p>
                                          <Tooltip
                                            id={`view-details-tooltip-${index}-${subIndex}`}
                                            style={{
                                              background: "transparent",
                                              border: "2px solid grey",
                                            }}
                                          >
                                            <div
                                              className="card"
                                              style={{
                                                opacity: "1",
                                                background: "#e9f5ff",
                                                width: "20",
                                                boxShadow:
                                                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                                                border: "2px solid #2196F3",
                                              }}
                                            >
                                              <div className="flex">
                                                <img
                                                  src={avatar}
                                                  alt=""
                                                  style={{ height: "60px" }}
                                                />
                                                <div className="text ml-4">
                                                  <p
                                                    style={{
                                                      color: "#000",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    Name:{" "}
                                                    <span>
                                                      {subEmployee.firstname}{" "}
                                                      {subEmployee.lastname}
                                                    </span>
                                                  </p>
                                                  <p
                                                    style={{
                                                      color: "#000",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    Staff ID:{" "}
                                                    <span>
                                                      {subEmployee.empid}
                                                    </span>
                                                  </p>
                                                  <p
                                                    style={{
                                                      color: "#000",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    Designation:{" "}
                                                    <span>
                                                      {subEmployee.position}
                                                    </span>
                                                  </p>
                                                  <p
                                                    style={{
                                                      color: "#000",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    Department:{" "}
                                                    <span>
                                                      {subEmployee.department}
                                                    </span>
                                                  </p>
                                                  <p
                                                    style={{
                                                      color: "#000",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    D.O.B:{" "}
                                                    <span>
                                                      {subEmployee.DOB}
                                                    </span>
                                                  </p>
                                                  <p
                                                    style={{
                                                      color: "#000",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    Mail ID:{" "}
                                                    <span
                                                      style={{
                                                        color: "#2196F3",
                                                      }}
                                                    >
                                                      {subEmployee.email}
                                                    </span>
                                                  </p>
                                                  <p
                                                    style={{
                                                      color: "#000",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    Contact No:{" "}
                                                    <span>
                                                      {
                                                        subEmployee.contact_number
                                                      }
                                                    </span>
                                                  </p>
                                                  <p
                                                    style={{
                                                      color: "#000",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    Status:{" "}
                                                    <span
                                                      style={{ color: "red" }}
                                                    >
                                                      {
                                                        subEmployee.employment_status
                                                      }
                                                    </span>
                                                  </p>
                                                  <p
                                                    style={{
                                                      color: "#000",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    Notice Period:{" "}
                                                    <span>0</span>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </Tooltip>
                                          {subIndex !==
                                            employeesByManagerId[employee.empid]
                                              .length -
                                              1 && (
                                            <div className="arrow1"></div>
                                          )}
                                          {float3 ===
                                            department.department_id &&
                                            subIndex === 0 && (
                                              <div
                                                className="arrowside3"
                                                style={{ position: "relative" }}
                                              ></div>
                                            )}
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Hierarchy;
