import React, { useState, useEffect, useRef } from "react";
import { Steps } from "primereact/steps";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { RadioButton } from "primereact/radiobutton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { EMPLOYEE_API, DEPARTMENT_API } from "../../constants/api";
import { FaInfoCircle } from "react-icons/fa";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { DesignationOptions } from "./data/Designation";
import { EmployeeTypeOptions } from "./data/EmployeeType";


export default function AddEmployee() {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    familyname: "",
    dateOfBirth: null,
    dateofjoining: null,
    gender: null,
    reportingManager: null,
    maritalStatus: "",
    empType: null,
    empStatus: null,
    phoneNumber: "",
    email: "",
    emailValid: true,
    house_no: "",
    address: "",
    salary: "",
    empid: "",
    kid_id: "",
    department: "",
    departmentData: [],
    designation: "",
    annualLeave: "",
    bankName: "",
    accountNumber: "",
    IFSC_Code: "",
    state: "",
    district: "",
    pin_code: "",
    ot_hour: "",
    responsibilities: "",
    activeIndex: 0,
    employees: [],
    phoneNumberValid: true,
    isSubmitting: false,
    ifscError: "",
    valid: true,
  });

  const [reportingManager, setReportingManager] = useState(null);
  const [employees, setEmployees] = useState([]);

  const toast = useRef(null);
  const navigate = useNavigate();

  const handleIdChange = (e) => {
    const value = e.target.value.toUpperCase();
    const isValidFormat = /^KCPL0/.test(value);
    setFormData((prevState) => ({
      ...prevState,
      empid: value,
      valid: isValidFormat,
    }));
  };

  const validateIFSC_Code = (code) => {
    const regex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    return regex.test(code);
  };

  const handleIFSC_CodeChange = (e) => {
    const newIFSC_Code = e.target.value.toUpperCase();
    const isValidIFSC = validateIFSC_Code(newIFSC_Code);
    setFormData((prevState) => ({
      ...prevState,
      IFSC_Code: newIFSC_Code,
      ifscError: isValidIFSC
        ? ""
        : "Invalid IFSC code. Please enter a valid IFSC code.",
    }));
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      email: newEmail,
      emailValid: isEmailValid(newEmail),
    }));
  };

  const handleGenderChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      gender: e.value,
    }));
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(EMPLOYEE_API.All_USERS);
        if (response.status === 200) {
          const employeesData = response.data.usersData;
          setFormData((prevState) => ({
            ...prevState,
            employees: employeesData,
          }));
          setEmployees(employeesData);
        }
      } catch (error) {
        console.error("Error fetching employees:", error.message);
      }
    };

    fetchEmployees();
  }, []);

  const getAllDepartment = async () => {
    try {
      const response = await axios.get(DEPARTMENT_API.DEPARTMENT_FETCH);
      setFormData((prevState) => ({
        ...prevState,
        departmentData: response.data,
      }));
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  useEffect(() => {
    getAllDepartment();
  }, []);

  const departmentOptions = formData.departmentData.map((department) => ({
    label: department.department_name,
    value: department.department_id,
  }));

  const handleSave = async () => {
    if (formData.isSubmitting) {
      return;
    }
    setFormData((prevState) => ({
      ...prevState,
      isSubmitting: true,
    }));

    const payload = {
      user: {
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        id: formData.empid,
        kid_id: formData.kid_id,
      },
      employeeDetails: {
        familyname: formData.familyname,
        DOB: formData.dateOfBirth,
        gender: formData.gender,
        marital_status: formData.maritalStatus,
        reporting_manager_id: formData.reportingManager,
        contact_number: formData.phoneNumber,
        house_no: formData.house_no,
        address: formData.address,
        department: formData.department,
        position: formData.designation,
        joining_date: formData.dateofjoining,
        employment_status: formData.empStatus,
        salary: formData.salary,
        employee_type: formData.empType,
        bank_name: formData.bankName,
        account_number: formData.accountNumber,
        IFSC_Code: formData.IFSC_Code,
        annual_leave: formData.annualLeave,
        annual_permissions: formData.annual_permissions,
        empid: formData.empid,
        state: formData.state,
        district: formData.district,
        pin_code: formData.pin_code,
        ot_hour: formData.ot_hour,
        responsibilities: formData.responsibilities,
      },
    };

    try {
      const response = await axios.post(EMPLOYEE_API.ADD_USER, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        showToast("success", "Data successfully added");
        setFormData((prevState) => ({
          ...prevState,
          isSubmitting: false,
        }));
        navigate("/AllEmployee");
      } else {
        showToast("error", response.data.error);
      }
    } catch (error) {
      setFormData((prevState) => ({
        ...prevState,
        isSubmitting: false,
      }));
      if (error.response && error.response.data && error.response.data.error) {
        showToast("error", error.response.data.error);
      } else {
        showToast("error", "An unexpected error occurred");
      }
    }
  };

  const MaritalStatus = [
    { label: "Single", value: "Single" },
    { label: "Married", value: "Married" },
  ];

  const EmpType = [
    { label: "Full-time", value: "Full-time" },
    { label: "Part-time", value: "Part-time" },
    { label: "Contract", value: "Contract" },
  ];

  const EmpStatus = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  const items = [
    {
      label: "Personal Info",
    },
    {
      label: "Employee Details",
    },
    {
      label: "Bank Details",
    },
  ];

  const handlePhoneNumberChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      phoneNumber: value,
      phoneNumberValid: value.replace(/\D/g, "").length >= 12,
    }));
  };

  const showToast = (severity, detail) => {
    toast.current.clear();
    toast.current.show({ severity, summary: "Validation Error", detail });
  };

  const handleNext = () => {
    let isValid = true;
    switch (formData.activeIndex) {
      case 0:
        isValid =
          !!formData.firstname &&
          !!formData.lastname &&
          !!formData.dateOfBirth &&
          !!formData.gender &&
          !!formData.maritalStatus &&
          !!formData.phoneNumber &&
          formData.emailValid &&
          !!formData.address &&
          !!formData.house_no;
        break;
      case 1:
        isValid =
          !!formData.department &&
          !!formData.designation &&
          !!formData.dateofjoining &&
          !!formData.empStatus &&
          !!formData.ot_hour &&
          !!formData.salary &&
          !!formData.empType &&
          !!formData.annualLeave &&
          !!formData.annual_permissions &&
          !!formData.responsibilities;
        break;
      case 2:
        isValid =
          !!formData.bankName &&
          !!formData.accountNumber &&
          !!formData.IFSC_Code;
        break;
      default:
        break;
    }

    if (!isValid) {
      showToast("error", "Please fill in all the required fields.");
      return;
    }

    if (formData.activeIndex < items.length - 1) {
      setFormData((prevState) => ({
        ...prevState,
        activeIndex: prevState.activeIndex + 1,
      }));
    }
  };

  const today = new Date().toISOString().split("T")[0];

  const handlePrevious = () => {
    if (formData.activeIndex > 0) {
      setFormData((prevState) => ({
        ...prevState,
        activeIndex: prevState.activeIndex - 1,
      }));
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="addEmployee card p-2 sm:p-3 md:p-5">
      <Toast ref={toast} />
      <Steps
        model={items}
        activeIndex={formData.activeIndex}
        onSelect={(e) =>
          setFormData((prevState) => ({
            ...prevState,
            activeIndex: e.index,
          }))
        }
        readOnly={false}
        style={{ marginTop: "2em", marginBottom: "1.5em" }}
      />

      {formData.activeIndex === 0 && (
        <div className="">
          <div className="grid ">
            <div className="md:col-4 col-12 mt-1 md:mt-3">
              <label htmlFor="firstname">First Name</label>
              <InputText
                keyfilter={/^[a-zA-Z\s]*$/}
                id="firstname"
                value={capitalizeFirstLetter(formData.firstname)}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    firstname: e.target.value,
                  }))
                }
              />
            </div>
            <div className="md:col-4 col-12 mt-1 md:mt-3">
              <label htmlFor="lastname">Last Name</label>
              <InputText
                id="lastname"
                keyfilter={/^[a-zA-Z\s]*$/}
                value={capitalizeFirstLetter(formData.lastname)}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    lastname: e.target.value,
                  }))
                }
              />
              <div className="text-blue-500 text-xs relative mt-2">
                <FaInfoCircle className="absolute top-[3px] mt-1" />
                <span className="ml-4">
                  Last name should be at least three letters long
                </span>
              </div>
            </div>
            <div className="md:col-4 col-12 mt-1 md:mt-3">
              <label htmlFor="familyname">Family Name</label>
              <InputText
                id="familyname"
                keyfilter={/^[a-zA-Z\s]*$/}
                value={capitalizeFirstLetter(formData.familyname)}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    familyname: e.target.value,
                  }))
                }
              />
            </div>
            <div className="md:col-4 col-12 mt-1 md:mt-3">
              <label htmlFor="dateOfBirth">Date of Birth</label>
              <InputText
                style={{ width: "100%" }}
                id="dateOfBirth"
                type="date"
                value={formData.dateOfBirth}
                max={today}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    dateOfBirth: e.target.value,
                  }))
                }
              />
              <div className="text-blue-500 text-xs relative mt-2">
                <FaInfoCircle className="absolute top-[3px]" />
                <span className="ml-4">
                  Please select a valid date of birth{" "}
                </span>
              </div>
            </div>
            <div className="md:col-4 col-12 mt-1 md:mt-3">
              <h3 className="gender-font font-medium">Gender</h3>
              <div className="flex flex-wrap gap-3">
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="male"
                    name="gender"
                    value="Male"
                    onChange={handleGenderChange}
                    checked={formData.gender === "Male"}
                  />
                  <label htmlFor="genderMale" className="ml-2 font-normal">
                    Male
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="female"
                    name="gender"
                    value="Female"
                    onChange={handleGenderChange}
                    checked={formData.gender === "Female"}
                  />
                  <label htmlFor="genderFemale" className="ml-2 font-normal">
                    Female
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="Transgender"
                    name="gender"
                    value="Transgender"
                    onChange={handleGenderChange}
                    checked={formData.gender === "Transgender"}
                  />
                  <label
                    htmlFor="genderTransgender"
                    className="ml-2 font-normal"
                  >
                    Transgender
                  </label>
                </div>
              </div>
            </div>
            <div className="md:col-4 col-12 mt-1 md:mt-3">
              <h3 className="gender-status font-medium">Marital Status</h3>
              <div className="flex flex-wrap gap-3">
                {MaritalStatus.map((status) => (
                  <div key={status.value} className="flex align-items-center">
                    <RadioButton
                      inputId={`maritalStatus_${status.value}`}
                      name="maritalStatus"
                      value={status.value}
                      onChange={(e) =>
                        setFormData((prevState) => ({
                          ...prevState,
                          maritalStatus: e.value,
                        }))
                      }
                      checked={formData.maritalStatus === status.value}
                    />
                    <label
                      htmlFor={`maritalStatus_${status.value}`}
                      className="ml-2 font-normal"
                    >
                      {status.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="md:col-4 col-12 mt-1 md:mt-3">
              <h3
                className="gender-status font-medium mb-1"
                style={{ whiteSpace: "nowrap" }}
              >
                Reporting Manager
              </h3>
              <div className="flex gap-3">
                <Dropdown
                  value={reportingManager}
                  optionLabel="fullname"
                  options={employees.map((employee) => ({
                    label: `${employee.firstname} ${employee.lastname}`,
                    value: employee.id,
                    fullname: `${employee.firstname} ${employee.lastname}`,
                  }))}
                  onChange={(e) => setReportingManager(e.value)}
                  placeholder="Reporting Manager"
                  className="w-full"
                  filter
                  showClear
                />
              </div>
            </div>
            <div className="md:col-4 col-12 mt-1 md:mt-3">
              <label htmlFor="phoneNumber">Phone Number</label>
              <PhoneInput
                country={"in"}
                value={formData.phoneNumber}
                onChange={handlePhoneNumberChange}
                inputStyle={{
                  width: "100%",
                }}
              />
              {!formData.phoneNumberValid && (
                <small className="p-error">Invalid phone number.</small>
              )}
            </div>
            <div className="md:col-4 col-12 mt-1 md:mt-3">
              <label htmlFor="email">Email</label>
              <InputText
                id="email"
                keyfilter="email"
                value={formData.email}
                onChange={handleEmailChange}
                className={!formData.emailValid ? "p-invalid" : ""}
              />
              {!formData.emailValid && (
                <small className="p-error">Invalid email address.</small>
              )}
            </div>
            <div className="md:col-2 col-12 mt-1 md:mt-3">
              <label htmlFor="house_no" style={{ whiteSpace: "nowrap" }}>
                House No
              </label>
              <InputText
                placeholder="House No"
                // keyfilter="pnum"
                value={formData.house_no}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    house_no: e.target.value,
                  }))
                }
              />
            </div>
            <div className="md:col-8 col-12 mt-1 md:mt-3">
              <label htmlFor="address">Address</label>
              <InputTextarea
                id="address"
                value={capitalizeFirstLetter(formData.address)}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    address: e.target.value,
                  }))
                }
                placeholder="Address"
                rows={1}
                cols={30}
                style={{ resize: "none" }}
              />
            </div>
            <div className="md:col-2 col-12 mt-1 md:mt-3">
              <label htmlFor="postal_code" style={{ whiteSpace: "nowrap" }}>
                Postal Code
              </label>
              <InputText
                placeholder="Postal Code"
                keyfilter="pnum"
                value={formData.pin_code}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,6}$/.test(value)) {
                    setFormData((prevState) => ({
                      ...prevState,
                      pin_code: value,
                    }));
                  }
                }}
              />
            </div>
            <div className="md:col-4 col-12 mt-1 md:mt-3">
              <label htmlFor="state">State</label>
              <InputText
                keyfilter={/^[a-zA-Z\s]*$/}
                placeholder="State"
                value={capitalizeFirstLetter(formData.state)}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    state: e.target.value,
                  }))
                }
              />
            </div>
            <div className="md:col-4 col-12 mt-1 md:mt-3">
              <label htmlFor="district">District</label>
              <InputText
                keyfilter={/^[a-zA-Z\s]*$/}
                placeholder="District"
                value={capitalizeFirstLetter(formData.district)}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    district: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        </div>
      )}

      {formData.activeIndex === 1 && (
        <div className="mt-6">
          <div className="grid">
            <div className="md:col-3 col-12 mt-1 md:mt-3">
              <label htmlFor="empid">Employee ID</label>
              <div className="p-inputgroup">
                <InputText
                  id="empid"
                  value={formData.empid}
                  onChange={handleIdChange}
                  className={!formData.valid ? "p-invalid" : ""}
                />
              </div>
              {!formData.valid && (
                <div className="text-red-500 text-xs relative mt-2">
                  <FaInfoCircle className="absolute top-[3px] mt-1" />
                  <span className="ml-4">
                    Employee id format should start with KCPL0
                  </span>
                </div>
              )}
            </div>
            <div className="md:col-3 col-12 mt-1 md:mt-3">
              <label htmlFor="kid_id">Employee KID</label>
              <div className="p-inputgroup">
                <InputText
                  id="kid_id"
                  value={formData.kid_id}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      kid_id: e.value,
                    }))
                  }
                  className={!formData.valid ? "p-invalid" : ""}
                />
              </div>
            </div>
            <div className="md:col-3 col-12 mt-1 md:mt-3">
              <label htmlFor="department">Department</label>
              <Dropdown
                id="department"
                value={formData.department}
                options={departmentOptions}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    department: e.value,
                  }))
                }
                placeholder="Select Department"
                className="w-full"
              />
            </div>
            <div className="md:col-3 col-12 mt-1 md:mt-3">
              <label htmlFor="designation">Designation</label>
              {/* <InputText
                id="designation"
                keyfilter={/^[a-zA-Z\s]*$/}
                value={capitalizeFirstLetter(formData.designation)}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    designation: e.target.value,
                  }))
                }
              /> */}

              <Dropdown
                id="designation"
                value={formData.designation}
                options={DesignationOptions}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    designation: e.target.value,
                  }))}
                placeholder="Select a designation"
                filter
                showClear
                className="w-full"
              />
            </div>
            <div
              className="md:col-4 col-12 mt-1 md:mt-3"
              style={{ whiteSpace: "nowrap" }}
            >
              <label htmlFor="dateofjoining">Date of Joining</label>
              <InputText
                id="dateofjoining"
                type="date"
                value={formData.dateofjoining}
                defaultValue="Date Of Joining"
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    dateofjoining: e.target.value,
                  }))
                }
              />
              <div className="text-blue-500 text-xs relative mt-2">
                <FaInfoCircle className="absolute top-[3px] mt-1" />
                <span className="ml-4" style={{ whiteSpace: "wrap" }}>
                  Please select a valid Joining date{" "}
                </span>
              </div>
            </div>
            <div className="md:col-4 col-12 mt-1 md:mt-3">
              <label htmlFor="empStatus" style={{ whiteSpace: "nowrap" }}>
                Employee Status
              </label>
              <Dropdown
                id="empStatus"
                value={formData.empStatus}
                options={EmpStatus}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    empStatus: e.value,
                  }))
                }
                placeholder="Select Employee Status"
                className="w-full"
              />
            </div>
            <div className="md:col-4 col-12 mt-1 md:mt-3">
              <label htmlFor="empType" style={{ whiteSpace: "nowrap" }}>
                Employee Type
              </label>
              <Dropdown
                id="empType"
                value={formData.empType}
                options={EmployeeTypeOptions}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    empType: e.value,
                  }))
                }
                placeholder="Select Employee Type"
                className="w-full"
                filter
                showClear
              />
            </div>
            <div className="md:col-3 col-12 mt-1 md:mt-3">
              <label htmlFor="salary">Salary</label>
              <InputNumber
                id="salary"
                value={formData.salary}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    salary: e.value,
                  }))
                }
                mode="currency"
                currency="INR"
                locale="en-IN"
                className="w-full"
              />
            </div>
            <div className="md:col-3 col-12 mt-1 md:mt-3">
              <label htmlFor="ot_hour">Per Day Salary</label>
              <InputNumber
                id="ot_hour"
                value={formData.ot_hour}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    ot_hour: e.value,
                  }))
                }
                mode="currency"
                currency="INR"
                locale="en-IN"
                className="w-full"
              />
            </div>
            <div className="md:col-3 col-12 mt-1 md:mt-3">
              <label htmlFor="annualLeave">Annual Leave</label>
              <InputNumber
                id="annualLeave"
                keyfilter="pnum"
                value={formData.annualLeave}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    annualLeave: e.value,
                  }))
                }
              />
            </div>
            <div className="md:col-3 col-12 mt-1 md:mt-3">
              <label
                htmlFor="annual_permissions"
                style={{ whiteSpace: "nowrap" }}
              >
                Annual Permission
              </label>
              <InputNumber
                id="annual_permissions"
                keyfilter="pnum"
                value={formData.annual_permissions}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    annual_permissions: e.value,
                  }))
                }
              />
            </div>
            <div className="md:col-12 col-12 mt-1 md:mt-3">
              <label htmlFor="responsibilities ">Job Responsibilities </label>
              <InputTextarea
                id="responsibilities "
                value={capitalizeFirstLetter(formData.responsibilities)}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    responsibilities: e.target.value,
                  }))
                }
                placeholder="Enter Job Responsibilities.... "
                rows={4}
                cols={30}
                style={{ resize: "none" }}
              />
            </div>
            <div className="md:col-3 col-12 mt-1 md:mt-3"></div>
          </div>
        </div>
      )}

      {formData.activeIndex === 2 && (
        <div className="mt-6">
          <div className="grid">
            <div className="md:col-4 col-12 mt-1 md:mt-3">
              <label htmlFor="bankName">Bank Name</label>
              <InputText
                id="bankName"
                placeholder="Bank Name"
                keyfilter={/^[a-zA-Z\s]*$/}
                value={formData.bankName}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    bankName: e.target.value,
                  }))
                }
              />
            </div>
            <div className="md:col-4 col-12 mt-1 md:mt-3">
              <label htmlFor="IFSC_Code">IFSC Code</label>
              <InputText
                id="IFSC_Code"
                placeholder="Enter IFSC Code"
                value={formData.IFSC_Code}
                onChange={handleIFSC_CodeChange}
                inputStyle={{
                  border: formData.ifscError ? "" : "1px solid red",
                }}
              />
              {formData.ifscError && (
                <div className="error-message" style={{ color: "red" }}>
                  {formData.ifscError}
                </div>
              )}
            </div>
            <div className="md:col-4 col-12 mt-1 md:mt-3">
              <label htmlFor="accountNumber">Account Number</label>
              <InputText
                id="accountNumber"
                keyfilter="pint"
                maxLength={15}
                value={formData.accountNumber}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    accountNumber: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-wrap justify-content-between my-3">
        <Button
          disabled={formData.activeIndex === 0}
          border-circl
          onClick={handlePrevious}
        // className="p-button-outlined"
        >
          <i className="fi fi-rr-arrow-small-left"></i>
        </Button>
        <Button
          onClick={formData.activeIndex < 2 ? handleNext : handleSave}
          disabled={
            (formData.activeIndex === 0 &&
              (!formData.firstname ||
                !formData.lastname ||
                !formData.dateOfBirth ||
                !formData.gender ||
                !formData.maritalStatus ||
                !formData.phoneNumber ||
                !formData.email ||
                !formData.emailValid ||
                !formData.address)) ||
            (formData.activeIndex === 1 &&
              (!formData.department ||
                !formData.designation ||
                !formData.dateofjoining ||
                !formData.empStatus ||
                !formData.salary ||
                !formData.empType ||
                !formData.annualLeave)) ||
            (formData.activeIndex === 2 &&
              (!formData.bankName ||
                !formData.accountNumber ||
                !formData.IFSC_Code))
          }
        >
          {formData.activeIndex < 2 ? (
            <i className="fi fi-rr-arrow-small-right"></i>
          ) : (
            <span>Save</span>
          )}
        </Button>
      </div>
    </div>
  );
}