import React, { useState } from "react";
import { Dialog, InputText, Dropdown, Button } from "primereact";
import { AutoComplete } from "primereact/autocomplete";

export default function AttendanceAddModel({
  setDisplayAddModal,
  displayAddModal,
  currentTime,
  attendanceStatus,
  setErrors,
  setAttendanceStatus,
  errors,
  handleSubmit,
  employeeData,
  selectedEmployee,
  setSelectedEmployee,
  setDateAdd,
  dateAdd,
}) {
  const employeeOptions = employeeData.map((employee) => ({
    label: `${employee.firstname} ${employee.lastname}`,
    value: employee.empid,
  }));
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const filterEmployees = (event) => {
    const filtered = employeeOptions.filter((option) =>
      option.label.toLowerCase().includes(event.query.toLowerCase())
    );
    setFilteredEmployees(filtered);
  };
  const attendanceOptions = [
    { label: "Present", value: "Present" },
    { label: "On The Way", value: "On The Way" },
    { label: "Absent ", value: "Absent" },
    { label: "Sick Leave", value: "Sick Leave" },
    { label: "Day Off", value: "Day Off" },
    { label: "Permissions", value: "Permissions" },
    { label: "Comp Off", value: "Comp Off" },
    { label: "Optional Leave", value: "Optional Leave" },
  ];

  const today = new Date().toISOString().split("T")[0];

  const handleStatusChange = (e) => {
    const value = e.target.value;
    setAttendanceStatus(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      attendanceStatus: !value ? "Status is required" : "",
    }));
  };
  return (
    <div>
      <Dialog
        visible={displayAddModal}
        onHide={() => {
          setDisplayAddModal(false)
          setErrors({});
          setAttendanceStatus("");
          setSelectedEmployee("");
          setDateAdd("");
        }
        }
        header="Add Attendance"
        style={{ width: "55vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="p-field">
          <label htmlFor="employee">Employee</label>
          <Dropdown
            id="employee"
            value={selectedEmployee}
            options={employeeOptions}
            onChange={(e) => setSelectedEmployee(e.value)}
            placeholder="Select an Employee" filter
            showClear
            className={errors.selectedEmployee ? "p-invalid w-full" : "w-full"}
          />

          {errors.selectedEmployee && (
            <small className="p-error ml-2">{errors.selectedEmployee}</small>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="currentDate">Date</label>
          <InputText
            id="currentDate"
            type="date"
            value={dateAdd}
            onChange={(e) => setDateAdd(e.target.value)}
            className={errors.dateAdd ? "p-invalid w-full" : "w-full"}
            max={today}
          />
          {errors.dateAdd && (
            <small className=" p-error ml-2">{errors.dateAdd}</small>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="currentTime">Time</label>
          <InputText id="currentTime" value={currentTime} readOnly />
        </div>
        <div className="p-field ">
          <label htmlFor="attendanceStatus">Status</label>

          <div className="grid">
            {attendanceOptions.map((option) => (
              <div
                key={option.value}
                className="flex col-12 sm:col-4 md:col-3 align-items-center"
              >
                <input
                  type="radio"
                  id={`attendanceStatus_${option.value}`}
                  name="attendanceStatus"
                  value={option.value}
                  checked={attendanceStatus === option.value}
                  onChange={handleStatusChange}
                  className={`${errors.attendanceStatus} ? 'p-error' : '' w-auto `}
                />
                <label
                  className="font-normal "
                  style={{ whiteSpace: "nowrap" }}
                  htmlFor={`attendanceStatus_${option.value}`}
                >
                  {option.label}
                </label>
              </div>
            ))}
          </div>
          {errors.attendanceStatus && (
            <small className="p-error">{errors.attendanceStatus}</small>
          )}
        </div>
        <div className="p-dialog-footer flex mt-4 justify-content-end">
          <Button
            label="Cancel"
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => {
              setDisplayAddModal(false);
              setErrors({});
              setAttendanceStatus("");
              setSelectedEmployee("");
              setDateAdd("");
            }}
          />
          <Button
            label="Add"
            icon="pi pi-check"
            className=""
            onClick={handleSubmit}
          />
        </div>
      </Dialog>
    </div>
  );
}