import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PrimeReactProvider } from "primereact/api";
import App from "./router";
import { store } from "./store";
import "./index.css";
import "./styles/main.scss";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css';  
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';



const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <PrimeReactProvider>
        <App />
      </PrimeReactProvider>
    </Provider>
  // </React.StrictMode>
);
