import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import axios from "axios";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { TASK_API, APPOINTMENTS_API, EMPLOYEE_API } from "../../constants/api";

const localizer = momentLocalizer(moment);

const TodayAttendance = () => {
  const [taskDetails, setTaskDetails] = useState([]);
  const [appointmentsData, setAppointmentData] = useState([]);
  const [meetingData, setMeetingData] = useState([]);
  const [employees, setEmployees] = useState([]);

  const fetchAppointmentData = () => {
    const userDataString = localStorage.getItem("user");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const employee_ids = userData.data.id;

      axios
        .get(APPOINTMENTS_API.APPOINTMENTS_MEMBER(employee_ids))
        .then((response) => {
          setAppointmentData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });
    } else {
      console.error("User data not found in local storage");
    }
  };

  const fetchTaskData = async () => {
    const userString = localStorage.getItem("user");
    const user = JSON.parse(userString);
    if (!user || !user.data || !user.data.id) {
      console.error(
        "User object is null, undefined, or does not contain an id property."
      );
      return;
    }
    const response = await axios.get(TASK_API.GET_USER_TASK(user.data.id));
    setTaskDetails(response.data);
  };

  const fetchData = async () => {
    try {
      const { data } = await axios.get(EMPLOYEE_API.GET_ALL);
      setEmployees(data.usersData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const todayMeetings = async () => {
    try {
      const userDataString = localStorage.getItem("user");
      const userData = JSON.parse(userDataString);
      const participants = userData.data.id;
      const response = await axios.get(TASK_API.TASK_PARTICIPANTS_GET(participants));

      setMeetingData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchAppointmentData();
    fetchTaskData();
    fetchData();
    todayMeetings();
  }, []);

  const generateBirthdayEvents = (employees) => {
    const today = moment();
    return employees
      .map((employee) => {
        const birthday = moment(employee.DOB, "YYYY-MM-DD");
        const birthdayThisYear = birthday.year(today.year());
        const birthdayInRange = birthdayThisYear.isSame(today, 'day') ||
          (birthdayThisYear.isAfter(today, 'day') && birthdayThisYear.diff(today, 'days') <= 7);

        if (birthdayInRange) {
          return {
            title: `${today.isSame(birthdayThisYear, 'day') ? "Birthday Today" : "Birthday Upcoming"} ${employee.firstname}`,
            start: birthdayThisYear.toDate(),
            end: birthdayThisYear.add(1, 'day').toDate(),
            type: "birthday",
          };
        }
        return null;
      })
      .filter(event => event !== null);
  };

  const events = [
    ...taskDetails.map((task) => ({
      title: `${task.task_name}`,
      start: moment(task.StartDate + " " + task.start_time, "YYYY-MM-DD HH:mm:ss").toDate(),
      end: moment(task.DueDate + " " + task.end_time, "YYYY-MM-DD HH:mm:ss").toDate(),
      type: "task",
    })),
    ...meetingData.map((meeting) => ({
      title: meeting.meeting_title,
      start: moment(meeting.MeetingSheduleDate + " " + meeting.start_time).toDate(),
      end: moment(meeting.MeetingSheduleDate + " " + meeting.end_time).toDate(),
      type: "meeting",
    })),
    ...appointmentsData.map((appointment) => ({
      title: appointment.appointment_title,
      start: moment(appointment.appointment_date + " " + appointment.appointment_time).toDate(),
      end: moment(appointment.appointment_date + " " + appointment.appointment_time).add(1, 'hour').toDate(),
      type: "appointment",
    })),
    ...generateBirthdayEvents(employees),
  ];

  return (
    <div className="calendar-container">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, border: "1px solid #ccc" }}
        eventPropGetter={(event, start, end, isSelected) => {
          let newStyle = {
            borderRadius: "0px",
            border: "none",
            color: "black",
            fontWeight: "500",
          };

          switch (event.type) {
            case "meeting":
              newStyle.backgroundColor = "#72f3f3";
              break;
            case "appointment":
              newStyle.backgroundColor = "#c7e0fb";
              break;
            case "birthday":
              newStyle.backgroundColor = "#ff6e38";
              newStyle.color = "#fff";
              break;
            default:
              break;
          }
          return {
            className: "",
            style: newStyle,
          };
        }}

      />
    </div>
  );
};

export default TodayAttendance;
