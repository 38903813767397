import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import React, { useState } from 'react';

export default function ApprovedReasonModel({ visible, onHide, handleApprove }) {
  const [description, setDescription] = useState("");

  const handleSubmit = () => {
    handleApprove(description);
    onHide();
  };

  return (
    <Dialog header="Approver Message" visible={visible} onHide={onHide} style={{ width: '450px' }}>
      <div className="p-field">
        <label htmlFor="description">Details</label>
        <InputTextarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={5}
          cols={30}
          autoResize={false}
          style ={{  resize: "none",}}
        />
      </div>
      <div className="p-dialog-footer mt-3">
        <Button label="Cancel" icon="pi pi-times" onClick={onHide} className="p-button-danger" />
        <Button label="Submit" icon="pi pi-check" onClick={handleSubmit} autoFocus />
      </div>
    </Dialog>
  );
}
