import React, { useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FaAtlas, FaBuilding, FaDoorClosed, FaDoorOpen, FaEnvelope, FaFlag, FaGlobe, FaHome, FaMapMarkerAlt, FaMapSigns, FaPhoneAlt, FaRoad } from 'react-icons/fa';


const Contact = ({ contactInfo }) => {
  const data = contactInfo

  return (
    <div>
      <div className='flex flex-wrap'>
        {data.length > 0 ? data.map(contact => (
          <div key={contact.id} className="p-mb-4 col-12 sm:col-6 lg:col-4">
            <Accordion className='mb-3 contact-accordion'>
              <AccordionTab header={<span>{contact.addresstype} Details</span>}>
                <div className="flex gap-2 flex-column">
                  <div><FaBuilding style={{ marginRight: '0.5rem' }} /><strong>Residential Type:</strong> {contact.residentialtype}</div>
                </div>
              </AccordionTab>

              <AccordionTab header="Address Details" className=''>
                <div className="flex gap-2 flex-column">
                  <div><FaDoorClosed style={{ marginRight: '0.5rem' }} /><strong>Door:</strong> {contact.doorno}</div>
                  <div><FaRoad style={{ marginRight: '0.5rem' }} /><strong>Street:</strong> {contact.street}</div>
                  <div><FaMapMarkerAlt style={{ marginRight: '0.5rem' }} /><strong>Place:</strong> {contact.place}</div>
                  <div><FaMapSigns style={{ marginRight: '0.5rem' }} /><strong>Taluk:</strong> {contact.taluk}</div>
                  <div><FaGlobe style={{ marginRight: '0.5rem' }} /><strong>District:</strong> {contact.district}</div>
                  <div><FaFlag style={{ marginRight: '0.5rem' }} /><strong>State:</strong> {contact.state}</div>
                  <div><FaAtlas style={{ marginRight: '0.5rem' }} /><strong>Country:</strong> {contact.country}</div>
                  <div><FaDoorOpen style={{ marginRight: '0.5rem' }} /><strong>Pincode:</strong> {contact.pincode}</div>
                </div>
              </AccordionTab>

              <AccordionTab header="Contact Details">
                <div className="flex gap-2 flex-column">
                  <div><FaPhoneAlt style={{ marginRight: '0.5rem' }} /><strong>Phone:</strong> +{contact.phonenumber}</div>
                  <div><strong className='flex'><FaEnvelope style={{ marginRight: '0.5rem' }} /><span>Email:</span></strong><span className='' style={{ wordBreak: 'break-word' }}>
                    {contact.email} </span> </div>
                </div>
              </AccordionTab>
            </Accordion>
          </div>
        )) : <span className='flex gap-2 justify-content-center mt-4'>No data available</span>}
      </div>
    </div>
  );
};

export default Contact;
