import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

import { EMPLOYEE_API, PROJECT_API, TASK_API } from "../../../constants/api";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import TaskEdit from "./TaskEdit";
import RejectModel from "./RejectedReasonModel";
import ApprovedModel from "./ApprovedReasonModel";

const AdminTaskApprovals = () => {
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [taskApproval, setTaskApproval] = useState([]);
  const [employeesData, setEmployeesData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [visible, setVisible] = useState(false);
  const [addform, setAddform] = useState({
    Task_id: "",
    projectname: "",
    maker: "",
    priority: "",
    approver: "",
    status: "",
    processorselect: [],
    StartDate: "",
    DueDate: "",
    Details: "",
    task_name: "",
    agenda: "",
    meeting_link: "",
    meeting_title: "",
    participants: [],
    MeetingSheduleDate: "",
    start_time: "",
    end_time: "",
    duration: "",
  });
  const [editData, setEditData] = useState(null);
  const [approverOptions, setApproverOptions] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const [selectedParticipantsIds, setSelectedParticipantsIds] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [duration, setDuration] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const fileInputRef = useRef(null);
  const status = [
    { name: "Initialized" },
    { name: "In Progress" },
    { name: "Completed" },
    { name: "Returned" },
    { name: "Resolved" },
    { name: "Rejected" },
  ];
  const priority = [{ name: "High" }, { name: "Medium" }, { name: "Low" }];

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.All_USERS);
      const { usersData } = response.data;
      setEmployeesData(usersData);
      const formattedApprovers = usersData?.map((employee) => ({
        label: `${employee.firstname} ${employee.lastname}`,
        value: `${employee.id}`,
      }));
      setEmployees(formattedApprovers);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTaskData = async () => {
    try {
      const { data } = await axios.get(TASK_API.GET_ALL_TASK);
      setTaskApproval(data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchTaskData();
    fetchEmployees();
  }, []);

  const showToast = (severity, detail) => {
    toast.current.show({ severity, detail });
  };

  const getStatusColor = (rowData) => {
    switch (rowData.approval_status) {
      case "Approved":
        return {
          borderColor: "#B7EB8F",
          backgroundColor: "#F6FFED",
          color: "#389E0D",
        };
      case "Rejected":
        return {
          borderColor: "#FF3D32",
          backgroundColor: "#FAFAFA",
          color: "#FF3D32",
        };
      case "Pending":
        return {
          borderColor: "#0d6efd",
          backgroundColor: "#0d6efd26",
          color: "#0d6efd",
        };
      case null:
        return {
          borderColor: "#0d6efd",
          backgroundColor: "#0d6efd26",
          color: "#0d6efd",
        };
      default:
        return {
          borderColor: "#ffbf00",
          backgroundColor: "#E6F4FF",
          color: "#4280E3",
        };
    }
  };

  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [dialogApproveVisible, setDialogApproveVisible] = useState(false);

  const handleApprove = async (rowData) => {
    try {
      const response = await axios.put(
        TASK_API.TASK_APPROVAL_ADMIN_APPROVAL(rowData.Task_id),
        { approval_status: "Approved" }
      );
      const { data } = await axios.get(TASK_API.GET_ALL_TASK);
      setTaskApproval(data);
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };

  const handleReject = async (approvalStatus, description, taskId) => {
    try {
      const response = await axios.put(
        TASK_API.TASK_APPROVAL_ADMIN_REJECTION(taskId),
        { approval_status: approvalStatus, task_rejection_reason: description }
      );
      const { data } = await axios.get(TASK_API.GET_ALL_TASK);
      setTaskApproval(data);
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };
  const actionTemplate = (rowData) => {
    return (
      <div className="flex align-items-center">
        {rowData.approval_status !== "Approved" && (
          <Button
            tooltip="Approve"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
            className="icon-button"
            icon="pi pi-check"
            style={{
              border: "none",
              background: "#22c55e",
              borderRadius: "10px",
              padding: "10px",
              fontSize: "10px",
              color: "#fff",
            }}
            onClick={() => handleApprove(rowData)}
          />
        )}
        <Button
          tooltip="Reject"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          className="icon-button ml-2"
          icon="pi pi-times"
          style={{
            border: "none",
            background: "#ff3d32",
            borderRadius: "10px",
            padding: "10px",
            fontSize: "10px",
            color: "#fff",
          }}
          onClick={() => {
            setSelectedTask(rowData);
            setDialogVisible(true);
          }}
        />
      </div>
    );
  };

  const handleRowSelect = (event) => {
    setVisible(true);
    setEditData(event.data);
    handleAddvalues(event.data);
  };

  const validateForm = () => {
    let errors = {};
    if (!addform.projectname) {
      errors.projectname = "Project name is required";
    }
    if (!addform.DueDate) {
      errors.DueDate = "Due date is required";
    }
    if (!addform.StartDate) {
      errors.StartDate = "Start date is required";
    }
    if (!addform.Details) {
      errors.Details = "Details is required";
    }
    if (!addform.approver) {
      errors.approver = "Approver is required";
    }
    if (!addform.priority) {
      errors.priority = "priority is required";
    }
    if (!addform.status) {
      errors.status = "status name is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const fetchApprovers = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.All_USERS);
      const { usersData } = response.data;
      const formattedApprovers = usersData?.map((employee) => ({
        label: `${employee.firstname} ${employee.lastname}`,
        value: `${employee.id}`,
      }));
      setApproverOptions(formattedApprovers);
    } catch (error) {
      console.error("Error fetching approvers:", error);
    }
  };

  useEffect(() => {
    fetchApprovers();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(PROJECT_API.GET_ALL_PROJECTS);
      setProjectOptions(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const onUpload = (event) => {
    const files = event.target.files;
    if (files.length > 5) {
      event.preventDefault();
      alert("You can only select up to 5 files.");
      return;
    }
    const fileNames = [];
    for (let i = 0; i < files.length; i++) {
      fileNames.push(files[i].name);
    }
    setUploadedFiles(fileNames);
  };

  const clearForm = () => {
    setAddform({
      Task_id: "",
      projectname: "",
      maker: "",
      priority: "",
      approver: "",
      status: "",
      processorselect: [],
      StartDate: "",
      DueDate: "",
      Details: "",
      task_name: "",
      agenda: "",
      meeting_link: "",
      meeting_title: "",
      participants: [],
      MeetingSheduleDate: "",
      start_time: "",
      end_time: "",
      duration: "",
      selectedParticipants: [],
    });
    setStartTime("");
    setEndTime("");
    setDuration("");
    setParticipants([]);
    setUploadedFiles([]);
  };

  const handleAddTask = (e) => {
    const { name, value } = e.target;
    setAddform({
      ...addform,
      [name]: value,
    });

    setFormErrors({
      ...formErrors,
      [name]: null,
    });
  };

  const handleAddvalues = (data) => {
    setEditData(data);
    const selectedEmployees = data.Processer.split(",").map((id) => {
      const employee = approverOptions.find(
        (employee) => employee.value === id
      );
      return {
        label: `${employee?.label || id}`,
        value: employee ? employee.value : id,
      };
    });
    const selectedParticipants = data?.participants
      ? data.participants.split(",").map((id) => {
          const participant = approverOptions.find(
            (employee) => employee.value === id
          );
          return {
            label: `${participant?.label || id}`,
            value: participant ? participant.value : id,
          };
        })
      : [];
    setAddform({
      ...addform,
      Task_id: data.Task_id,
      projectname: data.Project,
      //maker: user.firstname,
      priority: { name: data.Priority },
      approver: data.Approver,
      status: { name: data.Status },
      processorselect: selectedEmployees,
      StartDate: data.StartDate,
      DueDate: data.DueDate,
      Details: data.Details,
      task_name: data.task_name,
      meeting_title: data.meeting_title,
      meeting_link: data.meeting_link,
      agenda: data.agenda,
      participants: selectedParticipants,
      MeetingSheduleDate: data.MeetingSheduleDate,
      start_time: data.start_time,
      end_time: data.end_time,
      duration: data.duration,
      filename: data.filename,
    });
    setParticipants(selectedParticipants);
    setSelectedParticipantsIds(
      selectedParticipants.map((participant) => participant.value)
    );
    setSelectedParticipantsIds(
      selectedParticipants.map((employee) => employee.value)
    );
    setStartTime(data.start_time);
    setEndTime(data.end_time);
    setDuration(data.duration);
    setUploadedFiles(data.filename);
    setVisible(true);
  };

  const checkFormValidity = () => {
    if (!editData) {
      return (
        addform.projectname &&
        addform.DueDate &&
        addform.StartDate &&
        addform.Details &&
        addform.priority &&
        addform.status &&
        selectedEmployeeIds.length > 0
      );
    } else {
      return true;
    }
  };

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [addform, selectedEmployeeIds]);

  const handleCancel = () => {
    setVisible(false);
    setEditData(null);
    clearForm();
    setStartTime("");
    setEndTime("");
    setDuration("");
    setParticipants([]);
    setUploadedFiles([]);
  };

  const handleEmployeeSelectChange = (selectedOptions) => {
    setSelectedEmployeeIds(selectedOptions.map((option) => option.value));
    setAddform({
      ...addform,
      processorselect: selectedOptions,
    });
  };

  const handleParticipantSelectChange = (selectedOptions) => {
    setSelectedParticipantsIds(selectedOptions.map((option) => option.value));
    setParticipants(
      selectedOptions.map((option) => ({
        label: option.label,
        value: option.value,
      }))
    );
    setAddform({
      ...addform,
      participants: selectedOptions,
    });
  };

  const handleAddTaskSubmit = async () => {
    try {
      if (isSubmitting || !validateForm()) {
        return;
      }

      const processorselect = Array.isArray(addform.processorselect)
        ? addform.processorselect.map((option) => option.value).join(",")
        : addform.processorselect;
      const participants = addform.participants
        ? addform.participants.map((participant) => participant.value).join(",")
        : addform.participants;
      const meetingSheduleDate = addform.MeetingSheduleDate || "";
      const formData = new FormData();
      formData.append("Creater", `${user.data.id}`);
      formData.append("Processer", processorselect);
      formData.append("Approver", addform.approver);
      formData.append("Project", addform.projectname);
      formData.append("Details", addform.Details);
      formData.append("Status", addform.status.name);
      formData.append("Priority", addform.priority.name);
      formData.append("StartDate", addform.StartDate);
      formData.append("DueDate", addform.DueDate);
      formData.append("task_name", addform.task_name);
      formData.append("meeting_title", addform.meeting_title);
      formData.append("meeting_link", addform.meeting_link);
      formData.append("agenda", addform.agenda);
      formData.append("participants", participants);
      formData.append("MeetingSheduleDate", meetingSheduleDate);
      formData.append("start_time", startTime);
      formData.append("end_time", endTime);
      formData.append("duration", duration);

      const files = fileInputRef.current.files;
      if (files.length > 0) {
        Array.from(files).forEach((file) => {
          formData.append("files", file);
        });
      } else {
        formData.append("filename", null);
      }

      setIsSubmitting(true);
      if (editData) {
        await axios.put(TASK_API.EDIT_TASK(editData.Task_id), formData);
        clearForm();
      } else {
        await axios.post(TASK_API.ADD_TASK, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        clearForm();
      }
      setIsSubmitting(false);
      setEditData(null);
      clearForm();
      setVisible(false);
      fetchTaskData();
    } catch (error) {
      console.error("Error adding/editing task:", error);
      const errorMessage =
        error.response.data || "Failed to submit task. Please try again.";
      if (error.response && error.response.data) {
        showToast(error.response.data.message);
      } else {
        showToast("An error occurred.");
      }
      setIsSubmitting(false);
    }
  };

  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
    calculateDuration(e.target.value, endTime);
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
    calculateDuration(startTime, e.target.value);
  };

  const calculateDuration = (startTime, endTime) => {
    const [startHour, startMinute] = startTime?.split(":").map(Number);
    const [endHour, endMinute] = endTime?.split(":").map(Number);
    const totalStartMinutes = startHour * 60 + startMinute;
    const totalEndMinutes = endHour * 60 + endMinute;
    let durationMinutes = totalEndMinutes - totalStartMinutes;
    if (durationMinutes < 0) {
      durationMinutes += 1440;
    }
    const durationHours = Math.floor(durationMinutes / 60);
    const remainingMinutes = durationMinutes % 60;
    setDuration(`${durationHours} hours ${remainingMinutes} minutes`);
  };

  return (
    <div className="">
      <Toast ref={toast} />

      <div className="md:flex justify-content-between">
        <div className="ml-2">
          <h2>Task Approvals</h2>
        </div>

        <div className="md:col-4 lg:col-2 col-12 ">
          <div className="p-inputgroup">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>

      <DataTable
        value={taskApproval}
        scrollable
        className=""
        paginator
        rows={10}
        globalFilter={globalFilter.trim() ? globalFilter : null}
        selectionMode="single"
        onRowSelect={handleRowSelect}
      >
        <Column
          field="Task_id"
          header="Task Id"
          bodyStyle={{ width: "70px" }}
        ></Column>
        <Column
          field="Approver"
          header="Approver"
          body={(rowData) => {
            const approverId = rowData.Approver;
            const approver = employeesData.find((emp) => emp.id === approverId);
            return `${approver?.firstname} ${approver?.lastname}`;
          }}
        />
        <Column
          field="Creater"
          header="Creater"
          body={(rowData) => {
            const creatorId = rowData?.Creater;
            const creator = employeesData.find((emp) => emp.id === creatorId);
            return `${creator?.firstname} ${creator?.lastname}`;
          }}
        ></Column>
        <Column
          field="Processer"
          header="Processer"
          body={(rowData) => {
            const teamMemberIds = rowData.Processer.split(",");
            const processer = teamMemberIds
              .map((id) => employeesData.find((emp) => emp.id === id.trim()))
              .filter((emp) => emp !== undefined)
              .map((emp) => `${emp.firstname} ${emp.lastname}`)
              .join(", ");
            return (
              <div
                id={`Processer_${rowData.Task_id}`}
                className="tooltip-container"
              >
                {processer?.length > 10
                  ? `${processer.slice(0, 10)}...`
                  : processer}
                <Tooltip
                  target={`#Processer_${rowData.Task_id}`}
                  position="right"
                >
                  {processer}
                </Tooltip>
              </div>
            );
          }}
          style={{
            width: "180px",
            textTransform: "capitalize",
          }}
        ></Column>
        <Column
          field="Project"
          header="Project"
          style={{ minWidth: "105px" }}
        ></Column>
        <Column
          field="task_name"
          header="Task Name"
          style={{ cursor: "pointer" }}
          body={(rowData) => (
            <div
              id={`task_name${rowData.Task_id}`}
              className="tooltip-container"
            >
              {rowData?.task_name?.length > 15
                ? `${rowData?.task_name.slice(0, 15)}...`
                : rowData?.task_name}
              <Tooltip
                target={`#task_name${rowData.Task_id}`}
                position="right"
                style={{ width: "300px" }}
              >
                {rowData?.task_name}
              </Tooltip>
            </div>
          )}
        ></Column>
        <Column
          field="Details"
          header="Details"
          style={{ cursor: "pointer" }}
          body={(rowData) => (
            <div id={`Details${rowData.Task_id}`} className="tooltip-container">
              {rowData?.Details?.length > 20
                ? `${rowData?.Details.slice(0, 20)}...`
                : rowData?.Details}
              <Tooltip
                target={`#Details${rowData.Task_id}`}
                position="right"
                style={{ width: "300px" }}
              >
                {rowData?.Details}
              </Tooltip>
            </div>
          )}
        ></Column>
        <Column
          field="StartDate"
          header="Started"
          style={{ minWidth: "130px" }}
          body={(rowData) => moment(rowData.StartDate).format("DD-MM-YYYY")}
        ></Column>
        <Column
          field="DueDate"
          header="Due"
          style={{ minWidth: "130px" }}
          body={(rowData) => moment(rowData.DueDate).format("DD-MM-YYYY")}
        ></Column>
        <Column
          field="approval_status"
          header="Approval Status"
          style={{ minWidth: "100px", cursor: "pointer" }}
          body={(rowData) => (
            <div>
              <span
                id={`approval_status_${rowData.Task_id}`}
                style={{
                  border: "2px solid",
                  ...getStatusColor(rowData),
                  padding: "6px 12px",
                  borderRadius: "4px",
                }}
              >
                {rowData?.approval_status === null
                  ? "Pending"
                  : rowData.approval_status}
              </span>
              {rowData.approval_status !== "Approved" &&
                rowData.task_rejection_reason && (
                  <Tooltip
                    target={`#approval_status_${rowData.Task_id}`}
                    position="top"
                  >
                    <div>
                      <strong>Rejection Reason:</strong>
                      <p>{rowData.task_rejection_reason}</p>
                    </div>
                  </Tooltip>
                )}
            </div>
          )}
        />
        <Column
          header="Actions"
          body={actionTemplate}
          style={{ minWidth: "90px" }}
        ></Column>
      </DataTable>
      <TaskEdit
        visible={visible}
        setVisible={setVisible}
        addform={addform}
        projectOptions={projectOptions}
        setAddform={setAddform}
        formErrors={formErrors}
        handleAddTask={handleAddTask}
        editData={editData}
        user={user}
        approverOptions={approverOptions}
        employees={employees}
        priority={priority}
        handleEmployeeSelectChange={handleEmployeeSelectChange}
        onUpload={onUpload}
        status={status}
        fileInputRef={fileInputRef}
        uploadedFiles={uploadedFiles}
        participants={participants}
        handleParticipantSelectChange={handleParticipantSelectChange}
        startTime={startTime}
        handleStartTimeChange={handleStartTimeChange}
        endTime={endTime}
        handleEndTimeChange={handleEndTimeChange}
        duration={duration}
        handleCancel={handleCancel}
        handleAddTaskSubmit={handleAddTaskSubmit}
        isFormValid={isFormValid}
      />
      <RejectModel
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        handleReject={handleReject}
        setSelectedTask={setSelectedTask}
        selectedTask={selectedTask}
      />
      <ApprovedModel
        handleApprove={handleApprove}
        visible={dialogApproveVisible}
        onHide={() => setDialogApproveVisible(false)}
      />
    </div>
  );
};

export default AdminTaskApprovals;