import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { AUTH_API, DEPARTMENT_API, EMPLOYEE_API } from '../../constants/api';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast'
import User from '../../assets/images/user.png';
import Admin from '../../assets/images/admin.png';
import SuperAdmin from '../../assets/images/superAdmin.png';


const Role = () => {
    const [allUserData, setAllUserData] = useState([])
    const [globalFilter, setGlobalFilter] = useState('')
    const [departmentData, setDepartmentData] = useState([]);
    const toast = useRef(null)
    const showToast = (severity, detail) => {
        toast.current.clear();
        toast.current.show({ severity, detail });
    };

    const fetchData = async () => {
        axios
            .get(EMPLOYEE_API.GET_ALL)
            .then((response) => {
                setAllUserData(response.data.usersData);
            })
            .catch((error) => {
                console.error("Error fetching user data:", error.message);
            });
    }

    useEffect(() => {
        fetchData()
    }, [])
    const getAllDepartment = async () => {
        try {
            const response = await axios.get(DEPARTMENT_API.DEPARTMENT_FETCH);
            setDepartmentData(response.data);
        } catch (error) {
            console.error("Error fetching departments:", error);
        }
    };

    useEffect(() => {
        getAllDepartment();
    }, []);
    const handleRoleChange = async (rowData, role) => {
        const userData = localStorage.getItem("user");
        if (!userData) {
            showToast('error', 'User data not found in local storage.');
            return;
        }

        const { data: { id: superAdminId } } = JSON.parse(userData);

        try {
            const response = await axios.put(AUTH_API.ROLE_CHANGE(superAdminId), { role, userId: rowData.id });
            fetchData();
            showToast('success', 'Role updated successfully');
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
            showToast('error', errorMessage);
        }
    };

    const actionTemplate = (rowData) => {
        const { role } = rowData;
        const roles = ["superAdmin", "admin", "user"];
        const roleIcons = {
            superAdmin: SuperAdmin,
            admin: Admin,
            user: User,
        };

        const roleSizes = {
            superAdmin: { width: '32px', height: '30px' },
            admin: { width: '30px', height: '30px' },
            user: { width: '30px', height: '30px' },
        };

        const otherRoles = roles.filter(r => r !== role);

        return (
            <div className='flex gap-1 align-items-center'>
                {otherRoles.map(otherRole => (
                    <React.Fragment key={otherRole}>
                        <Tooltip
                            target={`#${otherRole}-button`}
                            content={otherRole.charAt(0).toUpperCase() + otherRole.slice(1)}
                            position="left"
                        />
                        <div
                            className={`text-lg border-circle text-white flex justify-content-center align-items-center gap-3`}
                            style={{
                                cursor: 'pointer',
                            }}
                            id={`${otherRole}-button`}
                            aria-label={`Switch to ${otherRole.charAt(0).toUpperCase() + otherRole.slice(1)}`}
                            onClick={() => handleRoleChange(rowData, otherRole)}
                        >
                            <div style={{
                                width: roleSizes[otherRole].width,
                                height: roleSizes[otherRole].height
                            }}>
                                <img
                                    src={roleIcons[otherRole]}
                                    alt={otherRole}
                                    style={{ width: '100%', height: '100%' }}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        );
    };




    const getRoleColor = (rowData) => {
        switch (rowData.role) {
            case "admin":
                return {
                    borderColor: "#fd8e2d",
                    backgroundColor: "#ffe6d1",
                    color: "#fd8e2d",
                };
            case "user":
                return {
                    borderColor: "#ef4444",
                    backgroundColor: "#ffd8d8",
                    color: "#ef4444",
                };
            case "superAdmin":
                return {
                    borderColor: "#6d90ff",
                    backgroundColor: "#B6DCFF",
                    color: "#6d90ff",
                };
            default:
                return {
                    borderColor: "#ffbf00",
                    backgroundColor: "#E6F4FF",
                    color: "#6c6c6c",
                };
        }
    };

    return (
        <div className=''>
            <Toast ref={toast} />
            <div className="md:flex block justify-content-between align-items-center my-3">
                <div className=" my-1 md:my-0">
                    <h2 className="">Role Permission</h2>
                </div>
                <div className="">
                    <div className="p-inputgroup flex-1">
                        <InputText
                            type="text"
                            className="search-input p-inputtext p-component"
                            placeholder="Search"
                            value={globalFilter}
                            onChange={(e) => setGlobalFilter(e.target.value)}
                        />
                        <Button icon="pi pi-search" className="search-icon" />
                    </div>
                </div>
            </div>
            <div className="card">
                <DataTable
                    value={allUserData}
                    globalFilter={globalFilter ? globalFilter.trim() : ""}
                    paginator rows={10} rowsPerPageOptions={[10, 25, 50]}
                    sortMode="multiple"
                    removableSort
                >
                    <Column
                        field="id"
                        header="Id"
                        sortable
                    ></Column>
                    <Column
                        body={(rowData) => {
                            return `${rowData?.firstname} ${rowData?.lastname}`
                        }}
                        header="Name"
                        style={{ minWidth: '120px' }}

                    ></Column>
                    <Column
                        field="department"
                        header="Department"
                        style={{ textTransform: "capitalize", minWidth: '120px' }}
                        body={(rowData) => {
                            const departmentId = parseInt(rowData?.department);
                            const department = departmentData.find(
                                (dept) => dept.department_id === departmentId
                            );
                            return department ? department.department_name : "-";
                        }}
                        sortable
                    />
                    <Column
                        header="Role"
                        style={{ minWidth: '120px' }}
                        body={(rowData) => (
                            <span
                                style={{
                                    border: "2px solid",
                                    ...getRoleColor(rowData),
                                    padding: "6px 12px",
                                    borderRadius: "4px",
                                    position: "relative",
                                    cursor: "pointer",
                                }}
                            >
                                {`${rowData?.role?.toUpperCase().charAt(0)}${rowData?.role?.slice(1)}`}
                            </span>
                        )}

                    />
                    <Column
                        header="Action"
                        body={actionTemplate}
                    ></Column>

                </DataTable>
            </div>
        </div>
    )
}

export default Role