import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { PERSONAL_DOCUMENT_API, EMPLOYEE_API, ASSETS_IMAGES_API, ASSETS_DOC_API } from '../../../constants/api';
import { Menu } from 'primereact/menu';
import { Image } from 'primereact/image';
import { DownloadOutlined, FilePdfOutlined, FileWordOutlined } from '@ant-design/icons';
import { InputText } from 'primereact/inputtext';
import { DocumentTitle } from './data/DocumentTitle';
import moment from 'moment';
import { Country } from './data/CountryList';

const AllPersonalDocument = () => {
    const [dialogVisible, setDialogVisible] = useState(false);
    const [formMode, setFormMode] = useState('add');
    const [selectedFile, setSelectedFile] = useState(null);
    const [state, setState] = useState({
        documentTitle: '',
        issueDate: "",
        validityDate: "",
        country: "",
        issuingAuthority: '',
        idNumber: '',
        empid: ''
    });
    const [validationErrors, setValidationErrors] = useState({
        title: '',
        file: '',
        empid: '',
        issueDate: '',
        validityDate: '',
        country: '',
        issuingAuthority: '',
        idNumber: ''
    });
    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [showFileInput, setShowFileInput] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const toast = useRef(null);
    const menu = useRef(null);
    const fileInputRef = useRef(null);

    const documentTypes = DocumentTitle;
    const countryOptions = Country;

    const fetchEmployees = useCallback(async () => {
        try {
            const response = await axios.get(EMPLOYEE_API.ACTIVE_INACTIVE_USERS);
            setEmployeeOptions(response.data.usersData.map(employee => ({
                label: `${employee.firstname} ${employee.lastname}`,
                value: employee.empid
            })));
        } catch (error) {
            console.error('Error fetching employees:', error);
            showToast('error', 'Failed to fetch employees');
        }
    }, []);

    const fetchDocuments = useCallback(async () => {
        try {
            const { data } = await axios.get(PERSONAL_DOCUMENT_API.GET_ALL);
            setDocuments(data || []);
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    }, []);

    useEffect(() => {
        fetchDocuments();
        fetchEmployees();
    }, [fetchEmployees, fetchDocuments]);

    const openDialog = (mode, document = null) => {

        setFormMode(mode);
        if (mode === 'edit' && document) {
            setState({
                documentTitle: document.title,
                issueDate: document.issueDate,
                validityDate: document.validityDate,
                country: document.country,
                issuingAuthority: document.issuingAuthority,
                idNumber: document.idNumber,
                empid: document.empid
            });
            setSelectedFile(document.document_file);
            setSelectedDocument(document);
        } else {
            setState({
                documentTitle: '',
                issueDate: "",
                validityDate: "",
                country: "",
                issuingAuthority: '',
                idNumber: '',
                empid: ''
            });
            setSelectedFile(null);
            setSelectedDocument(null);
        }
        setValidationErrors({
            title: '',
            file: '',
            empid: '',
            issueDate: '',
            validityDate: '',
            country: '',
            issuingAuthority: '',
            idNumber: ''
        });
        setShowFileInput(false);
        setDialogVisible(true);
    };

    const handleDeleteDocument = async (documentId) => {
        try {
            await axios.delete(PERSONAL_DOCUMENT_API.DOCUMENT_DELETE(documentId));
            await fetchDocuments();
            showToast('success', 'Document deleted successfully!');
        } catch (error) {
            console.error('Error deleting document:', error);
            const errorMessage = error.response?.data?.message || 'An error occurred while deleting the document.';
            showToast('error', errorMessage);
        }
    };

    const onFileChange = (e) => {
        const { files } = e.target;
        setSelectedFile(files[0]);
        setValidationErrors(prevErrors => ({ ...prevErrors, file: '' }));
    };

    const showToast = (severity, detail) => {
        if (toast.current) {
            toast.current.show({ severity, detail, life: 3000 });
        }
    };

    const handleSave = async () => {
        const errors = {
            title: '',
            file: '',
            empid: '',
            issueDate: '',
            validityDate: '',
            country: '',
            issuingAuthority: '',
            idNumber: ''
        };
        let hasError = false;

        if (!state.documentTitle) {
            errors.title = 'Title is required';
            hasError = true;
        }
        if (formMode === 'add' && !selectedFile) {
            errors.file = 'File is required';
            hasError = true;
        }
        if (!state.empid) {
            errors.empid = 'Employee is required';
            hasError = true;
        }

        setValidationErrors(errors);

        if (hasError) return;

        const formData = new FormData();
        formData.append('title', state.documentTitle);
        formData.append('issueDate', state.issueDate);
        formData.append('validityDate', state.validityDate);
        formData.append('country', state.country);
        formData.append('issuingAuthority', state.issuingAuthority);
        formData.append('idNumber', state.idNumber);
        if (selectedFile) {
            formData.append('file', selectedFile);
        }
        formData.append('empid', state.empid);

        try {
            if (formMode === 'add') {
                await axios.post(PERSONAL_DOCUMENT_API.DOCUMENT_ADD, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            } else {
                await axios.put(PERSONAL_DOCUMENT_API.DOCUMENT_UPDATE(selectedDocument.id), formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            }
            await fetchDocuments();
            showToast('success', 'Document saved successfully!');
            handleCancel();
        } catch (error) {
            console.error('Error saving document:', error);
            const errorMessage = error.response?.data?.message || 'An error occurred while saving the document.';
            showToast('error', errorMessage);
        }
    };

    const handleCancel = () => {
        setDialogVisible(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const getFilePreview = (fileUrl) => {
        if (!fileUrl) return <span>No file available</span>;

        const fileExtension = fileUrl.split('.').pop().toLowerCase();
        const fileUrlWithBase = `${ASSETS_DOC_API.ASSETS_DOC}${fileUrl}`;

        switch (fileExtension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
                return (
                    <Image src={`${ASSETS_IMAGES_API.ASSETS_IMAGES}${fileUrl}`} alt="File preview" preview width='45px' height='45px' />
                );
            case 'pdf':
                return (
                    <Button
                        icon={<FilePdfOutlined />}
                        className="p-button-text text-red-500 border-red-500 hover:bg-red-500 hover:text-white"
                        onClick={() => window.open(fileUrlWithBase, '_blank')}
                    />
                );
            case 'doc':
            case 'docx':
                return (
                    <Button
                        icon={<FileWordOutlined />}
                        className="p-button-text text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white"
                        onClick={() => window.open(fileUrlWithBase, '_blank')}
                    />
                );
            default:
                return <span>Unsupported file type</span>;
        }
    };

    const handleDownload = async (documentId) => {
        try {
            const url = PERSONAL_DOCUMENT_API.DOCUMENT_DOWNLOAD(documentId);
            const response = await axios.get(url, { responseType: 'blob' });

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const urlObject = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = urlObject;
            a.download = `file_${documentId}`;
            a.click();

            URL.revokeObjectURL(urlObject);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    const handleContextMenu = (event, rowData) => {
        event.preventDefault();
        setSelectedDocument(rowData);
        menu.current.show(event);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <Button
                    icon="pi pi-ellipsis-v"
                    className="p-button-rounded p-button-text"
                    aria-label="More"
                    onClick={(e) => handleContextMenu(e, rowData)}
                />
                <Menu
                    popup
                    model={[
                        { label: 'Edit', icon: 'pi pi-pencil', command: () => openDialog('edit', selectedDocument) },
                        { label: 'Delete', icon: 'pi pi-trash', command: () => handleDeleteDocument(selectedDocument.id) },
                        { label: 'Download', icon: <DownloadOutlined />, command: () => handleDownload(selectedDocument.id) }
                    ]}
                    ref={menu}
                    className="w-8rem"
                />
            </div>
        );
    };

    return (
        <div>
            <div className="md:flex justify-content-between">
                <div className="ml-2">
                    <h2>All Documents</h2>
                </div>
                <div className="md:col-4 lg:col-3 col-12">
                    <div className="p-inputgroup">
                        <InputText
                            type="text"
                            className="search-input p-inputtext p-component"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <Button icon="pi pi-search" className="search-icon" />
                    </div>
                </div>
            </div>
            <Toast ref={toast} />
            <div className="card">
                <div className="flex my-2 justify-content-end">
                    <Button label="Add" icon="pi pi-plus" onClick={() => openDialog('add')} />
                </div>
                <DataTable
                    value={documents}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    globalFilter={searchQuery.trim() ? searchQuery : null}
                >
                    <Column header="S.No" body={(rowData) => <span>{documents.indexOf(rowData) + 1}</span>} />
                    <Column field="empid" header="ID" />
                    <Column body={(rowData) => moment(rowData?.created_at).format("DD-MM-YYYY")} style={{ minWidth: '120px' }} header="Date" field='created_at' />
                    <Column header="Name" body={rowData => `${rowData?.firstname} ${rowData?.lastname}`} />
                    <Column
                        header="Document Id"
                        body={(rowData) => rowData?.idNumber || "-"}
                        field="idNumber"
                    />
                    <Column field="title" header="Document" />
                    <Column
                        header="Issue Date"
                        body={(rowData) => rowData?.issueDate ? moment(rowData?.issueDate).format("DD-MM-YYYY") : "-"}
                        style={{ minWidth: '120px' }}
                    />
                    <Column
                        header="Validity Date"
                        body={(rowData) => rowData?.validityDate ? moment(rowData?.validityDate)?.format("DD-MM-YYYY") : "-"}
                        style={{ minWidth: '120px' }}
                    />
                    <Column
                        header="Country"
                        body={(rowData) => rowData.country != "null" ? rowData?.country : "-"}
                        field="country"
                    />
                    <Column
                        header="Issuing Authority"
                        body={(rowData) => rowData?.issuingAuthority || "-"}
                        field="issuingAuthority"
                    />
                    <Column body={(rowData) => getFilePreview(rowData.document_file)} header="File" />
                    <Column body={actionBodyTemplate} header="Actions" />
                </DataTable>

                <Dialog
                    header={formMode === 'add' ? 'Add Document' : 'Edit Document'}
                    visible={dialogVisible}
                    onHide={handleCancel}
                    style={{ width: "90vw", maxWidth: "400px" }}
                    draggable={false}
                    position="center"
                    footer={
                        <div>
                            <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={handleCancel} />
                            <Button label="Save" icon="pi pi-check" onClick={handleSave} />
                        </div>
                    }
                >
                    <div className="p-fluid">
                        <div className="p-field">
                            <label htmlFor="empid">Employee</label>
                            <Dropdown
                                id="empid"
                                name="empid"
                                value={state.empid}
                                options={employeeOptions}
                                onChange={(e) => setState(prevState => ({ ...prevState, empid: e.value }))}
                                optionLabel="label"
                                filter
                                showClear
                                placeholder="Select Employee"
                            />
                            {validationErrors.empid && <div className="p-error">{validationErrors.empid}</div>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="title">Document Type</label>
                            <Dropdown
                                id="title"
                                name="title"
                                value={state.documentTitle}
                                options={documentTypes}
                                onChange={(e) => setState(prevState => ({ ...prevState, documentTitle: e.value }))}
                                placeholder="Select Document Type"
                                filter
                                showClear
                            />
                            {validationErrors.title && <div className="p-error">{validationErrors.title}</div>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="idNumber">ID Number</label>
                            <InputText
                                id="idNumber"
                                value={state.idNumber}
                                onChange={(e) => setState(prevState => ({ ...prevState, idNumber: e.target.value }))}
                                placeholder="Enter ID Number"
                            />
                            {validationErrors.idNumber && <div className="p-error">{validationErrors.idNumber}</div>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="issueDate">Issue Date</label>
                            <InputText
                                type='date'
                                value={state.issueDate || ""}
                                onChange={(date) => setState(prevState => ({ ...prevState, issueDate: date.target.value }))}
                                placeholder="Select Issue Date"
                            />
                            {validationErrors.issueDate && <div className="p-error">{validationErrors.issueDate}</div>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="validityDate">Validity Date</label>
                            <InputText
                                type='date'
                                value={state.validityDate || ""}
                                onChange={(date) => setState(prevState => ({ ...prevState, validityDate: date.target.value }))}
                                placeholder="Select Validity Date"
                            />
                            {validationErrors.validityDate && <div className="p-error">{validationErrors.validityDate}</div>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="issuingAuthority">Issuing Authority</label>
                            <InputText
                                id="issuingAuthority"
                                value={state.issuingAuthority}
                                onChange={(e) => setState(prevState => ({ ...prevState, issuingAuthority: e.target.value }))}
                                placeholder="Enter Issuing Authority"
                            />
                            {validationErrors.issuingAuthority && <div className="p-error">{validationErrors.issuingAuthority}</div>}
                        </div>      <div className="p-field">
                            <label htmlFor="country">Country</label>
                            <Dropdown
                                id="country"
                                name="country"
                                value={state.country}
                                options={countryOptions}
                                onChange={(e) => setState(prevState => ({ ...prevState, country: e.value }))}
                                filter
                                showClear
                                optionLabel="label"
                                placeholder="Select Country"
                            />
                            {validationErrors.country && <div className="p-error">{validationErrors.country}</div>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="file">File</label>
                            {selectedDocument?.document_file && !showFileInput && (
                                <div className='flex align-items-center'>
                                    {getFilePreview(selectedDocument.document_file)}
                                    <Button
                                        icon="pi pi-pencil"
                                        className="p-button-text"
                                        onClick={() => setShowFileInput(true)}
                                    />
                                </div>
                            )}
                            {(formMode === 'add' || showFileInput) && (
                                <div>
                                    <input
                                        type="file"
                                        name="file"
                                        ref={fileInputRef}
                                        onChange={onFileChange}
                                    />
                                    {validationErrors.file && <div className="p-error">{validationErrors.file}</div>}
                                    {showFileInput && (
                                        <Button
                                            label="Cancel"
                                            icon="pi pi-times"
                                            className="p-button-text mt-2 w-10rem"
                                            onClick={() => setShowFileInput(false)}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default AllPersonalDocument;
