import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { PERSONAL_DOCUMENT_API, ASSETS_IMAGES_API, ASSETS_DOC_API } from '../../../../constants/api';
import { Image } from 'primereact/image';
import { FilePdfOutlined, FileWordOutlined } from '@ant-design/icons';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';

const Document = ({ userId }) => {
  const [personalDocumentData, setPersonalDocumentData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const toast = useRef(null);

  const fetchPersonalDocumentData = useCallback(async () => {
    try {
      const { data } = await axios.get(PERSONAL_DOCUMENT_API.DOCUMENT_FETCH(userId));
      setPersonalDocumentData(data);
    } catch (error) {
      console.error('Error fetching personal_document data:', error);
    }
  }, [userId]);

  useEffect(() => {
    fetchPersonalDocumentData();
  }, []);

  const getFilePreview = (fileUrl) => {
    if (!fileUrl) return <span>No file available</span>;

    const extension = fileUrl.split('.').pop().toLowerCase();
    const fileUrlWithBase = `${ASSETS_DOC_API.ASSETS_DOC}${fileUrl}`;

    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
        return (
          <div>
            <Image src={`${ASSETS_IMAGES_API.ASSETS_IMAGES}${fileUrl}`} alt="File preview" preview width='45px' height='45px' />
          </div>
        );
      case 'pdf':
        return (
          <Button
            icon={<FilePdfOutlined />}
            className="p-button-text text-red-500 border-red-500 hover:bg-red-500 hover:text-white "
            onClick={() => window.open(fileUrlWithBase, '_blank')}
          />
        );
      case 'doc':
      case 'docx':
        return (
          <Button
            icon={<FileWordOutlined />}
            className="p-button-text text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white"
            onClick={() => window.open(fileUrlWithBase, '_blank')}
          />
        );
      default:
        return <span>Unsupported file type</span>;
    }
  };


  return (
    <div className=''>
      <div className="flex justify-content-end mb-2">
        <div className="w-15rem">
          <div className="p-inputgroup">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <Toast ref={toast} />
      <div className="">
        <DataTable value={personalDocumentData} paginator rows={10} rowsPerPageOptions={[10, 25, 50]}>
          <Column
            header="S.No"
            body={(rowData) => <span>{personalDocumentData.indexOf(rowData) + 1}</span>}
          />
          <Column
            header="Date"
            body={(rowData) => rowData?.created_at ? moment(rowData.created_at).format("DD-MM-YYYY") : "-"}
            style={{ minWidth: '120px' }}
            field='created_at'
          />
          <Column
            header="Document Id"
            body={(rowData) => rowData?.idnumber || "-"}
            field="idnumber"
          />
          <Column
            header="Document"
            body={(rowData) => rowData?.title || "-"}
            field="title"
          />
          <Column
            header="Issue Date"
            body={(rowData) => rowData?.issueDate ? moment(rowData?.issueDate).format("DD-MM-YYYY") : "-"}
            style={{ minWidth: '120px' }}
          />
          <Column
            header="Validity Date"
            body={(rowData) => rowData?.issueDate ? moment(rowData?.validityDate)?.format("DD-MM-YYYY") : "-"}
            style={{ minWidth: '120px' }}
          />
          <Column
            header="Country"
            field="country"
            body={(rowData) => rowData?.country != "null" ? rowData?.country : "-"}
          />
          <Column
            header="Issuing Authority"
            body={(rowData) => rowData?.issuingAuthority || "-"}
            field="issuingAuthority"
          />
          <Column
            header="File"
            body={(rowData) => rowData?.document_file ? getFilePreview(rowData.document_file) : "-"}
          />
        </DataTable>
        {/* <DataTable value={personalDocumentData} paginator rows={10} rowsPerPageOptions={[10, 25, 50]}
          globalFilter={globalFilter.trim() ? globalFilter : null}
          >
          <Column header="S.No"
            body={(rowData) => <span>{personalDocumentData.indexOf(rowData) + 1}</span>}
          />
          <Column header="Name" body={rowData => `${rowData?.firstname}${rowData?.lastname}`} />
          <Column field="title" header="Document" />
          <Column body={(rowData) => getFilePreview(rowData.document_file)} header="Document File" />
        </DataTable> */}
      </div>
    </div>
  );
};

export default Document;
