import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ASSETS_API } from '../../../../constants/api';
import { InputText } from 'primereact/inputtext';
import moment from 'moment'
import { Tooltip } from 'primereact/tooltip';

const AssetsRequest = ({ userId }) => {
    const [requests, setRequests] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');

    const fetchData = async () => {
        axios.get(ASSETS_API.ASSETS_REQUEST_FETCH(userId))
            .then(response => {
                setRequests(response.data);
            })
            .catch(error => {
                console.error('Error fetching requests:', error);
            });
    }

    useEffect(() => {
        fetchData();
    }, []);

    const getStatusColor = (rowData) => {
        switch (rowData.status) {
            case "pending":
                return {
                    borderColor: "#91CAFF",
                    backgroundColor: "#E6F4FF",
                    color: "#4280E3",
                };
            case "approved":
                return {
                    borderColor: "#B7EB8F",
                    backgroundColor: "#F6FFED",
                    color: "#389E0D",
                };
            case "rejected":
                return {
                    borderColor: "#FF3D32",
                    backgroundColor: "#FAFAFA",
                    color: "#FF3D32",
                };
            case "request":
                return {
                    borderColor: "#fd7e14",
                    backgroundColor: "#FAFAFA",
                    color: "#fd7e14",
                };
            default:
                return {
                    borderColor: "#ffbf00",
                    backgroundColor: "#E6F4FF",
                    color: "#4280E3",
                };
        }
    };

    return (
        <div>
            <div className="flex block justify-content-end align-items-center mb-2">
                <div className="w-15rem">
                    <div className="p-inputgroup">
                        <InputText type="text" id="globalFilter" className="search-input" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
                        <Button icon="fi fi-bs-search" className="search-icon" />
                    </div>
                </div>
            </div>
            <div className="">
                <DataTable value={requests} paginator rows={10} rowsPerPageOptions={[10, 25, 50]} className="p-datatable-customers" globalFilter={globalFilter.trim()} emptyMessage="No requests found">
                    <Column header="S.No" body={(rowData) => <span>{requests.indexOf(rowData) + 1}</span>} />
                    <Column field="assets" style={{ minWidth: '120px' }} sortable header="Assets " />
                    <Column field="model" style={{ minWidth: '120px' }} header="Model" body={(rowData) => rowData.model || '-'} />
                    <Column field="brand" style={{ minWidth: '120px' }} header="Brand" body={(rowData) => rowData.brand || '-'} />
                    <Column field="before_date" style={{ minWidth: '120px' }} header="Before" body={rowData => moment(rowData.before_date).format("DD-MM-YYYY")} />
                    <Column field="description" style={{ minWidth: '120px' }} header="Description" sortable body={(rowData) => (
                        <div>
                            <Tooltip target={`.description-tooltip-${rowData.id}`} content={rowData.description || 'No description'} position='left' />
                            <span className={`description-tooltip-${rowData.id}`} style={{ cursor: 'pointer' }}>
                                {rowData.description ? rowData.description.substring(0, 20) + (rowData.description.length > 20 ? '...' : '') : '-'}
                            </span>
                        </div>
                    )} />
                    <Column field="status" header="Status" body={(rowData) => (
                        <span
                            style={{
                                border: "2px solid",
                                ...getStatusColor(rowData),
                                padding: "6px 12px",
                                borderRadius: "4px",
                                position: "relative",
                                cursor: "pointer"
                            }}
                        >
                            {rowData?.status?.charAt(0)?.toUpperCase() +
                                rowData?.status?.slice(1)?.toLowerCase()}
                        </span>
                    )} />
                </DataTable>
            </div>
        </div>
    );
};

export default AssetsRequest;
