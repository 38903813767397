import React, { useState, useEffect, useRef } from "react";
import { ProductService } from ".././../Datas/ProductService";
import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup";
import TodayCalendar from "./CalendarEmployee";
import {
  APPOINTMENTS_API,
  LEAVE_API,
  TASK_API,
  PROJECT_API,
  EMPLOYEE_API,
  ATTENDANCE_API,
  HOLIDAYS_API,
} from "../../constants/api";
import axios from "axios";
import Appointments from "./Appointments";
import { Menu } from "primereact/menu";
import EditAppointmentDialog from "./EditAppointment";
import ConfirmationDialog from "./ConfirmationDialog ";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { ScrollPanel } from "primereact/scrollpanel";
import RescheduleDialog from "./RescheduleDialog";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import AddAttendance from "./Attendance/AddAttendance";
import BirthdayWish from "./BirthdayWish";
import AlertMemo from './Memo/AlertMemo'

const UserDashboard = () => {
  const [products, setProducts] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [meetingData, setMeetingData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [attendanceData, setattendanceData] = useState([]);
  const [showAddAppointment, setShowAddAppointment] = useState(false);
  const [appointmentData, setAppointmentData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const menuLeft = useRef(null);
  const [menuRefs, setMenuRefs] = useState([]);
  const toast = useRef(null);
  const userData = localStorage.getItem("user");
  const user = JSON.parse(userData);
  const { firstname, lastname } = user.data;
  const [holidays, setHolidays] = useState([]);
  const [absentDaysCount, setAbsentDaysCount] = useState(0);
  const [presentDaysCount, setPresentDaysCount] = useState(0);
  const [workingDays, setWorkingDays] = useState(0);
  const [holidaysCount, setHolidaysCount] = useState(0);
  const [rescheduleDialogVisible, setRescheduleDialogVisible] = useState(false);

  const handleReschedule = (index) => {
    const selectedAppointment = appointmentData[index];
    setSelectedAppointment(selectedAppointment);
    setRescheduleDialogVisible(true);
  };

  const handleRescheduleSubmit = (index) => { };

  useEffect(() => {
    setMenuRefs(
      Array(appointmentData.length)
        .fill()
        .map((_, i) => React.createRef())
    );
  }, [appointmentData]);

  const items = appointmentData.map((appointment, index) => {
    const userDataString = localStorage.getItem("user");

    let loggedInUser = ""
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      loggedInUser = userData.data.id;
    }
    const appointmentCreator = appointment.appointment_creator;
    const employeeIds = appointment.employee_ids.split(',');
    let menuItems = [];

    if (employeeIds.includes(loggedInUser) && appointmentCreator !== loggedInUser) {
      menuItems = [
        {
          label: 'Unable to Attend',
          icon: 'fi fi-rr-time-delete',
          command: () => handleUnableToAttend(index),
        },

      ];
    } else if (appointmentCreator === loggedInUser) {
      // User is the appointment creator, show appropriate options
      menuItems = [
        {
          label: 'Reschedule',
          icon: 'fi fi-rr-time-watch-calendar',
          command: () => handleReschedule(index),
        },
        {
          label: 'Cancel Appointment',
          icon: 'pi pi-times-circle',
          command: () => handleCancelAppointment(index),
        },
        {
          label: 'Unable to Attend',
          icon: 'fi fi-rr-time-delete',
          command: () => handleUnableToAttend(index),
        },
        {
          label: 'Delete',
          icon: 'pi pi-trash',
          command: () => handleDeleteAppointment(index),
        },
        {
          label: 'Edit',
          icon: 'pi pi-pencil',
          command: () => handleEditAppointment(index),
        }
      ];
    }

    return {
      items: menuItems,
    };
  });



  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteAppointmentId, setDeleteAppointmentId] = useState(null);

  const handleDeleteAppointment = (index) => {
    const selectedAppointment = appointmentData[index];
    setSelectedAppointment(selectedAppointment);
    setDeleteAppointmentId(selectedAppointment.appointment_id);
    setShowConfirmation(true);
  };

  const confirmDeleteAppointment = async () => {
    try {
      await axios.delete(
        APPOINTMENTS_API.APPOINTMENTS_DELETE(deleteAppointmentId)
      );
      setAppointmentData((prevData) =>
        prevData.filter((appointment) => appointment.id !== deleteAppointmentId)
      );
      reFetch();
    } catch (error) {
      console.error("Error deleting appointment:", error);
    } finally {
      setDeleteAppointmentId(null);
      setShowConfirmation(false);
    }
  };

  const handleEditAppointment = (index) => {
    const selectedAppointment = appointmentData[index];
    setSelectedAppointment(selectedAppointment);
    setShowAddAppointment(true);
    setIsEditing(true);
  };

  const handleAddButtonClick = () => {
    setSelectedAppointment(null);
    setShowAddAppointment(true);
    setIsEditing(false);
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.All_USERS);
      const usersData = response.data.usersData;
      setProducts(usersData);
      setEmployees(usersData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const reFetch = () => {
    fetchAppointmentData();
  };

  const fetchAppointmentData = () => {
    const userDataString = localStorage.getItem("user");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const employee_ids = userData.data.id;

      axios
        .get(APPOINTMENTS_API.APPOINTMENTS_MEMBER(employee_ids))
        .then((response) => {
          setAppointmentData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });
    } else {
      console.error("User data not found in local storage");
    }
  };

  useEffect(() => {
    fetchAppointmentData();
    fetchData();
  }, []);

  const [selectedAppointmentDetails, setSelectedAppointmentDetails] =
    useState(null);
  const [appointmentDetailView, setAppointmentDetailView] = useState(false);

  const showAppointmentDetails = (appointment) => {
    setSelectedAppointmentDetails(appointment);
    setAppointmentDetailView(true);
  };

  const hideAppointmentDetails = () => {
    setSelectedAppointmentDetails(null);
    setAppointmentDetailView(false);
  };

  const handleCancelAppointment = async (index) => {
    const selectedAppointment = appointmentData[index];
    setSelectedAppointment(selectedAppointment);
    try {
      await axios.put(
        APPOINTMENTS_API.APPOINTMENTS_DEACTIVATE(
          selectedAppointment.appointment_id
        )
      );
      setAppointmentData((prevData) =>
        prevData.filter(
          (appointment) => appointment.id !== selectedAppointment.id
        )
      );
      reFetch();
      showToast("success", "Appointment canceled successfully");
    } catch (error) {
      console.error("Error canceling appointment:", error);
      showToast("error", "Failed to cancel appointment");
    }
  };

  const handleUnableToAttend = async (index) => {
    const selectedAppointment = appointmentData[index];
    try {
      const userString = localStorage.getItem("user");
      const user = JSON.parse(userString);
      if (!user || !user.data || !user.data.id) {
        console.error(
          "User object is null, undefined, or does not contain an id property."
        );
        return;
      }
      const user_id = user.data.id;
      await axios.post(
        APPOINTMENTS_API.APPOINTMENTS_ABSENCES(
          selectedAppointment.appointment_id
        ),
        { user_id }
      );
      reFetch();
      showToast("success", "Absence added successfully");
    } catch (error) {
      console.error("Error adding absence:", error);
      showToast("error", "Failed to add absence");
    }
  };

  const totalLeaves = async () => {
    try {
      const userDataString = localStorage.getItem("user");
      const userData = JSON.parse(userDataString);
      const employee_ids = userData.data.id;
      const response = await axios.get(LEAVE_API.LEAVE_FETCH(employee_ids));

      setLeaveData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    totalLeaves();
  }, []);

  const todayMeetings = async () => {
    try {
      const userDataString = localStorage.getItem("user");
      const userData = JSON.parse(userDataString);
      const participants = userData.data.id;
      const response = await axios.get(
        TASK_API.TASK_PARTICIPANTS_GET(participants)
      );

      setMeetingData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    todayMeetings();
  }, []);

  useEffect(() => {
    ProductService.getProductsMini().then((data) => setProducts(data));
  }, []);

  const fetchProjects = async () => {
    try {
      const userString = localStorage.getItem("user");
      const user = JSON.parse(userString);
      if (!user || !user.data || !user.data.id) {
        console.error(
          "User object is null, undefined, or does not contain an id property."
        );
        return;
      }
      const response = await axios.get(
        PROJECT_API.GET_EMPLOYEE_PROJECT(user.data.id)
      );
      if (response.status === 200) {
        setProjects(response.data);
      } else {
        throw new Error("Failed to fetch team members");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    const fetchTaskData = async () => {
      const userString = localStorage.getItem("user");
      const user = JSON.parse(userString);
      if (!user || !user.data || !user.data.id) {
        console.error(
          "User object is null, undefined, or does not contain an id property."
        );
        return;
      }
      const response = await axios.get(TASK_API.GET_USER_TASK(user.data.id));
      setTaskData(response.data);
    };
    fetchTaskData();
  }, []);

  const [governmentCount, setGovernmentCount] = useState(0);
  useEffect(() => {
    governmentHolidayCount();
  }, []);

  const governmentHolidayCount = async () => {
    try {
      const response = await axios.get(HOLIDAYS_API.HOLIDAYS_ALL);
      const holidays = response.data;
      const today = new Date();
      const currentMonth = today.getMonth() + 1;

      const holidaysThisMonth = holidays.filter((holiday) => {
        const holidayDate = new Date(holiday.holiday_date);

        const holidayMonth = holidayDate.getMonth() + 1;
        const holidayDay = holidayDate.getDate();

        return holidayMonth === currentMonth && holidayDate <= today;
      });
      setGovernmentCount(holidaysThisMonth.length);
    } catch (error) {
      console.error("Error fetching or processing holidays:", error);
    }
  };

  useEffect(() => {
    const fetchAttendanceData = async () => {
      const userString = localStorage.getItem("user");
      const user = JSON.parse(userString);
      if (!user || !user.data || !user.data.id) {
        console.error(
          "User object is null, undefined, or does not contain an id property."
        );
        return;
      }

      try {
        const [attendanceResponse, holidaysResponse] = await Promise.all([
          axios.get(ATTENDANCE_API.ATTENDANCE_GET(user.data.id)),
          axios.get(HOLIDAYS_API.HOLIDAYS_ALL)
        ]);

        const attendanceData = attendanceResponse.data;
        setattendanceData(attendanceData);
        const holidays = holidaysResponse.data;

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const currentDay = currentDate.getDate();
        const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

        const allDates = Array.from(
          { length: currentDay },
          (_, i) => new Date(currentYear, currentMonth, i + 1)
        );

        const presentDates = attendanceData
          .filter(
            (day) =>
              new Date(day.date).getMonth() === currentMonth &&
              new Date(day.date).getDate() <= currentDay &&
              (day.status === "Present" || day.status === "Optional Leave")
          )
          .map((day) => new Date(day.date).getDate());

        const sundays = allDates
          .filter((date) => date.getDay() === 0)
          .map((date) => date.getDate());

        const holidaysThisMonth = holidays.filter((holiday) => {
          const holidayDate = new Date(holiday.holiday_date);
          return (
            holidayDate.getMonth() === currentMonth &&
            holidayDate.getFullYear() === currentYear &&
            holidayDate <= currentDate
          );
        });

        // Check if a holiday date has corresponding present attendance
        const holidaysWithPresentAttendance = holidaysThisMonth.filter((holiday) => {
          const holidayDate = new Date(holiday.holiday_date).getDate();
          return presentDates.includes(holidayDate);
        });

        const absentDates = allDates
          .filter(
            (date) =>
              !presentDates.includes(date.getDate()) &&
              !sundays.includes(date.getDate())
          )
          .map((date) => date.getDate());

        // Subtract only the holidays without present attendance from absent dates
        const absentCount = absentDates.length - (holidaysThisMonth.length - holidaysWithPresentAttendance.length);

        setAbsentDaysCount(absentCount);
        setPresentDaysCount(presentDates.length);

        const workingDaysCount = daysInMonth - sundays.length - holidaysThisMonth.length + holidaysWithPresentAttendance.length;
        setWorkingDays(workingDaysCount);

      } catch (error) {
        console.error("Error fetching attendance or holidays data:", error);
      }
    };

    fetchAttendanceData();
  }, []);


  const showToast = (severity, detail) => {
    toast.current.clear();
    toast.current.show({ severity, detail });
  };


  const currentDateMeetings = meetingData.filter((meeting) => {
    const currentDate = moment().format("YYYY-MM-DD");
    return moment(meeting.MeetingSheduleDate).isSame(currentDate, "day");
  });

  const newTasks = taskData.filter((task) => task.Status === "Initialized");
  const pendingTasks = taskData.filter((task) => task.Status === "In Progress");
  const completedTasks = taskData.filter((task) => task.Status === "Completed");

  const [selectedMeetingDetails, setSelectedMeetingDetails] = useState(null);
  const [meetingDetailView, setMeetingDetailView] = useState(false);

  const showMeetingDetails = (meeting) => {
    setSelectedMeetingDetails(meeting);
    setMeetingDetailView(true);
  };

  const hideMeetingDetails = () => {
    setSelectedMeetingDetails(null);
    setMeetingDetailView(false);
  };
  return (
    <div>
      <div className="flex justify-content-between">
        <h2 className="my-3">{`${firstname} ${lastname}`}</h2>
        <Toast ref={toast} />
      </div>
      <BirthdayWish />
      <div className="flex sm:flex-wrap lg:flex-nowrap sm:pb-3  lg:pr-3 lg:pb-3">
        <AddAttendance />
        <AlertMemo />
      </div>
      <div className="dashboard">
        <div className="grid">
          <div className="md:col-6 lg:col-3 col-12">
            <div className="card card1">
              <Link to="/EmpAttendance" style={{ color: "black" }}>
                <div className="title">
                  <h3>Working Days</h3>
                </div>
                <div className="dash-content">
                  <div className="Card-grid">
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Total Work Days</h5>
                        <p>{workingDays}</p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Total Absent</h5>
                        <p>{absentDaysCount}</p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Total Present</h5>
                        <p>{presentDaysCount}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="md:col-6 lg:col-3 col-12">
            <div className="card card2">
              <Link to="/Leave" style={{ color: "black" }}>
                <div className="title">
                  <h3>Leave</h3>
                </div>
                <div className="dash-content">
                  <div className="Card-grid">
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Total Leave</h5>
                        <p>{leaveData.annualLeave}</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Leave Balance</h5>
                        <p>{leaveData.balanceLeave || 0}</p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Leave Taken</h5>
                        <p>{leaveData.leaveTaken || 0}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="md:col-6 lg:col-3 col-12">
            <div className="card card3">
              <Link to="/MyProjects" style={{ color: "black" }}>
                <div className="dash-content">
                  <div className="title">
                    <h3>Project</h3>
                  </div>
                  <div className="Card-grid">
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Total Projects</h5>
                        <p>{projects.length}</p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Active Projects</h5>
                        <p>
                          {
                            projects.filter(
                              (project) => project.project_status === "Active"
                            ).length
                          }
                        </p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Completed Projects</h5>
                        <p>
                          {
                            projects.filter(
                              (project) =>
                                project.project_status === "Completed"
                            ).length
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="md:col-6 lg:col-3 col-12">
            <div className="card card4" style={{ position: "relative" }}>
              <Link to="/EmployeeTask" style={{ color: "black" }}>
                <div className="title">
                  <h3>Task</h3>
                </div>
                <div className="dash-content">
                  <div className="Card-grid">
                    <div className="c-left flex">
                      <h5>New Task</h5>
                      <p>{newTasks.length}</p>
                    </div>

                    <div className="flex">
                      <div className="c-left flex">
                        <h5 className="">Pending Task</h5>
                        <p>{pendingTasks.length}</p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="c-left flex">
                        <h5>Completed Task</h5>
                        <p>{completedTasks.length}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="md:col-6 col-12">
            <div className="card Dcard5">
              <Link to="/Task" style={{ color: "black" }}>
                <div className="title">
                  <h3>Meetings</h3>
                </div>
              </Link>
              <ScrollPanel
                style={{ height: "160px" }}
                className="appointmentBar"
              >
                <div className="dash-content">
                  <div className="">
                    {currentDateMeetings.map((meeting, index) => (
                      <div key={index} className="col-12">
                        <div className="c-left">
                          <div className="grid">
                            <div
                              className="col-5 cursor-pointer capitalize"
                              onClick={() => showMeetingDetails(meeting)}
                            >
                              <div>{meeting.meeting_title}</div>
                            </div>
                            <div className="col-3">
                              <div className="flex">
                                <AvatarGroup style={{ marginLeft: "10px" }}>
                                  {meeting?.participants
                                    ?.split(",")
                                    .slice(0, 4)
                                    .map((memberId, index) => {
                                      const employee = employees.find(
                                        (emp) => emp.id === memberId.trim()
                                      );
                                      if (employee) {
                                        return (
                                          <Avatar
                                            key={index}
                                            id={`avatar-${index}`}
                                            label={`${employee.firstname.charAt(
                                              0
                                            )}${employee.lastname.charAt(0)}`}
                                            title={`${employee.firstname} ${employee.lastname}`}
                                            size="small"
                                            style={{
                                              border: "transparent",
                                              backgroundColor:
                                                "rgb(48 212 125)",
                                              color: "#fff",
                                              marginLeft: "-8px",
                                              borderRadius: "50%",
                                              cursor: "pointer",
                                            }}
                                          />
                                        );
                                      }
                                      return null;
                                    })}
                                  {meeting?.participants?.split(",").length >
                                    4 && (
                                      <Avatar
                                        label={`+${meeting.participants.split(",").length -
                                          4
                                          }`}
                                        shape="circle"
                                        size="small"
                                        style={{
                                          border: "transparent",
                                          background: "#eee",
                                        }}
                                      />
                                    )}
                                </AvatarGroup>
                                {Array.isArray(meeting.participants) &&
                                  meeting.participants.length > 4 && (
                                    <span style={{ marginLeft: "-4px" }}>
                                      4+
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="col-1">
                              <p style={{ fontSize: "12px", fontWeight: 500 }}>
                                {meeting.start_time}
                              </p>
                            </div>
                            <div className="col-1">
                              <p style={{ fontSize: "12px", fontWeight: 500 }}>
                                {meeting.end_time}
                              </p>
                            </div>
                            <div className="col-2">
                              <p style={{ fontSize: "12px", fontWeight: 500 }}>
                                {moment(meeting.MeetingSheduleDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </ScrollPanel>
            </div>
          </div>
          {selectedMeetingDetails && (
            <Dialog
              visible={meetingDetailView}
              onHide={hideMeetingDetails}
              position="center"
              draggable={false}
              header={
                <div className="capitalize">
                  <i className="fi fi-br-time-check"></i>
                  {" " + selectedMeetingDetails.meeting_title}
                </div>
              }
              style={{ width: "40vw" }}
              breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
              {selectedMeetingDetails && (
                <div>
                  <div className="flex gap-2 mt-2 mb-3 ml-1">
                    <div className="flex-1 flex  justify-content-start">
                      {" "}
                      {selectedMeetingDetails.agenda}
                    </div>
                  </div>
                  <div className="p-2">
                    <div className=" mb-1 mt-2 p-2">
                      <span className="col-6">
                        <i class="fi fi-rr-calendar-clock"></i> Date:
                      </span>
                      <span>
                        {moment(
                          selectedMeetingDetails.MeetingSheduleDate
                        ).format("DD-MM-YYYY")}
                      </span>
                    </div>

                    <div className="mb-1 mt-2 p-2">
                      <span className="col-6">
                        <i class="fi fi-rr-hourglass-start"></i> Start Time:
                      </span>
                      <span className="">
                        {selectedMeetingDetails.start_time}
                      </span>{" "}
                    </div>
                    <div className="mb-1 mt-2 p-2">
                      <span className="col-6">
                        <i class="fi fi-rr-hourglass-end"></i> End Time:
                      </span>
                      <span className="">
                        {selectedMeetingDetails.end_time}
                      </span>{" "}
                    </div>
                    <div className="ml-2 mt-2 p-2">
                      <AvatarGroup>
                        <span className="mr-3">
                          <i class="fi fi-rs-users-alt"></i> Team:{" "}
                        </span>
                        {selectedMeetingDetails?.participants
                          ?.split(",")
                          .map((memberId, index) => {
                            const employee = employees.find(
                              (emp) => emp.id === memberId.trim()
                            );
                            if (employee) {
                              return (
                                <Avatar
                                  key={index}
                                  id={`avatar-${index}`}
                                  label={`${employee.firstname.charAt(
                                    0
                                  )}${employee.lastname.charAt(0)}`}
                                  title={`${employee.firstname} ${employee.lastname}`}
                                  size="small"
                                  style={{
                                    border: "transparent",
                                    backgroundColor: "#2196F3",
                                    color: "#ffffff",
                                    marginLeft: "-8px",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                  }}
                                />
                              );
                            }
                            return null;
                          })}
                      </AvatarGroup>
                    </div>
                  </div>
                </div>
              )}
            </Dialog>
          )}
          <div className="md:col-6 col-12">
            <div className="card Dcard6">
              <div className="title flex justify-content-between">
                <h3>Appointments</h3>
                {/* <Button
                  icon="fi fi-rr-add"
                  tooltip="Add"
                  onClick={handleAddButtonClick}
                  tooltipOptions={{ position: "top" }}
                  style={{
                    background: "#514BA7",
                    border: "none",
                    borderRadius: "10px",
                    width: "40px",
                  }}
                /> */}
                <span
                  className="icon-only-button"
                  onClick={handleAddButtonClick}
                >
                  <i
                    className="pi pi-plus"
                    style={{ width: "50px", textAlign: "center" }}
                  ></i>
                </span>

                {showAddAppointment && !isEditing && (
                  <Appointments
                    visible={showAddAppointment}
                    onHide={() => {
                      setShowAddAppointment(false);
                      setSelectedAppointment(null);
                    }}
                    onSave={(data) => {
                      handleAddButtonClick();
                    }}
                    reFetch={reFetch}
                  />
                )}

                {showAddAppointment && isEditing && (
                  <EditAppointmentDialog
                    visible={showAddAppointment}
                    onHide={() => {
                      setShowAddAppointment(false);
                      setSelectedAppointment(null);
                    }}
                    onSave={(data) => { }}
                    appointmentData={selectedAppointment}
                    reFetch={reFetch}
                  />
                )}
                <ConfirmationDialog
                  visible={showConfirmation}
                  onHide={() => setShowConfirmation(false)}
                  onConfirm={confirmDeleteAppointment}
                />
              </div>
              <ScrollPanel
                style={{ height: "160px" }}
                className="appointmentBar"
              >
                <div className="dash-content">
                  <table className="table">
                    <tbody>
                      {appointmentData.map((appointment, index) => (
                        <tr key={index}>
                          <td
                            style={{ width: "38%" }}
                            onClick={() => showAppointmentDetails(appointment)}
                            className="cursor-pointer capitalize"
                          >
                            {appointment.appointment_title}
                          </td>
                          <td style={{ width: "20%" }}>
                            <div className="flex">
                              <AvatarGroup style={{ marginLeft: "10px" }}>
                                {appointment?.employee_ids
                                  ?.split(",")
                                  .slice(0, 4)
                                  .map((memberId, index) => {
                                    const employee = employees.find(
                                      (emp) => emp.id === memberId.trim()
                                    );
                                    if (employee) {
                                      return (
                                        <Avatar
                                          key={index}
                                          id={`avatar-${index}`}
                                          label={`${employee.firstname.charAt(
                                            0
                                          )}${employee.lastname.charAt(0)}`}
                                          title={`${employee.firstname} ${employee.lastname}`}
                                          size="small"
                                          style={{
                                            border: "transparent",
                                            backgroundColor: "rgb(236 67 171)",
                                            color: "#fff",
                                            marginLeft: "-8px",
                                            borderRadius: "50%",
                                            cursor: "pointer",
                                          }}
                                        />
                                      );
                                    }
                                    return null;
                                  })}
                                {appointment?.employee_ids?.split(",").length >
                                  4 && (
                                    <Avatar
                                      label={`+${appointment.employee_ids.split(",")
                                        .length - 4
                                        }`}
                                      shape="circle"
                                      size="small"
                                      style={{
                                        border: "transparent",
                                        background: "#eee",
                                      }}
                                    />
                                  )}
                              </AvatarGroup>
                              {Array.isArray(appointment.employee_ids) &&
                                appointment.employee_ids.length > 4 && (
                                  <span style={{ marginLeft: "-4px" }}>4+</span>
                                )}
                            </div>
                          </td>
                          <td style={{ width: "20%" }}>
                            {appointment.absences.map((absence, index) => (
                              <div key={index}>
                                {absence.absense_status}-{absence.user_id}
                              </div>
                            ))}
                          </td>{" "}
                          <td style={{ width: "10%" }}>
                            {appointment.appointment_time}
                          </td>
                          <td style={{ width: "20%" }}>
                            {moment(appointment.appointment_date).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td style={{ width: "10%" }}>
                            {appointment.appointment_status}
                          </td>
                          <td style={{ width: "10%" }}>
                            <Menu
                              model={items[index].items}
                              popup
                              ref={menuRefs[index]}
                              id={`popup_menu_left_${index}`}
                            />
                            <Button
                              icon="fi fi-bs-menu-dots-vertical"
                              className="mr-2"
                              onClick={(event) =>
                                menuRefs[index].current.toggle(event)
                              }
                              aria-controls={`popup_menu_left_${index}`}
                              aria-haspopup
                              style={{
                                background: "none",
                                border: "none",
                                color: "black",
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {selectedAppointmentDetails && (
                    <Dialog
                      visible={appointmentDetailView}
                      onHide={hideAppointmentDetails}
                      position="center"
                      draggable={false}
                      header={
                        <div className="capitalize">
                          <i className="fi fi-br-time-check"></i>
                          {" " + selectedAppointmentDetails.appointment_title}
                        </div>
                      }
                      style={{ width: "50vw" }}
                      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                    >
                      {selectedAppointmentDetails && (
                        <div>
                          <div className="flex gap-2 mt-2 mb-3 ml-1">
                            <div className="flex-1 flex  justify-content-start">
                              {" "}
                              {
                                selectedAppointmentDetails.appointment_description
                              }
                            </div>

                            <div className="flex justify-content-end align-items-baseline ">
                              <span
                                className={`border-round-lg bg-blue-400 text-white border-blue-500 px-3 py-1`}
                              >
                                {selectedAppointmentDetails.appointment_status}
                              </span>
                            </div>
                          </div>

                          <div className=" mb-1 mt-2">
                            <span className="col-6">
                              <i class="fi fi-rr-calendar-clock"></i> Date:
                            </span>
                            <span>
                              {moment(
                                selectedAppointmentDetails.appointment_date
                              ).format("DD-MM-YYYY")}
                            </span>
                          </div>
                          <div className=" mb-1 mt-2">
                            <span className="col-6">
                              <i class="fi fi-rr-member-list"></i>  Creator:
                            </span>
                            <span >
                              {
                                `${employees.find(item => item.id ===
                                  selectedAppointmentDetails.appointment_creator
                                )?.firstname}
                                ${employees.find(item => item.id ===
                                  selectedAppointmentDetails.appointment_creator
                                )?.lastname
                                }`

                              }
                            </span>
                          </div>

                          <div className="mb-1 mt-2">
                            <span className="col-6">
                              <i class="fi fi-rs-clock-five"></i> Time:
                            </span>
                            <span className="">
                              {selectedAppointmentDetails.appointment_time}
                            </span>{" "}
                          </div>
                          {selectedAppointmentDetails.absences != "" && (
                            <div className="mt-2">
                              {selectedAppointmentDetails.absences.map(
                                (absence, index) => (
                                  <span key={index} className="">
                                    <span className="col-6">
                                      {" "}
                                      <i class="fi fi-rs-user-xmark"></i>{" "}
                                      {absence.absense_status}:
                                    </span>
                                    <span className="">
                                      {absence.user_id
                                        ?.split(",")
                                        .map((memberId, index) => {
                                          const employee = employees.find(
                                            (emp) => emp.id === memberId.trim()
                                          );
                                          if (employee) {
                                            return (
                                              <span key={index}>
                                                {`${employee.firstname}${employee.lastname}`}
                                              </span>
                                            );
                                          }
                                          return null;
                                        })}
                                    </span>
                                  </span>
                                )
                              )}
                            </div>
                          )}
                          <div className="ml-2 mt-2 inline-flex">
                            <span className="mr-2 white-space-nowrap" >
                              <i class="fi fi-rs-users-alt"></i> Team:
                            </span>
                            <div>
                              {selectedAppointmentDetails?.employee_ids
                                ?.split(",")
                                .map((memberId, index) => {
                                  const employee = employees.find(
                                    (emp) => emp.id === memberId.trim()
                                  );
                                  return (
                                    <span key={employee?.id}>
                                      {`${employee?.firstname} ${employee?.lastname}`}
                                      {index !== selectedAppointmentDetails.employee_ids.split(",").length - 1 ? ', ' : '.'}
                                    </span>
                                  );
                                })}
                            </div>

                          </div>
                        </div>
                      )}
                    </Dialog>
                  )}
                </div>
              </ScrollPanel>
              <RescheduleDialog
                visible={rescheduleDialogVisible}
                onHide={() => {
                  setRescheduleDialogVisible(false);
                  setSelectedAppointment(null);
                }}
                onSubmit={handleRescheduleSubmit}
                appointmentData={selectedAppointment}
                reFetch={reFetch}
              />
            </div>
          </div>
        </div>
        <div className="card">
          <h2 className="my-2">Calendar</h2>
          <TodayCalendar />
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
