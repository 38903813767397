import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/images/KodukkuLogoBorderNone.png";
import html2pdf from "html2pdf.js";
import { Button } from "primereact/button";
import axios from "axios";
import { PAYROLL_API, DEPARTMENT_API } from "../../constants/api";

const SalaryCertificate = () => {
  const { payroll_id } = useParams();
  const [payrollDetails, setPayrollDetails] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    fetchPayrollDetails();
  }, [payroll_id]);

  const fetchPayrollDetails = async () => {
    try {
      const response = await axios.get(
        PAYROLL_API.GET_PARTICULAR_PAYROLL(payroll_id)
      );
      setPayrollDetails(response.data.payrollDetails);
    } catch (error) {
      console.error("Error fetching payroll details:", error);
    }
  };
  const getAllDepartment = async () => {
    try {
      const response = await axios.get(DEPARTMENT_API.DEPARTMENT_FETCH);
      setDepartmentData(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  useEffect(() => {
    getAllDepartment();
  }, []);
  const downloadPdf = () => {
    const element = document.getElementsByClassName("salary-certi")[0];
    const fileName = "Salary_Certificate.pdf";
    html2pdf(element, {
      filename: fileName,
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    });
  };


  const currentDate = new Date();
  const formattedCurrentDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1
    }-${currentDate.getFullYear()}`;

  return (
    <div>
      <div className="flex justify-content-between">
        <div className="mb-4">
          <i
            className="pi pi-arrow-left"
            style={{ fontSize: "2rem", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          ></i>
        </div>
        <div className="flex mb-2">
          <Button
            onClick={downloadPdf}
            icon="fi fi-rr-download"
            className="px-3 btn-button"
            style={{ height: "40px" }}
          >
            Download
          </Button>
        </div>
      </div>
      <div className="salary-certi card p-5 border ml-6" style={{ width: "fit-content" }}>
        <div className="title flex align-items-center">
          <div className="img">
            <img src={logo} alt="" />
          </div>
          <div className="title mx-3">
            <h2 className="text-4xl " style={{ color: "#68bcff" }}>
              Kodukku Classifieds Private Limited
            </h2>
            <p className="font-semibold">
              253 Thanthai Periyar Nagar, Pallikaranai, Chennai, Tamil Nadu
              600100.
            </p>
          </div>
        </div>
        <h4 className="mt-5 font-semibold">
          Date: <span>{formattedCurrentDate}</span>
        </h4>
        <h4 className="mt-5 font-semibold">To</h4>
        <p className="ml-3">Bank Of India ,</p>
        <p className="ml-3">
          This is to certify that employment details are below
        </p>
        <table
          className="my-8 w-6"
          style={{ border: "1px solid black", borderSpacing: "0" }}
        >
          <tbody>
            <tr>
              <td
                className="p-2"
                style={{ border: "1px solid black", borderSpacing: "0" }}
              >
                NAME
              </td>
              <td
                className="p-2"
                style={{ border: "1px solid black", borderSpacing: "0", textTransform: "capitalize" }}
              >
                {payrollDetails?.userDetails?.firstname}{" "}
                {payrollDetails?.userDetails?.lastname}
              </td>
            </tr>
            <tr>
              <td
                className="p-2"
                style={{ border: "1px solid black", borderSpacing: "0" }}
              >
                Employee No
              </td>
              <td
                className="p-2"
                style={{ border: "1px solid black", borderSpacing: "0" }}
              >
                {payrollDetails?.empid}{" "}
              </td>
            </tr>
            <tr>
              <td
                className="p-2"
                style={{ border: "1px solid black", borderSpacing: "0" }}
              >
                Joining Date
              </td>
              <td
                className="p-2"
                style={{ border: "1px solid black", borderSpacing: "0" }}
              >
                {payrollDetails?.employeeDetails.joining_date}
              </td>
            </tr>
            <tr>
              <td
                className="p-2"
                style={{ border: "1px solid black", borderSpacing: "0" }}
              >
                Department
              </td>
              <td
                className="p-2"
                style={{ border: "1px solid black", borderSpacing: "0", textTransform: "capitalize" }}
              >
                {departmentData.length > 0 &&
                  payrollDetails?.employeeDetails?.department &&
                  departmentData.find(
                    (dept) =>
                      dept.department_id ===
                      parseInt(
                        payrollDetails.employeeDetails.department
                      )
                  )?.department_name}
              </td>
            </tr>
            <tr>
              <td
                className="p-2"
                style={{ border: "1px solid black", borderSpacing: "0" }}
              >
                Designation
              </td>
              <td
                className="p-2"
                style={{ border: "1px solid black", borderSpacing: "0", textTransform: "capitalize" }}
              >
                {payrollDetails?.employeeDetails?.position}
              </td>
            </tr>
            <tr>
              <td
                className="p-2"
                style={{ border: "1px solid black", borderSpacing: "0" }}
              >
                Salary
              </td>
              <td
                className="p-2"
                style={{ border: "1px solid black", borderSpacing: "0" }}
              >
                {payrollDetails?.employeeDetails.salary}
              </td>
            </tr>
            <tr>
              <td
                className="p-2"
                style={{ border: "1px solid black", borderSpacing: "0" }}
              >
                Status
              </td>
              <td
                className="p-2"
                style={{ border: "1px solid black", borderSpacing: "0", textTransform: "capitalize" }}
              >
                {payrollDetails?.employeeDetails.employee_type}
              </td>
            </tr>
          </tbody>
        </table>
        <p className="my-3">
          This certificate is being issued upon the request of the employee. We
          confirm that the above details are true and accurate to the best of
          our knowledge.
        </p>
        <p className="my-3">Sincerely</p>
        <p className="mt-3">HR NAME</p>
        <p className="">HR DESIGNATION</p>
        <p className="my-3">
          This certificate is issued Electronically hence no sign and stamp
          required.
        </p>
      </div>
    </div>
  );
};

export default SalaryCertificate;
