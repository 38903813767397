export const ROUTE_CONSTANTS = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  ADMIN_DASHBOARD: "/admin/dashboard",
  ADMIN_DASHBOARD1: "/admin/dashboard/:id",
  USER_DASHBOARD: "/user/dashboard",
  USER_DASHBOARD1: "/user/dashboard/:id",
  ADDEMPLOYEE: "/AddEmployee",
  EMPATTENDANCE: "/EmpAttendance",
  TODAYATTENDANCE: "/TotalAttendance",
  ATTENDANCESHEET: "/AttendanceSheet",
  ALLLEAVEREQUEST: "/AllLeaveRequest",
  APPLYLEAVE: "/ApplyLeave",
  APPLYHISTORY: "/ApplyHistory",
  APPLYPENDING: "/ApplyPending",
  LEAVEBALANCE: "/LeaveBalanace",
  LEAVEAPPLY: "/LeaveApply",
  PAYSLIP: "/PaySlip/:payroll_id",
  USER_PAYSLIP: "/PaySlip",
  PAYHEADS: "/PayHeads",
  EMPPAYSLIP: "/EmpPayslip",
  EMPLOYEEPAYROLL: "/EmpPayroll",
  ALLEMP: "/AllEmployee",
  SALARYCERTI: "/SalaryCertificate/:payroll_id",
  ONBOARD: "/Onboarding",
  LEAVE: "/Leave",
  PROFILE: "/profile/:id",
  ADMINTASK: "/Task",
  EMPLOYEETASK: "/EmployeeTask",
  TASKHISTORY: "/Taskhistory/:taskId",
  ADMIN_TASKHISTORY: "/TaskDetails/:taskId",
  TASKDETAIL: "/Taskdetail/:taskId",
  PROJECT: "/Project",
  DEPARTMENT: "/Department",
  DEPARTMENT_HISTORY: "/Department/Departhistory",
  MYPROJECT: "/MyProjects",
  ALLTASKS: "/AllTasks",
  ADDEMPLOYEES: "/addEmployeeDetails",
  HOLIDAYS: "/holidays",
  USERHOLIDAYS: "/AllHolidays",
  INACTIVEEMPLOYEES: "/InactiveEmployees",
  HIERARCHY: "/CompanyHierarchy",
  ASSETMANANGEMENT: "/assets",
  MY_PERMISSIONS: "/MyPermissions",
  All_PERMISSIONS: "/AllPerissions",
  ADMIN_LEAVES: "/AllLeaves",
  ALL_ATTENDANCE: "/AllAttendance",
  HOLIDAYS_CALENDAR: "/holidays/calendar",
  TIME_SHEET: "/timeSheet",
  TIME_SHEET_APPROVAL: "/timeSheetApproval",
  LEAVE_VIEW: "/leaveData/:id",
  MY_ACCOUNT: "/myaccount",
  RESET: "/resetPassword",
  TASK_APPROVAL: "/taskapproval",
  TASK_APPROVER: "/taskapprover",
  ROLE: '/rolePermission',
  EMPLOYEE_ASSETS_REQUEST: '/AssetsRequest',
  ALL_ASSETS_REQUEST: '/allassetsrequest',
  MY_ASSETS_DETAIL: '/MyAssetsDetail',
  ALL_ASSETS_DETAIL: '/AllAssetsDetail',
  ALL_LETTER: '/AllLetter',
  EMPLOYEE_LETTER: '/Letter',
  ALL_PERSONAL_DOCUMENT: '/AllPersonalDocument',
  EMPLOYEE_PERSONAL_DOCUMENT: '/PersonalDocument',
  ALL_MEMO: '/AllMemo',
  MY_MEMO: '/MyMemo',
  STAFF_CATEGORIES: '/staffcategories'
};


