import React from "react";

const DepartHistory = () => {
  const tableStyle = {
    borderCollapse: "collapse",
    width: "100%",
  };

  const cellStyle = {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
  };

  const headerCellStyle = {
    ...cellStyle,
    backgroundColor: "#f2f2f2",
  };

  return (
    <div className="card">
      <h3>Description :</h3>
      <p style={{ color: "#5b5b5b" }} className="mt-2">
        Human Resources (HR) professionals serve as the backbone of any
        organization, playing a pivotal role in its success and growth. Tasked
        with managing the company's most valuable asset—its people—HR
        professionals are entrusted with a diverse range of responsibilities
        essential for maintaining a healthy and productive work environment.
        From recruitment and onboarding to training and development, HR
        professionals oversee every stage of the employee lifecycle. They are
        instrumental in attracting top talent to the organization, ensuring a
        seamless onboarding process, and fostering a culture of inclusivity and
        diversity. Moreover, HR professionals serve as advocates for employees,
        addressing their concerns, providing guidance on company policies, and
        facilitating conflict resolution when necessary. Additionally, HR plays
        a crucial role in strategic planning, aligning human capital with
        organizational objectives, and driving initiatives to enhance employee
        engagement and retention. In essence, HR professionals are the catalysts
        for building strong, cohesive teams that drive innovation, productivity,
        and ultimately, the success of the company.
      </p>
      <h3 className="mt-3">History :</h3>
      <table style={tableStyle} className="mt-3">
        <thead>
          <tr>
            <th style={headerCellStyle}>Name</th>
            <th style={headerCellStyle}>Start Date</th>
            <th style={headerCellStyle}>End Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={cellStyle}>Mukesh</td>
            <td style={cellStyle}>25/01/2024</td>
            <td style={cellStyle}>25/08/2024</td>
          </tr>
          <tr>
            <td style={cellStyle}>John</td>
            <td style={cellStyle}>15/03/2024</td>
            <td style={cellStyle}>15/09/2024</td>
          </tr>
          <tr>
            <td style={cellStyle}>Emily</td>
            <td style={cellStyle}>02/05/2024</td>
            <td style={cellStyle}>02/11/2024</td>
          </tr>
          <tr>
            <td style={cellStyle}>Alice</td>
            <td style={cellStyle}>18/07/2024</td>
            <td style={cellStyle}>18/01/2025</td>
          </tr>
          <tr>
            <td style={cellStyle}>Michael</td>
            <td style={cellStyle}>10/09/2024</td>
            <td style={cellStyle}>10/03/2025</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DepartHistory;
