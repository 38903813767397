import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";

export default function EditTimeSheet({
  editVisible,
  handleClose,
  handleUpdate,
  setSelectedTitle,
  editData,
  errors,
  projectListData,
  selectedTitle,
  setEditData,
}) {
  return (
    <Dialog
      visible={editVisible}
      onHide={handleClose}
      modal
      position="center"
      style={{ width: "40vw" }}
      draggable={false}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      header="Edit Time Sheet"
    >
      <div className="grid">
        <div className="col-6">
          <label htmlFor="date">Date</label>
          <InputText id="date" name="date" value={editData.date} disabled />
          {errors.date && <small className="p-error">Date is required.</small>}
        </div>
        <div className="col-6"></div>

        <div className="col-6">
          <label htmlFor="startTime">Start Time</label>
          <InputText
            id="startTime"
            name="startTime"
            value={editData.startTime}
            disabled
          />
          {errors.startTime && (
            <small className="p-error">Start time is required.</small>
          )}
        </div>
        <div className="col-6">
          <label htmlFor="endTime">End Time</label>
          <InputText
            id="endTime"
            name="endTime"
            value={editData.endTime}
            disabled
          />
          {errors.endTime && (
            <small className="p-error">End time is required.</small>
          )}
        </div>

        <div className="col-12">
          <label htmlFor="title">Title</label>
          <Dropdown
            className="w-full"
            id="title"
            name="title"
            value={selectedTitle}
            options={projectListData}
            onChange={(e) => setSelectedTitle(e.value)}
            optionLabel="label"
            placeholder="Select a Project"
          />
          {errors.title && (
            <small className="p-error">Title is required.</small>
          )}
        </div>

        <div className="col-12">
          <label htmlFor="description">Description</label>
          <InputTextarea
            id="description"
            name="description"
            value={editData.description}
            onChange={(e) =>
              setEditData({ ...editData, description: e.target.value })
            }
            style={{ resize: "none" }}
            placeholder="..."
            rows={3}
          />
          {errors.description && (
            <small className="p-error">Description is required.</small>
          )}
        </div>
      </div>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "8px",
          gap: "10px",
        }}
      >
        <Button
          label="Cancel"
          className="p-button-danger"
          style={{ width: "auto" }}
          onClick={handleClose}
        />
        <Button
          label="Submit"
          style={{ width: "auto" }}
          onClick={handleUpdate}
        />
      </div>
    </Dialog>
  );
}
