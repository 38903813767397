import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import Select from "react-select";
import { Link } from "react-router-dom";
import { TASK_API, EMPLOYEE_API, PROJECT_API } from "../../../constants/api";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setFirst,
  setPageRows,
  setStatusSelector,
} from "../../../service/paginator";
import { Menu } from "primereact/menu";

export default function TaskAdmin() {
  const status = [
    { name: "Initialized" },
    { name: "In Progress" },
    { name: "Completed" },
    { name: "Returned" },
    { name: "Resolved" },
    { name: "Rejected" },
  ];
  const priority = [{ name: "High" }, { name: "Medium" }, { name: "Low" }];
  const [products, setProducts] = useState([]);
  const [visible, setVisible] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const today = new Date();
  const formattedDate = today.toISOString().split("T")[0];
  const [addform, setAddform] = useState({
    Task_id: "",
    projectname: "",
    maker: "",
    priority: "",
    approver: "",
    status: "",
    processorselect: [],
    StartDate: "",
    DueDate: "",
    Details: "",
    task_name: "",
    agenda: "",
    meeting_link: "",
    meeting_title: "",
    participants: [],
    MeetingSheduleDate: "",
    start_time: "",
    end_time: "",
    duration: "",
  });
  const [editData, setEditData] = useState(null);
  const [approverOptions, setApproverOptions] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [employees, setEmployees] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const [selectedParticipantsIds, setSelectedParticipantsIds] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [duration, setDuration] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const user = JSON.parse(localStorage.getItem("user"));
  const fileInputRef = useRef(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const menuRef = useRef(null)
  const [employeesData, setEmployeesData] = useState([])
  const dispatch = useDispatch();
  const first = useSelector(
    (state) => state.paginator.pages.allTaskAdmin.first
  );
  const rowsPerPage = useSelector(
    (state) => state.paginator.pages.allTaskAdmin.rowsPerPage
  );

  const [localFirst, setLocalFirst] = useState(first || 0);
  const [localRowsPerPage, setLocalRowsPerPage] = useState(rowsPerPage || 10);

  const onPageChange = (e) => {
    setLocalFirst(e.first);
    dispatch(setFirst({ page: "allTaskAdmin", value: e.first }));
  };

  const onPageRowsChange = (event) => {
    setLocalRowsPerPage(event.rows);
    dispatch(setPageRows({ page: "allTaskAdmin", value: event.rows }));
  };

  const rowsPerPageOptions = [10, 25, 50];

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setLocalRowsPerPage(newRowsPerPage);
    dispatch(setPageRows({ page: "allTaskAdmin", value: newRowsPerPage }));
  };

  const validateForm = () => {
    let errors = {};
    if (!addform.projectname) {
      errors.projectname = "Project name is required";
    }
    if (!addform.DueDate) {
      errors.DueDate = "Due date is required";
    }
    if (!addform.StartDate) {
      errors.StartDate = "Start date is required";
    }
    if (!addform.approver) {
      errors.approver = "Approver is required";
    }
    if (!addform.Details) {
      errors.Details = "Details is required";
    }
    if (!addform.priority) {
      errors.priority = "priority is required";
    }
    if (!addform.status) {
      errors.status = "status name is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const toastRef = useRef(null);

  const showToast = (message) => {
    if (toastRef.current) {
      toastRef.current.show({
        severity: "error",
        summary: "Error Message",
        detail: message,
      });
    }
  };
  const fetchApprovers = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.All_USERS);
      const { usersData } = response.data;
      setEmployeesData(usersData);
      const formattedApprovers = usersData?.map((employee) => ({
        label: `${employee.firstname} ${employee.lastname}`,
        value: `${employee.id}`,
      }));
      setApproverOptions(formattedApprovers);
      setEmployees(formattedApprovers);
    } catch (error) {
      console.error("Error fetching approvers:", error);
    }
  };

  useEffect(() => {
    fetchApprovers();
  }, []);

  const fetchAllTasks = async () => {
    try {
      const response = await axios.get(TASK_API.GET_ALL_TASK);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  useEffect(() => {
    fetchAllTasks();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(PROJECT_API.GET_ALL_PROJECTS);
      setProjectOptions(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const onUpload = (event) => {
    const files = event.target.files;
    if (files.length > 5) {
      event.preventDefault();
      alert("You can only select up to 5 files.");
      return;
    }
    const fileNames = [];
    for (let i = 0; i < files.length; i++) {
      fileNames.push(files[i].name);
    }
    setUploadedFiles(fileNames);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmation(false);
  };
  const handleDelete = async (taskId) => {
    try {
      await axios.delete(TASK_API.DELETE_TASK(taskId));

      const updatedProducts = products.filter(
        (product) => product.Task_id !== taskId
      );
      setProducts(updatedProducts);
      hideConfirmationModal();
      fetchAllTasks();
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  const confirmDelete = () => {
    handleDelete(selectedRow.Task_id);
  };

  const getPriorityClassName = (priority) => {
    switch (priority) {
      case "High":
        return "high-priority";
      case "Medium":
        return "medium-priority";
      case "Low":
        return "low-priority";
      default:
        return "";
    }
  };

  const clearForm = () => {
    setAddform({
      Task_id: "",
      projectname: "",
      maker: "",
      priority: "",
      approver: "",
      status: "",
      processorselect: [],
      StartDate: "",
      DueDate: "",
      Details: "",
      task_name: "",
      agenda: "",
      meeting_link: "",
      meeting_title: "",
      participants: [],
      MeetingSheduleDate: "",
      start_time: "",
      end_time: "",
      duration: "",
      selectedParticipants: [],
    });
    setStartTime("");
    setEndTime("");
    setDuration("");
    setParticipants([]);
    setUploadedFiles([]);
  };

  const handleAddTask = (e) => {
    const { name, value } = e.target;
    setAddform({
      ...addform,
      [name]: value,
    });

    setFormErrors({
      ...formErrors,
      [name]: null,
    });
  };

  const handleAddvalues = (data) => {
    setEditData(data);
    const selectedEmployees = data.Processer.split(",").map((id) => {
      const employee = approverOptions.find(
        (employee) => employee.value === id
      );
      return {
        label: `${employee?.label || id}`,
        value: employee ? employee.value : id,
      };
    });
    const selectedParticipants = data?.participants
      ? data.participants.split(",").map((id) => {
        const participant = approverOptions.find(
          (employee) => employee.value === id
        );
        return {
          label: `${participant?.label || id}`,
          value: participant ? participant.value : id,
        };
      })
      : [];
    setAddform({
      ...addform,
      Task_id: data.Task_id,
      projectname: data.Project,
      //maker: user.firstname,
      priority: { name: data.Priority },
      approver: data.Approver,
      status: { name: data.Status },
      processorselect: selectedEmployees,
      StartDate: data.StartDate,
      DueDate: data.DueDate,
      Details: data.Details,
      task_name: data.task_name,
      meeting_title: data.meeting_title,
      meeting_link: data.meeting_link,
      agenda: data.agenda,
      participants: selectedParticipants,
      MeetingSheduleDate: data.MeetingSheduleDate,
      start_time: data.start_time,
      end_time: data.end_time,
      duration: data.duration,
      filename: data.filename,
    });
    setParticipants(selectedParticipants);
    setSelectedParticipantsIds(
      selectedParticipants.map((participant) => participant.value)
    );
    setSelectedParticipantsIds(
      selectedParticipants.map((employee) => employee.value)
    );
    setStartTime(data.start_time);
    setEndTime(data.end_time);
    setDuration(data.duration);
    setUploadedFiles(data.filename);
    setVisible(true);
  };

  const checkFormValidity = () => {
    if (!editData) {
      return (
        addform.projectname &&
        addform.DueDate &&
        addform.StartDate &&
        addform.Details &&
        addform.priority &&
        addform.status &&
        selectedEmployeeIds.length > 0
      );
    } else {
      return true;
    }
  };

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [addform, selectedEmployeeIds]);

  const handleEdit = (rowData) => {
    setSelectedRow(rowData);
    handleAddvalues(rowData);
  };

  const handleCancel = () => {
    setVisible(false);
    setEditData(null);
    clearForm();
    setStartTime("");
    setEndTime("");
    setDuration("");
    setParticipants([]);
    setUploadedFiles([]);
  };
  const handleEmployeeSelectChange = (selectedOptions) => {
    setSelectedEmployeeIds(selectedOptions.map((option) => option.value));
    setAddform({
      ...addform,
      processorselect: selectedOptions,
    });
  };
  const handleParticipantSelectChange = (selectedOptions) => {
    setSelectedParticipantsIds(selectedOptions.map((option) => option.value));
    setParticipants(
      selectedOptions.map((option) => ({
        label: option.label,
        value: option.value,
      }))
    );
    setAddform({
      ...addform,
      participants: selectedOptions,
    });
  };

  const handleAddTaskSubmit = async () => {
    try {
      if (isSubmitting || !validateForm()) {
        return;
      }

      const processorselect = Array.isArray(addform.processorselect)
        ? addform.processorselect.map((option) => option.value).join(",")
        : addform.processorselect;
      const participants = addform.participants
        ? addform.participants.map((participant) => participant.value).join(",")
        : addform.participants;
      const meetingSheduleDate = addform.MeetingSheduleDate || "";
      const formData = new FormData();
      formData.append("Creater", `${user.data.id}`);
      formData.append("Processer", processorselect);
      formData.append("Approver", addform.approver);
      formData.append("Project", addform.projectname);
      formData.append("Details", addform.Details);
      formData.append("Status", addform.status.name);
      formData.append("Priority", addform.priority.name);
      formData.append("StartDate", addform.StartDate);
      formData.append("DueDate", addform.DueDate);
      formData.append("task_name", addform.task_name);
      formData.append("meeting_title", addform.meeting_title);
      formData.append("meeting_link", addform.meeting_link);
      formData.append("agenda", addform.agenda);
      formData.append("participants", participants);
      formData.append("MeetingSheduleDate", meetingSheduleDate);
      formData.append("start_time", startTime);
      formData.append("end_time", endTime);
      formData.append("duration", duration);

      const files = fileInputRef.current.files;
      if (files.length > 0) {
        Array.from(files).forEach((file) => {
          formData.append("files", file);
        });
      } else {
        formData.append("filename", null);
      }

      setIsSubmitting(true);
      if (editData) {
        await axios.put(TASK_API.EDIT_TASK(editData.Task_id), formData);
        clearForm();
      } else {
        await axios.post(TASK_API.ADD_TASK, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        clearForm();
      }
      setIsSubmitting(false);
      setEditData(null);
      clearForm();
      setVisible(false);
      fetchAllTasks();
    } catch (error) {
      console.error("Error adding/editing task:", error);
      const errorMessage =
        error.response.data || "Failed to submit task. Please try again.";
      if (error.response && error.response.data) {
        showToast(error.response.data.message);
      } else {
        showToast("An error occurred.");
      }
      setIsSubmitting(false);
    }
  };

  const handleAddButtonClick = () => {
    setVisible(true);
  };
  const submitButtonText = editData ? "Update" : "Submit";

  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
    calculateDuration(e.target.value, endTime);
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
    calculateDuration(startTime, e.target.value);
  };

  const calculateDuration = (startTime, endTime) => {
    const [startHour, startMinute] = startTime?.split(":").map(Number);
    const [endHour, endMinute] = endTime?.split(":").map(Number);
    const totalStartMinutes = startHour * 60 + startMinute;
    const totalEndMinutes = endHour * 60 + endMinute;
    let durationMinutes = totalEndMinutes - totalStartMinutes;
    if (durationMinutes < 0) {
      durationMinutes += 1440;
    }
    const durationHours = Math.floor(durationMinutes / 60);
    const remainingMinutes = durationMinutes % 60;
    setDuration(`${durationHours} hours ${remainingMinutes} minutes`);
  };

  const statusSelector = useSelector(
    (state) => state.paginator.pages.allTaskAdmin.statusSelector
  );
  const filteredProducts =
    statusSelector === "All"
      ? products
      : products.filter((product) => product.Status === statusSelector);
  const getStatusColor = (rowData) => {
    switch (rowData.approval_status) {
      case "Rejected":
        return { borderColor: "red", color: "red" };
      default:
        return { borderColor: "gray" };
    }
  };

  const menuItems = [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => handleEdit(selectedRequest),
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => handleDeleteModal(selectedRequest),
    },
  ];

  const handleContextMenu = (event, rowData) => {
    event.preventDefault();
    setSelectedRequest(rowData);
    menuRef.current.show(event);
  };

  const actionBodyTemplate = (rowData) => (
    <div className="flex" style={{ alignItems: "center" }}>
      <Button
        icon="pi pi-ellipsis-v"
        className="p-button-rounded p-button-text"
        onClick={(e) => handleContextMenu(e, rowData)}
      />
      <Menu model={menuItems} popup ref={menuRef} className="w-8rem" />
    </div>
  );

  const handleDeleteModal = (rowDataToDelete) => {
    setSelectedRow(rowDataToDelete);
    setDisplayConfirmation(true);
  };

  return (
    <div>
      <div className="md:flex justify-content-between">
        <div className="ml-2">
          <h2>All Tasks</h2>
        </div>
        <Toast ref={toastRef} />
        <div className="md:col-4 lg:col-3 col-12 ">
          <div className="p-inputgroup">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div className="card">
        <div
          className="md:flex block justify-content-between mb-2 align-items-center"
        >
          <div
            className="md:flex block filter-buttons "
            style={{ flexWrap: "wrap", gap: "10px" }}
          >
            <Button
              className={`filter-button1 md:mt-0 mt-3 ${selectedStatus === "All" ? "active" : ""
                }`}
              onClick={() => {
                setSelectedStatus("All");
                dispatch(
                  setStatusSelector({ page: "allTaskAdmin", value: "All" })
                );
              }}
            >
              All
            </Button>
            <Button
              className={`filter-button2 md:mt-0 mt-3 ${selectedStatus === "Initialized" ? "active" : ""
                }`}
              onClick={() => {
                setSelectedStatus("Initialized");
                dispatch(
                  setStatusSelector({
                    page: "allTaskAdmin",
                    value: "Initialized",
                  })
                );
              }}
            >
              Initialized
            </Button>
            <Button
              className={`filter-button3 md:mt-0 mt-3 ${selectedStatus === "In Progress" ? "active" : ""
                }`}
              onClick={() => {
                setSelectedStatus("In Progress");
                dispatch(
                  setStatusSelector({
                    page: "allTaskAdmin",
                    value: "In Progress",
                  })
                );
              }}
            >
              In Progress
            </Button>
            <Button
              className={`filter-button5 md:mt-0 mt-3 ${selectedStatus === "Returned" ? "active" : ""
                }`}
              onClick={() => {
                setSelectedStatus("Returned");
                dispatch(
                  setStatusSelector({ page: "allTaskAdmin", value: "Returned" })
                );
              }}
            >
              Returned
            </Button>
            <Button
              className={`filter-button4 md:mt-0 mt-3 ${selectedStatus === "Resolved" ? "active" : ""
                }`}
              onClick={() => {
                setSelectedStatus("Resolved");
                dispatch(
                  setStatusSelector({ page: "allTaskAdmin", value: "Resolved" })
                );
              }}
            >
              Resolved
            </Button>
            <Button
              className={`filter-button6 md:mt-0 mt-3 ${selectedStatus === "Completed" ? "active" : ""
                }`}
              onClick={() => {
                setSelectedStatus("Completed");
                dispatch(
                  setStatusSelector({
                    page: "allTaskAdmin",
                    value: "Completed",
                  })
                );
              }}
            >
              Completed
            </Button>
          </div>
          <div className="flex align-items-center justify-content-end mt-3 md:mt-0">
            <Button className="px-3 mb-2" onClick={handleAddButtonClick}>
              <i className="fi fi-rr-plus"></i>
            </Button>
          </div>
        </div>
        <DataTable
          value={filteredProducts}
          paginator
          tableStyle={{ minWidth: "50rem" }}
          globalFilter={globalFilter.trim() ? globalFilter : null}
          removableSort
          className="dataTableHiddenFooterBg"
          rows={localRowsPerPage}
          onPage={onPageChange}
          onPageRows={onPageRowsChange}
          first={localFirst}
          footer={
            <Dropdown
              value={localRowsPerPage}
              options={rowsPerPageOptions.map((option) => ({
                label: option,
                value: option,
              }))}
              onChange={handleRowsPerPageChange}
              placeholder="Select Rows Per Page"
            />
          }
        >
          <Column
            field="Task_id"
            header="Task Id"
            style={{ width: "90px" }}
            sortable
            frozen
          ></Column>
          <Column
            field="task_name"
            header="Task Name"
            body={(rowData) => (
              <>
                <Tooltip
                  target=".link-without-color"
                  mouseTrack
                  mouseTrackLeft={5}
                  mouseTrackTop={5}
                >
                  Click to view details
                </Tooltip>
                <Link
                  className="link-without-color"
                  to={`/TaskDetails/${rowData.Task_id}`}
                >
                  {rowData.task_name?.length > 15
                    ? `${rowData?.task_name?.slice(0, 15)}...`
                    : rowData?.task_name}
                </Link>
              </>

            )}
            sortable
          ></Column>
          <Column
            field="Creater"
            header="Maker"
            body={(rowData) => {
              const createrId = rowData.Creater;
              const creater = employeesData.find((emp) => emp.id === createrId);
              const createrName = creater ? `${creater.firstname} ${creater.lastname}` : '';
              return (
                <div
                  id={`Creater_${rowData.Task_id}`}
                  className="tooltip-container"
                >
                  {createrName.length > 20
                    ? `${createrName?.slice(0, 20)}...`
                    : createrName}
                  <Tooltip
                    target={`#Creater_${rowData.Task_id}`}
                    position="right"
                  >
                    {createrName}
                  </Tooltip>
                </div>
              );
            }}
          />
          <Column
            field="Approver"
            header="Approver"
            body={(rowData) => {
              const approverId = rowData.Approver;
              const approver = employeesData.find((emp) => emp.id === approverId);
              const approverName = approver ? `${approver.firstname} ${approver.lastname}` : '';

              return (
                <div
                  id={`Approver_${rowData.Task_id}`}
                  className="tooltip-container"
                >
                  {approverName.length > 20
                    ? `${approverName?.slice(0, 20)}...`
                    : approverName}
                  <Tooltip
                    target={`#Approver_${rowData.Task_id}`}
                    position="right"
                  >
                    {approverName}
                  </Tooltip>
                </div>
              );
            }}
          />
          <Column
            field="Processer"
            header="Processer"
            body={(rowData) => {
              const teamMemberIds = rowData.Processer.split(",");
              const processer = teamMemberIds
                .map((id) => employeesData.find((emp) => emp.id === id.trim()))
                .filter((emp) => emp !== undefined)
                .map((emp) => `${emp.firstname} ${emp.lastname}`)
                .join(", ");
              return (
                <div
                  id={`Processer_${rowData.Task_id}`}
                  className="tooltip-container"
                >
                  {processer?.length > 10
                    ? `${processer.slice(0, 10)}...`
                    : processer}
                  <Tooltip
                    target={`#Processer_${rowData.Task_id}`}
                    position="right"
                  >
                    {processer}
                  </Tooltip>
                </div>
              );
            }}
            style={{
              width: "180px",
              textTransform: "capitalize",
            }}
          ></Column>
          <Column
            field="Project"
            header="Project"
            style={{ width: "90px" }}
          ></Column>
          <Column
            field="Priority"
            header="Priority"
            style={{ width: "90px" }}
            body={(rowData) => {
              const priorityClassName = getPriorityClassName(rowData.Priority);
              return (
                <div className={priorityClassName}>{rowData.Priority}</div>
              );
            }}
          ></Column>
          <Column
            field="StartDate"
            header="Started"
            style={{ minWidth: "110px" }}
            body={(rowData) => moment(rowData.StartDate).format("DD-MM-YYYY")}
            sortable
          ></Column>
          <Column
            field="DueDate"
            header="Due"
            style={{ minWidth: "110px" }}
            body={(rowData) => moment(rowData.DueDate).format("DD-MM-YYYY")}
            sortables
          ></Column>
          <Column
            field="approval_status"
            header="Approval Status"
            bodyStyle={{ width: "105px", cursor: "pointer" }}
            body={(rowData) => (
              <div
                id={`approval_status_${rowData.Task_id}`}
                className="tooltip-container"
                style={{
                  border: "2px solid",
                  ...getStatusColor(rowData),
                  padding: "6px 12px",
                  borderRadius: "4px",
                }}
              >
                {rowData.approval_status === null
                  ? "Pending"
                  : rowData.approval_status}
                {rowData.approval_status !== "Approved" &&
                  rowData.task_rejection_reason && (
                    <Tooltip
                      target={`#approval_status_${rowData.Task_id}`}
                      position="top"
                    >
                      <div>
                        <strong>Rejection Reason:</strong>
                        <p>{rowData.task_rejection_reason}</p>
                      </div>
                    </Tooltip>
                  )}
              </div>
            )}
          />
          <Column
            field="Status"
            header="Status"
            style={{ width: "100px" }}
            sortable
            body={(rowData) => (
              <span
                className={rowData.Status === "Completed" ? "green-text" : ""}
              >
                {rowData.Status}
              </span>
            )}
          />
          <Column
            header="Actions"
            className="w-8rem action-button"
            body={actionBodyTemplate}
          ></Column>
        </DataTable>
        {/*add task and edit task */}
        <Dialog
          header={
            <div className="flex justify-content-between align-items-center">
              {editData ? "Edit Task" : "Add Task"}
              <span
                className="p-dialog-titlebar-close-icon"
                onClick={() => setVisible(false)}
              ></span>
            </div>
          }
          headerClassName="custom-dialog-header"
          visible={visible}
          onHide={() => {
            setVisible(false);
            setEditData(null);
            setAddform({
              Task_id: "",
              projectname: "",
              maker: "",
              priority: "",
              approver: "",
              status: "",
              processorselect: [],
              StartDate: "",
              DueDate: "",
              Details: "",
              task_name: "",
              agenda: "",
              meeting_link: "",
              meeting_title: "",
              participants: [],
              MeetingSheduleDate: "",
              start_time: "",
              end_time: "",
              duration: "",
              selectedParticipants: [],
            });
          }}
          style={{ width: "55vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          closable={true}
        >
          <div className="grid">
            <div className="md:col-6 col-12">
              <label htmlFor="Project">Project</label>
              <Dropdown
                value={addform.projectname}
                name="projectname"
                id="projectname"
                options={projectOptions.map((project) => ({
                  label: project.project_name,
                  value: project.project_name,
                }))}
                placeholder="Select a project"
                className="w-full"
                onChange={(e) => {
                  setAddform({ ...addform, projectname: e.value });
                }}
              />
              {formErrors.projectname && (
                <small className="text-red-500">{formErrors.projectname}</small>
              )}
            </div>
            <div className="md:col-6 col-12">
              <label htmlFor="TaskName">Task Name</label>
              <InputText
                id="task_name"
                name="task_name"
                value={addform.task_name}
                onChange={handleAddTask}
                className="w-full"
              />
            </div>
            {!editData && (
              <div className="md:col-3 col-12">
                <label htmlFor="Maker">Maker</label>
                <InputText
                  value={`${user.data.firstname} ${user.data.lastname}`}
                  readOnly
                  className="w-full"
                />
                <input type="hidden" name="makerId" value={user.data.id} />
              </div>
            )}
            <div className="md:col-3 col-12">
              <label htmlFor="Task">Approver</label>
              <Dropdown
                value={addform.approver}
                className="w-full"
                options={approverOptions}
                optionLabel="label"
                placeholder="Select an approver"
                onChange={(e) => setAddform({ ...addform, approver: e.value })}
                filter
                showClear
              />
              {formErrors.approver && (
                <small className="text-red-500">{formErrors.approver}</small>
              )}
            </div>
            <div className="md:col-6 col-12">
              <label htmlFor="Task">Processor</label>
              <Select
                value={addform.processorselect.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))}
                onChange={handleEmployeeSelectChange}
                options={employees}
                isMulti
                isClearable
                placeholder="Select team members"
                getOptionLabel={(option) => option.label}
              />
              {formErrors.processorselect && (
                <small className="text-red-500">
                  {formErrors.processorselect}
                </small>
              )}
            </div>
            <div className="md:col-6 col-12">
              <label htmlFor="Priority">Priority</label>
              <Dropdown
                value={addform.priority}
                name="priority"
                options={priority}
                optionLabel="name"
                placeholder="Select priority"
                className="w-full "
                onChange={handleAddTask}
              />
              {formErrors.priority && (
                <small className="text-red-500">{formErrors.priority}</small>
              )}
            </div>
            <div className="md:col-6 col-12">
              <label htmlFor="Status">Status</label>
              <Dropdown
                name="status"
                value={addform.status}
                options={status}
                optionLabel="name"
                placeholder="Select status"
                className="w-full "
                onChange={handleAddTask}
              />
              {formErrors.status && (
                <small className="text-red-500">{formErrors.status}</small>
              )}
            </div>
            <div className="md:col-6 col-12">
              <label htmlFor="StartDate">Start Date</label>
              <InputText
                type="date"
                id="StartDate"
                name="StartDate"
                value={addform.StartDate}
                onChange={handleAddTask}
                showIcon
                style={{ width: "100%" }}
                className={formErrors.StartDate ? "p-invalid" : ""}
                min={new Date().toISOString().split("T")[0]}
                disabled={!!editData}
              />
              {formErrors.StartDate && (
                <small className="text-red-500">{formErrors.StartDate}</small>
              )}
            </div>
            <div className="md:col-6 col-12">
              <label htmlFor="DueDate">Due Date</label>
              <InputText
                id="DueDate"
                name="DueDate"
                type="date"
                value={addform.DueDate}
                showIcon
                onChange={handleAddTask}
                style={{ width: "100%" }}
                className={formErrors.StartDate ? "p-invalid" : ""}
                min={addform.StartDate || ""}
              />
              {formErrors.DueDate && (
                <small className="text-red-500">{formErrors.DueDate}</small>
              )}
            </div>
            <div className="col-12">
              <label htmlFor="Details">Details</label>
              <InputTextarea
                id="Details"
                name="Details"
                value={addform.Details}
                onChange={handleAddTask}
                placeholder="Enter a task description..."
                style={{ resize: "none" }}
              />
              {formErrors.Details && (
                <small className="text-red-500">{formErrors.Details}</small>
              )}
            </div>
            <div className="col-12">
              <label htmlFor="file">Task Attachment</label>
              <input
                id="file"
                type="file"
                multiple
                onChange={onUpload}
                ref={fileInputRef}
              />
              <div className="flex">
                <div className="mt-1 ml-2">
                  <i className="fi fi-rs-file-upload"></i>
                </div>
                <p className="mb-2 ml-2">{uploadedFiles}</p>
              </div>
            </div>
          </div>
          <div>
            <h4 style={{ textTransform: "uppercase" }}>Schedule Meeting</h4>
            <div className="grid">
              <div className="md:col-6 col-12">
                <label htmlFor="TaskName">Meeting Title</label>
                <InputText
                  id="meeting_title"
                  name="meeting_title"
                  value={addform.meeting_title}
                  onChange={handleAddTask}
                  className="w-full"
                  placeholder="Meeting Title"
                />
              </div>
            </div>
            <div className="col-12" style={{ marginLeft: "-5px" }}>
              <label htmlFor="Details">Agenda</label>
              <InputTextarea
                id="agenda"
                name="agenda"
                value={addform.agenda}
                onChange={handleAddTask}
                placeholder="Agenda"
                style={{ resize: "none" }}
              />
              {formErrors.agenda && (
                <small className="text-red-500">{formErrors.agenda}</small>
              )}
            </div>
            <div className="col-12" style={{ marginLeft: "-5px" }}>
              <label htmlFor="Task">Participants</label>
              <Select
                value={participants}
                onChange={handleParticipantSelectChange}
                options={employees}
                isMulti
                isClearable
                placeholder="Select Participants"
                getOptionLabel={(option) => (
                  <div className="flex align-items-center">{`${option.label}`}</div>
                )}
              />

              {formErrors.participants && (
                <small className="text-red-500">
                  {formErrors.participants}
                </small>
              )}
            </div>
            <div className="grid">
              <div className="md:col-4 col-12">
                <label htmlFor="MeetingSheduleDate">Schedule it on</label>
                <InputText
                  type="date"
                  id="MeetingSheduleDate"
                  name="MeetingSheduleDate"
                  value={addform.MeetingSheduleDate || ""}
                  onChange={handleAddTask}
                  showIcon
                  style={{ width: "100%" }}
                  min={new Date().toISOString().split("T")[0]}
                />
              </div>

              <div className="md:col-2 col-12">
                <label htmlFor="startTime">Start Time</label>
                <input
                  type="time"
                  id="startTime"
                  name="startTime"
                  value={startTime}
                  onChange={handleStartTimeChange}
                />
              </div>
              <div className="md:col-2 col-12">
                <label htmlFor="endTime">End Time</label>
                <input
                  type="time"
                  id="endTime"
                  name="endTime"
                  value={endTime}
                  onChange={handleEndTimeChange}
                />
              </div>
              <div className="md:col-3 col-12">
                <label htmlFor="duration">Duration</label>
                <input
                  type="text"
                  id="duration"
                  name="duration"
                  value={duration}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="flex justify-content-end mt-4">
            <Button
              label="Cancel"
              onClick={handleCancel}
              className="bg-red-400"
              style={{ background: "red", border: "none" }}
            />
            <Button
              onClick={handleAddTaskSubmit}
              label={submitButtonText}
              disabled={!isFormValid}
              className="mx-2"
            />
          </div>
        </Dialog>
        {/* delete model */}
        <Dialog
          visible={displayConfirmation}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={hideConfirmationModal}
          header="Confirmation"
          footer={
            <div>
              <Button
                style={{ color: "white", background: "red" }}
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideConfirmationModal}
              />
              <Button
                style={{ color: "white", background: "#22c55e" }}
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={confirmDelete}
              />
            </div>
          }
        >
          Are you sure you want to delete this task?
        </Dialog>
      </div>
    </div>
  );
}
