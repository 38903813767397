import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';

const AdminAssetsModal = ({
    visible,
    onHide,
    onSubmit,
    selectedAsset,
    setSelectedAsset,
    selectedModel,
    setSelectedModel,
    selectedBrand,
    setSelectedBrand,
    date,
    setDate,
    description,
    setDescription,
    errors,
    assetOptions,
    brandOptions,
    modelOptions,
    editIndex,
    clearError,
    selectedEmployee,
    setSelectedEmployee,
    employeeOptions
}) => {
    const today = new Date().toISOString().split('T')[0];

    return (
        <Dialog
            header={editIndex != null ? 'Edit Request' : 'Add Request'} visible={visible} onHide={onHide}
            modal
            position="center"
            draggable={false}
            style={{ width: "90vw", maxWidth: "500px" }}
        >
            <div className="p-field">
                <label htmlFor="employee">Employee</label>
                <Dropdown
                    id="employee"
                    value={selectedEmployee}
                    options={employeeOptions}
                    onChange={(e) => {
                        setSelectedEmployee(e.value);
                        clearError('employee');
                    }}
                    placeholder="Select an Employee"
                    filter
                    showClear
                    className={errors.employee ? "p-invalid w-full" : "w-full"}
                />
                {errors.employee && <small className="p-error ml-2">{errors.employee}</small>}
            </div>
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="assetsName">Assets Name</label>
                    <Dropdown
                        id="assetsName"
                        value={selectedAsset}
                        options={assetOptions}
                        onChange={(e) => {
                            setSelectedAsset(e.value);
                            setSelectedBrand(null);
                            setSelectedModel(null);
                            clearError('asset');
                        }}
                        placeholder="Select Asset"
                        filter
                        showClear
                    />
                    {errors.asset && <small className="p-error">{errors.asset}</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="brand">Brand</label>
                    <Dropdown
                        id="brand"
                        value={selectedBrand}
                        options={brandOptions}
                        onChange={(e) => {
                            setSelectedBrand(e.value);
                            setSelectedModel(null);
                            clearError('asset');
                        }}
                        placeholder="Select Brand"
                        disabled={!selectedAsset}
                        filter
                        showClear
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="model">Model</label>
                    <Dropdown
                        id="model"
                        value={selectedModel}
                        options={modelOptions}
                        onChange={(e) => {
                            setSelectedModel(e.value);
                            clearError('asset');
                        }}
                        placeholder="Select Model"
                        disabled={!selectedBrand}
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="date">Date</label>
                    <input
                        type="date"
                        id="date"
                        value={date}
                        onChange={(e) => {
                            setDate(e.target.value);
                            clearError('date');
                        }}
                        className="p-inputtext p-component"
                        min={today}
                    />
                    {errors.date && <small className="p-error">{errors.date}</small>}
                </div>
                <div>
                    <label
                        htmlFor="description"
                        style={{ fontWeight: 600, fontSize: "15px", marginBottom: "10px" }}
                    >
                        Description
                    </label>
                    <InputTextarea
                        id="description"
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value);
                            clearError('description');
                        }}
                        style={{ marginBottom: "10px", width: "100%", height: "100px", resize: "none" }}
                    />
                    {errors.description && <small className="p-error">{errors.description}</small>}
                </div>
                <div className="flex justify-content-end mx-2 ">
                    <div className="flex justify-content-end mt-4 gap-3 align-items-center gap-1">
                        <Button icon="pi pi-times" label="Cancel" className="p-button-danger" onClick={onHide} />
                        <Button label={editIndex != null ? 'Update' : 'Submit'} icon="pi pi-check" onClick={onSubmit} />
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default AdminAssetsModal;
