import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { EMPLOYEE_API, TASK_API } from "../../../constants/api";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import RejectModel from "../AdminTask/RejectedReasonModel";

const TaskApprovals = () => {
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [taskApproval, setTaskApproval] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [statusOptions, setStatusOptions] = useState([
    { label: "Pending", value: "pending" },
    { label: "Approved", value: "approved" },
    { label: "Rejected", value: "rejected" },
  ]);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.All_USERS);
      const { usersData } = response.data;
      setEmployees(usersData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchTaskData = async () => {
      try {
        const userString = localStorage.getItem("user");
        const user = JSON.parse(userString);

        if (!user || !user.data || !user.data.id) {
          console.error(
            "User object is null, undefined, or does not contain an id property."
          );
          return;
        }

        const { data } = await axios.get(TASK_API.GET_ALL_TASK);

        const filterApproval = data.filter(
          (item) => item.Approver == user.data.id
        );
        setTaskApproval(filterApproval);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    fetchTaskData();
    fetchEmployees();
  }, []);

  const showToast = (severity, detail) => {
    toast.current.show({ severity, detail });
  };

  const getStatusColor = (rowData) => {
    switch (rowData.approval_status) {
      case "Approved":
        return {
          borderColor: "#B7EB8F",
          backgroundColor: "#F6FFED",
          color: "#389E0D",
        };
      case "Rejected":
        return {
          borderColor: "#FF3D32",
          backgroundColor: "#FAFAFA",
          color: "#FF3D32",
        };
      case "Pending":
        return {
          borderColor: "#0d6efd",
          backgroundColor: "#0d6efd26",
          color: "#0d6efd",
        };
      case null:
        return {
          borderColor: "#0d6efd",
          backgroundColor: "#0d6efd26",
          color: "#0d6efd",
        };
      default:
        return {
          borderColor: "#fd7e14",
          backgroundColor: "#fd7e1426",
          color: "#fd7e14",
        };
    }
  };

  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [dialogApproveVisible, setDialogApproveVisible] = useState(false);

  const handleApprove = async (rowData) => {
    try {
      const userString = localStorage.getItem("user");
      const user = JSON.parse(userString);

      if (!user || !user.data || !user.data.id) {
        console.error(
          "User object is null, undefined, or does not contain an id property."
        );
        return;
      }
      const response = await axios.put(
        TASK_API.TASK_APPROVAL_ADMIN_APPROVAL(rowData.Task_id),
        { approval_status: "Approved" }
      );
      const { data } = await axios.get(TASK_API.GET_ALL_TASK);
      const filteredTasks = data.filter(
        (item) => item.Approver === user.data.id
      );
      setTaskApproval(filteredTasks);
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };

  const handleReject = async (approvalStatus, description, taskId) => {
    try {
      const userString = localStorage.getItem("user");
      const user = JSON.parse(userString);

      if (!user || !user.data || !user.data.id) {
        console.error(
          "User object is null, undefined, or does not contain an id property."
        );
        return;
      }
      const response = await axios.put(
        TASK_API.TASK_APPROVAL_ADMIN_REJECTION(taskId),
        { approval_status: approvalStatus, task_rejection_reason: description }
      );
      const { data } = await axios.get(TASK_API.GET_ALL_TASK);
      const filteredTasks = data.filter(
        (item) => item.Approver === user.data.id
      );
      setTaskApproval(filteredTasks);
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };
  const actionTemplate = (rowData) => {
    return (
      <div className="flex align-items-center">
        {rowData.approval_status !== "Approved" && (
          <Button
            tooltip="Approve"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
            className="icon-button"
            icon="pi pi-check"
            style={{
              border: "none",
              background: "#22c55e",
              borderRadius: "10px",
              padding: "10px",
              fontSize: "10px",
              color: "#fff",
            }}
            onClick={() => handleApprove(rowData)}
          />
        )}
        <Button
          tooltip="Reject"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          className="icon-button ml-2"
          icon="pi pi-times"
          style={{
            border: "none",
            background: "#ff3d32",
            borderRadius: "10px",
            padding: "10px",
            fontSize: "10px",
            color: "#fff",
          }}
          onClick={() => {
            setSelectedTask(rowData);
            setDialogVisible(true);
          }}
        />
      </div>
    );
  };

  return (
    <div className="">
      <Toast ref={toast} />
      <div className="md:flex justify-content-between">
        <div className="ml-2">
          <h2>Task Approvals</h2>
        </div>

        <div className="md:col-4 lg:col-2 col-12 ">
          <div className="p-inputgroup">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>

      <DataTable
        value={taskApproval}
        scrollable
        className=""
        paginator
        rows={10}
        globalFilter={globalFilter.trim() ? globalFilter : null}
      >
        <Column
          field="Task_id"
          header="Task Id"
          bodyStyle={{ width: "70px" }}
        ></Column>
        <Column
          field="Creater"
          header="Creater"
          body={(rowData) => {
            const creatorId = rowData?.Creater;
            const creator = employees.find((emp) => emp.id === creatorId);
            return `${creator?.firstname} ${creator?.lastname}`;
          }}
        ></Column>
        <Column
          field="Processer"
          header="Processer"
          body={(rowData) => {
            const teamMemberIds = rowData.Processer.split(",");
            const processer = teamMemberIds
              .map((id) => employees.find((emp) => emp.id === id.trim()))
              .filter((emp) => emp !== undefined)
              .map((emp) => `${emp.firstname} ${emp.lastname}`)
              .join(", ");
            return (
              <div
                id={`Processer_${rowData.Task_id}`}
                className="tooltip-container"
              >
                {processer?.length > 10
                  ? `${processer.slice(0, 10)}...`
                  : processer}
                <Tooltip
                  target={`#Processer_${rowData.Task_id}`}
                  position="right"
                >
                  {processer}
                </Tooltip>
              </div>
            );
          }}
          style={{
            width: "180px",
            textTransform: "capitalize",
          }}
        ></Column>
        <Column
          field="Project"
          header="Project"
          style={{ minWidth: "105px" }}
        ></Column>
        <Column
          field="task_name"
          header="Task Name"
          style={{ cursor: "pointer" }}
          body={(rowData) => (
            <div
              id={`task_name${rowData.Task_id}`}
              className="tooltip-container"
            >
              {rowData?.task_name?.length > 20
                ? `${rowData?.task_name.slice(0, 20)}...`
                : rowData?.task_name}
              <Tooltip
                target={`#task_name${rowData.Task_id}`}
                position="right"
                style={{ width: "300px" }}
              >
                {rowData?.task_name}
              </Tooltip>
            </div>
          )}
        ></Column>
        <Column
          field="Details"
          header="Task Details"
          style={{ cursor: "pointer" }}
          body={(rowData) => (
            <div id={`Details${rowData.Task_id}`} className="tooltip-container">
              {rowData?.Details?.length > 20
                ? `${rowData?.Details.slice(0, 20)}...`
                : rowData?.Details}
              <Tooltip
                target={`#Details${rowData.Task_id}`}
                position="right"
                style={{ width: "300px" }}
              >
                {rowData?.Details}
              </Tooltip>
            </div>
          )}
        ></Column>
        <Column
          field="StartDate"
          header="Started"
          style={{ minWidth: "130px" }}
          body={(rowData) => moment(rowData.StartDate).format("DD-MM-YYYY")}
        ></Column>
        <Column
          field="DueDate"
          header="Due"
          style={{ minWidth: "130px" }}
          body={(rowData) => moment(rowData.DueDate).format("DD-MM-YYYY")}
        ></Column>
        <Column
          field="approval_status"
          header="Approval Status"
          style={{ minWidth: "100px", cursor: "pointer" }}
          body={(rowData) => (
            <div>
              <span
                id={`approval_status_${rowData.Task_id}`}
                style={{
                  border: "2px solid",
                  ...getStatusColor(rowData),
                  padding: "6px 12px",
                  borderRadius: "4px",
                }}
              >
                {rowData?.approval_status === null
                  ? "Pending"
                  : rowData.approval_status}
              </span>
              {rowData.approval_status !== "Approved" &&
                rowData.task_rejection_reason && (
                  <Tooltip
                    target={`#approval_status_${rowData.Task_id}`}
                    position="top"
                  >
                    <div>
                      <strong>Rejection Reason:</strong>
                      <p>{rowData.task_rejection_reason}</p>
                    </div>
                  </Tooltip>
                )}
            </div>
          )}
        />
        <Column
          header="Approval Actions"
          body={actionTemplate}
          style={{ minWidth: "90px" }}
        ></Column>
      </DataTable>
      <RejectModel
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        handleReject={handleReject}
        setSelectedTask={setSelectedTask}
        selectedTask={selectedTask}
      />
    </div>
  );
};

export default TaskApprovals;
