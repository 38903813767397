import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import axios from "axios";
import { ASSETS_IMAGES_API, ATTENDANCE_API } from "../../../../constants/api";
import { Badge } from "primereact/badge";
import moment from "moment/moment";
import { Image } from "primereact/image";
import AttendanceTime from "../../../Attendence/AttendanceTime";


export default function Attendance({ userId }) {
  const toastRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [timeDetails, setTimeDetails] = useState({})
  const getCurrentDate = new Date();
  const currentMonthSelected = getCurrentDate.toISOString().slice(0, 7);
  const [searchDate, setSearchDate] = useState(currentMonthSelected);
  const [userAttendance, setUserAttendance] = useState([])
  const [count, setCount] = useState({
    presentCount: 0,
    absentCount: 0,
    weekOffCount: 0,
    optionalLeave: 0,
    holidayCount: 0,
    workingDaysCount: 0,
    overTimeCount: 0
  })

  const fetchDataMonth = async () => {

    const [year, month] = searchDate.split("-")
    axios
      .get(ATTENDANCE_API.ATTENDANCE_GET_MONTH(userId, month, year))
      .then(({ data }) => {
        setUserAttendance(data.attendance)
        setTimeDetails(data.time)
        setCount({
          presentCount: data.counts.present,
          absentCount: data.counts.absent,
          weekOffCount: data.counts.weekoff,
          optionalLeave: data.counts.optionalLeave,
          holidayCount: data.counts.holiday,
          workingDaysCount: data.counts.workingDays,
          overTimeCount: data.counts.overtime,

        })
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  }
  useEffect(() => { fetchDataMonth() }, [searchDate])

  useEffect(() => {
    fetchAttendanceData();
  }, []);

  const fetchAttendanceData = () => {
    const userDataString = localStorage.getItem("user");

    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const userid = userData.data.id;

      axios
        .get(ATTENDANCE_API.ATTENDANCE_GET(userid))
        .then((response) => {
          setProducts(response.data);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });
    } else {
      console.error("User data not found in local storage");
    }
  };

  const onRowEditComplete = (e) => {
    let _products = [...products];
    let { newData, index } = e;

    _products[index] = newData;

    setProducts(_products);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  return (
    <div>
      <div className="card">
        <div className="md:flex block justify-content-between">
          <h2 className="mt-3">Attendance</h2>
          <Toast ref={toastRef} />
          <div className="" style={{ margin: "8px 0" }}>
            <div>
              <label htmlFor="monthPicker">
                <input
                  id="monthPicker"
                  type="month"
                  value={searchDate}
                  onChange={(e) => setSearchDate(e.target.value)}
                  style={{
                    boxShadow: "1px 1px 4px gray",
                    outline: "none",
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                    appearance: "none",
                  }}
                  showButtonBar={false}
                />
              </label>
            </div>
          </div>
        </div>  
        {timeDetails && <div className="">
          <AttendanceTime time={timeDetails} />
        </div>}
        <div className="" style={{ margin: "10px 0px" }}>
          <div
            className="permision-card md:flex  block"
            style={{ flexWrap: "wrap", gap: "10px" }}
          >
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={count.presentCount}
                severity="success"
                size="large"
              />
              <div className="permi-text">
                <h3>Present</h3>
              </div>
            </div>
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={count.absentCount}
                severity="danger"
                size="large"
              />
              <div className="permi-text">
                <h3>Absent</h3>
              </div>
            </div>
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={count.overTimeCount}
                className="bg-purple-500"
                size="large"
              />
              <div className="permi-text">
                <h3>Overtime</h3>
              </div>
            </div>
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={count.optionalLeave}
                severity="secondary"
                size="large"
              />
              <div className="permi-text">
                <h3>Optional Leave</h3>
              </div>
            </div>
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={count.weekOffCount}
                severity="warning"
                size="large"
              />
              <div className="permi-text">
                <h3>Weekoff</h3>
              </div>
            </div>
            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge
                value={count.holidayCount}
                severity="info"
                size="large"
              />
              <div className="permi-text">
                <h3>Holidays</h3>
              </div>
            </div>

            <div className="card md:mt-0 mt-3" style={{ flexGrow: "1" }}>
              <Badge value={count.workingDaysCount} size="large" />
              <div className="permi-text">
                <h3>Working days</h3>
              </div>
            </div>
          </div>
        </div>

        <DataTable
          stripedRows
          value={userAttendance}
          editMode="row"
          dataKey="id"
          selectionMode="single"
          selection={selectedRow}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          onRowEditComplete={onRowEditComplete}
          scrollable
          scrollHeight="600px"
          className="mt-3"
          paginator rows={10} rowsPerPageOptions={[10, 25, 50]}
          globalFilter={searchDate}
          emptyMessage="No records found"
        >
          <Column
            field="date"
            header="Date"
            style={{ minWidth: "105px" }}
            className="font-bold"
            headerStyle={{
              borderTopLeftRadius: "14px",
              borderBottomLeftRadius: "14px",
            }}
            body={(rowData) => moment(rowData.date).format("DD-MM-yyyy")}
          ></Column>
          <Column
            field="check_in"
            header="Check-In"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "120px" }}
          ></Column>
          <Column
            field="check_out"
            header="Check-Out"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "120px" }}
            body={(rowData) => (rowData.check_out ? rowData.check_out : "-")}
          ></Column>

          <Column
            field="workinghours"
            header="Working Hours"
            style={{ minWidth: "200px" }}
            body={(rowData) =>
              rowData.workinghours ? rowData.workinghours : "-"
            }
          ></Column>
          <Column
            field="status"
            header="Status"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "100px" }}
          ></Column>
          <Column
            field="over_time"
            header="OT"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "200px" }}
            body={(rowData) =>
              rowData.over_time ? rowData.over_time : "-"
            }
          ></Column>
          <Column
            field="remaining_time"
            header="Remaining Time"
            editor={(options) => textEditor(options)}
            style={{ minWidth: "200px" }}
            body={(rowData) =>
              rowData.remaining_time ? rowData.remaining_time : "-"
            }
          ></Column>
          <Column
            header="CII"
            body={(rowData) => {
              return rowData?.check_in_img !== null ?
                <>
                  <Image src={`${ASSETS_IMAGES_API.ASSETS_IMAGES}${rowData.check_in_img}`} alt="EmployeeImage" width="50" preview />
                </>
                : "-"
            }}
            style={{ width: "70px", textAlign: "center" }}
          />
          <Column
            header="COI"
            body={(rowData) => {
              return rowData.check_out_img !== null ?
                <Image src={`${ASSETS_IMAGES_API.ASSETS_IMAGES}${rowData.check_out_img}`} alt="EmployeeImage" width="50" preview />
                : "-"
            }}
            style={{ width: "50px", textAlign: "center" }}
          />
        </DataTable>

      </div>
    </div>
  );
}
