import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { PERMISSION_API } from "../../constants/api";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

export default function PermissionApprovals() {
  const toast = useRef(null);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [editedData, setEditedData] = useState({});
  const [no_of_hours, setno_of_hours] = useState(0);
  const [statusOptions, setStatusOptions] = useState([
    { label: "Pending", value: "Pending" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
  ]);
  const showToast = (severity, detail) => {
    toast.current.show({ severity, detail });
  };

  const fetchLeaveRequests = async () => {
    try {
      const response = await axios.get(PERMISSION_API.PERMISSION_GET);
      if (response.status === 200) {
        const data = response.data;
        const sortedLeaveRequests = data.permissionData.sort((a, b) => {
          if (a.status === "Pending" && b.status !== "Pending") return -1;
          if (a.status !== "Pending" && b.status === "Pending") return 1;
          return 0;
        });

        setLeaveRequests(sortedLeaveRequests);
      } else {
        console.error("Error fetching data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    if (selectedLeaveRequest?.from_time && selectedLeaveRequest?.to_time) {
      const from_time = new Date(selectedLeaveRequest.from_time);
      const to_time = new Date(selectedLeaveRequest.to_time);
      const timeDifference = to_time.getTime() - from_time.getTime();
      const calculatedno_of_hours = Math.ceil(
        timeDifference / (1000 * 3600 * 24) + 1
      );
      setno_of_hours(calculatedno_of_hours || 1);
    }
  }, [selectedLeaveRequest?.from_time, selectedLeaveRequest?.to_time]);

  const calculateno_of_hoursForEdit = (from_time, to_time) => {
    if (from_time === to_time) {
      setEditedData((prevData) => ({ ...prevData, no_of_hours: 0 }));
    } else {
      const from = new Date(`2022-01-01T${from_time}`);
      const to = new Date(`2022-01-01T${to_time}`);

      const timeDiff = Math.abs(to - from);
      const diffHours = Math.ceil(timeDiff / (1000 * 60 * 60));

      setEditedData((prevData) => ({ ...prevData, no_of_hours: diffHours }));
    }
  };

  const handleFromTimeChange = (e) => {
    const newFromTime = e.target.value;
    setEditedData((prevData) => ({
      ...prevData,
      from_time: newFromTime,
    }));
    calculateno_of_hoursForEdit(newFromTime, editedData.to_time);
  };

  const handleToTimeChange = (e) => {
    const newToTime = e.target.value;
    setEditedData((prevData) => ({
      ...prevData,
      to_time: newToTime,
    }));
    calculateno_of_hoursForEdit(editedData.from_time, newToTime);
  };

  useEffect(() => {
    fetchLeaveRequests();
  }, []);
  const handleEdit = (rowData) => {
    setSelectedLeaveRequest(rowData);
    setEditedData({
      date: rowData.date,
      from_time: rowData.from_time,
      to_time: rowData.to_time,
      no_of_hours: rowData.no_of_hours,
      reason: rowData.reason,
      status: rowData.status,
    });
    setShowEditModal(true);
  };

  const handleSave = async () => {
    try {
      const response = await axios.put(
        PERMISSION_API.PERMISSION_ADMIN_UPDATE(selectedLeaveRequest.id),
        {
          date: editedData.date,
          from_time: editedData.from_time,
          to_time: editedData.to_time,
          no_of_hours: editedData.no_of_hours,
          reason: editedData.reason,
          status: editedData.status,
        }
      );

      if (response.status === 200) {
        const updatedLeaveRequests = leaveRequests.map((request) =>
          request.id === selectedLeaveRequest.id
            ? { ...request, ...editedData }
            : request
        );
        setLeaveRequests(updatedLeaveRequests);
        setShowEditModal(false);
        fetchLeaveRequests();
        showToast("success", "Permission request edited successfully");
      } else {
        console.error(
          "Error updating permission request:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error updating permission request:", error.message);
    }
  };

  const getStatusColor = (rowData) => {
    switch (rowData.status) {
      case "Pending":
        return "#4AA9F5";
      case "Approved":
        return "#4caf6c";
      case "Rejected":
        return "#F44C56";
      default:
        return "#ffbf00";
    }
  };

  const handleApprove = async (rowData) => {
    try {
      const response = await axios.put(
        PERMISSION_API.PERMISSION_APPROVAL(rowData.id),
        { status: "Approved" }
      );

      if (response.status === 200) {
        const { PermissionData } = response.data;
        if (PermissionData) {
          const { userid } = PermissionData;
          rowData.status = "Approved";
          setLeaveRequests([...leaveRequests]);
          const leaveBalanceResponse = await axios.put(
            PERMISSION_API.PERMISSION_BALANCE(userid)
          );
          fetchLeaveRequests();
        } else {
          console.error("Permission data not found in response");
        }
      } else {
        console.error("Error updating status:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };

  const handleReject = async (rowData) => {
    try {
      const response = await axios.put(
        PERMISSION_API.PERMISSION_APPROVAL(rowData.id),
        { status: "Rejected" }
      );

      if (response.status === 200) {
        rowData.status = "Rejected";
        setLeaveRequests([...leaveRequests]);
        fetchLeaveRequests();
      } else {
        console.error("Error updating status:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };
  const actionTemplate = (rowData) => {
    const isApproved = rowData.status === "Approved";
    const isRejected = rowData.status === "Rejected";

    return (
      <div className="flex align-items-center">
        {!isApproved && !isRejected ? (
          <>
            <Button
              tooltip="Approve"
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
              }}
              className="icon-button"
              icon="pi pi-check"
              style={{
                border: "none",
                background: "#22c55e",
                borderRadius: "10px",
                padding: "10px",
                fontSize: "10px",
                color: "#fff",
              }}
              onClick={() => handleApprove(rowData)}
            />
            <Button
              tooltip="Reject"
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
              }}
              className="icon-button ml-2"
              icon="pi pi-times"
              style={{
                border: "none",
                background: "#ff3d32",
                borderRadius: "10px",
                padding: "10px",
                fontSize: "10px",
                color: "#fff",
              }}
              onClick={() => handleReject(rowData)}
            />
          </>
        ) : null}
        <Button
          tooltip="Edit"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          className="icon-button ml-2"
          icon="pi pi-pencil"
          style={{
            border: "none",
            background: "rgb(22,119,255)",
            borderRadius: "10px",
            padding: "10px",
            fontSize: "10px",
            color: "#fff",
          }}
          onClick={() => handleEdit(rowData)}
        />
      </div>
    );
  };

  return (
    <div className="AllleaveReq card">
      <Toast ref={toast} />
      <div className="md:flex block justify-content-between">
        <h2 className="my-3">Permission Requests</h2>
        <div className="md:col-4 lg:col-2 col-12">
          <div className="p-inputgroup flex-1">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <DataTable
        value={leaveRequests}
        scrollable
        className=""
        paginator
        rows={6}
        globalFilter={globalFilter.trim() ? globalFilter : null}
      >
        <Column
          field="Employeename"
          header="Name"
          style={{ minWidth: "100px" }}
          frozen
          className="font-bold"
          body={(rowData) => (
            <span>{`${rowData.firstname} ${rowData.lastname}`}</span>
          )}
        ></Column>
        <Column
          field="userid"
          header="Id"
          bodyStyle={{ minWidth: "100px" }}
        ></Column>
        <Column
          field="date"
          header="Date"
          style={{ minWidth: "105px" }}
          body={(rowData) => moment(rowData.date).format("DD-MM-YYYY")}
        ></Column>
        {/* <Column
          field="from_time"
          header="From Time"
          style={{ minWidth: "105px" }}
        ></Column>
        <Column
          field="to_time"
          header="To Time"
          style={{ minWidth: "105px" }}
        ></Column> */}
        <Column
          field="no_of_hours"
          header="No Of Hours"
          style={{ minWidth: "50px" }}
        ></Column>
        <Column
          field="annual_permissions"
          header="Annual Permissions"
          style={{ minWidth: "90px" }}
        ></Column>
        <Column
          field="permission_taken"
          header="Taken Permissions"
          style={{ minWidth: "90px" }}
        ></Column>
        <Column
          field="balance_permissions"
          header="Balance Permissions"
          style={{ minWidth: "90px" }}
        ></Column>
        <Column
          field="status"
          header="Status"
          style={{ minWidth: "80px" }}
          body={(rowData) => (
            <span
              style={{
                backgroundColor: getStatusColor(rowData),
                padding: "6px 12px",
                borderRadius: "4px",
                color: "#fff",
              }}
            >
              {rowData.status.charAt(0).toUpperCase() +
                rowData.status.slice(1).toLowerCase()}
            </span>
          )}
        />
        <Column
          field="reason"
          header="Reason"
          body={(rowData) => (
            <div
              id={`reason_${rowData.leaveRequestId}`}
              className="tooltip-container"
            >
              {rowData.reason.length > 20
                ? `${rowData.reason.slice(0, 20)}...`
                : rowData.reason}
              <Tooltip
                target={`#reason_${rowData.leaveRequestId}`}
                position="left"
                style={{ width: "300px" }}
              >
                {rowData.reason}
              </Tooltip>
            </div>
          )}
        />
        <Column
          field="action"
          header="Actions"
          body={actionTemplate}
          style={{ minWidth: "90px" }}
        ></Column>
      </DataTable>
      {showEditModal && (
        <Dialog
          visible={showEditModal}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={() => setShowEditModal(false)}
          header="Edit Permission"
        >
          <div>
            <label htmlFor="fromTime">Date</label>
            <input
              type="date"
              id="fromTime"
              value={editedData.date || ""}
              onChange={(e) =>
                setEditedData({ ...editedData, date: e.target.value })
              }
            />
          </div>
          <div>
            <label htmlFor="fromTime">From Time</label>
            <input
              type="time"
              id="fromTime"
              value={editedData.from_time || ""}
              onChange={handleFromTimeChange}
              min="00:00"
              max="23:59"
            />
          </div>
          <div>
            <label htmlFor="toTime">To Time</label>
            <input
              type="time"
              id="toTime"
              value={editedData.to_time || ""}
              onChange={handleToTimeChange}
              min="00:00"
              max="23:59"
            />
          </div>
          <div>
            <label htmlFor="no_of_hours">No Of Hours</label>
            <input
              type="number"
              id="no_of_hours"
              value={editedData.no_of_hours || "0"}
              onChange={(e) =>
                setEditedData({ ...editedData, no_of_hours: e.target.value })
              }
              disabled
            />
          </div>

          <div>
            <label
              htmlFor="editStatus"
              style={{
                fontWeight: 600,
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              Status
            </label>
            <Dropdown
              id="editStatus"
              options={statusOptions}
              value={editedData?.status || ""}
              onChange={(e) =>
                setEditedData({
                  ...editedData,
                  status: e.value,
                })
              }
              placeholder="Select Status"
              style={{ marginBottom: "10px", fontWeight: 600, width: "100%" }}
            />
          </div>
          <div>
            <label htmlFor="reason">Reason</label>
            <TextArea
              id="reason"
              className="w-full"
              value={editedData.reason || ""}
              onChange={(e) =>
                setEditedData({ ...editedData, reason: e.target.value })
              }
            />
          </div>
          <div className="flex justify-content-end mt-4">
            <Button
              label="Cancel"
              className="p-button-danger"
              onClick={() => setShowEditModal(false)}
            />
            <Button label="Submit" className="mx-2" onClick={handleSave} />
          </div>
        </Dialog>
      )}
    </div>
  );
}
