import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import axios from "axios";
import { PERMISSION_API } from "../../constants/api";
import TextArea from "antd/es/input/TextArea";
import { Tooltip } from "primereact/tooltip";
import { FaBook } from "react-icons/fa";
import { FaBookBookmark } from "react-icons/fa6";
import { GiPalmTree } from "react-icons/gi";
import moment from "moment/moment";

export default function MyPermissions() {
  const toast = useRef(null);
  const [products, setProducts] = useState([]);
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [remainingAnnualLeave, setRemainingAnnualLeave] = useState([]);
  const [editedData, setEditedData] = useState({
    date: "",
    from_time: "",
    to_time: "",
    no_of_hours: 0,
    reason: "",
    status: "",
  });
  const [newLeaveData, setNewLeaveData] = useState({});
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState(null);

  const showConfirmationModal = (rowData) => {
    setSelectedRowToDelete(rowData);
    setDisplayConfirmation(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmation(false);
    setSelectedRowToDelete(null);
  };

  const confirmDelete = () => {
    if (selectedRowToDelete) {
      deleteRow(selectedRowToDelete);
      hideConfirmationModal();
    }
  };

  const calculateno_of_hoursForNewLeave = (from_time, to_time) => {
    if (from_time === to_time) {
      setNewLeaveData((prevData) => ({ ...prevData, no_of_hours: 0 }));
    } else {
      const from = new Date(`2022-01-01T${from_time}`);
      const to = new Date(`2022-01-01T${to_time}`);

      const timeDiff = Math.abs(to - from);
      const diffHours = Math.ceil(timeDiff / (1000 * 60 * 60));

      setNewLeaveData((prevData) => ({ ...prevData, no_of_hours: diffHours }));
    }
  };

  const showToast = (severity, detail) => {
    toast.current.clear();
    toast.current.show({ severity, detail, life: 3000 });
  };

  useEffect(() => {
    fetchPermissionData();
  }, []);

  const openEditModal = (rowData, rowIndex) => {
    const { id, date, from_time, to_time, no_of_hours, reason, status } =
      rowData;
    setEditedData({
      id,
      date,
      from_time,
      to_time,
      no_of_hours,
      reason,
      status,
    });
    calculateno_of_hoursForEdit(from_time, to_time);
    setEditIndex(rowIndex);
    setDisplayEditModal(true);
  };

  const calculateno_of_hoursForEdit = (from_time, to_time) => {
    if (from_time === to_time) {
      setEditedData((prevData) => ({ ...prevData, no_of_hours: 0 }));
    } else {
      const from = new Date(`2022-01-01T${from_time}`);
      const to = new Date(`2022-01-01T${to_time}`);

      const timeDiff = Math.abs(to - from);
      const diffHours = Math.ceil(timeDiff / (1000 * 60 * 60));

      setEditedData((prevData) => ({ ...prevData, no_of_hours: diffHours }));
    }
  };

  const handleFromTimeChange = (e) => {
    const newFromTime = e.target.value;
    setEditedData((prevData) => ({
      ...prevData,
      from_time: newFromTime,
    }));
    calculateno_of_hoursForEdit(newFromTime, editedData.to_time);
  };

  const handleToTimeChange = (e) => {
    const newToTime = e.target.value;
    setEditedData((prevData) => ({
      ...prevData,
      to_time: newToTime,
    }));
    calculateno_of_hoursForEdit(editedData.from_time, newToTime);
  };

  const openAddModal = () => {
    setDisplayAddModal(true);
  };

  const fetchPermissionData = () => {
    const userDataString = localStorage.getItem("user");

    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const userId = userData.data.id;

      axios
        .get(PERMISSION_API.PERMISSION_GET_BYID(userId))
        .then((response) => {
          setProducts(response.data.permissions);
          setRemainingAnnualLeave(response.data);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });
    } else {
      console.error("User data not found in local storage");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userDataString = localStorage.getItem("user");
    if (
      !newLeaveData.from_time ||
      !newLeaveData.to_time ||
      !newLeaveData.reason
    ) {
      setDisplayAddModal(true);
      showToast("error", "Please enter all the fields.");
      return;
    }

    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const userId = userData.data.id;
      const requestData = {
        userid: userId,
        date: newLeaveData.date,
        from_time: newLeaveData.from_time,
        to_time: newLeaveData.to_time,
        no_of_hours:
          newLeaveData.from_time === newLeaveData.to_time
            ? 0
            : newLeaveData.no_of_hours,
        reason: newLeaveData.reason,
        status: "pending",
      };

      axios
        .post(PERMISSION_API.PERMISSION_ADD, requestData)
        .then((response) => {
          fetchPermissionData();
          showToast("success", "Permission request added successfully");
          setDisplayAddModal(false);
          setNewLeaveData({
            date: "",
            from_time: "",
            to_time: "",
            no_of_hours: 0,
            reason: "",
          });
        })
        .catch((error) => {
          console.error("Error submitting leave application:", error.message);
          const errorMessage =
            error.response?.data?.message || "Error adding leave request";
          showToast("error", errorMessage);
          setDisplayAddModal(true);
        });
    } else {
      console.error("User data not found in local storage");
    }
  };

  const saveChanges = async () => {
    if (editIndex !== null) {
      const requiredFields = ["date", "from_time", "to_time", "reason"];
      if (requiredFields.some((field) => !editedData[field])) {
        setDisplayEditModal(true);
        showToast("error", "Please enter all the fields.");
        return;
      }
      try {
        const { id, ...updatedData } = editedData;
        await axios.put(PERMISSION_API.PERMISSION_UPDATE(id), updatedData);
        setProducts((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts[editIndex] = editedData;
          return updatedProducts;
        });

        setDisplayEditModal(false);
        showToast("success", "Leave request updated successfully");
        fetchPermissionData();
      } catch (error) {
        console.error("Error updating leave application:", error);
        showToast(
          "error",
          "Error updating leave request: " + error.response.data.message
        );
      }
    }
  };

  const deleteRow = async (rowData) => {
    const { id } = rowData;

    try {
      await axios.delete(PERMISSION_API.PERMISSION_DELETE(id));
      const updatedProducts = products.filter((product) => product.id !== id);
      setProducts(updatedProducts);
      showToast("success", "Leave request deleted successfully");
      fetchPermissionData();
    } catch (error) {
      console.error("Error deleting leave application:", error);
      showToast("error", "Error deleting leave request");
    }
  };

  const actionTemplate = (rowData, rowIndex) => {
    if (rowData.status === "Approved" || rowData.status === "Rejected") {
      return null;
    }
    return (
      <div className="flex items-center">
        <Button
          tooltip="Edit"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-pencil"
          className="p-button-rounded p-mr-2"
          onClick={() => openEditModal(rowData, rowIndex)}
          style={{ marginRight: "10px", background: "rgb(22,119,255)" }}
        />
        <Button
          tooltip="Delete"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => showConfirmationModal(rowData)}
        />
      </div>
    );
  };

  const statusTemplate = (rowData) => {
    let color, label;

    switch (rowData.status) {
      case "Pending":
        color = "#FEFAD3";
        label = "Pending";
        break;
      case "Approved":
        color = "#C6EFCD";
        label = "Approved";
        break;
      case "Rejected":
        color = "#FFC8CE";
        label = "Rejected";
        break;
      default:
        color = "gray";
        label = "Unknown";
    }

    return (
      <span
        style={{
          backgroundColor: color,
          color: "black",
          padding: "5px",
          borderRadius: "5px",
        }}
      >
        {label}
      </span>
    );
  };

  const today = new Date().toISOString().split("T")[0];
  return (
    <div>
      <div className="flex justify-content-between mb-2">
        <div className=""></div>
        <div className="">
          <div className="p-inputgroup">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div className="permision-card md:flex block">
        <div className="card md:mt-0 mt-3">
          <div
            className="img-permi"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              fontSize: "2rem",
              background: "#F7F1FD",
              padding: "7px 7px",
              borderRadius: "10px",
              color: "#9B51E0",
            }}
          >
            <FaBook />
          </div>
          <div className="permi-text">
            <p>{remainingAnnualLeave.permission_taken || 0}</p>
            <h3>Permissions Taken</h3>
          </div>
        </div>
        <div className="card md:mt-0 mt-3">
          <div
            className="img-permi"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              fontSize: "2rem",
              background: "#EFF5FE",
              padding: "7px 7px",
              borderRadius: "10px",
              color: "#2F80ED",
            }}
          >
            <GiPalmTree />
          </div>
          <div className="permi-text">
            <p>{remainingAnnualLeave.balance_permissions || 0}</p>
            <h3>Balance Permission</h3>
          </div>
        </div>
        <div className="card md:mt-0 mt-3">
          <div
            className="img-permi"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              fontSize: "2rem",
              background: "#EEF7F2",
              padding: "7px 7px",
              color: "#219653",
              borderRadius: "10px",
            }}
          >
            <FaBookBookmark />
          </div>
          <div className="permi-text">
            <p>{remainingAnnualLeave.annual_permissions || 0}</p>
            <h3>Annual Permissions</h3>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <Toast ref={toast} />
        <div className="flex justify-content-end">
          <i
            class="fi fi-sr-square-plus"
            onClick={openAddModal}
            style={{
              color: "#6366F1",
              fontSize: "40px",
              marginRight: "9px",
            }}
          ></i>
        </div>

        <DataTable
          value={Array.isArray(products) ? products : []}
          paginator
          rows={5}
          globalFilter={globalFilter.trim() ? globalFilter : null}
        >
          <Column
            field="date"
            header="Date"
            body={(rowData) => moment(rowData.date).format("DD-MM-YYYY")}
            style={{ minWidth: "105px" }}
          />
          <Column
            field="no_of_hours"
            header="No Of Hours"
            style={{ minWidth: "120px" }}
          />
          <Column
            field="reason"
            header="Reason"
            body={(rowData) => (
              <div id={`reason_${rowData.id}`} className="tooltip-container">
                {rowData.reason.length > 20
                  ? `${rowData.reason.slice(0, 20)}...`
                  : rowData.reason}
                <Tooltip target={`#reason_${rowData.id}`} position="right">
                  {rowData.reason}
                </Tooltip>
              </div>
            )}
          />
          <Column
            field="status"
            header="Status"
            body={statusTemplate}
            style={{ minWidth: "100px" }}
          />
          <Column
            field="Action"
            header="Actions"
            body={actionTemplate}
            style={{ minWidth: "100px" }}
          />
        </DataTable>

        {/* Add Modal */}
        <Dialog
          visible={displayAddModal}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={() => setDisplayAddModal(false)}
          header="Add Permission"
        >
          <div>
            <label htmlFor="fromTime">Date</label>
            <input
              type="date"
              id="fromTime"
              value={newLeaveData?.date || ""}
              onChange={(e) => {
                setNewLeaveData({ ...newLeaveData, date: e.target.value });
              }}
              min={today}
            />
          </div>
          <div>
            <label htmlFor="fromTime">From Time</label>
            <input
              type="time"
              id="fromTime"
              value={newLeaveData?.from_time || ""}
              onChange={(e) => {
                setNewLeaveData({ ...newLeaveData, from_time: e.target.value });
                calculateno_of_hoursForNewLeave(
                  e.target.value,
                  newLeaveData.to_time
                );
              }}
              min="00:00"
              max="23:59"
            />
          </div>
          <div>
            <label htmlFor="toTime">To Time</label>
            <input
              type="time"
              id="toTime"
              value={newLeaveData?.to_time || ""}
              onChange={(e) => {
                setNewLeaveData({ ...newLeaveData, to_time: e.target.value });
                calculateno_of_hoursForNewLeave(
                  newLeaveData.from_time,
                  e.target.value
                );
              }}
              min="00:00"
              max="23:59"
            />
          </div>
          <div>
            <label htmlFor="no_of_hours">No Of Hours</label>
            <input
              type="number"
              id="no_of_hours"
              value={newLeaveData?.no_of_hours || "0"}
              onChange={(e) =>
                setNewLeaveData({
                  ...newLeaveData,
                  no_of_hours: e.target.value,
                })
              }
              disabled
            />
          </div>
          <div>
            <label htmlFor="reason">Reason</label>
            <TextArea
              type="text"
              id="reason"
              className="w-full"
              value={newLeaveData?.reason || ""}
              onChange={(e) =>
                setNewLeaveData({ ...newLeaveData, reason: e.target.value })
              }
            />
          </div>

          <div className="flex justify-content-end mt-4">
            <Button
              label="Cancel"
              className="p-button-danger"
              onClick={() => {
                setDisplayAddModal(false);
                setNewLeaveData({
                  date: "",
                  from_time: "",
                  to_time: "",
                  no_of_hours: 0,
                  reason: "",
                });
              }}
            />
            <Button
              label="Submit"
              className="mx-2"
              onClick={(e) => {
                setDisplayAddModal(false);
                handleSubmit(e);
              }}
            />
          </div>
        </Dialog>

        {/* Edit Modal */}
        <Dialog
          visible={displayEditModal}
          style={{ width: "50vw" }}
          onHide={() => setDisplayEditModal(false)}
          header="Edit Permission"
        >
          <div>
            <label htmlFor="fromTime">Date</label>
            <input
              type="date"
              id="fromTime"
              value={editedData.date || ""}
              onChange={(e) =>
                setEditedData({ ...editedData, date: e.target.value })
              }
            />
          </div>
          <div>
            <label htmlFor="fromTime">From Time</label>
            <input
              type="time"
              id="fromTime"
              value={editedData.from_time || ""}
              onChange={handleFromTimeChange}
              min="00:00"
              max="23:59"
            />
          </div>
          <div>
            <label htmlFor="toTime">To Time</label>
            <input
              type="time"
              id="toTime"
              value={editedData.to_time || ""}
              onChange={handleToTimeChange}
              min="00:00"
              max="23:59"
            />
          </div>
          <div>
            <label htmlFor="no_of_hours">No Of Hours</label>
            <input
              type="number"
              id="no_of_hours"
              value={editedData.no_of_hours || "0"}
              onChange={(e) =>
                setEditedData({ ...editedData, no_of_hours: e.target.value })
              }
              disabled
            />
          </div>
          <div>
            <label htmlFor="reason">Reason</label>
            <TextArea
              type="text"
              id="reason"
              className="w-full"
              value={editedData.reason || ""}
              onChange={(e) =>
                setEditedData({ ...editedData, reason: e.target.value })
              }
            />
          </div>

          <div className="flex justify-content-end mt-4">
            <Button
              label="Cancel"
              className="p-button-danger"
              onClick={() => setDisplayEditModal(false)}
            />
            <Button label="Submit" className="mx-2" onClick={saveChanges} />
          </div>
        </Dialog>

        {/* delete model */}
        <Dialog
          visible={displayConfirmation}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={hideConfirmationModal}
          header="Confirmation"
          footer={
            <div>
              <Button
                style={{ color: "white", background: "red" }}
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideConfirmationModal}
              />
              <Button
                style={{ color: "white", background: "#22c55e" }}
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={confirmDelete}
              />
            </div>
          }
        >
          Are you sure you want to delete the Permission data?
        </Dialog>
      </div>
    </div>
  );
}
