import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';

export default function RejectedReasonModel({ selectedTask, visible, onHide, handleReject }) {
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (selectedTask) {
      setDescription(""); 
    }
  }, [selectedTask]);

  const handleSubmit = () => {
    const approvalStatus = "Rejected";
    handleReject(approvalStatus, description, selectedTask?.Task_id);
    onHide();
  };

  return (
    <Dialog header="Rejection Reason" visible={visible} onHide={onHide} style={{ width: '450px' }}>
      <div className="p-field">
        <label htmlFor="task_rejection_reason">Details</label>
        <InputTextarea
          id="task_rejection_reason"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={5}
          cols={30}
          autoResize={false}
          style={{ resize: "none" }}
        />
      </div>
      <div className="p-dialog-footer mt-3">
        <Button label="Cancel" icon="pi pi-times" onClick={onHide} className="p-button-danger" />
        <Button label="Submit" icon="pi pi-check" onClick={handleSubmit} autoFocus />
      </div>
    </Dialog>
  );
}
