import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ADMIN_LEAVE_API, EMPLOYEE_API, LEAVE_API } from "../../constants/api";
import axios from "axios";
import { Fieldset } from "primereact/fieldset";
import { FaCheckCircle } from "react-icons/fa";
import { MdCalendarToday } from "react-icons/md";
import { TbMessageBolt } from "react-icons/tb";
import { Button } from "primereact/button";
import { FaPersonCircleExclamation, FaRegCalendarCheck } from "react-icons/fa6";
import { GrDocumentText } from "react-icons/gr";
import { ImClock } from "react-icons/im";
import { MdOutlineRequestQuote } from "react-icons/md";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import leaveDisplay from "../../assets/images/leavedisplay.jpg";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { AiOutlineCloseSquare } from "react-icons/ai";

export default function LeaveViewPage() {
  const { id: leaveRequestId } = useParams();
  const navigate = useNavigate();
  const toast = useRef(null);
  const [userLeaveData, setUserLeaveData] = useState([]);
  const [filenames, setFilenames] = useState([]);
  const [requestId, setLeaveRequestId] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [formData, setFormData] = useState({ reason: "", status: "rejected" });
  const [reasonError, setReasonError] = useState("");

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(EMPLOYEE_API.GET_ALL);
      if (response.status === 200) {
        const employeesData = response.data.usersData;
        setEmployees(employeesData);
      }
    } catch (error) {
      console.error("Error fetching employees:", error.message);
    }
  };

  const fetchProfileData = async () => {
    try {
      if (leaveRequestId) {
        const response = await axios.get(
          ADMIN_LEAVE_API.USER_LEAVEDATA(leaveRequestId)
        );
        setUserLeaveData(response.data.leaveData);
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, [leaveRequestId]);

  const fetchFilenames = async () => {
    try {
      const response = await axios.get(
        ADMIN_LEAVE_API.GET_LEAVE_DOCUMENT(leaveRequestId)
      );
      const data = response.data;
      setFilenames(data.filenames);
      setLeaveRequestId(data.leaveRequestId);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFilenames();
  }, []);

  const getFolderFromFilename = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
      return "Images";
    } else if (["pdf", "doc", "docx", "txt"].includes(extension)) {
      return "OnlyPDF";
    } else if (["mp3", "wav", "ogg", "aac"].includes(extension)) {
      return "OnlyAudio";
    } else if (["mp4", "avi", "mkv", "mov"].includes(extension)) {
      return "OnlyVideo";
    } else {
      return "common";
    }
  };

  const fetchAdminFileUrl = async (filename) => {
    try {
      const response = await axios.get(
        ADMIN_LEAVE_API.DOWNLOAD_LEAVE_FILE(
          getFolderFromFilename(filename),
          filename
        ),
        {
          responseType: "blob",
        }
      );
      const contentType = response.headers["content-type"];

      if (contentType && contentType.includes("application/json")) {
        const data = response.data;
        return data.fileUrl;
      } else {
        const blob = new Blob([response.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        return url;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleAdminFileDownload = async (filename) => {
    try {
      const fileUrl = await fetchAdminFileUrl(filename);
      if (fileUrl) {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const matchedEmployee = employees.find(
    (employee) => employee.empid === userLeaveData.empid
  );

  const openDialog = () => {
    setDialogVisible(true);
  };

  const closeDialog = () => {
    setDialogVisible(false);
    setReasonError("");
    setFormData({ ...formData, reason: "" });
  };


  const submitReason = async () => {
    try { 
      if (!formData.reason.trim()) {
        setReasonError("Reason is required");
        return;
      }

      const response = await axios.put(
        ADMIN_LEAVE_API.UPDATE_REJECTION_REASON(leaveRequestId),
        {
          status: formData.status,
          rejection_reason: formData.reason,
        }
      );
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Leave request updated successfully",
          life: 3000,
        });

         if (userLeaveData.status === "rejected" && userLeaveData.leave_request_type === "Paid") {
          const paidLeaveData = { status: userLeaveData.status, leave_request_type: userLeaveData.leave_request_type }

          const { data } = await axios.put(LEAVE_API.LEAVE_PAID_REJECTED(userLeaveData.leaveRequestId), { ...paidLeaveData })
        }


        closeDialog();
        fetchProfileData();
      }
    } catch (error) {
      console.error("Error updating leave request:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to update leave request",
        life: 3000,
      });
    }
  };


  return (
    <div>
      <Toast ref={toast} />
      <div className="mb-4">
        <i
          className="pi pi-arrow-left"
          style={{ fontSize: "2rem", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        ></i>
      </div>
      <div className="card">
        <div className="flex justify-content-end">
          <Button
            icon="fi fi-bs-plus"
            className="add-button"
            onClick={openDialog}
            autoFocus
          >
            Rejection Reason
          </Button>
        </div>
        <div className="flex">
          <Fieldset legend={userLeaveData.empid} style={{ width: "50%" }}>
            <div className="flex justify-content-between">
              <h2 className="my-3">
                {matchedEmployee
                  ? `${matchedEmployee.firstname} ${matchedEmployee.lastname}`
                  : ""}
              </h2>
            </div>
            <div>
              <p className="p-2">
                <MdCalendarToday
                  style={{
                    width: "20px",
                    marginRight: "5px",
                    fontSize: "25px",
                  }}
                />{" "}
                <strong>From Date:</strong> {userLeaveData.fromdate}
              </p>
              <p className="p-2">
                <FaRegCalendarCheck
                  style={{
                    width: "20px",
                    marginRight: "5px",
                    fontSize: "25px",
                  }}
                />{" "}
                <strong>To Date:</strong> {userLeaveData.todate}
              </p>
              <p className="p-2">
                <GrDocumentText
                  style={{
                    width: "20px",
                    marginRight: "5px",
                    fontSize: "25px",
                  }}
                />{" "}
                <strong>Leave Type:</strong> {userLeaveData.leavetype}
              </p>
              <p className="p-2">
                <ImClock
                  style={{
                    width: "20px",
                    marginRight: "5px",
                    fontSize: "25px",
                  }}
                />{" "}
                <strong>No Of Days:</strong> {userLeaveData.noOfdays}
              </p>
              <p className="p-2">
                <MdOutlineRequestQuote
                  style={{
                    width: "20px",
                    marginRight: "5px",
                    fontSize: "25px",
                  }}
                />{" "}
                <strong>Request Type:</strong>{" "}
                {userLeaveData.leave_request_type}
              </p>
              <p className="p-2" style={{ textTransform: "capitalize" }}>
                <FaCheckCircle
                  style={{
                    width: "20px",
                    marginRight: "5px",
                    fontSize: "25px",
                  }}
                />{" "}
                <strong>Status:</strong> {userLeaveData.status}
              </p>
              <p className="p-2">
                <TbMessageBolt
                  style={{
                    width: "20px",
                    marginRight: "5px",
                    fontSize: "25px",
                  }}
                />{" "}
                <strong>Reason:</strong> {userLeaveData.reason}
              </p>
              {userLeaveData.rejection_reason && (
                <p className="p-2">
                  <AiOutlineCloseSquare
                    style={{
                      width: "20px",
                      marginRight: "5px",
                      fontSize: "24px",
                      color: "red",
                    }}
                  />{" "}
                  <strong>Rejection Reason:</strong>{" "}
                  {userLeaveData.rejection_reason}
                </p>
              )}
              {userLeaveData.user_replay && (
                <p className="p-2">
                  <FaPersonCircleExclamation style={{
                    width: "20px",
                    marginRight: "5px",
                    fontSize: "24px",
                  }}
                  />
                  {" "}
                  <strong>Request:</strong>{" "}
                  {userLeaveData.user_replay}
                </p>
              )}

              <hr />
              <div>
                <div className="p-2">
                  <h4 style={{ marginBottom: "4px" }}>Attachment</h4>
                </div>
                {filenames.map((filename, index) => (
                  <p key={index} className="p-2">
                    {filename}{" "}
                    <i
                      className="pi pi-download"
                      onClick={() => handleAdminFileDownload(filename)}
                      style={{ color: "green", fontSize: "25px" }}
                    ></i>
                  </p>
                ))}
              </div>
            </div>
          </Fieldset>
          <div className="" style={{ marginTop: "30px" }}>
            <img
              src={leaveDisplay}
              alt="no image found"
              style={{ width: "600px", height: "770px" }}
            />
          </div>
        </div>
      </div>

      <Dialog
        header="Add Reason"
        visible={dialogVisible}
        onHide={closeDialog}
        position="center"
        draggable={false}
        footer={
          <div>
            <Button
              label="Cancel"
              icon="pi pi-times"
              onClick={closeDialog}
              className="p-button-danger"
            />
            <Button label="Submit" icon="pi pi-check" onClick={submitReason} />
          </div>
        }
      >
        <div className="p-field">
          <label htmlFor="reason">Reason For Rejection</label>
          <InputTextarea
            id="reason"
            value={formData.reason}
            onChange={(e) =>
              setFormData({ ...formData, reason: e.target.value })
            }
            rows={3}
            cols={30}
            style={{ resize: "none" }}
          />
          {reasonError && <small className="p-error">{reasonError}</small>}
        </div>
        <div className="p-field">
          <label htmlFor="status">Status</label>
          <InputText
            id="status"
            value={formData.status}
            onChange={(e) =>
              setFormData({ ...formData, status: e.target.value })
            }
            placeholder="Enter status"
            className="w-full"
            disabled
            style={{ textTransform: "capitalize" }}
          />
        </div>
      </Dialog>
    </div>
  );
}
