import { message } from "antd";

/* eslint no-param-reassign:"off" */
var a = [
  "",
  "one ",
  "two ",
  "three ",
  "four ",
  "five ",
  "six ",
  "seven ",
  "eight ",
  "nine ",
  "ten ",
  "eleven ",
  "twelve ",
  "thirteen ",
  "fourteen ",
  "fifteen ",
  "sixteen ",
  "seventeen ",
  "eighteen ",
  "nineteen ",
];
var b = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

export const isServer = !(typeof window !== "undefined");
export function inWords(num) {
  let n = [];
  if ((num = num.toString()).length > 9) return "overflow";
  n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        "only "
      : "";
  return str;
}

export const randomColor = () => {
  const baseColor = {
    r: Math.floor(Math.random() * 256),
    g: Math.floor(Math.random() * 256),
    b: Math.floor(Math.random() * 256),
  };

  const pastelColor = {
    r: Math.floor((baseColor.r + 255) / 2),
    g: Math.floor((baseColor.g + 255) / 2),
    b: Math.floor((baseColor.b + 255) / 2),
    a: 255,
  };

  return pastelColor;
};

export   const getDaysArray = (start, end) => {
  const daysArray = [];
  for (
    let dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    daysArray.push(new Date(dt).toLocaleDateString());
  }
  return daysArray;
};
export const getAvatarLabel = (char) => {
  let label = "";
  if (typeof char === "string") {
    const charSet = char.split(" ");
    for (const str of charSet) {
      label += str[0].toUpperCase();
    }
  }
  return label;
};

export const downloadFileAtUrl = (fileName, url) => {
  if (typeof fileName === "string" && typeof url === "string") {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(new Blob([blob]));
        const element = document.createElement("a");
        element.href = blobUrl;
        element.setAttribute("download", fileName);
        document.body.appendChild(element);
        element.click();
        element.remove();
        message.success("Downloaded");
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
        message.error("Something went wrong");
      });
  } else {
    message.error("Invalid argument");
  }
};
