export const DocumentTitle=[
    { "label": "Aadhaar Card", "value": "Aadhaar Card" },
    { "label": "Arms License", "value": "Arms License" },
    { "label": "Bank Passbook", "value": "Bank Passbook" },
    { "label": "Birth Certificate", "value": "Birth Certificate" },
    { "label": "Caste Certificate", "value": "Caste Certificate" },
    { "label": "Disability ID Card", "value": "Disability ID Card" },
    { "label": "Domicile Certificate", "value": "Domicile Certificate" },
    { "label": "Driving License", "value": "Driving License" },
    { "label": "Electricity Bill", "value": "Electricity Bill" },
    { "label": "Experience Certificate", "value": "Experience Certificate" },
    { "label": "Employee ID Card", "value": "Employee ID Card" },
    { "label": "Freedom Fighter Card", "value": "Freedom Fighter Card" },
    { "label": "Gas Connection Bill", "value": "Gas Connection Bill" },
    { "label": "Health Insurance Card", "value": "Health Insurance Card" },
    { "label": "Identity Cards Educational", "value": "Identity Cards Educational" },
    { "label": "Identity Cards Employers", "value": "Identity Cards Employers" },
    { "label": "Identity Certificate", "value": "Identity Certificate" },
    { "label": "Income Certificate", "value": "Income Certificate" },
    { "label": "Income Tax Assessment Order", "value": "Income Tax Assessment Order" },
    { "label": "Insurance Policy Document", "value": "Insurance Policy Document" },
    { "label": "Legal Heir Certificate", "value": "Legal Heir Certificate" },
    { "label": "Marriage Certificate", "value": "Marriage Certificate" },
    { "label": "Mark Sheet", "value": "Mark Sheet" },
    { "label": "National Population Register", "value": "National Population Register" },
    { "label": "PAN Card ", "value": "PAN Card " },
    { "label": "Passport", "value": "Passport" },
    { "label": "PaySlip", "value": "PaySlip" },
    { "label": "Pension Document", "value": "Pension Document" },
    { "label": "Performance Certificate", "value": "Performance Certificate" },
    { "label": "Property Tax Receipt", "value": "Property Tax Receipt" },
    { "label": "Provisional Certificate", "value": "Provisional Certificate" },
    { "label": "Railway Identity Card", "value": "Railway Identity Card" },
    { "label": "Ration Card", "value": "Ration Card" },
    { "label": "Resume", "value": "Resume" },
    { "label": "Relieving Letter", "value": "Relieving Letter" },
    { "label": "Senior Citizen Card", "value": "Senior Citizen Card" },
    { "label": "Social Security Card", "value": "Social Security Card" },
    { "label": "Student ID Card", "value": "Student ID Card" },
    { "label": "Telephone Bill", "value": "Telephone Bill" },
    { "label": "Salary Increment", "value": "Salary Increment" },
    { "label": "Vehicle Registration Certificate", "value": "Vehicle Registration Certificate" },
    { "label": "Voter ID Card", "value": "Voter ID Card" },
    { "label": "Water Bill", "value": "Water Bill" },
    { "label": "Other", "value": "other" }
]
